import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TemplateService } from 'app/_services/adm/template.service';
import { Template } from 'app/_models/adm/template';
import { Router } from '@angular/router';
@Component({
  selector: 'app-select-template-dialog',
  templateUrl: './select-template-dialog.component.html',
  styleUrls: ['./select-template-dialog.component.scss']
})
export class SelectTemplateDialogComponent implements OnInit, OnDestroy {
  constructor(public bsModalRef: BsModalRef, private templateService: TemplateService, public modalService: BsModalService, private router: Router) {}

  public templates: Template[] = [];
  public type = '';
  public subtype = '';
  public subscriptions = [];

  ngOnInit() {
    this.subscriptions.push(
      this.templateService.getByType(this.type).subscribe((templates) => {
        this.templates = templates;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  onEmpty() {
    if (this.subtype == 'milestone') {
      this.router.navigate(['referral/milestone/'], {
        queryParams: { isNew: true }
      });
    } else {
      this.router.navigate(['referral/classic/'], {
        queryParams: { isNew: true }
      });
    }
    this.bsModalRef.hide();
  }

  onSelectTemplate(template: Template) {
    this.subscriptions.push(this.templateService.use(template._id).subscribe(() => {}));

    if (template.subtype == 'milestone') {
      this.router.navigate(['referral/milestone/'], {
        queryParams: {
          isNew: true,
          template: template._id,
          fromTemplate: true
        }
      });
    } else {
      this.router.navigate(['referral/classic/'], {
        queryParams: {
          isNew: true,
          template: template._id,
          fromTemplate: true
        }
      });
    }
    this.bsModalRef.hide();
  }
}
