<div class="amp-dialog">
  <div class="amp-dialog-title">
    <p>Editando Conjuntos de Tags</p>
  </div>
  <p class="amp-dialog-subtitle">
    Aqui você gerencia seus Conjuntos de Tags. Estes conjuntos servem para agrupar suas tags, permitindo que você escolha qual conjunto quer aplicar em cada
    campanha separadamente.
  </p>
  <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>

  <div class="amp-dialog-content">
    <div class="amp-dialog-tags">
      <div
        class="amp-dialog-tag"
        *ngFor="let tagSet of tagSets; let i = index"
        [ngClass]="{ 'amp-dialog-selected-tag': i == tagIndexSelected }"
        (click)="selectTag(i)"
      >
        <span>{{ tagSet.name }}</span>
        <div class="amp-tagset-icons">
          <i (click)="renameTagSet()" *ngIf="i == tagIndexSelected" class="bi-pencil"></i>
          <i (click)="deleteTagSet()" *ngIf="i == tagIndexSelected" class="bi-trash3"></i>
        </div>
      </div>
      <div (click)="openCreateNewTagSet()" class="amp-dialog-new-tag">
        <span>novo conjunto</span>
        <i class="bi-plus-lg"></i>
      </div>
    </div>
    <div class="amp-dialog-divisor"></div>
    <div class="amp-dialog-keyword">
      <span class="amp-dialog-keyword-title">Escolha as tags para este conjunto</span>
      <amp-multi-select [(value)]="smartTags" [onlyFromAutoComplete]="true" primaryPlaceholder="Comece a digitar para buscar uma tag" [addOnEnter]="false" [optionsObservable]="requestTagsAutoComplete" displayBy="name" identifyBy="_id"></amp-multi-select>
    </div>
  </div>

  <div class="amp-dialog-actions">
    <button (click)="updateTagSets()" type="submit" class="amp-btn-success">
      <span>Salvar e Sair</span>
    </button>
  </div>
</div>
