<div class="amp-dialog">
  <div class="amp-customer-wrapper">
    <img class="amp-customer-picture" [src]="getGravatar(data.customer_email)" />
    <div class="amp-customer-data">
      <span class="amp-customer-name">{{
        data.customer_name ? (data.customer_name | lgpd) : data.customer_email ? data.customer_email : 'Contato Excluído'
      }}</span>
      <span class="amp-customer-date">Participa desde {{ data.created_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm' }}</span>
    </div>
  </div>

  <div class="amp-stepper">
    <div class="amp-step" (click)="step = 0" [ngClass]="{ active: step == 0 }">
      <span class="amp-step-text">Indicações</span>
    </div>
    <div *ngIf="campaign.type == 'milestone'" class="amp-step" (click)="step = 1" [ngClass]="{ active: step == 1 }">
      <span class="amp-step-text">Milestones</span>
    </div>
    <div class="amp-step" (click)="step = 2" [ngClass]="{ active: step == 2 }">
      <span class="amp-step-text">Compartilhamentos</span>
    </div>
  </div>

  <div [ngSwitch]="step">
    <ng-container *ngSwitchCase="0">
      <ng-container *ngTemplateOutlet="referralsTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <ng-container *ngTemplateOutlet="milestonesTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <ng-container *ngTemplateOutlet="sharesTemplate"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #referralsTemplate>
  <div class="amp-box-wrap">
    <div class="amp-box green">
      <div class="amp-box-title">Indicações</div>
      <div class="amp-box-content">{{ data.referral_confirmed }} confirmadas e {{ data.referral_pending }} pendentes</div>
    </div>
  </div>

  <div class="amp-list">
    <div class="amp-list-item" *ngFor="let referral of referrals">
      <img class="amp-customer-picture" [src]="referral._referee ? getGravatar(referral._referee.email) : 'https://app.amplifique.me/assets/img/user.png'" />
      <div class="amp-customer-data">
        <div class="amp-customer-infos">
          <span class="amp-customer-name">{{
            referral._referee?.name ? (referral._referee.name | lgpd) : referral._referee?.email ? referral._referee?.email : 'Contato Excluído'
          }}</span>
        </div>
        <span class="amp-customer-date" *ngIf="referral._referee?.email">{{ referral._referee?.email }}</span>
        <span class="amp-customer-date">Foi indicada em {{ referral.created_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm' }}</span>
      </div>
      <div class="amp-item-status">
        <span class="bi-check-lg" *ngIf="referral.referral_status == 'valid'"></span>
        <span class="bi-x-lg" *ngIf="referral.referral_status == 'invalid'"></span>
        <span class="bi-brightness-high" *ngIf="referral.referral_status == 'new'"></span>
        <span class="bi-clock" *ngIf="referral.referral_status == 'pending'"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #milestonesTemplate>
  <div class="amp-box-wrap">
    <div class="amp-box blue">
      <div class="amp-box-title">Milestones</div>
      <div class="amp-box-content">{{ data.reward_redeemed }} confirmadas e {{ data.reward_pending }} pendentes</div>
    </div>
  </div>

  <div class="amp-list">
    <div class="amp-list-item" *ngFor="let reward of rewards">
      <div class="amp-customer-data">
        <span class="amp-customer-name">{{ reward.name }}</span>
        <span class="amp-customer-date"
          >Alcançada em {{ reward.created_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm'
          }}<span *ngIf="reward.redeemed"> | Entregue em {{ reward.redeemed_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm' }}</span></span
        >
      </div>
      <div class="amp-item-status">
        <span tooltip="Reenviar e-mail de recompensa" (click)="resendRewardMail(reward._id)" class="bi-envelope"></span>
        <span class="bi-check-lg" *ngIf="reward.redeemed"></span>
        <span class="bi-clock" *ngIf="!reward.redeemed"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sharesTemplate>
  <div class="amp-share-list">
    <div class="amp-share-header">
      <div class="amp-share-channel">Canal</div>
      <div class="amp-share-click">Cliques</div>
      <div class="amp-share-share">Compart.</div>
    </div>
    <div class="amp-share-row amp-share-whatsapp">
      <div class="amp-share-channel">WhatsApp</div>
      <div class="amp-share-click" *ngIf="coupon.channels">{{ coupon.channels.whatsapp ? coupon.channels.whatsapp : 0 }}</div>
      <div class="amp-share-click" *ngIf="!coupon.channels">0</div>
      <div class="amp-share-share" *ngIf="coupon.share_channels">{{ coupon.share_channels.whatsapp ? coupon.share_channels.whatsapp : 0 }}</div>
      <div class="amp-share-share" *ngIf="!coupon.share_channels">0</div>
    </div>
    <div class="amp-share-row amp-share-facebook">
      <div class="amp-share-channel">Facebook</div>
      <div class="amp-share-click" *ngIf="coupon.channels">{{ coupon.channels.facebook ? coupon.channels.facebook : 0 }}</div>
      <div class="amp-share-click" *ngIf="!coupon.channels">0</div>
      <div class="amp-share-share" *ngIf="coupon.share_channels">{{ coupon.share_channels.facebook ? coupon.share_channels.facebook : 0 }}</div>
      <div class="amp-share-share" *ngIf="!coupon.share_channels">0</div>
    </div>
    <div class="amp-share-row amp-share-twitter">
      <div class="amp-share-channel">Twitter</div>
      <div class="amp-share-click" *ngIf="coupon.channels">{{ coupon.channels.twitter ? coupon.channels.twitter : 0 }}</div>
      <div class="amp-share-click" *ngIf="!coupon.channels">0</div>
      <div class="amp-share-share" *ngIf="coupon.share_channels">{{ coupon.share_channels.twitter ? coupon.share_channels.twitter : 0 }}</div>
      <div class="amp-share-share" *ngIf="!coupon.share_channels">0</div>
    </div>
    <div class="amp-share-row amp-share-linkedin">
      <div class="amp-share-channel">LinkedIn</div>
      <div class="amp-share-click" *ngIf="coupon.channels">{{ coupon.channels.linkedin ? coupon.channels.linkedin : 0 }}</div>
      <div class="amp-share-click" *ngIf="!coupon.channels">0</div>
      <div class="amp-share-share" *ngIf="coupon.share_channels">{{ coupon.share_channels.linkedin ? coupon.share_channels.linkedin : 0 }}</div>
      <div class="amp-share-share" *ngIf="!coupon.share_channels">0</div>
    </div>
    <div class="amp-share-row amp-share-copy">
      <div class="amp-share-channel">Botão Copiar</div>
      <div class="amp-share-click" *ngIf="coupon.channels">{{ coupon.channels.copy ? coupon.channels.copy : 0 }}</div>
      <div class="amp-share-click" *ngIf="!coupon.channels">0</div>
      <div class="amp-share-share" *ngIf="coupon.share_channels">{{ coupon.share_channels.copy ? coupon.share_channels.copy : 0 }}</div>
      <div class="amp-share-share" *ngIf="!coupon.share_channels">0</div>
    </div>
  </div>
</ng-template>
