import { SignupInfo } from 'app/_models/signupInfo';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
@Injectable()
export class SignupService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll() {
    return this.http.get<SignupInfo[]>(this.config.apiUrl + '/signupInfo');
  }

  getById(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/signupInfo/' + _id);
  }

  create(signupInfo: any) {
    return this.http.post<SignupInfo>(this.config.apiUrl + '/signupInfo', signupInfo);
  }

  update(signupInfo: any) {
    return this.http.put<SignupInfo>(this.config.apiUrl + '/signupInfo/' + signupInfo._id, signupInfo);
  }

  delete(_id: string) {
    return this.http.delete<SignupInfo>(this.config.apiUrl + '/signupInfo/' + _id);
  }

  getPendentesByEmpresa(_empresa: string, business?: string) {
    if (business) {
      return this.http.post<SignupInfo[]>(this.config.apiUrl + '/signupInfo/empresa/' + _empresa, { businesses: business });
    }

    return this.http.get<SignupInfo[]>(this.config.apiUrl + '/signupInfo/empresa/' + _empresa);
  }

  resendInvite(_id: string) {
    return this.http.post<SignupInfo>(this.config.apiUrl + '/signupInfo/send/' + _id, {});
  }

  sendPublicMailForm(email) {
    return this.http.post<any>(this.config.apiUrl + '/signupInfo/public', { email });
  }

  verifyPin(pinId, pin, email) {
    return this.http.post<any>(this.config.apiUrl + '/signupInfo/public/verify', { pinId, pin, email });
  }

  createBatch(invites, empresaId) {
    return this.http.post<any>(this.config.apiUrl + '/signupInfo/batch', { emails: invites, empresa: empresaId });
  }
}
