import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { EmpresaService, NotifyService, StoreService } from 'app/_services';
import { AdmService } from 'app/_services/adm/adm.service';
import { FilterPipe } from 'app/_pipes/filter.pipe';
@Component({
  selector: 'app-power-menu-dialog',
  templateUrl: './power-menu-dialog.component.html',
  styleUrls: ['./power-menu-dialog.component.scss']
})
export class PowerMenuDialogComponent implements AfterViewInit, OnDestroy {
  constructor(
    private store: StoreService,
    private bsModalRef: BsModalRef,
    private admService: AdmService,
    private notifyService: NotifyService,
    private empresaService: EmpresaService
  ) {}

  @ViewChild('dialogOptions') dialogOptionsRef: ElementRef;
  @ViewChild('searchFieldInput') searchFieldInput: ElementRef;

  public focusedInnerIndex = 0;
  public subscriptions = [];
  public loading = false;
  public tab = 'home';
  public focusedOuterIndex = -1;
  public surveys = [];
  public businesses = [];
  public resultado: Subject<any> = new Subject();
  public searchField = '';
  public options = [
    {
      value: 'business-selector',
      label: 'Trocar de Conta',
      icon: 'bi-buildings'
    },
    {
      value: 'survey-selector',
      label: 'Procurar Pesquisa',
      icon: 'bi-chat-left-text'
    },
    {
      value: 'admin-settings',
      label: 'Ir para Admin Interna',
      icon: 'bi-sliders2'
    }
  ];

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchFieldInput.nativeElement.focus();
    }, 500);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  @HostListener('document:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    const dialogOptions = this.dialogOptionsRef.nativeElement as HTMLElement;

    if (event.code == 'Escape') {
      this.bsModalRef.hide();
    }

    if (event.code == 'ArrowUp') {
      if (this.tab == 'home') {
        if (this.focusedOuterIndex == -1 || this.focusedOuterIndex == 0) {
          this.focusedOuterIndex = this.options.length - 1;
          return;
        }

        this.focusedOuterIndex--;
      }

      if (this.focusedInnerIndex == 0) {
        if (this.tab == 'business-selector') {
          this.focusedInnerIndex = this.businesses.length - 1;
        }

        if (this.tab == 'survey-selector') {
          this.focusedInnerIndex = this.surveys.length - 1;
        }

        this.scrollIntoView(dialogOptions, this.focusedInnerIndex + 1);
        return;
      }

      this.focusedInnerIndex--;
      this.scrollIntoView(dialogOptions, this.focusedInnerIndex);
    }

    if (event.code == 'ArrowDown') {
      if (this.tab == 'home') {
        if (this.focusedOuterIndex == -1 || this.focusedOuterIndex == this.options.length - 1) {
          this.focusedOuterIndex = 0;
          return;
        }

        this.focusedOuterIndex++;
      }

      if (
        (this.tab == 'business-selector' && this.focusedInnerIndex == this.businesses.length - 1) ||
        (this.tab == 'surveys-selector' && this.focusedInnerIndex == this.surveys.length - 1)
      ) {
        this.focusedInnerIndex = 0;
        this.scrollIntoView(dialogOptions, this.focusedInnerIndex);
        return;
      }

      this.focusedInnerIndex++;
      this.scrollIntoView(dialogOptions, this.focusedInnerIndex + 1);
    }

    if (event.code == 'Enter') {
      if (this.searchField.length > 0) {
        if (this.tab == 'home') {
          const filterPipe = new FilterPipe();
          const filter = {
            field: 'label',
            value: this.searchField
          };

          const filteredItems = filterPipe.transform(this.options, filter);

          if (filteredItems.length == 1) {
            this.triggerAction(filteredItems[0]);
          }

          this.searchField = '';

          return;
        }

        if (this.tab == 'business-selector') {
          const filterPipe = new FilterPipe();
          const filter = {
            field: 'nome',
            value: this.searchField
          };

          const filteredItems = filterPipe.transform(this.businesses, filter);

          if (filteredItems.length == 1) {
            this.selectBusiness(filteredItems[0]);
          }

          if (filteredItems.length > 1) {
            this.selectBusiness(filteredItems[this.focusedInnerIndex]);
          }

          return;
        }

        if (this.tab == 'survey-selector') {
          const filterPipe = new FilterPipe();
          const filter = {
            field: 'title',
            value: this.searchField
          };

          const filteredItems = filterPipe.transform(this.surveys, filter);

          if (filteredItems.length == 1) {
            this.selectSurvey(filteredItems[0]);
          }

          if (filteredItems.length > 1) {
            this.selectSurvey(filteredItems[this.focusedInnerIndex]);
          }

          return;
        }
      } else {
        if (this.tab == 'home') {
          this.triggerAction(this.options[this.focusedOuterIndex]);
        } else if (this.tab == 'survey-selector') {
          this.selectSurvey(this.surveys[this.focusedInnerIndex]);
        } else if (this.tab == 'business-selector') {
          this.selectBusiness(this.businesses[this.focusedInnerIndex]);
        }
      }
    }
  }

  searchSurveys() {
    this.loading = true;
    this.subscriptions.push(
      this.admService.getEmpresaSurveys(this.store.empresa).subscribe({
        next: (data) => {
          this.loading = false;
          this.surveys = data;
        },
        error: (error) => {
          this.loading = false;
          console.error(error);
          this.notifyService.error('Houve um erro ao buscar as pesquisas. Tente novamente ou entre em contato com o suporte.');
        }
      })
    );
  }

  searchBusinesses() {
    this.loading = true;
    this.subscriptions.push(
      this.empresaService.getManagedEmpresas().subscribe({
        next: (data) => {
          this.loading = false;
          this.businesses = data;
        },
        error: (error) => {
          this.loading = false;
          console.error(error);
          this.notifyService.error('Houve um erro ao buscar as contas. Tente novamente ou entre em contato com o suporte.');
        }
      })
    );
  }

  scrollIntoView(container: HTMLElement, index: number) {
    const item = container.children[index] as HTMLElement;
    if (item) {
      item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  triggerAction(option) {
    if (option.value == 'business-selector') {
      this.searchBusinesses();
      this.tab = 'business-selector';
    }

    if (option.value == 'survey-selector') {
      this.searchSurveys();
      this.tab = 'survey-selector';
    }

    if (option.value == 'admin-settings') {
      window.location.href = '/admin/empresas/' + this.store.empresa + '/businesses';
    }

    this.focusedInnerIndex = 0;
    this.focusedOuterIndex = 0;
  }

  selectSurvey(survey) {
    if (survey._business._id != this.store.business._id) {
      localStorage.setItem('business', survey._business._id);
    }

    window.location.href = `/customer-feedback/create-survey/${survey._id}`;
  }

  selectBusiness(business) {
    localStorage.setItem('empresaId', business._id);
    localStorage.removeItem('business');
    window.location.replace('/');
  }
}
