<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <i class="bi-file-earmark-spreadsheet"></i>
      <span>Mapear Colunas</span>
    </div>
    <div class="amp-dialog-subtitle">
      <span>Selecione relacione as colunas e campos que deseja exportar para a planilha.</span>
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-filter" *ngFor="let item of survey.integrations.gl_spreadsheet.columns; let idx = index">
      <span class="amp-column">Coluna {{ columns[idx] }}</span>
      <div class="amp-field amp-view-selector">
        <select class="amp-input-lg" [disabled]="this.survey.integrations.gl_spreadsheet.last_saved_index >= idx" [(ngModel)]="survey.integrations.gl_spreadsheet.columns[idx]">
          <option value="customer=name" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('customer=name')">Nome do Contato</option>
          <option value="customer=email" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('customer=email')">E-mail do Contato</option>
          <option value="customer=phone" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('customer=phone')">Telefone do Contato</option>
          <option value="customer=company" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('customer=company')">Nome da Empresa do Contato</option>

          <ng-container *ngFor="let ccf of customFields">
            <option [value]="'customer_custom_field=' + ccf.internal_name" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('customer_custom_field=' + ccf.internal_name)">Campo Customizado do Contato: {{ ccf.label }}</option>
          </ng-container>

          <ng-container *ngFor="let question of survey.questions">
            <option [value]="'survey_question=' + question.internal_name" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey_question=' + question.internal_name)">Pergunta: {{ question.variations[0].question }}</option>
          </ng-container>

          <ng-container *ngFor="let scf of customFields">
            <option [value]="'survey_custom_field=' + scf.internal_name" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey_custom_field=' + scf.internal_name)">Campo Customizado da Pesquisa: {{ scf.label }}</option>
          </ng-container>

          <option value="survey=created_at" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey=created_at')">Pesquisa solicitada em</option>
          <option value="survey=sent_at" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey=sent_at')">Pesquisa enviada em</option>
          <option value="survey=opened_at" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey=opened_at')">Pesquisa aberta em</option>
          <option value="survey=finalized_at" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey=finalized_at')">Pesquisa respondida em</option>
          <option value="survey=channel" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey=channel')">Canal</option>
          <option value="survey=origin" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey=origin')">Origem</option>
          <option value="survey=internalId" [disabled]="survey.integrations.gl_spreadsheet.columns.includes('survey=internalId')">Id Interno</option>
        </select>

        <span class="bi-chevron-down"></span>
      </div>
      <i (click)="removeRow(idx)" [isDisabled]="!(survey.integrations.gl_spreadsheet.last_saved_index >= idx)" tooltip="Não é possível remover ou alterar campos que já foram salvos anteriormente." [ngClass]="{'not-allowed': survey.integrations.gl_spreadsheet.last_saved_index >= idx }" class="bi-dash-circle"></i>
    </div>
    <button class="amp-add-condition"  (click)="addRow()">Adicionar Coluna</button>
  </div>

  <div class="amp-dialog-footer">
    <div class="amp-right-buttons">
      <button (click)="save()" class="amp-btn-success">Salvar</button>
    </div>
  </div>
</div>
