import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'transformDuration'
})
export class TransformDurationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const duration = moment.duration(value, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.asHours()) - days * 24;
      const mins = Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
      const sec = Math.floor(duration.asSeconds()) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60;

      if (days == 0 && hours == 0 && mins == 0 && sec == 0) {
        return '~1s';
      } else {
        return (days ? days + 'd ' : '') + (hours ? hours + 'h ' : '') + (mins ? mins + 'm ' : '') + (!days && !hours ? (sec ? sec + 's ' : '') : '');
      }
    } else {
      return 'N/A';
    }
  }
}
