import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class ForgotPasswordService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll() {
    return this.http.get(this.config.apiUrl + '/forgotPassword');
  }

  getById(_id: string) {
    return this.http.get(this.config.apiUrl + '/forgotPassword/' + _id);
  }

  create(signupInfo: any): any {
    return this.http.post(this.config.apiUrl + '/forgotPassword', signupInfo);
  }

  update(signupInfo: any) {
    return this.http.put(this.config.apiUrl + '/forgotPassword/' + signupInfo._id, signupInfo);
  }

  delete(_id: string) {
    return this.http.delete(this.config.apiUrl + '/forgotPassword/' + _id);
  }
}
