<section id="login" class="row">
  <div class="col login-card-wrapper">
    <img src="/assets/img/logo_amplifiqueme.svg">
    <div class="amp-card login__card">
      <div class="amp-card-content">
        <div class="animate__animated">
          <h3 class="text-center">Esqueceu ou quer trocar sua senha?</h3>
          <p>Crie sua nova senha abaixo</p>
          <form (ngSubmit)="form.form.valid && trocarSenha()" #form="ngForm" novalidate>
            <div class="amp-field">
              <label class="amp-label-text" for="senha">Nova senha</label>
              <input type="password" class="amp-input-lg" placeholder="Sua nova senha" (focus)="dropdownFilter.toggle(true)" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!#%*?&])[A-Za-z\d$@$!#%*?&].{7,}" [(ngModel)]="user.senha" #senha="ngModel" name="senha" required validateEqual="senha2" reverse="true" />
            </div>
            <div dropdown [autoClose]="false" class="amp-password-validator dropdown" #dropdownFilter="bs-dropdown" placement="left" aria-controls="filter-dropdown">
              <div class="dropdown-menu dropdown-menu-left sortOptions" id="passwordValidator" *dropdownMenu role="menu" aria-labelledby="dropdownFilter" (click)="stopCloseDropdown($event)">
                <password-validator [password]="user.senha"></password-validator>
              </div>
            </div>
            <div class="amp-field">
              <label class="amp-label-text" for="senha2">Confirmar nova senha</label>
              <input type="password" class="amp-input-lg" placeholder="Repita sua nova senha" validateEqual="senha" [(ngModel)]="user.senha2" #senha2="ngModel" name="senha2" required />
            </div>
            <button type="submit" class="amp-btn-lg">Salvar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
