import { User } from './user';
import { Empresa } from './empresa';
export class Company {
  _id: string;
  document: string;
  name: string;
  created_at: Date;
  _user: User;
  _empresa: Empresa;
}
