import { ClientWebhooksService } from 'app/_services/clientWebhooks.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WebhookEndpoint } from 'app/_models/webhook-endpoint';
import { Survey } from 'app/_models/customerFeedback/survey';
import { StoreService } from 'app/_services/store.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotifyService } from 'app/_services';
import { Token } from 'app/_models/token';
@Component({
  selector: 'app-add-webhook-dialog',
  templateUrl: './add-webhook-dialog.component.html',
  styleUrls: ['./add-webhook-dialog.component.scss']
})
export class AddWebhookDialogComponent implements OnInit, OnDestroy {
  constructor(public bsModalRef: BsModalRef, public store: StoreService, public webhookService: ClientWebhooksService, private notifyService: NotifyService) {}

  public clientWebhook: WebhookEndpoint = new WebhookEndpoint();
  public surveys: Survey[] = [];
  public token = new Token();
  public selectedEvents = [];
  public subscriptions = [];
  public isSurvey = false;
  public isNew = true;
  public description;
  public eventList;
  public url;

  public events = [
    {
      name: 'validated_referral'
    },
    {
      name: 'cf_response'
    },
    {
      name: 'new_milestone'
    },
    {
      name: 'new_referral'
    },
    {
      name: 'new_participant'
    }
  ];

  @Input() public cb: Function;

  ngOnInit() {
    this.events = this.events.filter((item) => !this.clientWebhook.enabled_events.includes(item.name));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  cancel() {
    this.bsModalRef.hide();
  }

  removeSpaces(evt) {
    evt.srcElement.value = evt.srcElement.value.replace(' ', '');
  }

  addEvent(event) {
    if (event != '') {
      this.clientWebhook.enabled_events.push(event);
      this.events = this.events.filter((item) => item.name != event);
      this.eventList = '';
    }
  }

  removeEvent(event) {
    this.clientWebhook.enabled_events = this.clientWebhook.enabled_events.filter((item) => item != event);
    this.events.push({ name: event });
  }

  saveWebhook() {
    if (this.isNew) {
      this.clientWebhook._business = this.store.business._id;
      this.subscriptions.push(
        this.webhookService.create(this.clientWebhook).subscribe(() => {
          this.bsModalRef.hide();
          this.notifyService.success('Webhook criado com sucesso!');
        })
      );
    } else {
      this.subscriptions.push(
        this.webhookService.update(this.clientWebhook).subscribe(() => {
          this.bsModalRef.hide();
          this.notifyService.success('Webhook salvo com sucesso!');
        })
      );
    }
  }
}
