import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../../_services/menu.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifyService } from '../../_services/notify.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DialogComponent } from '../../amp-components/dialog/dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'app/_services/store.service';
import { TokenDialogComponent } from 'app/_dialogs/token-dialog/token-dialog.component';
import { TokenService } from 'app/_services/token.service';
import { InfoDialogComponent } from 'app/_dialogs/info-dialog/info-dialog.component';
import { Token } from 'app/_models/token';
@Component({
  selector: 'app-api-panel',
  templateUrl: './api-panel.component.html',
  styleUrls: ['./api-panel.component.scss']
})
export class ApiPanelComponent implements OnInit, OnDestroy {
  constructor(
    public notifyService: NotifyService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private store: StoreService,
    private tokenService: TokenService,
    private menuService: MenuService,
    private modalService: BsModalService
  ) {
    this.menuService.emit(true);
    this.menuService.emitSidebar(true);
    this.menuService.emitPageTitle('API');
  }

  public isAccountManager = false;
  public tokens: Token[] = [];
  public subscriptions = [];

  ngOnInit() {
    this.loadTokens();

    this.subscriptions.push(
      this.menuService.getChangeEmmiterBusiness().subscribe(
        () => {
          this.loadTokens();
        },
        (error) => {
          console.error(error);
        }
      )
    );

    if (this.route.snapshot.queryParams.zapier == 'true') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { zapier: null },
        queryParamsHandling: 'merge'
      });
      this.createToken(true);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadTokens() {
    this.subscriptions.push(
      this.tokenService.getAll(this.store.business._id).subscribe((data) => {
        this.tokens = data;
      })
    );
  }

  editToken(token) {
    const initialState = {
      isNew: false,
      token: token,
      cb: () => {
        this.loadTokens();
      }
    };
    this.modalService.show(TokenDialogComponent, {
      class: 'amp-modal modal-lg',
      initialState
    });
  }

  createToken(isZapier = false) {
    let initialState;
    if (isZapier) {
      initialState = {
        isNew: true,
        cb: (data) => {
          this.loadTokens();
          this.modalService.show(InfoDialogComponent, {
            class: 'amp-modal modal-lg',
            initialState: {
              title: 'Token de Integração com o Zapier',
              message: data.access_token
            }
          });
        },
        zapier: true
      };
    } else {
      initialState = {
        isNew: true,
        cb: () => {
          this.loadTokens();
        },
        zapier: false
      };
    }

    this.modalService.show(TokenDialogComponent, {
      class: 'amp-modal modal-lg',
      initialState
    });
  }

  deleteToken(token) {
    const cb = () => {
      this.subscriptions.push(
        this.tokenService.delete(token._id).subscribe(
          () => {
            this.notifyService.success('Token excluído com sucesso!');
            this.loadTokens();
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Não foi possível excluir o token.');
          }
        )
      );
    };
    const initialState = {
      title: 'Excluir Token',
      subtitle: `Tem certeza que deseja excluir o token ${token.name}? Esta ação é irreversível.`,
      cancelButton: this.translate.instant('dialog-delete-user.cancelButton'),
      confirmButton: this.translate.instant('dialog-delete-user.deleteButton'),
      confirmCallback: cb
    };
    this.modalService.show(DialogComponent, {
      initialState,
      class: 'amp-modal'
    });
  }
}
