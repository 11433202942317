import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class GlService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getRatingsCount(_id: string) {
    return this.http.get(this.config.apiUrl + '/gl/countRatings/' + _id);
  }

  setupGoogleAccount(_id: string, code: string): any {
    return this.http.get(this.config.apiUrl + '/gl/oauth/' + _id + '?code=' + code);
  }

  getAccountInfo(business: string): any {
    return this.http.get(this.config.apiUrl + '/gl/account/info/' + business);
  }

  getBusinessAccounts(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/gl/accounts/' + _id);
  }

  getBusinessLocations(_id: string, accountName: string) {
    return this.http.get<any>(this.config.apiUrl + '/gl/locations/' + _id + '?accountName=' + accountName);
  }

  importReviews(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/gl/getReviews/' + _id);
  }

  authenticateGoogleAccount(includeSheets?): Promise<string> {
    const OAUTHURL = 'https://accounts.google.com/o/oauth2/auth?';
    const CLIENTID = '871333284678-bg64hp4oeltmmrscr12an1nu9stfeflp.apps.googleusercontent.com';
    const REDIRECT = 'https://app.amplifique.me/settings';
    let SCOPE = 'https://www.googleapis.com/auth/plus.business.manage profile https://www.googleapis.com/auth/userinfo.email';
    if (includeSheets) {
      SCOPE += ' https://www.googleapis.com/auth/drive.file';
    }
    const ACCESS_TYPE = 'offline';

    const TYPE = 'code';
    const _url =
      OAUTHURL +
      'scope=' +
      SCOPE +
      '&client_id=' +
      CLIENTID +
      '&redirect_uri=' +
      REDIRECT +
      '&response_type=' +
      TYPE +
      '&access_type=' +
      ACCESS_TYPE +
      '&include_granted_scopes=true&prompt=consent';
    const win = window.open(_url, 'windowname1', 'width=800, height=600');
    return new Promise((resolve, reject) => {
      const pollTimer = window.setInterval(function () {
        try {
          const code = localStorage.getItem('AMPL_GOOGLE_CODE');
          if (code) {
            window.clearInterval(pollTimer);
            try {
              win.close();
            } catch (e) {}
            localStorage.removeItem('AMPL_GOOGLE_CODE');
            return resolve(code);
          }
          if (win.document.URL.indexOf(REDIRECT) != -1) {
            window.clearInterval(pollTimer);
            const url = new URL(win.document.URL);
            try {
              win.close();
            } catch (e) {}
            return resolve(url.searchParams.get('code'));
          }
        } catch (e) {
          console.error(e);
        }
      }, 300);
    });
  }
}
