import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'ampTemplatePipe'
})
export class AmplTemplatePipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!filter || !items) {
      return items;
    }

    if (filter.type == 'referral') {
      return items.filter((item) => item.subtype === filter.stype);
    } else {
      return items;
    }
  }
}
