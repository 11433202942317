import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import moment from 'moment';
@Component({
  selector: 'amp-date-picker',
  templateUrl: 'amp-date-picker.component.html',
  styleUrls: ['amp-date-picker.component.scss']
})
export class AmpDatePickerComponent implements OnInit {
  @Input()
  public dateFilter: Date[] = [moment('1990-01-01').toDate(), moment().toDate()];

  @Output()
  public dateFilterChange = new EventEmitter<Date[]>();

  @Input()
  public subject: Subject<Date[]>;

  @Input()
  public showCustomDate = false;

  @Input()
  public filterTime = 'alltime';

  @Input()
  public disableCookie = false;

  @Output()
  public filterTimeChange = new EventEmitter<string>();

  public dropdownVisibility = false;
  public calendarVisibility = false;
  public customDate = false;
  public alltime = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    const dateFrom = this.cookieService.get('dateFrom');
    const dateTo = this.cookieService.get('dateTo');
    const dateValue = this.cookieService.get('dateValue');
    if (!this.disableCookie) {
      if (dateFrom && dateTo) {
        this.dateFilter[0] = new Date(dateFrom);
        this.dateFilter[1] = new Date(dateTo);
        this.filterTime = 'custom';
        this.setDateFilter('custom');
      } else {
        if (dateValue && dateValue != 'custom') {
          this.setDateFilter(dateValue);
        } else {
          this.setDateFilter('alltime');
        }
      }
    }
  }

  toggleDropdown() {
    if (this.dropdownVisibility) {
      this.dropdownVisibility = false;
      this.removeFlex();
      document.querySelector('#calendar-dropdown').classList.remove('show');
    } else {
      this.dropdownVisibility = true;
      this.calendarVisibility = false;
      this.addFlex();
    }
  }

  toggleCalendar() {
    if (this.calendarVisibility) {
      this.calendarVisibility = false;
      this.removeFlex();
    } else {
      this.calendarVisibility = true;
      this.addFlex();
    }
  }

  removeFlex() {
    this.dropdownVisibility = false;
    document.querySelector('#calendar-dropdown').classList.remove('flex');
  }

  addFlex() {
    if (document.querySelector('#calendar-dropdown')) {
      document.querySelector('#calendar-dropdown').classList.add('flex');
    }
  }

  onChangeDateRange(value) {
    this.dateFilter[0] = value[0];
    this.dateFilter[1] = value[1];
    this.customDate = true;
    this.setDateFilter('custom');
    if (!this.disableCookie) {
      this.cookieService.put('dateFrom', value[0]);
      this.cookieService.put('dateTo', value[1]);
    }
  }

  setDateFilter(value) {
    this.filterTime = value;
    this.cookieService.put('dateValue', value);
    if (value != 'custom' && !this.disableCookie) {
      this.cookieService.remove('dateFrom');
      this.cookieService.remove('dateTo');
    }
    switch (value) {
      case 'today':
        this.dateFilter[0] = moment().startOf('day').toDate();
        this.dateFilter[1] = moment().endOf('day').toDate();
        this.alltime = false;
        break;
      case 'yesterday':
        this.dateFilter[0] = moment().subtract(1, 'day').startOf('day').toDate();
        this.dateFilter[1] = moment().subtract(1, 'day').endOf('day').toDate();
        this.alltime = false;
        break;
      case 'week':
        this.dateFilter[0] = moment().startOf('week').toDate();
        this.dateFilter[1] = moment().endOf('week').toDate();
        this.alltime = false;
        break;
      case 'lastweek':
        this.dateFilter[0] = moment().subtract(1, 'weeks').startOf('week').toDate();
        this.dateFilter[1] = moment().subtract(1, 'weeks').endOf('week').toDate();
        this.alltime = false;
        break;
      case 'last30days':
        this.dateFilter[0] = moment().subtract(30, 'days').toDate();
        this.dateFilter[1] = moment().toDate();
        this.alltime = false;
        break;
      case 'thismonth':
        this.dateFilter[0] = moment().startOf('month').toDate();
        this.dateFilter[1] = moment().endOf('month').toDate();
        this.alltime = false;
        break;
      case 'lastmonth':
        this.dateFilter[0] = moment().subtract(1, 'months').startOf('month').toDate();
        this.dateFilter[1] = moment().subtract(1, 'months').endOf('month').toDate();
        this.alltime = false;
        break;
      case 'last6months':
        this.dateFilter[0] = moment().subtract(6, 'months').toDate();
        this.dateFilter[1] = moment().toDate();
        this.alltime = false;
        break;
      case 'thisyear':
        this.dateFilter[0] = moment().startOf('year').toDate();
        this.dateFilter[1] = moment().endOf('year').toDate();
        this.alltime = false;
        break;
      case 'alltime':
        this.dateFilter[0] = moment('1990-01-01').toDate();
        this.dateFilter[1] = moment().toDate();
        this.alltime = true;
        break;
    }

    this.filterTimeChange.next(value);
    this.dateFilterChange.next(this.dateFilter);

    if (this.subject) {
      this.subject.next(this.dateFilter);
    }
  }
}
