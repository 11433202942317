import { HttpClient } from '@angular/common/http';
import { Acesso } from 'app/_models/acesso';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
@Injectable()
export class AcessoService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getById(_id: string) {
    return this.http.get(this.config.apiUrl + '/acesso/' + _id);
  }

  create(acesso: Acesso) {
    return this.http.post(this.config.apiUrl + '/acesso/', acesso);
  }
}
