import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, map } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import TkPipeline from 'app/_models/ticketing/pipeline';
import { StoreService, TagService } from 'app/_services';
import TkTicket from 'app/_models/ticketing/ticket';
import { CustomerService } from 'app/_services/customer.service';
import { TkTicketService } from 'app/_services/ticketing/ticket.service';
import { TkPipelineService } from 'app/_services/ticketing/pipeline.service';
import moment from 'moment';
import { ContatoDialogComponent } from 'app/contatos/contato-dialog/contato-dialog.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-ticket-dialog',
  templateUrl: './create-ticket-dialog.component.html',
  styleUrls: ['./create-ticket-dialog.component.scss']
})
export class CreateTicketDialogComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private store: StoreService,
    private pipelineService: TkPipelineService,
    private customerService: CustomerService,
    private ticketService: TkTicketService,
    private tagService: TagService,
    private router: Router
  ) {}

  public feedbackId;
  public customerId;
  public minDate = moment().add(1, 'd').toDate();
  public isFromAnswer = false;
  public pipeline: TkPipeline = new TkPipeline();
  public pipelines = [];
  public pipelineOptions = [];
  public result: Subject<any> = new Subject();
  public ticket: TkTicket = new TkTicket();
  public usersOptions = [];
  public existingTickets;
  public loading = false;
  public actions = [
    {
      name: 'addContact',
      label: 'Criar Contato',
      icon: 'bi-plus-lg'
    }
  ];
  public priorites = [
    {
      label: 'Alta',
      value: 2
    },
    {
      label: 'Média',
      value: 1
    },
    {
      label: 'Baixa',
      value: 0
    }
  ];

  ngOnInit() {
    this.ticket.priority = 1;
    this.ticket._assigned_to = this.pipeline.default_assigned_to;
    this.populateUsersOptions();
    this.loadPipelines();
  }

  customersListObservable = (text: string): Observable<any[]> => {
    return this.customerService
      .getCustomers(this.store.business._id, 10, 1, text, JSON.stringify({ created_at: -1 }), this.store.empresaData.global_customers)
      .pipe(
        map((values) => {
          return values.customers.map((value) => {
            return { value: value._id, label: value.name ?? value.email };
          });
        })
      );
  };

  selectPipeline(event) {
    this.pipeline = this.pipelines.find((pipeline) => pipeline._id === event);
    this.populateUsersOptions();
  }

  loadPipelines() {
    this.pipelineService.getAll(this.store.empresa).subscribe((values) => {
      this.pipelines = values;
      this.pipelineOptions = values.map((value) => ({
        value: value._id,
        label: value.name
      }));
    });
  }

  findTickets() {
    this.ticketService.getTicketsByCustomer(this.ticket._customer).subscribe({
      next: (data) => {
        this.existingTickets = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  openTicket(id) {
    this.router.navigate([]).then(() => {
      window.open(`/ticketing/pipelines/ticket/${id}`, '_blank');
    });
  }

  requestTagsAutoComplete = (text): Observable<any> => {
    return this.tagService.getTags(this.store.empresa, text, 'ticketing');
  };

  checkDuplicates(event) {
    if (this.ticket?._tags?.find((tag) => tag.name == event && tag._id != event) || this.ticket?._tags?.filter((tag) => tag.name == event).length > 1) {
      this.ticket?._tags?.pop();
    }
  }

  populateUsersOptions() {
    this.usersOptions = this.pipeline._users.map((user) => {
      return {
        label: user.nome,
        value: user._id
      };
    });
  }

  onActionClick(name) {
    if (name == 'addContact') {
      this.modalService.show(ContatoDialogComponent, {
        class: 'amp-modal',
        initialState: {
          isFromTicketing: true
        }
      });

      this.ticket._customer = '';
    }
  }

  save() {
    this.loading = true;

    if (this.feedbackId) {
      this.ticket._surveyAnswer = this.feedbackId;
    }

    if (this.customerId) {
      this.ticket._customer = this.customerId;
    }

    if (this.ticket._pipeline) {
      this.pipeline._id = this.ticket._pipeline.toString();
    }

    this.ticketService.create(this.pipeline._id, this.ticket).subscribe((data) => {
      this.result.next(data);
      this.bsModalRef.hide();
    });
  }

  closeDialog() {
    this.result.next(false);
    this.bsModalRef.hide();
  }
}
