<div class="amp-circle-image-display">
  <div [ngStyle]="{cursor: cursor}" class="circle" placement="auto" [tooltip]="showTooltip ? circle.name : false" *ngFor="let circle of circles | slice: 0 : circlesNumber">
    <img [src]="circle.url">
  </div>
  <div class="circle" triggers="mouseenter:mouseleave" placement="auto" containerClass="amp-circle-image-display-popover" [popoverContext]="{ data: circles }" [popover]="circlePopover" boundariesElement="viewport" *ngIf="circles.length > circlesNumber">
    <div class="counter">
      <span>+{{ circles.length - circlesNumber }}</span>
    </div>
  </div>
</div>

<ng-template #circlePopover let-data="data">
  <span *ngFor="let circle of circles | slice: circlesNumber : (circles.length + 2 - circlesNumber)">{{ circle.name }}</span>
</ng-template>
