import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-reputation-survey-error-dialog',
  templateUrl: './reputation-survey-error-dialog.component.html',
  styleUrls: ['./reputation-survey-error-dialog.component.scss']
})
export class ReputationSurveyErrorDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public content: any = {};

  closeModal() {
    this.bsModalRef.hide();
  }

  checkMessageDelivered(error) {
    const message = `\n1) O dispositivo da pessoa está desligado ou fora da área de serviço por um determinado período de tempo 
    (normalmente 24h, mas para algumas operadoras, como a Vivo, o período é de apenas 8 horas).\n
    2) Número válido, mas inativo (algumas operadoras retornam este tipo de erro apenas neste status de segundo nível).`;

    if (error == 'NOT_DELIVERED') {
      return message;
    } else {
      return error ? error : 'Indisponível.';
    }
  }
}
