import { SurveyService } from 'app/_services/customerFeedback/survey.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { StoreService } from './../../_services/store.service';
import { EmpresaService, NotifyService } from 'app/_services';
import { AIService } from 'app/_services/ai/ai.service';
import { AISummary } from 'app/_models/ai/aiSummary';
import { FilterPipe } from 'app/_pipes/filter.pipe';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-create-summary-dialog',
  templateUrl: './create-summary-dialog.component.html',
  styleUrls: ['./create-summary-dialog.component.scss']
})
export class CreateSummaryDialogComponent implements OnInit {
  constructor(
    private ai: AIService,
    private store: StoreService,
    public bsModalRef: BsModalRef,
    private filterPipe: FilterPipe,
    private notifyService: NotifyService,
    private surveyService: SurveyService,
    public empresaService: EmpresaService
  ) {}

  public businesses = [];
  public surveysSelected = [];
  public sendEmail = false;
  public businessesSelected = [];
  public isNew = true;
  public creditsAvailable;
  public emails = [];
  public today = new Date();
  public isLoading = false;
  public selectAll = false;
  public searchValue = '';
  public summaryName = '';
  public aiSummary = new AISummary();
  public frequencies = [
    {
      label: 'Semanal',
      value: 'weekly'
    },
    {
      label: 'Quinzenal',
      value: 'biweekly'
    },
    {
      label: 'Mensal',
      value: 'monthly'
    }
  ];

  public answerEstimation = 0;
  public answerEstimationStatus = 'none';
  public answerEstimationSubject = new Subject();
  public result: Subject<any> = new Subject();

  ngOnInit() {
    this.getSurveys();

    if (!this.isNew) {
      if (this.aiSummary.frequency) {
        this.aiSummary.type = 'recurring';
      }

      if (this.aiSummary.emails.length) {
        this.sendEmail = true;
      }

      this.surveysSelected = this.aiSummary._surveys;
      this.businessesSelected = this.aiSummary._businesses;
    }

    this.getAIBalance();

    this.answerEstimationSubject
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.getAnswerEstimation();
      });
  }

  getAIBalance() {
    this.empresaService.getAIBalance(this.store.empresa).subscribe({
      next: (data) => {
        this.creditsAvailable = data[0].balance;
      },
      error: (error) => {
        this.notifyService.error('Houve um erro ao buscar o saldo de AI, tente novamente ou contate o suporte.');
        console.error(error);
      }
    });
  }

  getSurveys() {
    this.isLoading = true;

    this.surveyService.getAllByBusiness(this.store.empresa).subscribe({
      next: (groups) => {
        this.businesses = groups;
        this.isLoading = false;
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      }
    });
  }

  validateEmail(event) {
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,20}$/.test(event)) {
      this.notifyService.warning('E-mail inválido!');
      this.emails.pop();
    }
  }

  onChange() {
    if (this.aiSummary.start_date && this.aiSummary.end_date && this.businessesSelected?.length > 0 && this.surveysSelected.length > 0) {
      const hash = this._hash(
        this.aiSummary.start_date.toString() + this.aiSummary.end_date.toString() + this.surveysSelected.toString() + this.businessesSelected.toString()
      );

      this.answerEstimationSubject.next(hash);
    } else {
      this.answerEstimation = 0;
      this.answerEstimationStatus = 'none';
    }
  }

  getAnswerEstimation() {
    this.answerEstimationStatus = 'loading';
    this.answerEstimation = 0;

    this.ai.estimateAnswersAISummary(this.businessesSelected, this.surveysSelected, this.aiSummary.start_date, this.aiSummary.end_date).subscribe({
      next: (response) => {
        this.answerEstimation = response.nAnswers;
        this.answerEstimationStatus = 'loaded';
      },
      error: (error) => {
        console.error(error);
        this.answerEstimationStatus = 'error';
      }
    });
  }

  openChat() {
    this.result.next('chat');
    this.bsModalRef.hide();
  }

  selectSurveyFromBusiness(survey) {
    if (this.surveysSelected.includes(survey._id)) {
      this.surveysSelected.splice(this.surveysSelected.indexOf(survey._id), 1);

      let bus;
      let surveysOnBusiness = 0;

      this.businesses.find((business) => {
        business.surveys.find((s) => {
          if (s._id === survey._id) {
            bus = business;

            bus.surveys.filter((s) => {
              if (this.surveysSelected.includes(s._id)) {
                surveysOnBusiness++;
              }
            });

            if (surveysOnBusiness === 0) {
              this.businessesSelected.splice(this.businessesSelected.indexOf(bus._id), 1);
            }
          }
        });
      });
    } else {
      this.surveysSelected.push(survey._id);
      this.businesses.find((business) => {
        business.surveys.find((s) => {
          if (s._id === survey._id) {
            if (!this.businessesSelected.includes(business._id)) {
              this.businessesSelected.push(business._id);
            }
          }
        });
      });
    }
    this.onChange();
  }

  isDisabled() {
    if (this.creditsAvailable === 0) {
      return true;
    }

    if (this.aiSummary.type == 'onetime') {
      if (this.answerEstimation < 30 || this.answerEstimation > 2000) {
        return true;
      }
    }

    if (!this.surveysSelected.length) {
      return true;
    }

    if (this.aiSummary.type === 'onetime') {
      if (!this.aiSummary.start_date || !this.aiSummary.end_date || !this.aiSummary.name) {
        return true;
      }
    } else if (!this.aiSummary.frequency || !this.aiSummary.name) {
      return true;
    }

    return false;
  }

  selectAllSurveysFromAllBusinesses() {
    this.businessesSelected = [];
    this.surveysSelected = [];

    if (this.selectAll) {
      this.businesses.forEach((business) => {
        if (this.searchValue.length) {
          const x = business.surveys.filter((s) => s.title.toLowerCase().includes(this.searchValue.toLowerCase()));
          if (x.length) {
            this.businessesSelected.push(business._id);
            x.forEach((survey) => {
              this.surveysSelected.push(survey._id);
            });
          }
        } else {
          this.businessesSelected.push(business._id);
          business.surveys.forEach((survey) => {
            this.surveysSelected.push(survey._id);
          });
        }
      });
    }
    this.onChange();
  }

  selectAllSurveysFromBusiness(business) {
    if (this.businessesSelected.includes(business._id)) {
      this.businessesSelected.splice(this.businessesSelected.indexOf(business._id), 1);
      business.surveys.forEach((survey) => {
        if (this.surveysSelected.includes(survey._id)) {
          this.surveysSelected.splice(this.surveysSelected.indexOf(survey._id), 1);
        }
      });
    } else {
      this.businessesSelected.push(business._id);

      if (this.searchValue.length) {
        const x = this.filterPipe.transform(business.surveys, { field: 'title', value: this.searchValue });
        if (x.length) {
          x.forEach((survey) => {
            this.surveysSelected.push(survey._id);
          });
        }
      } else {
        business.surveys.forEach((survey) => {
          if (!this.surveysSelected.includes(survey._id)) {
            this.surveysSelected.push(survey._id);
          }
        });
      }
    }
    this.onChange();
  }

  createSummary() {
    this.aiSummary._surveys = this.surveysSelected;
    this.aiSummary._businesses = this.businessesSelected;

    if (this.sendEmail) {
      this.aiSummary.emails = this.emails;
    }

    if (this.store.userData.role == 'acmng') {
      this.aiSummary._empresa = this.store.empresa;
    }

    if (this.aiSummary.type == 'onetime') {
      this.ai.createAISummary(this.aiSummary).subscribe({
        next: () => {
          this.result.next('created');
          this.bsModalRef.hide();
          this.notifyService.success('Resumo criado com sucesso!');
        },
        error: (error) => {
          console.error(error);

          if (error.error == 'INSUFFICIENT_CREDITS') {
            this.notifyService.error('Você não possui créditos para gerar um resumo. Entre em contato com o suporte para adquirir créditos.');
          } else {
            this.notifyService.error('Houve um erro ao criar o resumo, tente novamente ou contate o suporte.');
          }
        }
      });
    } else {
      this.ai.createRecurringAISummary(this.aiSummary).subscribe({
        next: () => {
          if (this.isNew) {
            this.notifyService.success('Recorrência criada com sucesso!');
          } else {
            this.notifyService.success('Recorrência editada com sucesso!');
          }
          this.bsModalRef.hide();
        },
        error: (error) => {
          console.error(error);
          this.notifyService.error('Houve um erro ao salvar a recorrência, tente novamente ou contate o suporte.');
        }
      });
    }
  }

  private _hash(text) {
    //@ts-ignore
    return window.md5(text.toString());
  }
}
