<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-people"></span>
      {{'validar-indicacao.title' | translate}}
    </div>
    <div class="amp-dialog-subtitle"> Para validar uma indicação, é só buscar o contato e selecionar a recompensa!</div>

    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <form (ngSubmit)="f.form.valid && save()"  autocomplete="false" #f="ngForm">
  <div class="amp-dialog-body">
    <div class="amp-field amp-field-autocomplete">
      <label class="amp-label-text" >{{'contato-dialog.searchField' | translate}}</label>
      <input type="search"  class="amp-input-lg" id="amp-autocomplete" (keyup)="onTypeSearch($event.target.value)" (focus)="toggleDropdown()" name="searchContact" [(ngModel)]="searchField" autofill="false" autocomplete="off" #name="ngModel" [placeholder]="'contato-dialog.searchFieldPlaceholder' | translate" />
      <div clickOutside (clickOutside)="toggleDropdown()" *ngIf="showDropDown" class="amp-field-dropdown">
        <div class="amp-autocomplete-content">

          <div *ngFor="let customer of customers" (click)="selectCustomer(customer)" class="amp-autocomplete-item">
            <div class="amp-cell-infotext"> <span class="amp-info-name">{{customer.name}}</span> <span class="amp-info-email">{{customer.email}}</span><span class="amp-info-tel">{{customer.tel | phone }}</span></div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="amp-dialog-actions">
    <button class="amp-btn-gradient1 amp-btn-lg" type="submit">{{'validar-indicacao.button' | translate}}</button>
    <a class="amp-btn-outline-secondary amp-btn-lg" (click)="bsModalRef.hide()">{{'main.cancelButton' | translate}}</a>
  </div>
</form>
</div>
