<div class="amp-sidebar-filter animate__animated animate__faster" [ngClass]="{ animate__slideInRight: show, animate__slideOutRight: show == false && show != null }">
  <div class="amp-sidebar-filter-header">
    <span class="amp-sidebar-filter-header-title">Filtrar Contatos</span>
    <span class="amp-sidebar-filter-close bi-x-lg" (click)="closeFilter.emit()"></span>
  </div>

  <div class="amp-sidebar-filter-body">
    <div class="amp-sidebar-filter-section">
      <h6 class="amp-sidebar-filter-section-title">FILTRAR POR TAGS DE CONTATO</h6>
      <amp-multi-select [(value)]="tagsFilter" [onlyFromAutoComplete]="true" [addOnEnter]="false" identifyBy="value" displayBy="display" [optionsObservable]="requestTagsAutoComplete" primaryPlaceholder="Ex.: Importação"></amp-multi-select>
    </div>

    <div class="amp-sidebar-filter-section">
      <h6 class="amp-sidebar-filter-section-title">STATUS DO CONTATO</h6>
      <div class="status-options">
        <label class="amp-radio-button">
          <input type="radio" checked="checked" name="filterStatus" value="all" [(ngModel)]="filterStatus" />
          <span class="text">Todos</span>
          <span class="checkmark"></span>
        </label>

        <label class="amp-radio-button">
          <input type="radio" name="filterStatus" value="subscribed" [(ngModel)]="filterStatus" />
          <span class="text">Inscritos</span>
          <span class="checkmark"></span>
        </label>

        <label class="amp-radio-button">
          <input type="radio" name="filterStatus" value="unsubscribed" [(ngModel)]="filterStatus" />
          <span class="text">Desinscritos</span>
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <div class="amp-sidebar-filter-section">
      <h6 class="amp-sidebar-filter-section-title">DATA DE CRIAÇÃO DO CONTATO</h6>

      <div class="date-options">
        <label class="amp-checkbox">
          <input type="checkbox" [(ngModel)]="filterFromDateStatus" (change)="filterFromDate = ''" />
          <span class="text">A partir de uma data</span>
          <span class="checkmark"></span>
        </label>
        <input *ngIf="filterFromDateStatus" class="amp-input-lg datepicker" bsDatepicker [bsConfig]="{ containerClass: 'theme-amp' }" [(ngModel)]="filterFromDate" type="search" placeholder="Selecione uma data" />

        <label class="amp-checkbox">
          <input type="checkbox" [(ngModel)]="filterToDateStatus" (change)="filterToDate = ''" />
          <span class="text">Até uma data</span>
          <span class="checkmark"></span>
        </label>
        <input *ngIf="filterToDateStatus" class="amp-input-lg" bsDatepicker [bsConfig]="{ containerClass: 'theme-amp' }" [(ngModel)]="filterToDate" type="search" placeholder="Selecione uma data" />
      </div>

    </div>

    <div class="amp-sidebar-filter-section">
      <h6 class="amp-sidebar-filter-section-title">AÇÕES DO CONTATO</h6>

      <div class="date-options">
        <label class="amp-checkbox" *ngIf="store.business?.reviews_enabled">
          <input type="checkbox" [(ngModel)]="filterReviewStatus" />
          <span class="text">Reputação Online</span>
          <span class="checkmark"></span>
        </label>
        <div class="amp-sidebar-filter-subsec" *ngIf="filterReviewStatus">
          <label class="amp-radio-button">
            <input type="radio" name="filterReview" value="requestReview" [(ngModel)]="filterReview" />
            <span class="text">Solicitação Enviada</span>
            <span class="checkmark"></span>
          </label>
          <label class="amp-radio-button">
            <input type="radio" name="filterReview" value="NotrequestReview" [(ngModel)]="filterReview" />
            <span class="text">Solicitação Não Enviada</span>
            <span class="checkmark"></span>
          </label>
        </div>

        <label class="amp-checkbox" *ngIf="store.business?.nps_enabled && store.business?.cf_attributes?.cf_surveys">
          <input type="checkbox" [(ngModel)]="filterSurveyStatus" />
          <span class="text">Pesquisas e Respostas</span>
          <span class="checkmark"></span>
        </label>
        <div class="amp-sidebar-filter-subsec gap-16" *ngIf="filterSurveyStatus">
          <div>
            <label class="amp-label-text" for="campaignType">Selecionar Pesquisa</label>
            <amp-select [isSearchable]="true" [options]="surveysOptions" [(value)]="filterSurveyCampaign"></amp-select>
          </div>

          <div class="amp-radio-wrapper">
            <label class="amp-radio-button">
              <input type="radio" checked="checked" name="surveyAnswered" value="surveyAnswered" [(ngModel)]="filterSurvey" />
              <span class="text">Respondeu</span>
              <span class="checkmark"></span>
            </label>
            <label class="amp-radio-button">
              <input type="radio" name="notSurveyAnswered" value="notSurveyAnswered" [(ngModel)]="filterSurvey" />
              <span class="text">Não Respondeu</span>
              <span class="checkmark"></span>
            </label>
            <label class="amp-radio-button">
              <input type="radio" name="surveyRequested" value="surveyRequested" [(ngModel)]="filterSurvey" />
              <span class="text">Pesquisa Solicitada</span>
              <span class="checkmark"></span>
            </label>
            <label class="amp-radio-button">
              <input type="radio" name="notSurveyRequested" value="notSurveyRequested" [(ngModel)]="filterSurvey" />
              <span class="text">Pesquisa Não Solicitada</span>
              <span class="checkmark"></span>
            </label>
          </div>

          <div>
            <label class="amp-checkbox mb-1">
              <input type="checkbox" [(ngModel)]="filterSurveyDateStatus" />
              <span class="text">Solicitada em</span>
              <span class="checkmark"></span>
            </label>
            <input *ngIf="filterSurveyDateStatus" class="amp-input-lg" bsDaterangepicker [bsConfig]="{ containerClass: 'theme-amp' }" [(ngModel)]="filterSurveyDate" type="search" placeholder="Selecione uma data" />
          </div>
        </div>

        <div *ngIf="store.business?.referral_enabled">
          <label class="amp-checkbox">
            <input type="checkbox" [(ngModel)]="filterReferralStatus" />
            <span class="text">Programa de Indicação</span>
            <span class="checkmark"></span>
          </label>
          <div class="amp-sidebar-filter-subsec" *ngIf="filterReferralStatus">
            <div class="amp-field amp-radio-wrapper">
              <label class="amp-radio-button">
                <input type="radio" checked="checked" name="filterReferral" value="isReferrer" [(ngModel)]="filterReferral" />
                <span class="text">Participa do Programa</span>
                <span class="checkmark"></span>
              </label>
              <label class="amp-radio-button">
                <input type="radio" name="filterReferral" value="NotisReferrer" [(ngModel)]="filterReferral" />
                <span class="text">Não Participa</span>
                <span class="checkmark"></span>
              </label>
              <label class="amp-radio-button">
                <input type="radio" name="filterReferral" value="referred" [(ngModel)]="filterReferral" />
                <span class="text">Já Indicou</span>
                <span class="checkmark"></span>
              </label>
              <label class="amp-radio-button">
                <input type="radio" name="filterReferral" value="Notreferred" [(ngModel)]="filterReferral" />
                <span class="text">Não Indicou</span>
                <span class="checkmark"></span>
              </label>
              <label class="amp-radio-button">
                <input type="radio" name="filterReferral" value="referee" [(ngModel)]="filterReferral" />
                <span class="text">Foi Indicado</span>
                <span class="checkmark"></span>
              </label>
              <label class="amp-radio-button">
                <input type="radio" name="filterReferral" value="Notreferee" [(ngModel)]="filterReferral" />
                <span class="text">Não Foi Indicado</span>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="amp-sidebar-filter-section">
      <h6 class="amp-sidebar-filter-section-title">DADOS DE CONTATO</h6>
      <div class="date-options">
        <label class="amp-checkbox">
          <input type="checkbox" [(ngModel)]="filterEmailStatus" />
          <span class="text">E-mail</span>
          <span class="checkmark"></span>
        </label>
        <div class="amp-sidebar-filter-subsec" *ngIf="filterEmailStatus">
          <label class="amp-radio-button">
            <input type="radio" checked="checked" name="emailFilter" value="Email" [(ngModel)]="filterEmail" />
            <span class="text">Possui</span>
            <span class="checkmark"></span>
          </label>
          <label class="amp-radio-button">
            <input type="radio" name="emailFilter" value="notEmail" [(ngModel)]="filterEmail" />
            <span class="text">Não Possui</span>
            <span class="checkmark"></span>
          </label>
        </div>

        <label class="amp-checkbox">
          <input type="checkbox" [(ngModel)]="filterPhoneStatus" />
          <span class="text">Telefone</span>
          <span class="checkmark"></span>
        </label>
        <div class="amp-sidebar-filter-subsec" *ngIf="filterPhoneStatus">
          <label class="amp-radio-button">
            <input type="radio" checked="checked" name="phoneFilter" value="Phone" [(ngModel)]="filterPhone" />
            <span class="text">Possui</span>
            <span class="checkmark"></span>
          </label>
          <label class="amp-radio-button">
            <input type="radio" name="phoneFilter" value="notPhone" [(ngModel)]="filterPhone" />
            <span class="text">Não Possui</span>
            <span class="checkmark"></span>
          </label>
        </div>

        <label class="amp-checkbox mb-1">
          <input type="checkbox" [(ngModel)]="filterCompanyStatus">
          <span class="text">Empresa</span>
          <span class="checkmark"></span>
        </label>
      </div>

      <amp-multi-select *ngIf="filterCompanyStatus" [(value)]="companyFilter" [addOnEnter]="false" [onlyFromAutoComplete]="true" [optionsObservable]="requestCompaniesAutoComplete" primaryPlaceholder="Digite uma empresa"></amp-multi-select>
    </div>

    <div class="amp-sidebar-filter-section">
      <h6 class="amp-sidebar-filter-section-title">CAMPOS CUSTOMIZADOS</h6>
      <div class="amp-cf" *ngFor="let filter of customFieldFilters; let idx = index">
        <hr *ngIf="idx != 0" />
        <div>
          <label class="amp-label-text">Campo</label>
          <amp-select (valueChange)="onSelectCustomField($event)" [(value)]="filter.field" [options]="customFields"></amp-select>
        </div>

        <div>
          <label class="amp-label-text">Condição</label>
          <amp-select placeholder="Selecione" [(value)]="filter.condition" [options]="filterConditionOptions"></amp-select>
        </div>

        <div>
          <label class="amp-label-text">Valor</label>

          @if (filter.condition == 'contains' || filter.condition == 'not_contains') {
          <input class="amp-input" [(ngModel)]="filter.value">
          } @else {
          <amp-multi-select [maxItems]="1" [(value)]="filter.value" [addOnEnter]="false" [optionsObservable]="requestCustomFieldAutoComplete" [onlyFromAutoComplete]="true" [modelAsString]="true" primaryPlaceholder="Digite um valor"></amp-multi-select>
          }
        </div>

        <span class="amp-remove" (click)="removeFilter(idx)">Remover Filtro</span>
      </div>

      <button (click)="addCustomField()" class="amp-btn-blue200 add-custom-field-button">{{ !customFieldFilters.length ? 'Adicionar Campo Customizado' : 'Adicionar Outro Campo' }}</button>
    </div>
  </div>

  <div class="amp-sidebar-filter-footer">
    <button class="amp-btn-link" (click)="clearFilter()">Limpar Todos os Filtros</button>
    <button class="amp-btn-lg" (click)="applyFilters()">Aplicar Filtros</button>
  </div>
</div>