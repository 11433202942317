<div class="amp-dialog">
  <i class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></i>

  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Upload de Arquivos
    </div>
  </div>

  <div class="file-upload-dropzone" [ngClass]="{'dropzone-hover-effect': isDraggingOver}"
    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop()">
    <div *ngIf="!isLoading" class="dropzone">
      <input type="file" #fileDiv
        (change)="checkAndUploadFile($event.target.files[0])">
      <i class="bi-cloud-upload"></i>
      <span *ngIf="!fileName && !isDraggingOver">Arraste um arquivo aqui ou clique para buscar.</span>
      <span *ngIf="isDraggingOver">Solte o arquivo aqui!</span>
      <span *ngIf="fileName">Arquivado carregado: {{ fileName }}</span>
    </div>
    <amp-loading *ngIf="isLoading"></amp-loading>
  </div>
</div>