<div class="amp-top-bar" [ngClass]="{'red-background': review.rate < 3, 'yellow-background': review.rate >= 3 && review.rate < 4, 'blue-background': review.rate >= 4 && review.rate < 4.5, 'green-background': review.rate >= 4.5}"></div>
<div class="amp-header">
  <i (click)="closeModal()" class="bi-x-lg"></i>
</div>

<div class="amp-dialog">
  <div class="amp-upper">
    <div class="amp-left">
      <div *ngIf="review.Reviewer" class="amp-details">
        <div class="amp-first-line">
          <span>{{ review.Reviewer?.name ? review.Reviewer?.name : 'Pessoa não identificada' }}&nbsp;</span>
          <i *ngIf="review.reviewPlatform?.name">em <i class="amp-italic">{{review.reviewPlatform?.name}}</i></i>
        </div>
        <div class="amp-second-line">
          <img class="amp-logo" *ngIf="review.network != 'hotelscom' && review.network != 'expedia'"  src="../../../assets/img/{{review.network}}-icon.svg">
          <img class="amp-logo"  *ngIf="review.network == 'hotelscom' || review.network == 'expedia'" src="../../../assets/img/hotelsexpedia-icon.svg">
          <span [ngClass]="{'green-color': review.rate >= 4.5, 'red-color': review.rate < 3, 'blue-color': review.rate >= 4 && review.rate < 4.5, 'yellow-color': review.rate >= 3 && review.rate < 4}" *ngIf="review.network == 'booking' || review.network == 'hotelscom' || review.network == 'despegar'" class="amp-grade">Nota {{review.rate * 2}} de 10</span>
          <star-rating *ngIf="review.network == 'facebook' || review.network == 'google' || review.network == 'expedia' || review.network == 'tripadvisor' || review.network == 'airbnb'" class="amp-review-star-rating" [showHalfStars]="true" [starType]="'svg'" [readOnly]="true" [rating]="review.rate"> </star-rating>
          <span class="amp-date">{{review.created_at | amDateFormat: 'DD/MM/YYYY HH:mm'}}</span>
        </div>
      </div>
    </div>
    <div class="amp-right">
      <span class="amp-reply" *ngIf="!(review.reply?.length > 0)">Sem resposta</span>
      <span class="amp-reply" *ngIf="review.reply?.length > 0">Possui resposta</span>
      <span>{{review.business?.nome}}</span>
    </div>
  </div>
  <div class="amp-lower">
    <div class="amp-answers">
      <div *ngIf="loadingAISuggestion" class="amp-card-loader">
        <div class="amp-loader-bg"></div>
        <amp-loading [size]="50" *ngIf="loadingAISuggestion"></amp-loading>
      </div>

      <div class="amp-content">
        <div class="amp-comment" *ngIf="review.message?.length > 0">
          <span class="amp-title">Comentário</span>
          <span class="amp-text" innerHTML="{{review.message | ampHighlight :  filterObject['textFilter']}}"></span>
        </div>
        <div class="amp-comment" *ngIf="!review.message">
          <span class="amp-title">Comentário</span>
          <span class="amp-text">A pessoa não deixou comentários.</span>
        </div>
        <div class="amp-answer" *ngIf="!showAnswerInput && review.reply?.length > 0">
          <span class="amp-title">Resposta</span>
          <span class="amp-text">{{review.reply}}</span>
        </div>

        <div class="amp-answer amp-ia-preview" *ngIf="review.message && aiSuggestion.created_at">
          <span class="amp-title">Sugestão da AI</span>
          <span class="amp-text" *ngIf="suggestionText" id="demo">{{ suggestionText }}</span>
          <span class="amp-text" *ngIf="!suggestionText" id="demo">Houve um erro ao gerar uma resposta.</span>
          <div class="amp-ia-actions">
            <a target="_blank" (click)="copyToClipboard()">
              <i class="bi-clipboard-check"></i>
              <span>Copiar</span>
            </a>
            <div class="amp-feedback" *ngIf="!review.ai_suggestion?.feedback && !aiFeedbackSent">
              <span>A sugestão foi útil para você?</span>
              <a target="_blank" (click)="rateAI('positive')">
                <i class="bi-hand-thumbs-up"></i>
                <span>Sim</span>
              </a>
              <a target="_blank" (click)="rateAI('negative')">
                <i class="bi-hand-thumbs-down"></i>
                <span>Não</span>
              </a>
            </div>
          </div>
        </div>

        <div class="amp-answer" *ngIf="showAnswerInput">
          <div class="amp-header">
            <span class="amp-title">Resposta</span>
            <i (click)="showAnswerInput = false" class="bi-x-lg"></i>
          </div>
          <textarea [(ngModel)]="reply" class="amp-input amp-answer-input" placeholder="Ex.: Obrigado pelo comentário..." rows="4"></textarea>
          <div class="amp-buttons">
            <button (click)="getAIAnswerSuggestion()" [isDisabled]="!aiSuggestion?.created_at" [tooltip]="review.message ? 'Você já gerou uma resposta pela AI para esta pergunta' : 'AI disponível apenas em avaliações com comentários'" [ngClass]="{'disabled': aiSuggestion?.created_at}" class="amp-btn-outline-primary"><i class="bi-magic"></i>Gerar resposta com AI</button>
            <button (click)="sendAnswer()" class="amp-btn-outline-primary">Responder</button>
          </div>
        </div>

        <div *ngIf="!review.reply && !showAnswerInput && !(!(auth.userRole == 'notesonly' || auth.userRole == 'readonly' || auth.userRole == 'pdv') && !showAnswerInput && (review.network == 'google' || review.network == 'facebook'))">
          <button (click)="getAIAnswerSuggestion()" [isDisabled]="!aiSuggestion?.created_at" tooltip="Você já gerou uma resposta pela AI para esta pergunta" [ngClass]="{'disabled': aiSuggestion?.created_at}" class="amp-btn-outline-primary amp-ia"><i class="bi-magic"></i>Gerar resposta com AI</button>
        </div>
      </div>
      <div *ngIf="!(auth.userRole == 'notesonly' || auth.userRole == 'readonly' || auth.userRole == 'pdv') && !showAnswerInput && (review.network == 'google' || review.network == 'facebook') && !review.reply" class="amp-actions">
          <a target="_blank" *ngIf="review.message && !suggestionText.includes('erro')" (click)="getAIAnswerSuggestion();openAIAnswer()">
            <i class="bi-magic"></i>
            <span>Responder com AI</span>
          </a>
          <a target="_blank" (click)="answer()">
            <i class="bi-reply"></i>
            <span>Responder</span>
          </a>
        </div>
    </div>

    <div class="amp-bar" *ngIf="review.smartTags.length"></div>
    <div class="amp-right" *ngIf="review.smartTags.length">
      <div class="amp-tags">
        <span class="amp-title">Tags atribuídas</span>
        <div class="amp-tags">
          <div *ngFor="let smartTag of review.smartTags" class="amp-tag">
            {{smartTag.name}}
          </div>
    </div>
  </div>
</div>
</div>
</div>

