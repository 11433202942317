<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">Trocar de Conta</div>
    <div class="amp-dialog-subtitle">Escolha uma conta abaixo para acessar.</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-nps-buttons">
      <button class="amp-btn-success amp-btn" (click)="changeEmpresa('634d8aed998d65fea4793d14')">Conta de
        Demonstração</button>
      <button class="amp-btn-primary amp-btn" (click)="changeEmpresa('59c94ff5e5b20500126eadde')">Conta de
        Testes</button>
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Filtrar por</label>
      <amp-select [options]="options" [(value)]="typeFilter"></amp-select>
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Nome da Empresa</label>
      <input id="amp-search-input" type="search" name="search" class="amp-input" [(ngModel)]="searchField"
        placeholder="Buscar" autocomplete="off" />
    </div>

    <div class="d-flex mb-3 button-wrapper">
      <button class="amp-btn-lg amp-btn-outline-secondary" (click)="typeFilter == 'only_active' ? (typeFilter = 'all') : (typeFilter = 'only_active')">
        <div class="circle green"></div>
        <span>Ativo</span>
      </button>
      <button class="amp-btn-lg amp-btn-outline-secondary" (click)="typeFilter == 'only_trial' ? (typeFilter = 'all') : (typeFilter = 'only_trial')">
        <div class="circle yellow"></div>
        <span>Em Teste</span>
      </button>
      <button class="amp-btn-lg amp-btn-outline-secondary" (click)="typeFilter == 'canceled' ? (typeFilter = 'all') : (typeFilter = 'canceled')">
        <div class="circle red"></div>
        <span>Cancelado</span>
      </button>
    </div>

    <div class="amp-list amp-list-avatar">
      <div class="amp-option" *ngFor="let emp of filterType() | ampAdmCompany: searchField" (click)="changeEmpresa(emp._id)">
            <div placement="right" class="circle yellow" *ngIf="emp.isTrial"></div>
            <div placement="right" class="circle green" *ngIf="emp.active && (!emp.isTrial || emp.isTrial == undefined)"></div>
            <div placement="right" class="circle red" *ngIf="!emp.active && (!emp.isTrial || emp.isTrial == undefined)"></div>
          <span class="amp-option-primary">{{ emp.nome }}</span>
        <span class="bi-chevron-right"></span>
      </div>
    </div>


    @if (!list?.length) {
    <div class="amp-not-found">
      <span>NENHUM RESULTADO ENCONTRADO</span>
    </div>
    }
  </div>
</div>