import { CustomField } from './customField';
import { User } from './user';
export class Empresa {
  _id: string;
  nome: string;
  taxId: string;
  billing_mode = 'organization'; // organization, business
  contato: string;
  active: boolean;

  force_authenticator = false;
  auth_source: string;
  auth_tenant: string;
  auth_domain: string;

  street: string;
  district: string;
  state: string;
  city: string;
  zip_code: string;
  number: string;
  complement: string;

  _user: User;
  created_at: Date;
  wizard: boolean;

  _account_manager: string;
  wordcloud_badwords: string[] = [];

  isTrial: { type: boolean; default: false };
  trial_expiration_date: Date;
  zenvia_integration: boolean;
  zenvia_token: string;
  mobile21_integration: boolean;
  mobile21_token: string;
  stratusTelecom_integration: boolean;
  stratusTelecom_token: string;
  assertiva_integration: boolean;
  assertiva_token: string;
  letalk_integration: boolean;
  letalk_token: string;
  lynkmartech_integration: boolean;
  lynkmartech_url: string;
  takeblip_integration: boolean;
  takeblip_accountName: string;
  takeblip_apiKey: string;

  syngoo_integration: boolean;
  syngoo_accountName: string;
  syngoo_channel: string;
  syngoo_token: string;

  opasuite_integration: boolean;
  opasuite_accountName: string;
  opasuite_apiKey: string;
  opasuite_channelId: string;

  botmaker_integration: boolean;
  botmaker_apiKey: string;
  botmaker_channel: string;

  sicredi_whatsapp_integration: boolean;
  sicredi_whatsapp_token: string;
  sicredi_whatsapp_company: string;

  ezchat_integration: boolean;
  ezchat_service_id: string;
  ezchat_token: string;
  meta_whatsapp_integration: boolean;
  meta_whatsapp_token: string;
  meta_whatsapp_waba: string;
  meta_whatsapp_phone_id: string;

  naty_integration: boolean;
  naty_token: string;
  naty_connection_id: string;

  asksuite_integration: boolean;
  asksuite_token: string;

  zenvia_wa_integration: boolean;
  zenvia_wa_token: string;
  zenvia_wa_senderId: string;

  widechat_wa_integration: boolean;
  widechat_wa_email: string;
  widechat_wa_channel: string;
  widechat_wa_account: string;
  widechat_wa_password: string;

  twilio_wa_integration: boolean;
  twilio_wa_accountId: string;
  twilio_wa_serviceId: string;
  twilio_wa_senderNumber: string;
  twilio_wa_token: string;

  piperun_wa_integration: boolean;
  managed_twilio_wa_integration: boolean;
  managed_twilio_wa_allow_onboarding: boolean;
  managed_twilio_wa_accountId: string;
  managed_twilio_wa_serviceId: string;
  managed_twilio_wa_senderNumber: string = '';
  managed_twilio_wa_token: string;
  managed_twilio_wa_waba: string;
  managed_twilio_wa_phone_id: string;
  managed_twilio_wa_name: string;
  managed_twilio_wa_sender_id: string;

  rdconversas_wa_integration: boolean;
  rdconversas_wa_apiKey: string;

  gupshup_wa_integration: boolean;
  gupshup_wa_apiKey: string;
  gupshup_wa_source_name: string;
  gupshup_wa_source_number: string;

  whatsapp_channel_integration_credentials;

  global_customers: boolean;

  general_attributes: {
    allow_custom_email_domain: boolean;
    allow_beta: boolean;
    can_manage_balance: boolean;
    allow_ticketing: boolean;
    ai_credits: number;
    only_admin_notification: boolean;
  } = {
    allow_custom_email_domain: false,
    allow_beta: false,
    can_manage_balance: false,
    allow_ticketing: false,
    ai_credits: 0,
    only_admin_notification: false
  };

  cf_nResponses = 0;

  cf_nResponsesTest = 0;

  email_settings: {
    subdomain: string;
    domain: string;
    sender: string;
    sendgrid_id: string;
    is_dns_verified: boolean;
    custom_dkim_selector: string;
    is_inbound_created: boolean;
    is_inbound_verified: boolean;
  } = {
    subdomain: '',
    domain: '',
    sender: '',
    sendgrid_id: '',
    is_dns_verified: false,
    custom_dkim_selector: undefined,
    is_inbound_created: false,
    is_inbound_verified: false
  };

  custom_fields: CustomField[];

  _delete_scheduled_by: User;
  delete_schedule_to: Date;
}
