<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Nova Empresa
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
      <div class="row">
        <div class="col-xl-6 mx-auto" *ngIf="isNew">
          <p class="amp-guide-text"></p>
          <div class="amp-field">
            <label class="amp-label-text" for="title">Nome da Empresa</label>
            <input type="text" class="amp-input" [(ngModel)]="empresa.empresa_nome" autocomplete="off"
              #title="ngModel" name="title" title required>
          </div>
          <div class="amp-field">
            <label class="amp-label-text" for="contatoNome">Nome do Contato Principal</label>
            <input type="text" class="amp-input" [(ngModel)]="empresa.contato_nome" autocomplete="off"
              #contatoNome="ngModel" name="contatoNome" contatoNome required>
          </div>
          <div class="amp-field">
            <label class="amp-label-text" for="contatoEmail">E-mail Principal</label>
            <input type="text" class="amp-input" [(ngModel)]="empresa.contato_email" autocomplete="off"
              (keyup)="validateEmail($event)" #contatoEmail="ngModel" name="contatoEmail" contatoEmail required>
          </div>
          <div class="amp-field">
            <label class="amp-label-text" for="taxId">CNPJ</label>
            <input type="text" class="amp-input" [dropSpecialCharacters]="false" [(ngModel)]="empresa.taxId"
              autocomplete="off" mask="000.000.000-00||00.000.000/0000-00" #taxId="ngModel" name="taxId" taxId required>
          </div>
        </div>
        <div class="col-xl-6 mx-auto" *ngIf="!isNew">
          <div class="amp-field">
            <label class="amp-label-text" for="title">Nome da Empresa</label>
            <input type="text" class="amp-input" [(ngModel)]="empresa.nome" autocomplete="off" #title="ngModel"
              name="title" title required>
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Em Teste</label>
            <input id="amp-switch-free-trial" [(ngModel)]="empresa.isTrial" name="free_trial" type="checkbox"
              class="amp-switch">
            <label for="amp-switch-free-trial" class="amp-switch-label"></label>
          </div>
          <div class="amp-field" *ngIf="empresa.isTrial">
            <label class="amp-label-text" for="title">Expiração do Teste</label>
            <input type="text" [(ngModel)]="empresa.trial_expiration_date"
              placeholder="Selecione data de fim do Teste" class="form-control amp-input" name="trialDate"
              [minDate]="minDate" [bsConfig]="{containerClass: 'theme-amp', showWeekNumbers: true, isAnimated:true}"
              bsDatepicker>
          </div>
        </div>
      </div>
      <div class="amp-dialog-actions amp-dialog-actions-lg">
        <button type="submit" [disabled]="!form.form.valid" class="amp-btn-outline-primary amp-btn-lg">Salvar e
          Continuar</button>
      </div>
    </form>
  </div>
</div>