<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">Sua conta está desativada</div>
    <div class="amp-dialog-subtitle">
      O CNPJ {{ store.business.taxId }} Entre em contato através do e-mail <span class="amp-dialog-subtitle-strong">meajuda&#64;amplifique.me</span> para falar com
      o gerente da sua conta!
    </div>
  </div>

  <div class="amp-dialog-body">
    <button class="amp-btn-success amp-btn-lg" (click)="confirmar()">OK</button>
  </div>
</div>
