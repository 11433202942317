<div class="amp-dialog">
  <div class="search-bar">
    <i class="bi-search"></i>
    <input #searchFieldInput name="search" autocomplete="off" placeholder="Pesquise por uma opção..." [(ngModel)]="searchField" type="text">
  </div>

  <div #dialogOptions class="dialog-options" *ngIf="tab == 'home'">
    <div
      class="dialog-option"
      placement="auto"
      (click)="triggerAction(option)" (mouseover)="focusedOuterIndex = i"
      *ngFor="let option of options | filterPipe: { field: 'label', value: searchField }; let i = index"
      [ngClass]="{'focused': i == focusedOuterIndex}">
      <i [class]="option.icon"></i>
      {{ option.label }}
    </div>
    <div *ngIf="searchField.length && !(options | filterPipe: { field: 'label', value: searchField }).length"
      class="not-found">NENUHMA OPÇÃO ENCONTRADA</div>
  </div>

  <div #dialogOptions class="dialog-options" *ngIf="tab == 'survey-selector'">
    <div class="description">ESCOLHA UMA PESQUISA</div>
    <div class="dialog-option" (click)="selectSurvey(survey)" (mouseover)="focusedInnerIndex = i"
      *ngFor="let survey of surveys | filterPipe: { field: 'title', value: searchField }; let i = index"
      [ngClass]="{'focused': i == focusedInnerIndex}">
      <i [class]="survey.icon"></i>
      [{{ survey._business.nome }}] {{ survey.title }}
    </div>
    <div *ngIf="!loading && searchField.length && !(surveys | filterPipe: { field: 'title', value: searchField }).length"
      class="not-found">NENUHMA PESQUISA ENCONTRADA</div>
      <amp-loading [size]="50" *ngIf="loading"></amp-loading>
  </div>

  <div #dialogOptions class="dialog-options" *ngIf="tab == 'business-selector'">
    <div class="description">ESCOLHA UM CONTA</div>
    <div class="dialog-option" (click)="selectBusiness(business)" (mouseover)="focusedInnerIndex = i"
      *ngFor="let business of businesses | filterPipe: { field: 'nome', value: searchField }; let i = index"
      [ngClass]="{'focused': i == focusedInnerIndex}">
      <i [class]="business.icon"></i>
      {{ business.nome }}
    </div>
    <div *ngIf="!loading && searchField.length && !(businesses | filterPipe: { field: 'nome', value: searchField }).length"
      class="not-found">NENUHMA CONTA ENCONTRADA</div>
      <amp-loading [size]="50" *ngIf="loading"></amp-loading>
  </div>

  <div class="footer">
    <div class="hint">
      <i class="bi-arrow-up-square"></i>
      <i class="bi-arrow-down-square"></i>
      para navegar
    </div>

    <div class="hint">
      Enter para selecionar
    </div>

    <div class="hint">
      Esc para fechar
    </div>
  </div>
</div>