import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
@Injectable({ providedIn: 'root' })
export class ChatService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  private USER_ROLES = {
    acmng: 'Gerente de Conta',
    adm: 'Administrador',
    ana: 'Analista',
    own: 'Proprietário',
    pdv: 'Operador',
    readonly: 'Apenas Leitura',
    notesonly: 'Leitura e Notas',
    dashonly: 'Apenas Painel'
  };

  private isOpened = false;

  public chatLoaded = false;
  identifyUser(userData, businessData, empresaData) {
    let company = {
      company_id: empresaData._id,
      CNPJ: empresaData.taxId,
      created_at: moment(empresaData.created_at).unix(),
      name: empresaData.nome
    };
    if (userData.email.endsWith('@amplifique.me')) {
      company = {
        company_id: '5dd02d0d08ab4303c6ad128b',
        CNPJ: '22.404.676/0001-83',
        created_at: moment('2017-11-16T17:50:29.178+00:00').unix(),
        name: 'Amplifique.me'
      };
    }
    try {
      //@ts-ignore
      window.Intercom('boot', {
        custom_launcher_selector: '#intercom-chat-launcher',
        api_base: 'https://api-iam.intercom.io',
        app_id: 'ecf2audp',
        name: userData.nome,
        user_hash: userData.hash,
        email: userData.email,
        created_at: moment(userData.created_at).unix(),
        user_id: userData._id,
        phone: userData.phone,
        'Nível de Usuário': this.USER_ROLES[userData.role],
        language: 'pt-br',
        company: company
      });
      this.chatLoaded = true;
    } catch (error) {
      console.error('Failed to Identify User');
      setTimeout(() => this.identifyUser(userData, businessData, empresaData), 2000);
    }
  }

  onError(error) {
    console.error(error);
  }

  openChat() {
    try {
      if (!this.chatLoaded) {
        //@ts-ignore
        window.Intercom('boot', {
          custom_launcher_selector: '#intercom-chat-launcher',
          api_base: 'https://api-iam.intercom.io',
          app_id: 'ecf2audp',
          language: 'pt-br'
        });
      }

      //@ts-ignore
      window.Intercom('show');

      this.isOpened = true;
    } catch (error) {
      console.error(error);
    }
  }
}
