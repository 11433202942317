import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  transform(value: any, type: any): any {
    if (type == 'week') {
      return `${moment(value).startOf('week').format('DD MMMM YYYY')} - ${moment(value).endOf('week').format('DD MMMM YYYY')}`;
    } else if (type == 'semiannual') {
      return `${moment(value).startOf('month').format('MMMM YYYY')} - ${moment(value).startOf('month').add(5, 'month').endOf('month').format('MMMM YYYY')}`;
    } else if (type == 'quarter') {
      return `${moment(value).startOf('quarter').format('MMMM YYYY')} - ${moment(value).endOf('quarter').format('MMMM YYYY')}`;
    }
  }
}
