import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Tag } from 'app/_models/tag';
@Injectable()
export class TagService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getTags(_empresa: string, filter: string, type = 'customer') {
    const query = new URLSearchParams({
      filterMessage: filter,
      type: type
    });

    return this.http.get<any>(this.config.apiUrl + '/tag/empresa/' + _empresa + '?' + query.toString());
  }

  get(tagId) {
    return this.http.get<any>(this.config.apiUrl + '/tag/' + tagId);
  }

  create(_empresa: string, tag: any) {
    return this.http.post<any>(this.config.apiUrl + '/tag/' + _empresa, tag);
  }

  update(tag: Tag) {
    return this.http.put<any>(this.config.apiUrl + '/tag/' + tag._id, tag);
  }

  delete(tagId: string) {
    return this.http.delete<any>(this.config.apiUrl + '/tag/' + tagId);
  }
}
