<div class="alert-area">
  <div id="alert" class="amp-alert animate__animated animate__fadeInDown" *ngFor="let alert of store.showAlerts | alertType" [ngClass]="{'error': alert.level == 'error','warning': alert.level =='warning'}">
    <span class="amp-alert-icon bi-{{alert.icon}}"></span>
    <span class="amp-alert-message">{{alert.message}}</span>
    <div class="amp-alert-right">
      <button class="amp-btn-sm amp-btn-warning" *ngIf="alert.showSupportButton && alert.level=='warning'" (click)="openChat()">Falar com Suporte</button>
      <button class="amp-btn-sm amp-btn-danger" *ngIf="alert.showSupportButton && alert.level=='error'" (click)="openChat()">Falar com Suporte</button>
      <!-- <span class="amp-alert-close bi-x-lg" *ngIf="alert.dismissable" (click)="removeAlert(alert)"></span> -->
      <span class="amp-alert-close bi-x-lg" (click)="removeAlert(alert)"></span>
    </div>
  </div>
</div>
