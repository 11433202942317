import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[validateCpfCnpj][formControlName],[validateCpfCnpj][formControl],[validateCpfCnpj][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CpfCnpjValidator),
      multi: true
    }
  ]
})
export class CpfCnpjValidator implements Validator {
  validate(control: AbstractControl): { [key: string]: boolean } {
    if (!control.value) {
      return { cpf: true };
    }
    const replaceMask = control.value.replace(/[^0-9]+/g, '');
    if (replaceMask.length <= 11) {
      if (!this.cpfValido(control.value)) {
        return null;
      }
      return { cpf: true };
    } else {
      if (this.cnpjValido(control.value)) {
        return null;
      }
      return { cnpj: true };
    }
  }

  cnpjValido(cnpj: any) {
    cnpj = !cnpj || cnpj.replace(/\D/g, '');

    const cnpjsInvsRegex = /1{14}|2{14}|3{14}|4{14}|5{14}|6{14}|7{14}|8{14}|9{14}|0{14}/;

    if (!cnpj || cnpj.length !== 14 || cnpjsInvsRegex.test(cnpj)) {
      return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0), 10)) {
      return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    return resultado === parseInt(digitos.charAt(1), 10);
  }

  cpfValido(cpf) {
    if (cpf) {
      cpf = cpf.replace(/[^0-9]/g, '');

      let soma = 0;
      let resto: number;
      const regex = new RegExp('[0-9]{11}');

      if (
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999' ||
        !regex.test(cpf)
      )
        return { cpfIncompleto: true };
      else {
        for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(9, 10))) return { cpfInvalido: true };

        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(10, 11))) return { cpfInvalido: true };
        return null;
      }
    } else {
      return { cpfIncompleto: true };
    }
  }
}
