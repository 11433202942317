import { Empresa } from '../empresa';
import { User } from '../user';
export class SmartTag {
  _id: string;
  name: string;
  _empresa: Empresa;
  _user: User;
  created_at: Date;
  updated_at: Date;
  keywords: string[] = [];
}
