import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService, StoreService } from 'app/_services';
@Pipe({
  name: 'lgpd'
})
export class LgpdPipe implements PipeTransform {
  constructor(public authService: AuthenticationService, public store: StoreService) {}

  transform(value: any): any {
    if (value) {
      if (this.store.presentationMode) {
        return value.substring(0, value.length > 4 ? 4 : 1) + value.substring(value.length > 4 ? 4 : 1, value.length).replaceAll(/[A-Za-z0-9]/g, '*');
      }
    }
    return value;
  }
}
