import { Component } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-export-survey-dialog',
  templateUrl: './export-survey-dialog.component.html',
  styleUrls: ['./export-survey-dialog.component.scss']
})
export class ExportSurveyDialogComponent {
  constructor(public bsModalRef: BsModalRef, public store: StoreService) {}

  public result: Subject<any> = new Subject();

  export(method) {
    this.result.next({ type: method });
    this.bsModalRef.hide();
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
