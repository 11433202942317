<div class="amp-referral-widget-wrapper" [ngClass]="{'amp-referral-milestone-wrapper': type=='milestone', 'dark-mode': campaign.dark_mode}">
  <div class="widget-header" *ngIf="type!='milestone'" [ngStyle]="{'background': color, 'color':textColor}">
    <div class="amp-campaign-title">{{campaign.title}}</div>
  </div>

  <div class="amp-tabs-wrapper">
    <div class="amp-tab" [ngStyle]="{'display': type=='milestone' ? 'none' : 'block' }" #tab1>
      <div class="amp-info-tab">
        <div class="amp-markdown" [innerHtml]="campaign.content_about | showdown"></div>
        <div class="btn-small-wrapper">
          <button class="amp-btn-small" type="button" (click)="teste(tab2,2)" [ngStyle]="{'color': textColor, 'background':color,'border-color':color}">Começar a indicar <span class="bi-share"></span></button>
        </div>
      </div>
    </div>

    <div class="amp-tab" #tab2>

      <div class="amp-thank-you-preview" *ngIf="campaign.preview == 'thankyou'">
        <span class="bi-check-circle-fill" [ngStyle]="{'color': color}"></span>
        <showdown class="amp-markdown" [value]="campaign.referee_thankyou_message"></showdown>
        <button *ngIf="!campaign.referee_can_refer && campaign.redirect_after_referral" class="amp-btn-small amp-redirect-button" [ngStyle]="{'color': textColor, 'background':color,'border-color':color}">
          continuar
          <span class="bi-chevron-right"></span>
        </button>
      </div>

      <div *ngIf="campaign.preview == 'authentication'">
        <div>
          <div class="amp-markdown">
            <showdown class="amp-markdown" [value]="campaign.description"></showdown>
          </div>
          <form>
            <div class="amp-field" *ngIf="campaign.verification_mode=='sms'">
              <input type="tel" class="amp-input-lg" placeholder="Seu celular" name="phone" />
              <span class="amp-field-icon bi-phone"></span>
            </div>
            <div class="amp-field" *ngIf="campaign.verification_mode=='email' || campaign.verification_mode=='none'">
              <input type="email" class="amp-input-lg" placeholder="E-mail" name="email" autocomplete="on" />
              <span class="amp-field-icon bi-envelope"></span>
            </div>
            <div class="lgpd">
              <span class="bi-info-circle-fill"></span>
              <div class="lgpd-content">Utilizamos a Amplifique.me para gerenciar nosso programa de indicação. Clicando abaixo você consente que os dados serão transferidos para a Amplifique.me para serem processados de acordo com os <a target="_blank" rel="noopener" href="https://amplifique.me/termos-de-uso">Termos de Uso</a> e <a target="_blank" rel="noopener" href="https://amplifique.me/politica-de-privacidade">Política de Privacidade</a>.
              </div>
            </div>
            <div class="btn-small-wrapper">
              <button class="amp-btn-small" type="submit" [ngStyle]="{'color': textColor, 'background':color,'border-color':color}">
                Próximo
                <span class="bi-chevron-right"></span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <div *ngIf="!campaign.direct_referral && campaign.preview == 'reffer'">
        <showdown class="amp-markdown" [value]="campaign.content_refer"></showdown>
        <div class="amp-share-section">
          <div class="amp-share-btns">
            <div class="amp-share-btns-row">
              <button type="button" class="amp-btn-small amp-referral-share-btn-wa">
                <span class="amp-referral-share-icon">
                  <img src="./assets/img/wa-share-logo.svg">
                </span>
                WhatsApp
              </button>
              <button type="button" class="amp-btn-small amp-referral-share-btn-fb">
                <span class="amp-referral-share-icon">
                  <img src="./assets/img/fb-share-logo.svg">
                </span>
                Facebook
              </button>
            </div>
            <div class="amp-share-btns-row">
              <button type="button" class="amp-btn-small amp-referral-share-btn-tt">
                <span class="amp-referral-share-icon">
                  <img src="./assets/img/tt-share-logo.svg">
                </span>
                Twitter
              </button>
              <button type="button" class="amp-btn-small amp-referral-share-btn-in">
                <span class="amp-referral-share-icon">
                  <img src="./assets/img/in-share-logo.svg">
                </span>
                LinkedIn
              </button>
              <button _ngcontent-hdd-c120="" type="button" class="amp-btn-small amp-referral-share-btn-in">
                <span _ngcontent-hdd-c120="" class="bi-share"></span>
                Outros
              </button>
            </div>
          </div>
          <div class="amp-markdown amp-share-section-text">
            <p>ou, você pode copiar o link abaixo!</p>
          </div>
          <div class="amp-field amp-share-copy-field-wrapper">
            <input disabled class="amp-input-lg amp-share-copy-field" [(ngModel)]="shareUrl" />
            <button  type="button" class="amp-btn-share-copy">Copiar<span class="bi-files"></span></button>
          </div>
        </div>
        <div class="amp-markdown amp-share-section-text">
          <p>33 pessoas clicaram no seu link</p>
        </div>
      </div>

      <div *ngIf="campaign.direct_referral && campaign.preview == 'reffer'">
        <showdown class="amp-markdown" [value]="campaign.content_refer"></showdown>
        <div class="amp-share-section">
          <div class="amp-field">
            <div class="amp-observation" *ngIf="campaign.customer_form_fields.length == 0">* selecione os campos do formulário!</div>
            <input *ngIf="campaign.customer_form_fields.indexOf('name') > -1" type="text" placeholder="Nome" class="amp-input-lg">
            <input *ngIf="campaign.customer_form_fields.indexOf('phone') > -1" type="tel" placeholder="Celular com DDD" class="amp-input-lg">
            <div *ngFor="let customField of campaign.custom_fields_referee">
              <input class="amp-input-lg" type="text" [placeholder]="getCustomField(customField)?.placeholder">
            </div>
            <button type="button" class="amp-btn amp-btn-reffer" [ngStyle]="{'background-color': campaign.primary_color,'color':campaign.text_color}">Indicar</button>
          </div>
        </div>
      </div>

      <div *ngIf="campaign.preview == 'reffer'" class="amp-share-counter-section">
        <div class="amp-share-counter">
          <div class="amp-share-counter-number">
            7
          </div>
          <span class="amp-share-counter-text">
            Indicações Pendentes
          </span>
        </div>
        <div class="amp-share-counter">
          <div class="amp-share-counter-number">
            12
          </div>
          <span class="amp-share-counter-text">
            Indicações Confirmadas
          </span>
        </div>
      </div>
    </div>

    <div class="amp-tab" [ngStyle]="{'display': type == 'milestone' ? 'none' : 'block' }" #tab3>
      <showdown class="amp-markdown" [value]="campaign.content_coupons"></showdown>
      <div class="amp-coupon">
        <div class="amp-collapse amp-expanded">
          <div class="amp-coupon-collapse">
            <h2 class="amp-coupon-name">{{campaign.reward_referrer.name}}</h2>
            <h4 class="amp-coupon-description">Recompensa por indicar {{userName.split(' ')[0]}}</h4>
          </div>
          <div class="amp-coupon-expand expanded">
            <p class="amp-coupon-instruction">{{campaign.reward_referrer.instructions}}</p>
            <p class="amp-coupon-valid">VALIDO ATÉ 25/12/2019</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="widget-tab-selectors" *ngIf="type!='milestone'" [ngStyle]="{'background': color, 'color':textColor}">
    <div class="widget-tab-selector" (click)="teste(tab1,1)" [ngClass]="{'active': tab==1}">
      <span class="widget-tab-icon bi-lightbulb"></span>
      <span class="widget-tab-name">Como Funciona?</span>
    </div>
    <div class="widget-tab-selector" (click)="teste(tab2,2)" [ngClass]="{'active': tab==2}">
      <span class="widget-tab-icon bi-share"></span>
      <span class="widget-tab-name">Indicar Amigos</span>
    </div>
    <div class="widget-tab-selector" (click)="teste(tab3,3)" [ngClass]="{'active': tab==3}">
      <span class="widget-tab-icon bi-bookmark"></span>
      <span class="widget-tab-name">Meus Cupons</span>
    </div>
  </div>
</div>
