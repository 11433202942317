import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { Review } from '../_models/review';
@Injectable()
export class ReviewService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getReviews(_business: string, limit: number, page: number, filter: string, rating: any, date: any, networks: any, replyFilter: any, exports = false) {
    // @ts-ignore
    const query = new URLSearchParams({
      limit: limit,
      page: page,
      filterMessage: filter,
      rateMin: rating.min,
      rateMax: rating.max,
      dateMin: date.min,
      dateMax: date.max,
      networks: networks,
      replyFilter,
      exports: exports
    });

    return this.http.get<any>(this.config.apiUrl + '/review/getReviews/' + _business + '?' + query.toString());
  }

  getReview(_review: string) {
    return this.http.get<any>(this.config.apiUrl + '/review/' + _review);
  }

  getReviewsFilterResults(_business: string, filter: string, rating: any, date: any, networks: any) {
    const query = new URLSearchParams({
      filterMessage: filter,
      rateMin: rating.min,
      rateMax: rating.max,
      dateMin: date.min,
      dateMax: date.max,
      networks: networks
    });
    return this.http.get<number>(this.config.apiUrl + '/review/getReviewsFilterResults/' + _business + '?' + query.toString());
  }

  getAverage(_business: string, startDate, endDate) {
    return this.http.post<any>(this.config.apiUrl + '/review/getStats/' + _business, { startDate, endDate });
  }

  flag(_id: string) {
    return this.http.post<Review>(this.config.apiUrl + '/review/flag/' + _id, {});
  }

  reply(review: Review) {
    return this.http.post<Review>(this.config.apiUrl + '/review/' + review._id + '/reply', review);
  }

  deleteReply(review: Review) {
    return this.http.delete<Review>(this.config.apiUrl + '/review/' + review._id + '/reply');
  }

  chartDataOverTime(_business: string) {
    return this.http.get<any>(this.config.apiUrl + '/review/graph/overtime/' + _business);
  }

  chartDataOverTimeReviewAndLinks(_business: string) {
    return this.http.get<any>(this.config.apiUrl + '/review/graph/overtimeReviewsLink/' + _business);
  }

  chartDataDayOfWeek(_business: string) {
    return this.http.get<any>(this.config.apiUrl + '/review/graph/dayOfWeek/' + _business);
  }

  getSentimentals(_business: string) {
    return this.http.get<any>(this.config.apiUrl + '/review/sentiment/' + _business);
  }

  setCustomer(_review, customerId) {
    return this.http.post<any>(this.config.apiUrl + '/review/' + _review + '/customer', { _customer: customerId });
  }

  unsetCustomer(_review) {
    return this.http.delete<any>(this.config.apiUrl + '/review/' + _review + '/customer');
  }

  timeReport(_business: string, startDate, endDate, granularity) {
    return this.http.post<any>(this.config.apiUrl + '/review/graph/timereport/' + _business, { startDate, endDate, granularity });
  }

  scoreDistribution(_business: string, startDate, endDate) {
    return this.http.post<any>(this.config.apiUrl + '/review/scoreDistribution/' + _business, { startDate, endDate });
  }

  getSmartTagsChart(businessId: string) {
    return this.http.get<any>(this.config.apiUrl + `/review/smart-tags-chart/${businessId}`);
  }

  aiFeedback(reviewId, feedback) {
    return this.http.put<any>(this.config.apiUrl + `/review/${reviewId}/ai-feedback`, { feedback });
  }
}
