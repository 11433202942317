import { CompanyDialogComponent } from 'app/contatos/company-dialog/company-dialog.component';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { CustomerService } from 'app/_services/customer.service';
import { parse, format, isValidNumber } from 'libphonenumber-js';
import { StoreService } from './../../_services/store.service';
import { CompanyService } from 'app/_services/company.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Customer } from 'app/_models/customer';
import { NotifyService } from 'app/_services';
import { map, Observable } from 'rxjs';
import moment from 'moment';
@Component({
  selector: 'amp-contact-editor',
  templateUrl: './contact-editor.component.html',
  styleUrls: ['./contact-editor.component.scss']
})
export class ContactEditorComponent implements OnInit, OnDestroy {
  @Output('close') public closeEvent: EventEmitter<any> = new EventEmitter();
  @Input() public show;

  public maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public maskCpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public maskCnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public maskTel = ['+', 5, 5, ' ', '(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public subscriptions = [];

  public editName = false;
  public editMail = false;
  public editTel = false;
  public editCompany = false;
  public companySelector = false;
  public isLoading = true;
  public editCustomFieldIndex = -1;

  public companies = [];

  public actions = [
    {
      name: 'createCompany',
      label: 'Criar Empresa',
      icon: 'bi-plus-lg'
    },
    {
      name: 'removeCompany',
      label: 'Remover Empresa',
      icon: 'bi-trash3'
    }
  ];

  public customer: Customer;
  Object = Object;

  public callback;

  constructor(
    private customerService: CustomerService,
    private notifyService: NotifyService,
    private companyService: CompanyService,
    private modalService: BsModalService,
    public store: StoreService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.show = null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  toggleCompanySelector() {
    this.companySelector = !this.companySelector;
  }

  setCallback(callback) {
    this.callback = callback;
  }

  onActionClick(name) {
    if (name == 'createCompany') {
      this.openCreateCompanyDialog();
    }

    if (name == 'removeCompany') {
      this.removeCompany();
    }
  }

  removeCompany() {
    this.customer._company = null;
  }

  openCreateCompanyDialog() {
    this.modalService.show(CompanyDialogComponent, { class: 'amp-modal' });
  }

  requestCompaniesAutoComplete = (text): Observable<any> => {
    return this.companyService.getCompanies(this.store.empresa, text).pipe(
      map((companies) =>
        companies.map((company) => {
          return { value: company._id, label: company.name };
        })
      )
    );
  };

  getCompanyName(company) {
    if (company) {
      if (company.name) {
        return company.name;
      }

      if (company.label) {
        return company.label;
      }

      return '';
    }
  }

  setCustomer(customerId) {
    this.subscriptions.push(
      this.customerService.getCustomer(customerId).subscribe((data) => {
        this.customer = data.customer;
        this.isLoading = false;
      })
    );
  }

  removeSpaces(evt) {
    evt.srcElement.value = evt.srcElement.value.replace(' ', '');
  }

  onClose() {
    this.closeEvent.emit();
    this.callback = null;
    this.isLoading = true;
  }

  executeSave() {
    this.subscriptions.push(
      this.customerService.update(this.customer).subscribe(
        () => {
          this.subscriptions.push(
            this.translate.get('notify.customer.updated').subscribe((res) => {
              this.notifyService.success(res);
            })
          );
          if (this.callback) {
            this.callback(this.customer);
          }
          this.onClose();
        },
        (error) => {
          console.error(error);
          if (error.status === 409) {
            this.notifyService.error(
              `Não foi possível atualizar o contato, dados duplicados: <a target="_blank" rel="noreferrer" href="contatos/${error.error.customer._id}">Ver Contato</a>`
            );
          }
        }
      )
    );
  }

  save() {
    if (this.checkIsValid()) {
      if (this.customer.tel == null && this.customer.email == null) {
        this.subscriptions.push(
          this.translate.get('notify.contato.warning_contact_data').subscribe((res) => {
            this.notifyService.warning(res);
          })
        );
      } else {
        if (this.customer.tel) {
          if (isValidNumber(this.customer.tel)) {
            this.customer.tel = format(parse(this.customer.tel), 'E.164');
            this.executeSave();
          } else {
            const tempPhone = parse('+55' + this.customer.tel);
            if (isValidNumber(tempPhone)) {
              this.customer.tel = format(tempPhone, 'E.164');
              this.executeSave();
            } else {
              this.customer.tel = null;
              this.notifyService.warning('Telefone Inválido');
            }
          }
        } else {
          this.executeSave();
        }
      }
    }
  }

  _validateCpf(cpf) {
    if (cpf) {
      cpf = cpf.replace(/[^0-9]/g, '');
      let soma = 0;
      let resto: number;
      const regex = new RegExp('[0-9]{11}');
      if (
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999' ||
        !regex.test(cpf)
      )
        return false;
      else {
        for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(9, 10))) return false;

        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(10, 11))) return false;
        return true;
      }
    } else {
      return false;
    }
  }

  _validateCnpj(cnpj) {
    cnpj = !cnpj || cnpj.replace(/\D/g, '');

    const cnpjsInvsRegex = /1{14}|2{14}|3{14}|4{14}|5{14}|6{14}|7{14}|8{14}|9{14}|0{14}/;

    if (!cnpj || cnpj.length !== 14 || cnpjsInvsRegex.test(cnpj)) {
      return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0), 10)) {
      return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    return resultado === parseInt(digitos.charAt(1), 10);
  }

  checkIsValid() {
    if (this.customer.custom_fields) {
      const fields = Object.keys(this.customer.custom_fields);
      for (const field of fields) {
        const customFieldIndex = this.store.custom_fields.findIndex((item) => item.internal_name == field);
        if (customFieldIndex > -1) {
          const customField = this.store.custom_fields[customFieldIndex];
          if (customField.type == 'cpf') {
            if (!this._validateCpf(this.customer.custom_fields[field])) {
              this.editCustomFieldIndex = customFieldIndex;
              this.notifyService.error(`${customField.label} é inválido`);
              return false;
            }
          } else if (customField.type == 'date') {
            if (!moment(this.customer.custom_fields[field], 'DD/MM/YYYY').isValid()) {
              this.editCustomFieldIndex = customFieldIndex;
              this.notifyService.error(`${customField.label} é inválido(a)`);
              return false;
            }
          } else if (customField.type == 'cnpj') {
            if (!this._validateCnpj(this.customer.custom_fields[field])) {
              this.editCustomFieldIndex = customFieldIndex;
              this.notifyService.error(`${customField.label} é inválido`);
              return false;
            }
          }
        }
      }
      return true;
    } else {
      this.customer.custom_fields = {};
      return true;
    }
  }
}
