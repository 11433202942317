<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-title">Filtrar Resultados do Card
        <i class="bi-question-circle-fill" [popover]="infoTooltip" placement="auto" triggers="mouseenter:mouseleave" containerClass="amp-popover-thin"></i>
      </span>
    </div>

    <ng-template #infoTooltip>
      <div>
        <b>Todos os critérios:</b> Essa condição é como se fosse um "e", que exibe apenas os registros que atendem a todos os critérios especificados. Essa opção permite que você refine ainda mais sua busca e exiba apenas os dados mais relevantes para a análise que está realizando. Por exemplo, se você está analisando apenas as respostas de um determinado contato/empresa, pode escolher exibir apenas as respostas de um determinado contato.
        <br>
        <b>Ponto importante:</b> Não aplique dois filtros iguais no mesmo campo, Exemplo: Filtro empresa do contato é X, e mais um filtro Empresa do Contato é Y. Nesse caso, o filtro retornará 0 resultados.
        <br><br>
        <b>Qualquer critério:</b> Essa condição é como se fosse um "ou", que exibe registros que atendem a pelo menos um dos critérios especificados. Essa opção é útil quando se deseja exibir uma lista mais abrangente de registros que atendam a uma variedade de critérios. Por exemplo, se você está analisando as respostas e deseja exibir apenas aqueles que compraram produtos na última semana ou que têm mais de 50 anos, pode aplicar um filtro com a condição "ou". Nesse caso você verá tanto quem comprou na última semana, como quem tem mais de 50 anos também.
        <br>
        <b>Ponto importante:</b> Nesse caso você pode aplicar o filtro no mesmo campo. Exemplo: Filtro empresa do Contato é X, e mais um filtro Empresa do Contato é Y. Nesse caso, o filtro retornará os resultados de ambas as empresas.
      </div>
    </ng-template>

    <div class="amp-dialog-subtitle">
      <span>Abaixo, é possível incluir filtros nos resultados do card, de modo que só sejam consideradas as respostas ou respondentes que cumprirem os filtros
        determinados.</span>
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-field" *ngIf="origin == 'panel'">
      <label class="amp-label-text">Nome do Filtro</label>
      <input placeholder="Ex: Filtro de Gênero" [(ngModel)]="card.name" class="amp-input" />
    </div>

    <div class="amp-toggle-filter">
      <label class="amp-label-text">Aplicar filtros quando</label>
      <div class="ampl-btn-group">
        <button [ngClass]="{ active: card.filter_any == true }" (click)="card.filter_any = true" class="amp-btn">Qualquer critério for atingido</button>
        <button [ngClass]="{ active: card.filter_any == false }" (click)="card.filter_any = false" class="amp-btn">Todos os critérios forem atingidos</button>
      </div>
    </div>

    <div *ngFor="let filter of card.filters; let idx = index" class="amp-filter">
      <amp-select placeholder="Tipo" [options]="filterTypeOptions" [(value)]="filter.type" (valueChange)="clearInputs(idx); filterConditions(filter)"></amp-select>

      <amp-select *ngIf="filter.type == 'survey_question'" [options]="surveyQuestionsOptions" [(value)]="filter.field" (valueChange)="selectedAnswerInternalName = filter.field; filter.value = ''" placeholder="Pergunta"></amp-select>

      <amp-select *ngIf="filter.type == 'customer_customField' || filter.type == 'answer_customField'" placeholder="Campo" [options]="customFieldsOptions" [(value)]="filter.field" (valueChange)="selectedCustomField = filter.field; filter.value = ''"></amp-select>

      <amp-select *ngIf="filter.type == 'customer_tag'" placeholder="Condição" [(value)]="filter.condition" [options]="conditionOptionsContainsOnly"></amp-select>

      <amp-select *ngIf="filter.type == 'customer_company' || filter.type == 'survey_question'" placeholder="Condição" [(value)]="filter.condition" [options]="conditionOptionsEqualityOnly"></amp-select>

      <amp-select placeholder="Condição" *ngIf="filter.type == 'origin'" [(value)]="filter.condition" [options]="conditionsOptions"></amp-select>

      <amp-select placeholder="Condição" *ngIf="filter.type == 'customer_customField' || filter.type =='answer_customField'" [(value)]="filter.condition" [options]="conditionsOptions"></amp-select>

      <amp-select *ngIf="filter.type == 'customer_tag'" [options]="tags" [isSearchable]="true" [(value)]="filter.value" placeholder="Pesquisar" [optionsObservable]="tagsAutoComplete"></amp-select>

      <amp-select [isSearchable]="true" [options]="companies" [optionsObservable]="companiesAutoComplete" [(value)]="filter.value" *ngIf="filter.type == 'customer_company'" placeholder="Pesquise"></amp-select>

      <input *ngIf="(filter.type == 'origin' || filter.type == 'customer_customField' || filter.type == 'answer_customField') && (filter.condition == 'contains' || filter.condition == 'not_contains')" placeholder="Ex.: Atendimento" [(ngModel)]="filter.value" class="amp-input" />

      <amp-select placeholder="Pesquisar" [isSearchable]="true" [optionsObservable]="originsAutoComplete" [(value)]="filter.value" *ngIf="filter.type == 'origin' && (filter.condition == 'equals' || filter.condition == 'not_equals')"></amp-select>

      <amp-select (click)="selectedCustomField = filter.field" [isSearchable]="true" placeholder="Pesquisar" [optionsObservable]="customFieldValuesAutoComplete" [(value)]="filter.value" *ngIf="(filter.condition == 'equals' || filter.condition == 'not_equals') && (filter.type == 'customer_customField' || filter.type == 'answer_customField')"></amp-select>

      <amp-select placeholder="Pesquisar" [isSearchable]="true" [optionsObservable]="answersAutoComplete" [(value)]="filter.value" *ngIf="filter.type == 'survey_question'"></amp-select>

      <i (click)="removeFilter(idx)" tooltip="Remover Filtro" class="bi-dash-circle"></i>
    </div>

    <button (click)="addNewFilter()" class="amp-add-condition">Adicionar Novo Filtro</button>
  </div>

  <div class="amp-dialog-footer">
    <button (click)="deleteAllFilters()" class="amp-btn-danger">Excluir filtros</button>
    <div class="amp-right-buttons">
      <button (click)="hide()" class="amp-btn-secondary">Cancelar</button>
      <button [disabled]="!card.name.length" (click)="saveFilters()" class="amp-btn-success">Salvar</button>
    </div>
  </div>
</div>

<ng-template #customerCustomFieldPopover let-item="item">
  <span>{{ item | json}}</span>
</ng-template>