<span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>

<div class="title">{{ title }}</div>
<div class="subtitle">{{ subtitle }}</div>

<div class="content">
  <div class="amp-table">
    <div class="amp-table-head header">
      <div class="amp-table-column">País</div>
      <div class="amp-table-column">Preço</div>
    </div>
    <div class="amp-table-row body" *ngFor="let value of values">
      <div class="amp-table-cell">{{ value.name_pt }}</div>
      <div class="amp-table-cell">{{ value.value | currency:'BRL' }}</div>
    </div>
  </div>
</div>