import { Business } from './business';
import { User } from './user';
export class WebhookEndpoint {
  _id: string;
  description = '';
  endpoint_url = '';
  error_count = 0;
  enabled_events: string[] = [];
  secret = '';
  active = true;
  type = 'default';
  email: string;
  created_at: Date;
  _user: User;
  _business: Business | string;
}
