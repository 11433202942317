import { NotifyService, EmpresaService } from 'app/_services';
import { MenuService } from 'app/_services/menu.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services/store.service';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import moment from 'moment';
import { Empresa } from 'app/_models/empresa';
@Component({
  selector: 'app-admin-empresa-schedule-deletion-dialog',
  templateUrl: './schedule-deletion.component.html',
  styleUrls: ['./schedule-deletion.component.scss']
})
export class EmpresaScheduleDeletionComponent implements OnInit, OnDestroy {
  public minDate = moment().add(1, 'd').toDate();
  public empresa = new Empresa();
  public alreadyScheduled = false;
  public maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public subscriptions = [];

  constructor(
    public bsModalRef: BsModalRef,
    private menuService: MenuService,
    private empresaService: EmpresaService,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    public store: StoreService
  ) {
    this.menuService.emit(true);
  }

  ngOnInit() {
    if (this.empresa.delete_schedule_to) {
      this.empresa.delete_schedule_to = moment(this.empresa.trial_expiration_date).toDate();
      this.alreadyScheduled = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  fmSubmit() {
    const initialState = {
      title: 'Remover Empresa',
      message: 'Tem certeza que deseja agendar a exclusão da empresa ',
      strongText: this.empresa?.nome,
      messageEnd: ' e todos os seus dados?',
      forceConfirmation: true
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.empresaService.scheduleDeletion(this.empresa._id, this.empresa.delete_schedule_to).subscribe(
              () => {
                this.notifyService.success('Exclusão agendada com sucesso!');

                this.bsModalRef.hide();
              },
              (error) => {
                console.error(error);
                this.notifyService.error('Não foi possível agendar a exclusão!');
              }
            )
          );
        }
      })
    );
  }

  cancelSchedule() {
    this.empresa.delete_schedule_to = null;
    this.empresa._delete_scheduled_by = null;
    this.subscriptions.push(
      this.empresaService.update(this.empresa).subscribe(
        () => {
          this.notifyService.success('Exclusão programada cancelada!');
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Não foi possível cancelar a exclusão!');
        }
      )
    );
    this.bsModalRef.hide();
  }
}
