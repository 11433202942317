<div class="amp-nps-bar-wrapper">
  <div class="amp-nps-bar" [tooltip]="isTopic ? topicsTemplate : tooltipTemplate" placement="auto" [isDisabled]="!showTooltip" [ngClass]="{'show-zero-values': showZeroValues}">
    <div
      class="amp-nps-bar-indicator detractor"
      [ngStyle]="{
        width: (!allZero ? (detractorPercentage * 100) : 100/3) + '%'
      }"
    >

      <span class="amp-nps-bar-value" *ngIf="showValues">{{ detractor }}</span>
      <span class="amp-nps-bar-percentage" *ngIf="showValues">{{ detractorPercentage | percent}}</span>
    </div>
    <div *ngIf="neutral != -1"
      class="amp-nps-bar-indicator neutral"
      [ngStyle]="{
        width: (!allZero ? (neutralPercentage * 100) : 100/3) + '%'
      }"
    >

      <span class="amp-nps-bar-value" *ngIf="showValues">{{ neutral }}</span>
      <span class="amp-nps-bar-percentage" *ngIf="showValues">{{ neutralPercentage | percent }}</span>
    </div>
    <div
      class="amp-nps-bar-indicator promoter"
      [ngStyle]="{
        width: (!allZero ? (promoterPercentage * 100) : 100/3) + '%'
      }"
      >

      <span class="amp-nps-bar-value" *ngIf="showValues">{{ promoter }}</span>
      <span class="amp-nps-bar-percentage"*ngIf="showValues">{{ promoterPercentage | percent }}</span>
    </div>
  </div>
</div>

<ng-template #tooltipTemplate>
  <span>Promotores: {{ promoter }}</span>
  <br>
  <span>Neutros: {{ neutral }}</span>
  <br>
  <span>Detratores: {{ detractor }}</span>
</ng-template>

<ng-template #topicsTemplate>
  <span>Positivos: {{ promoter }}</span>
  <br>
  <span>Neutros: {{ neutral }}</span>
  <br>
  <span>Negativos: {{ detractor }}</span>
</ng-template>
