import { Component, Input } from '@angular/core';
@Component({
  selector: 'amp-circle-image-display',
  templateUrl: 'amp-circle-image-display.component.html',
  styleUrls: ['amp-circle-image-display.component.scss']
})
export class AmpCircleImageDisplayComponent {
  @Input()
  public circlesNumber = 4;

  @Input()
  public showTooltip = true;

  @Input()
  public showPopover = true;

  @Input()
  public cursor = 'pointer';

  @Input()
  public circles = [];
}
