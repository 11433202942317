<section id="login" class="row">
  <div class="col login-card-wrapper">
    <img src="/assets/img/logo_amplifiqueme.svg">
    <div class="amp-card login__card">
      <div class="amp-card-content">
        <div *ngIf="!forgotPassword && !authenticator" class="animate__animated" [ngClass]="{'animate__shake': fail,'animate__fadeIn': !fail && !forgotPassword}">
          <h3 class="amp-title text-center">Fazer login na Amplifique.me</h3>
          <p class="amp-subtitle">Entre ou crie sua conta</p>
          <form (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
            <div class="amp-field amp-email">
              <input type="email" class="amp-input-lg lowercase" (keyup)="checkDot()" [(ngModel)]="model.email" [placeholder]="'E-mail'" #email="ngModel" autocomplete="username" name="email" email required>
              <i class="bi-envelope"></i>
            </div>
            <div class="amp-warning-message" *ngIf="(email.invalid && (email.dirty || email.touched)) || (email.touched && !hasDot)">
              <span *ngIf="email.errors?.required">
                O campo e-mail é obrigatório.
              </span>
              <span *ngIf="email.errors?.email">
                Por favor, insira um e-mail válido.
              </span>
            </div>
            <div class="amp-field amp-password">
              <input type="password" id="password" class="amp-input-lg" [placeholder]="'Senha'" autocomplete="current-password" [(ngModel)]="model.senha" #senha="ngModel" name="senha" required />
              <i *ngIf="showPassword" (click)="togglePassword()" class="bi-eye"></i>
              <i *ngIf="!showPassword" (click)="togglePassword()" class="bi-eye-slash"></i>
            </div>

            <label class="amp-checkbox">
              <div>
                <input type="checkbox" [(ngModel)]="model.keepMeLoggedIn" name="keepMeLoggedIn">
                <span class="text">Manter conectado</span>
                <span class="checkmark"></span>
              </div>
            </label>

            <button class="amp-btn-lg btn-enter">
              <span *ngIf="!loading">Entrar</span>
              <i *ngIf="loading" class="loader"></i>
            </button>

            <div class="divider">
              <div class="line"></div>
              <div class="text">OU</div>
              <div class="line"></div>
            </div>

            <button (click)="loginWithMicrosoft()" class="amp-btn-lg amp-btn-outline-primary">
              <span>Entrar com Microsoft</span>
            </button>
          </form>
          
          <label class="amp-label-text">
            <a tabindex="-1" class="amp-btn-link amp-btn-sm amp-forgot-password blue-color" (click)="!forgotPassword ? forgotPassword=1 : forgotPassword=0; fail=0">Esqueci minha senha!</a>
          </label>
        </div>

        <div class="animate__animated" *ngIf="!forgotPassword && authenticator">
          <h3 class="amp-title text-center">Fazer login na Amplifique.me</h3>
          <p class="amp-subtitle">Entre ou crie sua conta</p>

          <form (ngSubmit)="f.form.valid && validateAuthenticator()" #f="ngForm" novalidate>
            <div class="amp-field amp-onetime-code-field">
              <label class="amp-label-text">Código de 6 dígitos do seu autenticador</label>
              <input type="text" inputmode="numeric" class="amp-input-lg amp-onetime-code-input" [readonly]="this.loading" (ngModelChange)="changeToken($event)" [(ngModel)]="token" #otp="ngModel" autocomplete="one-time-code" name="token" pattern="[0-9][0-9][0-9][0-9][0-9][0-9]" mask="000000" maxlength="6" required placeholder="" />
              <span class="amp-field-icon bi-lock"></span>
            </div>

            <button class="amp-btn-lg btn-enter">
              <span *ngIf="!loading">Entrar</span>
              <i *ngIf="loading" class="loader"></i>
            </button>
           </form>
        </div>

        <div class="animate__animated" *ngIf="forgotPassword" [ngClass]="{'animate__shake': fail && forgotPassword, 'animate__fadeIn': !fail && forgotPassword}">
          <h3 class="text-center">Recuperar Senha</h3>
          <form (ngSubmit)="f2.form.valid && esqueciSenha()" #f2="ngForm" novalidate>
            <p class="amp-subtitle">Insira o e-mail cadastrado em sua conta.</p>
            <div class="amp-field amp-email-recover">
              <input type="email" class="amp-input-lg lowercase" placeholder="Seu e-mail" [(ngModel)]="fp.email" #email="ngModel" name="email" email required />
              <i class="bi-envelope"></i>
            </div>
            <div class="login__card__button-wrapper">
              <button class="amp-btn-lg">Recuperar Senha</button>
              <button class="amp-btn-link" (click)="!forgotPassword ? forgotPassword=1 : forgotPassword=0; fail=0">Voltar para o login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="amp-help">
      Precisa de ajuda? <a (click)="openChat()" class="blue-color">Fale com a gente!</a>
    </div>
  </div>
</section>
