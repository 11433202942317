import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChatService } from 'app/_services/chat.service';
@Component({
  selector: 'app-error-auth-dialog',
  templateUrl: './error-auth-dialog.component.html',
  styleUrls: ['./error-auth-dialog.component.scss']
})
export class ErrorAuthDialog {
  constructor(public bsModalRef: BsModalRef, public chatService: ChatService) {}

  public showChat = true;
  public authType = 'Microsoft ENTRA ID (AD)';

  openChat() {
    this.chatService.openChat();
  }

  forceRefresh() {
    document.location.reload();
  }
}
