import { CampaignService } from 'app/_services/referral/campaign.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services/store.service';
import { Campaign } from 'app/_models/referral/campaign';
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-toggle-ip-status-dialog',
  templateUrl: './toggle-ip-status-dialog.component.html',
  styleUrls: ['./toggle-ip-status-dialog.component.scss']
})
export class ToggleIPStatusDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, public modalService: BsModalService, public store: StoreService, public campaignService: CampaignService) {}

  public ip;
  public campaign: Campaign = new Campaign();
  public isBlocked = false;
  public loading = false;
  public result: Subject<Campaign> = new Subject<Campaign>();
  public subscriptions = [];

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  changeStatus(operation) {
    this.loading = true;
    if (operation) {
      this.subscriptions.push(
        this.campaignService.updateCampaignIpBlacklist(this.campaign._id, this.ip, 'insert').subscribe((data) => {
          this.result.next(data.campaign);
          this.bsModalRef.hide();
          this.loading = false;
        })
      );
    } else {
      this.subscriptions.push(
        this.campaignService.updateCampaignIpBlacklist(this.campaign._id, this.ip, 'remove').subscribe((data) => {
          this.result.next(data.campaign);
          this.bsModalRef.hide();
          this.loading = false;
        })
      );
    }
  }
}
