import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
@Injectable()
export class CEPService {
  constructor(private handler: HttpBackend, private http: HttpClient = new HttpClient(handler)) {}

  getAddress(cep: string) {
    return this.http.get<any>('https://viacep.com.br/ws/' + cep + '/json/');
  }
}
