import { Component } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-rename-question-dialog',
  templateUrl: './rename-question-dialog.component.html',
  styleUrls: ['./rename-question-dialog.component.scss']
})
export class RenameQuestionDialogComponent {
  constructor(public bsModalRef: BsModalRef, private store: StoreService) {}

  public internalName;
  public result: Subject<any> = new Subject();
  public questionType;
  public typeScale = '';
  public likertOptions = [
    {
      label: '0 a 5',
      value: '0-5'
    },
    {
      label: '1 a 5',
      value: '1-5'
    },
    {
      label: '0 a 10',
      value: '0-10'
    },
    {
      label: '1 a 10',
      value: '1-10'
    }
  ];

  fmSubmit() {
    this.result.next({ internalName: this.internalName, typeScale: this.typeScale });
    this.bsModalRef.hide();
  }

  validateInternalName(evt) {
    evt.srcElement.value = evt.srcElement.value.replace(/ /g, '_');
    evt.srcElement.value = evt.srcElement.value.replace(/[^a-zA-Z0-9_]/g, '');
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
