<div class="amp-dialog">
  <div class="header">
    <div class="title">
      Novo Ticket
    </div>
    <div class="subtitle">Insira as informações para criar um novo ticket.
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <form #form="ngForm" (ngSubmit)="form.form.valid && save()">
    <div class="body">
      <div class="field">
        <label>Assunto do Ticket</label>
        <input class="amp-input-lg" name="inputSubject" placeholder="Ex.: Problemas com entrega" [(ngModel)]="ticket.subject" minlength="5" required>
      </div>
      <div class="field" *ngIf="!isFromAnswer">
        <label>Contato</label>
        <amp-select [isSearchable]="true" [actions]="actions" (actionsClick)="onActionClick($event)" [placeholder]="'Digite e selecione o nome do contato'" [optionsObservable]="customersListObservable" [(value)]="ticket._customer" (valueChange)="findTickets()"></amp-select>
      </div>

      @if (ticket._customer && existingTickets?.length) {
      <div class="d-flex flex-column">

        <div class="tickets-wrapper">
          <div (click)="showTickets = !showTickets">Existem {{ existingTickets?.length }} tickets abertos para este contato, clique para ver.</div>
          <div *ngIf="showTickets">
            <ul>
              @for (ticket of existingTickets; track ticket._id) {
              <li><a (click)="openTicket(ticket._id)" class="text-primary">{{ ticket.subject }}</a></li>
              }
            </ul>
          </div>
        </div>
      </div>
      }

      <div class="field" *ngIf="isFromAnswer">
        <label>Fluxo</label>
        <amp-select [isSearchable]="true" [placeholder]="'Selecione o fluxo desejado'" [options]="pipelineOptions" (valueChange)="selectPipeline($event)" [(value)]="ticket._pipeline"></amp-select>
      </div>
      <div class="field">
        <label>Descrição</label>
        <textarea rows="4" class="amp-input" name="inputDescription" placeholder="Insira uma descrição sobre esse Ticket" [(ngModel)]="ticket.description"></textarea>
      </div>
      <div class="field">
        <label>Responsável</label>
        <amp-select [isSearchable]="true" [placeholder]="'Selecione um usuário'" [options]="usersOptions" [(value)]="ticket._assigned_to"></amp-select>
      </div>
      <div class="field">
        <label>Tags</label>
        <amp-multi-select primaryPlaceholder="Digite para pesquisar" [addOnBlur]="true" (onAdd)="checkDuplicates($event)" [addOnEnter]="true" displayBy="name" identifyBy="_id"  [(value)]="ticket._tags" [optionsObservable]="requestTagsAutoComplete"></amp-multi-select>
      </div>
      <div class="double-columns">
        <div class="field">
          <label>Data de Vencimento</label>
          <input [(ngModel)]="ticket.due_at" name="inputDueDate" class="amp-input-lg" bsDatepicker placement="top" [minDate]="minDate" [bsConfig]="{ containerClass: 'theme-amp' }" placeholder="Escolha uma data">
        </div>
        <div class="field">
          <label>Prioridade</label>
          <amp-select [isSearchable]="false" [placeholder]="'Selecione a prioridade'" [options]="priorites" [(value)]="ticket.priority"></amp-select>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="amp-btn-outline-cancel amp-btn-lg" type="button" (click)="closeDialog()">Cancelar</button>
      <button class="amp-btn-success" type="submit" [disabled]="!form.form.valid || loading">Criar Ticket</button>
    </div>
  </form>
</div>
