import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwtDecode from 'jwt-decode';
import { AppConfig } from '../app.config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MixPanelService } from './mixPanel.service';
import { Router } from '@angular/router';
@Injectable()
export class AuthenticationService {
  public userRole = '';
  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private jwtHelper: JwtHelperService,
    private mixPanel: MixPanelService,
    private router: Router
  ) {}

  login(email: string, senha: string, keepMeLoggedIn: boolean) {
    return this.http.post(this.config.apiUrl + '/user/auth/', { email: email, senha: senha, keep_connected: keepMeLoggedIn }).pipe(
      map((response: any) => {
        const user = response;
        if (user && user.token) {
          localStorage.setItem('access_token', user.token);
          this.getTokenPayload();
          return { result: 1, user: user.user };
        } else if (user.require_authenticator) {
          return { result: -1, user: user.user };
        }
        return { result: 0, user: undefined };
      })
    );
  }

  logout() {
    localStorage.removeItem('business');
    localStorage.removeItem('empresaId');
    localStorage.removeItem('user_id');
    this.router.navigate(['/login']);
    const data = this.http.delete(this.config.apiUrl + '/user/invalidate-session').pipe(
      map(() => {
        localStorage.removeItem('access_token');
        return true;
      })
    );
    return data;
  }

  getNome() {
    if (this.isAuthenticated()) {
      const token = localStorage.getItem('access_token');
      const tokenPayload = jwtDecode(token);
      return tokenPayload.data.nome;
    }
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');

    if (token) {
      const tokenPayload = jwtDecode(token);

      if (!tokenPayload.data) {
        localStorage.removeItem('access_token');
        window.location.reload();
      }

      this.userRole = tokenPayload.data.role;
      this.mixPanel.init(tokenPayload.data._id, tokenPayload.data.email, tokenPayload.data.nome);
      return !this.jwtHelper.isTokenExpired(token);
    }

    return false;
  }

  isExpired(): boolean {
    const token = localStorage.getItem('access_token');
    if (token) {
      return this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  getTokenPayload(): any {
    const token = localStorage.getItem('access_token');
    if (this.isAuthenticated()) {
      const tokenPayload = jwtDecode(token);
      this.userRole = tokenPayload.data.role;
      return tokenPayload.data;
    } else {
      return;
    }
  }

  isAccountManager() {
    if (this.isAuthenticated()) {
      const tokenPayload = jwtDecode(localStorage.getItem('access_token'));
      this.userRole = tokenPayload.data.role;
      if (tokenPayload.data.role == 'acmng') {
        return true;
      }
      return false;
    }
    return false;
  }

  getRole() {
    if (this.isAuthenticated()) {
      const tokenPayload = jwtDecode(localStorage.getItem('access_token'));
      this.userRole = tokenPayload.data.role;
      return tokenPayload.data.role;
    }
    return null;
  }

  getToken(): any {
    return localStorage.getItem('access_token');
  }

  validateAuthenticatorToken(userId, token, keepMeLoggedIn) {
    return this.http.post<any>(this.config.apiUrl + '/user/auth/authenticator', { user: userId, token, keep_connected: keepMeLoggedIn });
  }
}
