import { ChangeBusinessDialogComponent } from './_dialogs/change-business-dialog/change-business-dialog.component';
import { Notification } from './_models/notification';
import { MenuService } from './_services/menu.service';
import { Component, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, EmpresaService, NotifyService, UserService, BusinessService } from 'app/_services';
import { StoreService } from './_services/store.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { environment } from 'environments/environment';
import { StripeAmpService } from './_services/stripe.service';
import { GetCpfCnpjDialogComponent } from './subscription/get-cpf-cnpj-dialog/get-cpf-cnpj-dialog.component';
import { Title } from '@angular/platform-browser';
import { TwoFaDialogComponent } from './_dialogs/two-fa-dialog/two-fa-dialog.component';
import { ContactEditorComponent } from './amp-components/contact-editor/contact-editor.component';
import { UpdateService } from './_services/update.service';
import { ChatService } from './_services/chat.service';
import { MixPanelService } from './_services/mixPanel.service';
import { ErrorAuthDialog } from './_dialogs/error-auth-dialog/error-auth-dialog.component';
import { PowerMenuDialogComponent } from './_dialogs/power-menu-dialog copy/power-menu-dialog.component';
import { Location } from '@angular/common';
import moment from 'moment';
import { UsersDialogComponent } from './settings/users/users-dialog/users-dialog.component';
@Component({
  selector: 'body',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
  public menuOpened = false;
  public showChat = false;
  public subscriptions = [];
  public beta = environment.beta;
  public homolog = environment.homolog ?? false;
  public loadingPhrases = [
    'Carregando...',
    'Seja bem-vindo à melhor plataforma de gestão da Experiência do Cliente.',
    'Construindo conexões duradouras com seus clientes.',
    'Preparando insights valiosos para você.',
    'Carregando sua jornada rumo à excelência.',
    'Estamos preparando algo especial para você.'
  ];

  public menuItems: any[] = [
    {
      icon: 'bi-columns-gap',
      title: 'dashboard',
      url: '/cf-dashboard',
      expectedRole: ['own', 'adm', 'ana', 'pdv', 'acmng', 'readonly', 'notesonly', 'dashonly'],
      collapsed: true,
      disabled: false
    },
    {
      icon: 'bi-people',
      title: 'contacts',
      url: '/contatos',
      expectedRole: ['own', 'adm', 'ana', 'pdv', 'acmng', 'readonly', 'notesonly'],
      collapsed: true,
      disabled: false
    },
    {
      icon: 'bi-star',
      title: 'reputation-management',
      url: '/reputation-management',
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'pdv', 'readonly', 'notesonly'],
      disabled: true
    },
    {
      icon: 'bi-chat-left-text',
      title: 'customer-feedback',
      url: '/customer-feedback',
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'readonly', 'notesonly'],
      disabled: true
    },
    {
      icon: 'bi-bar-chart',
      title: 'cf-analytics',
      url: '/cf-analytics',
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'readonly', 'notesonly'],
      disabled: true
    },
    {
      icon: 'bi-magic',
      title: 'insights',
      url: '/insights',
      expectedRole: ['own', 'adm', 'ana', 'acmng'],
      disabled: true
    },
    {
      icon: 'bi-check2-circle',
      title: 'ticketing',
      url: '/ticketing',
      expectedRoleTicketing: ['adm', 'ana', 'agent'],
      disabled: true
    },
    {
      icon: 'bi-megaphone',
      title: 'referralProgram',
      url: '/referral/',
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'readonly'],
      collapsed: true,
      disabled: true
    },
    {
      icon: 'bi-gear',
      title: 'settings',
      url: '/settings/',
      expectedRole: ['own', 'adm', 'ana', 'pdv', 'acmng', 'readonly', 'notesonly']
    }
  ];

  public isDarkTheme = false;
  public user: any = {};
  public empresas: any[] = [];
  public isAcMng = false;
  public canTemporaryAccess = false;
  public isNavBar = false;
  public empresa: any = '';
  public notifications: Notification[];
  public lockMenu = false;
  public pageTitle: string;
  public isSidebar = false;
  public isSidebarMobile = true;
  public TawkToAvailable = true;
  public bugsnagClient;
  public urlStripe;
  public businessesAmount = 0;
  public searchBusinessField;
  public togglePromo = false;
  public showSecondaryBar = false;
  public amplmeAuthenticatedLink = '';
  public phrase = '';
  public showContactEditor = false;
  public loadButtons = false;
  public showDropDown = false;
  public userDataLoaded = false;
  public empresaDataLoaded = false;
  public mainLoadingError = false;

  @ViewChild('editContactSidebar', { static: false })
  editContactComponent: ContactEditorComponent;

  constructor(
    private menuService: MenuService,
    private sw: UpdateService,
    public router: Router,
    public angularLocation: Location,
    private translate: TranslateService,
    public authenticationService: AuthenticationService,
    private empresaService: EmpresaService,
    private notifyService: NotifyService,
    private userService: UserService,
    private modalService: BsModalService,
    private stripeService: StripeAmpService,
    private businessService: BusinessService,
    public store: StoreService,
    public localeService: BsLocaleService,
    public route: ActivatedRoute,
    public titleService: Title,
    private chatService: ChatService,
    private mixPanel: MixPanelService
  ) {
    this.getRandomPhrase();
    this.sw.checkForUpdates();
    defineLocale('pt-br', ptBrLocale);
    this.localeService.use('pt-br');

    const url = location.search;
    const params = new URLSearchParams(url);
    if (params.has('access_token')) {
      localStorage.setItem('access_token', params.get('access_token'));
      if (params.has('empresa')) {
        localStorage.setItem('empresaId', params.get('empresa'));
      }
      if (params.has('business')) {
        localStorage.setItem('business', params.get('business'));
      }
    }

    if (params.has('msal_error')) {
      this.authenticationService.logout();
      this.modalService.show(ErrorAuthDialog, {
        class: 'amp-modal',
        initialState: {
          authType: 'Microsoft Entra ID (Azure AD)'
        }
      });
    }

    this.subscriptions.push(this.menuService.getChangeEmitter().subscribe((item) => (this.isNavBar = this.isSidebar = item)));
    this.subscriptions.push(
      this.menuService.getChangeEmmiterBusiness().subscribe((item) => {
        this.empresa = item.nome;
        localStorage.setItem('empresa_nome', this.empresa);

        if (this.store.business.nps_enabled) {
          if (this.store.business.cf_attributes.cf_surveys) {
            this.menuItems.find((elm) => {
              if (elm.title == 'menu.customer-feedback') {
                return (elm.disabled = false);
              }
            });
            this.menuItems.find((elm) => {
              if (elm.title == 'menu.cf-analytics') {
                return (elm.disabled = false);
              }
            });
            if (this.store.business.cf_attributes?.cf_ai) {
              this.menuItems.find((elm) => {
                if (elm.title == 'menu.insights') {
                  return (elm.disabled = false);
                }
              });
            }
          }
        }

        if (this.store.business.reviews_enabled) {
          this.menuItems.find((elm) => {
            if (elm.title == 'menu.reputation-management') {
              return (elm.disabled = false);
            }
          });
        }

        if (this.store.business.referral_enabled) {
          this.menuItems.find((elm) => {
            if (elm.title == 'menu.referralProgram') {
              return (elm.disabled = false);
            }
          });
        }

        if (this.store.business._id == '5d166c6656944e11aa1d6365') {
          this.menuItems.find((elm) => {
            if (elm.title == 'menu.dashboard') {
              return (elm.disabled = true);
            }
          });
        }

        this.empresaDataLoaded = true;
      })
    );

    this.subscriptions.push(
      this.menuService.getChangeEmitterUser().subscribe((item) => {
        this.user = item;
        localStorage.setItem('user_nome', this.user.nome);
        localStorage.setItem('user_id', this.user._id);
        localStorage.setItem('user_email', this.user.email);
        if (!environment.production && !environment.homolog) {
          this.titleService.setTitle('DEV | Amplifique.me');
        } else if (environment.homolog) {
          this.titleService.setTitle('HOMOLOG | Amplifique.me');
        }
        if (this.user.role == 'acmng') {
          this.loadEmpresas();
        } else if (this.user.permissions?.can_temporary_access) {
          this.loadEmpresas(true);
        }

        this.amplmeAuthenticatedLink = `https://ampl.me/new?access_token=${this.authenticationService.getToken()}`;
        this.getPageData();
        this.checkBusinessesAmount();
        this.userDataLoaded = true;
      })
    );
    this.subscriptions.push(this.menuService.getSidebarChangeEmitter().subscribe((item) => (this.isSidebar = item)));

    this.subscriptions.push(
      this.menuService.getChangeEmitterPageTitle().subscribe((pgTitle: any) => {
        this.pageTitle = pgTitle;
      })
    );

    this.loadEmpresa();
    this.loadDataUser();

    this.menuItems.map((item) => {
      this.subscriptions.push(
        this.translate.get('menu.' + item.title).subscribe((res) => {
          item.title = res;
        })
      );
    });
    this.initializeI18n();

    this.subscriptions.push(
      this.menuService.getContactSidebarChangeEmitter().subscribe((data: any) => {
        this.changeContactSidebar(data.status, data.customerId, data.callback);
      })
    );
  }

  @HostListener('document:click', ['$event'])
  public onClick(event) {
    if (event.target.id == 'amp-businesses') {
      event.preventDefault();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k' && this.user.role == 'acmng' && this.modalService.getModalsCount() == 0) {
      event.preventDefault();
      this.onCtrlK();
    }
  }

  onCtrlK() {
    this.modalService.show(PowerMenuDialogComponent, {
      class: 'amp-modal-ai'
    });
  }

  redirect(item) {
    if (item.disabled) {
      this.notifyService.warning('Você não possui esse recurso habilitado. Entre em contato com o suporte para saber mais.');

      switch (item.title.split('menu.')[1]) {
        case 'reputation-management':
          //@ts-ignore
          window.Intercom('showNews', 46373);
          break;
        case 'customer-feedback':
          // @ts-ignore
          window.Intercom('showNews', 46375);
          break;
        case 'insights':
          // @ts-ignore
          window.Intercom('showNews', 46376);
          break;
        case 'ticketing':
          // @ts-ignore
          window.Intercom('showNews', 46377);
          break;
        default:
          break;
      }
    } else {
      this.router.navigate([item.url]);
    }
  }

  goToBeta() {
    const urlParams = new URLSearchParams();
    urlParams.set('access_token', this.authenticationService.getToken());
    urlParams.set('business', this.store.business._id);
    urlParams.set('empresa', this.store.empresaData._id);
    this.mixPanel.track('Access Beta');
    window.open(`https://beta.amplifique.me?${urlParams.toString()}`, '_blank');
  }

  openIntercomNews() {
    //@ts-ignore
    window.Intercom('showNews');
  }

  loadEmpresa() {
    if (!this.store.empresaData || !this.store.userData) {
      setTimeout(() => {
        this.loadEmpresa();
      }, 1000);
      return;
    }

    if (this.store.empresaData?.general_attributes?.allow_ticketing && this.store.userData?.ticketing_role?.length > 0) {
      this.menuItems.find((elm) => {
        if (elm.title == 'menu.ticketing') {
          return (elm.disabled = false);
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  checkBusinessesAmount() {
    this.businessesAmount = this.user._businesses.length;
  }

  closeDrawer() {
    this.isSidebarMobile = true;
  }

  closeContactEditor() {
    this.showContactEditor = false;
  }

  openDrawer() {
    this.isSidebarMobile = false;
  }

  showDropdown() {
    this.checkBusinessesAmount();

    if (this.businessesAmount > 1) {
      this.showDropDown = true;
    }
  }

  hideDropdown() {
    this.showDropDown = false;
  }

  initializeI18n() {
    this.translate.addLangs(['pt', 'es']);
    this.translate.setDefaultLang('pt');
    let browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('lang') != null) {
      browserLang = localStorage.getItem('lang');
    }
    localStorage.setItem('lang', browserLang);
    this.translate.use(browserLang.match(/pt|es/) ? browserLang : 'pt');
    moment.locale('pt-BR');
  }

  uncollapse() {
    this.isSidebarMobile = !this.isSidebarMobile;
    this.menuItems.map((item) => {
      item.collapsed = true;
    });
  }

  checkHasResults() {
    if (document.querySelector('.amp-autocomplete-item') == null) {
      return true;
    }
    return false;
  }

  openSecondaryBar() {
    this.showSecondaryBar = !this.showSecondaryBar;
  }

  getRandomPhrase() {
    this.phrase = this.loadingPhrases[Math.floor(Math.random() * this.loadingPhrases?.length)];
    setInterval(() => {
      this.phrase = this.loadingPhrases[Math.floor(Math.random() * this.loadingPhrases?.length)];
    }, 3000);
  }

  loadDataUser() {
    if (this.authenticationService.isAuthenticated()) {
      this.subscriptions.push(
        this.userService.getInfo().subscribe(
          (data) => {
            this.store.userData = data;
            this.menuService.emitUser(data);
            if (data._empresa.force_authenticator && !data.authenticator_enabled) {
              this.modalService.show(TwoFaDialogComponent, {
                ignoreBackdropClick: true,
                keyboard: false,
                class: 'amp-modal'
              });
            }
            this.userDataLoaded = true;
          },
          (error) => {
            this.mainLoadingError = true;
            console.error(error);
          }
        )
      );
    }
  }

  setAlertIconVisibility() {
    if (this.store.alerts.length) {
      if (this.store.alerts.some((item) => item.id != 'ai_credits_low')) {
        return true;
      } else {
        if (this.store.aiCreditsLimit == 100) {
          return false;
        }
        if (this.store.aiCreditsLimit > 100) {
          if (this.angularLocation.path().includes('/insights')) {
            return true;
          }
        }
      }
    }

    return false;
  }

  changeBusiness(_id) {
    localStorage.setItem('business', _id);
    this.hideDropdown();
    this.router.navigate([], {
      queryParams: {}
    });
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  goToFinances() {
    this.router.navigate(['/settings/finances']);
  }

  getCustomerPortal() {
    this.store.business._id = localStorage.getItem('business');
    this.subscriptions.push(
      this.stripeService.getCustomerPortal(this.store.business._id).subscribe(
        (data) => {
          this.urlStripe = data;
          window.open(this.urlStripe, '_self');
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  checkPage(page) {
    return window?.location?.pathname.includes(page);
  }

  getPageData() {
    this.store.business._id = localStorage.getItem('business');
    this.loadButtons = true;
    if (this.user.role == 'acmng' || this.user.permissions.can_temporary_access) {
      if (this.store.business._id) {
        this.subscriptions.push(
          this.businessService.getMyBusinessToEdit(this.store.business._id).subscribe(
            (data) => {
              this.store.setBusiness(data);
              this.empresa = this.store.business.nome;
              this.menuService.emitBusiness(this.store.business);
            },
            (error) => {
              console.error(error);
              this.mainLoadingError = true;
            }
          )
        );
        if (!localStorage.getItem('empresaId')) {
          return this.authenticationService.logout().subscribe(() => {});
        }
        this.subscriptions.push(
          this.businessService.getMyBusiness(localStorage.getItem('empresaId')).subscribe(
            (data) => {
              this.user._businesses = data;
            },
            (error) => {
              console.error(error);
              this.mainLoadingError = true;
              this.authenticationService.logout().subscribe(() => {});
            }
          )
        );
      } else {
        if (!localStorage.getItem('empresaId')) {
          return this.authenticationService.logout().subscribe(() => {});
        }
        this.subscriptions.push(
          this.businessService.getMyBusiness(localStorage.getItem('empresaId')).subscribe(
            (data) => {
              this.user._businesses = data;
              this.store.setBusiness(data[0]);
              this.menuService.emitBusiness(this.store.business);
            },
            (error) => {
              this.authenticationService.logout().subscribe(() => {});
              this.mainLoadingError = true;
              console.error(error);
            }
          )
        );
      }
    } else {
      if (this.store.business._id) {
        this.subscriptions.push(
          this.businessService.getMyBusinessToEdit(this.store.business._id).subscribe(
            (data) => {
              this.store.setBusiness(data);
              this.menuService.emitBusiness(this.store.business);
            },
            (error) => {
              this.mainLoadingError = true;
              console.error(error);
            }
          )
        );
      } else {
        this.subscriptions.push(
          this.businessService.getById(this.user._businesses[0]._id).subscribe(
            (data) => {
              this.store.setBusiness(data);
              this.menuService.emitBusiness(this.store.business);
            },
            (error) => {
              this.mainLoadingError = true;
              console.error(error);
            }
          )
        );
      }
    }
  }

  openTutorial(page) {
    if (page == 'insights') {
      window.open('https://www.youtube.com/watch?v=1ScBtogJ8XE', '_blank');
    }

    if (page == 'ticketing') {
      window.open('https://www.youtube.com/watch?v=L9ShhpNK_0U', '_blank');
    }

    if (page == 'answer-importer') {
      window.open('https://amplifiqueme.notion.site/Como-importar-respostas-121a6daff71d81d6bc71c953f797f40c', '_blank');
    }
  }

  openNotion() {
    window.open('https://ajuda.amplifique.me/', '_blank');
  }

  public checkTaxInformationStatus() {
    if (this.store.business.stripeCustomer?.length > 0 && this.store.business.activeAccount) {
      if (!this.store.business.taxId || !this.store.business.companyName) {
        const BsModalRef = this.modalService.show(GetCpfCnpjDialogComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'amp-modal'
        });

        this.subscriptions.push(
          BsModalRef.content.result.subscribe((data: any) => {
            this.store.business.taxId = data.tax_id;
            this.store.business.companyName = data.company_name;
            this.subscriptions.push(
              this.stripeService.saveCustomerInfo(this.store.business._id, this.store.business.companyName, this.store.business.taxId).subscribe(() => {})
            );
          })
        );
      }
    }
  }

  async logout() {
    this.authenticationService.logout().subscribe({
      next: () => {
        this.router.navigate(['/login']);
        this.isAcMng = false;
      },
      error: (error) => {
        console.error(error);
        this.router.navigate(['/login']);
        this.isAcMng = false;
      }
    });
  }

  loadEmpresas(isTemp = false) {
    if (isTemp) {
      return this.subscriptions.push(
        this.empresaService.getTemporaryAccessEmpresas().subscribe((res) => {
          this.empresas = [];
          this.empresas = res;
          this.canTemporaryAccess = true;
        })
      );
    }
    return this.subscriptions.push(
      this.empresaService.getManagedEmpresas().subscribe((res) => {
        this.empresas = [];
        this.empresas = res;
        this.isAcMng = true;
      })
    );
  }

  openSubscribe() {
    this.router.navigate(['subscription/checkout']);
  }

  openModalSwitchEmpresa() {
    const initialState = {
      empresas: this.empresas,
      changeEmpresa: this.changeEmpresa
    };

    this.modalService.show(ChangeBusinessDialogComponent, {
      initialState,
      class: 'amp-modal'
    });
  }

  changeEmpresa(_id: any) {
    localStorage.setItem('empresaId', _id);
    localStorage.removeItem('business');
    window.location.replace('/');
  }

  openChat() {
    this.chatService.openChat();
  }

  checkAlert() {
    if (document.getElementById('alert')) {
      return true;
    }
  }

  editProfile() {
    const initialState = {
      isNew: false,
      user: this.user,
      isProfileEdit: true
    };

    this.modalService.show(UsersDialogComponent, {
      ignoreBackdropClick: false,
      initialState,
      class: 'amp-modal'
    });
  }

  changeContactSidebar(status, customerId, callback?) {
    if (status) {
      if (this.showContactEditor) {
        this.notifyService.warning('Você já está editando um contato!');
      } else {
        this.showContactEditor = true;
        setTimeout(() => {
          this.editContactComponent.setCustomer(customerId);
          this.editContactComponent.setCallback(callback);
        }, 300);
      }
    } else {
      this.showContactEditor = false;
    }
  }
}
