import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate, query, stagger, animateChild } from '@angular/animations';
import { AuthenticationService } from 'app/_services';
import { Campaign } from 'app/_models/referral/campaign';
@Component({
  selector: 'amp-referral-milestone-widget-preview',
  templateUrl: './referral-milestone-widget-preview.component.html',
  styleUrls: ['./referral-milestone-widget-preview.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('.5s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate(
          '.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)',
            opacity: 0,
            height: '0px',
            margin: '0px',
            padding: '0px'
          })
        )
      ])
    ]),
    trigger('list', [transition(':enter', [query('@items', stagger(100, animateChild()))])])
  ]
})
export class ReferralMilestoneWidgetPreviewComponent implements OnInit {
  @Input('campaign') campaign: Campaign = new Campaign();

  public event;
  public showRecompensas = true;
  public showIndicacoes = false;
  public listaIndicacoes: any = [
    {
      id: 1,
      nome: 'Jane Cooper',
      status: 2
    },
    {
      id: 2,
      nome: 'Darrell Steward',
      status: 2
    },
    {
      id: 3,
      nome: 'Albert Flores',
      status: 1
    },
    {
      id: 4,
      nome: 'Leslie Alexander',
      status: 1
    },
    {
      id: 5,
      nome: "Kristin Watson'",
      status: 1
    }
  ];

  public indicacoesConfirmadas = 0;

  public userName: string;

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    this.countConfirmed();
    this.userName = this.authService.getNome();
  }

  countConfirmed() {
    this.listaIndicacoes.map((data) => {
      if (data.status == 1) {
        this.indicacoesConfirmadas++;
      }
    });
  }

  toggleIndicacoes() {
    this.showRecompensas = false;
    this.showIndicacoes = true;
  }

  toggleRecompensas() {
    this.showRecompensas = true;
    this.showIndicacoes = false;
  }
}
