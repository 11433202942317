<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Token de Integração
    </div>

    <div class="amp-dialog-subtitle">Estas são as configurações do Token de Integração para solicitação de pesquisas.</div>

    <span class="amp-dialog-close bi-x-lg" *ngIf="!zapier" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body" *ngIf="!zapier">
    <form (ngSubmit)="form.form.valid && confirmar()" #form="ngForm">

      <div class="amp-field">
        <label class="amp-label-text">Nome do Token</label>
        <input type="text" class="amp-input" [(ngModel)]="token.name" required #email="ngModel" name="tokenName" placeholder="Defina um apelido para o seu token" />
      </div>

      <div class="amp-field amp-select-new">
        <label class="amp-label-text" for="campaignType">Selecionar Pesquisa </label>
        <select class="amp-input-lg" name="tokenCampaign" [(ngModel)]="token.action.campaign">
          <option value="" disabled>Selecionar Pesquisa</option>
          <option *ngFor="let survey of surveys" [value]="survey._id">{{survey.title}}</option>
        </select>
        <span class="bi-chevron-down"></span>
      </div>

      <div class="amp-field amp-radio-wrapper" *ngIf="isSurvey && surveys.length && (checkChannelSurvey('whatsapp-web') || checkChannelSurvey('sms') || checkChannelSurvey('email'))">
        <label class="amp-label-text">Canal de preferência para o envio</label>
        <label class="amp-radio-button" *ngIf="checkChannelSurvey('sms')">
          <input type="radio" name="channel" value="sms" [(ngModel)]="token.action.channel">
          <span class="text">SMS</span>
          <span class="checkmark"></span>
        </label>
        <label class="amp-radio-button" *ngIf="checkChannelSurvey('email')">
          <input type="radio" name="channel" value="email" [(ngModel)]="token.action.channel">
          <span class="text">E-mail</span>
          <span class="checkmark"></span>
        </label>
        <label class="amp-radio-button" *ngIf="checkChannelSurvey('whatsapp-web') && (store.business?.wa_enabled || store.empresaData?.letalk_integration || store.empresaData?.lynkmartech_integration || store.empresaData?.takeblip_integration || store.empresaData?.syngoo_integration || store.empresaData?.sicredi_whatsapp_integration || store.empresaData?.botmaker_integration || store.empresaData?.meta_whatsapp_integration || store.empresaData?.naty_integration || store.empresaData?.asksuite_integration ||  store.empresaData?.zenvia_wa_integration || store.empresaData?.ezchat_integration || store.empresaData?.managed_twilio_wa_integration)">
          <input type="radio" name="channel" value="wa-api" [(ngModel)]="token.action.channel">
          <span class="text">WhatsApp API</span>
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="amp-field">
        <label class="amp-label-text" for="switchStatus">Se ativado, os dados dos contato serão atualizados caso já
          estejam cadastrados na plataforma.</label>
        <input id="amp-switch-status-campaign" [(ngModel)]="token.force_rewrite" name="forceUpdate" #forceUpdate="ngModel" type="checkbox" class="amp-switch">
        <label for="amp-switch-status-campaign" class="amp-switch-label"></label>
        <label for="amp-switch-status-campaign" class="amp-switch-status-text" *ngIf="token.force_rewrite">Ativada</label>
        <label for="amp-switch-status-campaign" class="amp-switch-status-text" *ngIf="!token.force_rewrite">Desativada</label>
      </div>

      <div class="amp-field amp-select-new">
        <label class="amp-label-text" for="delayMode">Modo de Atraso</label>
        <select class="amp-input-lg" name="delayMode" [(ngModel)]="token.action.delay_mode">
          <option selected value="">Nenhum</option>
          <option value="delay">Delay</option>
          <option value="specific_time">Horário específico</option>
        </select>
        <span class="bi-chevron-down"></span>
      </div>

      <div *ngIf="token.action.delay_mode == 'delay'" class="amp-field">
        <label class="amp-label-text">Delay em minutos</label>
        <input type="number" class="amp-input-lg" min="1" max="14400" [(ngModel)]="token.action.delay" #delay="ngModel" name="delayTime" />
      </div>

      <div class="amp-field">
        <label class="amp-label-text">Expiração do Link da Solicitação</label>
        <amp-select placeholder="Selecione" [(value)]="token.expiration" [options]="expirationOptions"></amp-select>
      </div>

      <div *ngIf="token.action.delay_mode == 'specific_time'" class="amp-field">
        <label class="amp-label-text">Horário</label>
        <timepicker [showMeridian]="false" #specificTime="ngModel" name="specificTime" [(ngModel)]="token.action.specific_time"></timepicker>
      </div>

      <button class="amp-btn-lg" [disabled]="!form.form.valid">Salvar</button>
    </form>
  </div>