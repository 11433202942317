import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public model = '';
  public title = '';
  public message = '';
  public options: any;
  public buttonName = '';
  public placeholder = '';
  public isSearchable = false;
  public cancelName = 'Cancelar';
  public optionsObservable: Subject<any>;
  public result: Subject<any> = new Subject();

  confirmar() {
    this.result.next(this.model);
    this.bsModalRef.hide();
  }

  cancelar() {
    this.result.next(false);
    this.bsModalRef.hide();
  }
}
