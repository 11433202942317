<div class="row" *ngIf="loading">
      <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
</div>

<div class="row" *ngIf="!loading">
  <div class="col-lg-12">
    <div class="amp-section-title">Resumo</div>
    <div class="amp-card amp-attempts">
      <div class="amp-header">
        <div class="amp-table-column amp-status-resume">Motivo do Status</div>
        <div class="amp-table-column amp-created">Quantidade</div>
      </div>
      <div class="amp-attempt" *ngFor="let item of queueGroups">
        <div class="amp-line">
          <div class="amp-status-resume">
            <span *ngIf="item.name == 'EXECUTED|'">Executado</span>
            <span *ngIf="item.name == 'undefined|undefined'">Pendente/Aguardando</span>
            <span *ngIf="item.name != 'EXECUTED|' && item.name != 'undefined|undefined'">{{'error-queue-'+ item.name.split('|')[1] | translate }}</span>
          </div>
          <div class="amp-created">
            {{item.count}}
          </div>
        </div>
      </div>
      <div class="amp-attempt">
        <div class="amp-line">
          <div class="amp-status-resume">
            <b>Total</b>
          </div>
          <div class="amp-created">
            <b>{{queueItems.length}}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="!loading">
  <div class="col-lg-12">
    <p class="amp-section-title">Itens do Envio</p>
    <div class="amp-card amp-attempts">
      <span *ngIf="!queueItems.length">Dados não encontrados.</span>
      <div *ngIf="queueItems.length" class="amp-header">
        <div class="amp-table-column amp-status">Status</div>
        <div class="amp-table-column amp-event">Identificação</div>
        <div class="amp-table-column amp-id">Motivo do Status</div>
        <div class="amp-table-column amp-created">Execução em</div>
      </div>
      <div class="amp-attempt" *ngFor="let item of queueItemsPage; let i = index">
        <div class="amp-line">
          <div class="amp-status">
            <i *ngIf="item.response == 'EXECUTED'" tooltip="Sucesso" class="bi-check-lg"></i>
            <i *ngIf="item.response == 'NOT_EXECUTED'" tooltip="Falha" class="bi-x-lg"></i>
            <i *ngIf="!item.response" tooltip="Aguardando..." class="bi-clock"></i>
          </div>
          <div class="amp-event">
            <div class="amp-contact">
              <div class="amp-contact-name" *ngIf="!item.customer">
                Contato Excluído
              </div>
              <div class="amp-contact-name" *ngIf="item.customer?.name">
                {{item.customer?.name}}

              </div>
                <span class="amp-contact-email" *ngIf="item.customer?.email">{{item.customer?.email}}</span>

              <span class="phone-cell"><span *ngIf="item.customer?.tel?.length > 5" [class]="'phone-flag iti__flag iti__' + (item.customer?.tel | phone:true)"></span>{{ item.customer?.tel |phone }}</span>
            </div>
          </div>
          <div class="amp-id">
            <span *ngIf="!item.response">Pendente/Aguardando</span>
            <span *ngIf="item.response">
              {{'error-queue-'+ item.responseReason | translate:{fallback:item.responseReason} }}
            </span>
          </div>
          <div class="amp-created">
            {{item.executed_at | date:'dd/MM/yy HH:mm:ss'}}
          </div>
        </div>

      </div>

      <div class="amp-navigators">
        <pagination [totalItems]="queueItems.length" [itemsPerPage]="limit" [rotate]="true" [maxSize]="9" [(ngModel)]="pageNumber" [boundaryLinks]="true" [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" (pageChanged)="changePage($event.page)" [firstText]="'Primeira'" [lastText]="'Última'"></pagination>
      </div>
    </div>
  </div>
</div>


<ng-template #nextTemplate>
  <span class="bi-chevron-right"></span>
</ng-template>
<ng-template #prevTemplate>
  <span class="bi-chevron-left"></span>
</ng-template>
