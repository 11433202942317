import { Component, Pipe, PipeTransform } from '@angular/core';
import { StoreService } from 'app/_services';
import { ChatService } from 'app/_services/chat.service';
@Component({
  selector: 'alerts-area',
  templateUrl: './alerts-area.component.html',
  styleUrls: ['./alerts-area.component.scss']
})
export class AlertsAreaComponent {
  constructor(public store: StoreService, private chatService: ChatService) {}

  openChat() {
    try {
      this.chatService.openChat();
    } catch (exception) {
      console.error(exception);
    }
  }

  removeAlert(rAlert) {
    const i = this.store.showAlerts.findIndex((alert) => alert.message == rAlert.message);

    if (i > -1) {
      this.store.showAlerts.splice(i, 1);
    }
  }
}
@Pipe({
  name: 'alertType',
  pure: false
})
export class AlertTypePipe implements PipeTransform {
  transform(value) {
    return value ? value.filter((it) => it.type == 'topbar') : [];
  }
}
