<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Configurações de Domínio Customizado de E-mail
    </div>
    <div class="amp-dialog-subtitle">Por padrão, o disparo de e-mails acontece através do nosso domínio (amplifique.me). Para realizar disparos a partir do seu próprio domínio, configure os campos necessários abaixo.</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-wrapper">
      <div class="amp-field">
        <label class="amp-label-text">Endereço de E-mail</label>
        <input [(ngModel)]="sender" placeholder="Ex: pesquisas" (keyup)="validateInputs($event)" autocomplete="off" name="sender" type="text" class="amp-input">
      </div>

      <div class="amp-field">
        <label class="amp-label-text">Subdomínio</label>
        <input [(ngModel)]="subdomain" placeholder="Ex: pesquisa" (keyup)="validateInputs($event)" autocomplete="off" name="subdomain" type="text" class="amp-input">
      </div>

      <div class="amp-field">
        <label class="amp-label-text">Domínio</label>
        <input [(ngModel)]="domain" placeholder="Ex: empresa.com" (keyup)="validateInputs($event)" autocomplete="off" name="domain" type="text" class="amp-input">
      </div>

      <div class="amp-field">
        <label class="amp-label-text">DKIM (opcional)</label>
        <input [(ngModel)]="customDKIMSelector" (keyup)="validateInputs($event)" autocomplete="off" name="dkim" type="text" maxlength="3" minlength="1" class="amp-input">
      </div>

      <div class="amp-field">
        <label class="amp-label-text">Pré-visualização</label>
        <input [value]="(sender == undefined ? '' : sender) + '@' + (subdomain == undefined ? '' : subdomain) + '.' + (domain == undefined ? '' : domain)" type="text" disabled class="amp-input">
      </div>
    </div>

    <button class="amp-btn-primary amp-btn" [disabled]="!sender || !subdomain || !domain" (click)="confirmar()">Salvar</button>
  </div>
</div>
