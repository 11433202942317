import { NotifyService } from './notify.service';
import { TermsOfUseDialogComponent } from './../_dialogs/terms-of-use-dialog/terms-of-use-dialog.component';
import { Injectable } from '@angular/core';
import { MixPanelService } from './mixPanel.service';
import { MenuService } from './menu.service';
import { EmpresaService } from './empresa.service';
import { Router } from '@angular/router';
import { AmpSurveyService } from '@amplifique.me/ngx-amplifiqueme';
import { UserService } from './user.service';
import { ChangeLog } from 'app/_models/adm/changeLog';
import { AuthenticationService } from './authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BusinessService } from './business.service';
import { InfoDialogComponent } from 'app/_dialogs/info-dialog/info-dialog.component';
import { CustomField } from 'app/_models/customField';
import { CookieService } from 'ngx-cookie';
import { environment } from 'environments/environment';
import { DisableAccountDialogComponent } from 'app/_dialogs/disable-account-dialog/disable-account-dialog.component';
import { TagService } from './tag.service';
import { ChatService } from './chat.service';
import { Business } from 'app/_models/business';
import { Empresa } from 'app/_models/empresa';
import { Tag } from 'app/_models/tag';
import { User } from 'app/_models/user';
import moment from 'moment';
@Injectable({ providedIn: 'root' })
export class StoreService {
  public business: Business = new Business();
  public contacts_pagination = 1;
  public empresa_nome = '';
  public empresa = '';
  public alerts: any[] = [];
  public showAlerts: any[] = [];
  public empresaData: Empresa = null;
  public trialInterval;
  public isShowingLoginDialog = false;
  public changeLog: ChangeLog[] = [];
  public changeLogSkip = 0;
  public aiCreditsLimit;
  public changeLogTotal = 0;
  public changeLogAllRead = true;
  public isExpired;
  public custom_fields: CustomField[] = undefined;
  public tags: Tag[] = [];
  private USER_ROLES = {
    acmng: 'Gerente de Conta',
    adm: 'Administrador',
    ana: 'Analista',
    own: 'Proprietário',
    pdv: 'Operador',
    readonly: 'Apenas Leitura',
    notesonly: 'Leitura e Notas',
    dashonly: 'Apenas Painel'
  };

  public businesses: string[];

  public togglePendingPayment = true;

  public advancedFilter = {
    filterFromDateStatus: false,
    filterToDateStatus: false,
    filterReviewStatus: false,
    filterNpsStatus: false,
    filterReferralStatus: false,
    filterEmailStatus: false,
    filterPhoneStatus: false,
    filterCompanyStatus: false,
    filterFromDate: null,
    filterToDate: null,
    filterReview: 'didReview',
    filterNps: 'didNps',
    filterNpsCampaign: '',
    filterReferral: 'didNps',
    filterEmail: 'Email',
    filterPhone: 'Phone',
    tagsFilter: [],
    companyFilter: [],
    scoreFilterNps: [0, 10],
    filterStatus: 'all',
    filterSurvey: 'surveyAnswered',
    filterSurveyCampaign: '',
    filterSurveyStatus: false,
    customFieldFilters: [],
    filterSurveyDate: [new Date(), new Date()],
    filterSurveyDateStatus: false
  };

  public presentationMode = localStorage.getItem('presentMode') == 'true' ? true : false;
  public advancedFilterStatus = false;
  public userData: User;

  constructor(
    private mixPanel: MixPanelService,
    private menuService: MenuService,
    private empresaService: EmpresaService,
    public authService: AuthenticationService,
    private modalService: BsModalService,
    private router: Router,
    private userService: UserService,
    private businessService: BusinessService,
    private ampSurveyService: AmpSurveyService,
    private cookieService: CookieService,
    private notifyService: NotifyService,
    private tagsService: TagService,
    private chatService: ChatService
  ) {
    this.menuService.eventUser.subscribe((data) => {
      this.businesses = data._businesses;
    });

    if (environment.production == false) {
      //@ts-ignore
      window.AmplifiquemeStore = this;
    }
  }

  async setBusiness(business: Business) {
    this.business = business;
    if (typeof this.business._empresa == 'string') {
      this.empresa = this.business._empresa;
    } else {
      this.empresa = this.business._empresa._id;
      this.business._empresa = this.business._empresa._id;
    }
    localStorage.setItem('business', this.business._id);
    this.checkAccountUsage();
    this.getTags();
    //@ts-ignore
    this.mixPanel.setBusiness(business.nome, business._empresa);
    if (this.empresa != '' && this.empresaData == null) {
      this.getEmpresaData();
    }
    if (!this.business.activeAccount && !this.authService.isAccountManager()) {
      this.modalService.show(DisableAccountDialogComponent, {
        class: 'modal-lg amp-modal'
      });
    }
    this.cookieService.remove('amp_error_count');
  }

  getEmpresaData() {
    this.empresaService.getById(this.empresa).subscribe((data) => {
      this.empresaData = data;
      this.business._empresa == data._id;
      this.showNpsSurveyAndLoadChat();
      this.getCustomFields();
      if (this.empresaData.isTrial) {
        this.checkTrial();
      }
    });
  }

  getCustomFields() {
    this.empresaService.getCustomFields(this.empresa).subscribe((data) => {
      this.custom_fields = data;
    });
  }

  getTags() {
    this.tagsService.getTags(this.empresa, '').subscribe((data) => {
      this.tags = data;
    });
  }

  showNpsSurveyAndLoadChat() {
    if (this.userData?._id) {
      this.loadNovu(this.userData);
      this.chatService.identifyUser(this.userData, this.business, this.empresaData);
      this.checkSigninTerms();
      this.checkSigninPrivacy();

      if (this.userData.role != 'acmng' && !this.userData.email.includes('@amplifique.me')) {
        const identifier = '59c950f7e5b20500126eade1';

        this.ampSurveyService.load(identifier, true).then(() => {
          this.ampSurveyService.identify(
            {
              email: this.userData.email,
              name: this.userData.nome,
              phone: this.userData.phone ? this.userData.phone : undefined,
              //@ts-ignore
              created_at: this.userData.created_at,
              company: this.empresaData?.nome,
              companyDocument: this.empresaData?.taxId,
              custom_fields: {
                role: this.USER_ROLES[this.userData.role]
              }
            },
            true
          );
        });
      }
    } else {
      this.userService.getInfo().subscribe((userData) => {
        this.userData = userData;
        this.showNpsSurveyAndLoadChat();
      });
    }
  }

  public loadNovu(userData) {
    //@ts-ignore
    if (!window.novu) {
      setTimeout(() => this.loadNovu(userData), 500);
      return;
    }
    const customTheme = {
      light: {
        layout: {
          background: 'white'
        },
        notificationItem: {
          unread: {
            notificationItemBeforeBrandColor: '#b04bff'
          }
        }
      }
    };

    try {
      //@ts-ignore
      window.novu.init(
        environment.novu_key,
        {
          bellSelector: '#notification-bell',
          unseenBadgeSelector: '#unseen-badge',
          i18n: 'pt',
          theme: customTheme
        },
        {
          subscriberId: userData._id,
          email: userData.email,
          firstName: userData.nome,
          lastName: 'Teste'
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  public checkTrial() {
    if (this.empresaData) {
      if (this.empresaData.isTrial && new Date(this.empresaData.trial_expiration_date) < new Date()) {
        this.isExpired = true;
        if (this.userData.role != 'acmng' && !this.userData.permissions.can_temporary_access) {
          this.router.navigate(['subscription/checkout'], { replaceUrl: true });
        }
      }
    } else {
      setTimeout(() => this.checkTrial(), 1000);
    }
  }

  async checkAccountUsage(force = false) {
    if (force) {
      this.cookieService.remove(`amp_alerts`);
    }

    if (!this.empresaData) {
      return setTimeout(() => this.checkAccountUsage(force), 1000);
    }

    this.businessService.checkAccountUsage(this.business._id).subscribe((data) => {
      this.aiCreditsLimit = data.n_ai_credits_limit_on_month;
      this.alerts = [...data.alerts];

      if (!['acmng', 'own', 'adm'].includes(this.userData.role)) {
        if (this.empresaData?.general_attributes?.only_admin_notification) {
          this.alerts = [];
        }
        return;
      }

      //@ts-ignore
      let alertPopup = this.alerts.filter((alert) => alert.type == 'popup');
      if (!this.cookieService.hasKey(`amp_alerts`)) {
        this.showAlerts = [...data.alerts];
        this.cookieService.put(`amp_alerts`, JSON.stringify(this.alerts), { expires: moment().add(30, 'm').toDate() });

        if (this.aiCreditsLimit == 100) {
          this.showAlerts = this.showAlerts.filter((alert) => alert.id != 'ai_credits_low');
          alertPopup = alertPopup.filter((alert) => alert.id != 'ai_credits_low');
        }

        if (!['acmng', 'own', 'adm'].includes(this.userData.role)) {
          this.showAlerts = [];
          return;
        }

        alertPopup.map((alert: any) => {
          const modal = this.modalService.show(InfoDialogComponent, {
            ignoreBackdropClick: !alert.dismissable,
            animated: true,
            class: 'modal-lg amp-modal',
            initialState: {
              title: 'Alerta',
              message: alert.message,
              buttonText: 'Falar com o Suporte',
              buttonType: alert.level == 'error' ? 'danger' : 'warning'
            }
          });

          modal.content.resultado.subscribe(() => {
            this.chatService.openChat();
          });
        });
      }

      if (this.alerts.length) {
        const aiAlert = this.alerts.filter((alert) => alert.id == 'ai_credits_low' && alert.type == 'topbar');
        if (aiAlert) {
          //@ts-ignore
          window.Intercom('trackEvent', 'ai_account_usage_limit', {
            date: new Date().getTime()
          });
        }
      }
    });
  }

  checkSigninTerms() {
    if (!this.userData.terms_signed || this.userData.terms_signin_version != environment.termsVersion) {
      const initialState = {
        isTerms: true
      };

      const bsModalRef: BsModalRef = this.modalService.show(TermsOfUseDialogComponent, {
        keyboard: false,
        ignoreBackdropClick: true,
        initialState,
        class: 'amp-modal-large'
      });

      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.userService.signinTerms(this.userData._id, environment.termsVersion).subscribe(
            () => {
              this.notifyService.success('Obrigado!');
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Houve um erro ao aceitar os termos.');
            }
          );
        }
      });
    }
  }

  checkSigninPrivacy() {
    if (!this.userData.privacy_signed || this.userData.privacy_signin_version != environment.privacyVersion) {
      const initialState = {
        isPrivacy: true
      };
      const bsModalRef: BsModalRef = this.modalService.show(TermsOfUseDialogComponent, {
        initialState,
        keyboard: false,
        ignoreBackdropClick: true,
        class: 'amp-modal-large'
      });

      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.userService.signinPrivacy(this.userData._id, environment.privacyVersion).subscribe(
            () => {
              this.notifyService.success('Obrigado!');
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Houve um erro ao aceitar os termos.');
            }
          );
        }
      });
    }
  }
}
