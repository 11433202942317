import { Component } from '@angular/core';
import { PartnerRequest } from 'app/_models/partnerRequest';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-log-dialog',
  templateUrl: './log-dialog.component.html',
  styleUrls: ['./log-dialog.component.scss']
})
export class LogDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public log: PartnerRequest = new PartnerRequest();
}
