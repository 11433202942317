import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class TokenService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(_business: string) {
    return this.http.get<any>(this.config.apiUrl + '/partners/token/' + _business + '/all  ');
  }

  getByPartner(_business, partnerName) {
    return this.http.get<any>(this.config.apiUrl + `/partners/token/${_business}/partner?partner=${partnerName}`);
  }

  get(tokenId) {
    return this.http.get<any>(this.config.apiUrl + '/partners/token/' + tokenId);
  }

  create(token) {
    return this.http.post<any>(this.config.apiUrl + '/partners/token/', token);
  }

  update(token: any) {
    return this.http.put<any>(this.config.apiUrl + '/partners/token/' + token._id, token);
  }

  delete(tokenId) {
    return this.http.delete<any>(this.config.apiUrl + '/partners/token/' + tokenId);
  }

  getLog(tokenId, page) {
    const query = new URLSearchParams({
      page: page
    });

    return this.http.get<any>(this.config.apiUrl + '/partners/token/' + tokenId + '/log?' + query.toString());
  }
}
