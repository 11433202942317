import { Subject } from 'rxjs';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services';
import { MenuService } from 'app/_services/menu.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent {
  constructor(public bsModalRef: BsModalRef, public menuService: MenuService, public store: StoreService) {}

  public result: Subject<any> = new Subject();
  public imageChangedEvent = '';
  public croppedImage = '';
  public maintainAspectRatio = true;
  public uploadedFile;
  public aspectRatio;
  public minWidth;
  public maxWidth;
  public minHeight;
  public maxHeight;
  public showSkipButton = true;
  public resizeWidth;
  public type;

  fileChangeEvent(event) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  skipCrop() {
    this.result.next(['skip', null]);
    this.bsModalRef.hide();
  }

  saveCrop() {
    this.result.next(['cropped', this.croppedImage]);
    this.bsModalRef.hide();
  }
}
