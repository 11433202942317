import { EmpresaService } from './../../_services/empresa.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TagService } from 'app/_services';
import { StoreService } from 'app/_services/store.service';
import { map } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CompanyService } from 'app/_services/company.service';
import { MenuService } from 'app/_services/menu.service';
import { SurveyService } from 'app/_services/customerFeedback/survey.service';
import { Survey } from 'app/_models/customerFeedback/survey';
@Component({
  selector: 'amp-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
  constructor(
    public store: StoreService,
    private tagService: TagService,
    private surveyService: SurveyService,
    private menuService: MenuService,
    private companyService: CompanyService,
    private localeService: BsLocaleService,
    private empresaService: EmpresaService
  ) {}

  @Input() public show;

  @Output() public advancedFilterCb: EventEmitter<any> = new EventEmitter();
  @Output() public closeFilter: EventEmitter<any> = new EventEmitter();

  public selectCustomFieldSubject: Subject<string> = new Subject();

  public surveys: Survey[];
  public subscriptions = [];
  public selectedCustomField;
  public filterFromDateStatus = false;
  public filterToDateStatus = false;
  public filterReviewStatus = false;
  public filterNpsStatus = false;
  public filterReferralStatus = false;
  public filterSurveyStatus = false;
  public filterSurveyDateStatus = false;
  public filterEmailStatus = false;
  public filterPhoneStatus = false;
  public filterCompanyStatus = false;
  public filterFromDate;
  public filterToDate;
  public customFieldFilter;
  public customFieldConditional;
  public customFieldValue;
  public customFields = [];
  public filterStatus = 'all';
  public filterReview = 'didReview';
  public filterNps = 'didNps';
  public filterNpsCampaign = '';
  public scoreFilterNps = [0, 10];
  public filterSurveyDate = [new Date(), new Date()];
  public filterSurvey = 'surveyAnswered';
  public filterSurveyCampaign = '';
  public filterReferral = 'didNps';
  public filterEmail = 'Email';
  public filterPhone = 'Phone';
  public tagsFilter = [];
  public companyFilter = [];
  public filters = {};
  public customFieldFilters = [];

  public filters2 = {
    fromDate: null,
    toDate: null,

    review: {
      didReview: null,
      requestReview: null
    },
    nps: {
      didNps: null,
      requestNps: null
    },
    referral: {
      isInACampaign: null,
      isReferrer: null,
      isReferee: null
    },
    hasEmail: null,
    hasPhone: null
  };

  public surveysOptions = [
    {
      label: 'Todas',
      value: ''
    }
  ];

  public filterConditionOptions = [
    {
      label: 'é',
      value: 'equals'
    },
    {
      label: 'não é',
      value: 'not_equals'
    },
    {
      label: 'contém',
      value: 'contains'
    },
    {
      label: 'não contém',
      value: 'not_contains'
    }
  ];

  ngOnInit() {
    this.show = null;
    this.localeService.use('pt-br');
    this.filterFromDateStatus = this.store.advancedFilter.filterFromDateStatus;
    this.filterToDateStatus = this.store.advancedFilter.filterToDateStatus;
    this.filterReviewStatus = this.store.advancedFilter.filterReviewStatus;
    this.filterNpsStatus = this.store.advancedFilter.filterNpsStatus;
    this.filterReferralStatus = this.store.advancedFilter.filterReferralStatus;
    this.filterEmailStatus = this.store.advancedFilter.filterEmailStatus;
    this.filterPhoneStatus = this.store.advancedFilter.filterPhoneStatus;
    this.filterFromDate = this.store.advancedFilter.filterFromDate;
    this.filterToDate = this.store.advancedFilter.filterToDate;
    this.filterReview = this.store.advancedFilter.filterReview;
    this.filterNps = this.store.advancedFilter.filterNps;
    this.filterReferral = this.store.advancedFilter.filterReferral;
    this.filterEmail = this.store.advancedFilter.filterEmail;
    this.filterPhone = this.store.advancedFilter.filterPhone;
    this.tagsFilter = this.store.advancedFilter.tagsFilter;
    this.scoreFilterNps = this.store.advancedFilter.scoreFilterNps;
    this.filterCompanyStatus = this.store.advancedFilter.filterCompanyStatus;
    this.companyFilter = this.store.advancedFilter.companyFilter;
    this.filterNpsCampaign = this.store.advancedFilter.filterNpsCampaign;
    this.filterStatus = this.store.advancedFilter.filterStatus;
    this.filterSurvey = this.store.advancedFilter.filterSurvey;
    this.filterSurveyStatus = this.store.advancedFilter.filterSurveyStatus;
    this.filterSurveyCampaign = this.store.advancedFilter.filterSurveyCampaign;
    this.customFieldFilters = this.store.advancedFilter.customFieldFilters;
    this.filterSurveyDate = this.store.advancedFilter.filterSurveyDate;
    this.filterSurveyDateStatus = this.store.advancedFilter.filterSurveyDateStatus;

    this.applyFilters();
    this.loadSurveys();
    this.getCustomFields();

    this.subscriptions.push(
      this.menuService.getChangeEmmiterBusiness().subscribe(() => {
        this.loadSurveys();
        this.getCustomFields();
      })
    );

    this.selectCustomFieldSubject.subscribe((data) => {
      this.selectedCustomField = data;
    });
  }

  removeFilter(idx) {
    this.customFieldFilters.splice(idx, 1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  addCustomField() {
    this.customFieldFilters.push({ field: '', condition: '', value: '' });
  }

  getCustomFields() {
    if (!this.store.empresa) {
      return;
    }
    this.subscriptions.push(
      this.empresaService.getCustomFields(this.store.empresa).subscribe((data) => {
        data.forEach((data) => {
          this.customFields.push({ value: data.internal_name, label: data.label });
        });
      })
    );
  }

  loadSurveys() {
    if (this.store.business._id !== undefined) {
      this.subscriptions.push(
        this.surveyService.get(this.store.business._id).subscribe((data) => {
          data.forEach((survey) => {
            this.surveysOptions.push({
              label: survey.title,
              value: survey._id
            });
          });
          this.surveys = data;
        })
      );
    }
  }

  onChangeFilterFromDate(value) {
    this.filters2.toDate = value;
  }

  applyFilters() {
    this.filters = {};
    if (this.filterStatus != 'all') {
      this.filters['status'] = this.filterStatus;
    }

    if (this.tagsFilter.length) {
      const tags = this.tagsFilter.map((elm) => {
        return elm.value;
      });
      this.filters['tags'] = tags;
    }

    if (this.companyFilter.length) {
      const companies = this.companyFilter.map((elm) => {
        return elm.value;
      });
      this.filters['company'] = companies;
    }

    if (this.filterFromDateStatus) {
      this.filters['created_at'] = true;
      this.filters['createdAtFrom'] = this.filterFromDate;
    }

    if (this.filterToDateStatus) {
      this.filters['created_at'] = true;
      this.filters['createdAtTo'] = this.filterToDate;
    }

    if (this.filterReviewStatus) {
      this.filters['review'] = true;
      switch (this.filterReview) {
        case 'didReview':
          this.filters['didReview'] = true;
          break;
        case 'NotdidReview':
          this.filters['didReview'] = false;
          break;
        case 'requestReview':
          this.filters['requestedReview'] = true;
          break;
        case 'NotrequestReview':
          this.filters['requestedReview'] = false;
          break;
      }
    }

    if (this.filterNpsStatus) {
      this.filters['nps'] = true;
      switch (this.filterNps) {
        case 'didNps':
          this.filters['didNps'] = true;
          this.filters['npsScore'] = this.scoreFilterNps;
          break;
        case 'NotdidNps':
          this.filters['didNps'] = false;
          break;
        case 'requestedNps':
          this.filters['requestedNps'] = true;
          break;
        case 'NotrequestedNps':
          this.filters['requestedNps'] = false;
          break;
      }

      if (this.filterNpsCampaign.length > 0) {
        this.filters['npsCampaign'] = this.filterNpsCampaign;
      }
    }

    if (this.filterSurveyStatus) {
      this.filters['surveys'] = true;
      switch (this.filterSurvey) {
        case 'surveyAnswered':
          this.filters['surveyAnswered'] = true;
          break;
        case 'notSurveyAnswered':
          this.filters['surveyAnswered'] = false;
          break;
        case 'surveyRequested':
          this.filters['surveyRequested'] = true;
          break;
        case 'notSurveyRequested':
          this.filters['surveyRequested'] = false;
          break;
      }

      if (this.filterSurveyCampaign.length > 0) {
        this.filters['survey'] = this.filterSurveyCampaign;
      }
      if (this.filterSurveyDateStatus) {
        this.filters['surveyed_at'] = this.filterSurveyDate;
      }
    }

    if (this.filterReferralStatus) {
      this.filters['referral'] = true;
      switch (this.filterReferral) {
        case 'isReferrer':
          this.filters['isReferrer'] = true;
          break;
        case 'NotisReferrer':
          this.filters['isReferrer'] = false;
          break;
        case 'referred':
          this.filters['referred'] = true;
          break;
        case 'Notreferred':
          this.filters['referred'] = false;
          break;
        case 'referee':
          this.filters['referee'] = true;
          break;
        case 'Notreferee':
          this.filters['referee'] = false;
          break;
      }
    }

    if (this.filterEmailStatus) {
      if (this.filterEmail == 'Email') {
        this.filters['email'] = true;
      } else {
        this.filters['email'] = false;
      }
    }

    if (this.filterPhoneStatus) {
      if (this.filterPhone == 'Phone') {
        this.filters['tel'] = true;
      } else {
        this.filters['tel'] = false;
      }
    }

    if (this.customFieldFilters.length > 0) {
      this.filters['customField'] = this.customFieldFilters.map((elm) => {
        return {
          field: elm.field,
          condition: elm.condition,
          value: elm.value[0]
        };
      });
    }

    this.store.advancedFilter = {
      filterFromDateStatus: this.filterFromDateStatus,
      filterToDateStatus: this.filterToDateStatus,
      filterReviewStatus: this.filterReviewStatus,
      filterNpsStatus: this.filterNpsStatus,
      filterReferralStatus: this.filterReferralStatus,
      filterEmailStatus: this.filterEmailStatus,
      filterPhoneStatus: this.filterPhoneStatus,
      filterCompanyStatus: this.filterCompanyStatus,
      filterFromDate: this.filterFromDate,
      filterToDate: this.filterToDate,
      filterReview: this.filterReview,
      filterNps: this.filterNps,
      filterReferral: this.filterReferral,
      filterEmail: this.filterEmail,
      filterPhone: this.filterPhone,
      tagsFilter: this.tagsFilter,
      companyFilter: this.companyFilter,
      scoreFilterNps: this.scoreFilterNps,
      filterNpsCampaign: this.filterNpsCampaign,
      filterStatus: this.filterStatus,
      filterSurvey: this.filterSurvey,
      filterSurveyStatus: this.filterSurveyStatus,
      filterSurveyCampaign: this.filterSurveyCampaign,
      customFieldFilters: this.customFieldFilters,
      filterSurveyDate: this.filterSurveyDate,
      filterSurveyDateStatus: this.filterSurveyDateStatus
    };

    this.store.advancedFilterStatus = true;

    this.advancedFilterCb.emit(this.filters);
  }

  clearFilter() {
    this.filterFromDateStatus = false;
    this.filterToDateStatus = false;
    this.filterReferralStatus = false;
    this.filterNpsStatus = false;
    this.filterSurveyStatus = false;
    this.filterReviewStatus = false;
    this.filterEmailStatus = false;
    this.filterPhoneStatus = false;
    this.tagsFilter = [];
    this.companyFilter = [];
    this.scoreFilterNps = [0, 10];
    this.store.advancedFilterStatus = false;
    this.customFieldFilters = [];
    this.filterSurveyDateStatus = false;
    this.applyFilters();
  }

  requestCustomFieldAutoComplete = (search): Observable<any> => {
    return this.empresaService.getCustomFieldValues(this.store.empresa, this.selectedCustomField, undefined, search).pipe(
      map((values) =>
        values.map((value) => {
          return value.custom_fields;
        })
      )
    );
  };

  onSelectCustomField(item) {
    this.selectedCustomField = item;
  }

  requestTagsAutoComplete = (text): Observable<any> => {
    return this.tagService.getTags(this.store.empresa, text).pipe(
      map((tags) =>
        tags.map((tag) => {
          return { value: tag._id, display: tag.name };
        })
      )
    );
  };

  requestCompaniesAutoComplete = (text): Observable<any> => {
    return this.companyService.getCompanies(this.store.empresa, text).pipe(
      map((companies) =>
        companies.map((company) => {
          return { value: company._id, display: company.name };
        })
      )
    );
  };
}
