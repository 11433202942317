<div class="amp-dialog">
  <div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div>
      <img [src]="url">
    </div>
  </div>
</div>