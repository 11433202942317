import { DashboardService } from 'app/_services/dashboard.service';
import { StoreService } from 'app/_services/store.service';
import { Component, OnDestroy } from '@angular/core';
import { Dashboard } from 'app/_models/dashboard';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotifyService } from 'app/_services';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-create-dashboard-review-dialog',
  templateUrl: './create-dashboard-dialog.component.html',
  styleUrls: ['./create-dashboard-dialog.component.scss']
})
export class CreateDashboardDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private dashboardService: DashboardService, private notifyService: NotifyService, private store: StoreService) {}

  public isNew = true;
  public subscriptions = [];
  public origin = 'customer-feedback';
  public dashboard: Dashboard = new Dashboard();
  public result: Subject<Dashboard> = new Subject();

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  createDashboard() {
    if (this.origin == 'customer-feedback') {
      if (this.isNew) {
        //@ts-ignore
        this.dashboard._business = this.store.business._id;
        this.dashboard._empresa = this.store.empresa;
        this.dashboard.type = 'cf';
        this.subscriptions.push(
          this.dashboardService.create(this.dashboard).subscribe({
            next: (data) => {
              this.result.next(data);
              this.notifyService.success('Painel criado com sucesso!');
              this.bsModalRef.hide();
            },
            error: (error) => {
              console.error(error);
              this.notifyService.error('Não foi possível criar o painel, tente novamente ou entre em contato com o suporte.');
            }
          })
        );
      } else {
        this.subscriptions.push(
          this.dashboardService.update(this.dashboard).subscribe({
            next: (data) => {
              this.result.next(data);
              this.notifyService.success('Painel atualizado com sucesso!');
              this.bsModalRef.hide();
            },
            error: (error) => {
              console.error(error);
              this.notifyService.error('Não foi possível atualizar o painel, tente novamente ou entre em contato com o suporte.');
            }
          })
        );
      }
    }

    if (this.origin == 'reputation-management') {
      if (this.isNew) {
        //@ts-ignore
        this.dashboard._business = this.store.business._id;
        this.dashboard._empresa = this.store.empresa;
        this.dashboard.type = 'review';
        this.subscriptions.push(
          this.dashboardService.create(this.dashboard).subscribe({
            next: (data) => {
              this.result.next(data);
              this.notifyService.success('Painel criado com sucesso!');
              this.bsModalRef.hide();
            },
            error: (error) => {
              console.error(error);
              this.notifyService.error('Não foi possível criar o painel, tente novamente ou entre em contato com o suporte.');
            }
          })
        );
      } else {
        this.subscriptions.push(
          this.dashboardService.update(this.dashboard).subscribe({
            next: (data) => {
              this.result.next(data);
              this.notifyService.success('Painel atualizado com sucesso!');
              this.bsModalRef.hide();
            },
            error: (error) => {
              console.error(error);
              this.notifyService.error('Não foi possível atualizar o painel, tente novamente ou entre em contato com o suporte.');
            }
          })
        );
      }
    }
  }
}
