import { VideoPlayerDialogComponent } from 'app/_dialogs/video-player-dialog/video-player-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService, BusinessService, CampaignService, EmpresaService, NotifyService } from 'app/_services';
import { StoreService } from 'app/_services/store.service';
import Partner from 'app/_models/partner';
import { MenuService } from 'app/_services/menu.service';
import { Survey } from 'app/_models/customerFeedback/survey';
import { SurveyService } from 'app/_services/customerFeedback/survey.service';
import { TokenService } from 'app/_services/token.service';
import { Token } from 'app/_models/token';
import { TokenDialogComponent } from '../token-dialog/token-dialog.component';
import { HttpClient } from '@angular/common/http';
import { CustomField } from 'app/_models/customField';
import { ClientWebhooksService } from 'app/_services/clientWebhooks.service';
import { WebhookEndpoint } from 'app/_models/webhook-endpoint';
import { GlService } from 'app/_services/google.service';
import { Campaign } from 'app/_models/referral/campaign';
import { Business } from 'app/_models/business';
@Component({
  selector: 'app-partner-dialog',
  templateUrl: './partner-dialog.component.html',
  styleUrls: ['./partner-dialog.component.scss']
})
export class PartnerDialogComponent implements OnInit, OnDestroy {
  public partner: Partner;
  public enable = false;
  public token = 'asdhkasydoasud9as8d0nas9d8na908ds098a09s8nd90asd8sd0a9sd809as8d90a';
  public ac_lists: [] = [];
  public active_campaign_mode = 'cf';
  public picpay_step = 1;
  public cmnetSaved = false;
  public subscriptions = [];
  public referralCampaigns: Campaign[] = [];
  public campaign;
  public survey;
  public cfSurveys: Survey[] = [];
  public selectedToken = null;

  public picpayFields = {
    project_id: null,
    name: null,
    description: null,
    started_at: null,
    ended_at: null,
    payee_transaction_limit: null,
    payee_transaction_value: null,
    identical_transaction_rule: null
  };

  public askSuiteToken = '';
  public selectedStage = '';
  public pipedriveStages = [];
  public picPayTokenData: Token;
  public tokens: Token[] = [];
  public loading = false;

  public customFieldsList: CustomField[] = [];

  public funilVendasParams = {
    sales_channel_code: '',
    stage_code: '',
    salesman_code: '',
    pipeline_code: ''
  };

  public totvsParams = {
    partner_cmnet_hotel_id: '',
    partner_cmnet_db_host: '',
    partner_cmnet_db_name: '',
    partner_cmnet_db_user: '',
    partner_cmnet_db_password: '',
    partner_cmnet_db_port: '',
    partner_cmnet_token: null
  };

  public desbravadorParams = {
    partner_desbravador_token: null,
    partner_desbravador_key: ''
  };

  public elementorParams = {
    f_name: '',
    f_email: '',
    url: ''
  };

  public customerXParams = {
    f_label: '',
    f_journey: '',
    event: null,
    url: ''
  };

  public googleParams = {
    email: ''
  };

  public gupyParams = {
    active: false,
    webhook_status: false,
    access_token: '',
    triggers: []
  };

  constructor(
    public bsModalRef: BsModalRef,
    public menuService: MenuService,
    public businessService: BusinessService,
    public store: StoreService,
    public notifyService: NotifyService,
    public authService: AuthenticationService,
    public campaignService: CampaignService,
    public surveyService: SurveyService,
    public tokenService: TokenService,
    public modalService: BsModalService,
    private empresaService: EmpresaService,
    public http: HttpClient,
    private clientWebhookService: ClientWebhooksService,
    private googleService: GlService
  ) {}

  ngOnInit() {
    if (this.partner.internal_name == 'RDStation_marketing' || this.partner.internal_name == 'eduzz' || this.partner.internal_name == 'elementor') {
      this.getReferralCampaigns();
      this.getCustomerFeedbackSurveys();
    } else if (this.partner.internal_name == 'Hotmart') {
      this.getReferralCampaigns();
    } else if (this.partner.internal_name == 'asksuite') {
      this.loading = true;
      this.subscriptions.push(
        this.tokenService.getByPartner(this.store.business._id, this.partner.internal_name).subscribe(
          (data) => {
            if (data) {
              this.askSuiteToken = data[0].access_token;
            }
            this.loading = false;
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao configurar o AskSuite.');
            this.bsModalRef.hide();
          }
        )
      );
    } else if (this.partner.internal_name == 'pipedrive') {
      if (this.store.business.partner_pipedrive_key) {
        this.getPipeDriveStages();
      }
    } else if (this.partner.internal_name == 'picpay') {
      if (this.store.business.partner_picpay == undefined) {
        this.store.business.partner_picpay = new Business().partner_picpay;
      } else {
        if (this.store.business.partner_picpay.refresh_token) {
          this.loading = true;
          this.subscriptions.push(
            this.tokenService.getByPartner(this.store.business._id, this.partner.internal_name).subscribe(
              (data) => {
                if (data) {
                  this.picPayTokenData = data[0];
                  this.getPicPayProject();
                  this.picpay_step = 2;
                }
                this.loading = false;
              },
              (error) => {
                console.error(error);
                this.notifyService.error('Erro ao configurar AskSuite');
                this.bsModalRef.hide();
              }
            )
          );
        }
      }
    } else if (this.partner.internal_name == 'totvs_cmnet') {
      this.loading = true;
      this.subscriptions.push(
        this.businessService.getMyBusinessToEdit(this.store.business._id).subscribe((data) => {
          if (data.partner_cmnet_db_host) {
            this.totvsParams.partner_cmnet_db_host = data.partner_cmnet_db_host;
            this.totvsParams.partner_cmnet_db_name = data.partner_cmnet_db_name || '';
            this.totvsParams.partner_cmnet_db_password = data.partner_cmnet_db_password || '';
            this.totvsParams.partner_cmnet_db_port = data.partner_cmnet_db_port || '';
            this.totvsParams.partner_cmnet_db_user = data.partner_cmnet_db_user || '';
            this.totvsParams.partner_cmnet_hotel_id = data.partner_cmnet_hotel_id || '';
            this.totvsParams.partner_cmnet_token = data.partner_cmnet_token || '';
            this.cmnetSaved = true;
          }
          this.loading = false;
        })
      );
    } else if (this.partner.internal_name == 'desbravador') {
      this.loading = true;
      this.subscriptions.push(
        this.businessService.getMyBusinessToEdit(this.store.business._id).subscribe((data) => {
          if (data.partner_desbravador_key) {
            this.desbravadorParams.partner_desbravador_key = data.partner_desbravador_key || '';
            this.desbravadorParams.partner_desbravador_token = data.partner_desbravador_token || null;
            this.cmnetSaved = true;
          }
          this.loading = false;
        })
      );
    } else if (this.partner.internal_name == 'google-sheets') {
      if (this.store.business.gl_refresh_token) {
        this.subscriptions.push(
          this.googleService.getAccountInfo(this.store.business._id).subscribe((data) => {
            this.googleParams.email = data.email;
          })
        );
      }
    } else if (this.partner.internal_name == 'gupy') {
      if (this.store.business.partner_gupy) {
        this.gupyParams = this.store.business.partner_gupy;
      }
    }
    this.loadTokens();
    this.getCustomFields();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getCustomFields() {
    this.subscriptions.push(
      this.empresaService.getCustomFields(this.store.empresa).subscribe((data) => {
        this.customFieldsList = data.filter((x) => x.type == 'cpf');
        this.store.custom_fields = data;
      })
    );
  }

  goToTutorial() {
    window.open('https://amplifiqueme.notion.site/ActiveCampaign-121a6daff71d80d39fdeddcec28a2792', '_blank');
  }

  requestSupport() {
    if (!this.partner.self_setting) {
      this.menuService.emitChat(0);
      this.bsModalRef.hide();
    }
  }

  activeIntegration() {
    if (this.partner.internal_name == 'desbravador') {
      this.store.business.partner_desbravador_key = this.desbravadorParams.partner_desbravador_key;
      this.store.business.partner_desbravador_token = this.desbravadorParams.partner_desbravador_token;
      this.subscriptions.push(
        this.businessService.update(this.store.business).subscribe(() => {
          location.reload();
        })
      );
    } else if (this.partner.internal_name == 'gupy') {
      this.loading = true;
      this.store.business.partner_gupy = this.gupyParams;
      if (this.gupyParams.access_token) {
        this.subscriptions.push(
          this.http.post<any>(`https://integration.services.amplifique.me/gupy/setup/${this.store.business._id}`, this.gupyParams).subscribe(
            () => {
              this.loading = false;
              this.bsModalRef.hide();
            },
            (error) => {
              console.error(error);
              this.loading = false;
              this.notifyService.error('Não foi possível salvar a integração!');
            }
          )
        );
      }
    } else {
      this.subscriptions.push(
        this.businessService.update(this.store.business).subscribe(() => {
          location.reload();
        })
      );
    }
  }

  getReferralCampaigns() {
    this.subscriptions.push(
      this.campaignService.getActive(this.store.business._id).subscribe((data) => {
        this.referralCampaigns = data;
      })
    );
  }

  getCustomerFeedbackSurveys() {
    this.subscriptions.push(
      this.surveyService.getActive(this.store.business._id).subscribe((data) => {
        this.cfSurveys = data;
      })
    );
  }

  addRow(array) {
    array.push({});
  }

  getAcLists() {
    if (this.partner.internal_name == 'active_campaign') {
      this.subscriptions.push(
        this.businessService
          .getActiveCampaignList(
            this.store.business,
            this.store.business.partner_active_campaign_account_name,
            this.store.business.partner_active_campaign_token
          )
          .subscribe(
            (data) => {
              this.ac_lists = data;
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Falha ao buscar as listas, verifique os parâmetros acima e tente novamente.');
            }
          )
      );
    }
  }

  loadTokens() {
    this.subscriptions.push(
      this.tokenService.getByPartner(this.store.business._id, this.partner.internal_name).subscribe((data) => {
        this.tokens = data;
      })
    );
  }

  openGoogleSheetsHelper() {
    const initialState = {
      url: 'https://www.youtube.com/embed/izvL4Dc0Jt0'
    };

    this.modalService.show(VideoPlayerDialogComponent, {
      ignoreBackdropClick: false,
      class: 'modal-lg amp-modal-player',
      initialState
    });
  }

  generateAskSuiteToken() {
    this.loading = true;
    const token = new Token();
    token.name = this.partner.internal_name;
    token.partner = this.partner.internal_name;
    //@ts-ignore
    token._business = this.store.business._id;
    token.action = {
      campaign: null,
      channel: '',
      type: 'get-review',
      delay: null,
      delay_mode: null,
      specific_time: null,
      expiration: 0
    };
    token.force_rewrite = false;
    //@ts-ignore
    token._empresa = this.store.empresa;
    this.subscriptions.push(
      this.tokenService.create(token).subscribe((data) => {
        this.loading = false;
        this.askSuiteToken = data.access_token;
      })
    );
  }

  createNewIntegrationToken() {
    const token = new Token();
    token.partner = this.partner.internal_name;

    const initialState = {
      isNew: true,
      cb: (data) => {
        this.loadTokens();
        this.selectedToken = data.access_token;
      },
      token: token,
      zapier: false
    };

    this.modalService.show(TokenDialogComponent, {
      class: 'amp-modal modal-lg',
      ignoreBackdropClick: false,
      initialState
    });
  }

  savePicPayCredentials() {
    this.loading = true;
    this.subscriptions.push(
      this.http
        .post<{ refresh_token: string }>('https://integration.services.amplifique.me/picpay/refresh-token', {
          client_id: this.store.business.partner_picpay.client_id,
          client_secret: this.store.business.partner_picpay.client_secret
        })
        .subscribe(
          (data) => {
            this.store.business.partner_picpay.refresh_token = data.refresh_token;

            const token = new Token();
            token.name = this.partner.internal_name;
            token.partner = this.partner.internal_name;
            //@ts-ignore
            token._business = this.store.business._id;
            token.action = {
              campaign: null,
              channel: '',
              type: 'picpay-reward',
              delay: null,
              delay_mode: null,
              specific_time: null,
              expiration: 0
            };
            token.force_rewrite = false;
            //@ts-ignore
            token._empresa = this.store.empresa;
            this.subscriptions.push(
              this.tokenService.create(token).subscribe(
                (tokenData) => {
                  this.picPayTokenData = tokenData;
                  this.store.business.partner_picpay._token = tokenData._id;

                  this.subscriptions.push(
                    this.businessService.update(this.store.business).subscribe(
                      () => {
                        this.picpay_step = 2;
                        this.getPicPayProject();
                        this.loading = false;
                      },
                      (error) => {
                        console.error(error);
                        this.notifyService.error('Falha ao salvar o refresh_token do PicPay!');
                        this.loading = false;
                        this.picpay_step = 1;
                      }
                    )
                  );
                },
                (error) => {
                  console.error(error);
                  this.notifyService.error('Falha ao gerar Token de Integração!');
                  this.loading = false;
                  this.picpay_step = 1;
                }
              )
            );
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Falha ao validar as credenciais do PicPay! Verifique e tente novamente.');
            this.loading = false;
            this.picpay_step = 1;
          }
        )
    );
  }

  getPicPayProject() {
    this.loading = true;
    this.subscriptions.push(
      this.http.get<any>(`https://integration.services.amplifique.me/picpay/project?access_token=Bearer ${this.picPayTokenData.access_token}`).subscribe(
        (data) => {
          if (data.total > 0) {
            this.picpayFields = data.data[0];
            this.picpayFields.started_at = new Date(data.data[0].started_at);
            this.picpayFields.ended_at = new Date(data.data[0].ended_at);
            this.loading = false;
          }
          this.loading = false;
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Não foi possível buscar o projeto no PicPay!');
          this.loading = false;
          this.picpay_step = 1;
        }
      )
    );
  }

  savePicPayProject() {
    this.loading = true;
    const data = { ...this.picpayFields };
    data.started_at = data.started_at.toISOString().split('T')[0];
    data.ended_at = data.ended_at.toISOString().split('T')[0];
    let request;
    if (data.project_id) {
      request = this.http.put<any>(`https://integration.services.amplifique.me/picpay/project?access_token=Bearer ${this.picPayTokenData.access_token}`, data);
    } else {
      request = this.http.post<any>(`https://integration.services.amplifique.me/picpay/project?access_token=Bearer ${this.picPayTokenData.access_token}`, data);
    }

    this.subscriptions.push(
      request.subscribe(() => {
        this.store.business.partner_picpay.active = true;
        this.subscriptions.push(
          this.businessService.update(this.store.business).subscribe(() => {
            this.loading = false;
            const webhook = new WebhookEndpoint();
            webhook._business = this.store.business._id;
            webhook.active = true;
            webhook.description = 'PicPay Credit';
            webhook.enabled_events = ['new_milestone'];
            webhook.type = 'picpay-credit';
            webhook.endpoint_url = `https://integration.services.amplifique.me/picpay/webhook-handler?access_token=Bearer ${this.picPayTokenData.access_token}`;
            this.subscriptions.push(
              this.clientWebhookService.create(webhook).subscribe(
                () => {
                  this.notifyService.success('Configuração finalizada com sucesso!');
                  this.bsModalRef.hide();
                },
                (error) => {
                  console.error(error);
                  this.notifyService.error('Falha ao configurar Webhooks para o PicPay');
                }
              )
            );
          })
        );
      })
    );
  }

  getPipeDriveStages() {
    this.subscriptions.push(
      this.http.get<any>(`https://api.pipedrive.com/v1/stages?api_token=${this.store.business.partner_pipedrive_key}`).subscribe(
        (data: any) => {
          this.pipedriveStages = data.data;
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Não foi possível buscar os estágios!');
        }
      )
    );
  }

  generateElementorURL() {
    const url = new URL(`https://integration.services.amplifique.me/elementor/referral/${this.campaign}`);
    if (this.elementorParams.f_name.length > 0) {
      url.searchParams.append('f_name', this.elementorParams.f_name);
    }
    if (this.elementorParams.f_email.length > 0) {
      url.searchParams.append('f_email', this.elementorParams.f_email);
    }
    url.searchParams.append('access_token', 'Bearer ' + this.selectedToken);
    this.elementorParams.url = url.toString();
  }

  generateCustomerXURL() {
    const url = new URL(`https://integration.services.amplifique.me/customerx/cf/${this.customerXParams.event}`);
    if (this.customerXParams.f_journey.length > 0) {
      url.searchParams.append('f_journey', this.customerXParams.f_journey);
    }
    if (this.customerXParams.f_label.length > 0) {
      url.searchParams.append('f_label', this.customerXParams.f_label);
    }
    url.searchParams.append('access_token', 'Bearer ' + this.selectedToken);
    this.customerXParams.url = url.toString();
  }

  createNewTokenReferral() {
    const token = new Token();
    token.name = this.partner.internal_name;
    token.partner = this.partner.internal_name;
    //@ts-ignore
    token._business = this.store.business._id;
    token.action = {
      campaign: null,
      channel: '',
      type: 'referral',
      delay: null,
      delay_mode: null,
      specific_time: null,
      expiration: 0
    };
    token.force_rewrite = false;
    //@ts-ignore
    token._empresa = this.store.empresa;
    this.subscriptions.push(
      this.tokenService.create(token).subscribe((tokenData) => {
        this.loading = true;
        this.subscriptions.push(
          this.tokenService.getByPartner(this.store.business._id, this.partner.internal_name).subscribe(
            (data) => {
              if (data) {
                this.tokens = data;
                this.selectedToken = tokenData.access_token;
              }
              this.loading = false;
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Erro ao configurar');
              this.bsModalRef.hide();
            }
          )
        );
      })
    );
  }

  saveCMNetIntegration() {
    this.subscriptions.push(
      this.businessService.setIntegrationCMNet(this.store.business._id, this.totvsParams).subscribe(
        () => {
          this.notifyService.success('Integração realizada com sucesso!');
          this.bsModalRef.hide();
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Não foi possível salvar a integração, tente novamente.');
        }
      )
    );
  }

  public authenticateGoogle() {
    this.subscriptions.push(
      this.googleService
        .authenticateGoogleAccount(true)
        .then((googleData) => {
          if (googleData) {
            this.subscriptions.push(
              this.googleService.setupGoogleAccount(this.store.business._id, googleData).subscribe(() => {
                this.loading = true;
                this.store.business.gl_spreadsheet_active = true;
                delete this.store.business.gl_refresh_token;
                this.subscriptions.push(
                  this.businessService.update(this.store.business).subscribe(() => {
                    this.loading = true;
                    const token = new Token();
                    token.name = this.partner.internal_name;
                    token.partner = this.partner.internal_name;
                    //@ts-ignore
                    token._business = this.store.business._id;
                    token.action = {
                      campaign: null,
                      channel: '',
                      type: 'cf',
                      delay: null,
                      delay_mode: null,
                      specific_time: null,
                      expiration: 0
                    };
                    token.force_rewrite = false;
                    //@ts-ignore
                    token._empresa = this.store.empresa;
                    this.subscriptions.push(
                      this.tokenService.create(token).subscribe((tokenData) => {
                        const webhook = new WebhookEndpoint();
                        webhook._business = this.store.business._id;
                        webhook.active = true;
                        webhook.description = 'Google Sheets';
                        webhook.enabled_events = ['cf_response'];
                        webhook.type = 'google-sheets';
                        webhook.endpoint_url = `https://integration.services.amplifique.me/google-sheets/webhook-handler?access_token=Bearer+${tokenData.access_token}`;
                        this.subscriptions.push(
                          this.clientWebhookService.create(webhook).subscribe(() => {
                            this.loading = false;
                            this.bsModalRef.hide();
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          } else {
            this.notifyService.error('Não foi possível ativar a integração!');
          }
        })
        .catch((error) => {
          console.error(error);
          this.notifyService.error('Não foi possível ativar a integração!');
        })
    );
  }
}
