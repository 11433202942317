import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MixPanelService } from 'app/_services/mixPanel.service';
import { CompanyService } from 'app/_services/company.service';
import { StoreService } from 'app/_services/store.service';
import { Component, OnDestroy } from '@angular/core';
import { NotifyService } from 'app/_services';
import { Company } from 'app/_models/company';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent implements OnDestroy {
  constructor(
    private storage: StoreService,
    public bsModalRef: BsModalRef,
    private mixPanel: MixPanelService,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private companyService: CompanyService
  ) {}

  public isNew = true;
  public loading = false;
  public subscriptions = [];
  public allowSaveButton = true;
  public editCustomFieldIndex = -1;
  public company: Company = new Company();
  public result: Subject<Company> = new Subject();
  public maskCpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public maskCnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
    this.result?.complete();
  }

  maskCpfCnpj() {
    let replaceMask;
    return {
      mask: (value) => {
        replaceMask = value.replace(/\D+/g, '');
        if (replaceMask.length <= 11) {
          return this.maskCpf;
        } else {
          return this.maskCnpj;
        }
      },
      guide: true
    };
  }

  save() {
    this.loading = true;
    if (this.isNew) {
      this.mixPanel.track('Add Company');
      if (this.storage.business._empresa) {
        //@ts-ignore
        this.company._empresa = this.storage.business._empresa;
        this.subscriptions.push(
          this.companyService.create(this.company).subscribe({
            next: (data) => {
              this.notifyService.success('Empresa cadastrada com sucesso!');
              this.result.next(data);
              this.bsModalRef.hide();
            },
            error: (error) => {
              console.error(error);
              this.notifyService.error('Houve um erro ao cadastrar a empresa.');
              this.loading = false;
            }
          })
        );
      } else {
        this.notifyService.error('Houve um erro ao tentar ler alguns dados...');
        this.loading = false;
      }
    } else {
      this.mixPanel.track('Update Company');
      this.subscriptions.push(
        this.companyService.update(this.company).subscribe({
          next: () => {
            this.notifyService.success('Empresa atualizada com sucesso!');
            this.bsModalRef.hide();
          },
          error: (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao atualizar a empresa.');
            this.loading = false;
          }
        })
      );
    }
  }

  delete() {
    if (!this.isNew) {
      this.mixPanel.track('Delete Company');

      const initialState = {
        title: 'Excluir Empresa',
        message: 'Você tem certeza que deseja excluir a empresa ',
        strongText: this.company.name,
        messageEnd: ' e desatribuir todos os seus contatos?',
        forceConfirmation: true
      };

      const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
        initialState,
        class: 'amp-modal'
      });

      this.subscriptions.push(
        bsModalRef.content.resultado.subscribe((result) => {
          if (result) {
            this.subscriptions.push(
              this.companyService.delete(this.company._id).subscribe({
                next: () => {
                  this.notifyService.success('Empresa excluída com sucesso!');
                  this.bsModalRef.hide();
                  this.result.next(this.company);
                },
                error: (error) => {
                  console.error(error);
                  this.notifyService.error('Houve um erro ao excluir a empresa. Tente novamente ou entre em contato com o suporte.');
                }
              })
            );
          }
        })
      );
    }
  }
}
