<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-clock"></span>
      Programação de Exclusão de Conta
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
      <div class="">
        <p class="amp-guide-text"></p>
          <div class="amp-field">
            <label class="amp-label-text" for="title">Nome da Empresa</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="empresa.nome" readonly autocomplete="off" placeholder="" #title="ngModel" name="title" title>
          </div>
          <label class="amp-label-text" for="title">Data de exclusão</label>
          <input type="text" [(ngModel)]="empresa.delete_schedule_to" placeholder="Selecione data de exclusão a ser programada" class="form-control amp-input" name="deletionDate" [minDate]="minDate" [bsConfig]="{containerClass: 'theme-amp', showWeekNumbers: true, isAnimated:true}" bsDatepicker>
      </div>
      <div class="amp-buttons">
        <button type="submit" [disabled]="!empresa.delete_schedule_to" class="amp-btn-outline-danger amp-btn-lg">{{alreadyScheduled ? 'Reagendar Exclusão' : 'Agendar Exclusão'}}</button>
        <button type="button"  *ngIf="empresa.delete_schedule_to"  (click)="cancelSchedule()" class="amp-btn-outline-primary amp-btn-lg">Cancelar Exclusão</button>
      </div>
    </form>
  </div>
</div>
