export const environment = {
  production: false,
  homolog: false,
  beta: false,
  apiURL: 'http://localhost:3000',
  termsVersion: 3,
  privacyVersion: 2,
  // apiURL: 'http://192.168.0.158:3000',
  // apiURL: 'https://dev-backend-dot-amplifique-me.appspot.com'
  // apiURL: 'https://api.dev.amplifique.me',
  // apiURL: 'https://api.homolog.amplifique.me',
  // apiURL: 'http://mac-robson.amplifiqueme.com.br:3000',
  //  apiURL: 'https://eccc0e6e67c3.ngrok.io',
  //  apiURL: 'http://mac-robson.local:3000',
  ably_key: 'HtRrPw.71B4cw:E8XBOEIGODc40MgoRxB0GVVX6zZn8V_FkA7Uqar_YkQ',
  stripe_pk: 'pk_live_XLzVLKoHrbAkoK6CSbputvMA',
  novu_key: '69NK2SHkvpIs' //prod
  // novu_key: 'RaBQdFH4YYbf'
};
