import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  upload(upload_url: string, key: string, file: File) {
    return this.http.put(upload_url, file, {
      headers: {
        'Content-Type': file.type
      },
      reportProgress: true,
      observe: 'events'
    });
  }

  getSignedUploadUrl(type) {
    return this.http.get<any>(this.config.apiUrl + `/storage/signedUrl?type=${type}`, {});
  }

  generatePublicReadURL(type, url) {
    return this.http.get<any>(this.config.apiUrl + `/storage/signed-read-url?type=${type}&url=${url}`, {});
  }
}
