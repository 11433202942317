import { Component, OnDestroy } from '@angular/core';
import { NotifyService } from 'app/_services';
import { SurveyAnswerService } from 'app/_services/customerFeedback/surveyAnswer.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-forward-dialog',
  templateUrl: './forward-dialog.component.html',
  styleUrls: ['./forward-dialog.component.scss']
})
export class ForwardDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private notifyService: NotifyService, private surveyAnswerService: SurveyAnswerService) {}

  public surveyId;
  public email = [];
  public emailString;
  public subscriptions = [];

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  validateEmail(event) {
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,20}$/.test(event)) {
      this.notifyService.warning('E-mail inválido!');
      this.email.pop();
    }
  }

  forwardAnswer() {
    this.emailString = this.email.join(',');

    this.subscriptions.push(
      this.surveyAnswerService.sendToEmails(this.surveyId, this.emailString).subscribe(
        () => {
          if (this.email.length == 1) {
            this.notifyService.success('E-mail enviado com sucesso!');
          } else {
            this.notifyService.success('E-mails enviados com sucesso!');
          }

          this.bsModalRef.hide();
        },
        (error) => {
          console.error(error);
          if (this.email.length == 1) {
            this.notifyService.error('Não foi possível enviar o e-mail, tente novamente ou entre em contato com o suporte.');
          } else {
            this.notifyService.error('Não foi possível enviar os e-mails, tente novamente ou entre em contato com o suporte.');
          }
        }
      )
    );
  }
}
