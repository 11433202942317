import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'urlStringify'
})
export class UrlStringifyPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return value.replace(/^\/\/|^.*?:\/\//, '');
    }
    return null;
  }
}
