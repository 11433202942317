import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CouponService } from './../../_services/referral/coupon.service';
import { Reward } from 'app/_models/referral/reward';
import { CampaignService, NotifyService } from 'app/_services';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Coupon } from 'app/_models/referral/coupon';
import { Referral } from 'app/_models/referral/referral';
import { Campaign } from 'app/_models/referral/campaign';

const md5 = window['md5'] || [];
@Component({
  selector: 'app-milestones-customer-dialog',
  templateUrl: './milestones-customer-dialog.component.html',
  styleUrls: ['./milestones-customer-dialog.component.scss']
})
export class MilestonesCustomerDialogComponent implements OnInit, OnDestroy {
  public data = {
    _id: '',
    total_shares: 0,
    total_link_click: 0,
    shares: {},
    reward_redeemed: 0,
    reward_pending: 0,
    referral_confirmed: 0,
    referral_pending: 0,
    customer_name: '',
    customer_email: '',
    customer_id: '',
    created_at: new Date(),
    code: '',
    channels: {}
  };

  public coupon: Coupon;
  public referrals: Referral[];
  public rewards: Reward[];
  public campaign: Campaign;
  public step = 0;
  public subscriptions = [];

  constructor(
    public bsModalRef: BsModalRef,
    public couponService: CouponService,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private campaignService: CampaignService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.couponService.getCouponDetails(this.data._id).subscribe((data) => {
        this.referrals = data.referrals;
        this.rewards = data.rewards;
        this.coupon = data.coupon;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getGravatar(email) {
    if (!email) {
      return 'https://app.amplifique.me/assets/img/user.png';
    } else {
      return `https://www.gravatar.com/avatar/${md5(email)}?d=${encodeURIComponent('https://app.amplifique.me/assets/img/user.png')}`;
    }
  }

  resendRewardMail(rewardId) {
    const title = 'Reenviar E-mail de Recompensa';
    const message = 'Tem certeza que deseja reenviar o e-mail de recompensa?';
    const initialState = {
      title: title,
      message: message,
      buttonName: 'Reenviar'
    };

    const bsModalRef: BsModalRef = this.modalService.show(ConfirmDialogComponent, {
      ignoreBackdropClick: true,
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.result.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.campaignService.resendMilestoneRewards(rewardId).subscribe(
              () => {
                this.notifyService.success('E-mail reenviado com sucesso!');
              },
              (error) => {
                console.error(error);
                this.notifyService.error('Houve um erro ao reenviar o e-mail.');
              }
            )
          );
        }
      })
    );
  }
}
