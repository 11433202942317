import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotifyService, StoreService } from 'app/_services';
import { ReviewPlatformService } from 'app/_services/reviewPlatform.service';
import { ReviewPlatform } from 'app/_models/reviewPlatform';
@Component({
  selector: 'app-network-selector-dialog',
  templateUrl: './network-selector-dialog.component.html',
  styleUrls: ['./network-selector-dialog.component.scss']
})
export class NetworkSelectorDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public notifyService: NotifyService,
    private translate: TranslateService,
    private store: StoreService,
    private reviewPlatformService: ReviewPlatformService
  ) {}

  public fbChecked = false;
  public glChecked = false;
  public tripChecked = false;
  public options: any = [];
  public available_links: any;
  public selectedAll = false;
  public searchField = '';
  public surveyFilter = 'all';
  public loading = false;
  public array = [];
  public resultado: Subject<any> = new Subject();
  public subscriptions = [];
  public reviewPlatforms: ReviewPlatform[] = [];

  ngOnInit() {
    this.loadPlatforms();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
    this.resultado?.complete();
  }

  loadPlatforms() {
    this.loading = true;
    this.subscriptions.push(
      this.reviewPlatformService.get(this.store.business._id, undefined, true).subscribe(
        async (data) => {
          this.reviewPlatforms = data;
          this.loading = false;
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  getPlatformName(platform) {
    if (platform == 'booking') return 'Booking';
    if (platform == 'tripadvisor') return 'TripAdvisor';
    if (platform == 'facebook') return 'Facebook';
    if (platform == 'hotelscom') return 'Hoteis.com';
    if (platform == 'google') return 'Google';
    if (platform == 'despegar') return 'Decolar';
    if (platform == 'expedia') return 'Expedia';
    if (platform == 'airbnb') return 'Airbnb';
  }

  togglePlatform(reviewPlatform: ReviewPlatform) {
    let i;
    if ((i = this.options.indexOf(reviewPlatform._id)) > -1) {
      this.options.splice(i, 1);
    } else {
      this.options.push(reviewPlatform._id);
    }
  }

  checkPlatformIncluded(reviewPlatform) {
    return this.options.find((item) => item == reviewPlatform._id) == undefined ? false : true;
  }

  send() {
    if (this.options.length) {
      this.resultado.next(this.options);
      this.bsModalRef.hide();
    } else {
      this.subscriptions.push(
        this.translate.get('notify.network-selector.choose_one_network_error').subscribe((res) => {
          this.notifyService.warning(res);
        })
      );
    }
  }
}
