import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services';
import { MenuService } from 'app/_services/menu.service';
@Component({
  selector: 'app-video-player-dialog',
  templateUrl: './video-player-dialog.component.html',
  styleUrls: ['./video-player-dialog.component.scss']
})
export class VideoPlayerDialogComponent {
  constructor(public bsModalRef: BsModalRef, public menuService: MenuService, public store: StoreService) {}

  public url;
}
