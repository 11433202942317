<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div *ngIf="isNew" class="amp-dialog-title">{{ isNew ? 'Adicionar' : 'Editar' }} Webhook</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-field">
      <label class="amp-label-text">URL do endpoint</label>
      <input [(ngModel)]="url" type="text" placeholder="Ex.: https://example.com/" [(ngModel)]="clientWebhook.endpoint_url" class="amp-input-lg">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Descrição</label>
      <input [(ngModel)]="description" placeholder="Ex.: Usuários Cadastrados" type="text" [(ngModel)]="clientWebhook.description" class="amp-input-lg">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">E-mail para receber atualizações de status do Webhook</label>
      <input type="text" (keyup)="removeSpaces($event)" class="amp-input-lg" [(ngModel)]="clientWebhook.email" #email="ngModel" name="email" placeholder="Digite aqui o e-mail do responsável" />
    </div>

    <div class="amp-field amp-select-new">
      <label class="amp-label-text">Eventos a serem enviados</label>
      <select (change)="addEvent(eventList)" [(ngModel)]="eventList" class="amp-input-lg">
        <option value="" selected>Selecionar eventos</option>
        <option *ngFor="let event of events" [value]="event.name">{{event.name}}</option>
      </select>
      <span class="bi-chevron-down"></span>
    </div>

    <div class="amp-events">
      <div *ngIf="!clientWebhook.enabled_events.length" class="amp-event-placeholder">
        <span>Nenhum evento selecionado.</span>
      </div>

      <div *ngFor="let event of clientWebhook.enabled_events" class="amp-event">
        <span>{{ event }}</span>
        <i (click)="removeEvent(event)" class="bi-x-lg"></i>
      </div>
    </div>

    <button [disabled]="!clientWebhook.endpoint_url.length || !clientWebhook.description.length || !clientWebhook.enabled_events.length || !clientWebhook.email?.length " (click)="saveWebhook()" class="amp-btn-lg">{{ isNew ? 'Adicionar' : 'Salvar' }}</button>
  </div>
</div>