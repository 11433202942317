import { AuthenticationService, EmpresaService, SignupService, StoreService, UserService } from 'app/_services';
import { MixPanelService } from 'app/_services/mixPanel.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'app/_services/menu.service';
import { debounceTime } from 'rxjs/operators';
import { Role, User } from 'app/_models/user';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private signupService: SignupService,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private userService: UserService,
    private router: Router,
    private store: StoreService,
    private empresaService: EmpresaService,
    private mixPanelService: MixPanelService
  ) {}

  public model: any = {};
  public modelEmpresa: any = { business_size: '', business_area: '', interests: '' };
  public loading = false;
  public fail = false;
  public returnUrl: string;
  public forgotPassword: any = 0;
  public showPassword = false;
  public step = 0;
  public editFilterIndex = -1;
  public filters: any = { value: [] };
  public filterObject: any = {};
  public pinError;
  public invites = [{ email: '' }, { email: '' }, { email: '' }];
  public isCreatingContact = false;
  public isCreatingCompany = false;
  public subscriptions = [];
  public maskPin = [/\d/, /\d/, /\d/, /\d/];
  public maskTel = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public pinId;
  public pin;
  public forbiddenEmail = false;
  public hasDot = false;
  public gclid;
  public queryParams;
  public newContact = true;
  public contactId = null;
  public newEmpresa = true;
  public empresaId = null;
  private subjectContact: Subject<string> = new Subject();
  private subjectCompany: Subject<string> = new Subject();
  private forbiddenMailProviders = [
    'gmail.com',
    'gmail.com.br',
    'hotmail.com',
    'hotmail.com.br',
    'outlook.com',
    'outlook.com.br',
    'live.com',
    'live.com.br',
    'yahoo.com',
    'yahoo.com.br',
    'uol.com',
    'uol.com.br',
    'bol.com',
    'bol.com.br',
    'icloud.com',
    'icloud.com.br',
    'yopmail.com',
    'yopmail.com.br',
    'ymail.com',
    'ymail.com.br',
    'cupbest.com',
    'cupbest.com.br',
    'getnada.com',
    'getnada.com.br',
    'aol.com',
    'aol.com.br'
  ];

  ngOnInit() {
    this.subscriptions.push(
      this.subjectContact.pipe(debounceTime(1000)).subscribe(() => {
        this.saveContactData();
      })
    );
    this.subscriptions.push(
      this.subjectCompany.pipe(debounceTime(1000)).subscribe(() => {
        this.saveCompanyData();
      })
    );
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  login() {
    this.subscriptions.push(
      this.subscriptions.push(
        this.authenticationService.login(this.model.email, this.model.senha, false).subscribe((data) => {
          if (data) {
            this.mixPanelService.track('Create Account - Auto Login Success');
            this.mixPanelService.trackDataLayer('create-account-auto-login-success');
            this.subscriptions.push(
              this.userService.getInfo().subscribe((dadosUser: User) => {
                if (dadosUser.role == Role.mf) {
                  this.returnUrl = '/ampemp';
                } else {
                  localStorage.setItem('empresaNome', dadosUser._empresa.nome);
                  localStorage.setItem('empresaId', dadosUser._empresa._id);
                  this.store.empresa = dadosUser._empresa._id;
                  localStorage.setItem('empresaWizard', dadosUser._empresa.wizard.toString());
                }
                this.menuService.emitUser(dadosUser);
                return this.router.navigate([this.returnUrl]);
              })
            );
          }
        })
      )
    );
  }

  addField() {
    this.invites.push({ email: '' });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    const field = document.getElementById('password') as HTMLInputElement;
    if (field.type == 'password') {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
  }

  stopCloseDropdown(event) {
    event.stopPropagation();
  }

  verifyEmail() {
    this.mixPanelService.track('Create Account - Request Verify Email');
    this.mixPanelService.trackDataLayer('create-account-request-verify-email');
    this.mixPanelService.trackDataLayer('contact_email', this.model.email);
    this.loading = true;

    this.subscriptions.push(
      this.signupService.sendPublicMailForm(this.model.email).subscribe(
        (data) => {
          this.pinId = data.pinId;
          this.step = 1;
          this.loading = false;
          this.mixPanelService.track('Create Account - Verify Email Sent');
          this.mixPanelService.trackDataLayer('create-account-verify-email-sent');
        },
        (error) => {
          console.error(error);
          if (error.error.code == 'EMAIL_ALREADY_SIGNUP') {
            this.step = 5;
            this.mixPanelService.track('Create Account - E-mail Already Signed up');
            this.mixPanelService.trackDataLayer('create-account-e-mail-already-signed-up');
          }
        }
      )
    );
  }

  goToLogin() {
    this.router.navigate(['/login'], {
      queryParams: {
        email: this.model.email
      }
    });
  }

  verifyPin() {
    this.mixPanelService.track('Create Account - Pin Verification Sent');
    this.mixPanelService.trackDataLayer('create-account-pin-verification-sent');
    this.loading = true;
    this.pinError = false;
    this.subscriptions.push(
      this.signupService.verifyPin(this.pinId, this.pin, this.model.email).subscribe(
        (data) => {
          this.mixPanelService.track('Create Account - Pin Verification Success');
          this.mixPanelService.trackDataLayer('create-account-pin-verification-success');
          if (data.user) {
            this.model.email = data.user.email;
            this.model.nome = data.user.nome;
            this.model.phone = data.user.phone;
            this.contactId = data.user._id;
            this.newContact = false;
            if (data.user._empresa && data.user._empresa != null) {
              this.modelEmpresa = data.user._empresa;
              this.empresaId = data.user._empresa._id;
              this.newEmpresa = false;
              this.mixPanelService.setBusiness(null, this.modelEmpresa.nome);
              this.mixPanelService.track('Create Account - Continue a Signup stopped on Empresa Step');
              this.mixPanelService.trackDataLayer('create-account-continue-a-signup-stopped-on-empresa-step');
            } else {
              this.mixPanelService.track('Create Account - Continue a Signup stopped on User Step');
              this.mixPanelService.trackDataLayer('create-account-continue-a-signup-stopped-on-user-step');
            }
          }
          this.step = 2;
          this.loading = false;
        },
        (error) => {
          console.error(error);
          this.mixPanelService.track('Create Account - Pin Verification Error');
          this.mixPanelService.trackDataLayer('create-account-pin-verification-error');
          this.loading = false;
          if (error.error.invalid) {
            this.pinError = true;
          }
        }
      )
    );
  }

  openChat() {
    this.mixPanelService.track('Create Account - Open Chat');
    this.mixPanelService.trackDataLayer('create-account-open-Chat');
    this.menuService.emitChat(true);
  }

  onContactChange() {
    this.subjectContact.next('a');
  }

  onCompanyChange() {
    this.subjectCompany.next('a');
  }

  saveContactData() {
    this.mixPanelService.track('Create Account - Save Contact Data');
    if (this.isCreatingContact) {
      return setTimeout(() => this.saveContactData(), 2500);
    }

    if (this.newContact) {
      this.isCreatingContact = true;
      this.gclid = this.route.snapshot.queryParams['gclid'] ? this.route.snapshot.queryParams['gclid'] : '';
      this.queryParams = {
        utm_content: this.route.snapshot.queryParams['utm_content'],
        utm_term: this.route.snapshot.queryParams['utm_term'],
        utm_campaign: this.route.snapshot.queryParams['utm_campaign'],
        utm_source: this.route.snapshot.queryParams['utm_source'],
        utm_medium: this.route.snapshot.queryParams['utm_medium']
      };
      this.subscriptions.push(
        this.userService.createPublic(this.model, this.gclid, this.queryParams).subscribe(
          (data) => {
            this.newContact = false;
            this.isCreatingContact = false;
            this.contactId = data._id;
          },
          (error) => {
            console.error(error);
          }
        )
      );
    } else if (this.contactId != null) {
      this.subscriptions.push(
        this.userService.updatePublic(this.contactId, this.model).subscribe(
          () => {},
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  saveCompanyData() {
    this.mixPanelService.setBusiness(null, this.modelEmpresa.nome);
    this.mixPanelService.track('Create Account - Save Company Data');
    this.mixPanelService.trackDataLayer('create-account-save-company-data');

    if (this.modelEmpresa.business_area) {
      this.mixPanelService.trackDataLayer('business_area', this.modelEmpresa.business_area);
    }

    if (this.modelEmpresa.business_size) {
      this.mixPanelService.trackDataLayer('business_size', this.modelEmpresa.business_size);
    }

    if (this.modelEmpresa.interests) {
      this.mixPanelService.trackDataLayer('interests', this.modelEmpresa.interests);
    }

    if (this.isCreatingCompany) {
      return setTimeout(() => this.saveCompanyData(), 2500);
    }

    if (this.newEmpresa) {
      this.isCreatingCompany = true;
      this.subscriptions.push(
        this.empresaService
          .createPublic({
            nome: this.modelEmpresa.nome,
            business_area: this.modelEmpresa.business_area,
            business_size: this.modelEmpresa.business_size,
            interests: this.modelEmpresa.interests,
            email: this.model.email
          })
          .subscribe(
            (data) => {
              this.isCreatingCompany = false;
              this.empresaId = data._id;
              this.newEmpresa = false;

              this.model.interests = this.modelEmpresa.interests;
              this.model._empresa = this.empresaId;
              this.saveContactData();
            },
            (error) => {
              console.error(error);
            }
          )
      );
    } else {
      if (this.empresaId != null) {
        this.subscriptions.push(
          this.empresaService
            .updatePublic(this.empresaId, {
              nome: this.modelEmpresa.nome,
              business_area: this.modelEmpresa.business_area,
              business_size: this.modelEmpresa.business_size,
              interests: this.modelEmpresa.interests,
              email: this.model.email
            })
            .subscribe(() => {
              this.model.interests = this.modelEmpresa.interests;
              this.saveContactData();
            })
        );
      }
    }
  }

  finishEmpresa() {
    if (this.empresaId) {
      this.loading = true;
      this.subscriptions.push(
        this.empresaService.startTrial(this.empresaId, this.model.email).subscribe((data) => {
          if (data.trial_expiration_date) {
            this.mixPanelService.trackDataLayer('end_free_trial', data.trial_expiration_date);
          }
          this.model.active = true;
          this.step = 4;
          this.loading = false;
          this.saveContactData();
          this.mixPanelService.track('Create Account - Started Trial');
          this.mixPanelService.trackDataLayer('create-account-started-trial');
        })
      );
    }
  }

  inviteUsers() {
    let invites: any[] = this.invites.map((item) => item.email);

    invites = this.remove_duplicates_es6(invites);

    this.subscriptions.push(
      this.signupService.createBatch(invites, this.empresaId).subscribe(() => {
        this.mixPanelService.track('Create Account - Invited Users', { invites: invites.length });
        this.mixPanelService.trackDataLayer('create-account-invited-users');
      })
    );
    this.login();
  }

  remove_duplicates_es6(arr) {
    const s = new Set(arr);
    const it = s.values();
    return Array.from(it);
  }

  trackEvent(event) {
    this.mixPanelService.trackDataLayer(event);
  }

  changePin(data) {
    this.pin = data;
    if (data.length == 4) {
      this.verifyPin();
    }
  }

  checkDot() {
    if (this.model.email.split('@')[1]?.includes('.')) {
      this.hasDot = true;
    } else {
      this.hasDot = false;
    }
  }

  checkEmailProvider() {
    const items = this.forbiddenMailProviders.join('|');
    const regex = new RegExp(`(${items})`, 'gm');
    this.forbiddenEmail = regex.test(this.model.email.toLowerCase());
  }
}
