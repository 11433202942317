import { CouponService } from '../../_services/referral/coupon.service';
import { MenuService } from '../../_services/menu.service';
import { Component, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services/store.service';
@Component({
  selector: 'app-unblock-participant-dialog',
  templateUrl: './unblock-participant-dialog.component.html',
  styleUrls: ['./unblock-participant-dialog.component.scss']
})
export class UnblockParticipantDialogComponent implements OnDestroy {
  public referralStatus;
  public referralValue;
  public reason;
  public id;
  public status;
  public subscriptions = [];

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public store: StoreService,
    private menuService: MenuService,
    private couponService: CouponService
  ) {
    this.menuService.emit(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  unblockParticipant() {
    this.subscriptions.push(
      this.couponService.changeBlockStatus(this.id, this.status).subscribe((data) => {
        this.status = data.blocked;
        this.bsModalRef.hide();
      })
    );
  }
}
