import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import SurveyGroup from 'app/_models/customerFeedback/surveyGroup';
import { Survey } from 'app/_models/customerFeedback/survey';
@Injectable({ providedIn: 'root' })
export class SurveyGroupService {
  constructor(private httpClient: HttpClient, private config: AppConfig) {}
  create(surveyGroup: SurveyGroup) {
    return this.httpClient.post<SurveyGroup>(this.config.apiUrl + '/cf/surveyGroup', surveyGroup);
  }

  rename(surveyGroup: SurveyGroup) {
    return this.httpClient.put<SurveyGroup>(this.config.apiUrl + `/cf/surveyGroup/${surveyGroup._id}`, surveyGroup);
  }

  delete(surveyGroup: SurveyGroup) {
    return this.httpClient.delete<SurveyGroup>(this.config.apiUrl + `/cf/surveyGroup/${surveyGroup._id}`);
  }

  updateQuestionsGroups(surveyGroupId, questions_groups) {
    return this.httpClient.put<SurveyGroup>(this.config.apiUrl + `/cf/surveyGroup/${surveyGroupId}/questions-groups`, { questions_groups });
  }

  list(business: string) {
    return this.httpClient.get<SurveyGroup[]>(this.config.apiUrl + `/cf/surveyGroup/${business}/list`);
  }

  listMatchingSurveys(hash: string, _empresa: string) {
    const urlParams = new URLSearchParams();
    urlParams.set('hash', hash);
    urlParams.set('empresa', _empresa);
    return this.httpClient.get<Survey[]>(this.config.apiUrl + `/cf/surveyGroup/list-matching-surveys?${urlParams.toString()}`);
  }
}
