<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Vamos começar?
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <div class="amp-content-text"> Agora que você criou o seu Programa de Indicação, e habilitou o Widget, você deve inserir o snippet abaixo no seu site ou na página informada: {{campaign.widget_url}}
<br><br>
Estamos a disposição para auxiliar em seus primeiros dias de uso, qualquer dúvida é só chamar no chat ou enviar um email para <a src="mailto:ajuda@amplifique.me" >ajuda&#64;amplifique.me</a>.</div>
     <div class="amp-field block amp-js">
          <textarea class="amp-input" readonly="readonly"
            [(ngModel)]="script" rows="10"></textarea>
      </div>
      <button class="amp-btn-warning amp-btn-lg" (click)="bsModalRef.hide()">Começar a Amplificar!</button>
</div>
