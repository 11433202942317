import { User } from '../user';
export class Template {
  _id: string;
  title: string;
  description: string;
  icon: string;
  type = 'nps';
  subtype: string;
  uses: number;
  json: object;
  active: boolean;
  _user: User;
  created_at: Date;
}
