<section id="login" class="row">
  <div class="col login-card-wrapper">
    <img src="/assets/img/logo_amplifiqueme.svg">

    <div *ngIf="step == 0" class="amp-card login__card">
      <div class="amp-card-content">
        <div>
          <h3 class="amp-title text-center">Criar conta na Amplifique.me</h3>
          <p class="amp-subtitle">Digite seu e-mail profissional para começar</p>
          <form (ngSubmit)="f.form.valid && !loading && !forbiddenEmail && verifyEmail()" #f="ngForm">
            <div class="amp-email">
              <input type="email" class="amp-input-lg lowercase" (keyup)="checkEmailProvider();checkDot()" [(ngModel)]="model.email" [placeholder]="'seunome@empresa.com'" #email="ngModel" name="email" email required>
              <i class="bi-envelope"></i>
            </div>
            <div class="amp-warning-message" *ngIf="email.invalid && (email.dirty || email.touched) || forbiddenEmail || !hasDot">
              <span *ngIf="email.errors?.email">
                Por favor, insira um e-mail válido.
              </span>
              <span *ngIf="forbiddenEmail">
                Por favor, insira um e-mail profissional.
              </span>
            </div>
            <button *ngIf="!loading" [disabled]="!f.form.valid || forbiddenEmail || !hasDot" type="submit" class="amp-btn-lg btn-begin">
              <span>Começar</span>
              <i class="bi-arrow-right"></i>
            </button>
            <button *ngIf="loading" type="button" class="amp-btn-lg btn-begin">
              <i class="loader"></i>
            </button>
            <a href='/login' class="amp-login">
              Voltar para login
            </a>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="step == 1" class="amp-card login__card">
      <div class="amp-card-content">
        <div>
          <h3 class="amp-title text-center">Verificar E-mail</h3>
          <p class="amp-subtitle">Enviamos um código de verificação com 4 dígitos no seu e-mail, insira-o abaixo.<br><br>Caso não o encontre, verifique a sua caixa de spam.</p>
          <form (ngSubmit)="f.form.valid && !loading && verifyPin()" #f="ngForm" novalidate>
            <div class="amp-field amp-email">
              <input type="text" inputmode="numeric" autofocus  mask="0000" (ngModelChange)="changePin($event)" class="amp-input-lg amp-onetime-code-input" autocomplete="one-time-code" maxlength="4" pattern="[0-9][0-9][0-9][0-9]" [(ngModel)]="pin" #pinField="ngModel" name="pin" required>
              <i class="bi-lock"></i>
              <div class="amp-field-message error" *ngIf="pinError"><span  class="amp-field-message-icon bi-x-circle-fill"></span><span >Código inválido!</span></div>
            </div>
            <button [disabled]="!f.form.valid" type="submit" class="amp-btn-lg btn-enter">
              <span *ngIf="!loading">Validar</span>
              <i *ngIf="!loading" class="bi-check-lg"></i>
              <i *ngIf="loading" class="loader"></i>
            </button>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="step == 2" class="amp-card login__card">
      <div class="amp-bullet">
        <span>Passo 1 de 3</span>
      </div>
      <div class="amp-card-content">
        <div *ngIf="!forgotPassword" class="animate__animated" [ngClass]="{'animate__shake': fail,'animate__fadeIn': !fail && !forgotPassword}">
          <h3 class="amp-title text-center">Seus dados</h3>
          <p class="amp-subtitle">Agora, digite seu nome, seu celular e crie sua senha</p>
          <form (ngSubmit)="f.form.valid" #f="ngForm" autocomplete="off" novalidate>
            <div class="amp-field amp-email">
              <input type="text" class="amp-input-lg" [(ngModel)]="model.nome" (keyup)="onContactChange()" [placeholder]="'Nome completo'" #nome="ngModel" name="name" required>
              <i class="bi-person"></i>
            </div>
            <div class="amp-field amp-email">
              <input type="tel" class="amp-input-lg"  mask="(00) 0 0000-0000" [(ngModel)]="model.phone" (keyup)="onContactChange()" [placeholder]="'Celular'" #phone="ngModel" name="phone" required>
              <i class="bi-phone"></i>
            </div>
            <div class="amp-field amp-password">
              <input type="password" autocomplete="false" id="password" class="amp-input-lg" clickOutside (clickOutside)="dropdownFilter.toggle(true)" (focus)="dropdownFilter.toggle(true)" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!#%*?&])[A-Za-z\d$@$!#%*?&].{8,}" (keyup)="onContactChange()" [(ngModel)]="model.senha" [placeholder]="'Crie sua senha'" #password="ngModel" name="password" required>
              <i *ngIf="showPassword" (click)="togglePassword()" class="bi-eye"></i>
              <i *ngIf="!showPassword" (click)="togglePassword()" class="bi-eye-slash"></i>
            </div>
            <div dropdown [autoClose]="false" class="amp-password-validator dropdown" #dropdownFilter="bs-dropdown" placement="left" aria-controls="filter-dropdown">
              <div class="dropdown-menu dropdown-menu-left sortOptions" id="passwordValidator" *dropdownMenu role="menu" aria-labelledby="dropdownFilter" (click)="stopCloseDropdown($event)">
                <password-validator [password]="model.senha"></password-validator>
              </div>
            </div>
            <div class="amp-terms-of-use">
              <label class="amp-checkbox">
                <input [(ngModel)]="model.termsofuse" type="checkbox" name="termsOfUse" required>
                <span class="text">Declaro que li e aceito os&nbsp;</span>
                <a target="_blank" rel="noopener" href="https://amplifique.me/termos-de-uso/">Termos de Uso</a>
                <span class="checkmark"></span>
              </label>
            </div>
            <button [disabled]="!f.form.valid" (click)="step = 3;trackEvent('create-account-save-contact-data')" type="submit" class="amp-btn-lg btn-enter">
              <span *ngIf="!loading">Próximo</span>
              <i *ngIf="!loading" class="bi-arrow-right"></i>
              <i *ngIf="loading" class="loader"></i>
            </button>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="step == 3" class="amp-card login__card">
      <div class="amp-bullet">
        <span>Passo 2 de 3</span>
      </div>
      <div class="amp-card-content">
        <div *ngIf="!forgotPassword" class="animate__animated" [ngClass]="{'animate__shake': fail,'animate__fadeIn': !fail && !forgotPassword}">
          <h3 class="amp-title text-center">Informações da Empresa</h3>
          <p class="amp-subtitle">Preencha os dados da sua empresa e seus interesses</p>
          <form (ngSubmit)="f.form.valid && !loading && finishEmpresa()" #f="ngForm" novalidate>
            <div class="amp-field amp-email">
              <input type="text" class="amp-input-lg" (keyup)="onCompanyChange()" [(ngModel)]="modelEmpresa.nome" [placeholder]="'Nome da empresa'" minlength="3" #companyName="ngModel" name="companyName" required>
              <i class="bi-briefcase"></i>
            </div>
            <div class="amp-field amp-email">
              <select class="amp-input" (change)="onCompanyChange()" [(ngModel)]="modelEmpresa.business_area" #businessArea="ngModel" name="businessArea" required>
                <option value="" [ngValue]="" disabled selected>Ramo de atividade</option>
                <option value="hotelaria">Hotelaria</option>
                <option value="industria">Indústria</option>
                <option value="agronegocio">Agronegócio</option>
                <option value="ecommerce">E-commerce</option>
                <option value="software">Software</option>
                <option value="bares-e-restaurantes">Bares e restaurantes</option>
                <option value="alimentacao-e-bebidas">Alimentação e bebidas</option>
                <option value="vestuario-e-calcados">Vestuário e calçados</option>
                <option value="construcao">Construção</option>
                <option value="saude">Saúde</option>
                <option value="educacao">Educação</option>
                <option value="servicos-pessoais">Serviços pessoais</option>
                <option value="servicos-especializados">Serviços especializados</option>
                <option value="informatica">Informática</option>
                <option value="vendas-e-marketing">Vendas e marketing</option>
                <option value="entretenimento">Entretenimento</option>
                <option value="consultoria">Consultoria</option>
                <option value="financeiro">Financeiro</option>
                <option value="outro">Outro</option>
              </select>
              <i class="bi-chevron-down"></i>
            </div>

            <div class="amp-field amp-business-size">
              <select class="amp-input" (change)="onCompanyChange()" [(ngModel)]="modelEmpresa.business_size" #companySize="ngModel" name="companySize" required>
                <option value="" [ngValue]="" disabled selected>Número de funcionários</option>
                <option value="1-10">1 - 10</option>
                <option value="11-50">11 - 50</option>
                <option value="51-100">51 - 100</option>
                <option value="100">+100</option>
              </select>
              <i class="bi-chevron-down"></i>
            </div>

            <div class="amp-field amp-email">
              <input type="text" class="amp-input-lg" (keyup)="onContactChange()" [(ngModel)]="model.business_position" [placeholder]="'Seu cargo'" #businessPosition="ngModel" name="businessPosition" required>
              <i class="bi-person-check"></i>
            </div>

            <div class="amp-field amp-business-size">
              <select class="amp-input" (change)="onCompanyChange()" [(ngModel)]="modelEmpresa.interests" #interest="ngModel" name="interests" required>
                <option value="" [ngValue]="" disabled selected>Selecione seu principal interesse</option>
                <option value="Fazer Pesquisas de Satisfação">Fazer Pesquisas de Satisfação</option>
                <option value="Gerenciar Avaliações Online">Gerenciar Avaliações Online</option>
              </select>
              <i class="bi-chevron-down"></i>
            </div>

            <button *ngIf="!loading" [disabled]="!f.form.valid" type="submit" class="amp-btn-lg btn-enter">
              <span>Próximo</span>
              <i class="bi-arrow-right"></i>
            </button>
            <button *ngIf="loading" type="button" class="amp-btn-lg btn-begin">
              <i class="loader"></i>
            </button>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="step == 4" class="amp-card login__card">
      <div class="amp-bullet">
        <span>Passo 3 de 3</span>
      </div>
      <div class="amp-card-content">
        <div *ngIf="!forgotPassword" class="animate__animated" [ngClass]="{'animate__shake': fail,'animate__fadeIn': !fail && !forgotPassword}">
          <h3 class="amp-title text-center">Trabalhar em equipe é melhor!</h3>
          <p class="amp-subtitle">Insira o e-mail dos seus colegas para eles experimentarem a Amplifique.me junto com você!</p>
          <form id="form" (ngSubmit)="f.form.valid && !loading && login()" #f="ngForm" novalidate>
            <div *ngFor="let invite of invites; let idx = index" class="amp-field amp-email">
              <input type="email" class="amp-input-lg" [(ngModel)]="invite.email" [placeholder]="'E-mail do colega ' + (idx + 1)" [name]="idx+'-email'" email required>
              <i class="bi-envelope"></i>
            </div>
            <a (click)="addField()" class="amp-emails">
              Adicionar mais e-mails
            </a>
            <button (click)="inviteUsers()" class="amp-btn-lg btn-finish">Enviar convite e começar a usar!</button>
            <a (click)="login()" class="amp-login">
              Pular essa etapa
            </a>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="step == 5" class="amp-card login__card">
      <div class="amp-card-content">
        <div>
          <h3 class="amp-title text-center">Conta já existente!</h3>
          <p class="amp-subtitle">Já existe uma conta com o e-mail informado.</p>
          <button type="submit" class="amp-btn-lg btn-enter">
            <span (click)="goToLogin()">Ir para Login</span>
          </button>
        </div>
      </div>
    </div>

    <div class="amp-help">
      Precisa de ajuda? <a (click)="openChat()">Fale com a gente!</a>
    </div>
  </div>
</section>
