import { Output, EventEmitter, ElementRef, HostListener, Directive } from '@angular/core';
@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsiteDirective {
  @Output() public clickOutside = new EventEmitter();

  public ids = ['amp-password-validator', 'thumbs', 'amp-autocomplete', 'colorPicker', 'secondary-bar', 'dropdown', 'dropdownFilter'];

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.ids.includes(targetElement.id)) {
      return;
    }

    if (document.getElementById('secondary-bar').contains(targetElement)) {
      return;
    }

    const elements = document.querySelectorAll('.amp-wysiwyg');

    let flag = false;

    elements.forEach((element) => {
      if (element.contains(targetElement) && window.getSelection().toString().length > 0) {
        return (flag = true);
      }
    });

    if (targetElement.classList.contains('amp-select-opener')) {
      return;
    }

    if (flag) {
      return;
    }

    if (targetElement.getAttribute('aria-label') == 'filterDropdown') {
      return;
    }

    if (targetElement.classList.contains('amp-wysiwyg')) {
      if (window.getSelection().toString().length > 0) {
        return;
      }
    }

    const isClickedInside = this._elementRef.nativeElement.contains(targetElement);

    if (!isClickedInside) {
      this.clickOutside.emit(null);
    }
  }
}
