import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'ampAdmCompany'
})
export class AmpAdmCompany implements PipeTransform {
  transform(items: any[], filter: any): any {
    items = items.sort((a, b) => {
      if (
        a?.nome
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') <
        b?.nome
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ) {
        return -1;
      } else if (
        a?.nome
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') >
        b?.nome
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ) {
        return 1;
      }
      return 0;
    });

    if (!filter || !items) {
      return items;
    }

    return items.filter((item) =>
      item.nome
        ?.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          filter
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
    );
  }
}
