import { HttpClient } from '@angular/common/http';
import { Company } from 'app/_models/company';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
@Injectable()
export class CompanyService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  get(id) {
    return this.http.get<any>(this.config.apiUrl + '/company/' + id);
  }

  getCompanies(empresaId, filter, skip = 0, limit = 10) {
    // @ts-ignore
    const query = new URLSearchParams({
      filter: filter,
      skip: skip,
      limit: limit
    });

    return this.http.get<any>(this.config.apiUrl + '/company/all/' + empresaId + '?' + query.toString());
  }

  create(company: Company) {
    return this.http.post<any>(this.config.apiUrl + '/company/', company);
  }

  update(company: Company) {
    return this.http.put<any>(this.config.apiUrl + '/company/' + company._id, company);
  }

  delete(id) {
    return this.http.delete<any>(this.config.apiUrl + '/company/' + id);
  }
}
