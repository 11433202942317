import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Customer } from './../../_models/customer';
import { CustomerService } from './../../_services/customer.service';
import { MenuService } from './../../_services/menu.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services/store.service';
import { ValidarRecompensaDialogComponent } from '../validar-recompensa-dialog/validar-recompensa-dialog.component';
@Component({
  selector: 'app-validar-indicacao-dialog',
  templateUrl: './validar-indicacao-dialog.component.html',
  styleUrls: ['./validar-indicacao-dialog.component.scss']
})
export class ValidarIndicacaoDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private menuService: MenuService,
    private customerService: CustomerService,
    private store: StoreService
  ) {
    this.menuService.emit(true);
    this.subscriptions.push(
      this.subjectCustomer
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged())
        .subscribe((textSearch) => {
          this.searchContact(textSearch);
        })
    );
  }

  public subscriptions = [];
  public customer: any = {};
  public customers: Customer[];
  public searchField: any = '';
  public showDropDown = false;
  private subjectCustomer: Subject<string> = new Subject();

  ngOnInit() {
    this.getCustomers(0);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
    this.subjectCustomer?.complete();
  }

  save() {
    this.modalService.show(ValidarRecompensaDialogComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg amp-modal',
      initialState: { customer: this.customer }
    });
  }

  onTypeSearch(value) {
    this.subjectCustomer.next(value);
  }

  toggleDropdown() {
    this.showDropDown = !this.showDropDown;
  }

  searchContact(search) {
    if (!isNaN(this.searchField.charAt(0)) || this.searchField.charAt(0) == '(') {
      this.searchField = this.searchField.replace(/[^0-9+]/g, '');
    }
    if (this.searchField.length >= 3 || this.searchField.length == 0) {
      this.getCustomers(0);
    }
  }

  getCustomers(page: number) {
    this.subscriptions.push(
      this.customerService
        .getCustomers(this.store.business._id, 30, page, this.searchField, JSON.stringify({ created_at: -1 }), true)
        .subscribe((customers) => {
          this.customers = customers.customers;
        })
    );
  }

  selectCustomer(customer: Customer) {
    this.customer = customer;
    this.toggleDropdown();
    this.searchField = customer.name;
  }
}
