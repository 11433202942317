import { AuthenticationService, EmpresaService, NotifyService, StoreService } from 'app/_services';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Campaign } from 'app/_models/referral/campaign';
@Component({
  selector: 'amp-referral-milestone-preview',
  templateUrl: './referral-milestone-preview.component.html',
  styleUrls: ['./referral-milestone-preview.component.scss']
})
export class ReferralMilestonePreviewComponent implements OnInit, OnDestroy {
  @Input('campaign') campaign = new Campaign();

  public primaryColorSelector = false;
  public textColorSelector = false;
  public subscriptions = [];
  public colorsText = ['#383838', '#ffffff', '#0a1f44', '#445571', '#98a1b1'];
  public colors = ['#5885f7', '#4bc490', '#e85d6b', '#98a1b1', '#ffca00', '#0a1f44', '#445571', '#ba68c8', '#dbe5ff', '#ffe373'];
  public customFields = [];
  public userName: string;

  constructor(
    private authService: AuthenticationService,
    private notifyService: NotifyService,
    private empresaService: EmpresaService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.userName = this.authService.getNome();
    this.campaign.preview = 'authentication';

    this.subscriptions.push(
      this.empresaService.getCustomFields(this.store.empresa).subscribe((data) => {
        this.customFields = data;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  toggleRefereeField(customField) {
    let i;
    if ((i = this.campaign.custom_fields_referee.indexOf(customField)) > -1) {
      this.campaign.custom_fields_referee.splice(i, 1);
    } else {
      this.campaign.custom_fields_referee.push(customField);
    }
  }

  toggleReferrerField(customField) {
    let i;
    if ((i = this.campaign.custom_fields_referrer.indexOf(customField)) > -1) {
      this.campaign.custom_fields_referrer.splice(i, 1);
    } else {
      this.campaign.custom_fields_referrer.push(customField);
    }
  }

  changePrimaryColor(evt) {
    this.campaign.primary_color = evt.color.hex;
  }

  changeTextColor(evt) {
    this.campaign.text_color = evt.color.hex;
  }

  toggleCustomerField(field: string) {
    let i;
    if ((i = this.campaign.customer_form_fields.indexOf(field)) > -1) {
      this.campaign.customer_form_fields.splice(i, 1);
    } else {
      this.campaign.customer_form_fields.push(field);
    }
  }

  togglePickerPrimaryColor() {
    if (this.textColorSelector) {
      this.textColorSelector = false;
    }
    this.primaryColorSelector = !this.primaryColorSelector;
  }

  togglePickerTextColor() {
    if (this.primaryColorSelector) {
      this.primaryColorSelector = false;
    }
    this.textColorSelector = !this.textColorSelector;
  }

  formatURL(id) {
    const input = (<HTMLInputElement>document.getElementById(id)).value;
    if (input.length) {
      if (!input.includes('http')) {
        (<HTMLInputElement>document.getElementById(id)).value = 'https://' + input;
        this.campaign.widget_url = 'https://' + input;
      }
    }
  }

  async copyLinkToClipboard() {
    await navigator.clipboard
      .writeText(`https://api.amplifique.me/referral/referral/validate-redirect/${this.campaign._id}?email=[EMAIL DO INDICADO]`)
      .then(() => {
        this.notifyService.success('Link copiado para a área de transferência!');
      });
  }
}
