import { HttpClient } from '@angular/common/http';
import { Empresa } from 'app/_models/empresa';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
@Injectable()
export class EmpresaService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(active = undefined, trial = undefined, scheduleDeletion = undefined, skip = 0, filter) {
    let query = {};

    if (active != undefined) {
      query['active'] = active;
    }

    if (trial != undefined) {
      query['trial'] = trial;
    }

    if (scheduleDeletion != undefined) {
      query['schedule_deletion'] = scheduleDeletion;
    }

    if (filter) {
      query['filter'] = filter;
    }

    query['skip'] = skip;
    query['limit'] = 50;
    query = new URLSearchParams(query);

    return this.http.get<any>(this.config.apiUrl + '/empresa?' + query.toString());
  }

  getById(_id: string) {
    return this.http.get<Empresa>(this.config.apiUrl + '/empresa/' + _id);
  }

  create(empresa: Empresa) {
    return this.http.post<Empresa>(this.config.apiUrl + '/empresa', empresa);
  }

  update(empresa: Empresa) {
    return this.http.put<Empresa>(this.config.apiUrl + '/empresa/' + empresa._id, empresa);
  }

  delete(_id: string) {
    return this.http.delete<Empresa>(this.config.apiUrl + '/empresa/' + _id);
  }

  getManagedEmpresas() {
    return this.http.get<Empresa[]>(this.config.apiUrl + '/empresa/acmng/mybusiness');
  }

  getTemporaryAccessEmpresas() {
    return this.http.get<Empresa[]>(this.config.apiUrl + '/empresa/temporary-access');
  }

  createWithWizard(empresa: { suggested_plan; contato_email; contato_nome; empresa_nome }) {
    return this.http.post<any>(this.config.apiUrl + '/zapier/empresa', empresa, {
      headers: { api_internal_token: 'TUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUF3K2tkVkJSc1ZhSTVPdzIrMGdVNw==' }
    });
  }

  createPublic(data) {
    return this.http.post<any>(this.config.apiUrl + '/empresa/public', data);
  }

  updatePublic(empresaId, data) {
    return this.http.put<any>(this.config.apiUrl + '/empresa/public/' + empresaId, data);
  }

  putTaxIdOnBusiness(empresaId) {
    return this.http.put<any>(this.config.apiUrl + '/empresa/' + empresaId + '/tax-id-business', {});
  }

  startTrial(empresaId, email) {
    return this.http.put<any>(this.config.apiUrl + '/empresa/start-trial/' + empresaId, { email });
  }

  requestContactSales(empresaId) {
    return this.http.put<any>(this.config.apiUrl + `/empresa/request-sales-contact/${empresaId}`, {});
  }

  createCustomField(empresaId, customField) {
    return this.http.post<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-field`, customField);
  }
  updateCustomField(empresaId, customField) {
    return this.http.put<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-field/${customField._id}`, customField);
  }
  deleteCustomField(empresaId, customField) {
    return this.http.delete<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-field/${customField._id}`);
  }
  getCustomField(empresaId, customFieldId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-field/${customFieldId}`);
  }
  getCustomFields(empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-field`);
  }
  getCustomFieldValues(empresaId, customField, business?, search?, restrict_to?) {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('custom_field', customField);
    if (business) {
      urlSearchParams.append('business', business);
    }
    if (search) {
      urlSearchParams.append('search', search);
    }
    if (restrict_to) {
      urlSearchParams.append('restrict_to', restrict_to);
    }
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-field/values?` + urlSearchParams.toString());
  }

  scheduleDeletion(empresaId, date) {
    return this.http.put<any>(this.config.apiUrl + `/empresa/${empresaId}/schedule-deletion`, { date });
  }

  addCustomEmailDomain(empresaId, subdomain, domain, sender, custom_dkim_selector = undefined) {
    return this.http.post<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-email-domain`, { subdomain, domain, sender, custom_dkim_selector });
  }
  validateCustomEmailDomain(empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-email-domain/validate`);
  }
  getCustomEmailDomain(empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-email-domain`);
  }
  deleteCustomEmailDomain(empresaId) {
    return this.http.delete<any>(this.config.apiUrl + `/empresa/${empresaId}/custom-email-domain`);
  }

  updateCfBalance(empresaId, cf_nResponses, cf_nResponsesTest) {
    return this.http.put<any>(this.config.apiUrl + `/empresa/${empresaId}/cf-balance`, { cf_nResponses, cf_nResponsesTest });
  }

  getAIBalance(empresaId, full_history = false) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/ai-balance?full_history=${full_history}`);
  }

  getWABalance(empresaId, full_history = false) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-balance?full_history=${full_history}`);
  }

  getAIUsageOvertime(empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/ai-usage-overtime`);
  }

  getEmpresaBalancesAdm(period = null, type) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/historyBalance?type=${type}&period=${period}`);
  }

  getWhatsappTwilioServices(empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/service`);
  }

  testWhatsappTwilio(empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/test`);
  }

  getWhatsappTwilioTemplates(empresaId, only_approved = false) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/template?only_approved=${only_approved}`);
  }

  getWhatsappTwilioUsage(empresaId, dateFilter, unit?, category?) {
    const urlParams = new URLSearchParams();
    urlParams.append('startDate', dateFilter[0].toISOString());
    urlParams.append('endDate', dateFilter[1].toISOString());
    if (unit) {
      urlParams.append('unit', unit);
    }
    if (category) {
      urlParams.append('category', category);
    }
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/usage?` + urlParams.toString());
  }

  createWhatsappTwilioTemplate(empresaId, template: { friendly_name: string; language: string; button_title: string; body: string }) {
    return this.http.post<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/template`, template);
  }

  deleteWhatsappTwilioTemplate(empresaId, templateId) {
    return this.http.delete<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/template/${templateId}`);
  }

  saveWhatsAppTwilioWaba(empresaId, phone_number_id, waba_id, phone_number, profile_name) {
    return this.http.post<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/waba`, { phone_number_id, waba_id, phone_number, profile_name });
  }

  activeWhatsAppTwilioWaba(empresaId) {
    return this.http.put<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/active`, {});
  }

  getStatusWhatsAppTwilioWaba(empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/status`);
  }

  updateProfileWhatsAppTwilio(empresaId, data: any) {
    return this.http.put<any>(this.config.apiUrl + `/empresa/${empresaId}/wa-twilio/profile`, data);
  }
}
