import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
@Component({
  selector: 'app-company-list-dialog',
  templateUrl: './company-list-dialog.component.html',
  styleUrls: ['./company-list-dialog.component.scss']
})
export class CompanyListDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public loading = false;
}
