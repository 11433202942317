<section>
  <div class="row">
    <div class="col-lg-12">
      <div class="header">
        <p class="title">Tokens de Integração</p>
        <button class="amp-btn-primary" (click)="createToken()">Criar Novo Token</button>
      </div>
      <div class="amp-card">
        <div class="amp-table">
          <div class="amp-table-head">
            <div class="amp-table-column amp-token-name">Apelido do Token</div>
            <div class="amp-table-column amp-token-type">Tipo</div>
            <div class="amp-table-column">Token</div>
            <div class="amp-table-column amp-table-actions-col"></div>
          </div>
          <div class="amp-table-row" *ngFor="let token of tokens; let i = index">
            <div class="amp-table-cell">{{ token.name }}</div>
            <div class="amp-table-cell">{{ token.action.type }}</div>
            <div class="amp-table-cell">
              <div class="amp-token">{{ token.access_token }}</div>
            </div>
            <div class="amp-table-cell amp-table-cell-actions">
              <span routerLink="/partners/token/{{token._id}}/log" class="bi-eye"></span>
              <span (click)="editToken(token)" class="bi-pencil"></span>
              <span (click)="deleteToken(token)" class="bi-x-lg"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>