import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService, StoreService } from 'app/_services';
@Pipe({
  name: 'cnpj'
})
export class CNPJPipe implements PipeTransform {
  constructor(public authService: AuthenticationService, public store: StoreService) {}

  transform(value: any) {
    if (value) {
      value = value.replace(/\D/g, '');
      return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }
    return value;
  }
}
