import { Customer } from '../customer';
import { SurveyAnswer } from '../customerFeedback/surveyAnswer';
import { Empresa } from '../empresa';
import { Tag } from '../tag';
import { User } from '../user';
import TkPipeline, { TkPipelineStatus } from './pipeline';

export default class TkTicket {
  _id: string;
  code: number;
  subject: string;
  description: string;
  step: string;
  status: TkPipelineStatus;
  priority: number;
  checklist: any;
  due_at: Date;
  last_activity_at: Date;
  closed_at: Date;
  has_unread_activities: boolean;
  stay_in_pipeline_steps: Object;
  _surveyAnswer: SurveyAnswer | string;
  _watchers: User[] | string[];
  _customer: Customer | string;
  _empresa: Empresa | string;
  _pipeline: TkPipeline | string;
  _created_by: User | string;
  _assigned_to: User | string;
  email_subject: string;
  assigned_to: User;
  created_at: Date;
  updated_at: Date;
  _tags: Tag[];
  archived: boolean;
}
