<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Galeria de Imagens
    </div>
    <div class="amp-dialog-subtitle">Escolha uma das imagens abaixo ou faça um novo upload. Para consultar os tamanhos e formatos recomendados, <a href="https://amplifiqueme.notion.site/Upload-de-Imagens-121a6daff71d8024996de20034b03516" target="_blank">clique aqui.</a></div>

    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <div class="amp-gallery">
      <div class="amp-gallery-image" *ngFor="let image of gallery" #ref tooltip="Esta imagem não está na proporção correta para este uso." [isDisabled]="isImageAllowed(ref)" [ngClass]="{'amp-image-not-allowed': !isImageAllowed(ref)}">
        <img [src]="image" (click)="onSelectImage(ref, image)">
        <i tooltip="Excluir imagem" (click)="deleteImage(image)" class="bi-x-lg"></i>
      </div>
      <div class="amp-gallery-image amp-gallery-dashed" (click)="uploadImage()">
        <div class="amp-gallery-wrapper">
          <span class="amp-gallery-icon bi-plus-lg"></span>
          <div class="amp-gallery-items-wrapper">
            <h5 class="amp-gallery-title">Enviar Imagem</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
