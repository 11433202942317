import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef) {}

  public errorList: any[];

  ngOnInit() {
    console.log(this.errorList);
  }

  close() {
    this.bsModalRef.hide();
  }
}
