import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthenticationService } from 'app/_services';
import { CustomField } from 'app/_models/customField';
import { Campaign } from 'app/_models/referral/campaign';
@Component({
  selector: 'amp-referral-widget-preview',
  templateUrl: './referral-widget-preview.component.html',
  styleUrls: ['./referral-widget-preview.component.scss']
})
export class ReferralWidgetPreviewComponent implements OnInit {
  constructor(private authService: AuthenticationService) {}

  @ViewChild('tab2', { static: false }) tabCenter;
  @Input('campaign') campaign: Campaign = new Campaign();
  @Input('customFields') customFields: CustomField[] = [];
  @Input('color') color: string;
  @Input('textColor') textColor: string;
  @Input('type') type = 'referral';

  public i = 1;
  public tab = 1;
  public userName: string;
  public shareUrl = 'https://ampl.me/123';

  ngOnInit() {
    this.userName = this.authService.getNome();
    if (this.type == 'milestone') {
      this.tab = 2;
      setTimeout(() => {
        this.tabCenter.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 1500);
    }
  }

  getCustomField(customField) {
    return this.customFields.find((item) => item.internal_name == customField);
  }

  teste(el: HTMLElement, tab: number) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    this.tab = tab;
  }
}
