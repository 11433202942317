import { TkPipelineService } from './../../_services/ticketing/pipeline.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import TkPipeline, { TkPipelineStatus, TkPipelineStep } from 'app/_models/ticketing/pipeline';
import { NotifyService, StoreService, UserService } from 'app/_services';
import groupBy from 'lodash-es/groupBy';
import { SelectDialogComponent } from 'app/_dialogs/select-dialog/select-dialog.component';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
@Component({
  selector: 'app-create-pipeline-dialog',
  templateUrl: './create-pipeline-dialog.component.html',
  styleUrls: ['./create-pipeline-dialog.component.scss']
})
export class CreatePipelineDialogComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private store: StoreService,
    private userService: UserService,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private tkPipelineService: TkPipelineService
  ) {}

  public isNew = true;
  public pipeline: TkPipeline = new TkPipeline();
  public users = [];
  public result: Subject<boolean> = new Subject();
  public pending = [];
  public inProgress = [];
  public isLoading = false;
  public closed = [];
  public currentStepItem;
  public currentStepName;
  public isEditingName = false;
  public currentStepIndex;
  public isEditingChecklist = -1;
  public checklistInput = '';
  public tab = 0;
  public currentStepEdit = undefined;
  public steps = {
    pending: [{ _id: undefined, name: 'Aguardando Encaminhamento', status: 0 }],
    in_progress: [{ _id: undefined, name: 'Em Progresso', status: 1 }],
    closed: [{ _id: undefined, name: 'Resolvido com Sucesso', status: 2 }]
  };

  ngOnInit() {
    if (!this.isNew) {
      this.pipeline = JSON.parse(JSON.stringify(this.pipeline));
    }

    this.loadUsers();
    this.splitSteps();
  }

  splitSteps() {
    if (!this.pipeline.steps) return;
    const groups = groupBy(this.pipeline.steps, 'status');
    this.steps.pending = groups[TkPipelineStatus.pending] || [];
    this.steps.in_progress = groups[TkPipelineStatus.in_progress] || [];
    this.steps.closed = groups[TkPipelineStatus.closed] || [];
  }

  joinSteps() {
    const pipeline = JSON.parse(JSON.stringify(this.pipeline));
    pipeline.steps = [...this.steps.pending, ...this.steps.in_progress, ...this.steps.closed];
    return pipeline;
  }

  addStep(type: TkPipelineStatus) {
    this.steps[TkPipelineStatus[type]].push({ _id: undefined, name: 'Nova Fase', status: type });
  }

  loadUsers() {
    this.userService.getByEmpresa(this.store.empresa).subscribe((users) => {
      this.users = users
        .filter((item) => (item.ticketing_role ? item.ticketing_role?.length : false))
        .map((u) => ({ value: u._id, display: u.nome, label: u.nome }));
      if (this.pipeline._users?.length) {
        this.pipeline._users = this.pipeline._users.map((u) => ({ value: u._id, display: u.nome, label: u.nome }));
      } else {
        this.pipeline._users = [];
      }
    });
  }

  requestUsersAutoComplete = (filterName): Observable<any> => {
    return this.userService.getByEmpresa(this.store.empresa, null, filterName).pipe(
      map((users) =>
        users
          .filter((item) => (item.ticketing_role ? item.ticketing_role?.length : false))
          .map((user) => {
            return { value: user._id, display: user.nome, label: user.nome };
          })
      )
    );
  };

  toggleChecklistEdition(i) {
    if (this.isEditingChecklist == i) {
      this.isEditingChecklist = -1;
    } else {
      this.isEditingChecklist = i;
      setTimeout(() => {
        document.getElementById('on-edit-input').focus();
      }, 100);
    }
  }

  closeDialog() {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  save() {
    const pipeline = this.joinSteps();

    if (pipeline.default_expiration > 180 || pipeline.default_expiration < 0) {
      this.notifyService.warning('O prazo padrão deve ser entre 0 e 180 dias!');
      return;
    }

    for (const item of pipeline.steps) {
      if (item.checklist_enabled && !item.checklist.length) {
        this.notifyService.warning('A fase ' + item.name + ' deve ter ao menos um item na checklist!');
        return;
      }
    }

    if (pipeline._users?.length) {
      pipeline._users = this.pipeline._users.map((u) => u.value);
    }

    pipeline._empresa = this.store.empresa;

    if (this.isNew) {
      this.tkPipelineService.create(pipeline).subscribe(
        () => {
          this.result.next(true);
          this.bsModalRef.hide();
          this.notifyService.success('Fluxo criado com sucesso!');
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    } else {
      this.tkPipelineService.update(pipeline._id, pipeline).subscribe(
        () => {
          this.result.next(true);
          this.bsModalRef.hide();
          this.notifyService.success('Fluxo atualizado com sucesso!');
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    }
  }

  addOptionToChecklist() {
    if (!this.currentStepItem.checklist) {
      this.currentStepItem.checklist = [];
    }

    this.currentStepItem.checklist.push({ name: this.checklistInput });
    this.checklistInput = '';
  }

  removeOptionFromChecklist(index) {
    this.currentStepItem.checklist.splice(index, 1);
  }

  selectStep(item, index: number, name, steps) {
    this.currentStepEdit = name + index;
    this.currentStepItem = item;
  }

  getStepColor() {
    switch (this.currentStepItem.status) {
      case 0:
        return 'step-settings-pending';
      case 1:
        return 'step-settings-in-progress';
      case 2:
        return 'step-settings-closed';
      default:
        return;
    }
  }

  deleteStep(step: TkPipelineStep, i: number) {
    if (step.unique_id) {
      const initialState = {
        title: 'Mover Tickets',
        message: `Escolha para qual fase os Tickets devem ser movidos após a exclusão da Fase "${step.name}".`,
        buttonName: 'Confirmar',
        placeholder: 'Escolher Fase',
        options: this.pipeline.steps
          .filter((s) => s.unique_id && s.unique_id != step.unique_id)
          .map((s) => {
            return { value: s.unique_id, label: s.name };
          })
      };

      const selectDialog = this.modalService.show(SelectDialogComponent, {
        initialState,
        class: 'amp-modal'
      });

      selectDialog.content.result.subscribe((data) => {
        if (data) {
          const newStep = this.pipeline.steps.find((s) => s.unique_id == data);
          const initialState = {
            title: 'Mover Tickets',
            message: `Tem certeza de que deseja excluir a Fase "${step.name}" e mover os respectivos Tickets para a Fase "${newStep.name}"?`,
            buttonName: 'Confirmar',
            forceConfirmation: true
          };

          const deleteDialog = this.modalService.show(DeleteDialogComponent, {
            initialState,
            class: 'amp-modal'
          });

          deleteDialog.content.resultado.subscribe((data) => {
            if (data) {
              this.isLoading = true;
              this.tkPipelineService.moveInBatch(this.pipeline._id, step.unique_id, newStep.unique_id).subscribe(() => {
                this.steps[TkPipelineStatus[step.status]].splice(i, 1);
                this.save();
                this.notifyService.success('Fase excluída com sucesso!');
              });
            }
          });
        }
      });
    } else {
      this.steps[TkPipelineStatus[step.status]].splice(i, 1);
    }
  }

  dropOptions(event: CdkDragDrop<string[]>) {
    const element = event.item.element;

    moveItemInArray(this.currentStepItem.checklist, event.previousIndex, event.currentIndex);

    element.nativeElement.classList.add('glow');

    setTimeout(() => {
      element.nativeElement.classList.remove('glow');
    }, 1000);
  }

  focusInput(step, i) {
    this.currentStepEdit = step + i;
    setTimeout(() => {
      const input = document.querySelector('.' + step + '-' + i) as HTMLElement;
      input.focus();
    }, 100);
  }

  updateArrays(event: CdkDragDrop<TkPipelineStep[]>) {
    if (event.previousContainer == event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      event.container.data[event.currentIndex].status = TkPipelineStatus[event.container.id];
    }
  }
}
