import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService, StoreService } from 'app/_services';
@Pipe({
  name: 'cep'
})
export class CEPPipe implements PipeTransform {
  constructor(public authService: AuthenticationService, public store: StoreService) {}

  transform(value: any): any {
    if (value) {
      value = value.replace(/\D/g, '');
      return value.replace(/^(\d{5})(\d{3})$/, '$1-$2');
    }
    return value;
  }
}
