<div class="amp-dialog">
  <p class="amp-dialog-title">{{ title }}</p>
  <div class="amp-dialog-subtitle">
    {{ subtitle }}
  </div>

  <div class="amp-field">
    <label class="amp-label-text" for="role">{{ inputLabel }}</label>
    <input *ngIf="!textArea" type="text" class="amp-input" [placeholder]="placeholder" [(ngModel)]="model" />
    <textarea *ngIf="textArea" type="text" class="amp-input" [placeholder]="placeholder" [(ngModel)]="model"></textarea>
  </div>

  <div class="amp-buttons">
    <button [disabled]="!model" class="amp-btn-success amp-btn" (click)="submit()">{{ buttonText }}</button>
    <button type="button" class="amp-btn-outline-cancel amp-btn" (click)="close()">Cancelar</button>
  </div>
</div>