import { Business } from './business';
import { Empresa } from './empresa';
import { User } from './user';
export class Token {
  name: string;
  action: {
    campaign: string;
    channel: string;
    type: string;
    delay: number;
    delay_mode: string;
    specific_time: string;
    expiration: number;
  } = { campaign: '', channel: '', type: '', delay: 0, specific_time: '', delay_mode: null, expiration: 0 };
  force_rewrite: boolean;
  access_token: string;
  created_at: Date;
  partner: string;
  _business: Business;
  _user: User;
  _empresa: Empresa;
}
