import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CreateSummaryDialogComponent } from '../create-summary-dialog/create-summary-dialog.component';
import { AIService } from 'app/_services/ai/ai.service';
import { NotifyService, StoreService } from 'app/_services';
@Component({
  selector: 'app-manage-summaries-dialog',
  templateUrl: './manage-summaries-dialog.component.html',
  styleUrls: ['./manage-summaries-dialog.component.scss']
})
export class ManageSummariesDialogComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private notifyService: NotifyService,
    private store: StoreService,
    private modalService: BsModalService,
    private aiService: AIService
  ) {}

  public recurrencies = [];
  public isLoading = false;

  ngOnInit() {
    this.loadRecurrencies();
  }

  loadRecurrencies() {
    this.isLoading = true;
    this.aiService.getAllRecurringAISummaries(this.store.empresa).subscribe({
      next: (data) => {
        this.recurrencies = data;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    });
  }

  editRecurrency(recurrency) {
    const initialState = {
      isNew: false,
      aiSummary: recurrency
    };

    this.modalService.show(CreateSummaryDialogComponent, {
      initialState,
      class: 'amp-modal-large'
    });
  }

  deleteRecurrency(recurrency) {
    const title = 'Excluir Agendamento';
    const message = 'Tem certeza que deseja excluir este agendamento?';
    const initialState = {
      title: title,
      message: message,
      buttonName: 'Excluir'
    };

    const modal: BsModalRef = this.modalService.show(ConfirmDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    modal.content.result.subscribe((result) => {
      if (result) {
        this.aiService.deleteRecurringAISummary(recurrency.id).subscribe({
          next: () => {
            this.notifyService.success('Agendamento excluído com sucesso!');
          },
          error: (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao excluir o agendamento, tente novamente ou contate o suporte.');
          }
        });
      }
    });
  }
}
