import { HttpClient } from '@angular/common/http';
import { StoreService } from './store.service';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Customer } from 'app/_models/customer';
@Injectable()
export class CustomerService {
  constructor(private http: HttpClient, private config: AppConfig, private store: StoreService) {}

  getCustomers(_business: string, limit: number, page: number, filter: string, orderBy: string, global: boolean, importData?: string) {
    // @ts-ignore
    const query = new URLSearchParams({
      limit: limit,
      page: page,
      filterMessage: filter,
      orderBy: orderBy,
      global
    });

    if (importData) {
      query.append('importData', importData);
    }

    return this.http.get<any>(this.config.apiUrl + '/customer/business/' + _business + '?' + query.toString());
  }

  getCustomersAdvanced(_business: string, limit: number, page: number, filter: any, orderBy: string, force: string) {
    let query;
    if (force) {
      // @ts-ignore
      query = new URLSearchParams({
        limit: limit,
        page: page,
        orderBy: orderBy,
        force: true
      });
    } else {
      // @ts-ignore
      query = new URLSearchParams({
        limit: limit,
        page: page,
        orderBy: orderBy
      });
    }

    return this.http.post<any>(this.config.apiUrl + '/customer/advancedFilter/' + _business + '?' + query.toString(), filter);
  }

  create(customer: Customer) {
    // @ts-ignore
    customer._empresa = this.store.empresa;
    // @ts-ignore
    customer._business = this.store.business._id;
    // @ts-ignore
    customer._businesses = [];
    // @ts-ignore
    customer._businesses.push(this.store.business._id);

    return this.http.post<any>(this.config.apiUrl + '/customer/', customer);
  }

  createByTotem(customer: Customer, tag: string, _campaign: string, qrCode?: string, isSurvey = false) {
    return this.http.post<any>(this.config.apiUrl + '/customer/totem?isSurvey=' + isSurvey, { customer, tag, _campaign, qrCode });
  }

  getRewards(_customer: string) {
    return this.http.get<any>(this.config.apiUrl + '/customer/' + _customer + '/rewards');
  }

  update(customer: any): any {
    return this.http.put<any>(this.config.apiUrl + '/customer/' + customer._id, customer);
  }

  delete(customer: any): any {
    return this.http.delete<any>(this.config.apiUrl + '/customer/' + customer._id);
  }

  checkCSV(file: any): any {
    return this.http.post<any>(this.config.apiUrl + '/customer/csvCheck', { url: file });
  }

  validateImport(file: any, keys: any): any {
    return this.http.post<any>(this.config.apiUrl + '/customer/csvCheckFields', { url: file, keys: keys });
  }

  import(file: any, business: any, tag: any, keys: any, action: any, action_params: any, tryAlternativeChannel): any {
    return this.http.post<any>(this.config.apiUrl + '/customer/import', { url: file, keys, business, tag, action, action_params, tryAlternativeChannel });
  }

  getCustomer(customerId: string) {
    return this.http.get<any>(this.config.apiUrl + '/customer/detailed/' + customerId);
  }

  createComment(_customer: string, comment: string): any {
    return this.http.post<any>(this.config.apiUrl + '/customer/' + _customer + '/comment', { comment });
  }

  deleteComment(_customer: string, _comment: string): any {
    return this.http.delete<any>(this.config.apiUrl + '/customer/' + _customer + '/comment/' + _comment);
  }

  addTag(_customer: string, _tag: string): any {
    return this.http.post<any>(this.config.apiUrl + '/customer/' + _customer + '/tag', { tag: _tag });
  }

  deleteTag(_customer: string, _tag: string): any {
    return this.http.delete<any>(this.config.apiUrl + '/customer/' + _customer + '/tag/' + _tag);
  }

  addTagInBatch(_customers: string[], _tag: string, _business: any, filters: any = null): any {
    return this.http.post<any>(this.config.apiUrl + '/customer/tag-batch', { tag: _tag, customers: _customers, filters, _business });
  }

  deleteTagInBatch(_customers: string[], _tag: string, _business: any, filters: any = null): any {
    return this.http.put<any>(this.config.apiUrl + '/customer/tag-batch/delete/' + _tag, { customers: _customers, filters, _business });
  }

  updateCompanyInBatch(_customers: string[], company: string, _business: any, filters: any = null): any {
    return this.http.post<any>(this.config.apiUrl + '/customer/company-batch', { company: company, customers: _customers, filters, _business });
  }

  deleteMany(customers: any): any {
    return this.http.request<any>('delete', this.config.apiUrl + '/customer/many', { body: { customers } });
  }

  deleteByFilter(_business: any, filters: any): any {
    return this.http.request<any>('delete', this.config.apiUrl + '/customer/filter', { body: { _business, filters } });
  }

  updateSubscriptionMany(customers: any, unsubscribed): any {
    return this.http.put<any>(this.config.apiUrl + '/customer/subscription/many', { customers, unsubscribed });
  }

  updateSubscriptionByFilter(_business: any, filters: any, unsubscribed): any {
    return this.http.put<any>(this.config.apiUrl + '/customer/subscription/filter', { _business, filters, unsubscribed });
  }

  removeBounce(_id: string) {
    return this.http.patch<any>(this.config.apiUrl + `/customer/${_id}/removeBounce`, {});
  }
}
