import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BusinessService, NotifyService } from 'app/_services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { MenuService } from '../../_services/menu.service';
import { Subject } from 'rxjs';
import { Business } from 'app/_models/business';
import { Campaign } from 'app/_models/referral/campaign';
@Component({
  selector: 'app-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.scss']
})
export class ChangeStatusDialogComponent implements OnInit, OnDestroy {
  public campaign: Campaign = new Campaign();
  public oldStatus;
  public status;
  public amount;
  public statusMessage;
  public businessesList: Business[];
  public business = '';
  public result: Subject<any> = new Subject<any>();
  public subscriptions = [];

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public store: StoreService,
    private menuService: MenuService,
    private businessService: BusinessService,
    private notifyService: NotifyService
  ) {
    this.menuService.emit(true);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.businessService.getMyBusiness(this.store.empresa).subscribe(
        (data) => {
          this.businessesList = data;
          this.business = this.store.business._id;
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Falha ao obter a lista de unidades.');
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  exit() {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  execute() {
    this.result.next({
      amount: this.amount,
      status: this.status,
      statusMessage: this.statusMessage,
      business: this.business
    });
    this.bsModalRef.hide();
  }
}
