import { ReferralMilestoneWidgetPreviewComponent } from './referral-milestone-widget-preview/referral-milestone-widget-preview.component';
import { NpsResponseDistributionBarComponent } from './nps-response-distribution-bar/nps-response-distribution-bar.component';
import { ReferralMilestonePreviewComponent } from './referral-milestone-preview/referral-milestone-preview.component';
import { ReviewsDistributionBarComponent } from './reviews-distribution-bar/reviews-distribution-bar.component';
import { AmpCircleImageDisplayComponent } from './amp-circle-image-display/amp-circle-image-display.component';
import { ReferralWidgetPreviewComponent } from './referral-widget-preview/referral-widget-preview.component';
import { ReferralLandingEditorComponent } from './referral-landing-editor/referral-landing-editor.component';
import { ReviewPlatformSelectComponent } from 'app/review-platform-select/review-platform-select.component';
import { RefereeLandingEditorComponent } from './referee-landing-editor/referee-landing-editor.component';
import { ReferralEmailPreviewComponent } from './referral-email/referral-email-preview.component';
import { AmpWYSIWYGInputComponent } from './amp-wysiwyg-input/amp-wysiwyg-input.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { AmpMarkdownEditorComponent } from './markdown-editor/markdown-editor.component';
import { AmpProgressBarComponent } from './amp-progress-bar/amp-progress-bar.component';
import { AmpMultiSelectComponent } from './amp-multi-select/amp-multi-select.component';
import { AmpDatePickerComponent } from './amp-date-picker/amp-date-picker.component';
import { SmartTagsBarComponent } from './smart-tags-bar/smart-tags-bar.component';
import { AmpMailBuilderComponent } from './mail-builder/mail-builder.component';
import { SecondaryBarComponent } from './secondary-bar/secondary-bar.component';
import { MockupPhoneComponent } from './mockup-phone/mockup-phone.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { AmpLoadingComponent } from './amp-loading/amp-loading.component';
import { AmpSelectComponent } from './amp-select/amp-select.component';
import { MainDropdown } from 'app/_directives/main-dropdown.module';
import { CfFilterComponent } from './cf-filter/cf-filter.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoaderComponent } from './loader/loader.component';
import { DialogComponent } from './dialog/dialog.component';
import { FilterComponent } from './filter/filter.component';
import { ShowdownModule } from '@amplifiqueme/ngx-showdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CfFilterPipe } from './cf-filter/cf-filter.pipe';
import { MainPipe } from 'app/_pipes/main-pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ColorBlockModule } from 'ngx-color/block';
import { NouisliderModule } from 'ng2-nouislider';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
@NgModule({
  imports: [
    MainPipe,
    FormsModule,
    CommonModule,
    RouterModule,
    MainDropdown,
    MomentModule,
    TooltipModule,
    PopoverModule,
    TranslateModule,
    NouisliderModule,
    ColorBlockModule,
    BsDropdownModule,
    BsDatepickerModule,
    InfiniteScrollModule,
    ShowdownModule.forRoot({
      emoji: true,
      noHeaderId: true,
      flavor: 'github',
      extensions: []
    })
  ],
  declarations: [
    CfFilterPipe,
    LoaderComponent,
    DialogComponent,
    FilterComponent,
    CfFilterComponent,
    AmpSelectComponent,
    PhoneInputComponent,
    AmpLoadingComponent,
    MockupPhoneComponent,
    SecondaryBarComponent,
    SmartTagsBarComponent,
    AmpDatePickerComponent,
    AmpProgressBarComponent,
    AmpMailBuilderComponent,
    AmpMultiSelectComponent,
    CompanySelectorComponent,
    AmpWYSIWYGInputComponent,
    AmpMarkdownEditorComponent,
    ReviewPlatformSelectComponent,
    ReferralEmailPreviewComponent,
    RefereeLandingEditorComponent,
    AmpCircleImageDisplayComponent,
    ReferralWidgetPreviewComponent,
    ReferralLandingEditorComponent,
    ReviewsDistributionBarComponent,
    ReferralMilestonePreviewComponent,
    NpsResponseDistributionBarComponent,
    ReferralMilestoneWidgetPreviewComponent
  ],
  exports: [
    CfFilterPipe,
    LoaderComponent,
    DialogComponent,
    FilterComponent,
    CfFilterComponent,
    AmpSelectComponent,
    AmpLoadingComponent,
    PhoneInputComponent,
    AmpLoadingComponent,
    MockupPhoneComponent,
    SecondaryBarComponent,
    SmartTagsBarComponent,
    AmpDatePickerComponent,
    AmpMultiSelectComponent,
    AmpProgressBarComponent,
    AmpMailBuilderComponent,
    CompanySelectorComponent,
    AmpWYSIWYGInputComponent,
    AmpMarkdownEditorComponent,
    ReferralEmailPreviewComponent,
    RefereeLandingEditorComponent,
    ReviewPlatformSelectComponent,
    ReferralWidgetPreviewComponent,
    ReferralLandingEditorComponent,
    AmpCircleImageDisplayComponent,
    ReviewsDistributionBarComponent,
    ReferralMilestonePreviewComponent,
    NpsResponseDistributionBarComponent,
    ReferralMilestoneWidgetPreviewComponent
  ]
})
export class AmpComponentsModule {
  static forRoot(): ModuleWithProviders<AmpComponentsModule> {
    return {
      ngModule: AmpComponentsModule
    };
  }
}
