<div class="amp-dialog">
  <p class="amp-dialog-title"><i class="bi-lock"></i>Bloquear Endereço IP</p>
  <div class="amp-dialog-subtitle">
    Ao bloquear um Endereço IP, o sistema ignora qualquer pessoa se cadastrando com o referido endereço e não contabiliza a indicação.
  </div>
  <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
    <div class="amp-select-business-wrapper" >
      <div class="amp-field">
        <label class="amp-label-text" for="search">Endereço IP</label>
        <input name="ipAddress" mask="IP" type="text" class="amp-input" [dropSpecialCharacters]="false" [showMaskTyped]="true" [(ngModel)]="ipAddress" placeholder="Ex.: 192.168.100.101" autocomplete="off" >
      </div>
    </div>
    <div class="amp-buttons">
      <button class="amp-btn-danger amp-btn-lg" type="submit" *ngIf="!loading" [disabled]="!form.form.valid">Bloquear Endereço IP</button>
      <button class="amp-btn-danger amp-btn-lg w-100" *ngIf="loading" [disabled]="true">Processando<span class="loading-ellipsis">...</span></button>
      <button type="button" class="amp-btn-lg amp-btn-outline-secondary" *ngIf="!loading" (click)="closeModal()">Cancelar</button>
    </div>
  </form>
</div>
