import { AuthenticationService } from './../_services/authentication.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StoreService } from 'app/_services';
import jwtDecode from 'jwt-decode';
@Injectable()
export class RoleGuard {
  constructor(private router: Router, private auth: AuthenticationService, private store: StoreService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const expectedRole: any[] = route.data.expectedRole;
    const expectedTicketingRole: any[] = route.data.expectedTicketingRole;
    const expectedPermission: any[] = route.data.permission;

    if (!route.routeConfig.path.includes('subscription')) {
      this.store.checkTrial();
    }

    if (this.auth.isAuthenticated()) {
      const token = localStorage.getItem('access_token');

      const tokenPayload = jwtDecode(token);

      if (expectedRole?.length > 0) {
        if (expectedRole.indexOf(tokenPayload.data.role) > -1) {
          if (expectedPermission && tokenPayload.data.role != 'acmng') {
            if (tokenPayload.data.permission[expectedPermission]) {
              return true;
            }
          } else {
            return true;
          }
        }
      }

      if (expectedTicketingRole?.length > 0) {
        if (expectedTicketingRole.indexOf(tokenPayload.data.ticketing_role) > -1) {
          return true;
        }
      }
    } else {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    this.router.navigate(['/contatos']);
    return false;
  }
}
