<div class="amp-dialog">
  <div class="amp-dialog-title">
    <p>Editando Tags</p>
  </div>
  <p class="amp-dialog-subtitle">Aqui você gerencia todas as tags e suas palavras-chave que pertencem a cada tag. É possível criar, alterar e remover tags.</p>
  <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>

  <div class="amp-dialog-content">
    <div class="amp-dialog-tags">
      <div class="amp-dialog-tag" *ngFor="let tag of smartTags; let i = index" [ngClass]="{'amp-dialog-selected-tag': i == tagIndexSelected}" (click)="selectTag(i)">
        <span>{{tag.name}}</span>
        <div class="amp-tag-icons">
          <i (click)="renameTag()" *ngIf="i == tagIndexSelected" class="bi-pencil"></i>
          <i (click)="deleteTag()" *ngIf="i == tagIndexSelected" class="bi-trash3"></i>
        </div>
      </div>
      <div (click)="openCreateNewTag()" class="amp-dialog-new-tag">
        <span>nova tag</span>
        <i class="bi-plus-lg"></i>
      </div>
    </div>
    <div class="amp-dialog-divisor"></div>
    <div class="amp-dialog-keyword">
      <span class="amp-dialog-keyword-title">Palavras-chave (insira separando por vírgula)</span>
      <textarea type="text" (keyup)="validateKeywords($event)" [disabled]="!smartTags.length" [(ngModel)]="keywordsString" placeholder="Ex.: site,atendimento,preço"></textarea>
    </div>
  </div>

  <div class="amp-dialog-actions">
    <button (click)="updateTag()" type="submit" class="amp-btn-success">
      <span>Salvar e Sair</span>
    </button>
  </div>
</div>