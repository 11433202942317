import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, StoreService } from 'app/_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DisableAccountDialogComponent } from 'app/_dialogs/disable-account-dialog/disable-account-dialog.component';
import { InfoDialogComponent } from 'app/_dialogs/info-dialog/info-dialog.component';
import { LoginDialogComponent } from 'app/_dialogs/login-dialog/login-dialog.component';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public store: StoreService,
    public authService: AuthenticationService,
    private modalService: BsModalService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('viacep.com.br')) {
      return next.handle(req);
    }
    if (req.url.includes('integration.services')) {
      return next.handle(req);
    }
    if (req.url.includes('api.pipedrive.com')) {
      return next.handle(req);
    }
    if (req.url.includes('cloudflare')) {
      return next.handle(req);
    }

    req = req.clone({
      setHeaders: {
        Authorization: localStorage.getItem('access_token') || ``,
        Ampl_Company: localStorage.getItem('empresaId') || ``,
        Ampl_Business: localStorage.getItem('business') || ``
      }
    });

    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (req.url.includes('invalidate-session')) {
              localStorage.removeItem('access_token');
            }
            if (event.status == 200) {
              if (!req.url.includes('invalidate-session')) {
                if (event.headers.get('Authorization') !== null && localStorage.getItem('access_token')) {
                  localStorage.setItem('access_token', event.headers.get('Authorization'));
                }
              }
            }

            if (event.url.match(/ampl.me/g)) {
              return;
            }

            if (event.status == 401) {
              localStorage.removeItem('access_token');
              if (this.store.isShowingLoginDialog || window.location.pathname == '/login') {
                return;
              }

              const modal = this.modalService.show(LoginDialogComponent, {
                class: 'amp-modal'
              });

              this.store.isShowingLoginDialog = true;

              modal.content.result.subscribe(() => {
                this.store.isShowingLoginDialog = false;
              });

              if (event.body.reason == 'empresa_not_active') {
                this.modalService.show(DisableAccountDialogComponent, {
                  class: 'modal-lg amp-modal'
                });
              }
            } else if (event.status == 403) {
              this.modalService.show(InfoDialogComponent, {
                class: 'modal-lg amp-modal',
                initialState: {
                  title: 'Permissão',
                  message: 'Você não tem a permissão necessária para executar esta ação!',
                  buttonText: 'OK!',
                  buttonType: 'info'
                }
              });
            }
          }
        },
        (error) => {
          console.error(error);
          if (error.url.match(/ampl.me/g)) {
            return;
          }

          if (error.status == 503) {
            this.returnMaintenance();
            localStorage.removeItem('access_token');
            localStorage.removeItem('businessId');
            this.router.navigate(['/login']);
          }

          if (error.status == 401) {
            localStorage.removeItem('access_token');
            if (this.store.isShowingLoginDialog || window.location.pathname == '/login') {
              return;
            }

            const modal = this.modalService.show(LoginDialogComponent, {
              ignoreBackdropClick: true,
              class: 'amp-modal'
            });

            this.store.isShowingLoginDialog = true;

            modal.content.result.subscribe(() => {
              this.store.isShowingLoginDialog = false;
            });

            if (error.error.reason == 'empresa_not_active') {
              this.modalService.show(DisableAccountDialogComponent, {
                class: 'modal-lg amp-modal'
              });
            }
          } else if (error.status == 403) {
            this.modalService.show(InfoDialogComponent, {
              class: 'modal-lg amp-modal',
              initialState: {
                title: 'Erro de Permissão',
                message: 'Você não tem a permissão necessária para executar a ação!',
                buttonText: 'OK',
                buttonType: 'danger'
              }
            });
          }
        }
      )
    );
  }

  returnMaintenance() {
    const initialState = {
      title: 'Manutenção',
      message: 'Estamos passando por atualizações, a plataforma está ',
      strongText: 'temporariamente desabilitada. ',
      messageEnd: 'Retornamos em breve.'
    };
    this.modalService.show(InfoDialogComponent, {
      initialState,
      class: 'modal-lg amp-modal'
    });
  }
}
