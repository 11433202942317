import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { Link } from 'app/_models/link';
@Injectable()
export class LinkService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getById(_id: string) {
    return this.http.get<Link>(this.config.apiUrl + '/link/' + _id);
  }

  getMySMSs(_business: string) {
    return this.http.get<Link[]>(this.config.apiUrl + '/link/business/' + _business);
  }

  create(link: any): any {
    return this.http.post<any>(this.config.apiUrl + '/link/', link);
  }

  getRequests(filters, limit, page) {
    const query = new URLSearchParams({
      limit: limit,
      page: page
    });

    return this.http.post<any>(this.config.apiUrl + '/link/getRequests?' + query.toString(), filters);
  }
}
