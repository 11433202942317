import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import PendingPayments from 'app/_models/adm/pendingPayments';
@Injectable({ providedIn: 'root' })
export class PendingPaymentService {
  constructor(private httpClient: HttpClient, private config: AppConfig) {}

  get(skip?: number, limit?: number, filter?: string) {
    const params = new URLSearchParams();
    if (limit) {
      params.set('limit', limit.toString());
    }
    if (skip) {
      params.set('limit', skip.toString());
    }
    if (filter) {
      params.set('filter', filter.toString());
    }
    return this.httpClient.get<PendingPayments[]>(this.config.apiUrl + '/adm/pendingPayments?' + params.toString());
  }
}
