import { Business } from './../business';
import { User } from './../user';
import { EmailTemplate } from '../emailTemplate';
export class Campaign {
  _id: string;
  title = '';
  description = '';
  terms_url = '';
  channels: string[] = [];
  active = true;

  reward_referee: {
    name: string;
    expiration: number;
    instructions: string;
  } = { name: '', expiration: null, instructions: '' };

  reward_referrer: {
    name: string;
    expiration: number;
    instructions: string;
  } = { name: '', expiration: null, instructions: '' };

  email_message_1 = '';
  email_message_2 = '';
  dark_mode = false;

  sms_message = '';

  share_message = '';

  primary_color = '#5885f7';
  text_color = '#ffffff';
  widget_position = '';
  widget_url = '';
  referral_url = '';

  content_about = '';
  content_refer = '';
  content_coupons = '';

  widget_stats: {
    view: number;
    click: number;
    open: number;
  };

  has_reward = true;

  referee_can_refer = true;
  referee_welcome_message = '';
  referee_thankyou_message = '';

  preview = 'authentication';
  direct_referral = false;

  customer_form_fields: string[] = ['name', 'email', 'phone'];
  require_fullname = false;
  optin_custom_fields: [
    {
      name: string;
      placeholder: string;
    }
  ] = [{ name: '', placeholder: '' }];

  custom_fields_referee: string[] = [];
  custom_fields_referrer: string[] = [];

  allow_multiple_referrals = false;

  ip_blacklist: string[] = [];

  type = 'referral';

  verification_mode = 'none';

  validation_mode = 'signup';
  _businesses: any[] = [];

  milestones: [
    {
      name: string; //Nome da Recompensa
      required_referrals: number; //Indicações Necessárias
      send_email: boolean; // é para enviar um e-mail
      _emailTemplate: EmailTemplate;
      type: string;
      amount: number;
    }
  ] = [{ name: 'Recompensa de Exemplo', required_referrals: 1, send_email: false, _emailTemplate: null, type: 'generic', amount: 0 }];

  reward_email = '';

  //Uses Amplifique.me Pages
  lr_enabled: boolean;
  lp_slug = '';
  company_logo = '';
  hero_button_text = 'Indicar agora!';
  hero_text = 'Indique a Empresa X para seus amigos. Quanto mais você indica, mais você ganha!';
  hero_image = 'https://source.unsplash.com/random';
  hero_text_color = '#ffffff';
  page_bg_color = '#445571';
  share_widget_title = 'Indique seus amigos';
  milestones_widget_title = 'Recompensas';
  custom_footer = '';
  site_link = '';
  fb_link = '';
  ig_link = '';
  ln_link = '';
  tt_link = '';
  wa_link = '';
  redirect_after_referral: boolean;
  redirect_after_referral_url: string;

  lr_hero_button_text = 'Indicar agora!';
  lr_hero_text: string;
  lr_hero_image: string;
  lr_hero_text_color: string;
  lr_page_bg_color: string;
  lr_content_about: string;
  lr_share_widget_title: string;
  lr_milestones_widget_title: string;

  optin_sms_message = '';
  require_optin = false;

  _users_notify: User[] = [];

  _invite_emailTemplate: EmailTemplate = new EmailTemplate();
  _welcome_emailTemplate: EmailTemplate = new EmailTemplate();
  welcome_email = true;
  _referral_emailTemplate: EmailTemplate = new EmailTemplate();
  referral_email = true;
  _optin_emailTemplate: EmailTemplate = new EmailTemplate();
  referee_email = true;
  _referee_emailTemplate: EmailTemplate = new EmailTemplate();

  custom_status_new = 'Nova';
  custom_status_pending = 'Análise';
  custom_status_valid = 'Válida';
  custom_status_invalid = 'Inválida';

  custom_css = '';

  fb_pixel_enabled: boolean;
  fb_pixel_id: string;

  gl_remarketing_enabled: boolean;
  gl_remarketing_id: string;

  script_settings: {
    isCustomForm: boolean;
    tagForm: string;
    tagFieldName: string;
    tagFieldEmail: string;
  } = { isCustomForm: false, tagForm: '', tagFieldEmail: '', tagFieldName: '' };

  privacy_text = '';

  created_at: Date;
  _user: User;
  _business: Business;
}
