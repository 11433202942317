import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GalleryDialogComponent } from 'app/_dialogs/gallery-dialog/gallery-dialog.component';
import { LinkCreatorDialogComponent } from 'app/_dialogs/link-creator-dialog/link-creator-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
let EasyMDE = window['EasyMDE'];
@Component({
  selector: 'amp-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss']
})
export class AmpMarkdownEditorComponent implements AfterViewInit, OnDestroy {
  @Input() allowImage = true;
  @Input() allowLinks = true;

  public subscriptions = [];

  @Input() markdown: any;
  @Output() markdownChange = new EventEmitter<string>();

  @ViewChild('mdeField', { static: false }) mdField: ElementRef;

  @Input()
  set content(value) {
    this.markdownChange.emit(value);
    this._content = value;
  }
  get content() {
    return this._content;
  }
  public _content: string;
  public selectionStart;
  public selectionEnd;
  public replacedText = '';
  public showField = true;
  public fieldLoaded = false;
  public easyMDE;

  constructor(public modalService: BsModalService) {
    this.content = this.markdown;
  }

  ngAfterViewInit() {
    this.loadMDE();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadMDE() {
    EasyMDE = window['EasyMDE'];
    this.easyMDE = new EasyMDE({
      element: this.mdField.nativeElement,
      toolbar: [
        {
          name: 'bold',
          action: EasyMDE.toggleBold,
          className: 'bi-type-bold',
          title: 'Negrito'
        },
        {
          name: 'italic',
          action: EasyMDE.toggleItalic,
          className: 'bi-type-italic',
          title: 'Itálico'
        },
        {
          name: 'heading',
          action: EasyMDE.toggleHeadingSmaller,
          className: 'bi-textarea-t',
          title: 'Heading'
        },
        '|',
        {
          name: 'unordered-list',
          action: EasyMDE.toggleUnorderedList,
          className: 'bi-list-ul',
          title: 'Lista Genérica'
        },
        {
          name: 'ordered-list',
          action: EasyMDE.toggleOrderedList,
          className: 'bi-list-ol',
          title: 'Lista Ordenada'
        },
        '|',
        {
          name: 'link',
          action: (editor) => {
            if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;

            const linkCreator = this.modalService.show(LinkCreatorDialogComponent, {
              class: 'amp-modal'
            });

            this.subscriptions.push(
              linkCreator.content.resultado.subscribe((data) => {
                const cm = editor.codemirror;
                const options = editor.options;
                const startPoint = cm.getCursor('start');
                const endPoint = cm.getCursor('end');
                let text;
                const url = data;
                text = cm.getSelection();
                cm.replaceSelection(`[${data.text}](${data.url})`);
                cm.focus();
              })
            );
          },
          className: 'bi-link-45deg',
          title: 'Link',
          default: true
        },
        {
          name: 'image',
          action: (editor) => {
            if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;

            const galleryPicker = this.modalService.show(GalleryDialogComponent, {
              class: 'amp-modal-lg col-xl-7 col-lg-9 col-md-10 col-sm-12'
            });

            this.subscriptions.push(
              galleryPicker.content.result.subscribe((data) => {
                const cm = editor.codemirror;
                const options = editor.options;
                let start = options.insertTexts.image[0];
                let end = options.insertTexts.image[1];
                const startPoint = cm.getCursor('start'),
                  endPoint = cm.getCursor('end');
                const url = data;
                start = start.replace('#url#', url);
                end = end.replace('#url#', url);
                const text = cm.getSelection();
                cm.replaceSelection(start + text + end);

                startPoint.ch += start.length;

                if (startPoint !== endPoint) {
                  endPoint.ch += start.length;
                }

                cm.setSelection(startPoint, endPoint);
                cm.focus();
              })
            );
          },
          className: 'bi-images',
          title: 'Imagem'
        },
        '|',
        {
          name: 'custom',
          action: (editor) => {
            if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;
            const cm = editor.codemirror;
            let start = '<center>';
            let end = '</center>';
            const startPoint = cm.getCursor('start');
            const endPoint = cm.getCursor('end');
            const text = cm.getLine(startPoint.line);
            start = text.slice(0, startPoint.ch);
            end = text.slice(startPoint.ch);
            start = start.replace(/(\*\*|<center>)(?![\s\S]*(\*\*|<center>))/, '');
            end = end.replace(/(\*\*|<\/center>)/, '');
            cm.replaceRange(
              start + end,
              {
                line: startPoint.line,
                ch: 0
              },
              {
                line: startPoint.line,
                ch: 99999999999999
              }
            );
            startPoint.ch -= 8;
            if (startPoint !== endPoint) {
              endPoint.ch -= 8;
            }
            cm.setSelection(startPoint, endPoint);
            cm.focus();
          },
          className: 'bi-justify-left',
          title: 'Left'
        },
        {
          name: 'custom',
          action: (editor) => {
            if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;
            const cm = editor.codemirror;
            const stat = editor.getState(cm);
            let text;
            const start = '<center>';
            const end = '</center>';
            const startPoint = cm.getCursor('start');
            const endPoint = cm.getCursor('end');
            text = cm.getSelection();
            text = text.split('<center>').join('');
            text = text.split('</center>').join('');
            cm.replaceSelection(start + text + end);
            startPoint.ch += '<center>'.length;
            endPoint.ch = startPoint.ch + text.length;
            cm.setSelection(startPoint, endPoint);
            cm.focus();
          },
          className: 'bi-text-center',
          title: 'Center'
        },
        {
          name: 'custom',
          action: (editor) => {
            if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;
            const cm = editor.codemirror;
            const stat = editor.getState(cm);
            let text;
            const start = '<p style="text-align: right">';
            const end = '</p>';
            const startPoint = cm.getCursor('start');
            const endPoint = cm.getCursor('end');
            text = cm.getSelection();
            text = text.split('<p style="text-align: right">').join('');
            text = text.split('</p>').join('');
            cm.replaceSelection(start + text + end);
            startPoint.ch += '<p style="text-align: right">'.length;
            endPoint.ch = startPoint.ch + text.length;
            cm.setSelection(startPoint, endPoint);
            cm.focus();
          },
          className: 'fa fa-align-right',
          title: 'Right'
        },
        '|',
        {
          name: 'custom',
          className: 'bi-braces',
          title: 'Interpolação',
          children: [
            {
              name: 'customer-name',
              action: (editor) => {
                if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;
                const cm = editor.codemirror;
                const stat = editor.getState(cm);
                let text;
                const start = '{{customerName}}';
                const startPoint = cm.getCursor('start');
                const endPoint = cm.getCursor('end');
                text = cm.getSelection();
                text = text.split('{{customerName}}').join('');
                cm.replaceSelection(start + text);
                startPoint.ch += '{{customerName}}'.length;
                endPoint.ch = startPoint.ch + text.length;
                cm.setSelection(startPoint, endPoint);
                cm.focus();
              }
            },
            {
              name: 'company-customer-name',
              action: (editor) => {
                if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;
                const cm = editor.codemirror;
                const stat = editor.getState(cm);
                let text;
                const start = '{{companyName}}';
                const startPoint = cm.getCursor('start');
                const endPoint = cm.getCursor('end');
                text = cm.getSelection();
                text = text.split('{{companyName}}').join('');
                cm.replaceSelection(start + text);
                startPoint.ch += '{{companyName}}'.length;
                endPoint.ch = startPoint.ch + text.length;
                cm.setSelection(startPoint, endPoint);
                cm.focus();
              }
            },
            {
              name: 'custom-field',
              action: (editor) => {
                if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) return;
                const cm = editor.codemirror;
                const stat = editor.getState(cm);
                let text;
                const start = '{{customFields.nome_interno}}';
                const startPoint = cm.getCursor('start');
                const endPoint = cm.getCursor('end');
                text = cm.getSelection();
                text = text.split('{{customFields.nome_interno}}').join('');
                cm.replaceSelection(start + text);
                startPoint.ch += '{{customFields.nome_interno}}'.length;
                endPoint.ch = startPoint.ch + text.length;
                cm.setSelection(startPoint, endPoint);
                cm.focus();
              }
            }
          ]
        }
      ],
      status: [],
      toolbarTips: true,
      styleSelectedText: false,
      spellChecker: false,
      initialValue: this.markdown
    });

    this.easyMDE.codemirror.on('change', () => {
      this.save(this.easyMDE.value());
    });
    this.fieldLoaded = true;
  }

  highlightInterpolation(text) {
    const tagStart = '<span class="amp-interpolation">';
    const tagEnd = '</span>';

    const customerRegex = new RegExp('{{customerName}}', 'gi');
    const companyRegex = new RegExp('{{companyName}}', 'gi');

    text = text.replace(customerRegex, tagStart + 'Nome do Contato' + tagEnd);
    text = text.replace(companyRegex, tagStart + 'Nome da Empresa do Contato' + tagEnd);

    this.replacedText = text;
  }

  save(value) {
    this.content = value;
  }
}
