import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { Plan } from 'app/_models/adm/plan';
import ProductsPlan from 'app/_models/adm/productsPlan';
@Injectable()
export class PlanService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  create(data: ProductsPlan) {
    return this.http.post(this.config.apiUrl + '/products-plan/', data);
  }

  get() {
    return this.http.get<any>(this.config.apiUrl + '/products-plan/');
  }

  getByType() {
    return this.http.get<any>(this.config.apiUrl + '/products-plan/type');
  }

  update(data: ProductsPlan) {
    return this.http.put<Plan>(this.config.apiUrl + '/products-plan/' + data._id, data);
  }

  delete(data: ProductsPlan) {
    return this.http.delete<any>(this.config.apiUrl + '/products-plan/' + data._id);
  }
}
