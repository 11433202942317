<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <p class="amp-section-title">Detalhes do Webhook</p>
      <div class="amp-card">
        <div class="amp-card-left mb-2">
          <div>
            <span><b>URL: </b></span>
            <span class="amp-url">{{webhook?.endpoint_url }}</span>
          </div>
          <div>
            <span><b>Descrição: </b></span>
            <span>{{webhook?.description}}</span>
          </div>
          <div>
            <span><b>Eventos: </b></span>
            <span>[{{getEvents()}}]</span>
          </div>
          <div>
            <span><b>Criado em: </b></span>
            <span>{{webhook?.created_at | date:'dd/MM/yy HH:mm:ss'}}</span>
          </div>
          <div>
            <span><b>Status: </b></span>
            <span [ngClass]="{'disabled': !webhook?.active}" class="amp-badge">{{webhook?.active ? 'Ativado' : 'Desativado'}}</span>
          </div>
        </div>

        <div class="d-flex">
          <button (click)="editWebhook()" class="amp-btn edit-webhook mr-2">Editar Webhook</button>

          @if (webhook?.active) {
          <button (click)="toggleStatusWebhook()" class="amp-btn disable-webhook mr-2">Desativar Webhook</button>
          } @else {
          <button (click)="toggleStatusWebhook()" class="amp-btn-success enable-webhook mr-2">Ativar Webhook</button>
          }

          <button (click)="deleteWebhook()" class="amp-btn-danger remove-webhook">Excluir Webhook</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <p class="amp-section-title">Segredo</p>
      <div class="amp-card amp-secret-card flex-row">
        @if (isSecretHidden) {
        <span class="amp-fake-secret">this_is_not_the_real_secret</span>
        <button (click)="getSecret()" class="amp-btn amp-reveal-secret">Revelar Segredo</button>
        } @else {
        <span class="amp-real-secret">{{ secret }}
          <i (click)="copyToClipboard(secret)" tooltip="Copiar" class="bi-files"></i>
        </span>
        <button (click)="isSecretHidden = true; secret = ''" class="amp-btn amp-reveal-secret">Esconder Segredo</button>
        }
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <p class="amp-section-title">Tentativas</p>
      <div class="amp-card amp-attempts">
        <span *ngIf="!webhookAttempts.length">Não houveram eventos.</span>
        <div *ngIf="webhookAttempts.length" class="amp-header">
          <div class="amp-table-column amp-status">Status</div>
          <div class="amp-table-column amp-event">Evento</div>
          <div class="amp-table-column amp-id">ID</div>
          <div class="amp-table-column amp-created">Enviado em</div>
        </div>
        <div class="amp-attempt" *ngFor="let attempt of webhookAttempts; let i = index">
          <div (click)="showDetails == i ? showDetails = -1 : showDetails = i" class="amp-line">
            <div class="amp-status">
              <i *ngIf="attempt.executions.responseStatus == 200 || attempt.executions.responseStatus == 201" tooltip="Sucesso" class="bi-check-lg"></i>
              <i *ngIf="!(attempt.executions.responseStatus == 200 || attempt.executions.responseStatus == 201)" tooltip="Falha" class="bi-x-lg"></i>
            </div>
            <div class="amp-event">
              {{attempt.event_type}}
            </div>
            <div class="amp-id">
              {{attempt._id}}
            </div>
            <div class="amp-created">
              {{attempt.executions.executed_at | date:'dd/MM/yy HH:mm:ss'}}
            </div>
          </div>
          <div *ngIf="showDetails == i" class="amp-http">
            <div class="amp-left">
              <span><b>HTTP Status Code</b></span>
            </div>
            <div class="amp-right">
              <span>{{attempt.executions.responseStatus}} - {{attempt.executions.responseStatus == 500 && attempt.executions.responseTime > 10000? 'Tempo Limite Esgotado (10s)' : ''}}</span>
            </div>
          </div>
          <div *ngIf="showDetails == i" class="amp-http">
            <div class="amp-left">
              <span><b>Response Time</b></span>
            </div>
            <div class="amp-right">
              <span>{{attempt.executions.responseTime}} ms</span>
            </div>
          </div>
          <div *ngIf="showDetails == i" class="amp-request">
            <div class="amp-left">
              <span><b>Request Body</b></span>
            </div>
            <div class="amp-right">
              <pre [innerHTML]="attempt.requestBody | prettyjson:[true, 3]"></pre>
            </div>
          </div>
          <div *ngIf="showDetails == i" class="amp-response">
            <div class="amp-left">
              <span><b>Response Body</b></span>
            </div>
            <div class="amp-right">
              <pre [innerHTML]="attempt.executions.responseBody | prettyjson:[true, 3]"></pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>