import { SelectImportDialogComponent } from 'app/_dialogs/select-importer-dialog/select-importer-dialog.component';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { NotifyService } from '../../_services/notify.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from '../../_services/menu.service';
import { StoreService } from 'app/_services/store.service';
import { UtilService } from 'app/_services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import moment from 'moment';
@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit, OnDestroy {
  private subjectSearchField: Subject<string> = new Subject();
  public date = moment();
  public imports = [];
  public subscriptions = [];
  public adminMode = false;
  public limit = 20;
  public page = 1;
  public paginationPage = 1;
  public totalItems = 0;
  public searchField = '';
  public interval;
  public hasInProgress = false;
  public id;

  constructor(
    public notifyService: NotifyService,
    public auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private modalService: BsModalService,
    private store: StoreService,
    private utilService: UtilService
  ) {
    this.menuService.emit(true);
    this.menuService.emitSidebar(true);
    this.menuService.emitPageTitle('Importações');
  }

  ngOnInit() {
    if (this.store.business._id) {
      this.getImports();
    }

    this.id = this.route.snapshot.queryParams['id'];

    this.subscriptions.push(
      this.menuService.getChangeEmmiterBusiness().subscribe(() => {
        this.getImports();
      })
    );

    this.subscriptions.push(
      this.subjectSearchField
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          this.page = 0;
          this.getImports();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  onTypeSearch(value) {
    this.subjectSearchField.next(value);
  }

  filterBy(importation) {
    if (importation.type == 'import_answers') {
      this.router.navigate(['customer-feedback'], {
        queryParams: { importDataId: importation._id }
      });
    } else {
      this.router.navigate(['contatos'], {
        queryParams: { importDataId: importation._id, importDataDate: this.imports.find((item) => item._id == importation._id).created_at }
      });
    }
  }

  openAnswerImporter() {
    this.router.navigate(['/importer/answers']);
  }

  openContactImporter() {
    if (this.store.empresaData._id == '641c4893ffbae4006ef75f24') {
      this.modalService.show(SelectImportDialogComponent, {
        class: 'amp-modal-large'
      });
    } else {
      this.router.navigate(['/importer/contacts']);
    }
  }

  getImports(page = 1) {
    this.page = page;
    this.imports = [];

    if (this.adminMode) {
      this.subscriptions.push(
        this.utilService.getImportsForAdmin(this.limit, this.page, this.searchField).subscribe((data) => {
          this.imports = data.data;
          this.totalItems = data.total;
        })
      );
    } else {
      this.subscriptions.push(
        this.utilService.getImports(this.store.business._id, this.limit, this.page).subscribe((data) => {
          this.imports = data.data;
          this.totalItems = data.total;
          this.hasInProgress = data.data.some((item) => item.status !== 'finished' && item.status !== 'error');
          if (this.hasInProgress) {
            if (!this.interval) {
              this.setupInterval();
            }
          } else {
            if (this.interval) {
              clearInterval(this.interval);
              this.interval = null;
              this.router.navigate(['contatos'], {
                queryParams: { importDataId: this.id, importDataDate: this.imports.find((item) => item._id == this.id).created_at }
              });
            }
          }
        })
      );
    }
  }

  checkExpiration(date) {
    return this.date.diff(date, 'd');
  }

  setupInterval() {
    this.interval = setInterval(() => this.getImports(), 5_000);
  }
}
