import { AuthenticationService, ForgotPasswordService, NotifyService, StoreService, UserService } from 'app/_services';
import { Component, HostListener, OnDestroy } from '@angular/core';
import { MenuService } from 'app/_services/menu.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Role, User } from 'app/_models/user';
import { Subject } from 'rxjs';
import moment from 'moment';
@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public notifyService: NotifyService,
    private store: StoreService,
    private userService: UserService,
    private menuService: MenuService,
    private forgotPasswordService: ForgotPasswordService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) {}

  public result: Subject<boolean> = new Subject();
  public loading = false;
  public subscriptions = [];
  public authenticator = false;
  public showPassword = false;
  public model: any = {};
  public forgotPassword: any = 0;
  public returnUrl: string;
  public fail = false;
  public fp: any = {};
  public hasDot = false;
  public userId = '';
  public token = '';
  public maskPin = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(event) {
    if (event.code == 'Enter') {
      this.confirmar();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  checkDot() {
    if (this.model?.email?.split('@')[1]?.includes('.')) {
      this.hasDot = true;
    } else {
      this.hasDot = false;
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    (document.getElementById('password') as HTMLInputElement).type = this.showPassword ? 'text' : 'password';
  }

  changeToken(data) {
    this.token = data;
    if (data.length == 6) {
      this.validateAuthenticator();
    }
  }

  cancelar() {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  confirmar() {
    this.result.next(true);
    this.bsModalRef.hide();
  }

  login() {
    this.loading = true;
    this.fail = false;
    this.subscriptions.push(
      this.authenticationService.login(this.model.email, this.model.senha, this.model.keepMeLoggedIn).subscribe(
        (data) => {
          if (data.result == 1) {
            this.proccessAfterLogin();
          } else if (data.result == -1) {
            this.authenticator = true;
            this.loading = false;
            this.userId = data.user;
          } else {
            this.fail = true;
            this.loading = false;
            this.notifyService.error('Senha incorreta!');
          }
        },
        (error) => {
          console.error(error);
          this.fail = true;
          this.loading = false;
          if (error.status == 429) {
            const dur = moment.duration(error.error['retryAfter'], 's').as('milliseconds');
            this.notifyService.error(`Bloqueado! Tente novamente em ${moment.utc(dur).hours()} horas e ${moment.utc(dur).minutes()} minutos.`);
            console.error(error.error['retryAfter']);
          } else {
            this.notifyService.error('Houve um erro ao efetuar o login!');
          }
        }
      )
    );
  }

  loginWithMicrosoft() {
    const win = window.open('https://api.amplifique.me/user/msal/auth', '_blank');

    const token = localStorage.getItem('access_token');

    const interval = setInterval(() => {
      if (token != localStorage.getItem('access_token')) {
        win.close();
        this.proccessAfterLogin();
        clearInterval(interval);
      }
    }, 1000);
  }

  proccessAfterLogin() {
    this.subscriptions.push(
      this.userService.getInfo().subscribe((dadosUser: User) => {
        if (dadosUser.role == Role.mf) {
          this.returnUrl = '/ampemp';
        } else {
          localStorage.setItem('empresaNome', dadosUser._empresa.nome);
          localStorage.setItem('empresaId', dadosUser._empresa._id);
          this.store.empresa = dadosUser._empresa._id;
          localStorage.setItem('empresaWizard', dadosUser._empresa.wizard.toString());
        }
        this.menuService.emitUser(dadosUser);

        this.cancelar();
      })
    );
  }

  esqueciSenha() {
    this.fail = false;
    this.subscriptions.push(
      this.forgotPasswordService.create(this.fp).subscribe(
        () => {
          this.forgotPassword = 0;
          this.subscriptions.push(
            this.translate.get('notify.forgot-password.success').subscribe((res) => {
              this.notifyService.success(res);
            })
          );
        },
        (error) => {
          console.error(error);
          this.fail = true;
        }
      )
    );
  }

  validateAuthenticator() {
    if (!this.loading) {
      this.loading = true;
      this.subscriptions.push(
        this.authenticationService.validateAuthenticatorToken(this.userId, this.token, this.model.keepMeLoggedIn).subscribe(
          (data) => {
            localStorage.setItem('access_token', data.token);
            this.authenticationService.getTokenPayload();
            this.proccessAfterLogin();
          },
          (error) => {
            console.error(error);
            this.loading = false;
            this.fail = true;
            if (error.error.message == 'TOKEN_INVALID') {
              this.notifyService.error('Token incorreto!');
            } else {
              this.notifyService.error('Não foi possível validar o token!');
            }
          }
        )
      );
    }
  }
}
