<div class="amp-dialog" id="amp-autocomplete">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Gerenciador de Tags
    </div>
    <div class="amp-dialog-subtitle"></div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-field amp-company-selector-field-header">
    <label class="amp-label-text">Selecione ou cadastre uma tag</label>
    <input type="search" class="amp-input" name="searchCompany" autofill="false" autocomplete="false"
      (keyup)="onTypeSearchField($event.target.value)" [(ngModel)]="searchField" #name="ngModel"
      placeholder="Buscar tag...">
  </div>

  <div class="amp-list">
    <div class="amp-list-empty" *ngIf="!tags?.length">
      NENHUM RESULTADO ENCONTRADO
    </div>
    <div class="amp-list-item" *ngFor="let tag of tags" (click)="selectTag(tag)">
      <span class="bi-tag"></span>
      <div class="amp-company-list-item">
        <div class="amp-company-list-item-name">{{ tag.name }}</div>
        <div class="amp-company-list-item-subtitle">Criado em {{tag.created_at | amLocale:'pt-BR' | amDateFormat:
          'DD/MM/YY'}}</div>
      </div>
    </div>
  </div>

  <button type="button" (click)="newTag()" class="amp-btn-outline-primary amp-btn amp-company-selector-btn">Cadastrar
    Nova Tag</button>
</div>