<div class="amp-landing-editor">
  <div class="amp-landing-sidebar-editor animate__animated animate__faster animate__slideInLeft">

  <div class="amp-sidebar-section">
    <p class="amp-card-title">Landing Page do Programa de Indicação</p>

    <div class="amp-field">
      <label class="amp-label-text">Texto do botão</label>
      <input type="text" (focus)="scroll(heroSection)" class="amp-input" [(ngModel)]="campaign.hero_button_text">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Texto do cabeçalho</label>
      <input type="text" (focus)="scroll(heroSection)" class="amp-input" [(ngModel)]="campaign.hero_text">
    </div>

    <div class="amp-color-selector-input">
      <label class="amp-label-text">Cor do texto do cabeçalho</label>
      <div class="amp-color-selector-wrapper">
        <div class="amp-color-selector-name" id="colorPicker" (click)="toglePickerHeroTextColor();scroll(heroSection)">
          {{campaign.hero_text_color}}</div>
        <div class="amp-color-selector" id="colorPicker" (click)="toglePickerHeroTextColor();scroll(heroSection)" [ngStyle]="{'background': campaign.hero_text_color}"></div>


        <div class="amp-colorpicker" *ngIf="heroTextColorSelector" clickOutside (clickOutside)="toglePickerHeroTextColor()">
          <color-block [color]="campaign.hero_text_color" [colors]="colors" width="170" (onChangeComplete)="changeHeroTextColor($event)">
          </color-block>
        </div>
      </div>
    </div>


    <div class="amp-color-selector-input">
      <label class="amp-label-text">Cor de fundo da página</label>
      <div class="amp-color-selector-wrapper">
        <div class="amp-color-selector-name" id="colorPicker" (click)="togglePickerPageBgColor();scroll(contentAbout)">
          {{campaign.page_bg_color}}</div>
        <div class="amp-color-selector" id="colorPicker" (click)="togglePickerPageBgColor();scroll(contentAbout)" [ngStyle]="{'background': campaign.page_bg_color}"></div>

        <div class="amp-colorpicker" *ngIf="pageBgColorSelector" clickOutside (clickOutside)="togglePickerPageBgColor()">
          <color-block [color]="campaign.page_bg_color" [colors]="colors" width="170" (onChangeComplete)="changePageBgColor($event)">
          </color-block>
        </div>
      </div>
    </div>

    <a class="amp-btn-link p-0" *ngIf="!campaign.hero_image" (click)="uploadCover()" tooltip="Recomendamos que a imagem não contenha texto, pois ela se adapta ao tamanho do dispositivo e pode sofrer cortes. Tamanhos sugeridos: 1366x700 e 1920x700." placement="right">Adicionar imagem de fundo</a>
    <a class="amp-btn-link-danger p-0" *ngIf="campaign.hero_image" (click)="campaign.hero_image=null">Remover imagem de fundo</a>

    <div class="amp-field" *ngIf="this.campaign.channels.indexOf('widget') == -1">
      <label class="amp-label-text" for="termsUrl">Slug da Landing Page</label>
      <input type="text" class="amp-input-lg" [(ngModel)]="campaign.lp_slug" (keyup)="onChangeSlug($event.target.value)" autocomplete="off" placeholder="" #lpSlug="ngModel" name="termsUrl" required>
      <div class="amp-field-message" *ngIf="validSlug!='free' && validSlug!=''"><img
        src="/assets/img/icon-input-error.svg"> <span *ngIf="validSlug=='used'">Slug já utilizada</span> <span *ngIf="validSlug=='invalid'">Slug inválida</span></div>
    </div>

  </div>
    <hr class="amp-hr"/>
    <div class="amp-sidebar-section">
      <p class="amp-card-title">Sessão Sobre</p>
      <div class="amp-field">
        <label class="amp-label-text">Texto da sessão sobre</label>
        <amp-markdown-editor name="markdown2" required [(markdown)]="campaign.content_about"  placeholder="Texto do Sobre" name="contentRefer" allowImage="false" allowLinks="true">
        </amp-markdown-editor>
      </div>
    </div>
    <hr class="amp-hr"/>

    <div class="amp-sidebar-section">
      <p class="amp-card-title">Widgets</p>
    <div class="amp-field">
      <label class="amp-label-text">Título do Widget de Autenticação / Indicação / Agradecimento</label>
      <input type="text"  (focus)="scroll(shareWidgetTitle)" class="amp-input" [(ngModel)]="campaign.share_widget_title">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Título do Widget de Recompensas / Milestones</label>
      <input type="text"  (focus)="scroll(milestoneWidgetTitle)" class="amp-input" [(ngModel)]="campaign.milestones_widget_title">
    </div>
    </div>

    <hr class="amp-hr"/>

    <div class="amp-sidebar-section">
      <p class="amp-card-title">Rodapé</p>

    <div class="amp-field">
      <label class="amp-label-text">Mensagem Personalizada (opcional)</label>
      <input type="text" class="amp-input" placeholder="Conteúdo exclusivo de..." (focus)="scroll(links)" [(ngModel)]="campaign.custom_footer">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Link do Site (opcional)</label>
      <input type="text" class="amp-input" placeholder="https://www.exemplo.com.br" (focus)="scroll(links)" [(ngModel)]="campaign.site_link">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Link da Página no Facebook (opcional)</label>
      <input type="text" class="amp-input" placeholder="https://www.facebook.com/exemplo/" (focus)="scroll(links)" [(ngModel)]="campaign.fb_link">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Link do Perfil do Instagram (opcional)</label>
      <input type="text" class="amp-input" placeholder="https://www.instagram.com/exemplo/" (focus)="scroll(links)" [(ngModel)]="campaign.ig_link">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Link do Perfil do LinkedIn (opcional)</label>
      <input type="text" class="amp-input" placeholder="https://www.linkedin.com/company/exemplo/" (focus)="scroll(links)" [(ngModel)]="campaign.ln_link">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Link do Perfil do Twitter (opcional)</label>
      <input type="text" class="amp-input" placeholder="https://twitter.com/exemplo" (focus)="scroll(links)" [(ngModel)]="campaign.tt_link">
    </div>

    <div class="amp-field">
      <label class="amp-label-text">Número do WhatsApp (opcional)</label>
      <input type="text" class="amp-input" placeholder="(XX) XXXXX-XXXX" mask="(00) 0 0000-0000 || (00) 0000-0000" (focus)="scroll(links)" [(ngModel)]="campaign.wa_link">
    </div>

    </div>

  </div>

  <div class="amp-sidebar-offset"></div>

  <div class="amp-landing-content">
    <div class="amp-header-logo">
      <a [href]="campaign.site_link" target="_blank">
        <img [src]="campaign.company_logo">
      </a>
    </div>
    <section class="amp-hero" #heroSection>
      <header>
      </header>
      <div class="amp-hero-background" [ngStyle]="{'background-image': 'url('+campaign.hero_image+')'}"></div>
      <div class="amp-hero-background-overlay"></div>
      <div class="container amp-hero-container">
        <div class="row amp-hero-row-container">

          <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12">
            <h1 class="amp-hero-title"  [ngStyle]="{'color': campaign.hero_text_color}">{{campaign.hero_text}}</h1>
            <button class="amp-btn" href="#referral" [ngStyle]="{'background-color': campaign.primary_color,'color':campaign.text_color}">{{campaign.hero_button_text}}</button>
          </div>
        </div>
      </div>
    </section>

    <section class="amp-main" [ngStyle]="{'background-color': campaign.page_bg_color}">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="amp-card">
              <div class="amp-card-content" #contentAbout>
                <showdown [value]="campaign.content_about"></showdown>
              </div>
            </div>
          </div>
        </div>

        <div class="row amp-referral" id="referral">
          <div class="col-lg-5">
            <h2 class="amp-section-title" #shareWidgetTitle [ngStyle]="{'color': campaign.hero_text_color}">{{campaign.share_widget_title}}</h2>
            <div data-ampl-referral="referralWidget"><amp-referral-widget-preview  type="milestone" [color]="campaign.primary_color" [textColor]="campaign.text_color" [campaign]="campaign"></amp-referral-widget-preview></div>
          </div>
          <div class="col-lg-5 offset-lg-2" >
            <h2 class="amp-section-title" #milestoneWidgetTitle [ngStyle]="{'color': campaign.hero_text_color}">{{campaign.milestones_widget_title}}</h2>
            <div data-ampl-referral="milestoneWidget"><amp-referral-milestone-widget-preview [campaign]="campaign"></amp-referral-milestone-widget-preview></div>
          </div>
        </div>
      </div>

    </section>

    <footer>
      <div class="amp-footer">
        <div class="container">
          <div class="amp-footer-logo">
            <a [href]="campaign.site_link" target="_blank">
              <img [src]="campaign.company_logo">
            </a>
          </div>
          <div class="amp-footer-custom-message">
            <span>{{campaign.custom_footer}}</span>
          </div>
          <div class="amp-footer-social" #links>
            <a *ngIf="campaign.ig_link" target="_blank" rel="noopener" [href]="campaign.ig_link"><img src="https://amplifique.me/wp-content/themes/amplifiqueme/assets/dist/img/instagram-logo.png"></a>
            <a *ngIf="campaign.fb_link" target="_blank" rel="noopener" [href]="campaign.fb_link"><img src="https://amplifique.me/wp-content/themes/amplifiqueme/assets/dist/img/facebook-logo.png"></a>
            <a *ngIf="campaign.ln_link" target="_blank" rel="noopener" [href]="campaign.ln_link"><img src="https://amplifique.me/wp-content/themes/amplifiqueme/assets/dist/img/linkedin-logo.png"></a>
            <a *ngIf="campaign.tt_link" target="_blank" rel="noopener" [href]="campaign.tt_link"><img src="https://amplifique.me/wp-content/themes/amplifiqueme/assets/dist/img/twitter-logo.png"></a>
            <a *ngIf="campaign.wa_link" target="_blank" rel="noopener"><img src="https://amplifique.me/wp-content/themes/amplifiqueme/assets/dist/img/whatsapp-logo.png"></a>
          </div>
          <div class="amp-footer-powered-by">
            <a href="https://amplifique.me/" rel="noopener" target="_blank">Powered by Amplifique.me</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
