import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';
@Component({
  selector: 'app-link-creator-dialog',
  templateUrl: './link-creator-dialog.component.html',
  styleUrls: ['./link-creator-dialog.component.scss']
})
export class LinkCreatorDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public text;
  public url;
  public resultado: Subject<any> = new Subject();

  cancelar() {
    this.bsModalRef.hide();
  }

  confirmar() {
    this.resultado.next({ text: this.text, url: this.url });
    this.bsModalRef.hide();
  }
}
