<section>
  <div class="description">Aqui você consegue verificar o status dos envios em lote da sua conta. Envios individuais ou via integração não aparecem nessa tela.</div>
  <div class="amp-actions">
    <div class="search">
      <input class="amp-input search" (keyup)="onKeyUp()" [(ngModel)]="searchInput" placeholder="Buscar pesquisa...">
      <i *ngIf="!searchInput.length" class="bi-search"></i>
      <i *ngIf="searchInput.length" class="bi-x-lg" (click)="searchInput = '';onKeyUp()"></i>
    </div>

    <amp-date-picker [showCustomDate]="true" [disableCookie]="true" [(filterTime)]="filterTime" [subject]="dateSubject" [(dateFilter)]="dateFilter"></amp-date-picker>    

    <button class="amp-btn-primary amp-btn-lg" *ngIf="auth.userRole == 'acmng'" (click)="adminMode = !adminMode; this.getRequestQueues()">
      {{ adminMode ? 'Desativar Modo Admin' : 'Ativar Modo Admin' }}
    </button>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="amp-card">
        <div class="amp-table">
          <div class="amp-table-head">
            <div class="amp-table-column" *ngIf="adminMode">Unidade</div>
            <div class="amp-table-column">Tipo</div>
            <div class="amp-table-column">Data de Solicitação</div>
            <div class="amp-table-column">Status</div>
            <div class="amp-table-column">Data de Envio</div>
            <div class="amp-table-column" *ngIf="auth.userRole == 'acmng'">Agendamento</div>
            <div class="amp-table-column">Processamento</div>
            <div class="amp-table-column">Progresso</div>
            <div class="amp-table-column"></div>
          </div>

          <ng-container *ngIf="!loading">

          <div [ngClass]="{ highlighted: flagFirst && idx == 0 }" class="amp-table-row amp-webhook-row" *ngFor="let item of queues; let idx = index" [routerLink]="'/queues/'+ item._id + '/detailed'" tooltip="Clique para ver mais detalhes.">
            <div class="amp-table-cell" *ngIf="adminMode">
              <b>{{ item.empresa?.nome }}</b> - {{ item.business?.nome }}
            </div>
            <div class="amp-table-cell">
              {{
              item.queue.type == 'cf'
              ? ('Solicitação de Pesquisa' + ' ' + (item.survey?.length > 0 ? (item.survey[0]?.title || '') : ''))
              : item.queue.type == 'referral'
              ? 'Convite para o Prog. de Indicação'
              : item.queue.type == 'nps'
              ? 'Solicitação de Pesquisa Antiga'
              : 'Solicitação de Avaliação'
              }}
            </div>
            <div class="amp-table-cell">{{ item.queue.created_at | date: 'dd/MM/yy HH:mm' }}</div>
            <div class="amp-table-cell">
              <span class="amp-queue-status finalized" *ngIf="item.scheduledSum>= 1 && item.percentageStatus == 1">Finalizado</span>
              <span class="amp-queue-status running" *ngIf="item.scheduledSum>= 1 && item.percentageStatus != 1">Em Processamento</span>
              <span class="amp-queue-status waiting" *ngIf="item.scheduledSum == 0">Aguardando</span>
            </div>
            <div class="amp-table-cell">{{ item.queue.schedule_params?.type == 'scheduled' ? (item.queue.schedule_params?.scheduled_to | date: 'dd/MM/yy HH:mm') : (item.queue.created_at | date: 'dd/MM/yy HH:mm') }}</div>
            <div class="amp-table-cell" *ngIf="auth.userRole == 'acmng'">
              {{ item.scheduledSum }} de {{ item.total }} ({{ item.percentageScheduled | percent }})
            </div>
            <div class="amp-table-cell">{{ item.statusSum }} de {{ item.total }}</div>
            <div class="amp-table-cell">
              <amp-progress-bar *ngIf="item.percentageStatus != 1" [value]="item.percentageStatus * 100" [onlyBar]="true" color="green"></amp-progress-bar>
            </div>
            <div class="amp-table-cell">
              <i *ngIf="item.queue.schedule_params?.type == 'scheduled' && !item.queue.scheduled && checkMinTime(item)" placement="auto" tooltip="Cancelar envio" (click)="cancelSending(item,$event)" class="bi-x-lg"></i>
            </div>
          </div>
        </ng-container>
        </div>

        <div class="pt-2">
          <amp-loading [size]="30" *ngIf="loading"></amp-loading>
          <div class="amp-not-found" *ngIf="!loading && !queues.length">
            <span>NENHUM RESULTADO ENCONTRADO</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <pagination [(ngModel)]="paginationPage" [totalItems]="totalItems" [itemsPerPage]="20" [rotate]="true" [maxSize]="9" [boundaryLinks]="true" [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" (pageChanged)="getRequestQueues($event.page)" [firstText]="'Primeira'" [lastText]="'Última'">
  </pagination>
</section>

<ng-template #nextTemplate>
  <span class="bi-chevron-right"></span>
</ng-template>
<ng-template #prevTemplate>
  <span class="bi-chevron-left"></span>
</ng-template>
