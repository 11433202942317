<div class="amp-select" (clickOutside)="onClickOutside()" placement="auto" container="body" (mouseover)="value?.length > 0 && showPopover && filterValue?.length > 20 ? pop.show() : null" (mouseout)="pop.hide()" #pop="bs-popover" [triggers]="''" containerClass="amp-fit-popover" boundariesElement="viewport" [popover]="surveyPopover" [popoverContext]="{ data: filterValue }">
  <i [class]="'start-icon ' + icon"></i>
  <input #input type="text" autocomplete="off" [placeholder]="placeholder" id="amp-select-input" [ngStyle]="{width: width ? width + 'px' : ''}" [ngClass]="{'small': small, 'disabled': disabled, 'dashed': dashed || disabled, 'start-icon-padding': icon.length, 'arrow-icon-padding': showArrow, 'clear-icon-padding': showClearInput && filterValue}" [readOnly]="!isSearchable" class="amp-input" name="select-filter" [(ngModel)]="filterValue" (focus)="!disabled ? onFocus() : null" (keyup)="onKeyUp($event)" (blur)="handleBlur()">
  <i *ngIf="showClearInput && value" (click)="clearInput()" class="bi-x-lg amp-clear-icon"></i>
  <i *ngIf="showArrow" (click)="input.focus()" class="bi-chevron-down amp-select-icon"></i>

  <div class="dropdown" [ngClass]="{'dropdown-autoresize': autoResize}" *ngIf="showDropdown">
    <div class="dropdown-inner dropdown-add-item" (click)="onActionClick(item.name)" *ngFor="let item of actions">
      <div class="option">
        <i [class]="'bi '+ item.icon"></i>
        {{ item.label }}
      </div>
    </div>
    <div class="dropdown-divider" *ngIf="isSearchable && actions.length"></div>
    <div class="dropdown-inner" *ngIf="optionsFiltered.length">
      <ng-container *ngIf="!optionsLoading">
        <ng-container *ngFor="let option of optionsFiltered">
        <div *ngIf="!option.hide" class="option cursor-pointer" [ngClass]="{'break-all': breakAll}"  (click)="selectOption($event, option)">
            <span class="amp-prefix" *ngIf="option.group && groupType == 'prefix'">{{ option.group }}</span>
            <i *ngIf="option.group && groupType == 'prefix'" _ngcontent-pav-c229="" class="amp-chevron bi-chevron-right"></i>
            <span class="amp-value">{{ option[displayBy] }}</span>
            <span class="option-group-label" *ngIf="option.group && groupType == 'badge'" [ngClass]="{'highlight': option.groupHighlight}">{{ option.group }}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="dropdown-inner amp-not-found" *ngIf="isSearchable && !filterValue.length && !optionsLoading && optionsFiltered.length == 0">
      DIGITE PARA PESQUISAR
    </div>

    <div class="dropdown-inner amp-not-found" *ngIf="isSearchable && filterValue.length && !optionsLoading && optionsFiltered.length == 0">
      NENHUM RESULTADO ENCONTRADO
    </div>

    <div class="loading" *ngIf="isSearchable && optionsLoading">
       <amp-loading [size]="35"></amp-loading>
    </div>
  </div>
</div>

<ng-template #surveyPopover let-data="data">
  <div class="amp-select-popover">
    <span>{{ data }}</span>
  </div>
</ng-template>
