import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class FbService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getLongToken(_token: string, _fb_id: string) {
    return this.http.get(this.config.apiUrl + '/fb/getLongToken?fb_exchange_token=' + _token + '&fb_id=' + _fb_id);
  }

  getPages() {
    return this.http.get<any>(this.config.apiUrl + '/fb/getPages');
  }

  getPageRatings(_id: string) {
    return this.http.get(this.config.apiUrl + '/fb/pageRatings/' + _id);
  }

  getRatingsCount(_id: string) {
    return this.http.get(this.config.apiUrl + '/fb/countRatings/' + _id);
  }

  getInstagramStats(_id: string) {
    return this.http.get(this.config.apiUrl + '/fb/instagramStats/' + _id);
  }

  getNode(node: string) {
    return this.http.post(this.config.apiUrl + '/fb/getNode/', { node: node });
  }

  importReviews(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/fb/getReviews/' + _id);
  }
}
