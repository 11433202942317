import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[validateCpf][formControlName],[validateCpf][formControl],[validateCpf][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => CpfValidator), multi: true }]
})
export class CpfValidator implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    let cpf: string;
    cpf = c.value;
    if (cpf) {
      cpf = cpf.replace(/[^0-9]/g, '');

      let soma = 0;
      let resto: number;
      const regex = new RegExp('[0-9]{11}');

      if (
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999' ||
        !regex.test(cpf)
      )
        return { cpfIncompleto: true };
      else {
        for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(9, 10))) return { cpfInvalido: true };

        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(10, 11))) return { cpfInvalido: true };
        return null;
      }
    } else {
      return { cpfIncompleto: true };
    }
  }
}
