import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NotifyService } from 'app/_services';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
@Component({
  selector: 'amp-multi-select',
  templateUrl: 'amp-multi-select.component.html',
  styleUrls: ['amp-multi-select.component.scss']
})
export class AmpMultiSelectComponent implements OnInit, OnDestroy {
  constructor(private notifyService: NotifyService) {}

  @ViewChild('dialogOptions') dialogOptionsRef!: ElementRef;

  public focusedOptionIndex = 0;

  @HostListener('document:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    const dialogOptions = this.dialogOptionsRef?.nativeElement as HTMLElement;

    // @ts-ignore
    if (event.key === 'Backspace' && !this.inputValue?.length && event.target.id == 'amp-multi-select') {
      if (this.onRemove.observed) {
        this.removeTag(this.value[this.value.length - 1], this.value.length - 1);
      } else {
        this.value.pop();
      }
    }

    if (event.code == 'ArrowUp') {
      if (this.focusedOptionIndex == 0) {
        this.focusedOptionIndex = this.options.length;
      }

      if (this.focusedOptionIndex > 0) {
        this.focusedOptionIndex--;
      }
    }

    if (event.code == 'ArrowDown') {
      if (this.focusedOptionIndex == dialogOptions.children.length - 1) {
        this.focusedOptionIndex = 0;
      } else if (this.focusedOptionIndex >= 0) {
        this.focusedOptionIndex++;
      }
    }
  }

  public showDropdown = false;
  public inputValue = '';

  // Abre o dropdown com as opções ao clicar no campo.
  @Input() showOptionsOnClick = false;

  // Quantidade máxima de itens que podem ser adicionados no campo, sendo -1 para ilimitado.
  @Input() maxItems = -1;

  // Placeholder que aparecerá quando nenhuma opção tiver sido adicionada anteriormente.
  @Input() primaryPlaceholder = '';

  // Placeholder que aparecerá quando uma opção já tiver sido adicionada anteriormente.
  @Input() secondaryPlaceholder = '';

  // Permitir a adição de tags ao apertar a tecla Enter.
  @Input() addOnEnter = true;

  @Input() onlyFromAutoComplete = false;

  // Permitir adicionar uma tag ao clicar fora do input.
  @Input() addOnBlur = false;

  // Permitir remover uma tag adicionada.
  @Input() allowRemove = true;

  // Caso o value passado como parâmetro não seja um array de objetos, é necessário informar qual a chave que será utilizada para identificar o objeto.
  @Input() identifyBy = 'value';

  // Caso o value passado como parâmetro não seja um array de objetos, é necessário informar qual a chave que será utilizada para exibir o objeto.
  @Input() displayBy = 'display';

  // Caso o value passado como parâmetro não seja um array de objetos, é necessário informar se é string.
  @Input() modelAsString = false;

  @Input() onlyLowerCase = false;

  public filterSubject: Subject<string> = new Subject();

  // public tags = [];

  @Input() show: Subject<string>;

  // Define se o componente terá bordas.
  @Input() borders = true;

  // Função a ser chamada quando um tag é adicionada.
  @Output() onAdd = new EventEmitter();

  // Função a ser chamada quando uma tag é removida.
  @Output() onRemove = new EventEmitter();

  /**
   * @name breakAll
   * @desc Quebra o texto das opções no espaço.
   **/
  @Input() breakAll = false;

  /**
   * @name hiddenSelected
   * @desc ?
   **/
  @Input() hiddenSelected = false;

  @Input() optionsObservable: (text: string) => Observable<any>;

  private _options = [];

  /**
   * @name options
   * @desc Lista de opções do select.
   **/
  public optionsLoading = false;

  @Input()
  set options(options) {
    this._options = options;
    this.applyFilter();
    if (this.value) {
      this.inputValue = this.options.find((option) => option.value === this.value)?.label;
    }
  }

  get options() {
    return this._options;
  }

  optionsFiltered = [];

  private _value;
  public hasValue = false;

  @Input()
  set value(value: any) {
    if (value) {
      this._value = value;
    } else {
      this._value = [];
    }
  }

  get value() {
    return this._value;
  }

  /**
   * @name valueChange
   * @desc the value callback to be called when a option is selected
   **/
  @Output() valueChange = new Subject();

  ngOnInit() {
    this.applyFilter();
    this.filterSubject
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.applyFilter();
      });
  }

  ngOnDestroy() {
    this.filterSubject.unsubscribe();
  }

  applyFilter() {
    if (this.optionsObservable) {
      this.optionsLoading = true;
      this.optionsObservable(this.inputValue).subscribe((options) => {
        if (this.modelAsString) {
          const tagsNames = this.value.map((tag) => tag);
          this.optionsFiltered = options.filter((option) => !tagsNames.includes(option));
          this._options = options;
          this.optionsLoading = false;
        } else {
          const tagsNames = this.value.map((tag) => tag[this.displayBy]);
          this.optionsFiltered = options.filter((option) => !tagsNames.includes(option[this.displayBy]));
          this._options = options;
          this.optionsLoading = false;
        }
      });
    } else {
      if (this.inputValue?.length > 0) {
        this.optionsFiltered = this.options.filter((option) => option.label.toLowerCase().includes(this.inputValue.toLowerCase()));
      } else {
        this.optionsFiltered = [...this.options];
        if (this.value) {
          this.inputValue = this.options.find((option) => option.value === this.value)?.label || '';
        }
      }
    }
  }

  onFocus() {
    if (this.showOptionsOnClick) {
      this.applyFilter();

      if (this.maxItems > 0 && this.value.length == this.maxItems) {
        return;
      }

      this.showDropdown = true;
    }
  }

  removeTag(tag, index: number) {
    if (this.onRemove.observed) {
      this.onRemove.next({ tag, index });
    } else {
      this.value.splice(index, 1);
    }
  }

  onKeyUp(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.inputValue?.length) {
      if (this.onlyLowerCase) {
        this.inputValue = this.inputValue.toLowerCase();
      }

      if (event.key === 'Enter') {
        if (this.maxItems > 0 && this.value.length == this.maxItems) {
          return;
        }

        if (this.addOnEnter) {
          if (this.modelAsString) {
            if (this.value.includes(this.inputValue)) {
              this.notifyService.warning('Você já adicionou esse item!');
              this.inputValue = '';
            } else {
              this.value.push(this.inputValue);
              this.onAdd.next(this.inputValue);
              this.valueChange.next(this.value);
              this.inputValue = '';
            }
          } else {
            const find = this.value.find((tag) => tag[this.identifyBy] === this.inputValue[this.identifyBy]);
            if (find) {
              this.notifyService.warning('Você já adicionou esse item!');
              this.inputValue = '';
            } else {
              this.value.push({ [this.identifyBy]: this.inputValue, [this.displayBy]: this.inputValue });
              this.onAdd.next(this.inputValue);
              this.valueChange.next(this.value);
              this.inputValue = '';
            }
          }
        } else {
          if (!this.optionsFiltered.length && this.onlyFromAutoComplete) {
            return;
          }

          this.selectOption(event, this.optionsFiltered[this.focusedOptionIndex]);
          this.focusedOptionIndex = 0;
        }
      } else {
        if (this.optionsObservable) {
          if (this.maxItems > 0 && this.value.length == this.maxItems) {
            return;
          }

          this.showDropdown = true;
          this.filterSubject.next(event.target.value);
        }
      }
    } else {
      if (event.key === 'Enter' && !this.addOnEnter && !this.modelAsString) {
        if (!this.optionsFiltered.length && this.onlyFromAutoComplete) {
          return;
        }

        this.selectOption(event, this.optionsFiltered[this.focusedOptionIndex]);
        this.focusedOptionIndex = 0;
      }
    }
  }

  onClickOutside() {
    if (this.inputValue?.length && this.addOnBlur) {
      this.value.push(this.inputValue);
      this.onAdd.next(this.inputValue);
      this.valueChange.next(this.value);
      this.inputValue = '';
    }

    this.showDropdown = false;

    if (this.value) {
      this.inputValue = this.options.find((option) => option.value === this.value)?.label;
    }
  }

  selectOption(event, option) {
    event.stopPropagation();

    if (this.maxItems > 0 && this.value.length == this.maxItems) {
      return;
    }

    if (this.value.includes(option)) {
      this.notifyService.warning('Você já adicionou esse item!');
      this.inputValue = '';
      return;
    }

    this.value.push(option);
    this.valueChange.next(this.value);
    this.inputValue = '';
    this.showDropdown = false;
  }
}
