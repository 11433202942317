<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Erro de autenticação com Login Federado {{authType}}
    </div>
    <div class="amp-dialog-subtitle" *ngIf="showChat">Não foi possível conectar com a plataforma da Amplifique.me, entre em contato com o nosso time de suporte abaixo, ou tente novamente mais tarde!</div>

    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <button class="amp-btn-primary amp-btn-lg amp-btn-send" *ngIf="showChat" (click)="openChat()"><span class='bi-chat'></span>Entrar em contato com o Suporte</button>
  </div>
</div>
