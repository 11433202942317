import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotifyService } from 'app/_services';
import { StoreService } from 'app/_services/store.service';
import { Token } from 'app/_models/token';
import { TokenService } from 'app/_services/token.service';
import { SurveyService } from 'app/_services/customerFeedback/survey.service';
import { Survey } from 'app/_models/customerFeedback/survey';
@Component({
  selector: 'app-token-dialog',
  templateUrl: './token-dialog.component.html',
  styleUrls: ['./token-dialog.component.scss']
})
export class TokenDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public store: StoreService,
    private surveyService: SurveyService,
    private tokenService: TokenService,
    private notifyService: NotifyService
  ) {}

  public surveys: Survey[] = [];
  public token;
  public isNew = true;
  public isSurvey = false;
  public zapier = false;
  public subscriptions = [];

  public expirationOptions = [
    {
      label: 'Desabilitada',
      value: 0
    },
    {
      label: '1 dia',
      value: 1
    },
    {
      label: '3 dias',
      value: 3
    },
    {
      label: '5 dias',
      value: 5
    },
    {
      label: '7 dias',
      value: 7
    },
    {
      label: '14 dias',
      value: 14
    },
    {
      label: '21 dias',
      value: 21
    },
    {
      label: '30 dias',
      value: 30
    },
    {
      label: '60 dias',
      value: 60
    },
    {
      label: '90 dias',
      value: 90
    }
  ];

  @Input() public cb: Function;

  ngOnInit() {
    this.isSurvey = true;
    this.getSurveys();

    if (this.zapier) {
      this.token.name = 'Zapier';
      this.token.partner = 'Zapier';
      this.token.force_rewrite = true;
      this.confirmar();
    }

    if (!this.token) {
      this.token = new Token();
    }

    const hora = this.token.action.specific_time.split(':')[0];
    const minuto = this.token.action.specific_time.split(':')[1];
    this.token.action.specific_time = new Date(null, null, null, hora, minuto);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getSurveys() {
    this.subscriptions.push(
      this.surveyService.getActive(this.store.business._id).subscribe((data) => {
        this.surveys = data;
      })
    );
  }

  confirmar() {
    if (this.token.action.delay_mode == 'specific_time' && this.token.action.specific_time != undefined) {
      this.token.action.specific_time = this.token.action.specific_time.getHours().toString() + ':' + this.token.action.specific_time.getMinutes().toString();
    }

    if (this.isNew) {
      this.token._business = this.store.business._id;
      this.token._empresa = this.store.empresa;
      this.token.action.type = 'nps';
      if (this.isSurvey) {
        this.token.action.type = 'cf';
      }
      this.subscriptions.push(
        this.tokenService.create(this.token).subscribe(
          (data) => {
            this.notifyService.success('Token criado com sucesso!');
            this.cb(data);
            this.bsModalRef.hide();
          },
          () => {
            this.notifyService.success('Não foi possível criar o token.');
          }
        )
      );
    } else {
      this.subscriptions.push(
        this.tokenService.update(this.token).subscribe(
          (data) => {
            this.notifyService.success('Token atualizado com sucesso!');
            this.cb(data);
            this.bsModalRef.hide();
          },
          () => {
            this.notifyService.success('Não foi possível atualizar o token.');
          }
        )
      );
    }
  }

  cancelar() {
    this.bsModalRef.hide();
  }

  checkChannelSurvey(channel) {
    const survey = this.surveys.find((data) => data._id == this.token.action.campaign);
    if (survey) {
      if (survey.channels.includes(channel)) {
        return true;
      }
    }
    return false;
  }
}
