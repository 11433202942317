<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Nova Campanha
    </div>
    <div *ngIf="type=='nps'" class="amp-dialog-subtitle">Para configurar uma nova Campanha de NPS, selecione um modelo ou comece do zero.</div>

    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">

    <div class="amp-helper-text" *ngIf="type=='referral'">1. Escolha um tipo de Programa de Indicação.</div>
    <div class="amp-templates" *ngIf="type=='referral'">
      <div class="amp-template" (click)="subtype='referral'" [ngClass]="{'active': subtype=='referral'}">
        <div class="amp-template-wrapper">
          <div class="amp-template-items-wrapper">
            <h5 class="amp-template-title">Modo Clássico</h5>
            <p class="amp-template-description">Programa de indicação normal, com opção de gerar ou não recompensas para quem indica ou é indicado</p>
          </div>
        </div>
      </div>
      <div class="amp-template" (click)="subtype='milestone'" [ngClass]="{'active': subtype=='milestone'}">
        <div class="amp-template-wrapper">
          <div class="amp-template-items-wrapper">
            <h5 class="amp-template-title">Modo Milestones</h5>
            <p class="amp-template-description">Programa de indicação com milestones, onde apenas quem indica acumula recompensas. A cada (x) indicações uma recompensa é gerada para quem indica</p>
          </div>
        </div>
      </div>
    </div>
    
    <div class="amp-helper-text" *ngIf="type=='referral' && subtype!=''"></div>

    <div class="amp-templates">
      <div class="amp-template" *ngFor="let template of templates | ampTemplatePipe:{type:type,stype:subtype}" (click)="onSelectTemplate(template)">  
        <div class="amp-template-wrapper">
          <span [class]="'amp-template-icon ' + template.icon"></span>
          <div class="amp-template-items-wrapper">
            <h5 class="amp-template-title">{{template.title}}</h5>
            <p class="amp-template-description">{{template.description}}</p>
          </div>
        </div>
      </div>
      <div class="amp-template amp-template-dashed" *ngIf="(type=='referral' && subtype!='') || type=='nps'" (click)="onEmpty()">
        <div class="amp-template-wrapper">
          <span class="amp-template-icon bi-plus-lg"></span>
          <div class="amp-template-items-wrapper">
            <h5 class="amp-template-title">Começar do Zero</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>