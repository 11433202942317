import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { ChangeLog } from 'app/_models/adm/changeLog';
@Injectable()
export class ChangeLogService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  create(data: ChangeLog) {
    return this.http.post<any>(this.config.apiUrl + '/adm/change-log/', data);
  }

  getAll(skip) {
    return this.http.get<any>(this.config.apiUrl + `/adm/change-log/?skip=${skip}`);
  }

  update(data: ChangeLog) {
    return this.http.put<ChangeLog>(this.config.apiUrl + '/adm/change-log/' + data._id, data);
  }

  updateReadStatus(data) {
    return this.http.put<ChangeLog>(this.config.apiUrl + '/adm/change-log/read', { changeLogs: data });
  }

  delete(data: ChangeLog) {
    return this.http.delete<any>(this.config.apiUrl + '/adm/change-log/' + data._id);
  }

  get(template: string) {
    return this.http.get<ChangeLog>(this.config.apiUrl + '/adm/change-log/' + template);
  }
}
