import { Injectable, EventEmitter } from '@angular/core';
import { User } from 'app/_models/user';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class MenuService {
  event: EventEmitter<boolean> = new EventEmitter();
  eventUser: EventEmitter<any> = new EventEmitter();
  eventBusiness: EventEmitter<any> = new EventEmitter();
  eventMenuLocker: EventEmitter<boolean> = new EventEmitter();
  eventPageTitle: EventEmitter<string> = new EventEmitter();
  eventSidebar: EventEmitter<boolean> = new EventEmitter();
  chat: EventEmitter<boolean> = new EventEmitter();

  eventContactSidebar: BehaviorSubject<any> = new BehaviorSubject<any>({ status: false, customerId: null, timestamp: 0 });

  private _userData: User;

  emit(valor) {
    this.event.emit(valor);
  }

  getChangeEmitter() {
    return this.event;
  }

  emitChat(valor) {
    this.chat.emit(valor);
  }

  getChatEmitter() {
    return this.chat;
  }

  emitSidebar(valor) {
    this.eventSidebar.emit(valor);
  }

  getSidebarChangeEmitter() {
    return this.eventSidebar;
  }

  emitContactSidebar(status: boolean, customerId, callback?) {
    this.eventContactSidebar.next({ status, customerId, timestamp: new Date().getTime(), callback });
  }

  getContactSidebarChangeEmitter() {
    return this.eventContactSidebar;
  }

  emitUser(valor) {
    this.eventUser.emit(valor);
    this._userData = valor;
  }

  getChangeEmitterUser() {
    return this.eventUser;
  }

  getUser(): User {
    return this._userData;
  }

  emitBusiness(valor) {
    this.eventBusiness.emit(valor);
  }

  getChangeEmmiterBusiness() {
    return this.eventBusiness;
  }

  emitMenuLocker(valor) {
    this.eventMenuLocker.emit(valor);
  }

  getChangeEmmiterMenuLocker() {
    return this.eventMenuLocker;
  }

  emitPageTitle(valor) {
    this.eventPageTitle.emit(valor);
  }

  getChangeEmitterPageTitle() {
    return this.eventPageTitle;
  }
}
