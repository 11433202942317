<ng-template #npsTemplate>
  Ocorrência da Tag: {{_promoter + _neutral + _detractor}}<br>
  Promotores: {{_promoter}} | {{promoterPercentage | percent}}<br>
  Neutros: {{_neutral}} | {{neutralPercentage | percent}}<br>
  Detratores: {{_detractor}} | {{detractorPercentage | percent}}
</ng-template>

<ng-template #topicTemplate>
  Ocorrência do Tópico: {{_promoter + _neutral + _detractor}}<br>
  Positivos: {{_promoter}} | {{promoterPercentage | percent}}<br>
  Neutros: {{_neutral}} | {{neutralPercentage | percent}}<br>
  Negativos: {{_detractor}} | {{detractorPercentage | percent}}
</ng-template>

<div class="amp-nps-bar-wrapper" placement="auto" container="body" boundariesElement="viewport" [tooltip]="metric != 'none' ? metric.includes('topics') ? topicTemplate : npsTemplate : ''">
  <span class="amp-title">{{tag}}</span>
  <div class="amp-wrapper">
    <div class="amp-nps-bar" [ngStyle]="{width: this.barPercentage + '%'}">
      <div
        class="amp-nps-bar-indicator detractor"
        (click)="redirectToAnswers('negative')"
        [ngStyle]="{
          width: (!allZero ? detractorPercentage * 100 : 100 / 3) + '%'
        }"
      ></div>
      <div
        class="amp-nps-bar-indicator neutral"
        (click)="redirectToAnswers('neutral')"
        [ngStyle]="{
          width: (!allZero ? neutralPercentage * 100 : 100 / 3) + '%'
        }"
      ></div>
      <div
        class="amp-nps-bar-indicator promoter"
        (click)="redirectToAnswers('positive')"
        [ngClass]="{'blue' : metric == 'none'}"
        [ngStyle]="{
          width: (!allZero ? promoterPercentage * 100 : 100 / 3) + '%'
        }"
      ></div>
    </div>
    <div class="amp-value" *ngIf="metric == 'nps'">{{this.score | number:'1.0-0'}}</div>
    <div class="amp-value" *ngIf="metric == 'csat' || metric == 'thumbs' || metric == 'topics-2'">{{this.score / 100 | percent}}</div>
    <div class="amp-value" *ngIf="metric == 'ces'">{{this.score | number:'1.0-1'}}</div>
    <div class="amp-value" *ngIf="metric == 'none'">{{this.score | number:'1.0-1'}}</div>
  </div>
</div>
