<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Cortar Imagem
    </div>
  </div>

  <div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <image-cropper
    [imageFile]="uploadedFile"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="aspectRatio"
    [cropperMinHeight]="minHeight"
    [cropperMinWidth]="minWidth"
    [cropperMaxWidth]="maxWidth"
    [cropperMaxHeight]="maxHeight"
    format="png"
    [autoCrop]="true"
    [resizeToWidth]="resizeWidth"
    (imageCropped)="imageCropped($event)"
    ></image-cropper>

    <div class="amp-buttons">
      <button *ngIf="showSkipButton" (click)="skipCrop()" class="amp-btn amp-btn-primary">Pular</button>
      <button (click)="saveCrop()" class="amp-btn amp-btn-success">Salvar</button>
    </div>
  </div>
</div>
