import { SurveyService } from './../../_services/customerFeedback/survey.service';
import { map, Subject, Observable } from 'rxjs';
import { CardFilter, Dashboard } from './../../_models/dashboard';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from 'app/_services/dashboard.service';
import { EmpresaService, NotifyService, StoreService, TagService } from 'app/_services';
import { CompanyService } from 'app/_services/company.service';
import { SurveyAnswerService } from 'app/_services/customerFeedback/surveyAnswer.service';
@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit, OnDestroy {
  constructor(
    public store: StoreService,
    public bsModalRef: BsModalRef,
    private companyService: CompanyService,
    private tagService: TagService,
    private empresaService: EmpresaService,
    private modalService: BsModalService,
    private dashboardService: DashboardService,
    private notifyService: NotifyService,
    private surveyservice: SurveyService,
    private surveyAnswerService: SurveyAnswerService
  ) {}
  public result: Subject<any> = new Subject();
  public card: any;
  public subscriptions = [];
  public dashboard: Dashboard;
  public customFields = [];
  public tags = [];
  public companies = [];
  public isLoading = false;
  public answers;
  public customerCompanyOptions = [];
  public origin;
  public selectedCustomField;
  public selectedAnswerInternalName;
  public surveyQuestionList: any;
  public isMultiple = false;
  public surveyId;
  public customFieldValues = [];
  public customFieldValuesFiltered = [];
  public companiesFiltered = [];
  public answersFiltered = [];
  public tagsFiltered = [];
  public origins = [];
  public originsFiltered = [];
  public surveyQuestionsOptions = [];
  public customFieldsOptions = [];
  public tagsFilteredOptions = [];

  public filterTypeOptions = [
    {
      value: 'customer_tag',
      label: 'Tag do Contato'
    },
    {
      value: 'customer_customField',
      label: 'Campo Customizado do Contato'
    },
    {
      value: 'answer_customField',
      label: 'Campo Customizado da Resposta'
    },
    {
      value: 'customer_company',
      label: 'Empresa do Contato'
    },
    {
      value: 'origin',
      label: 'Origem (InApp e Link/QRCode)'
    },
    {
      value: 'survey_question',
      label: 'Respostas da Pesquisa'
    }
  ];

  public conditionsOptions = [
    {
      value: 'equals',
      label: 'é'
    },
    {
      value: 'not_equals',
      label: 'não é'
    },
    {
      value: 'contains',
      label: 'contém'
    },
    {
      value: 'not_contains',
      label: 'não contém'
    }
  ];

  public conditionOptionsContainsOnly = [
    {
      value: 'contains',
      label: 'possui'
    },
    {
      value: 'not_contains',
      label: 'não possui'
    }
  ];

  public conditionOptionsEqualityOnly = [
    {
      value: 'equals',
      label: 'é'
    },
    {
      value: 'not_equals',
      label: 'não é'
    }
  ];

  filterConditions(filter) {
    this.conditionsOptionsFiltered = [];

    if (filter.type != 'customer_tag') {
      this.conditionsOptionsFiltered.push({ value: 'equals', label: 'é' });
      this.conditionsOptionsFiltered.push({ value: 'not_equals', label: 'não é' });

      if (filter.type != 'customer_company' && filter.type != 'survey_question' && !this.checkIsMultiple(filter.field)) {
        this.conditionsOptionsFiltered.push({ value: 'contains', label: 'contém' });
        this.conditionsOptionsFiltered.push({ value: 'not_contains', label: 'não contém' });
      }
    }

    if (filter.type == 'customer_tag') {
      this.conditionsOptionsFiltered.push({ value: 'contains', label: 'possui' });
      this.conditionsOptionsFiltered.push({ value: 'not_contains', label: 'não possui' });
    }
  }

  public conditionsOptionsFiltered = [];

  public scoreOptions = {
    nps: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ces: [1, 2, 3, 4, 5, 6, 7],
    csat: [1, 2, 3, 4, 5],
    likert: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    thumbs: ['Positivo', 'Negativo']
  };

  ngOnInit() {
    this.getCustomFields();

    this.card = JSON.parse(JSON.stringify(this.card));

    this.card.filters = this.card.filters.map((item) => {
      if (item.field && item.value) {
        if (item.type == 'survey_question') {
          this.selectedAnswerInternalName = item.field;
        } else {
          this.selectedCustomField = item.field;
        }
      }
      return item;
    });

    this.getSurveyQuestions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  originsAutoComplete = (search): Observable<any> => {
    const filterObject: any = {};
    filterObject['_empresa'] = this.store.empresa;
    filterObject.filterText = search;
    return this.surveyAnswerService.getOrigins(filterObject).pipe(map((data) => data.map((e) => ({ value: e.origin, label: e.origin ?? '' }))));
  };

  customFieldValuesAutoComplete = (search): Observable<any> => {
    return this.empresaService
      .getCustomFieldValues(this.store.empresa, this.selectedCustomField, undefined, search)
      .pipe(map((data) => data.map((e) => ({ value: e.custom_fields, label: e.custom_fields ?? '' }))));
  };

  tagsAutoComplete = (search): Observable<any> => {
    return this.tagService.getTags(this.store.empresa, search).pipe(map((data) => data.map((e) => ({ value: e._id, label: e.name ?? '' }))));
  };

  companiesAutoComplete = (search): Observable<any> => {
    return this.companyService.getCompanies(this.store.empresa, search, 0, 100).pipe(map((data) => data.map((e) => ({ value: e._id, label: e.name ?? '' }))));
  };

  answersAutoComplete = (search): Observable<any> => {
    let surveys;

    if (this.origin == 'panel') {
      surveys = [this.surveyId];
    } else {
      surveys = this.card._surveys;
    }

    return this.surveyAnswerService.getAnswersOptionsOfQuestion(surveys, this.selectedAnswerInternalName, search).pipe(
      map((data) => {
        // @ts-ignore
        data = data.map((e) => ({
          value: e.answer,
          label: e.answer ?? ''
        }));

        return data;
      })
    );
  };

  getSurveyQuestions() {
    let surveys;

    if (this.origin == 'panel') {
      surveys = [this.surveyId];
    } else {
      surveys = this.card._surveys;
    }

    this.subscriptions.push(
      this.surveyservice.getQuestionsListBySurveys(surveys).subscribe((data) => {
        // @ts-ignore
        this.surveyQuestionsOptions = data.map((e) => ({
          value: e._id,
          label: e.questionText ?? '',
          hide: e.questionType == 'matrix'
        }));
      })
    );
  }

  getCustomFields() {
    this.subscriptions.push(
      this.empresaService.getCustomFields(this.store.empresa).subscribe((data) => {
        this.customFieldsOptions = data.map((e) => ({
          value: e.internal_name,
          label: `${e.label} (${e.internal_name})` ?? ''
        }));
      })
    );
  }

  getCompanyName(id) {
    return this.companies.find((x) => x._id == id)?.name;
  }

  getTagName(id) {
    return this.tags.find((x) => x._id == id)?.name;
  }

  checkAnswerIsString(item) {
    if (typeof item == 'string') {
      return true;
    } else {
      return false;
    }
  }

  removeFilter(idx) {
    this.card.filters.splice(idx, 1);
  }

  focusSearchInput() {
    setTimeout(() => {
      const searchInput = document.querySelector('#searchDashboard') as HTMLInputElement;
      searchInput?.focus();
    }, 100);
  }

  saveFilters() {
    if (this.origin == 'panel') {
      const cardClone = JSON.parse(JSON.stringify(this.card));
      cardClone.filters = cardClone.filters.map((filter) => {
        if (typeof filter.value != 'string' && typeof filter.value != 'number') {
          filter.value = filter.value[0];
        }
        return filter;
      });

      this.result.next(cardClone);
      this.bsModalRef.hide();
    } else {
      const index = this.dashboard.cards.findIndex((card) => card._id == this.card._id);
      const cardClone = JSON.parse(JSON.stringify(this.card));
      cardClone.filters = cardClone.filters.map((filter) => {
        if (typeof filter.value != 'string' && typeof filter.value != 'number') {
          filter.value = filter.value[0];
        }
        return filter;
      });

      if (index > -1) {
        this.dashboard.cards[index] = cardClone;
      } else {
        return;
      }
      //@ts-ignore
      this.dashboard.cards = this.dashboard.cards.map((item) => {
        return {
          _id: item._id,
          name: item.name,
          type: item.type,
          metric: item.metric,
          tagSet: item.tagSet,
          filters: item.filters,
          filter_any: item.filter_any,
          internal_name: item.internal_name,
          tags: item.tags.map((tag) => {
            if (typeof tag === 'string') {
              return tag;
            } else {
              return tag._id;
            }
          }),
          _surveys: item._surveys.map((platform) => {
            if (typeof platform === 'string') {
              return platform;
            } else {
              return platform._id;
            }
          })
        };
      });

      this.subscriptions.push(
        this.dashboardService.update(this.dashboard).subscribe(
          (dashboard) => {
            this.notifyService.success('Filtros salvos com sucesso!');
            this.result.next(dashboard);
            this.modalService.setDismissReason('cf_update_dash');
            this.bsModalRef.hide();
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Não foi possível salvar os filtros.');
          }
        )
      );
    }
  }

  checkIsMultiple(internal_name) {
    if (internal_name.length) {
      const customField = this.customFields.find((customField) => customField.internal_name == internal_name);
      if (customField) {
        return customField.type == 'multiple';
      }
    }
    return false;
  }

  clearInputs(idx) {
    this.card.filters[idx].condition = '';
    this.card.filters[idx].field = '';
    this.card.filters[idx].value = '';
    this.applyFilter();
  }

  addNewFilter() {
    this.card.filters.push(new CardFilter());
  }

  deleteAllFilters() {
    const initialState = {
      title: 'Excluir filtros',
      message: 'Você tem certeza que deseja excluir todos os filtros?',
      buttonName: 'Excluir'
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.card.filters = [];
          this.saveFilters();
        }
      })
    );
  }

  hide() {
    this.modalService.setDismissReason('cancel');
    this.bsModalRef.hide();
  }

  applyFilter() {
    this.customFieldValuesFiltered = this.customFieldValues;
    this.answersFiltered = this.answers;
    this.tagsFiltered = this.tags;
    this.companiesFiltered = this.companies;
    this.originsFiltered = this.origins.map((e) => ({
      value: e.origin,
      label: e.origin ?? ''
    }));

    this.isLoading = false;
  }
}
