<section>
  <div class="description">Aqui você consegue verificar o status das últimas importações.</div>
  <div class="amp-action-bar">
    <div class="amp-actions">
      <button class="amp-btn-primary amp-btn-lg" (click)="openAnswerImporter()">
        Importar Respostas
      </button>
      <button class="amp-btn-primary amp-btn-lg" (click)="openContactImporter()">
        Importar Contatos
      </button>
      <button class="amp-btn-primary amp-btn-lg"
        *ngIf="auth.userRole == 'acmng'" (click)="adminMode = !adminMode; this.getImports()">
        {{ adminMode ? 'Desativar Modo Admin' : 'Ativar Modo Admin' }}
      </button>
      <div class="amp-search-field" *ngIf="adminMode">
        <input class="amp-input-lg" placeholder="Pesquisar..." type="search" #searchInput [(ngModel)]="searchField"
          (keyup)="onTypeSearch($event.target)" [(ngModel)]="searchField">
        <i *ngIf="!searchField.length" class="bi-search"></i>
        <i *ngIf="searchField.length" (click)="searchField = ''" class="bi-x-lg"></i>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="amp-card">
        <div class="amp-table">
          <div class="amp-table-head">
            <div class="amp-table-column amp-webhook-description-header amp-unit" *ngIf="adminMode">Unidade</div>
            <div class="amp-table-column amp-webhook-description-header amp-user" *ngIf="adminMode">Usuário</div>
            <div class="amp-table-column amp-webhook-description-header amp-type">Tipo</div>
            <div class="amp-table-column">Data de Importação</div>
            <div class="amp-table-column">Status</div>
            <div class="amp-table-column">Dados</div>
            <div class="amp-table-column">Progresso</div>
          </div>
          
          <div class="amp-table-row amp-webhook-row" *ngFor="let item of imports" (click)="filterBy(item)" [isDisabled]="item.progress != 1" tooltip="Clique para visualizar os resultados.">
            <div class="amp-table-cell amp-webhook-description" *ngIf="adminMode">
              <b>{{ item._business?._empresa.nome }} - </b>{{ item._business?.nome }}
            </div>
            <div class="amp-table-cell amp-webhook-description" *ngIf="adminMode">{{ item._user?.nome }}</div>
            <div class="amp-table-cell amp-webhook-description">{{ 'import-type_' + item.type | translate }}</div>

            <div class="amp-table-cell">{{ item.created_at | date: 'dd/MM/yy HH:mm:ss' }}</div>
            <div class="amp-table-cell">
              <span [classList]="'amp-import-status ' + item.status">
                {{ 'export-status_' + item.status | translate }}
              </span>
            </div>
            <div class="amp-table-cell">
              {{ item.totalData }}
            </div>
            <div class="amp-table-cell">
              <amp-progress-bar [value]="item.progress * 100" [onlyBar]="true" color="green"></amp-progress-bar>
            </div>
          </div>

        </div>
        <div class="amp-not-found-results" *ngIf="searchField != '' && !imports.length">
          <span>NENHUM RESULTADO ENCONTRADO</span>
        </div>
      </div>
    </div>
  </div>

  <pagination [(ngModel)]="paginationPage" [totalItems]="totalItems" [itemsPerPage]="20"
    [rotate]="true" [maxSize]="9" [boundaryLinks]="true" [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate" (pageChanged)="getImports($event.page)" [firstText]="'Primeira'"
    [lastText]="'Última'">
  </pagination>
</section>

<ng-template #nextTemplate>
  <span class="bi-chevron-right"></span>
</ng-template>
<ng-template #prevTemplate>
  <span class="bi-chevron-left"></span>
</ng-template>