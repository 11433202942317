import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { WebhookEndpoint } from 'app/_models/webhook-endpoint';

@Injectable()
export class ClientWebhooksService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  get(webhookId) {
    return this.http.get<any>(this.config.apiUrl + `/client-webhook/${webhookId}`);
  }

  list(business) {
    return this.http.get<WebhookEndpoint[]>(this.config.apiUrl + `/client-webhook/${business}/list`);
  }

  create(clientWebhook) {
    return this.http.post<any>(this.config.apiUrl + '/client-webhook/', clientWebhook);
  }

  update(clientWebhook) {
    return this.http.put<any>(this.config.apiUrl + '/client-webhook/' + clientWebhook._id, clientWebhook);
  }

  revealSecret(webhookId) {
    return this.http.get<any>(this.config.apiUrl + '/client-webhook/' + webhookId + '/reveal-secret');
  }

  delete(webhookId) {
    return this.http.delete<any>(this.config.apiUrl + '/client-webhook/' + webhookId);
  }
}
