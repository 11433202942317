import { Notification } from './../_models/notification';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class NotificationService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getNotifications() {
    return this.http.get<Notification[]>(this.config.apiUrl + '/notification/');
  }

  setAllAsRead() {
    return this.http.put(this.config.apiUrl + '/notification/', {});
  }

  setAsRead(_id: string) {
    return this.http.put(this.config.apiUrl + '/notification/' + _id, {});
  }
}
