import { Business } from './business';
import { Empresa } from './empresa';
import { User } from './user';
export class Tag {
  _id: string;
  name: string;
  created_at: Date;
  _user: User;
  _business: Business;
  _empresa: Empresa;
  type: string;
}
