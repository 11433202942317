import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export enum AmpLoaderEventType {
  VISIBLE
}
export class AmpLoaderEvent {
  constructor(public type: AmpLoaderEventType, public value: any) {}
}
@Injectable()
export class AmpLoaderService {
  private _visible = false;

  private timeRunning = 0;
  private minTimeRunning = 3000;

  private interval;

  private eventSource: Subject<AmpLoaderEvent> = new Subject<AmpLoaderEvent>();
  public events: Observable<AmpLoaderEvent> = this.eventSource.asObservable();

  set visible(value: boolean) {
    this._visible = value;
    this.emitEvent(new AmpLoaderEvent(AmpLoaderEventType.VISIBLE, this._visible));
  }

  get visible(): boolean {
    return this._visible;
  }

  private emitEvent(event: AmpLoaderEvent) {
    if (this.eventSource) {
      this.eventSource.next(event);
    }
  }

  show() {
    this.visible = true;

    this.interval = setInterval(() => {
      this.timeRunning += 100;
    }, 100);
  }

  hide() {
    if (this.timeRunning < this.minTimeRunning) {
      setTimeout(() => {
        this.hide();
      }, this.minTimeRunning - this.timeRunning);
    } else {
      clearInterval(this.interval);
      this.visible = false;
    }
  }
}
