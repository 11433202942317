<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-person-x"></span>
      Desbloquear Participante
    </div>
    <div class="amp-dialog-subtitle">
      Ao desbloquear um participante, ele volta a poder se autenticar no Programa de Indicação e suas indicações voltarão a ser contabilizadas.
    </div>
  </div>

  <div class="amp-buttons">
    <button (click)="unblockParticipant()" class="amp-btn-lg amp-btn-success">Desbloquear Participante</button>
    <button (click)="this.bsModalRef.hide()" class="amp-btn-lg amp-btn-outline-secondary">Cancelar</button>
  </div>
</div>
