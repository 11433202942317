import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormatter'
})
export class NumberFormatterPipe implements PipeTransform {
  private suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];

  transform(input: any, decimals: number = 1): any {
    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 10000) {
      return input.toLocaleString('pt-BR');
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000));
    const value = input / Math.pow(1000, exp);

    const fixedValue = (Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals);

    return fixedValue + this.suffixes[exp - 1];
  }
}
