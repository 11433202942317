import { Component, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { NotifyService, StoreService, TagService } from 'app/_services';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { Tag } from 'app/_models/tag';
@Component({
  selector: 'app-tag-editor-dialog',
  templateUrl: './tag-editor-dialog.component.html',
  styleUrls: ['./tag-editor-dialog.component.scss']
})
export class TagEditorDialogComponent implements OnDestroy {
  public subscriptions = [];
  public isNew = true;
  public allowSaveButton = true;
  public loading = false;
  public tag: Tag = new Tag();
  public result: Subject<Tag> = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    public tagService: TagService,
    private store: StoreService,
    private modalService: BsModalService,
    private notifyService: NotifyService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
    this.result?.complete();
  }

  save() {
    this.loading = true;
    if (this.isNew) {
      this.subscriptions.push(
        this.tagService.create(this.store.empresa, this.tag).subscribe(
          (data) => {
            this.notifyService.success('Tag criada com sucesso!');
            this.result.next(data);
            this.bsModalRef.hide();
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao criar a tag');
            this.loading = false;
          }
        )
      );
    } else {
      this.subscriptions.push(
        this.tagService.update(this.tag).subscribe(
          () => {
            this.notifyService.success('Tag atualizada com sucesso!');
            this.bsModalRef.hide();
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao atualizar a tag.');
            this.loading = false;
          }
        )
      );
    }
  }

  delete() {
    if (!this.isNew) {
      const initialState = {
        title: 'Excluir Tag',
        message: 'Você tem certeza que deseja excluir a tag ',
        strongText: this.tag.name,
        messageEnd: '?',
        forceConfirmation: true
      };

      const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
        initialState,
        class: 'amp-modal'
      });

      this.subscriptions.push(
        bsModalRef.content.resultado.subscribe((result) => {
          if (result) {
            this.subscriptions.push(
              this.tagService.delete(this.tag._id).subscribe(
                () => {
                  this.notifyService.success('Tag excluída com sucesso!');
                  this.bsModalRef.hide();
                  this.result.next(this.tag);
                },
                (error) => {
                  console.error(error);
                  this.notifyService.error('Houve um erro ao excluir a tag.');
                }
              )
            );
          }
        })
      );
    }
  }
}
