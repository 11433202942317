<div class="amp-cf-filter">
  <div class="amp-filters-wrapper">
    <button class="amp-btn amp-btn-outline-secondary" (click)="editFilter(filter, i, dropFilter, $event)"
      placement="auto" [adaptivePosition]="true" containerClass="amp-filter-popover" [popoverContext]="{ data: filter }"
      [triggers]="filter.values.length > 1 ? 'mouseenter:mouseleave' : ''" [popover]="filterPopover"
      *ngFor="let filter of filters;trackBy:trackByFilter; let i = index">
      <i [class]="filter.filter.icon"></i>
      <span>{{ filter | ampCfFilterPipe }}</span>
      <i class="bi-x-lg" (click)="removeFilter(i)"></i>
    </button>

    <div dropdown [insideClick]="true" id="dropdown" class="amp-dropdown dropdown" (onHidden)="resetNewFilter()"
      #dropFilter="bs-dropdown" placement="left">
      <a dropdownToggle (click)="editFilterIndex = -1; (false)" id="dropdownFilter" aria-controls="filterdropdown">
        <i class="bi-funnel"></i><span>Adicionar Filtro</span>
      </a>

      <div class="dropdown-menu dropdown-menu-left sortOptions" id="filterdropdown" *dropdownMenu role="menu"
        aria-labelledby="dropdownFilter">
        <div class="amp-component">
          <div class="amp-search-input" *ngIf="step == 0">
            <input [(ngModel)]="filterSearch" type="text" class="amp-input" placeholder="Buscar">
            <i class="bi-search"></i>
          </div>

          <div class="amp-options" *ngIf="step == 0">
            <div class="amp-option"
              *ngFor="let item of filterOptions | filterPipe:{field: 'label', value: filterSearch, field2: 'name', value2: filterSearch, type: 'or' }"
              [ngClass]="{disabled: checkDisabled(item)}" (click)="selectOption(item, $event)">
              <i [classList]="'amp-option-icon ' + item.icon"></i>
              <span class="amp-topic">{{'cf-filter-' + item.type | translate}}</span>
              <i class="amp-chevron bi-chevron-right"></i>
              <span class="amp-subtopic">{{ item.label }}</span>
            </div>

            <!-- Not Found -->
            <div class="amp-not-found"
              *ngIf="!(filterOptions | filterPipe:{field: 'label', value: filterSearch, field2: 'name', value2: filterSearch, type: 'or' }).length">
              <span>NENHUM RESULTADO ENCONTRADO</span>
            </div>
          </div>

          <div class="amp-header" *ngIf="step == 1">
            <i (click)="backStep($event)" *ngIf="!isEditingFilter" class="bi-chevron-left"></i>
            <span><i [classList]="newFilter.filter.icon"></i>{{ newFilter.filter.label }}</span>
          </div>

          <div class="amp-body" *ngIf="step == 1">
            <div class="amp-select-lg" *ngIf="newFilter.filter.name != 'score' && newFilter.filter.name != 'text'">
              <select class="amp-input" [(ngModel)]="newFilter.condition" (change)="onChangeCondition()">
                <option value="contains"
                  [disabled]="newFilter.filter.name == 'ticketing-tag' || newFilter.filter.name == 'priority' || newFilter.filter.name == 'deliveryStatus' || newFilter.filter.name == 'aiPerson' || newFilter.filter.name == 'aiType' || newFilter.filter.name == 'aiSeverity' || newFilter.filter.name == 'aiSentiment' || newFilter.filter.name == 'channel' || newFilter.filter.name == 'contact_optin' || newFilter.filter.name == 'resolved'">
                  contém</option>
                <option value="not_contains"
                  [disabled]="newFilter.filter.name == 'ticketing-tag' || newFilter.filter.name == 'priority' || newFilter.filter.name == 'deliveryStatus' || newFilter.filter.name == 'text' || newFilter.filter.name == 'aiPerson' || newFilter.filter.name == 'aiType' || newFilter.filter.name == 'aiSeverity' || newFilter.filter.name == 'aiSentiment' || newFilter.filter.name == 'channel' || newFilter.filter.name == 'contact_optin' || newFilter.filter.name == 'resolved'">
                  não contém</option>
                <option value="equals" [disabled]="newFilter.filter.name == 'text'">igual</option>
                <option value="not_equals"
                  [disabled]="newFilter.filter.name == 'text' || newFilter.filter.name == 'contact_optin' || newFilter.filter.name == 'resolved'">
                  diferente
                </option>
              </select>
              <i class="bi-chevron-down"></i>
            </div>

            <div class="amp-select-lg" *ngIf="newFilter.filter.name == 'score'">
              <amp-select [options]="metricsOptions" [(value)]="selectedMetric"
                (valueChange)="this.suggestions = this.scoreOptions[this.selectedMetric]"></amp-select>
            </div>

            <div class="amp-search" *ngIf="newFilter.filter.name != 'score'">
              <input class="amp-input" type="text" (keyup)="emitKeyUpSearchFilter($event)"
                [(ngModel)]="innerSearchFilter" placeholder="Buscar">
              <i class="bi-search"></i>
            </div>

            <div class="amp-options-2" [ngStyle]="{ height: newFilter.filter.name != 'score' ? '220px' : '270px' }"
              *ngIf="!loadingSuggestions && (newFilter.condition == 'equals' || newFilter.condition == 'not_equals')">
              <label class="amp-checkbox"
                *ngIf="newFilter.filter.name != 'contact_optin' && newFilter.filter.name != 'resolved' && suggestions.length">
                <div class="amp-checkbox-option" *ngIf="!innerSearchFilter.length">
                  <input type="checkbox" (change)="selectAll(true)" [(ngModel)]="isSelectAll">
                  <span class="amp-option-text">Selecionar Tudo</span>
                  <span class="checkmark"></span>
                </div>

                <div class="amp-checkbox-option" *ngIf="innerSearchFilter.length > 0">
                  <input type="checkbox" (change)="selectAll(false)">
                  <span class="amp-option-text">Selecionar Resultados</span>
                  <span class="checkmark-neutral"></span>
                </div>
              </label>

              <label class="amp-checkbox" *ngFor="let item of suggestions">
                <div class="amp-checkbox-option">
                  <input type="checkbox" [checked]="newFilter.values.includes(item)" (change)="onSelectValue(item)">
                  <span [adaptivePosition]="true" containerClass="amp-filter-popover" [popoverContext]="{ data: item }"
                    container="body" boundariesElement="viewport"
                    [triggers]="item.length > 30 ? 'mouseenter:mouseleave' : ''" [popover]="optionPopover"
                    class="amp-option-text">{{ item }}</span>
                  <span class="checkmark"></span>
                </div>
              </label>

              <!-- Not Found -->
              <div class="amp-not-found" *ngIf="!loadingSuggestions && !suggestions.length">
                <span>NENHUM RESULTADO ENCONTRADO</span>
              </div>
            </div>

            <div class="amp-options-2"
              *ngIf="!loadingSuggestions && (newFilter.condition == 'contains' || newFilter.condition == 'not_contains')">
              <div class="amp-option" [ngClass]="{active: newFilter.values.includes(item)}"
                (click)="onSelectValue(item)" *ngFor="let item of suggestions">
                <span *ngIf="item?.length">{{ item }}</span>
                <span *ngIf="!item?.length"><i
                    tooltip="Indica respostas que não possuem o campo de detalhamento em questão">&lt;vazio&gt;</i></span>
              </div>

              <!-- Not Found -->
              <div class="amp-not-found"
                *ngIf="!loadingSuggestions && !suggestions.length && newFilter.filter.name != 'text'">
                <span>NENHUM RESULTADO ENCONTRADO</span>
              </div>
            </div>

            <!-- Loading -->
            <div class="amp-loading" *ngIf="loadingSuggestions">
              <div class="loader"></div>
            </div>

            <div
              *ngIf="innerSearchFilter.length && innerSearchFilter != '' && !(newFilter.filter.name == 'text' || newFilter.filter.name == 'aiPerson' || newFilter.filter.name == 'aiType' || newFilter.filter.name == 'aiSeverity' || newFilter.filter.name == 'aiSentiment' || newFilter.filter.name == 'channel' || newFilter.filter.name == 'contact_optin' || newFilter.filter.name == 'resolved')"
              class="amp-specific">
              <label class="amp-checkbox">
                <div class="amp-checkbox-option">
                  <input type="checkbox" [(ngModel)]="newFilter.specify">
                  <span class="amp-option-text"><i>Especificar: {{ innerSearchFilter }}</i></span>
                  <span class="checkmark"></span>
                </div>
              </label>
            </div>

            <div *ngIf="newFilter.filter.name == 'score'" class="amp-specific">
              <label class="amp-checkbox">
                <div class="amp-checkbox-option">
                  <input type="checkbox" [(ngModel)]="isMainMetric">
                  <span class="amp-option-text"><i>Apenas Métrica Principal</i></span>
                  <span class="checkmark"></span>
                </div>
              </label>
            </div>
          </div>
          <button ngClass="amp-apply" *ngIf="step == 1" class="amp-btn btn-primary"
            (click)="applyFilter(); dropFilter.hide()">Aplicar Filtro</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template let-data="data" #filterPopover>
  <div class="amp-filter">
    <span>{{ data.filter.label }}&nbsp;</span>
    <span><b>{{ getCondition(data.condition) }}</b></span>
  </div>
  <div class="amp-values">
    <span class="amp-badge" *ngFor="let filter of data.values">{{ filter }}</span>
  </div>
</ng-template>

<ng-template let-data="data" #optionPopover>
  {{ data }}
</ng-template>