<div class="amp-dialog">
  <div class="amp-dialog-header">
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <div *ngIf="!enable">
      <div class="amp-partner-image-wrapper" [ngStyle]="{ background: partner.color }">
        <img class="amp-partner-image" [src]="partner.logo" />
      </div>
      <div class="amp-partner-name">
        {{ partner.name }}
      </div>

      <showdown class="amp-markdown-email" [value]="partner.integration_description"></showdown>

      <div *ngIf="partner.internal_name == 'google-sheets'" class="google">
        <button (click)="openGoogleSheetsHelper()" class="amp-btn-warning">
          Como Funciona?
        </button>
        <div class="amp-btn-lg amp-btn-danger login-button-google" (click)="authenticateGoogle()">Fazer Login no Google</div>
      </div>

      <div *ngIf="partner.internal_name == 'asksuite' && !loading && askSuiteToken">
        <div class="amp-field">
          <label class="amp-label-text">Token do Ask Suite</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="askSuiteToken" placeholder="" />
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'Hotmart'">
        <div class="amp-fields">
          <p>Indicação</p>
          <span>Escolha um Programa de Indicação abaixo para visualizar a URL de Integração (webhook):</span>

          <div class="amp-field amp-select-new margin">
            <label class="amp-label-text" for="campaignType">Campanha de Indicação </label>
            <select class="amp-input-lg" name="tokenCampaign" [(ngModel)]="campaign">
              <option value="" disabled>Selecionar Campanha</option>
              <ng-container *ngFor="let cp of referralCampaigns">
                <option [value]="cp._id">{{ cp.title }}</option>
              </ng-container>
            </select>
            <span class="bi-chevron-down"></span>
          </div>

          <div class="amp-field" *ngIf="campaign">
            <label class="amp-label-text">URL de Integração</label>
            <input type="text" class="amp-input-lg" readonly="readonly" [value]="'https://api.amplifique.me/integrations/hotmart/referral?campaign=' + campaign" placeholder="" />
          </div>
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'RDStation_marketing'">
        <p>
          Caso você deseje enviar uma pesquisa utilizando os Fluxos do RDStation, escolha uma pesquisa abaixo. (Caso você não encontre sua pesquisa, ela precisa
          conter o canal <b>e-mail</b>).
        </p>
        <div class="amp-fields">
          <p>Enviar Pesquisa</p>
          <span>Escolha uma Pesquisa abaixo para visualizar a URL de Integração:</span>

          <div class="amp-field amp-select-new margin">
            <label class="amp-label-text" for="campaignType">Pesquisa </label>
            <select class="amp-input-lg" name="tokenCampaign" [(ngModel)]="survey">
              <option value="" disabled>Selecionar pesquisa</option>
              <ng-container *ngFor="let cp of cfSurveys">
                <option *ngIf="cp.channels.includes('email') || cp.channels.includes('sms')" [value]="cp._id">{{ cp.title }}</option>
              </ng-container>
            </select>
            <span class="bi-chevron-down"></span>
          </div>

          <div class="amp-field" *ngIf="survey">
            <label class="amp-label-text">URL de Integração</label>
            <input type="text" class="amp-input-lg" readonly="readonly" [value]="'https://api.amplifique.me/integrations/rdstation/customer-feedback?survey=' + survey" placeholder="nome-da-loja.myshopify.com" />
          </div>
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'funil'">
        <div class="amp-field">
          <label>Chave de API</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_funilvendas_key" placeholder="aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" />
        </div>
        <div class="amp-field">
          <label>Código Etapa</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="funilVendasParams.stage_code" placeholder="00000" />
        </div>
        <div class="amp-field">
          <label>Código do Vendedor</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="funilVendasParams.salesman_code" placeholder="00000" />
        </div>
        <div class="amp-field">
          <label>Código Funil de Venda</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="funilVendasParams.pipeline_code" placeholder="00000" />
        </div>
        <div class="amp-field">
          <label>Código Canal de Venda</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="funilVendasParams.sales_channel_code" placeholder="00000" />
        </div>

        <div class="amp-field" *ngIf="
            funilVendasParams.stage_code.length > 0 &&
            funilVendasParams.salesman_code.length > 0 &&
            funilVendasParams.pipeline_code.length > 0 &&
            funilVendasParams.sales_channel_code.length > 0
          ">
          <label class="amp-label-text">URL de Integração para o Webhook da Plataforma (adicionar o API Token da Amplifique.me)</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="
              'https://integration.services.amplifique.me/funilvendas/referral/webhook?stage_code=' +
              funilVendasParams.stage_code +
              '&salesman_code=' +
              funilVendasParams.salesman_code +
              '&pipeline_code=' +
              funilVendasParams.pipeline_code +
              '&sales_channel_code=' +
              funilVendasParams.sales_channel_code +
              '&access_token=Bearer%20'
            " />
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'eduzz'">
        <div class="amp-fields">
          <p>Criação de Indicação</p>
          <span>Escolha um Programa de Indicação abaixo para criar a URL de Integração:</span>

          <div class="amp-field amp-select-new margin">
            <label class="amp-label-text" for="campaignType">Campanha de Indicação </label>
            <select class="amp-input-lg" name="tokenCampaign" [(ngModel)]="campaign">
              <option value="" disabled>Selecionar Campanha</option>
              <ng-container *ngFor="let cp of referralCampaigns">
                <option [value]="cp._id">{{ cp.title }}</option>
              </ng-container>
            </select>
            <span class="bi-chevron-down"></span>
          </div>

          <div class="amp-field" *ngIf="campaign">
            <label class="amp-label-text">URL de Integração</label>
            <input type="text" class="amp-input-lg" readonly="readonly" [value]="'https://api.amplifique.me/integrations/eduzz/create-referral?campaign=' + campaign" placeholder="nome-da-loja.myshopify.com" />
          </div>
        </div>
      </div>

      <div class="amp-tabs" *ngIf="partner.internal_name == 'active_campaign'">
        <div class="amp-tab" [ngClass]="{ active: active_campaign_mode == 'cf' }" (click)="active_campaign_mode = 'cf'">Pesquisas e Respostas</div>
        <div class="amp-tab" [ngClass]="{ active: active_campaign_mode == 'referral' }" (click)="active_campaign_mode = 'referral'">Programa de Indicação</div>
      </div>

      <button *ngIf="partner.internal_name == 'active_campaign' && active_campaign_mode == 'cf'" (click)="goToTutorial()" class="amp-btn w-100 amp-search-list">Ir para Tutorial</button>

      <div *ngIf="partner.internal_name == 'active_campaign' && active_campaign_mode == 'referral'">
        <div class="amp-field">
          <label class="amp-label-text">Nome da Conta do ActiveCampaign (Exemplo: amplifiqueme)</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_active_campaign_account_name" required #acname="ngModel" name="acName" placeholder="Nome do ActiveCampaign" />
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Token de Integração do ActiveCampaign</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_active_campaign_token" required #ackey="ngModel" name="acKey" placeholder="Token do ActiveCampaign" />
        </div>

        <div class="amp-field">
          <label class="amp-label-text">ID de Evento do ActiveCampaign (Exemplo: 123456)</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_active_campaign_event_id" required #acEventId="ngModel" name="acEventId" placeholder="Id Do evento ActiveCampaign" />
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Chave do evento (Exemplo: bdhbs172bs739191278b391s28)</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_active_campaign_event_key" required #acEventKey="ngModel" name="acEventKey" placeholder="Chave do Evento ActiveCampaign" />
        </div>

        <div>
          <button (click)="getAcLists()" *ngIf="ac_lists.length == 0" class="amp-btn w-100 amp-search-list">Buscar Listas</button>

          <div class="amp-field amp-select-new">
            <label class="amp-label-text" for="business">Lista com os Indicados</label>
            <select class="amp-input-lg" [(ngModel)]="store.business.partner_active_campaign_list" autocomplete="off" name="business" required>
              <option [value]="null" disabled>Selecione a Lista</option>
              <option *ngFor="let item of ac_lists" [value]="item.id">{{ item.name }}</option>
            </select>
            <span class="bi-chevron-down"></span>
          </div>
        </div>

        <button class="amp-btn-lg amp-btn-success w-100" [disabled]="
        partner.internal_name == 'active_campaign' &&
        (store.business.partner_active_campaign_token?.length == 0 || store.business.partner_active_campaign_account_name?.length == 0)
      " (click)="activeIntegration()">
          Habilitar/Conectar
        </button>
      </div>

      <div *ngIf="partner.internal_name == 'picpay' && !store.business.rf_attributes.picpay_reward">
        <p>Contate o suporte para ativar a integração com o PicPay!</p>
      </div>

      <div *ngIf="partner.internal_name == 'picpay' && store.business.rf_attributes.picpay_reward">
        <amp-loading *ngIf="loading"></amp-loading>

        <div *ngIf="!loading && picpay_step == 1">
          <div class="amp-field">
            <label class="amp-label-text">Client ID</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_picpay.client_id" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Client Secret</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_picpay.client_secret" />
          </div>
          <div class="amp-field amp-select-new">
            <label class="amp-label-text" for="business">Campo CPF</label>
            <select class="amp-input-lg" [(ngModel)]="store.business.partner_picpay.custom_field_for_cpf" autocomplete="off" name="cpfField" required>
              <option [value]="null" disabled>Selecione</option>
              <option *ngFor="let item of customFieldsList" [value]="item.internal_name">{{ item.internal_name }}</option>
            </select>
            <span class="bi-chevron-down"></span>
          </div>
          <button (click)="savePicPayCredentials()" [disabled]="
              !store.business.partner_picpay.client_id || !store.business.partner_picpay.client_secret || !store.business.partner_picpay.custom_field_for_cpf
            " class="amp-btn-lg amp-btn-success w-100">
            Continuar
          </button>
        </div>

        <div *ngIf="!loading && picpay_step == 2">
          <div class="amp-field">
            <label class="amp-label-text">Nome</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="picpayFields.name" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Início</label>
            <input class="amp-input-lg" bsDatepicker [bsConfig]="{ containerClass: 'theme-amp' }" [(ngModel)]="picpayFields.started_at" type="search" placeholder="Selecione uma data" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Término</label>
            <input class="amp-input-lg" bsDatepicker [bsConfig]="{ containerClass: 'theme-amp' }" [(ngModel)]="picpayFields.ended_at" type="search" placeholder="Selecione uma data" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Número Máximo de Transações</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="picpayFields.payee_transaction_limit" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Valor Máximo de Recebimento</label>
            <input currencyMask name="amount" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="amp-input-lg" [(ngModel)]="picpayFields.payee_transaction_value" />
          </div>

          <div class="amp-field amp-radio-wrapper">
            <label class="amp-label-text">Pode existir transações de mesmo valor para a mesma pessoa?</label>
            <label class="amp-radio-button">
              <input type="radio" required name="identifical_transaction_rule" [value]="false" [(ngModel)]="picpayFields.identical_transaction_rule" />
              <span class="text">Sim</span>
              <span class="checkmark"></span>
            </label>
            <label class="amp-radio-button">
              <input type="radio" name="identifical_transaction_rule" required [value]="true" [(ngModel)]="picpayFields.identical_transaction_rule" />
              <span class="text">Não</span>
              <span class="checkmark"></span>
            </label>
          </div>
          <button (click)="savePicPayProject()" class="amp-btn-lg amp-btn-success w-100">Salvar Projeto</button>
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'elementor'">
        <div class="mb-4">Selecione um Token de Integração ou crie um novo no botão abaixo</div>

        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Token de Integração</label>
          <select class="amp-input-lg" [(ngModel)]="selectedToken" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>
              {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
            </option>
            <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <button (click)="createNewTokenReferral()" *ngIf="!selectedToken" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

        <div class="amp-field">
          <label class="amp-label-text">Name do campo nome no formulário</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="elementorParams.f_name" />
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Name do campo e-mail no formulário</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="elementorParams.f_email" />
        </div>

        <div class="amp-field amp-select-new margin">
          <label class="amp-label-text" for="campaignType">Campanha de Indicação </label>
          <select class="amp-input-lg" name="tokenCampaign" [(ngModel)]="campaign">
            <option value="" disabled>Selecionar Campanha</option>
            <ng-container *ngFor="let cp of referralCampaigns">
              <option [value]="cp._id">{{ cp.title }}</option>
            </ng-container>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <button type="button" class="amp-btn amp-btn-success w-100" (click)="generateElementorURL()">Gerar URL do Webhook</button>
        <br />
        <div class="amp-field" *ngIf="selectedToken && elementorParams.url.length > 0">
          <label class="amp-label-text">URL do Webhook</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="elementorParams.url" placeholder="integration.service.amplifique.me..." />
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'pipedrive'">
        <div class="amp-field">
          <label class="amp-label-text">Chave de API do PipeDrive</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_pipedrive_key" (blur)="getPipeDriveStages()" placeholder="ASDAPnsdsadCasdasddASWDFGyEXPsclz" />
        </div>

        <div class="mb-4">Selecione um Token de Integração ou crie um novo no botão abaixo</div>

        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Token de Integração</label>
          <select class="amp-input-lg" [(ngModel)]="selectedToken" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>
              {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
            </option>
            <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <button (click)="createNewIntegrationToken()" *ngIf="!selectedToken" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

        <div class="mb-4">Selecione um estágio que irá disparar o gatilho da Integração</div>
        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Estágio do PipeDrive</label>
          <select class="amp-input-lg" [(ngModel)]="selectedStage" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>Selecione o Estágio</option>
            <option *ngFor="let item of pipedriveStages" [value]="item.id">{{ item.pipeline_name }} | {{ item.name }}</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <div class="mb-4" *ngIf="selectedStage && selectedToken">
          Crie um novo Webhook no PipeDrive (PipeDrive -> Configurações da Empresa -> Ferramentas e Aplicativos -> Webhooks)<br />
          Com a Ação do Evento: <b>Updated</b> <br />
          Objeto Do Evento: <b>Deal</b>
        </div>
        <div class="amp-field" *ngIf="selectedStage && selectedToken">
          <label class="amp-label-text">URL do Ponto de Extremidade</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="'https://integration.services.amplifique.me/pipedrive/cf/deal-moved?stage=' + selectedStage + '&access_token=Bearer%20' + selectedToken" placeholder="nome-da-loja.myshopify.com" />
        </div>

        <button *ngIf="selectedStage && selectedToken" class="amp-btn-lg amp-btn-success w-100" (click)="activeIntegration()">Finalizar</button>
      </div>

      <div *ngIf="partner.internal_name == 'piperun'">
        <p>Acesse a documentação <a href="https://amplifiqueme.notion.site/PipeRun-121a6daff71d8083b8bbcf502d001296" target="_blank">aqui</a>.</p>
        <div class="mb-2">Selecione um Token de Integração ou crie um novo no botão abaixo</div>

        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Token de Integração</label>
          <select class="amp-input-lg" [(ngModel)]="selectedToken" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>
              {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
            </option>
            <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <button (click)="createNewIntegrationToken()" *ngIf="!selectedToken" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

        <div class="mb-4" *ngIf="selectedToken">
          Crie uma nova ação automática no PipeRun (PipeRun -> Ajustes e Configurações → Ferramentas → Ações automáticas → Adicionar ação)<br />
        </div>
        <div class="amp-field" *ngIf="selectedToken">
          <label class="amp-label-text">URL</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="'https://integration.services.amplifique.me/piperun/webhook?access_token=Bearer%20' + selectedToken" placeholder="URL" />
        </div>

        <button *ngIf="selectedToken" class="amp-btn-lg amp-btn-success w-100" (click)="activeIntegration()">Finalizar</button>
      </div>

      <div *ngIf="partner.internal_name == 'customerx'">
        <div class="mb-4">Selecione um evento</div>
        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Evento</label>
          <select class="amp-input-lg" [(ngModel)]="customerXParams.event" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>Selecione o Evento</option>
            <option value="journey-task">Jornada CSM</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <div class="mb-4">Selecione um Token de Integração ou crie um novo no botão abaixo</div>

        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Token de Integração</label>
          <select class="amp-input-lg" [(ngModel)]="selectedToken" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>
              {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
            </option>
            <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <button (click)="createNewIntegrationToken()" *ngIf="!selectedToken" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

        <div class="amp-field">
          <label class="amp-label-text">Filtro de Tag</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="customerXParams.f_label" />
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Filtro de Jornada</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="customerXParams.f_journey" />
        </div>

        <button type="button" class="amp-btn amp-btn-success w-100" (click)="generateCustomerXURL()">Gerar URL do Webhook</button>
        <br />
        <div class="amp-field" *ngIf="selectedToken && customerXParams.url.length > 0">
          <label class="amp-label-text">URL do Webhook</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="customerXParams.url" placeholder="integration.service.amplifique.me..." />
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'tinyerp'">
        <div class="amp-field">
          <label class="amp-label-text">Chave de API do Tiny ERP</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="store.business.partner_tinyerp_key" placeholder="ASDAPnsdsadCasdasddASWDFGyEXPsclz" />
        </div>

        <div class="mb-4">Selecione um Token de Integração ou crie um novo no botão abaixo:</div>

        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Token de Integração</label>
          <select class="amp-input-lg" [(ngModel)]="selectedToken" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>
              {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
            </option>
            <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <button (click)="createNewIntegrationToken()" *ngIf="!selectedToken" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

        <div *ngIf="selectedToken" class="amp-field">
          <label class="amp-label-text">URL do Ponto de Extremidade</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="'https://integration.services.amplifique.me/tiny-erp/cf/webhook?access_token=Bearer%20' + selectedToken" placeholder="nome-da-loja.myshopify.com" />
        </div>

        <button *ngIf="selectedToken" class="amp-btn-lg amp-btn-success w-100" (click)="activeIntegration()">Finalizar</button>
      </div>

      <div *ngIf="partner.internal_name == 'cvcrm'">
        <div class="mb-4">Selecione um Token de Integração ou crie um novo no botão abaixo</div>

        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="business">Token de Integração</label>
          <select class="amp-input-lg" [(ngModel)]="selectedToken" autocomplete="off" name="tokenIntegration" required>
            <option [value]="null" disabled>
              {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
            </option>
            <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <button (click)="createNewIntegrationToken()" *ngIf="!selectedToken" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

        <div class="mb-4" *ngIf="selectedStage && selectedToken">
          Crie um novo Webhook no PipeDrive (PipeDrive -> Configurações da Empresa -> Ferramentas e Aplicativos -> Webhooks)<br />
          Com a Ação do Evento: <b>Updated</b> <br />
          Objeto Do Evento: <b>Deal</b>
        </div>
        <div class="amp-field" *ngIf="selectedToken">
          <label class="amp-label-text">URL do Ponto de Extremidade</label>
          <input type="text" class="amp-input-lg" readonly="readonly" [value]="'https://integration.services.amplifique.me/cv/cf/webhook?access_token=Bearer%20' + selectedToken" />
        </div>

        <button *ngIf="selectedToken" class="amp-btn-lg amp-btn-success w-100" (click)="activeIntegration()">Finalizar</button>
      </div>

      <div *ngIf="partner.internal_name == 'totvs_cmnet'">
        <amp-loading *ngIf="loading"></amp-loading>

        <div *ngIf="!loading">
          <div class="mb-4">Selecione um Token de Integração ou crie um novo no botão abaixo</div>

          <div class="amp-field amp-select-new">
            <label class="amp-label-text" for="business">Token de Integração</label>
            <select class="amp-input-lg" [(ngModel)]="totvsParams.partner_cmnet_token" autocomplete="off" name="tokenIntegration" required>
              <option [value]="null" disabled>
                {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
              </option>
              <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
            </select>
            <span class="bi-chevron-down"></span>
          </div>

          <button (click)="createNewIntegrationToken()" *ngIf="!selectedToken" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

          <div class="amp-field">
            <label class="amp-label-text">ID do Hotel</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="totvsParams.partner_cmnet_hotel_id" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Host do Banco de Dados ({{ cmnetSaved ? 'Dado Criptografado' : '' }})</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="totvsParams.partner_cmnet_db_host" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Nome do Banco de Dados</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="totvsParams.partner_cmnet_db_name" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Usuário do Banco de Dados</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="totvsParams.partner_cmnet_db_user" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Senha do Banco de Dados ({{ cmnetSaved ? 'Dado Criptografado' : '' }})</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="totvsParams.partner_cmnet_db_password" />
          </div>
          <div class="amp-field">
            <label class="amp-label-text">Porta do Banco de Dados</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="totvsParams.partner_cmnet_db_port" />
          </div>
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'desbravador'">
        <amp-loading *ngIf="loading"></amp-loading>
        <div *ngIf="!loading">
          <div class="mb-4">Selecione um Token de Integração ou crie um novo no botão abaixo</div>

          <div class="amp-field amp-select-new">
            <label class="amp-label-text" for="business">Token de Integração</label>
            <select class="amp-input-lg" [(ngModel)]="desbravadorParams.partner_desbravador_token" autocomplete="off" name="tokenIntegration" required>
              <option [value]="null" disabled>
                {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão abaixo para criar um!' }}
              </option>
              <option *ngFor="let item of tokens" [value]="item.access_token">{{ item.name }}</option>
            </select>
            <span class="bi-chevron-down"></span>
          </div>

          <button (click)="createNewIntegrationToken()" class="amp-btn-light w-100 amp-search-list">Criar Novo Token</button>

          <div class="amp-field">
            <label class="amp-label-text">Chave de Acesso Desbravador</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="desbravadorParams.partner_desbravador_key" />
          </div>
        </div>
      </div>

      <div *ngIf="partner.internal_name == 'gupy'">
        <amp-loading *ngIf="loading"></amp-loading>

        <div *ngIf="!loading">
          <div class="amp-field">
            <label class="amp-label-text">Insira abaixo a chave da API da Gupy</label>
            <input [disabled]="gupyParams.active" type="text" class="amp-input-lg" [(ngModel)]="gupyParams.access_token" placeholder="Exemplo: "/>
          </div>

          <div class="amp-table">
            <div class="amp-table-head">
              <div class="amp-table-column">Nome da Fase <i tooltip="" class="bi-info-circle-fill gupy-table-icon"></i></div>
              <div class="amp-table-column">Token</div>
            </div>
            <div class="amp-table-row" *ngFor="let trigger of gupyParams.triggers; let i = index">
              <div class="amp-table-cell">
                <input class="amp-input" [(ngModel)]="trigger.step_name">
              </div>
              <div class="amp-table-cell gupy-table-cell">
                <button class="amp-btn btn-succes" (click)="createNewIntegrationToken()">Criar Token</button>
                <div class="amp-field amp-select-new amp-table-select">
                  <select class="amp-input" [(ngModel)]="trigger._token" autocomplete="off" name="tokenIntegration" required>
                    <option [value]="null" disabled>
                      {{ tokens.length > 0 ? 'Selecione o Token ' : 'Nenhum token encontrado, clique no botão ao lado para criar um!' }}
                    </option>
                    <option *ngFor="let item of tokens" [value]="item._id">{{ item.name }}</option>
                  </select>
                  <span class="bi-chevron-down"></span>
                </div>
              </div>
            </div>
            <div class="gupy-no-trigger-message" *ngIf="gupyParams?.triggers?.length == 0">Nenhum gatilho criado.</div>
          </div>

          <button class="amp-btn btn-success amp-add-row" (click)="addRow(gupyParams?.triggers)">Adicionar Gatilho</button>
          <button (click)="activeIntegration()" class="amp-btn btn-success">Salvar</button>
        </div>
      </div>

      <button *ngIf="partner.internal_name == 'api'" class="amp-btn-lg amp-btn-success w-100" (click)="bsModalRef.hide()" routerLink="/partners/api">
        Ir para Painel
      </button>
      <button *ngIf="partner.internal_name == 'webhook'" class="amp-btn-lg amp-btn-success w-100" (click)="bsModalRef.hide()" routerLink="/partners/webhook">
        Ir para Painel
      </button>
      <button *ngIf="partner.internal_name == 'funil'" class="amp-btn-lg amp-btn-success w-100" [disabled]="partner.internal_name == 'funil' && store.business.partner_rd_station_api_key?.length == 0" (click)="activeIntegration()">
        Habilitar/Conectar
      </button>
      <button *ngIf="partner.internal_name == 'RDStation_marketing'" class="amp-btn-lg amp-btn-success w-100" [disabled]="partner.internal_name == 'RDStation_marketing' && store.business.partner_rd_station_api_key?.length == 0" (click)="activeIntegration()">
        Habilitar/Conectar
      </button>
      <button *ngIf="
          partner.internal_name != 'elementor' &&
          partner.internal_name != 'eduzz' &&
          partner.internal_name != 'active_campaign' &&
          partner.self_setting == false
        " class="amp-btn-lg amp-btn-success w-100" (click)="requestSupport()">
        Conversar com o Suporte
      </button>
      <button *ngIf="partner.internal_name == 'asksuite' && !loading && !askSuiteToken" class="amp-btn-lg amp-btn-success w-100" (click)="generateAskSuiteToken()">
        Gerar Token AskSuite
      </button>
      <button *ngIf="partner.internal_name == 'totvs_cmnet' && !loading" [disabled]="
          totvsParams.partner_cmnet_db_host?.length == 0 ||
          totvsParams.partner_cmnet_db_name?.length == 0 ||
          totvsParams.partner_cmnet_db_user.length == 0 ||
          totvsParams.partner_cmnet_db_password.length == 0 ||
          totvsParams.partner_cmnet_db_port?.length == 0 ||
          totvsParams.partner_cmnet_hotel_id?.length == 0 ||
          totvsParams.partner_cmnet_token?.length == 0
        " class="amp-btn-lg amp-btn-success w-100" (click)="saveCMNetIntegration()">
        Salvar
      </button>
      <button *ngIf="partner.internal_name == 'desbravador' && !loading" [disabled]="desbravadorParams.partner_desbravador_key?.length == 0 || desbravadorParams.partner_desbravador_token?.length == 0" class="amp-btn-lg amp-btn-success w-100" (click)="activeIntegration()">
        Salvar
      </button>
    </div>

    <div *ngIf="enable">
      <div class="amp-partner-image-wrapper" [ngStyle]="{ background: partner.color }">
        <img class="amp-partner-image" [src]="partner.logo" />
      </div>

      <div class="amp-field">
        <label class="amp-label-text" for="tokenField">Token de Integração</label>
        <input type="text" class="amp-input-lg" [(ngModel)]="token" autocomplete="off" placeholder="Token" #tokenField="ngModel" name="tokenField" />
      </div>

      <button class="amp-btn-lg amp-btn-danger w-100">Excluir Token</button>
    </div>
  </div>
</div>
