import { EmailTemplate } from './emailTemplate';
import { Empresa } from './empresa';
import { TagSet } from './smartCluster/tagSet';
import { Token } from './token';
export class Business {
  _id: string;
  nome: string;
  companyName: string;
  taxId: string;
  picture: string;
  mensagem: string;
  slug: string;
  mensagem_nps: string;
  fb_page_token: string;
  fb_page_id: string;
  _empresa: Empresa | string;
  created_at: Date;
  expedia_pageid: string;
  booking_pagename: string;
  trip_url: string;
  hotelscom_pageid: string;
  despegar_id: string;
  airbnb_id: string;
  kekanto_url: string;
  available_links: any;
  instagram_id: string;
  nps_review_gte_8: boolean;
  gl_access_token: string;
  gl_refresh_token: string;
  gl_spreadsheetId: string;
  gl_spreadsheet_active: boolean;

  wa_device_id: string;
  wa_device_authenticated: boolean;
  wa_device_enabled: boolean;
  wa_enabled: boolean;
  wa_number: string;

  review_message_sms: string;
  review_message_wa: string;
  review_message_email_1: string;
  review_message_email_2: string;

  nps_message_sms: string;
  nps_message_wa: string;
  nps_message_email_1: string;
  nps_message_email_2: string;
  nps_message_thankyou: string;
  nps_message_gte_8: string;
  nps_question_label: string;
  nps_question_placeholder: string;

  nps_enabled = false;
  reviews_enabled = false;
  referral_enabled = false;
  sms_enabled = false;
  email_enabled = false;

  totem_description: string;
  totem_title: string;

  gallery: string[];

  language: string;
  smsBalance: number;
  minimumBalance: number;
  smsReservedBalance: number;
  _tagSet: TagSet;

  sessionId: string;
  stripeCustomer: string;

  _products: {
    referralProduct: string;
    cfProduct: string;
    reviewsProduct: string;
  };

  cf_attributes: {
    cf_nResponses: number;
    cf_surveys: boolean;
    cf_old_nps: boolean;
    cf_block_when_reach_limit: boolean;
    cf_allow_hide_trademark: boolean;
    cf_protocol_mode: boolean;
    cf_ai: boolean;
    cf_incomplete_answers: boolean;
    cf_can_manage_balance: boolean;
  };
  rf_attributes: {
    rf_nReferrals: number;
    active_referral_campaigns: number;
    picpay_reward: boolean;
  };
  rp_attributes: {
    rp_nPlatforms: { type: number; default: 0 };
    rp_webhooks: boolean;
  };

  activeAccount: boolean;

  referral_participant_usage: number;

  pendingPayment: boolean;
  partner_active_campaign_account_name: string;
  partner_active_campaign_token: string;
  partner_active_campaign_list: string;
  partner_active_campaign_event_id: string;
  partner_active_campaign_event_key: string;
  partner_rd_station_api_key: string;

  partner_beta_labs_project_id: string;
  partner_beta_labs_api_key: string;
  partner_movidesk_key: string;
  partner_pipedrive_key: string;
  partner_tinyerp_key: string;
  partner_funilvendas_key: string;

  partner_cmnet_hotel_id: string;
  partner_cmnet_token: string;
  partner_cmnet_db_host: string;
  partner_cmnet_db_name: string;
  partner_cmnet_db_user: string;
  partner_cmnet_db_password: string;
  partner_cmnet_db_port: string;

  partner_desbravador_token: string;
  partner_desbravador_key: string;

  partner_picpay: {
    active: boolean;
    client_id: string;
    client_secret: string;
    refresh_token: string;
    custom_field_for_cpf: string;
    _token: Token;
  } = { active: false, client_id: '', client_secret: '', refresh_token: '', custom_field_for_cpf: '', _token: null };

  reviews_params: {
    sms_request_message: string;
    _email_request_message: EmailTemplate;
    smartTagsEnabled: boolean;
    _tagSet: TagSet;
    link_params: {
      page_title: string;
      page_description: string;
      links: [
        {
          name: string;
          created_at: Date;
          isQrCode: boolean;
          views: number;
          starts: number;
        }
      ];
    };
  } = {
    sms_request_message: '',
    _email_request_message: new EmailTemplate(),
    smartTagsEnabled: false,
    _tagSet: undefined,
    link_params: {
      page_title: '',
      page_description: '',
      links: [
        {
          name: 'Link 1',
          created_at: new Date(),
          isQrCode: false,
          views: 0,
          starts: 0
        }
      ]
    }
  };

  partner_gupy: any = {};

  email_settings: {
    subdomain: string;
    domain: string;
    sender: string;
    sendgrid_id: string;
    is_dns_verified: boolean;
    custom_dkim_selector: string;
    is_inbound_created: false;
    is_inbound_verified: false;
  } = {
    subdomain: '',
    domain: '',
    sender: '',
    sendgrid_id: '',
    is_dns_verified: false,
    custom_dkim_selector: undefined,
    is_inbound_created: false,
    is_inbound_verified: false
  };

  billing_data: {
    day_renew_balance: number;
  } = { day_renew_balance: 1 };
}
