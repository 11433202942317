<div class="amp-dialog">
  <p *ngIf="isNew" class="amp-dialog-title">Criar Unidade</p>
  <p *ngIf="!isNew" class="amp-dialog-title">Clonar Unidade</p>
  <form (ngSubmit)="fmSubmit()" #form="ngForm">
    <div class="amp-field" *ngIf="!isFromAdmInterna">
      <label class="amp-label-text" for="role">Selecione a empresa</label>
      <select required [(ngModel)]="business._empresa" #empresa="ngModel" name="empresa" class="amp-input">
        <option disabled>Selecione uma empresa</option>
        <option *ngFor="let empresa of empresas" value="{{ empresa._id }}">
          {{ empresa.nome }}
        </option>
      </select>
    </div>
    <div class="amp-field amp-content">
      <label class="amp-label-text">Nome da Unidade</label>
      <input type="text" class="amp-input" [(ngModel)]="business.nome" #nome="ngModel" name="nome" required />
    </div>

    <div *ngIf="empresa?.billing_mode == 'business'">
      <span><b>Faturamento</b>Unidade</span>
      <span>CNPJ/CPF</span>
      <input class="amp-input"  mask="000.000.000-00||00.000.000/0000-00" validateCpfCnpj name="cpf-cnpj" [(ngModel)]="business.taxId" />
    </div>

    <div *ngIf="empresa?.billing_mode == 'organization'">
      <span><b>Faturamento: </b>Organização</span>
      <br>
      <span><b>CNPJ/CPF: </b>{{ empresa.taxId }}</span>
    </div>
    <br>
    <button type="submit" class="amp-btn-outline-primary amp-btn-lg">Salvar</button>
  </form>
</div>
