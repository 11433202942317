import { Token } from '@angular/compiler';
import { Customer } from './customer';
import { Business } from './business';
import { Empresa } from './empresa';
export class PartnerRequest {
  body: object;
  status: string;
  error: object;
  created_at: Date;
  _token: Token;
  _customer: Customer;
  _business: Business;
  _empresa: Empresa;
}
