<div class="amp-date-picker">
  <button class="amp-btn-lg amp-btn-outline-secondary">
    <i class="bi-calendar-week"></i>
    <div class="dropdown" dropdown #dropdown="bs-dropdown" (onHidden)="removeFlex()" placement="right">
      <a class="amp-card-filter-item" dropdownToggle (click)="toggleDropdown()" aria-controls="calendar-dropdown">
        <span *ngIf="filterTime == 'custom'">{{ dateFilter[0].toLocaleDateString('pt-BR') }} - {{ dateFilter[1].toLocaleDateString('pt-BR') }}</span>
        <span *ngIf="filterTime != 'custom'">{{ 'filterDate.' + filterTime | translate }}</span>
      </a>
      <div class="dropdown-menu amp-datepicker dropdown-menu-right" id="calendar-dropdown" *dropdownMenu role="menu" aria-labelledby="calendarDropdown">
        <div class="amp-datepicker-timeselector">
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="today" (click)="setDateFilter('today'); customDate = false; dropdown.hide(); removeFlex()" />
            <span class="amp-datepicker-time">{{ 'filterDate.today' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="yesterday" (click)="setDateFilter('yesterday'); customDate = false; dropdown.hide(); removeFlex()" /><span class="amp-datepicker-time">{{ 'filterDate.yesterday' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="week" (click)="setDateFilter('week'); customDate = false; dropdown.hide(); removeFlex()" /><span class="amp-datepicker-time">{{ 'filterDate.week' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="lastweek" (click)="setDateFilter('lastweek'); customDate = false; dropdown.hide(); removeFlex()" /><span class="amp-datepicker-time">{{ 'filterDate.lastweek' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="last30days" (click)="setDateFilter('last30days'); customDate = false; dropdown.hide(); removeFlex()" /><span class="amp-datepicker-time">{{ 'filterDate.last30days' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="thismonth" (click)="setDateFilter('thismonth'); customDate = false; dropdown.hide(); removeFlex()" /><span class="amp-datepicker-time">{{ 'filterDate.thismonth' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="lastmonth" (click)="setDateFilter('lastmonth'); customDate = false; dropdown.hide(); removeFlex()" /><span class="amp-datepicker-time">{{ 'filterDate.lastmonth' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="last6months" (click)="setDateFilter('last6months'); customDate = false; dropdown.hide(); removeFlex()" /><span class="amp-datepicker-time">{{ 'filterDate.last6months' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="thisyear" (click)="setDateFilter('thisyear'); customDate = false; dropdown.hide(); removeFlex()" checked /><span class="amp-datepicker-time">{{ 'filterDate.thisyear' | translate }}</span></label>
          <label class="radio"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="alltime" (click)="setDateFilter('alltime'); customDate = false; dropdown.hide(); removeFlex()" checked /><span class="amp-datepicker-time">{{ 'filterDate.alltime' | translate }}</span></label>
          <hr class="amp-horizontal-line" *ngIf="showCustomDate" />
          <label class="radio" *ngIf="showCustomDate"><input type="radio" [(ngModel)]="filterTime" name="dateTime" value="custom" (bsValueChange)="onChangeDateRange($event)" (click)="toggleCalendar()" /><span class="amp-datepicker-time">{{ 'filterDate.custom' | translate }}</span></label>
        </div>
        <div class="amp-divisor" *ngIf="calendarVisibility"></div>
        <div *ngIf="calendarVisibility" class="amp-calendar-inline pr-3 pb-3">
          <bs-daterangepicker-inline (bsValueChange)="onChangeDateRange($event)" [bsConfig]="{ containerClass: 'theme-amp theme-amp-flat', customTodayClass: 'custom-today-class' }"></bs-daterangepicker-inline>
          <div class="amp-calendar-buttons">
            <button (click)="setDateFilter('month'); toggleCalendar()">Cancelar</button>
            <button (click)="toggleCalendar()">Aplicar</button>
          </div>
        </div>
      </div>
    </div>
  </button>
</div>
