<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-people"></span>
      {{'validar-recompensa-dialog.title' | translate}}
    </div>
    <div class="amp-dialog-subtitle">{{'validar-recompensa-dialog.subtitle' | translate}}</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-indicacao">
      <div class="amp-table-cell amp-cell-info"><img src="./assets/img/user.png">
        <div class="amp-cell-infotext"> <span class="amp-info-name">{{customer.name}}</span> <span class="amp-info-email">{{customer.email}}</span></div>
      </div>
    </div>

    <div class="amp-rewards">
      <div *ngFor="let referral of rewards">
        <ng-container [ngTemplateOutlet]="(referral._referrer._id == customer._id) ?  referTemplate : referredTemplate" [ngTemplateOutletContext]="{referral: referral,i:i}"></ng-container>
      </div>
    </div>
  </div>

  <div class="amp-dialog-actions">
    <button class="amp-btn-success amp-btn-lg" (click)="save()" [disabled]="selected==null">{{'validar-recompensa-dialog.button' | translate}}</button>
  </div>
</div>

<ng-template #referTemplate let-referral="referral" let-i="i">
  <div class="amp-collapse" *ngIf="!referral.reward_confirmed && !referral.referral_valid && !isExpired(referral.referrer_expiration)">
    <div class="amp-coupon-collapse pending">
      <div class="amp-coupon-usedat">Pendente</div>
      <h2 class="amp-coupon-name">{{ referral._campaign.reward_referrer.name}}</h2>
      <h4 class="amp-coupon-description">Recompensa por indicar {{referral._referee ? referral._referee.name : ''}}</h4>
    </div>
  </div>

  <div class="amp-collapse" *ngIf="referral.referral_valid && isExpired(referral.referrer_expiration) && !referral.reward_confirmed">
    <div class="amp-coupon-collapse pending">
      <div class="amp-coupon-usedat">Expirado</div>
      <h2 class="amp-coupon-name">{{referral._campaign.reward_referrer.name}}</h2>
      <h4 class="amp-coupon-description">Recompensa por indicar {{referral._referee ? referral._referee.name : ''}}</h4>
      <p class="amp-coupon-valid">VALIDO ATÉ {{referral.referrer_expiration ? (referral.referrer_expiration | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY') : 'O FINAL DA CAMPANHA'}}</p>
    </div>
  </div>

  <div class="amp-collapse" *ngIf="referral.reward_confirmed">
    <div class="amp-coupon-collapse used">
      <div class="amp-coupon-usedat">Utilizado em {{referral.redeemed_at | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY'}}</div>
      <h2 class="amp-coupon-name">{{referral._campaign.reward_referrer.name}}</h2>
      <h4 class="amp-coupon-description">Recompensa por indicar {{referral._referee ? referral._referee.name : ''}}</h4>
      <p class="amp-coupon-valid">VALIDO ATÉ {{referral.referrer_expiration ? (referral.referrer_expiration | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY') : 'O FINAL DA CAMPANHA'}}</p>
    </div>
  </div>

  <div class="amp-collapse" *ngIf="referral.referral_valid && !isExpired(referral.referrer_expiration) && !referral.reward_confirmed">
    <div (click)="selectReward(referral,1)" [ngClass]="{'selected': referral._id == selected}" class="amp-coupon-collapse">
      <h2 class="amp-coupon-name">{{referral._campaign.reward_referrer.name}}</h2>
      <h4 class="amp-coupon-description">Recompensa por indicar {{referral._referee ? referral._referee.name : ''}}</h4>
      <p class="amp-coupon-valid">VALIDO ATÉ {{referral.referrer_expiration ? (referral.referrer_expiration | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY') : 'O FINAL DA CAMPANHA'}}</p>
    </div>
  </div>
</ng-template>

<ng-template #referredTemplate let-referral="referral" let-i="i">
  <div class="amp-collapse" *ngIf="!isExpired(referral.referee_expiration) && !referral.referral_valid">
    <div (click)="selectReward(referral,0)" [ngClass]="{'selected': referral._id == selected}" class="amp-coupon-collapse">
      <h2 class="amp-coupon-name">{{referral._campaign.reward_referee.name}}</h2>
      <h4 class="amp-coupon-description">Indicado por {{referral._referrer ? referral._referrer.name : ''}}</h4>
      <p class="amp-coupon-valid">VALIDO ATÉ {{referral.referee_expiration ? (referral.referee_expiration | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY') : 'O FINAL DA CAMPANHA'}}</p>
    </div>
  </div>

  <div class="amp-collapse" *ngIf="!referral.referral_valid && isExpired(referral.referee_expiration)">
    <div class="amp-coupon-collapse pending">
      <div class="amp-coupon-usedat">Expirado</div>
      <h2 class="amp-coupon-name">{{referral._campaign.reward_referee.name}}</h2>
      <h4 class="amp-coupon-description">Indicado por {{referral._referrer ? referral._referrer.name : ''}}</h4>
      <p class="amp-coupon-valid">VALIDO ATÉ {{referral.referee_expiration ? (referral.referee_expiration | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY') : 'O FINAL DA CAMPANHA'}}</p>
    </div>
  </div>

  <div class="amp-collapse" *ngIf="referral.referral_valid">
    <div class="amp-coupon-collapse used">
      <div class="amp-coupon-usedat">Utilizado em {{referral.validate_at | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY'}}</div>
      <h2 class="amp-coupon-name">{{referral._campaign.reward_referee.name}}</h2>
      <h4 class="amp-coupon-description">Indicado por {{referral._referrer ? referral._referrer.name : ''}}</h4>
      <p class="amp-coupon-valid">VALIDO ATÉ {{referral.referee_expiration ? (referral.referee_expiration | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY') : 'O FINAL DA CAMPANHA'}}</p>
    </div>
  </div>
</ng-template>