import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { Dashboard } from 'app/_models/dashboard';
@Injectable()
export class DashboardService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getById(_id: string) {
    return this.http.get<Dashboard>(this.config.apiUrl + '/dashboards/' + _id);
  }

  getAllByBusiness(_business: string, type = 'nps') {
    return this.http.get<any>(this.config.apiUrl + '/dashboards/all/' + _business + '?type=' + type);
  }

  update(dashboard: Dashboard) {
    return this.http.put<Dashboard>(this.config.apiUrl + '/dashboards/' + dashboard._id, dashboard);
  }

  updateCard(dashboardId, dashboardCard: any) {
    return this.http.patch<any>(this.config.apiUrl + `/dashboards/${dashboardId}/update-card/${dashboardCard._id}`, dashboardCard);
  }

  create(dashboard: Dashboard) {
    return this.http.post<Dashboard>(this.config.apiUrl + '/dashboards', dashboard);
  }

  delete(_id: string) {
    return this.http.delete<any>(this.config.apiUrl + '/dashboards/' + _id);
  }
}
