import { BusinessService, NotifyService, SignupService, UserService } from 'app/_services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'app/_services/store.service';
import { MixPanelService } from 'app/_services/mixPanel.service';
import { MenuService } from 'app/_services/menu.service';
import { UploadFileDialogComponent } from 'app/_dialogs/upload-file-dialog/upload-file-dialog.component';
import { User } from 'app/_models/user';
import { Business } from 'app/_models/business';
@Component({
  selector: 'app-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.scss']
})
export class UsersDialogComponent implements OnInit, OnDestroy {
  public businesses: Business[];
  public selectedAll = false;
  public items: string[] = [];
  public user: User = new User();
  public isNew = true;
  public isProfileEdit = false;
  public roles: any = [
    { name: 'Proprietário', value: 'own' },
    { name: 'Administrador', value: 'adm' },
    { name: 'Analista', value: 'ana' },
    { name: 'Operador', value: 'pdv' },
    { name: 'Leitura e Notas', value: 'notesonly' },
    { name: 'Apenas Leitura', value: 'readonly' },
    { name: 'Apenas Painel', value: 'dashonly' }
  ];
  public ticketing_roles: any = [
    { name: 'Nenhum', value: '' },
    { name: 'Administrador', value: 'adm' },
    { name: 'Analista', value: 'ana' },
    { name: 'Agente', value: 'agent' }
  ];

  public allowSaveButton = true;
  public subscriptions = [];

  public maskPin = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public maskTel = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    public bsModalRef: BsModalRef,
    private menuService: MenuService,
    private mixPanel: MixPanelService,
    private businessService: BusinessService,
    private translate: TranslateService,
    private userService: UserService,
    public store: StoreService,
    private modalService: BsModalService,
    private signupService: SignupService,
    private notifyService: NotifyService
  ) {
    this.menuService.emit(true);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.businessService.getMyBusiness(this.store.empresa).subscribe((data) => {
        const t = data;
        this.businesses = t;
        let businesses;
        if (!this.isNew) {
          businesses = this.user._businesses;
          this.items = businesses;
          const busId = this.businesses.map((b) => b._id);
          if (JSON.stringify(busId) == JSON.stringify(this.items)) {
            this.selectedAll = true;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.push(
      this.subscriptions.forEach((item, index, array) => {
        item.unsubscribe();
        array.splice(index, 1);
      })
    );
  }

  validateEmail(evt) {
    evt.srcElement.value = evt.srcElement.value.replace(' ', '');
  }

  save() {
    if (this.isNew) {
      if (this.store.empresaData.auth_source == 'msal') {
        if (this.user.email.split('@')[1] !== this.store.empresaData.auth_domain) {
          this.notifyService.error('Este e-mail não pertence ao domínio da empresa!');
          return;
        }
      }

      this.mixPanel.track('Create User');
      this.subscriptions.push(
        this.signupService
          .create({
            ...this.user,
            empresa: this.store.empresa,
            _businesses: this.items,
            email: this.user.email,
            nome: this.user.nome,
            role: this.user.role,
            type: false,
            language: localStorage.getItem('lang')
          })
          .subscribe(
            () => {
              this.bsModalRef.hide();
              this.subscriptions.push(
                this.translate.get('notify.user.invite-sent').subscribe((res) => {
                  this.notifyService.success(res);
                })
              );
            },
            (error) => {
              console.error(error);
              this.subscriptions.push(
                this.translate.get('notify.user.invite-sent-error').subscribe((res) => {
                  this.notifyService.error(res);
                })
              );
            }
          )
      );
    } else {
      //@ts-ignore
      this.user._businesses = this.items;

      this.mixPanel.track('Update User');

      if (!this.user._businesses.length) {
        return this.notifyService.warning('Você não pode remover todas as unidades de um usuário!');
      }

      if (this.isProfileEdit) {
        this.subscriptions.push(
          this.userService.updateSelf(this.user).subscribe(
            () => {
              this.subscriptions.push(
                this.translate.get('notify.user.user-updated').subscribe((res) => {
                  this.notifyService.success(res);
                })
              );
              this.bsModalRef.hide();
            },
            (error) => {
              console.error(error);
              this.subscriptions.push(
                this.translate.get('notify.user.user-updated-error').subscribe((res) => {
                  this.notifyService.error(res);
                })
              );
            }
          )
        );
      } else {
        this.subscriptions.push(
          this.userService.update(this.user).subscribe(
            () => {
              this.subscriptions.push(
                this.translate.get('notify.user.user-updated').subscribe((res) => {
                  this.notifyService.success(res);
                })
              );
              this.bsModalRef.hide();
            },
            (error) => {
              console.error(error);
              this.subscriptions.push(
                this.translate.get('notify.user.user-updated-error').subscribe((res) => {
                  this.notifyService.error(res);
                })
              );
            }
          )
        );
      }
    }
  }

  savePicture() {
    this.allowSaveButton = false;

    const initialState = {
      type: 'user',
      fileType: 'images',
      aspectRatio: 4 / 3,
      minWidth: 200,
      maxWidth: 512,
      minHeight: 200,
      maxHeight: 512,
      resizeWidth: 512
    };

    const BsModalRef = this.modalService.show(UploadFileDialogComponent, {
      class: 'modal-lg amp-modal',
      initialState
    });

    BsModalRef.content.result.subscribe(
      (data) => {
        if (data) {
          this.user.picture = data;
          this.allowSaveButton = true;
          this.notifyService.success('Foto alterada com sucesso!');
        } else {
          this.notifyService.error('Houve um erro ao alterar a foto.');
        }
      },
      (error) => {
        this.notifyService.error('Houve um erro ao alterar a foto.');
        console.error(error);
      }
    );
  }

  delete() {
    this.mixPanel.track('Delete User');
    this.subscriptions.push(
      this.userService.delete(this.user._id).subscribe(
        () => {
          this.notifyService.success('Foto removida com sucesso!');
          this.bsModalRef.hide();
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Houve um erro ao remover a foto.');
        }
      )
    );
  }

  async toggleItems(_id: string) {
    const index = await this.items.findIndex((value) => {
      if (value == _id) return true;
    });
    if (index > -1) {
      this.items.splice(index, 1);
    } else {
      this.items.push(_id);
    }
  }

  async selectAll() {
    if (this.selectedAll) {
      this.items = [];
      this.selectedAll = false;
    } else {
      this.items = this.businesses.map((b) => b._id);
      this.selectedAll = true;
    }
  }
}
