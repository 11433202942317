import { DeleteDialogComponent } from './../../_dialogs/delete-dialog/delete-dialog.component';
import { RequestQueueService } from 'app/_services/requestQueue.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotifyService } from '../../_services/notify.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from '../../_services/menu.service';
import { StoreService } from 'app/_services/store.service';
import { AuthenticationService } from 'app/_services';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
@Component({
  selector: 'app-queue-log',
  templateUrl: './queue-log.component.html',
  styleUrls: ['./queue-log.component.scss']
})
export class QueueLogComponent implements OnInit, OnDestroy {
  constructor(
    private store: StoreService,
    private route: ActivatedRoute,
    private menuService: MenuService,
    public auth: AuthenticationService,
    public notifyService: NotifyService,
    private modalService: BsModalService,
    private requestQueue: RequestQueueService
  ) {
    this.menuService.emit(true);
    this.menuService.emitSidebar(true);
    this.menuService.emitPageTitle('Fila de Envios');

    this.flagFirst = this.route.snapshot.queryParams['first'] == 'true';

    if (this.flagFirst) {
      this.setupInterval();
    }
  }

  public page = 1;
  public interval;
  public limit = 20;
  public queues = [];
  public totalItems = 0;
  public loading = false;
  public searchInput = '';
  public adminMode = false;
  public flagFirst = false;
  public subscriptions = [];
  public paginationPage = 1;
  public filterTime = 'thismonth';
  public dateSubject: Subject<Date[]> = new Subject<Date[]>();
  public filterSubject: Subject<string> = new Subject<string>();
  public dateFilter: any = [moment().startOf('month').toDate(), moment().endOf('month').toDate()];

  ngOnInit() {
    if (this.store.business._id) {
      this.getRequestQueues();
    }

    this.filterSubject
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.getRequestQueues();
      });

    this.dateSubject.subscribe(() => {
      this.getRequestQueues();
    });

    this.subscriptions.push(
      this.menuService.getChangeEmmiterBusiness().subscribe(() => {
        this.getRequestQueues();
      })
    );
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  checkMinTime(item) {
    if (moment(item.queue.schedule_params?.scheduled_to).diff(moment(), 'm') > 10) {
      return true;
    }
  }

  onKeyUp() {
    this.filterSubject.next(this.searchInput);
  }

  getRequestQueues(page = 1) {
    this.loading = true;
    this.page = page;
    if (this.adminMode) {
      this.queues = [];
      this.subscriptions.push(
        this.requestQueue.getAllForAdmin(this.limit, this.page, this.dateFilter).subscribe((data) => {
          this.loading = false;
          this.queues = data.data;
          this.totalItems = data.total;
          if (this.flagFirst && this.interval != null) {
            this.checkIntervalToDisable();
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.requestQueue.getAll(this.store.business._id, this.limit, this.page, this.dateFilter, this.searchInput).subscribe((data) => {
          this.queues = [];
          this.loading = false;
          this.queues = data.data;
          this.totalItems = data.total;
          if (this.flagFirst && this.interval != null) {
            this.checkIntervalToDisable();
          } else if (data.data[0].percentageStatus != 1 && data.data[0].queue.schedule_params.type == 'default') {
            if (this.interval == null) {
              this.setupInterval();
            }
          }
        })
      );
    }
  }

  cancelSending(item, event) {
    event.stopPropagation();
    event.preventDefault();
    const initialState = {
      title: 'Cancelar envio',
      message: 'Tem certeza que deseja cancelar este envio?',
      strongText: '',
      messageEnd: '',
      forceConfirmation: false
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.requestQueue.deleteScheduled(item._id).subscribe({
            next: () => {
              this.notifyService.success('Envio cancelado com sucesso!');
              this.getRequestQueues();
            },
            error: (error) => {
              console.error(error);
              this.notifyService.error('Houve um erro ao cancelar o envio!');
            }
          });
        }
      })
    );
  }

  checkIntervalToDisable() {
    if (this.queues[0].percentageStatus == 1 || !this.queues[0]?.scheduled) {
      clearInterval(this.interval);
      this.interval = null;
      this.flagFirst = false;
    }
  }

  setupInterval() {
    this.interval = setInterval(() => this.getRequestQueues(), 5000);
  }
}
