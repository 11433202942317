import { NotifyService } from 'app/_services';
import { MenuService } from 'app/_services/menu.service';
import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services/store.service';
import { Template } from 'app/_models/adm/template';
import { TemplateService } from 'app/_services/adm/template.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-template-create-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss']
})
export class TemplateDialogComponent implements OnDestroy {
  public template = new Template();
  public isNew = true;
  public isClone = false;
  public subscriptions = [];

  constructor(
    public bsModalRef: BsModalRef,
    private menuService: MenuService,
    private router: Router,
    private templateService: TemplateService,
    private notifyService: NotifyService,
    public store: StoreService
  ) {
    this.menuService.emit(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  fmSubmit() {
    if (this.isNew) {
      this.template._id = undefined;
      this.template.created_at = undefined;
      this.template.uses = undefined;
      this.isNew = false;
      this.subscriptions.push(
        this.templateService.create(this.template).subscribe((template) => {
          if (this.template.type == 'nps') {
            if (this.isClone) {
              this.notifyService.success('Modelo criado com sucesso!');
            }
          } else {
            if (this.isClone) {
              this.notifyService.success('Modelo criado com sucesso!');
            } else {
              if (this.template.subtype == 'referral') {
                this.router.navigate(['referral/classic'], {
                  queryParams: {
                    template: template._id,
                    isTemplate: true,
                    isNew: true
                  }
                });
              } else {
                this.router.navigate(['referral/milestone'], {
                  queryParams: {
                    template: template._id,
                    isTemplate: true,
                    isNew: true
                  }
                });
              }
            }
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.templateService.update(this.template).subscribe((template) => {
          if (this.template.subtype == 'referral') {
            this.router.navigate(['referral/classic'], {
              queryParams: {
                template: template._id,
                isTemplate: true,
                isNew: false
              }
            });
          } else {
            this.router.navigate(['referral/milestone'], {
              queryParams: {
                template: template._id,
                isTemplate: true,
                isNew: false
              }
            });
          }
        })
      );
    }

    this.bsModalRef.hide();
  }

  openIcons() {
    window.open('https://s3-sa-east-1.amazonaws.com/amplifiqueme/icons-page/demo.html', '_blank', 'noopener');
  }
}
