<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">Clonar Pesquisa</div>
    <div class="amp-dialog-subtitle">Selecione o(s) negócio(s) onde a campanha será clonada.</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body" *ngIf="authService.userRole === 'acmng'">
    <amp-select [options]="empresas" [(value)]="selectedEmpresa" (valueChange)="onChangeEmpresa()" [isSearchable]="true" placeholder="Escolha a Organização/Empresa"></amp-select>

    <div class="amp-select-all">
      <label class="amp-checkbox">
        <input type="checkbox" [checked]="selectedAll" (change)="selectAll()">
        <span class="text">Selecionar Todas</span>
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="amp-survey-list">
      <label class="amp-checkbox" *ngFor="let item of businesses">
        <div class="amp-option">
          <input type="checkbox" [checked]="selected.includes(item._id)" (change)="toggleBusiness(item._id)">
          <span class="amp-option-text">{{ item.nome }}</span>
          <span class="checkmark"></span>
        </div>
      </label>
    </div>

    <button [disabled]="!selected.length" class="amp-btn-success amp-btn-lg" (click)="confirmar()">Clonar</button>
  </div>



  <div class="amp-dialog-body" *ngIf="authService.userRole !== 'acmng'">
    <div class="amp-select-all">
      <label class="amp-checkbox">
        <input type="checkbox" [checked]="selectedAll" (change)="selectAll()">
        <span class="text">Selecionar Todas</span>
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="amp-survey-list">
      <label class="amp-checkbox" *ngFor="let item of businesses">
        <div class="amp-option">
          <input type="checkbox" [checked]="selected.includes(item._id)" (change)="toggleBusiness(item._id)">
          <span class="amp-option-text">{{ item.nome }}</span>
          <span class="checkmark"></span>
        </div>
      </label>
    </div>

    <button [disabled]="!selected.length" class="amp-btn-success amp-btn-lg" (click)="confirmar()">Clonar</button>
  </div>
</div>
