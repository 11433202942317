import { TransformDurationPipe } from './transformDuration.pipe';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { NumberFormatterPipe } from './numberFormatter.pipe';
import { AmpInterpolation } from './interpolation.pipe';
import { UrlStringifyPipe } from './urlStringify.pipe';
import { AmpCustomFields } from './customFields.pipe';
import { AmplTemplatePipe } from './templates.pipe';
import { TagRemoverPipe } from './tag-remover.pipe';
import { NameInitials } from './nameInitials.pipe';
import { AmpAdmCompany } from './adm-company.pipe';
import { PrettyJsonPipe } from './prettyjson.pipe';
import { DateRangePipe } from './dateRange.pipe';
import { FileSizePipe } from './file-size.pipe';
import { CommonModule } from '@angular/common';
import { ProtocolPipe } from './protocol.pipe';
import { ReversePipe } from './reverse.pipe';
import { FilterPipe } from './filter.pipe';
import { PhonePipe } from './phone.pipe';
import { NgModule } from '@angular/core';
import { RolePipe } from './role.pipe';
import { LgpdPipe } from './lgpd.pipe';
import { CNPJPipe } from './cnpj.pipe';
import { CEPPipe } from './cep.pipe';
@NgModule({
  declarations: [
    CEPPipe,
    RolePipe,
    LgpdPipe,
    CNPJPipe,
    PhonePipe,
    FilterPipe,
    ReversePipe,
    NameInitials,
    ProtocolPipe,
    FileSizePipe,
    AmpAdmCompany,
    DateRangePipe,
    TagRemoverPipe,
    PrettyJsonPipe,
    AmpCustomFields,
    UrlStringifyPipe,
    AmplTemplatePipe,
    AmpInterpolation,
    NumberFormatterPipe,
    HighlightSearchPipe,
    TransformDurationPipe
  ],
  imports: [CommonModule],
  exports: [
    CEPPipe,
    RolePipe,
    LgpdPipe,
    CNPJPipe,
    PhonePipe,
    FilterPipe,
    ReversePipe,
    NameInitials,
    FileSizePipe,
    ProtocolPipe,
    AmpAdmCompany,
    DateRangePipe,
    TagRemoverPipe,
    PrettyJsonPipe,
    AmpCustomFields,
    UrlStringifyPipe,
    AmplTemplatePipe,
    AmpInterpolation,
    NumberFormatterPipe,
    HighlightSearchPipe,
    TransformDurationPipe
  ],
  providers: [FilterPipe]
})
export class MainPipe {}
