import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { NotifyService } from './../../_services/notify.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from 'app/_services/storage.service';
import { MenuService } from 'app/_services/menu.service';
import { StoreService } from 'app/_services';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent {
  constructor(
    public bsModalRef: BsModalRef,
    private storageService: StorageService,
    private notifyService: NotifyService,
    public menuService: MenuService,
    public store: StoreService,
    private modalService: BsModalService
  ) {}

  public subscriptions = [];
  public file;
  public importName = '';
  public uploadProgress = 0;
  public isDraggingOver = false;
  public fileType;
  public showSkipButton = true;
  public result: Subject<any> = new Subject();
  public uploadedFile;
  public aspectRatio;
  public minWidth;
  public maxWidth;
  public minHeight;
  public maxHeight;
  public resizeWidth;
  public type;
  public isLoading = false;

  getResolution(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = function (e) {
        const image = new Image();

        // @ts-ignore
        image.src = e.target.result;

        image.onload = function () {
          const height = image.height;
          const width = image.width;
          return resolve([width, height]);
        };
      };
    });
  }

  async checkAndUploadFile(file: File) {
    this.isLoading = true;
    this.uploadedFile = file;
    if (this.fileType == 'images') {
      const resolution = await this.getResolution(file);
      if (
        !file.name?.includes('.jpg') &&
        !file.name?.includes('.png') &&
        !file.name?.includes('.jpeg') &&
        !file.type?.includes('image/png') &&
        !file.type?.includes('image/jpg') &&
        !file.type?.includes('image/jpeg')
      ) {
        this.notifyService.warning('O formato do arquivo precisa ser JPG, JPEG ou PNG!');
        this.isLoading = false;
        return;
      } else if (file.size > 5242880) {
        this.isLoading = false;
        this.notifyService.warning('O arquivo precisa ter no máximo 5 megabytes!');
        return;
      } else if (resolution[0] > 10240 || resolution[1] > 4320) {
        this.isLoading = false;
        this.notifyService.warning('A imagem precisa ser no máximo 10240x4320!');
        return;
      } else if (resolution[0] < 150 || resolution[1] < 50) {
        this.isLoading = false;
        this.notifyService.warning('A imagem precisa ser no mínimo 150x50!');
        return;
      }

      if (this.type == 'cover') {
        this.isLoading = false;
        this.openCropper(file, 'cover');
      } else if (this.type == 'user') {
        this.isLoading = false;
        this.openCropper(file, 'user');
      } else {
        this.isLoading = false;
        this.processFile(file);
      }
    } else if (this.fileType == 'imports') {
      if (!file.name?.includes('.csv') && !file.type.includes('text/csv')) {
        this.isLoading = false;
        this.notifyService.warning('O formato do arquivo precisa ser CSV!');
        return;
      } else if (file.size > 20 * 1024 * 1024) {
        this.isLoading = false;
        this.notifyService.warning('O arquivo enviado é muito grande!');
        return;
      } else {
        this.isLoading = false;
        this.processFile(file);
      }
    } else if (this.fileType == 'importSicredi') {
      if (!file.name?.includes('.xlsx') && !file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        this.isLoading = false;
        this.notifyService.warning('O formato do arquivo precisa ser XLSX!');
        return;
      } else {
        this.isLoading = false;
        this.processFile(file);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDraggingOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDraggingOver = false;
  }

  onDrop(): void {
    this.isDraggingOver = false;
  }

  openCropper(file, type) {
    const initialState = {
      showSkipButton: this.showSkipButton,
      uploadedFile: file,
      aspectRatio: type == 'user' ? 1 / 1 : type == 'cover' ? 600 / 160 : 16 / 9,
      maintainAspectRatio: true,
      type: type
    };

    const BsModalRef = this.modalService.show(ImageCropperDialogComponent, {
      class: 'modal-lg amp-modal',
      initialState
    });

    BsModalRef.content.result.subscribe((data) => {
      if (data) {
        if (data[0] == 'skip') {
          this.processFile(file);
        } else if (data[0] == 'cropped') {
          const image = this.renderImage(data[1], file.name);
          this.processFile(image);
        }
      } else {
        this.notifyService.error('Erro!');
      }
    });
  }

  renderImage(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);

    let n = bstr.length;

    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  processFile(file) {
    this.subscriptions.push(
      this.storageService.getSignedUploadUrl(this.fileType).subscribe((urlData) => {
        this.subscriptions.push(
          this.storageService.upload(urlData.upload_url, urlData.key, file).subscribe(
            (event) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.uploadProgress = Math.round((100 * event.loaded) / event.total);
              } else if (event instanceof HttpResponse) {
                this.file = urlData.url;
                this.importName = file.name;
                this.result.next(this.file);
                this.bsModalRef.hide();
              }
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Houve um erro ao enviar o arquivo.');
              this.uploadProgress = 0;
            }
          )
        );
      })
    );
  }
}
