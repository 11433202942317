import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MenuService } from 'app/_services/menu.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'amp-secondary-bar',
  templateUrl: './secondary-bar.component.html',
  styleUrls: ['./secondary-bar.component.scss']
})
export class SecondaryBarComponent implements OnInit {
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Input() public show;

  public homolog = environment.homolog ?? false;

  constructor(public store: StoreService, public authService: AuthenticationService, private localeService: BsLocaleService, public menuService: MenuService) {}

  ngOnInit() {
    this.show = null;
    this.localeService.use('pt-br');
  }

  onClose() {
    this.close.emit();
  }
}
