<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Criar Link
    </div>

    <div class="amp-dialog-subtitle">Informe o Link e o Texto</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-field">
      <label class="amp-label-text" for="url">URL</label>
      <input type="text" class="amp-input-lg" autocomplete="off" [(ngModel)]="url" placeholder="URL" name="url"
        required>
    </div>

    <div class="amp-field">
      <label class="amp-label-text" for="text">Texto do Link</label>
      <input type="text" class="amp-input-lg" autocomplete="off" [(ngModel)]="text" placeholder="Texto do link"
        name="text" required>
    </div>

    <button class="amp-btn-success amp-btn-lg" [disabled]="text == '' || url == ''" (click)="confirmar()">Confirmar</button>
    <button class="amp-btn-outline-cancel amp-btn-lg" (click)="cancelar()">Cancelar</button>
  </div>
</div>
