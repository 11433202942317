import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'amp-select',
  templateUrl: 'amp-select.component.html',
  styleUrls: ['amp-select.component.scss']
})
export class AmpSelectComponent implements OnInit, OnDestroy {
  @Input() icon = '';
  @Input() showArrow = true;
  @Input() dashed = false;
  @Input() show: Subject<string>;
  @Input() isSearchable = false;
  @Input() showPopover = false;
  @Input() small = false;
  @Input() showClearInput = false;
  @Input() placeholder = '';
  @Input() width = undefined;
  @Input() breakAll = false;
  @Input() disabled = false;
  @Input() groupType = 'prefix';
  @Input() autoResize = false;
  @Input() hiddenSelected = false;
  @Input() actions: { name: string; label: string; icon: string }[] = [];
  @Input() displayBy = 'label';
  @Input() identifyBy = '_id';
  @Input() optionsObservable: (text: string) => Observable<any>;
  @Input() saveBothFields = false;

  @Output() actionsClick = new EventEmitter();

  @Output() onBlur = new EventEmitter<void>();

  // @Output() focus = new EventEmitter();

  private _options = [];

  public filterValue = '';
  public showDropdown = false;
  public optionsLoading = false;
  public filterSubject: Subject<string> = new Subject();

  @Input()
  set options(options) {
    this._options = options;
    this.applyFilter();
    if (this.value) {
      const opt = this.options.find((option) => option.value === this.value);
      if (opt) {
        this.filterValue = opt[this.displayBy];
      } else {
        if (this.saveBothFields) {
          this.filterValue = this.value[this.displayBy];
        }
      }
    }
  }

  get options() {
    return this._options;
  }

  optionsFiltered = [];

  private _value = '';
  public hasValue = false;

  @Input()
  set value(value: any) {
    this._value = value;
    if (this.value && !this.optionsObservable) {
      const opt = this.options.find((option) => option.value === this.value);
      if (opt) {
        this.filterValue = opt[this.displayBy];
      }
    } else {
      if (this.value && this.options?.length) {
        const opt = this.options.find((option) => option.value === this.value);
        if (opt) {
          this.filterValue = opt[this.displayBy];
        } else {
          if (this.saveBothFields) {
            this.filterValue = this.value[this.displayBy];
          }
        }
      } else if (this.value != '') {
        if (this.saveBothFields) {
          this.filterValue = this.value[this.displayBy];
        } else {
          this.filterValue = this.value?.toString();
        }
      }
    }
    this.applyFilter();
  }

  get value() {
    return this._value;
  }

  @Output() valueChange = new Subject();

  ngOnInit() {
    this.applyFilter();
    this.filterSubject
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.applyFilter();
      });

    if (this.show) {
      this.show.subscribe(() => {
        this.onFocus();
      });
    }
  }

  ngOnDestroy() {
    this.filterSubject.unsubscribe();
  }

  handleBlur() {
    setTimeout(() => {
      this.onBlur.emit();
    }, 100);
  }

  applyFilter() {
    if (this.optionsObservable && this.filterValue?.length > 0) {
      this.optionsLoading = true;
      this.optionsObservable(this.filterValue).subscribe((options) => {
        this.optionsFiltered = options;
        this._options = options;
        this.optionsLoading = false;
        if (this.value) {
          const opt = this.options.find((option) => option.value === this.value);
          if (opt) {
            this.filterValue = opt[this.displayBy];
          }
        }
      });
    } else {
      if (this.filterValue?.length > 0) {
        this.optionsFiltered = this.options.filter((option) => option[this.displayBy].toLowerCase().includes(this.filterValue.toLowerCase()));
      } else {
        this.optionsFiltered = [...this.options];
        if (this.value) {
          const opt = this.options.find((option) => option.value === this.value);
          if (opt) {
            this.filterValue = opt[this.displayBy];
          }
        }
      }
    }
  }

  clearInput() {
    this.value = undefined;
    this.valueChange.next(this.value);
  }

  onFocus() {
    this.showDropdown = true;
    this.filterValue = '';
    this.applyFilter();
    // this.filterValue = '';
  }

  onKeyUp(event) {
    this.filterSubject.next(event.target.value);
  }

  onClickOutside() {
    this.showDropdown = false;
    if (this.value) {
      const opt = this.options.find((option) => option.value === this.value);
      if (opt) {
        this.filterValue = opt[this.displayBy];
      }
    }
  }

  selectOption(event, option) {
    event.stopPropagation();

    this.showDropdown = false;
    this.filterValue = option[this.displayBy];

    if (this.optionsObservable) {
      this._value = this.saveBothFields
        ? {
            [this.identifyBy]: option.value,
            [this.displayBy]: option[this.displayBy]
          }
        : option.value;
    } else {
      this.value = this.saveBothFields
        ? {
            [this.identifyBy]: option.value,
            [this.displayBy]: option[this.displayBy]
          }
        : option.value;
    }

    this.valueChange.next(this.value);

    if (this.hiddenSelected) {
      this.value = '';
      this.filterValue = '';
    }
  }

  onActionClick(action) {
    this.actionsClick.emit(action);
  }
}
