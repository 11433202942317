import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuService } from 'app/_services/menu.service';
import { RequestQueueService } from 'app/_services/requestQueue.service';
import countBy from 'lodash-es/countBy';
import orderBy from 'lodash-es/orderBy';
@Component({
  selector: 'app-queue-log-detailed',
  templateUrl: 'queue-log-detailed.component.html',
  styleUrls: ['queue-log-detailed.component.scss']
})
export class QueueLogDetailedComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private requestQueueService: RequestQueueService, private menuService: MenuService) {}

  public limit = 50;
  public pageNumber = 0;
  public loading = false;
  public queueItems = [];
  public queueGroups = [];
  public queueItemsPage = [];
  private subscriptions = [];
  private requestQueueId = '';

  ngOnInit() {
    this.menuService.emit(true);
    this.menuService.emitPageTitle('Detalhes da Fila');
    this.requestQueueId = this.route.snapshot.params['id'];
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  public loadData() {
    this.loading = true;
    this.subscriptions.push(
      this.requestQueueService.getQueueItems(this.requestQueueId).subscribe((data) => {
        this.queueItems = data;
        this.queueGroups = [];
        const tempGroups = countBy(this.queueItems, (item) => item.response + '|' + item.responseReason);
        for (const item of Object.keys(tempGroups)) {
          this.queueGroups.push({ name: item, count: tempGroups[item] });
        }
        this.queueGroups = orderBy(this.queueGroups, 'count', 'desc');
        this.loading = false;
        this.changePage(1);
      })
    );
  }

  changePage(page) {
    this.pageNumber = page;
    this.queueItemsPage = this.queueItems.slice((this.pageNumber - 1) * this.limit, this.pageNumber * this.limit);
  }
}
