<i class="amp-close-dialog-button bi-x-lg" (click)="bsModalRef.hide()"></i>

<div class="amp-dialog">
  <p class="title" *ngIf="isNew">{{ 'usuarios.dialog.title1' | translate }}</p>
  <p class="amp-dialog-subtitle" *ngIf="isNew">{{ 'usuarios.dialog.subtitle1' | translate }}</p>
  <p class="title" *ngIf="!isNew">{{ 'usuarios.dialog.title2' | translate }}</p>
  <p class="amp-dialog-subtitle" *ngIf="!isNew">{{ 'usuarios.dialog.subtitle2' | translate: { nome: user.nome } }}</p>

  <form (ngSubmit)="f.form.valid && save()" #f="ngForm">
    <div class="amp-user-picture" *ngIf="!isNew">
      <div dropdown class="amp-profile-dropdown" [dropup]="true">
        <a id="pictureDropdown" href dropdownToggle (click)="(false)" aria-controls="picture-dropdown" *ngIf="user.picture && stepAuthenticator == 0">
          <img [src]="user.picture" class="img" />
          <div class="img-upload"><span class="bi-pencil"></span></div>
        </a>
        <a id="pictureDropdown" href dropdownToggle (click)="(false)" aria-controls="picture-dropdown" class="img" *ngIf="!user.picture">{{ user.nome[0] }}
          <div class="img-upload"><span class="bi-pencil"></span></div>
        </a>

        <div class="dropdown-menu" id="picture-dropdown" *dropdownMenu role="menu" aria-labelledby="pictureDropdown">
          <div class="options">
            <a (click)="user.picture = null">{{ 'usuarios.dialog.removePicture' | translate }}</a>
            <a (click)="savePicture()">{{ 'usuarios.dialog.changePicture' | translate }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="amp-field">
      <label class="amp-label-text" for="nome">{{ 'usuarios.dialog.nameField' | translate }}</label>
      <input type="text" class="amp-input" [(ngModel)]="user.nome" [placeholder]="'usuarios.dialog.nameFieldPlaceholder' | translate" #nome="ngModel" name="nome" nome required />
    </div>

    <div class="amp-field">
      <label class="amp-label-text" for="email">{{  store.empresaData.auth_source != 'msal' ? ('usuarios.dialog.emailField' | translate) : 'E-mail (Domínio: @' + store.empresaData.auth_domain + ')' }}</label>
      <input type="text" class="amp-input" [(ngModel)]="user.email" [placeholder]="'usuarios.dialog.emailFieldPlaceholder' | translate" (keyup)="validateEmail($event)" #email="ngModel" name="email" email required [disabled]="!isNew" />
    </div>

    <div class="amp-field" *ngIf="!isProfileEdit">
      <label class="amp-label-text" for="phone">Celular</label>
      <amp-phone-input [(phone)]="user.phone" ></amp-phone-input>
    </div>

    <div class="amp-field" *ngIf="!isProfileEdit">
      <label class="amp-label-text" for="role">{{ 'usuarios.dialog.nivelField' | translate }}</label>
      <select class="amp-input" [(ngModel)]="user.role" placeholder="Selecione o nível" #role="ngModel" name="role" role required>
        <option *ngFor="let role of roles" [value]="role.value">{{ role.name }}</option>
      </select>
    </div>

    <div class="amp-field" *ngIf="!isProfileEdit && store.empresaData.general_attributes?.allow_ticketing">
      <label class="amp-label-text" for="ticketingRole">Nível no Fechamento de Loop</label>
      <select class="amp-input" [(ngModel)]="user.ticketing_role" placeholder="Selecione o nível na ferramenta de Fechamento de Loop" #role="ngModel" name="ticketingRole" role>
        <option *ngFor="let role of ticketing_roles" [value]="role.value">{{ role.name }}</option>
      </select>
    </div>

    <div class="amp-switch-wrapper">
      <div class="amp-field" *ngIf="!isProfileEdit">
        <label class="amp-label-text" for="role">Receber Relatório Semanal</label>
        <input id="amp-switch-weekly-report" [(ngModel)]="user.weekly_report" name="weekly" type="checkbox" class="amp-switch" />
        <label for="amp-switch-weekly-report" class="amp-switch-label"></label>
        <label for="amp-switch-weekly-report" class="amp-switch-weekly" *ngIf="user.weekly_report">Ativado</label>
        <label for="amp-switch-weekly-report" class="amp-switch-weekly" *ngIf="!user.weekly_report">Desativado</label>
      </div>

      <div class="amp-field" *ngIf="!isProfileEdit && store.userData?.role == 'acmng'" [ngStyle]="{'cursor': store.empresaData.general_attributes.can_manage_balance ? '' : 'not-allowed'}">
        <label class="amp-label-text" [ngStyle]="{'cursor': store.empresaData.general_attributes.can_manage_balance ? '' : 'not-allowed'}" for="role">Permitir Gerenciar Saldos</label>
        <input id="amp-switch-manage-balance" [ngStyle]="{'cursor': store.empresaData.general_attributes.can_manage_balance ? '' : 'not-allowed'}" [disabled]="!store.empresaData.general_attributes.can_manage_balance" [(ngModel)]="user.permissions.can_manage_balance" name="balance" type="checkbox" class="amp-switch" />
        <label for="amp-switch-manage-balance" [ngStyle]="{'cursor': store.empresaData.general_attributes.can_manage_balance ? '' : 'not-allowed'}" class="amp-switch-label"></label>
        <label for="amp-switch-manage-balance" [ngStyle]="{'cursor': store.empresaData.general_attributes.can_manage_balance ? '' : 'not-allowed'}" class="amp-switch-weekly" *ngIf="user.permissions.can_manage_balance">Ativado</label>
        <label for="amp-switch-manage-balance" [ngStyle]="{'cursor': store.empresaData.general_attributes.can_manage_balance ? '' : 'not-allowed'}" class="amp-switch-weekly" *ngIf="!user.permissions.can_manage_balance">Desativado</label>
      </div>
    </div>

    <div *ngIf="user.role == 'acmng' || user.role == 'own' || user.role == 'adm'" class="amp-field">
      <label class="amp-label-text" for="role">Receber Notificações de Saldo</label>
      <input id="amp-switch-status-notifications" name="notificationSwitch" [(ngModel)]="user.historyBalance_notifications" type="checkbox" class="amp-switch" />
      <label for="amp-switch-status-notifications" class="amp-switch-label"></label>
      <label for="amp-switch-status-notifications" class="amp-switch-status-text" *ngIf="!user.historyBalance_notifications">Desativado</label>
      <label for="amp-switch-status-notifications" class="amp-switch-status-text" *ngIf="user.historyBalance_notifications">Ativado</label>
    </div>

    <div class="amp-options">
      <div class="amp-select-business">
        <div class="amp-select-business-header">
          <label class="amp-checkbox">
            <input type="checkbox" [checked]="selectedAll" (change)="selectAll()">
            <span class="text">Selecionar Todos</span>
            <span class="checkmark"></span>
          </label>
          <div class="selected-custom-fields-amount" *ngIf="items.length">
            {{ 'unidade' | translate : { count: items.length } }} {{ 'selecionada' | translate : { count: items.length } }}
          </div>
        </div>
        <div class="amp-select-business-content">
          <label class="amp-checkbox" *ngFor="let business of businesses">
            <input type="checkbox" [checked]="items.indexOf(business._id) > -1" (change)="toggleItems(business._id)">
            <span class="text">{{ business.nome }}</span>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="amp-dialog-actions">
      <button type="submit" class="amp-btn-success" *ngIf="isNew" [disabled]="!allowSaveButton || !f.form.valid || !items.length">
        {{ 'usuarios.dialog.inviteButton' | translate }}
      </button>
      <button type="submit" class="amp-btn-success" *ngIf="!isNew" [disabled]="!allowSaveButton || !f.form.valid">
        {{ 'usuarios.dialog.saveButton' | translate }}
      </button>
    </div>
  </form>
</div>
