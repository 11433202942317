import { NotifyService } from 'app/_services';
import { User } from '../../_models/user';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services/store.service';
import { NewTagDialogComponent } from '../new-tag-dialog/new-tag-dialog.component';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { SmartTagService } from 'app/_services/smartTags/smartTags.service';
import { SmartTag } from 'app/_models/smartCluster/smartTags';
import { InfoDialogComponent } from 'app/_dialogs/info-dialog/info-dialog.component';
@Component({
  selector: 'app-tag-manager-dialog',
  templateUrl: './tag-manager-dialog.component.html',
  styleUrls: ['./tag-manager-dialog.component.scss']
})
export class TagManagerDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    private notifyService: NotifyService,
    private modalService: BsModalService,
    private smartTagService: SmartTagService,
    private store: StoreService,
    private bsmodalService: BsModalService
  ) {}

  public user: User = new User();
  public isNew = true;
  public isProfileEdit = false;
  public allowSaveButton = true;
  public tag;
  public smartTags: SmartTag[] = [];
  public subscriptions = [];
  public tagIndexSelected = 0;
  public closeModalSub;
  public keywordsString;

  ngOnInit() {
    this.loadSmartTags();

    this.closeModalSub = this.subscriptions.push(
      this.bsmodalService.onHide.subscribe(() => {
        this.loadSmartTags();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadSmartTags() {
    this.subscriptions.push(
      this.smartTagService.getSmartTags(this.store.empresa).subscribe((data) => {
        this.smartTags = data.result;
        this.tagIndexSelected = 0;
        this.keywordsString = this.smartTags[0].keywords.join(',');
      })
    );
  }

  openCreateNewTag() {
    this.saveTag();
    this.modalService.show(NewTagDialogComponent, { class: 'amp-modal' });
  }

  selectTag(i) {
    if (this.keywordsString.includes(',')) {
      this.smartTags[this.tagIndexSelected].keywords = this.keywordsString.split(',');
    } else {
      this.smartTags[this.tagIndexSelected].keywords = [this.keywordsString];
    }

    this.tagIndexSelected = i;
    this.keywordsString = this.smartTags[this.tagIndexSelected].keywords.join(',');
  }

  saveTag() {
    if (this.keywordsString) {
      if (this.keywordsString.includes(',')) {
        this.smartTags[this.tagIndexSelected].keywords = this.keywordsString.split(',');
      } else {
        this.smartTags[this.tagIndexSelected].keywords = [this.keywordsString];
      }
      this.subscriptions.push(this.smartTagService.updateBatchSmartTag(this.smartTags).subscribe(() => {}));
    }
  }

  validateKeywords(evt) {
    evt.srcElement.value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  updateTag() {
    if (this.keywordsString) {
      if (this.keywordsString.includes(',')) {
        this.smartTags[this.tagIndexSelected].keywords = this.keywordsString.split(',');
      } else {
        this.smartTags[this.tagIndexSelected].keywords = [this.keywordsString];
      }

      this.subscriptions.push(
        this.smartTagService.updateBatchSmartTag(this.smartTags).subscribe(() => {
          this.bsModalRef.hide();
        })
      );

      const initialState = {
        title: 'Alteração de Tags',
        message: 'Como você realizou alterações nas tags, as respostas das pesquisas serão reanalisadas em ',
        strongText: 'até 30 minutos.',
        messageEnd: ''
      };

      this.modalService.show(InfoDialogComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        initialState,
        class: 'modal-lg amp-modal'
      });

      this.notifyService.success('Tags salvas com sucesso!');
    } else {
      this.bsModalRef.hide();
    }
  }

  renameTag() {
    const initialState = {
      isNew: false,
      smartTag: this.smartTags[this.tagIndexSelected]
    };

    this.modalService.show(NewTagDialogComponent, {
      initialState,
      class: 'amp-modal'
    });
  }

  deleteTag() {
    const initialState = {
      title: 'Remover Tag',
      message: 'Tem certeza que deseja remover a tag',
      strongText: this.smartTags[this.tagIndexSelected].name,
      messageEnd: ' e todas as suas palavras-chave?'
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.smartTagService.deleteSmartTag(this.smartTags[this.tagIndexSelected]).subscribe(() => {
              this.smartTags.splice(this.tagIndexSelected, 1);
              this.tagIndexSelected = -1;
            })
          );
          this.notifyService.success('Tag deletada com sucesso!');
        }
      })
    );
  }
}
