import { ConfirmDialogComponent } from 'app/_dialogs/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class UpdateService {
  constructor(public updates: SwUpdate, public router: Router, private modalService: BsModalService) {
    if (updates.isEnabled) {
      interval(60 * 1000).subscribe(() => updates.checkForUpdate().then(() => {}));
    }
  }

  public checkForUpdates() {
    this.updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion
        }))
      )
      .subscribe(() => {
        this.showDialog();
      });
  }

  private showDialog() {
    this.updates.activateUpdate().then(() => {
      const initialState = {
        title: 'Atualização Disponível',
        buttonName: 'Atualizar',
        cancelName: 'Mais tarde'
      };

      const modal = this.modalService.show(ConfirmDialogComponent, { class: 'amp-modal', ignoreBackdropClick: true, initialState });
      modal.content.result.subscribe((result) => {
        if (result) {
          document.location.reload();
        }
      });
    });
  }
}
