import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from 'libphonenumber-js';
@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      if (value.includes('(')) {
        return value;
      } else {
        if (args) {
          return parse(value)?.country?.toLowerCase();
        }
        return format(value, 'INTERNATIONAL');
      }
    }
    return null;
  }
}
