<div class="amp-dialog">
  <div class="amp-dialog-header">
    <i class="bi-exclamation-triangle-fill"></i>
    <span class="amp-dialog-title">Pendências</span>
  </div>
  <div class="amp-dialog-body">
    <div class="amp-errors" *ngFor="let error of errorList">
      <span *ngIf="error.message == 'NUMBER_GT_POSSIBLE'" class="amp-error">Os campos <b>"{{error.fieldReadable}}"</b> estão incorretos na etapa {{error.fieldLocation}}.</span>
      <span *ngIf="error.message == 'DUPLICATED_ENTRY'" class="amp-error">O campo <b>"{{error.fieldReadable}}"</b> está duplicado na etapa {{error.fieldLocation}}.</span>
      <span *ngIf="error.message == 'EMPTY_ERROR'" class="amp-error">O campo <b>"{{error.fieldReadable}}"</b> está vazio na etapa {{error.fieldLocation}}.</span>
      <span *ngIf="error.message == 'MATRIX_EMPTY'" class="amp-error">{{ error.fieldReadable }}</span>
      <span *ngIf="error.message == 'MATRIX_ROW_EMPTY'" class="amp-error">{{ error.fieldReadable }}</span>
      <span *ngIf="error.message == 'MATRIX_ROW_DUPLICATED'" class="amp-error">{{ error.fieldReadable }}</span>
      <span *ngIf="error.message == 'SMART_FOLLOW_UP_EMPTY'" class="amp-error">O campo <b>"{{ error.fieldReadable }}"</b> no Smart Follow-Up precisa ser entre 1 e 30.</span>
      <span *ngIf="error.message == 'COLUMNS_EMPTY'" class="amp-error"><b>"{{ error.fieldReadable }}"</b> está ligada, porem as colunas <b>não</b> foram mapeadas.</span>
    </div>
  </div>
  <div class="amp-dialog-footer">
    <button (click)="close()" class="amp-btn-success amp-btn-lg">Fechar</button>
  </div>
</div>
