<div class="amp-referral-milestone-preview">
  <div class="amp-landing-sidebar-editor animate__animated animate__faster animate__slideInLeft">
    <div class="amp-sidebar-section">
      <p class="amp-card-title">Configurações dos Widgets</p>
      <br>
      <div class="amp-options mx-auto">
        <label class="amp-label-text" for="checkbox">Campos do Formulário (E-mail ou Telefone são obrigatórios)</label>
        <label class="amp-checkbox">
          <input type="checkbox" [checked]="this.campaign.customer_form_fields.indexOf('name') > -1"
          (change)="toggleCustomerField('name')">
          <span class="text">Nome</span>
          <span class="checkmark"></span>
        </label>
        <label class="amp-checkbox">
          <input type="checkbox" [checked]="this.campaign.customer_form_fields.indexOf('email') > -1"
          (change)="toggleCustomerField('email')">
          <span class="text">E-mail</span>
          <span class="checkmark"></span>
        </label>
        <label class="amp-checkbox">
          <input type="checkbox" [checked]="this.campaign.customer_form_fields.indexOf('phone') > -1"
          (change)="toggleCustomerField('phone')">
          <span class="text">Telefone</span>
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="amp-color-selector-input">
        <label class="amp-label-text">Cor dos Botões</label>
        <div class="amp-color-selector-wrapper">
          <div class="amp-color-selector-name" id="colorPicker" (click)="togglePickerPrimaryColor()">
            {{campaign.primary_color}}</div>
          <div class="amp-color-selector" id="colorPicker" (click)="togglePickerPrimaryColor()" [ngStyle]="{'background': campaign.primary_color}"></div>
          <div class="amp-colorpicker" *ngIf="primaryColorSelector" clickOutside (clickOutside)="togglePickerPrimaryColor()">
            <color-block [color]="campaign.primary_color" [colors]="colors" width="170" (onChangeComplete)="changePrimaryColor($event)">
            </color-block>
          </div>
        </div>
      </div>
      <div class="amp-color-selector-input">
        <label class="amp-label-text">Cor do Texto dos Botões</label>
        <div class="amp-color-selector-wrapper">
          <div class="amp-color-selector-name" id="colorPicker" (click)="togglePickerTextColor()">
            {{campaign.text_color}}</div>
          <div class="amp-color-selector" id="colorPicker" (click)="togglePickerTextColor()" [ngStyle]="{'background': campaign.text_color}"></div>
          <div class="amp-colorpicker" *ngIf="textColorSelector" clickOutside (clickOutside)="togglePickerTextColor()">
            <color-block [color]="campaign.text_color" [colors]="colorsText" width="170" (onChangeComplete)="changeTextColor($event)">
            </color-block>
          </div>
        </div>
      </div>

      <div class="amp-field">
        <label class="amp-label-text">Modo Escuro</label>
        <input id="amp-switch-color-theme" [(ngModel)]="campaign.dark_mode" name="campaignStatus"
          #campaignTheme="ngModel" type="checkbox" class="amp-switch">
        <label for="amp-switch-color-theme" class="amp-switch-label"></label>
        <label for="amp-switch-color-theme" class="amp-switch-status-text" *ngIf="campaign.dark_mode">Ativado</label>
        <label for="amp-switch-color-theme" class="amp-switch-status-text"
          *ngIf="!campaign.dark_mode">Desativado</label>
      </div>

      <div class="amp-field amp-radio-wrapper">
        <label class="amp-radio-button">
          <input type="radio" required name="preview" value="authentication" [(ngModel)]="campaign.preview">
          <span class="text">Visualizar prévia da tela de autenticação</span>
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="amp-field">
        <label class="amp-label-text">Edite na caixa abaixo o texto da tela de autenticação</label>
        <amp-markdown-editor name="markdown3" required [(markdown)]="campaign.description" name="description" [allowImage]="false" [allowLinks]="true">
        </amp-markdown-editor>
      </div>

      <div class="amp-field">
        <label class="amp-radio-button">
          <input type="radio" name="preview" value="reffer" [(ngModel)]="campaign.preview">
          <span class="text">Visualizar prévia da tela de indicação</span>
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="amp-field">
        <label class="amp-label-text">Edite na caixa abaixo o texto da tela de indicação</label>
        <amp-markdown-editor name="markdown2" required [(markdown)]="campaign.content_refer" name="contentRefer" [allowImage]="false" [allowLinks]="true">
        </amp-markdown-editor>
      </div>

      <div class="amp-field" *ngIf="this.campaign.channels.indexOf('widget') > -1">
        <label class="amp-label-text" for="termsUrl">Link para a Página onde ficará o Programa de Indicação</label>
        <input type="text" class="amp-input-lg" (blur)="formatURL('widgetUrl')" id="widgetUrl" [(ngModel)]="campaign.widget_url" autocomplete="off" placeholder="" #termsUrl="ngModel" name="termsUrl" required>
      </div>

      <div class="amp-field">
        <label *ngIf="!campaign.referee_can_refer && !campaign.direct_referral" class="amp-radio-button">
          <input type="radio" name="preview" value="thankyou" [(ngModel)]="campaign.preview">
          <span class="text">Visualizar prévia da tela de agradecimento</span>
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="amp-field" *ngIf="!campaign.referee_can_refer && !campaign.direct_referral">
        <label class="amp-label-text">Edite na caixa abaixo o texto da tela de agradecimento para pessoa indicada que não pode indicar <i tooltip="Preenchimento necessário se utilizar os Widgets da Amplifique.me, seja em sua Própria LP ou na LP da Amplifique.me" class="bi-question-circle-fill"></i></label>
        <amp-markdown-editor name="markdown4" required [(markdown)]="campaign.referee_thankyou_message" name="thankYou" [allowImage]="false" [allowLinks]="true">
        </amp-markdown-editor>
      </div>

      <div class="amp-field" *ngIf="!campaign.referee_can_refer && !campaign.direct_referral">
        <label class="amp-label-text">Edite na caixa abaixo o texto de privacidade que aparece antes do usuário preencher os dados</label>
        <amp-markdown-editor name="markdown5" required [(markdown)]="campaign.privacy_text" name="privacyText" [allowImage]="false" [allowLinks]="true">
        </amp-markdown-editor>
      </div>

      <label class="amp-checkbox mb-32" *ngIf="!this.campaign.direct_referral && (!campaign.referee_can_refer || (campaign.referee_can_refer && campaign.validation_mode == 'conversion'))">
        <input type="checkbox" [checked]="this.campaign.redirect_after_referral" (change)="this.campaign.redirect_after_referral=!this.campaign.redirect_after_referral">
        <span class="text" *ngIf="!this.campaign.referee_can_refer">Redirecionar o indicado após o cadastro</span>
        <span class="text" *ngIf="this.campaign.referee_can_refer && this.campaign.validation_mode=='conversion'">Link para Validação com Redirecionamento</span>
        <span class="checkmark"></span>
      </label>

      <div class="amp-field" *ngIf=" !this.campaign.direct_referral && this.campaign.redirect_after_referral">
        <label class="amp-label-text" for="termsUrl" *ngIf="!this.campaign.referee_can_refer">Link para a Página onde o indicado será redirecionado após preencher o formulário</label>
        <label class="amp-label-text" for="termsUrl" *ngIf="this.campaign.referee_can_refer && this.campaign.validation_mode=='conversion'">Link para onde será redirecionado no link de validação</label>
        <input type="text" class="amp-input-lg" (blur)="formatURL('referralUrl')" [(ngModel)]="campaign.redirect_after_referral_url" autocomplete="off" placeholder="" #referralUrl="ngModel" name="referralUrl" required>
      </div>

      <div class="amp-field" (click)="copyLinkToClipboard()" *ngIf=" !this.campaign.direct_referral && this.campaign.redirect_after_referral && this.campaign.referee_can_refer && this.campaign.validation_mode=='conversion'" tooltip="Você pode colocar o parâmetro de url &image=true (para inserir como uma imagem em um e-mail para validar com a abertura do e-mail), você pode alterar o redirecionamento com o parâmetro &redirect_to=[LINK PARA ONDE QUER REDIRECIONAR COM O HTTPS://]">
        <label class="amp-label-text" for="termsUrl" >Link de validação com o redirecionamento</label>
        <input type="text" readonly="true" class="amp-input-lg" value="https://api.amplifique.me/referral/referral/validate-redirect/{{this.campaign._id}}?email=[EMAIL DO INDICADO]" autocomplete="off" placeholder=""  name="redirectValidationReferral">
      </div>

      <div *ngIf="customFields.length" class="amp-select-business-wrapper">
        <label class="amp-label-text">Campos Customizados do Indicado</label>
        <div class="amp-select-business">
          <div class="amp-select-business-content">
            <label class="amp-checkbox" *ngFor="let customField of customFields">
              <input type="checkbox" [checked]="this.campaign.custom_fields_referee.indexOf(customField.internal_name) > -1" (change)="toggleRefereeField(customField.internal_name)">
              <span class="text">{{customField.label}}</span>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <br *ngIf="customFields.length">
      <div *ngIf="customFields.length" class="amp-select-business-wrapper">
        <label class="amp-label-text">Campos Customizados do Indicador</label>
        <div class="amp-select-business">
          <div class="amp-select-business-content">
            <label class="amp-checkbox" *ngFor="let customField of customFields">
              <input type="checkbox" [checked]="this.campaign.custom_fields_referrer.indexOf(customField.internal_name) > -1" (change)="toggleReferrerField(customField.internal_name)">
              <span class="text">{{customField.label}}</span>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="amp-sidebar-offset"></div>
  <div class="amp-landing-content">
    <section class="amp-main">
      <div class="container">
        <div class="row amp-referral" id="referral">
          <div class="col-lg-5">
            <h2 class="amp-section-title">{{campaign.share_widget_title}}</h2>
            <div data-ampl-referral="referralWidget">
              <amp-referral-widget-preview type="milestone" [color]="campaign.primary_color" [textColor]="campaign.text_color" [campaign]="campaign" [customFields]="customFields"></amp-referral-widget-preview>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-2">
            <h2 class="amp-section-title">{{campaign.milestones_widget_title}}</h2>
            <div data-ampl-referral="milestoneWidget">
              <amp-referral-milestone-widget-preview [campaign]="campaign"></amp-referral-milestone-widget-preview>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
