import { MenuService } from '../../_services/menu.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifyService } from '../../_services/notify.service';
import { StoreService } from 'app/_services/store.service';
import { AuthenticationService } from 'app/_services';
import { UtilService } from 'app/_services/util.service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent implements OnInit, OnDestroy {
  constructor(
    private store: StoreService,
    private menuService: MenuService,
    public notifyService: NotifyService,
    private utilService: UtilService,
    public auth: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.menuService.emit(true);
    this.menuService.emitSidebar(true);
    this.menuService.emitPageTitle('Exportações');
  }

  public date = moment();
  public exports = [];
  public subscriptions = [];
  public adminMode = false;
  public limit = 20;
  public page = 1;
  public paginationPage = 1;
  public totalItems = 0;

  public exportDataId = null;

  ngOnInit() {
    if (this.store.business._id) {
      this.getExports();
    }

    this.subscriptions.push(
      this.menuService.getChangeEmmiterBusiness().subscribe(() => {
        this.getExports();
      })
    );

    if (this.route.snapshot.queryParams['exportDataId']) {
      this.exportDataId = this.route.snapshot.queryParams['exportDataId'];
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getExports(page = 1) {
    this.page = page;
    this.exports = [];
    if (this.adminMode) {
      this.subscriptions.push(
        this.utilService.getExportsForAdmin(this.limit, this.page).subscribe((data) => {
          this.exports = data.data;
          this.totalItems = data.total;
        })
      );
    } else {
      this.subscriptions.push(
        this.utilService.getExports(this.store.business._id, this.limit, this.page).subscribe((data) => {
          this.exports = data.data;
          this.totalItems = data.total;
        })
      );
    }
  }

  checkExpiration(date) {
    return this.date.diff(date, 'd');
  }
}
