import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class ReportService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getNpsStats(_campaign: any) {
    return this.http.get<any>(this.config.apiUrl + '/report/npsStats/' + _campaign);
  }
}
