import { UserService, SignupService, NotifyService } from '../_services/index';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from './../_services/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, debounceTime } from 'rxjs';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private userService: UserService,
    private signupService: SignupService,
    private notifyService: NotifyService
  ) {
    this.menuService.emit(false);
  }

  public sub: any;
  public type = '';
  public id: string;
  public empresa = '';
  public error = false;
  public convidador = '';
  public model: any = {};
  public loading = false;
  public returnUrl: string;
  public subscriptions = [];
  public termsOfUse: boolean;
  public showPassword = false;
  public user: any = { nome: '' };
  public allowSaveButton: boolean;
  private subjectContact: Subject<string> = new Subject();

  public maskTel = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  ngOnInit() {
    this.subscriptions.push(
      this.subjectContact.pipe(debounceTime(1000)).subscribe(() => {
        this.teste();
      })
    );

    this.loading = true;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    this.sub = this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.id = params['id'];
        this.subscriptions.push(
          this.signupService.getById(this.id).subscribe({
            next: (data) => {
              this.user.email = data.signupInfo.email;
              this.user._empresa = data.signupInfo._empresa._id;
              this.user.nome = data.signupInfo.nome;
              this.user.role = data.signupInfo.role;
              this.empresa = data.signupInfo._empresa.nome;
              this.convidador = data.signupInfo._usuario.nome;
              data.visited = true;
              this.loading = false;
              this.subscriptions.push(
                this.signupService.update(data.signupInfo).subscribe({
                  next: () => {},
                  error: (error) => {
                    console.error(error);
                  }
                })
              );
            },
            error: (error) => {
              console.error(error);
              this.error = true;
            }
          })
        );
      })
    );
  }

  teste() {
    return setTimeout(() => this.teste(), 2500);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;

    const field = document.getElementById('password') as HTMLInputElement;

    if (field.type == 'password') {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
  }

  onContactChange() {
    this.subjectContact.next('a');
  }

  stopCloseDropdown(event) {
    event.stopPropagation();
  }

  checkPassword(senha) {
    return /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!#%*?&])[A-Za-z\d$@!#%*?&].{7,}/.test(senha);
  }

  create() {
    this.subscriptions.push(
      this.userService.create(this.user, this.id).subscribe({
        next: () => {
          this.router.navigateByUrl('/login');
        },
        error: (error) => {
          console.error(error);
          if (error.error.message.includes('URL ja utilizada')) {
            this.notifyService.error('Esse e-mail já está cadastrado!');
            this.router.navigateByUrl('/login');
          } else if (error.error.message.includes('Senha fraca')) {
            this.notifyService.error('Senha Fraca!');
          }
        }
      })
    );
  }
}
