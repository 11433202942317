import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'customFields'
})
export class AmpCustomFields implements PipeTransform {
  transform(target: any, ...customFields: any[]): any {
    if (customFields) {
      customFields = customFields[0];
    }
    if (target) {
      const regex = new RegExp(/{{customFields.([\s\S]*?)}}/, 'g');
      const items = target.match(regex);
      if (items) {
        for (const item of items) {
          let customField = item.replace('{{customFields.', '');
          customField = customField.replace('}}', '');
          if (customFields) {
            target = target.replace(item, customFields[customField] ? customFields[customField] : '');
          } else {
            target = target.replace(item, '');
          }
        }
      }
    }
    return target;
  }
}
