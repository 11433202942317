import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import moment from 'moment';
@Injectable()
export class RequestQueueService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  post(
    type: string,
    main_channel: number,
    _business: string,
    customers: string[],
    _campaign?: string,
    options?,
    force = false,
    scheduleType = 'default',
    scheduleTo = undefined,
    tryAlternativeChannel = false,
    expiration = null,
    expire_at = null
  ) {
    return this.http.post<any>(this.config.apiUrl + '/requestQueue', {
      type,
      main_channel,
      _business,
      customers,
      _campaign,
      options,
      force,
      tryAlternativeChannel,
      schedule_params: {
        type: scheduleType,
        scheduled_to: scheduleTo
      },
      expiration,
      expire_at
    });
  }

  postAll(
    type: string,
    main_channel: number,
    _business: string,
    filters: {},
    importData: string,
    _campaign?: string,
    options?,
    force = false,
    scheduleType = 'default',
    scheduleTo = undefined,
    tryAlternativeChannel = false,
    expiration = null,
    expire_at = null
  ) {
    return this.http.post<any>(this.config.apiUrl + '/requestQueue/all', {
      type,
      main_channel,
      _business,
      filters,
      importData,
      _campaign,
      options,
      force,
      tryAlternativeChannel,
      schedule_params: {
        type: scheduleType,
        scheduled_to: scheduleTo
      },
      expiration,
      expire_at
    });
  }

  getAllForAdmin(limit = 20, page = 0, filterDate) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;

    const params = new URLSearchParams();
    //@ts-ignore
    params.set('limit', limit);
    //@ts-ignore
    params.set('page', page);
    params.set('start_at', start);
    params.set('end_at', end);

    return this.http.get<any>(this.config.apiUrl + `/requestQueue/business/?${params.toString()}`);
  }

  getAll(_business, limit = 20, page = 0, filterDate, surveyFilter) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;

    const params = new URLSearchParams();
    //@ts-ignore
    params.set('limit', limit);
    //@ts-ignore
    params.set('page', page);

    if (surveyFilter.length) {
      params.set('surveyFilter', surveyFilter);
    }

    params.set('start_at', start);
    params.set('end_at', end);
    return this.http.get<any>(this.config.apiUrl + '/requestQueue/business/' + _business + `?${params.toString()}`);
  }

  getCurrent(_business, limit = 1, page = 0) {
    const params = new URLSearchParams();
    //@ts-ignore
    params.set('limit', limit);
    //@ts-ignore
    params.set('page', page);
    params.set('current', 'true');
    return this.http.get<any>(this.config.apiUrl + '/requestQueue/business/' + _business + `?${params.toString()}`);
  }

  getQueueItems(requestQueueId: string) {
    return this.http.get<any>(this.config.apiUrl + '/requestQueue/' + requestQueueId + '/items');
  }

  reescheduleQueue(requestQueueId, schedule_to) {
    return this.http.put<any>(this.config.apiUrl + '/requestQueue/' + requestQueueId + '/reschedule', {
      schedule_params: {
        schedule_to
      }
    });
  }

  deleteScheduled(requestQueueId) {
    return this.http.delete<any>(this.config.apiUrl + '/requestQueue/' + requestQueueId);
  }
}
