import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener } from '@angular/core';
@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public title = '';
  public message = '';
  public strongText = '';
  public messageEnd = '';
  public buttonName = 'Excluir';
  public forceConfirmation = false;
  public confirmField = '';
  public resultado: Subject<boolean> = new Subject();

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(event) {
    if (event.code == 'Enter') {
      this.confirmar();
    }
  }

  cancelar() {
    this.resultado.next(false);
    this.bsModalRef.hide();
  }

  confirmar() {
    this.resultado.next(true);
    this.bsModalRef.hide();
  }
}
