import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import intlTelInput from 'intl-tel-input';
@Component({
  selector: 'amp-phone-input',
  templateUrl: 'phone-input.component.html',
  styleUrls: ['phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit {
  public phoneInputId;
  @Input() placeholder = 'Insira seu telefone';

  @Input()
  phone: string;
  @Output()
  phoneChange = new EventEmitter<string>();

  @Input()
  valid: boolean;
  @Output()
  validChange = new EventEmitter<boolean>();

  @Output()
  finalize = new EventEmitter<string>();

  @Input()
  autofocus = true;

  @Input()
  allowFixedLine = false;

  public input;

  ngOnInit() {
    this.phoneInputId = 'amp-phone-input';
    setTimeout(() => {
      function formatIntlTelInput(context: PhoneInputComponent) {
        // @ts-ignore
        if (typeof window.intlTelInputUtils !== 'undefined') {
          // @ts-ignore
          const currentText = window.iti.getNumber(window.intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === 'string') {
            // @ts-ignore
            window.iti.setNumber(currentText);
          }
          // @ts-ignore
          if (window.iti.isValidNumber()) {
            if (!context.allowFixedLine) {
              if (
                // @ts-ignore
                window.iti.getNumberType() == window.intlTelInputUtils.numberType.MOBILE ||
                // @ts-ignore
                window.iti.getNumberType() == window.intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE
              ) {
                context.validChange.emit(true);
                context.phoneChange.emit(currentText);
              }
            } else {
              context.validChange.emit(true);
              context.phoneChange.emit(currentText);
            }
          } else {
            context.validChange.emit(false);
            context.phoneChange.emit(undefined);
          }
        }
      }

      this.input = document.querySelector(`#${this.phoneInputId}`);
      if (this.phone) {
        this.input.value = this.phone;
      }
      // @ts-ignore
      window.iti = intlTelInput(this.input, {
        separateDialCode: true,
        initialCountry: 'BR',
        formatOnDisplay: true,
        nationalMode: false,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js'
      });

      document.querySelector('.iti').addEventListener('focusout', (e) => {
        //@ts-ignore
        if (!e.currentTarget.contains(e.relatedTarget)) {
          this.onBlur();
        }
      });

      document.querySelector('.iti').addEventListener('focusin', () => {
        this.input.addEventListener('keyup', () => formatIntlTelInput(this));
        this.input.addEventListener('change', () => formatIntlTelInput(this));
      });

      if (this.autofocus) {
        this.input.focus();
      }
    }, 100);
  }

  validatePhoneNumber(evt) {
    evt.srcElement.value = evt.srcElement.value.replace(/[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ!"`'#%&,:;<>=@{}~$_()*+/\\?[\]^|]+/, '');
    if (evt.key == 'Enter') {
      this.onBlur();
    }
  }

  onBlur() {
    this.input.removeAllListeners();
    this.finalize.emit(crypto.randomUUID());
  }
}
