<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      {{ title }}
    </div>
    <div class="amp-dialog-subtitle" [ngClass]="{'amp-message-emphasis': messageEmphasis}" [innerHTML]="message"></div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <button class="amp-btn-success" (click)="confirmar()">{{ buttonName }}</button>
    <button class="amp-btn-outline-cancel" (click)="cancelar()">{{ cancelName }}</button>
  </div>
</div>