import { SmartTagService } from 'app/_services/smartTags/smartTags.service';
import { NotifyService, StoreService } from 'app/_services';
import { TagSet } from 'app/_models/smartCluster/tagSet';
import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-new-tag-set-dialog',
  templateUrl: './new-tag-set-dialog.component.html',
  styleUrls: ['./new-tag-set-dialog.component.scss']
})
export class NewTagSetDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private store: StoreService, private notifyService: NotifyService, private smartTagService: SmartTagService) {}

  public isNew = true;
  public subscriptions = [];
  public tagSet: TagSet = new TagSet();

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  save() {
    if (this.isNew) {
      this.tagSet._empresa = this.store.empresa;
      this.subscriptions.push(
        this.smartTagService.createTagSet(this.tagSet).subscribe(() => {
          this.bsModalRef.hide();
          this.notifyService.success('Conjunto de Tags criado com sucesso!');
        })
      );
    } else {
      this.subscriptions.push(
        this.smartTagService.updateTagSet(this.tagSet).subscribe({
          next: () => {
            this.bsModalRef.hide();
            this.notifyService.success('Conjunto de Tags renomeado com sucesso!');
          },
          error: (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao renomear o Conjunto de Tags. Tente novamente ou entre em contato com o suporte.');
          }
        })
      );
    }
  }
}
