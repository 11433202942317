import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-two-fa-dialog',
  templateUrl: './two-fa-dialog.component.html',
  styleUrls: ['./two-fa-dialog.component.scss']
})
export class TwoFaDialogComponent {
  constructor(public router: Router, public bsModalRef: BsModalRef) {}

  redirect() {
    this.router.navigate(['settings'], { queryParams: { '2fa': true } });
    this.bsModalRef.hide();
  }
}
