import { QueueLogDetailedComponent } from './settings/QueueLog/QueueLogDetailed/queue-log-detailed.component';
import { WebhookDetailsComponent } from './settings/WebhookDetails/webhook-details.component';
import { WebhookPanelComponent } from './partners/webhook-panel/webhook-panel.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { ApiPanelComponent } from './partners/api-panel/api-panel.component';
import { TokenLogComponent } from './settings/TokenLog/token-log.component';
import { QueueLogComponent } from './settings/QueueLog/queue-log.component';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ExportsComponent } from './settings/Exports/exports.component';
import { ImportsComponent } from './settings/Imports/imports.component';
import { PartnersComponent } from './partners/partners.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { RoleGuard } from './_guards/role.guard';
import { AuthGuard } from './_guards/auth.guard';
import { FlagGuard } from './_guards/flag.guard';
import { NgModule } from '@angular/core';

const appRoutes: Routes = [
  { path: 'partners', component: PartnersComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng'] } },
  { path: 'partners/api', component: ApiPanelComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng'] } },
  { path: 'partners/token/:id/log', component: TokenLogComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng'] } },
  { path: 'partners/webhook/:id', component: WebhookDetailsComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng'] } },
  { path: 'partners/webhook', component: WebhookPanelComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng'] } },
  { path: 'queues', component: QueueLogComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng', 'ana'] } },
  {
    path: 'queues/:id/detailed',
    component: QueueLogDetailedComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: ['own', 'adm', 'acmng', 'ana'] }
  },
  { path: 'imports', component: ImportsComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng', 'ana'] } },
  { path: 'exports', component: ExportsComponent, canActivate: [AuthGuard, RoleGuard], data: { expectedRole: ['own', 'adm', 'acmng', 'ana'] } },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'create-account',
    component: CreateAccountComponent
  },
  {
    path: 'cf-dashboard',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'pdv', 'readonly', 'notesonly', 'dashonly']
    },
    loadChildren: () => import('./cf-dashboard/cf-dashboard.module').then((m) => m.CfDashboardModule)
  },
  {
    path: 'cf-analytics',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'pdv', 'readonly']
    },
    loadChildren: () => import('./cf-analytics/cf-analytics.module').then((m) => m.CfAnalyticsModule)
  },
  {
    path: 'insights',
    canActivate: [AuthGuard, RoleGuard, FlagGuard],
    data: {
      expectedRole: ['own', 'adm', 'ana', 'acmng'],
      expectedFlag: 'insights'
    },
    loadChildren: () => import('./insights/insights.module').then((m) => m.InsightsModule)
  },
  {
    path: 'ticketing',
    canActivate: [AuthGuard, RoleGuard, FlagGuard],
    data: {
      expectedTicketingRole: ['adm', 'ana', 'agent'],
      expectedFlag: 'ticketing'
    },

    loadChildren: () => import('./ticketing/ticketing.module').then((m) => m.TicketingModule)
  },
  {
    path: 'reviews',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'pdv', 'readonly']
    },
    loadChildren: () => import('./gerenciar-reviews/gerenciar-reviews.module').then((m) => m.GerenciarReviewsModule)
  },
  {
    path: 'referral',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: ['own', 'adm', 'ana', 'acmng', 'pdv', 'readonly']
    },
    loadChildren: () => import('./gerenciar-referral/gerenciar-referral.module').then((m) => m.GerenciarReferralModule)
  },
  {
    path: 'contatos',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: ['own', 'adm', 'acmng', 'ana', 'pdv', 'readonly', 'notesonly']
    },
    loadChildren: () => import('./contatos/contatos.module').then((m) => m.ContatosModule)
  },
  {
    path: 'importer',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: ['own', 'adm', 'acmng', 'pdv', 'ana']
    },
    loadChildren: () => import('./importers/importers.module').then((m) => m.ImportersModule)
  },
  {
    path: 'settings',
    data: {
      expectedRole: ['own', 'adm', 'acmng', 'pdv', 'ana', 'readonly']
    },
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'ampemp',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['mf']
    },
    loadChildren: () => import('./create-empresa/create-empresa.module').then((m) => m.CreateEmpresaModule)
  },
  {
    path: 'reputation-management',
    canActivate: [RoleGuard, FlagGuard],
    data: {
      expectedRole: ['own', 'adm', 'ana', 'pdv', 'acmng', 'readonly', 'notesonly'],
      expectedFlag: 'reputation-management'
    },
    loadChildren: () => import('./reputation-management/reputation-management.module').then((m) => m.ReputationManagementModule)
  },
  {
    path: 'customer-feedback',
    canActivate: [RoleGuard, FlagGuard],
    data: {
      expectedRole: ['own', 'adm', 'ana', 'pdv', 'acmng', 'readonly', 'notesonly'],
      expectedFlag: 'customer-feedback'
    },
    loadChildren: () => import('./customer-feedback/customer-feedback.module').then((m) => m.CustomerFeedbackModule)
  },
  {
    path: 'admin',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['acmng']
    },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)
  },

  {
    path: 'subscription',
    canActivate: [AuthGuard],
    data: {},
    loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule)
  },
  {
    path: 'forgotPassword/:id',
    component: ForgotPasswordComponent
  },
  {
    path: 'link',
    loadChildren: () => import('./link/link.module').then((m) => m.LinkModule)
  },
  {
    path: 'signup/:id',
    component: SignupComponent
  },
  {
    path: '',
    redirectTo: '/cf-dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/cf-dashboard',
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
