<div class="amp-dialog">
  <p class="amp-dialog-title">{{ isNew ? 'Novo' : 'Renomear' }} Painel</p>

  @if (isNew) {
  <div class="amp-dialog-subtitle">Escolha um nome para salvar as configurações do seu painel.</div>
  } @else {
  <div class="amp-dialog-subtitle">Escolha um novo nome para o seu painel.</div>
  }

  <form (ngSubmit)="form.form.valid && createDashboard()" #form="ngForm">
    <div class="amp-field">
      <label class="amp-label-text" for="role">Nome do Painel</label>
      <input type="text" class="amp-input" placeholder="Painel de Exemplo" [(ngModel)]="dashboard.name" #nome="ngModel" name="nome" required />
    </div>
    
    <div class="amp-buttons">
      <button class="amp-btn-success" type="submit" [disabled]="!form.form.valid">{{ isNew ? 'Criar' : 'Renomear' }}</button>
      <button type="button" class="amp-btn-outline-cancel" (click)="this.bsModalRef.hide()">Cancelar</button>
    </div>
  </form>
</div>