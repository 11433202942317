import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
@Injectable()
export class CouponService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  create(businessId: string, data) {
    return this.http.post<any>(this.config.apiUrl + '/referral/coupon/' + businessId, data);
  }

  createFromNps(businessId: string, data) {
    return this.http.post<any>(this.config.apiUrl + '/referral/coupon/nps/' + businessId, data);
  }

  getFromReferrer(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/referral/coupon/' + _id + '/referrer');
  }

  getFromReferee(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/referral/coupon/' + _id + '/referee');
  }

  validate(businessId: string, coupon: string) {
    const query = new URLSearchParams({
      coupon
    });

    return this.http.get<any>(this.config.apiUrl + '/referral/coupon/validate/' + businessId + '?' + query.toString());
  }

  getCouponDetails(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/referral/coupon/' + _id + '/details');
  }

  changeBlockStatus(_id: string, block) {
    return this.http.put<any>(this.config.apiUrl + '/referral/coupon/' + _id + '/block', { block });
  }
}
