import { Business } from './business';
import { Empresa } from './empresa';
export class User {
  _id: string;
  nome: string;
  email: string;
  phone: string;
  senha: string;
  active: boolean;
  fb_access_token: string;
  fb_id: string;
  role: Role;
  _empresa: Empresa;
  created_at: Date;
  picture: string;
  _businesses: Business[];
  authenticator_enabled = false;
  authenticator_secret = '';
  ticketing_role = '';
  _changeLogsReaded: [];

  last_login: Date;
  last_request: Date;

  permissions = {
    can_manage_balance: false,
    can_temporary_access: false
  };

  terms_signin_version: number;
  terms_signin_at: Date;
  terms_signed: boolean;
  terms_signin_ip: string;

  privacy_signin_version: number;
  privacy_signin_at: Date;
  privacy_signed: boolean;
  privacy_signin_ip: string;

  historyBalance_notifications = true;

  weekly_report: boolean;
}

export enum Role {
  mf = 'mf',
  adm = 'adm',
  own = 'own',
  ana = 'ana',
  pdv = 'pdv',
  readonly = 'readonly',
  notesonly = 'notesonly',
  dashonly = 'dashonly',
  acmng = 'acmng'
}
