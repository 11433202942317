<div class="amp-dialog">
    <div class="amp-dialog-title">
        <p>{{ isNew ? 'Novo' : 'Renomear' }} Conjunto de Tags</p>
    </div>

    <p class="amp-dialog-subtitle">Escolha um {{ !isNew ? 'novo' : '' }} nome para dar ao seu conjunto de tags.</p>

    <div class="amp-dialog-content">
        <div class="amp-field">
            <label class="amp-label-text">Nome do Conjunto de Tags</label>
            <input type="text" [(ngModel)]="tagSet.name" class="amp-input" placeholder="Ex.: Atendimento Online" required />
        </div>
    </div>

    <div class="amp-dialog-actions">
        <button [disabled]="!tagSet.name" type="submit" (click)="save()" class="amp-btn-success">{{ isNew ? 'Criar' : 'Renomear' }} Conjunto</button>
        <button (click)="bsModalRef.hide()" class="amp-btn-outline-cancel">Cancelar</button>
    </div>
</div>