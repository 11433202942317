import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeTag'
})
export class TagRemoverPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    if (value) {
      return value.replace(/<\/?[^>]+(>|$)/g, '');
    }

    return value;
  }
}
