<amp-loading *ngIf="loading"></amp-loading>

<div *ngIf="!loading && sources.length" class="amp-campaign-source">
  <div class="amp-content">
    <input type="search" name="searchFilters" class="amp-input" [(ngModel)]="searchFilters" placeholder="Buscar" autocomplete="off" autofill="off" />
    <i class="bi-search"></i>
    <div class="amp-option-list">
      <div class="amp-options" *ngFor="let source of sources | filterPipe: { field: 'name', value: searchFilters }">
        <label class="amp-checkbox">
          <input type="checkbox" [checked]="isChecked(source)" (click)="toggleItemToFilterArray(source)" type="checkbox">
          <span class="text">{{ source.name }}</span>
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
  <div (click)="filters.value != null  && saveFilter()" [ngClass]="{'checked': filters.value != null}" class="amp-apply-button">
    <span>
      Salvar
    </span>
  </div>
</div>

<div class="amp-not-found" *ngIf="!loading && !sources.length">
  <span>NENHUMA PLATAFORMA FOI ENCONTRADA</span>
</div>