<section id="token-log">
  <div class="row mb-5">
    <div class="col-lg-8 mx-auto">
      <p class="amp-section-title">Resumo (últimos 90 dias)</p>
      <div class="amp-card amp-card-large amp-carad-border-yellow">
        <div class="amp-card-header">
          <div class="amp-logs amp-table">
            <div class="amp-table-head">
              <div class="amp-table-column amp-token-name">Status</div>
              <div class="amp-table-column">Quantidade</div>
            </div>
            <div class="amp-table-row" *ngFor="let metric of metrics; let i = index">
              <div class="amp-table-cell">{{'log-status-'+metric._id | translate}}</div>
              <div class="amp-table-cell">{{ metric.count }}</div>
            </div>
            <div class="amp-table-head">
              <div class="amp-table-column amp-token-name">Total</div>
              <div class="amp-table-column">{{pagination.totalItems}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="token-log">
  <div class="row">
    <div class="col-lg-8 mx-auto">
      <p class="amp-section-title">Registros (últimos 90 dias)</p>
      <div class="amp-card amp-card-large amp-carad-border-yellow">
        <div class="amp-card-header">
          <div class="amp-logs amp-table">
            <div class="amp-table-head">
              <div class="amp-table-column amp-token-name">Data da Solicitação</div>
              <div class="amp-table-column">Status</div>
              <div class="amp-table-column">Contato</div>
            </div>
            <div class="amp-table-row" (click)="view(log)" *ngFor="let log of logs; let i = index">
              <div class="amp-table-cell">{{log.created_at | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm:ss'}}</div>
              <div class="amp-table-cell amp-log-status" [ngClass]="'amp-log-status-'+log.status">{{'log-status-'+log.status | translate}}</div>
              <div class="amp-table-cell"><a target="_blank" *ngIf="log._customer" [routerLink]="'/contatos/'+log._customer._id">{{ log._customer.name }}</a> <span *ngIf="log._customer==null">{{(log.body.name ? log.body.name : '')}}</span></div>
            </div>
          </div>
        </div>
        <pagination [totalItems]="pagination.totalItems" [itemsPerPage]="10" [rotate]="true" [maxSize]="9" [boundaryLinks]="true" [customPreviousTemplate]='prevTemplate' [customNextTemplate]='nextTemplate' (pageChanged)="getLog($event.page-1)" [firstText]="'Primeira'" [lastText]="'Última'">
        </pagination>
      </div>
    </div>
  </div>
</section>

<ng-template #nextTemplate>
  <span class="bi-chevron-right"></span>
</ng-template>
<ng-template #prevTemplate>
  <span class="bi-chevron-left"></span>
</ng-template>
