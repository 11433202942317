<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      {{'network-selector-dialog.title' | translate}}
    </div>
    <div class="amp-dialog-subtitle">{{'network-selector-dialog.subtitle' | translate}}</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-surveys">
      <label>Encontrar plataformas</label>
      <input type="search" name="search" class="amp-input" [(ngModel)]="searchField" placeholder="Digite para buscar"
        autocomplete="off">
      <div class="amp-survey-list">
        <span class="amp-not-found" *ngIf="!reviewPlatforms.length">NÃO HÁ PLATAFORMAS CADASTRADAS</span>
        <label class="amp-checkbox"
          *ngFor="let platform of reviewPlatforms | filterPipe: { field: 'name', value: searchField }">
          <div class="amp-option">
            <input type="checkbox" [checked]="checkPlatformIncluded(platform)" (change)="togglePlatform(platform)">
            <span class="amp-option-text">{{ platform.name }}</span>
            <span class='amp-option-type'>{{getPlatformName(platform.platform)}}</span>
            <span class="checkmark"></span>
          </div>
        </label>
      </div>
    </div>
    <button class="amp-btn-lg amp-btn-success" (click)="send()">{{'network-selector-dialog.button' |
      translate}}</button>
  </div>
</div>