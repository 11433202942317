import { GalleryDialogComponent } from 'app/_dialogs/gallery-dialog/gallery-dialog.component';
import { EmailTemplateService } from 'app/_services/emailTemplate.service';
import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { EmailTemplate } from 'app/_models/emailTemplate';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotifyService } from 'app/_services';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'amp-mail-builder',
  templateUrl: './mail-builder.component.html',
  styleUrls: ['./mail-builder.component.scss']
})
export class AmpMailBuilderComponent implements OnInit, OnDestroy {
  public tthis;
  public content;
  public surveyLogo;
  public mailName = '';
  public surveyQuestion;
  public isNew: boolean;
  public reference = '';
  public subscriptions = [];
  public disableSave = true;
  public showMarkdown: boolean;
  public showLinkEditor: boolean;
  public enableCustomLink: boolean;
  public textColorSelector: boolean;
  public primaryColorSelector: boolean;
  public template: EmailTemplate = new EmailTemplate();
  public colorsText = ['#383838', '#ffffff', '#0a1f44', '#445571', '#98a1b1'];
  public colors = ['#5885f7', '#4bc490', '#e85d6b', '#98a1b1', '#ffca00', '#0a1f44', '#445571', '#ba68c8', '#dbe5ff', '#ffe373'];

  @ViewChild('coverFile') coverFile;
  @ViewChild('form') form: NgForm;
  @ViewChild('formDiv') formDiv;

  constructor(
    private notifyService: NotifyService,
    public storeService: StoreService,
    public emailTemplateService: EmailTemplateService,
    public modalService: BsModalService
  ) {}

  public save(callback) {
    if (this.validateFields()) {
      if (this.isNew) {
        this.subscriptions.push(
          this.emailTemplateService.create(this.template).subscribe((data) => {
            callback(data, this.reference, this.tthis);
          })
        );
      } else {
        this.subscriptions.push(
          this.emailTemplateService.update(this.template).subscribe((data) => {
            callback(data, this.reference, this.tthis);
          })
        );
      }
    }
  }

  ngOnInit() {
    if (this.template.type == 'survey') {
      if (!['nps', 'ces', 'csat', 'thumbs'].includes(this.surveyQuestion.type) && this.template.show_scale) {
        this.template.show_scale = false;
        this.emailTemplateService.update(this.template).subscribe();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  changePrimaryColor(evt) {
    this.template.button_color = evt.color.hex;
  }

  changeTextColor(evt) {
    this.template.button_text_color = evt.color.hex;
  }

  togglePickerPrimaryColor() {
    if (this.textColorSelector) {
      this.textColorSelector = false;
    }
    this.primaryColorSelector = !this.primaryColorSelector;
  }

  togglePickerButtonTextColor() {
    if (this.primaryColorSelector) {
      this.primaryColorSelector = false;
    }
    this.textColorSelector = !this.textColorSelector;
  }

  setDefaultColors() {
    if (this.template.show_scale) {
      this.template.button_color = '#f8f8fd';
      this.template.button_text_color = '#8a94a6';
    }
  }

  uploadCover() {
    const galleryPicker = this.modalService.show(GalleryDialogComponent, {
      initialState: {
        isCover: true
      },
      class: 'amp-modal-lg col-xl-7 col-lg-9 col-md-10 col-sm-12'
    });

    this.subscriptions.push(
      galleryPicker.content.result.subscribe((data) => {
        this.template.cover_url = data;
      })
    );
  }

  removeSpaces(evt) {
    evt.srcElement.value = evt.srcElement.value.replace(' ', '');
  }

  validateFields() {
    const fieldsError = [];
    if (this.template.from_name === '') {
      fieldsError.push('Nome do Remetente');
    }
    if (
      this.template.reply_to === '' ||
      !this.template.reply_to.includes('@') ||
      !this.template.reply_to.includes('.') ||
      this.template.reply_to.includes(' ') ||
      this.template.reply_to.includes(';') ||
      this.template.reply_to.includes(',')
    ) {
      fieldsError.push('E-mail de Resposta');
    }
    if (this.template.subject === '') {
      fieldsError.push('Assunto');
    }
    if (this.template.body === '') {
      fieldsError.push('Corpo do E-mail');
    }
    if (this.template.button_text === '') {
      fieldsError.push('Texto do botão');
    }
    if (this.template.button_color === '') {
      fieldsError.push('Cor Primária do Botão');
    }
    if (this.template.button_color === '') {
      fieldsError.push('Cor do Texto do Botão');
    }
    if (fieldsError.length > 0) {
      this.notifyService.warning(`Os seguintes campos estão com erros: ${fieldsError.join(', ')}`);
    }
    return fieldsError.length === 0;
  }
}
