<div class="amp-dialog">
  <p class="amp-dialog-title">Renomear Card</p>
  <div class="amp-dialog-subtitle">
    Escolha um novo nome para o seu card.
  </div>
  <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
    <div class="amp-field" *ngIf="dashboard.type == 'review'">
      <label class="amp-label-text" for="role">Nome do Card</label>
      <input type="text" class="amp-input" placeholder="Card de Exemplo" [(ngModel)]="reviewCard.name" #nome="ngModel"
        name="nome" required />
    </div>
    <div class="amp-field" *ngIf="dashboard.type != 'review'">
      <label class="amp-label-text" for="role">Nome do Card</label>
      <input type="text" class="amp-input" placeholder="Card de Exemplo" [(ngModel)]="card.name" #nome="ngModel"
        name="nome" required />
    </div>
    <div class="amp-buttons">
      <button class="amp-btn-success amp-btn-lg" type="submit" [disabled]="!form.form.valid">Renomear</button>
      <button type="button" class="amp-btn-outline-cancel amp-btn-lg" (click)="closeModal()">Cancelar</button>
    </div>
  </form>
</div>