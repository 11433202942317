import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthenticationService } from 'app/_services';
import { GalleryDialogComponent } from 'app/_dialogs/gallery-dialog/gallery-dialog.component';
import { AmplmeService } from 'app/_services/amplme.service';
import { Campaign } from 'app/_models/referral/campaign';
const validator = new RegExp(/^[a-z0-9_-]+$/i);
@Component({
  selector: 'amp-referral-landing-editor',
  templateUrl: './referral-landing-editor.component.html',
  styleUrls: ['./referral-landing-editor.component.scss']
})
export class ReferralLandingEditorComponent implements OnInit, OnDestroy {
  public shareUrl = 'https://ampl.me/123';

  @Input('campaign') campaign = new Campaign();

  public tab = 1;
  public heroTextColorSelector = false;
  public pageBgColorSelector = false;
  public subscriptions = [];
  public colorsText = ['#383838', '#ffffff', '#0a1f44', '#445571', '#98a1b1'];
  public colors = ['#5885f7', '#4bc490', '#e85d6b', '#98a1b1', '#ffca00', '#0a1f44', '#445571', '#ba68c8', '#dbe5ff', '#ffe373'];
  private subjectSlug: Subject<string> = new Subject();
  private validSlug = '';
  public userName: string;

  constructor(private authService: AuthenticationService, private modalService: BsModalService, private amplmeService: AmplmeService) {}

  ngOnInit() {
    this.userName = this.authService.getNome();

    this.subscriptions.push(
      this.subjectSlug
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged())
        .subscribe((search) => {
          this.checkSlug(search);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  changeHeroTextColor(evt) {
    this.campaign.hero_text_color = evt.color.hex;
  }

  changePageBgColor(evt) {
    this.campaign.page_bg_color = evt.color.hex;
  }

  toglePickerHeroTextColor() {
    if (this.pageBgColorSelector) {
      this.pageBgColorSelector = false;
    }
    this.heroTextColorSelector = !this.heroTextColorSelector;
  }

  togglePickerPageBgColor() {
    if (this.heroTextColorSelector) {
      this.heroTextColorSelector = false;
    }
    this.pageBgColorSelector = !this.pageBgColorSelector;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }

  uploadCover() {
    const galleryPicker = this.modalService.show(GalleryDialogComponent, {
      class: 'amp-modal-lg col-xl-7 col-lg-9 col-md-10 col-sm-12'
    });

    this.subscriptions.push(
      galleryPicker.content.result.subscribe((data) => {
        this.campaign.hero_image = data;
      })
    );
  }

  onChangeSlug(value) {
    this.subjectSlug.next(value);
  }

  checkSlug(search) {
    if (validator.test(search)) {
      this.subscriptions.push(
        this.amplmeService.check(search).subscribe((data) => {
          this.validSlug = data.status;
        })
      );
    } else {
      this.validSlug = 'invalid';
    }
  }
}
