import { Component, Input } from '@angular/core';
@Component({
  selector: 'password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss']
})
export class PasswordValidatorComponent {
  @Input('password') password = '';

  patternLowerCase = new RegExp('(?=[^a-z]*[a-z])', 'g');
  patternUpperCase = new RegExp('(?=[^A-Z]*[A-Z])', 'g');
  patternNumber = new RegExp('(?=.*[0-9])', 'g');
  patternSpecial = new RegExp('(?=.*[$@$!#%*?&])', 'g');
}
