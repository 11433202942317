import { CpfCnpjValidator } from './cpfcnpj.validator';
import { EqualValidator } from './equal.validator';
import { PhoneValidator } from './phone.validator';
import { CommonModule } from '@angular/common';
import { CpfValidator } from './cpf.validator';
import { NgModule } from '@angular/core';
@NgModule({
  declarations: [EqualValidator, PhoneValidator, CpfValidator, CpfCnpjValidator],
  imports: [CommonModule],
  exports: [EqualValidator, PhoneValidator, CpfValidator, CpfCnpjValidator]
})
export class AmpValidators {}
