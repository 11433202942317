import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { EmailTemplate } from 'app/_models/emailTemplate';
@Injectable()
export class EmailTemplateService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  get(id: string) {
    return this.http.get<any>(this.config.apiUrl + '/emailTemplate/' + id);
  }

  update(emailTemplate: EmailTemplate) {
    return this.http.put<any>(this.config.apiUrl + '/emailTemplate/' + emailTemplate._id, emailTemplate);
  }

  create(emailTemplate: EmailTemplate) {
    return this.http.post<any>(this.config.apiUrl + '/emailTemplate/', emailTemplate);
  }

  delete(id: string) {
    return this.http.delete<any>(this.config.apiUrl + '/emailTemplate/' + id);
  }

  clone(id: string, _business, _empresa) {
    return this.http.post<any>(this.config.apiUrl + '/emailTemplate/clone/' + id, { _business, _empresa });
  }
}
