import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CfFilterService {
  constructor(private router: Router) {}

  generateFilter(appliedFilters: any, newFilter: any, businessMode = 'all', selectedCompletion = 'complete', dates?) {
    let filters = JSON.parse(JSON.stringify(appliedFilters));
    filters = [...filters, ...newFilter];

    const chartStorageKey = crypto.randomUUID();

    localStorage.setItem(chartStorageKey, JSON.stringify({ filters }));

    const params = {
      storageKey: chartStorageKey,
      viewMode: businessMode,
      version: 'new',
      filter_selected_completion: selectedCompletion
    };

    if (dates) {
      params['start_date'] = dates[0];
      params['end_date'] = dates[1];
    }

    const url = this.router.createUrlTree(['customer-feedback/answers'], {
      queryParams: params
    });

    window.open(url.toString(), '_blank');
  }

  addSmartTagsToFilter(value) {
    return {
      condition: 'equals',
      specify: false,
      specifyValue: '',
      values: [value],
      filter: {
        type: 'cf_response',
        subType: 'none',
        name: 'smartTags',
        label: 'Tags Inteligentes',
        icon: 'bi-tags'
      }
    };
  }

  addSpecificScoreToFilter(internalName, metric, value) {
    return {
      condition: 'equals',
      specify: false,
      internalName: internalName,
      specifyValue: metric,
      values: typeof value === 'object' ? value : [value],
      filter: {
        type: 'cf_response',
        subType: 'none',
        name: 'score',
        label: 'Nota',
        icon: 'bi-star'
      },
      isMainMetric: false
    };
  }
}
