<div class="amp-dialog" id="amp-autocomplete">
  <div class="amp-dialog-header">

    @if (isNew) {
    <div class="amp-dialog-title">Nova Empresa</div>
    <div class="amp-dialog-subtitle">Preencha os dados abaixo para cadastrar uma nova empresa.</div>
    } @else {
    <div class="amp-dialog-title">Editar Empresa</div>
    <div class="amp-dialog-subtitle">Edite os dados da empresa abaixo.</div>
    }

    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <form (ngSubmit)="save()" #f="ngForm">
    <div class="amp-dialog-body">
      <div class="amp-field" id="amp-autocomplete">
        <label class="amp-label-text">Nome Fantasia</label>
        <input id="amp-autocomplete" type="text" class="amp-input" [(ngModel)]="company.name" #name="ngModel" name="name" required />
      </div>
      <div class="amp-field" id="amp-autocomplete">
        <label class="amp-label-text">CNPJ</label>
        <input type="text" class="amp-input" mask="000.000.000-00||00.000.000/0000-00" autofocus
          (blur)="editCustomFieldIndex = -1" (keyUp.enter)="editCustomFieldIndex = -1"
          [(ngModel)]="company.document" #document="ngModel" name="document" required autocomplete="false" />
      </div>
    </div>

    <div class="amp-dialog-actions">
      <button class="amp-btn-success mr-2" type="submit" id="amp-autocomplete" *ngIf="isNew && !loading">Criar Empresa</button>
      <button class="amp-btn-success" type="submit" *ngIf="!isNew && !loading">Atualizar Empresa</button>
      <button class="amp-btn-outline-secondary" *ngIf="!loading" type="button" (click)="bsModalRef.hide()">{{ 'main.cancelButton' | translate }}</button>
      <button class="amp-btn-link-danger" *ngIf="!isNew && !loading" type="button" (click)="delete()">Excluir Empresa</button>
    </div>
  </form>
</div>