import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'amp-nps-response-distribution-bar',
  templateUrl: './nps-response-distribution-bar.component.html',
  styleUrls: ['./nps-response-distribution-bar.component.scss']
})
export class NpsResponseDistributionBarComponent implements OnInit {
  @Input('showValues')
  showValues = true;
  @Input('showTooltip')
  showTooltip = false;
  _promoter = 1 / 3;
  _neutral = 1 / 3;
  _detractor = 1 / 3;
  get promoter() {
    return this._promoter;
  }

  @Input('isTopic') isTopic = false;

  @Input('promoter') set promoter(value) {
    this._promoter = value;
    this.check();
  }
  get neutral() {
    return this._neutral;
  }
  @Input('neutral') set neutral(value) {
    this._neutral = value;
    this.check();
  }
  get detractor() {
    return this._detractor;
  }
  @Input('detractor') set detractor(value) {
    this._detractor = value;
    this.check();
  }

  @Input('showZeroValues') showZeroValues = false;

  public promoterPercentage;
  public neutralPercentage;
  public detractorPercentage;
  public allZero = false;

  ngOnInit() {
    this.check();
  }

  check() {
    if (this.promoter == 0 && this.neutral == 0 && this.detractor == 0) {
      this.allZero = true;
    }
    this.promoterPercentage = this.promoter / (this.promoter + this.detractor + this.neutral);
    this.neutralPercentage = this.neutral / (this.promoter + this.detractor + this.neutral);
    this.detractorPercentage = this.detractor / (this.promoter + this.detractor + this.neutral);
  }
}
