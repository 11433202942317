<div class="amp-dialog">
  <p class="amp-dialog-title">Defina o Identificador da Pergunta</p>
  <div class="amp-dialog-subtitle">
    <div class="amp-box-warning">
      <b>Atenção:</b> Para poder somar resultados de perguntas em uma só métrica, elas <b>precisam ser do mesmo tipo</b> e ter <b>exatamente o mesmo</b> nome interno.
      <br>
      Não é possível somar para visualizar perguntas com nomes internos diferentes.
    </div>
    <br>
    Este identificador é apenas para referência interna e para identificação nos paineis. Ele <b>não aparecerá</b> para o respondente da pesquisa.
    <br>
    <br>
    <b>Exemplos:</b> nps_atendimento, csat_plataforma, ces_produto
  </div>
  <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
    <div class="amp-field">
      <label class="amp-label-text" for="role">Identificador da Pergunta</label>
      <input
        type="text"
        class="amp-input"
        placeholder="Ex.: nps_atendimento"
        [(ngModel)]="internalName"
        #nome="ngModel"
        name="nome"
        (keyup)="validateInternalName($event)"
        required
      />
    </div>
    <div class="amp-field" *ngIf="questionType && questionType == 'likert'">
      <label class="amp-label-text" for="role">Tipo da Escala</label>

      <amp-select placeholder="Selecione" [options]="likertOptions" [(value)]="typeScale"></amp-select>
    </div>
    <div class="amp-buttons">
      <button class="amp-btn-success" type="submit" [disabled]="!form.form.valid">Confirmar</button>
      <button type="button" class="amp-btn-outline-cancel" (click)="closeModal()">Cancelar</button>
    </div>
  </form>
</div>
