import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Campaign } from 'app/_models/referral/campaign';
@Component({
  selector: 'app-snippet-referral',
  templateUrl: './snippet-referral.component.html',
  styleUrls: ['./snippet-referral.component.scss']
})
export class SnippetReferralComponent implements OnInit {
  public campaign: Campaign;
  public script;
  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.script = `<!-- Begin Amplifique.me Referral Milestones Script -->\n <script type="text/javascript">\nwindow.amplReferralData = {\ncampaign: '${this.campaign._id}',\n}\n</script>\n<script type="text/javascript" src="https://referral.amplifique.me/assets/amp-referral-sdk.js"></script>\n<script type="text/javascript">\nAmplReferral.run('run');\n</script>\n<!-- End Amplifique.me Referral Milestones Script -->`;

    if (this.campaign.script_settings.isCustomForm) {
      this.script =
        `<!-- Begin Amplifique.me Referral Script -->\n <script>!function(e,a,r,n,m,o=!1,t=!1,l={}){e[n]=e[n]||{campaign:m,liteMode:o,formMode:t,...l};var i=a.getElementsByTagName(r)[0],s=a.createElement(r);s.async=!0,s.onload=function(){e.AmplReferral.run()},s.src="https://referral.amplifique.me/assets/amp-referral-sdk.js",i.parentNode.insertBefore(s,i)}(window,document,"script","amplReferralData","${this.campaign._id}",${this.campaign.script_settings.isCustomForm},${this.campaign.script_settings.isCustomForm}, {formTag:'` +
        this.campaign.script_settings.tagForm +
        `',emailTag:'` +
        this.campaign.script_settings.tagFieldEmail +
        `',nameTag:'` +
        this.campaign.script_settings.tagFieldName +
        `'});</script>\n<!-- End Amplifique.me Referral Script -->`;
    } else {
      this.script = ` <!-- Begin Amplifique.me Referral  Script -->\n<script>!function(e,a,r,n,m,o=!1,t=!1,l={}){e[n]=e[n]||{campaign:m,liteMode:o,formMode:t,...l};var i=a.getElementsByTagName(r)[0],s=a.createElement(r);s.async=!0,s.onload=function(){e.AmplReferral.run()},s.src="https://referral.amplifique.me/assets/amp-referral-sdk.js",i.parentNode.insertBefore(s,i)}(window,document,"script","amplReferralData","${this.campaign._id}");</script>\n<!-- End Amplifique.me Referral Script -->`;
    }
  }
}
