<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-plus-lg"></span>
      Novo Programa de Indicação
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-stepper">
      <div class="amp-step" (click)="step=0" [ngClass]="{'active': step==0}">
        <span class="bi-columns-gap"></span><span class="amp-step-text">Sobre o Programa</span>
      </div>
      <div class="amp-step" (click)="step=1" [ngClass]="{'active': step==1}">
        <span class="bi-bookmark"></span><span class="amp-step-text">Recompensas</span>
      </div>

      <div class="amp-step" (click)="step=2" [ngClass]="{'active': step==2}">
        <span class="bi-display"></span><span class="amp-step-text">Tela/Widget</span>
      </div>

      <div class="amp-step" (click)="step=3" [ngClass]="{'active': step==3}">
        <span class="bi-send"></span><span class="amp-step-text">Email</span>
      </div>

      <div class="amp-step" (click)="step=4" [ngClass]="{'active': step==4}">
        <span class="bi-share"></span><span class="amp-step-text">SMS/Compartilhamento</span>
      </div>
    </div>

    <div [ngSwitch]="step">
      <ng-container *ngSwitchCase="0">
        <ng-container *ngTemplateOutlet="CampaignInformation"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="CampaignRewards"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="CampaignWidget"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <ng-container *ngTemplateOutlet="CampaignEmail"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="4">
        <ng-container *ngTemplateOutlet="CampaignSmsShare"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #CampaignInformation>
  <form (ngSubmit)="form.form.valid && fmCampaignInformationSubmit()" #form="ngForm">
    <div class="row">

      <div class="col-xl-6 mx-auto">
        <p class="amp-guide-text">As informações abaixo são importantes para a configuração do Programa de Indicação, preencha todas com clareza.</p>
        <div class="amp-field">
          <label class="amp-label-text" for="title">Título do Programa</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="campaign.title" autocomplete="off" placeholder=""
            #title="ngModel" name="title" title required>
        </div>

        <p class="amp-guide-text">Quais canais de solicitação para esse Programa de Indicação deseja
          habilitar? Marque uma ou mais opções.</p>
        <div class="amp-options">
          <label class="amp-label-text" for="checkbox">Canais de solicitação</label>
          <label class="amp-checkbox">
            <input type="checkbox" [checked]="this.campaign.channels.indexOf('sms') > -1"
              (change)="toggleChannel('sms')">
            <span class="text">SMS</span>
            <span class="checkmark"></span>
          </label>
          <label class="amp-checkbox">
            <input type="checkbox" [checked]="this.campaign.channels.indexOf('e-mail') > -1"
              (change)="toggleChannel('e-mail')">
            <span class="text">E-mail</span>
            <span class="checkmark"></span>
          </label>
          <label class="amp-checkbox">
            <input type="checkbox" [checked]="this.campaign.channels.indexOf('widget') > -1"
              (change)="toggleChannel('widget')">
            <span class="text">Widget</span>
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="amp-field amp-select-new">
          <label class="amp-label-text" for="triggerPromoterType">Tipo de verificação</label>
          <select class="amp-input-lg" [(ngModel)]="campaign.verification_mode" autocomplete="off"
            #verificationMode="ngModel" name="verification_mode" required>
            <option [value]="null" disabled selected>Selecione o tipo de verificação</option>
            <option value="none">Sem verificação</option>
            <option value="sms">SMS (verificação do celular)</option>
            <option value="email">E-mail (verificação do e-mail)</option>
          </select>
          <span class="bi-chevron-down"></span>
        </div>

        <div class="amp-select-business-wrapper">
          <label class="amp-label-text">Negócios participantes do Programa de Indicação</label>
          <div class="amp-select-business">
            <div class="amp-select-business-header">
              <label class="amp-checkbox">
                <input type="checkbox" [checked]="selectAllBusinesses"
                  (change)="selectAllBusiness()">
                <span class="text">Selecionar Todos</span>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="amp-select-business-content">
              <label class="amp-checkbox" *ngFor="let business of businessesList">
                <input type="checkbox" [checked]="campaign._businesses.includes(business._id)" (change)="toggleBusiness(business._id)">
                <span class="text">{{business.nome}}</span>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Status do Programa</label>
          <input id="amp-switch-status-campaign" [(ngModel)]="campaign.active" name="campaignStatus"
            #campaignStatus="ngModel" type="checkbox" class="amp-switch">
          <label for="amp-switch-status-campaign" class="amp-switch-label"></label>
          <label for="amp-switch-status-campaign" class="amp-switch-status-text" *ngIf="campaign.active">Ativado</label>
          <label for="amp-switch-status-campaign" class="amp-switch-status-text"
            *ngIf="!campaign.active">Desativado</label>
        </div>

      </div>
    </div>

    <div class="amp-dialog-actions amp-dialog-actions-lg">
        <button type="button" *ngIf="!isNew && !loadingSave" (click)="fmCampaignSmsShareSubmit()" class="amp-btn-gradient1 amp-btn-lg">Finalizar<span
          class="bi-check-lg"></span></button>
          <button type="button" class="amp-btn-gradient1 amp-btn-lg" *ngIf="!isNew && loadingSave" disabled>Processando<span class="loading-ellipsis">...</span></button>
      <button type="submit" [disabled]="!form.form.valid || !this.campaign.channels.length || !this.campaign._businesses.length"
        class="amp-btn-outline-primary amp-btn-lg">Continuar<span class="bi-chevron-right"></span></button>
    </div>
  </form>
</ng-template>
 
<ng-template #CampaignRewards>
  <form (ngSubmit)="form.form.valid && fmCampaignRewardsSubmit()" #form="ngForm">
    <div class="row">
      <div class="col-lg-6">
        <p class="amp-guide-text">Informações sobre a recompensa / cupom para o contato que <b>indicou</b> outro</p>
        <div class="amp-field">
          <label class="amp-label-text" for="title">Descrição da recompensa / cupom</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="campaign.reward_referrer.name" autocomplete="off"
            placeholder="" #rewardRefererr="ngModel" name="rewardRefererr" title required>
        </div>

        <div class="amp-field">
          <label class="amp-label-text" for="title">Tempo de Expiração (para cupom sem tempo de expiração, utilize valor
            "0")</label>
          <input type="number" min="0" class="amp-input-lg" [(ngModel)]="campaign.reward_referrer.expiration"
            autocomplete="off" placeholder="Número de dias de validade do cupom" #rewardReferrerExpiration="ngModel"
            name="rewardReferrerExpiration" required>
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Instruções de uso</label>
          <textarea required class="amp-input"
            placeholder="Instruções para uso da recompensa / cupom para o contato que indicou outro"
            [(ngModel)]="campaign.reward_referrer.instructions" #referrerInstructions="ngModel"
            name="referrerInstructions" rows="3" minlength="1" maxlength="300"></textarea>
          <span class="amp-label-charcount">{{campaign.reward_referrer.instructions.length}}/300</span>
        </div>

      </div>
      <div class="col-lg-6">
        <p class="amp-guide-text">Informações sobre a recompensa / cupom para o contato que <b>foi indicado</b> por outro</p>
        <div class="amp-field">
          <label class="amp-label-text" for="title">Descrição da recompensa / cupom</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="campaign.reward_referee.name" autocomplete="off"
            placeholder="" #rewardReferee="ngModel" name="rewardReferee" title required>
        </div>

        <div class="amp-field">
          <label class="amp-label-text" for="title">Expiração (para cupom sem tempo de expiração, utilize valor
            "0")</label>
          <input type="number" min="0" class="amp-input-lg" [(ngModel)]="campaign.reward_referee.expiration"
            autocomplete="off" placeholder="Número de dias de validade do cupom" #rewardRefereeExpiration="ngModel"
            name="rewardRefereeExpiration" required>
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Instruções de uso</label>
          <textarea required class="amp-input"
            placeholder="Instruções para uso da recompensa / cupom para o contato que foi indicado por outro"
            [(ngModel)]="campaign.reward_referee.instructions" #refereeInstructions="ngModel" name="refereeInstructions" 
            rows="3" minlength="1" maxlength="300"></textarea>
          <span class="amp-label-charcount">{{campaign.reward_referee.instructions.length}}/300</span>
        </div>
      </div>
    </div>


    <div class="amp-dialog-actions">
      <button (click)="step=0" class="amp-btn-link amp-back-link" type="button"><span
          class="bi-chevron-left"></span>Voltar</button>
      <button type="submit" [disabled]="!form.form.valid " class="amp-btn-outline-primary amp-btn-lg">Continuar<span
          class="bi-chevron-right"></span></button>
    </div>
  </form>

</ng-template>


<ng-template #CampaignWidget>
  <form (ngSubmit)="form.form.valid &&  fmCampaignWidgetSubmit()" #form="ngForm">
    <div class="row">
      <div class="col-lg-6">

        <p class="amp-guide-text">Os campos abaixo definem os conteúdos exibidos para o usuário na tela de indicação e no widget. Todo o texto é formatado em <b>Markdown</b>, caso haja dúvidas sobre como escrever, <a href="https://github.com/luong-komorebi/Markdown-Tutorial/blob/master/README_pt-BR.md#syntax" rel="noopener" target="_blank">este link pode ajudar</a>.</p>
        <div class="amp-field">
          <label class="amp-label-text">Conteúdo da aba "Como Funciona"</label>
          <textarea required class="amp-input"
            placeholder="Texto para instruir o contato sobre o programa de indicação e suas regras, e convidá-lo a participar"
            [(ngModel)]="campaign.content_about" #contentAbout="ngModel" name="contentAbout" rows="5" minlength="1"
            maxlength="1000"></textarea>
          <span class="amp-label-charcount">{{campaign.content_about.length}}/1000</span>
        </div>

        <div class="amp-field"> 
          <label class="amp-label-text">Conteúdo da aba "Indicar Amigos"</label>
          <textarea required class="amp-input"
            placeholder="Conteúdo exibido acima dos botões de compartilhamento"
            [(ngModel)]="campaign.content_refer" #contentShare="ngModel" name="contentShare" rows="4" minlength="1"
            maxlength="500"></textarea>
          <span class="amp-label-charcount">{{campaign.content_refer.length}}/500</span>
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Conteúdo da aba "Meus Cupons"</label>
          <textarea required class="amp-input"
            placeholder="Conteúdo exibido acima dos cupons do contato"
            [(ngModel)]="campaign.content_coupons" #contentCoupon="ngModel" name="contentCoupon" rows="4" minlength="1"
            maxlength="500"></textarea>
          <span class="amp-label-charcount">{{campaign.content_coupons.length}}/500</span>
        </div>

        <div class="amp-field">
          <label class="amp-label-text" for="description">Descrição para pessoas que entram na página do Programa, sem terem sido indicadas ou estarem indicando</label>
          <input type="text" class="amp-input-lg" [(ngModel)]="campaign.description" autocomplete="off" placeholder="Esta mensagem aparecerá para pessoas que acessem o programa de indicação sem terem sido indicadas ou estarem indicando."
            #description="ngModel" name="description" required>
        </div>

        <div class="amp-field" *ngIf="campaign.channels.includes('widget')">
          <label class="amp-label-text" for="termsUrl">Site onde o widget será instalado</label>
          <input type="url" class="amp-input-lg" [(ngModel)]="campaign.widget_url" autocomplete="off" placeholder=""
            #widgetUrl="ngModel" name="widgetUrl" required>
        </div>
        <br>
        <br>
        <p class="amp-guide-text">É importante escolher cores que estejam de acordo com a identidade da empresa e garantam legibilidade.</p>
        <div class="amp-color-selector-input">
          <label class="amp-label-text">Cor Primária</label>
          <div class="amp-color-selector-wrapper">
            <div class="amp-color-selector-name" id="colorPicker" (click)="togglePickerPrimaryColor()">
              {{campaign.primary_color}}</div>
            <div class="amp-color-selector" id="colorPicker" (click)="togglePickerPrimaryColor()"
              [ngStyle]="{'background': campaign.primary_color}"></div>


            <div class="amp-colorpicker" *ngIf="primaryColorSelector" clickOutside
              (clickOutside)="togglePickerPrimaryColor()">
              <color-block [color]="campaign.primary_color" [colors]="colors" width="170"
                (onChangeComplete)="changePrimaryColor($event)">
              </color-block>
            </div>
          </div>
        </div>

        <div class="amp-color-selector-input">
          <label class="amp-label-text">Cor do Texto</label>
          <div class="amp-color-selector-wrapper">
            <div class="amp-color-selector-name" id="colorPicker" (click)="togglePickerTextColor()">
              {{campaign.text_color}}</div>
            <div class="amp-color-selector" id="colorPicker" (click)="togglePickerTextColor()"
              [ngStyle]="{'background': campaign.text_color}"></div>


            <div class="amp-colorpicker" *ngIf="textColorSelector" clickOutside
              (clickOutside)="togglePickerTextColor()">
              <color-block [color]="campaign.text_color" [colors]="colorsText" width="170"
                (onChangeComplete)="changeTextColor($event)">
              </color-block>
            </div>
          </div>
        </div>

        <div class="amp-field amp-radio-wrapper" *ngIf="campaign.channels.includes('widget')">
          <label class="amp-label-text">Posição do Widget na tela</label>
          <label class="amp-radio-button">
            <input type="radio" checked="checked" required name="widgetPosition" value="left"
              [(ngModel)]="campaign.widget_position">
            <span class="text">Esquerda</span>
            <span class="checkmark"></span>
          </label>
          <label class="amp-radio-button">
            <input type="radio" name="widgetPosition" required value="right" [(ngModel)]="campaign.widget_position">
            <span class="text">Direita</span>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="col-lg-6">
        <amp-referral-widget-preview [color]="campaign.primary_color" [textColor]="campaign.text_color" [campaign]="campaign" type="referral"></amp-referral-widget-preview>
      </div>
    </div>

    <div class="amp-dialog-actions">
      <button (click)="step=1" class="amp-btn-link amp-back-link" type="button"><span
          class="bi-chevron-left"></span>Voltar</button>
      <button type="submit" [disabled]="!form.form.valid" class="amp-btn-outline-primary amp-btn-lg">Continuar<span
          class="bi-chevron-right"></span></button>
    </div>
  </form>
</ng-template>

<ng-template #CampaignEmail>
  <form (ngSubmit)="form.form.valid && fmCampaignEmailSubmit()" #form="ngForm">
    <div class="row">
      <div class="col-lg-6">
        <div class="amp-field">
          <label class="amp-label-text">Mensagem principal do E-mail</label>
          <textarea required class="amp-input" placeholder="" [(ngModel)]="campaign.email_message_1"
            #emailMessage1="ngModel" name="emailMessage1" rows="3" minlength="1" maxlength="140"></textarea>
          <span class="amp-label-charcount">{{campaign.email_message_1.length}}/140</span>
        </div>

        <div class="amp-field">
          <label class="amp-label-text">Mensagem secundária do E-mail</label>
          <textarea required class="amp-input" placeholder="" [(ngModel)]="campaign.email_message_2"
            #emailMessage2="ngModel" name="emailMessage2" rows="3" minlength="1" maxlength="500"></textarea>
          <span class="amp-label-charcount">{{campaign.email_message_2.length}}/500</span>
        </div>
      </div>
      <div class="col-lg-6">
          <amp-referral-email-preview [bgColor]="campaign.primary_color" [fontColor]="campaign.text_color" [picture]="store.business.picture" [message1]="campaign.email_message_1"
          [message2]="campaign.email_message_2"></amp-referral-email-preview>
      </div>
    </div>
    <div class="amp-dialog-actions">
      <button (click)="step=2" class="amp-btn-link amp-back-link" type="button"><span
          class="bi-chevron-left"></span>Voltar</button>
      <button type="submit" [disabled]="!form.form.valid " class="amp-btn-outline-primary amp-btn-lg">Continuar<span
          class="bi-chevron-right"></span></button>
    </div>
  </form>
</ng-template>

<ng-template #CampaignSmsShare>
  <form (ngSubmit)="form.form.valid && fmCampaignSmsShareSubmit()" #form="ngForm">
    <div class="row">
      <div class="col-xl-7 col-lg-6 amp-col-sms">
        <div class="amp-field">
          <label class="amp-label-text">Mensagem para a convidar para participar do Programa por SMS</label>
          <textarea required class="amp-input"
            placeholder="Escreva a mensagem que seu contato receberá via SMS para participar do Programa de Indicação"
            [(ngModel)]="campaign.sms_message" (focus)="referralSmsShareFocus = true" #smsMessage="ngModel" name="smsMessage"
            rows="3" minlength="1" maxlength="120"></textarea>
          <span class="amp-label-charcount">{{campaign.sms_message.length}}/120</span>
        </div>
        <div class="amp-field">
          <label class="amp-label-text">Mensagem de Compartilhamento do Programa</label>
          <textarea required class="amp-input"
            placeholder="Escreva a mensagem que precede o link quando um usuário indicar amigos"
            [(ngModel)]="campaign.share_message" (focus)="referralSmsShareFocus = false" #shareMessage="ngModel"
            name="shareMessage" rows="3" minlength="1" maxlength="140"></textarea>
          <span class="amp-label-charcount">{{campaign.share_message.length}}/140</span>
        </div>
      </div>
      <div class="col-xl-5 col-lg-6">
        <amp-mockup-phone [text]="referralSmsShareFocus ? campaign.sms_message : campaign.share_message">
        </amp-mockup-phone>
      </div>
    </div>

    <div class="amp-dialog-actions">
        <button (click)="step=3" class="amp-btn-link amp-back-link" type="button"><span
            class="bi-chevron-left"></span>Voltar</button>
            <button type="submit" [disabled]="!form.form.valid" *ngIf="!loadingSave" class="amp-btn-gradient1 amp-btn-lg">Finalizar<span
              class="bi-check-lg"></span></button>
              <button type="button" class="amp-btn-gradient1 amp-btn-lg" *ngIf="!isNew && loadingSave" disabled>Processando<span class="loading-ellipsis">...</span></button>
      </div>
  </form>
</ng-template>
