import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
@Injectable()
export class ReferralService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  create(businessId: string, data) {
    return this.http.post<any>(this.config.apiUrl + '/referral/referral/' + businessId, data);
  }

  redeemReferrer(_referral: string, business) {
    return this.http.put<any>(this.config.apiUrl + '/referral/referral/' + _referral + '/referrer', { business });
  }
  redeemReferrerBatch(_referrals: string[], business) {
    return this.http.put<any>(this.config.apiUrl + '/referral/referral/referrer', { referrals: _referrals, business });
  }

  redeemReward(_reward: string, business) {
    return this.http.put<any>(this.config.apiUrl + '/referral/referral/' + _reward + '/reward', { business });
  }

  validateReferral(_referral: string, business, status = 'valid', amount = 0, statusMessage?) {
    const query = new URLSearchParams({
      status
    });

    return this.http.put<any>(this.config.apiUrl + '/referral/referral/' + _referral + '/referee?' + query.toString(), {
      business,
      referral_status_message: statusMessage,
      amount
    });
  }

  redeemRewardBatch(_rewards: string[], business) {
    return this.http.put<any>(this.config.apiUrl + '/referral/referral/reward', { rewards: _rewards, business });
  }

  validateReferralBatch(_referrals: string[], business, status = 'valid', amount = 0, statusMessage?) {
    const query = new URLSearchParams({
      status
    });

    return this.http.put<any>(this.config.apiUrl + '/referral/referral/referee?' + query.toString(), {
      referrals: _referrals,
      business,
      referral_status_message: statusMessage,
      amount
    });
  }

  stats(_business: string) {
    return this.http.get<any>(this.config.apiUrl + '/referral/referral/stats/' + _business);
  }
}
