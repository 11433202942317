import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterPipe'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (filter.field == '') {
      if (!filter.value || filter.value == 'null') {
        return items;
      } else {
        return items.filter((item) =>
          item
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              filter.value
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        );
      }
    }

    if (((!filter.value || filter.value == 'null') && (!filter.value2 || filter.value2 == 'null')) || !items) {
      return items;
    }

    if (filter.type == 'and' && filter.value?.length > 0) {
      return items.filter(
        (item) =>
          (filter.field && filter.value
            ? item[filter.field]
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  filter.value
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            : false) &&
          (filter.field2 && filter.value2
            ? item[filter.field2]
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  filter.value2
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            : false)
      );
    } else {
      return items.filter(
        (item) =>
          (filter.field && filter.value
            ? item[filter.field]
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  filter.value
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            : false) ||
          (filter.field2 && filter.value2
            ? item[filter.field2]
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  filter.value2
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            : false)
      );
    }
  }
}
