import { NotifyService } from '../_services/notify.service';
import { MenuService } from '../_services/menu.service';
import { User, Role } from '../_models/user';
import { UserService } from '../_services/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, ForgotPasswordService } from '../_services/index';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'app/_services/store.service';
import moment from 'moment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private store: StoreService,
    public notifyService: NotifyService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private forgotPasswordService: ForgotPasswordService,
    private menuService: MenuService
  ) {
    this.menuService.emit(false);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    if (this.authenticationService.isExpired()) {
      this.subscriptions.push(
        this.translate.get('notify.login.session_expired').subscribe((res) => {
          this.notifyService.error(res);
        })
      );
    }

    this.authenticationService.logout();
  }

  public model: any = {};
  public loading = false;
  public fail = false;
  public returnUrl: string;
  public forgotPassword: any = 0;
  public fp: any = {};
  public isTokenExpired = false;
  public showPassword = false;
  public authenticator = false;
  public token = '';
  public userId = '';
  public subscriptions = [];
  public hasDot = false;
  public maskPin = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  ngOnInit() {
    this.model.email = this.route.snapshot.queryParams.email || '';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;

    const field = document.getElementById('password') as HTMLInputElement;

    if (field.type == 'password') {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
  }

  checkDot() {
    if (this.model.email.split('@')[1]?.includes('.')) {
      this.hasDot = true;
    } else {
      this.hasDot = false;
    }
  }

  loginWithMicrosoft() {
    window.location.href = 'https://api.amplifique.me/user/msal/auth';
  }

  login() {
    this.loading = true;
    this.fail = false;
    this.subscriptions.push(
      this.authenticationService.login(this.model.email, this.model.senha, this.model.keepMeLoggedIn).subscribe(
        (data) => {
          if (data.result == 1) {
            this.store.empresa = data.user._empresa;
            this.proccessAfterLogin();
          } else if (data.result == -1) {
            this.store.empresa = data.user._empresa;
            this.authenticator = true;
            this.loading = false;
            this.userId = data.user;
          } else {
            this.fail = true;
            this.loading = false;
            this.subscriptions.push(
              this.translate.get('notify.login.login_error').subscribe((res) => {
                this.notifyService.error(res);
              })
            );
          }
        },
        (error) => {
          console.error(error);

          this.fail = true;
          this.loading = false;

          if (error.status == 429) {
            const dur = moment.duration(error.error['retryAfter'], 's').as('milliseconds');
            this.notifyService.error(`Bloqueado! Tente novamente em ${moment.utc(dur).hours()} horas e ${moment.utc(dur).minutes()} minutos.`);
            console.error(error.error['retryAfter']);
          } else {
            if (error.error.message == 'MSAL_AUTH') {
              this.notifyService.error('Essa conta exige autenticação utilizando o Login Federado do Microsoft Entra ID (Azure AD)');
              return;
            }

            if (error.error.message == 'USER_DEACTIVATED') {
              this.notifyService.error('Este usuário foi desativado.');
              return;
            }

            this.subscriptions.push(
              this.translate.get('notify.login.login_error').subscribe((res) => {
                this.notifyService.error(res);
              })
            );
          }
        }
      )
    );
  }

  openChat() {
    this.menuService.emitChat(true);
  }

  esqueciSenha() {
    this.fail = false;
    this.subscriptions.push(
      this.forgotPasswordService.create(this.fp).subscribe(
        () => {
          this.forgotPassword = 0;
          this.subscriptions.push(
            this.translate.get('notify.forgot-password.success').subscribe((res) => {
              this.notifyService.success(res);
            })
          );
        },
        (error) => {
          console.error(error);
          this.fail = true;
        }
      )
    );
  }

  changeToken(data) {
    this.token = data;
    if (data.length == 6) {
      this.validateAuthenticator();
    }
  }

  validateAuthenticator() {
    if (!this.loading) {
      this.loading = true;
      this.subscriptions.push(
        this.authenticationService.validateAuthenticatorToken(this.userId, this.token, this.model.keepMeLoggedIn).subscribe(
          (data) => {
            this.store.empresa = data.user._empresa;
            localStorage.setItem('access_token', data.token);
            this.authenticationService.getTokenPayload();
            this.proccessAfterLogin();
          },
          (error) => {
            console.error(error);
            this.loading = false;
            this.fail = true;
            if (error.error.message == 'TOKEN_INVALID') {
              this.notifyService.error('Token incorreto!');
            } else {
              this.notifyService.error('Não foi possível validar o Token');
            }
          }
        )
      );
    }
  }

  proccessAfterLogin() {
    this.userService.getInfo().subscribe((dadosUser: User) => {
      if (dadosUser.role == Role.mf) {
        this.returnUrl = '/ampemp';
      } else {
        localStorage.setItem('empresaNome', dadosUser._empresa.nome);
        localStorage.setItem('empresaId', dadosUser._empresa._id);
        this.store.empresa = dadosUser._empresa._id;
        localStorage.setItem('empresaWizard', dadosUser._empresa.wizard.toString());
      }
      this.menuService.emitUser(dadosUser);

      this.returnUrl = decodeURIComponent(this.returnUrl);
      const route = this.returnUrl.split('?');
      this.returnUrl = route[0];
      let params = {};
      if (route.length > 1) {
        params = Object.entries(new URLSearchParams(route[1]));
      }

      return this.router.navigate([this.returnUrl], { queryParams: params });
    });
  }
}
