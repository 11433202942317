<section id="login" class="row">
  <div class="col login-card-wrapper">
    <img src="/assets/img/logo_amplifiqueme.svg">
    <div class="amp-card login__card">
      <div class="amp-card-content">
        <div class="animate__animated">
          <h3 *ngIf="!loading && !error" class="text-center">Bem-vindo!</h3>
          <p *ngIf="!loading && !error">Insira os dados abaixo para criar a sua conta da Amplifique.me</p>

          <amp-loading *ngIf="loading"></amp-loading>

          <div class="amp-error" *ngIf="!loading && error">
            <i class="bi-exclamation-triangle-fill"></i>
            <span class="amp-text">NÃO FOI POSSÍVEL CARREGAR AS INFORMAÇÕES</span>
          </div>
          <form *ngIf="!loading && !error" (ngSubmit)="f.form.valid && create()" #f="ngForm" novalidate>
            <div class="amp-field">
              <label class="amp-label-text" for="nome">Nome</label>
              <input type="text" class="amp-input-lg" [(ngModel)]="user.nome" placeholder="Seu nome" #name="ngModel" name="name" required>
            </div>
            <div class="amp-field">
              <label class="amp-label-text" for="email">E-mail</label>
              <input type="email" class="amp-input-lg" [(ngModel)]="user.email" autocomplete="username" placeholder="Seu e-mail" #email="ngModel" name="email" email required disabled>
            </div>
            <div class="amp-field">
              <label class="amp-label-text" for="phone">Celular</label>
              <amp-phone-input [(phone)]="user.phone"></amp-phone-input>
            </div>
            <div class="amp-field amp-password">
              <label class="amp-label-text" for="senha">Senha</label>
              <input type="password" id="password" class="amp-input-lg" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!#%*?&])[A-Za-z\d$@$!#%*?&].{7,}" clickOutside (clickOutside)="dropdownFilter.hide()" (focus)="dropdownFilter.toggle(true)" (keyup)="onContactChange()" placeholder="Sua senha" [(ngModel)]="user.senha" #password="ngModel" name="password" required />
              <i *ngIf="showPassword" (click)="togglePassword()" class="bi-eye"></i>
              <i *ngIf="!showPassword" (click)="togglePassword()" class="bi-eye-slash"></i>
            </div>
            <div dropdown [autoClose]="false" class="amp-password-validator dropdown" #dropdownFilter="bs-dropdown" placement="left" aria-controls="filter-dropdown">
              <div class="dropdown-menu dropdown-menu-left sortOptions" id="passwordValidator" *dropdownMenu role="menu" aria-labelledby="dropdownFilter" (click)="stopCloseDropdown($event)">
                <password-validator [password]="user.senha"></password-validator>
              </div>
            </div>
            <div class="amp-terms-of-use">
              <label class="amp-checkbox">
                <input [(ngModel)]="termsOfUse" type="checkbox" name="termsOfUse" required>
                <span class="text">Declaro que li e aceito os <a target="_blank" rel="noopener" href="https://amplifique.me/termos-de-uso/">Termos de Uso </a>e aceito receber notificações via WhatsApp e RCS.</span>
                <span class="checkmark"></span>
              </label>
            </div>
            <button [disabled]="!termsOfUse || !user.nome || !user.phone || !user.senha || !checkPassword(user.senha)" type="submit" class="amp-btn-lg">Criar Conta</button>
            <!-- <p class="amp-create-account">Já possui uma conta?</p>
            <button routerLink="/l" class="amp-btn-outline-secondary amp-entrar">Entrar</button> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
