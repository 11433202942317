import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { User } from 'app/_models/user';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll() {
    return this.http.get<User[]>(this.config.apiUrl + '/user');
  }

  getByEmpresa(_id: string, business?: string, filter?: string) {
    if (business) {
      return this.http.post<User[]>(this.config.apiUrl + '/user/empresa/' + _id, { businesses: business });
    }
    if (filter) {
      return this.http.get<User[]>(this.config.apiUrl + '/user/empresa/' + _id, {
        params: {
          filter: filter
        }
      });
    }
    return this.http.get<User[]>(this.config.apiUrl + '/user/empresa/' + _id, {});
  }

  getById(_id: string) {
    return this.http.get<User>(this.config.apiUrl + '/user/' + _id);
  }

  getInfo() {
    return this.http.get<User>(this.config.apiUrl + '/user/info/');
  }

  create(user: any, signupId) {
    return this.http.post<User>(this.config.apiUrl + '/user/?signupInfoId=' + signupId, user);
  }

  update(user: any) {
    return this.http.put<User>(this.config.apiUrl + '/user/' + user._id, user);
  }
  updateSelf(user: any) {
    return this.http.put<User>(this.config.apiUrl + '/user/self/' + user._id, user);
  }

  updatePassword(user: any, idFp: string) {
    return this.http.put<User>(this.config.apiUrl + '/user/fp/' + user._id + '/?idFp=' + idFp, user);
  }

  delete(_id: string) {
    return this.http.delete<User>(this.config.apiUrl + '/user/' + _id);
  }

  createPublic(data, gclid, queryParams) {
    const params = new URLSearchParams();
    params.append('utm_content', queryParams.utm_content);
    params.append('utm_term', queryParams.utm_term);
    params.append('utm_campaign', queryParams.utm_campaign);
    params.append('utm_source', queryParams.utm_source);
    params.append('utm_medium', queryParams.utm_medium);
    params.append('gclid', queryParams.gclid);

    return this.http.post<User>(this.config.apiUrl + '/user/public?' + params.toString(), data);
  }

  updatePublic(userId, data) {
    return this.http.put<User>(this.config.apiUrl + '/user/public/' + userId, data);
  }

  getAuthenticatorSecret() {
    return this.http.post<any>(this.config.apiUrl + '/user/authenticator/', {});
  }

  enableAuthenticator(token) {
    return this.http.put<any>(this.config.apiUrl + '/user/authenticator/enable', { token });
  }

  disableAuthenticator() {
    return this.http.put<any>(this.config.apiUrl + '/user/authenticator/disable', {});
  }

  signinTerms(userId, version) {
    return this.http.put<any>(this.config.apiUrl + `/user/${userId}/terms/${version}`, {});
  }

  signinPrivacy(userId, version) {
    return this.http.put<any>(this.config.apiUrl + `/user/${userId}/privacy/${version}`, {});
  }
}
