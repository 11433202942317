<section>
  <div class="description">
    Aqui você consegue verificar o status das exportações em lote da sua conta.
  </div>
  <div class="amp-action-bar">
    <div class="amp-actions">
      <button class="amp-btn-primary amp-btn-lg"
        *ngIf="auth.userRole == 'acmng'" (click)="adminMode = !adminMode; this.getExports()">
        {{ adminMode ? 'Desativar Modo Admin' : 'Ativar Modo Admin' }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="amp-card">
        <div class="amp-table">
          <div class="amp-table-head">
            <div class="amp-table-column exports-business" *ngIf="adminMode">Unidade</div>
            <div class="amp-table-column exports-user" *ngIf="adminMode">Usuário</div>
            <div class="amp-table-column exports-type">Tipo</div>
            <div class="amp-table-column exports-link">Link</div>
            <div class="amp-table-column exports-date">Data de Solicitação</div>
            <div class="amp-table-column exports-status">Status</div>
            <div class="amp-table-column exports-data">Dados</div>
            <div class="amp-table-column exports-progress">Progresso</div>
          </div>

          <div class="amp-table-row amp-webhook-row" *ngFor="let item of exports"
            [ngClass]="{active: item._id === this.exportDataId}">
            <div class="amp-table-cell" *ngIf="adminMode">
              <b>{{ item._business?._empresa.nome }} - </b>{{ item._business?.nome }}
            </div>
            <div class="amp-table-cell" *ngIf="adminMode">{{ item._user.nome }}</div>
            <div class="amp-table-cell">{{ 'export-type_' + item.type | translate }}</div>

            <div class="amp-table-cell">
              <div *ngIf="item.status == 'finished'">
                <a tooltip="Este link expirará em 7 dias!" [href]="item.file" target="_blank" *ngIf="checkExpiration(item.ended_at) < 7">Baixar</a>
                <span class="item-expired" *ngIf="checkExpiration(item.ended_at) >= 7">Link Expirado</span>
              </div>
              <div *ngIf="item.status != 'finished'"> - </div>
            </div>
            
            <div class="amp-table-cell">{{ item.created_at | date: 'dd/MM/yy HH:mm:ss' }}</div>
            
            <div class="amp-table-cell">
              <span [classList]="'amp-export-status ' + item.status">
                {{ 'export-status_' + item.status | translate }}
              </span>
            </div>
            <div class="amp-table-cell">
              {{ item.totalData }}
            </div>
            <div class="amp-table-cell">
              <amp-progress-bar [value]="item.progress * 100" [onlyBar]="true" color="green"></amp-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <pagination [(ngModel)]="paginationPage" [totalItems]="totalItems" [itemsPerPage]="20"
    [rotate]="true" [maxSize]="9" [boundaryLinks]="true" [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate" (pageChanged)="getExports($event.page)" [firstText]="'Primeira'"
    [lastText]="'Última'">
  </pagination>
</section>

<ng-template #nextTemplate>
  <span class="bi-chevron-right"></span>
</ng-template>

<ng-template #prevTemplate>
  <span class="bi-chevron-left"></span>
</ng-template>