import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { Template } from 'app/_models/adm/template';
@Injectable()
export class TemplateService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  create(data: Template) {
    return this.http.post<any>(this.config.apiUrl + '/adm/template/', data);
  }

  getAll() {
    return this.http.get<Template[]>(this.config.apiUrl + '/adm/template/');
  }

  update(data: Template) {
    return this.http.put<Template>(this.config.apiUrl + '/adm/template/' + data._id, data);
  }

  use(data: string) {
    return this.http.put<Template>(this.config.apiUrl + '/adm/template/use/' + data, {});
  }

  delete(data: Template) {
    return this.http.delete<any>(this.config.apiUrl + '/adm/template/' + data._id);
  }

  get(template: string) {
    return this.http.get<Template>(this.config.apiUrl + '/adm/template/' + template);
  }

  getByType(type: string) {
    return this.http.get<Template[]>(this.config.apiUrl + '/adm/template/' + type);
  }
}
