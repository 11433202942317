import { SmartTag } from './smartCluster/smartTags';
import { Survey } from './customerFeedback/survey';
import { ReviewPlatform } from './reviewPlatform';
import { Campaign } from './referral/campaign';
import { TagSet } from './smartCluster/tagSet';
import { Business } from './business';
import { Empresa } from './empresa';
import { User } from './user';
export class Dashboard {
  _id: string;
  name = 'Novo Painel';
  _user: User;
  created_at: Date;
  _business: Business;
  _empresa: Empresa | string;
  _campaign: Campaign[] = [];
  cards: DashboardCard[];
  reviewCards: DashboardReviewCard[];
  type: string;
  updated_at: Date;
}
export class DashboardCard {
  _id: string;
  name = '';
  _surveys: Survey[] = [];
  metric = '';
  internal_name = '';
  type = '';
  tags: SmartTag[] = [];
  tagSet: TagSet;
  filter_any = false;
  filters: CardFilter[] = [];
  evolutive_granularity: string;
  matrix_question_selected: number;
}
export class CardFilter {
  type = ''; // customer_tag, customer_customField, answer_customField, customer_company
  field = ''; // customFields case
  condition = ''; // equals, not_equals, contains, not_contains
  value: any; // filter value
}
export class DashboardReviewCard {
  _id: string;
  name = '';
  _reviewPlatforms: ReviewPlatform[] = [];
  _businesses: Business[] = [];
  subType = '';
  type = ''; //evolutive or overall or smart-tag
  tags: SmartTag[] = [];
  tagSet: TagSet;
}
