<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-toggle-on"></span>
      Alterar status da indicação
    </div>
    <div class="amp-dialog-subtitle">
      Para alterar o status dessa indicação, utilize os campos abaixo. Atenção! Ao alterar para "VALIDADA", não é possível alterar novamente para outro status.
    </div>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-field amp-select-new">
      <label class="amp-label-text" for="business">Selecionar Unidade</label>
      <select class="amp-input-lg" [(ngModel)]="business"  required>
        <option value="" disabled>Selecione uma unidade</option>
        <option *ngFor="let item of businessesList" [value]="item._id">{{item.nome}}</option>
      </select>
      <span class="bi-chevron-down"></span>
    </div>

    <div class="amp-field amp-select-new">
      <label class="amp-label-text" for="campaignType">Status da Indicação</label>
      <select class="amp-input-lg" [disabled]="oldStatus=='valid'" (change)="statusMessage = ''" [(ngModel)]="status" required>
        <option value="new" [disabled]="oldStatus!='new'">{{campaign.custom_status_new}}</option>
        <option value="pending" >{{campaign.custom_status_pending}}</option>
        <option value="valid">{{campaign.custom_status_valid}}</option>
        <option value="invalid">{{campaign.custom_status_invalid}}</option>
      </select>
      <span class="bi-chevron-down"></span>
    </div>

    <div class="amp-field">
      <label class="amp-label-text" for="description">Valor monetário da indicação (opcional)</label>
      <input type="number" class="amp-input-lg" [(ngModel)]="amount" placeholder="Ex.: R$ 199">
    </div>
  
    <div class="amp-field">
      <label class="amp-label-text" for="description">Motivo da alteração do Status (opcional, aparece para o participante)</label>
      <textarea class="amp-input" rows="3" [(ngModel)]="statusMessage" placeholder="Ex.: Compra efetuada segundo o regulamento."></textarea>
    </div>
  </div>

  <div class="amp-buttons">
    <button class="amp-btn-lg amp-btn-success" [disabled]="business == '' || status ==  ''" (click)="execute()">Alterar status</button>
    <button (click)="exit()" class="amp-btn-lg amp-btn-outline-secondary">Cancelar</button>
  </div>
</div>
