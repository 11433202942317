import { RequestQueueService } from 'app/_services/requestQueue.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StoreService } from 'app/_services';
import { filter } from 'rxjs';
@Component({
  selector: 'amp-delivery-progress-bar',
  templateUrl: './amp-delivery-progress-bar.component.html',
  styleUrls: ['./amp-delivery-progress-bar.component.scss']
})
export class AmpDeliveryProgressBarComponent implements OnInit, OnDestroy {
  constructor(public router: Router, private requestQueue: RequestQueueService, private store: StoreService) {}

  public sent;
  public total;
  public progress;
  public showBar = false;
  public interval = null;
  public subscriptions = [];

  ngOnInit() {
    this.getCurrentQueue();
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.getCurrentQueue();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getCurrentQueue() {
    if (this.store.business?._id == undefined) {
      setTimeout(() => {
        this.getCurrentQueue();
      }, 300);
      return;
    }
    const subscription = this.requestQueue.getCurrent(this.store?.business?._id, 1, 1).subscribe((data) => {
      if (data.data?.length > 0 && data.data[0]?.percentageStatus != 1 && data.data[0].type != 'nps' && data.data[0]?.scheduledSum >= 1) {
        this.progress = data.data[0].percentageStatus;
        this.sent = data.data[0].statusSum;
        this.total = data.data[0].total;
        if (!this.interval) {
          this.setupInterval();
        }
        this.showBar = true;
      } else {
        if (this.interval != null) {
          clearInterval(this.interval);
          this.interval = null;
        }
        this.showBar = false;
      }
      subscription.unsubscribe();
    });
  }

  dismiss() {
    this.showBar = false;
  }

  redirect() {
    this.router.navigate(['queues']);
  }

  setupInterval() {
    this.interval = setInterval(() => this.getCurrentQueue(), 5000);
  }
}
