import { Component, Input, Output, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { ReviewPlatformService } from 'app/_services/reviewPlatform.service';
import { NotifyService, StoreService } from 'app/_services';
@Component({
  selector: 'review-platform-select',
  templateUrl: './review-platform-select.component.html',
  styleUrls: ['./review-platform-select.component.scss']
})
export class ReviewPlatformSelectComponent implements OnInit, OnDestroy {
  constructor(private store: StoreService, private notifyService: NotifyService, private reviewPlatformService: ReviewPlatformService) {}

  @Input('filters') filters;
  @Output('applyFilter') applyFilter = new EventEmitter();

  public sources = [];
  public loading = false;
  public searchFilters = '';
  public subscriptions = [];

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this.reviewPlatformService.get(this.store.business._id, null, true).subscribe({
        next: (result) => {
          this.sources = result.map((value) => {
            return { _id: value._id, name: value.name };
          });
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
          this.notifyService.error('Não foi possível obter as plataformas.');
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  isChecked(source) {
    return this.filters.value.findIndex((value) => value._id == source._id) > -1;
  }

  toggleItemToFilterArray(id) {
    if (this.filters.value == null) {
      this.filters.value = [id];
    } else {
      const index = this.filters.value.findIndex((value) => value._id == id._id);
      if (index > -1) {
        this.filters.value.splice(index, 1);
      } else {
        this.filters.value.push(id);
      }
    }
  }

  saveFilter() {
    this.applyFilter.emit(this.filters);
  }
}
