import { UserService } from './../../_services/user.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { Question } from 'app/_models/customerFeedback/survey';
import { AuthenticationService, StoreService } from 'app/_services';
import { SurveyAnswerService } from 'app/_services/customerFeedback/surveyAnswer.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { NotifyService } from 'app/_services';
import { ForwardDialogComponent } from '../forward-dialog/forward-dialog.component';
import { SurveyAnswer } from 'app/_models/customerFeedback/surveyAnswer';
import { CustomerService } from 'app/_services/customer.service';
import { TkTicketService } from 'app/_services/ticketing/ticket.service';
import { CreateTicketDialogComponent } from 'app/ticketing/create-ticket-dialog/create-ticket-dialog.component';
@Component({
  selector: 'app-view-answer-dialog',
  templateUrl: './view-answer-dialog.component.html',
  styleUrls: ['./view-answer-dialog.component.scss']
})
export class ViewAnswerDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public surveyAnswerService: SurveyAnswerService,
    public modalService: BsModalService,
    public auth: AuthenticationService,
    public store: StoreService,
    public userService: UserService,
    public ticketService: TkTicketService,
    public customerService: CustomerService,
    private notifyService: NotifyService
  ) {}

  Object = Object;

  public feedbacks;
  public feedback;
  public result: Subject<any> = new Subject();
  public isAccountManager;
  public isNoteVisible = false;
  public contactCustomFields = 0;
  public message;
  public notes;
  public modalClosed = true;
  public isEdit = false;
  public note;
  public filters;
  public limit;
  public tickets;
  public page;
  public answerPosition;
  public totalFeedbacks;
  public user;
  public noteInput = '';
  public replyInput = '';
  public reply;
  public isCreateTicketModalVisible = false;
  public customer;
  public publicView;
  public tab = 0;
  public replyPublicEmail;
  public isReplyVisible = false;
  public rightClicked = false;
  public isNoteInputVisible = false;
  public isReplyInputVisible = false;
  public leftClicked = false;
  public showBackArrow = true;
  public showBlankAnswers = false;
  public showNextArrow = true;
  public operators = { maior: '>', menor: '<', igual: '==', entre: 'between', qualquer: 'any' };
  public subscriptions = [];
  public isOldFilter = false;

  objectKeys = Object.keys;

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(event) {
    if (
      !this.publicView &&
      !this.isNoteInputVisible &&
      !this.isCreateTicketModalVisible &&
      (event.code == 'ArrowRight' || event.code == 'ArrowDown') &&
      document.querySelector('.amp-note-popover') == null &&
      this.showNextArrow
    ) {
      this.nextAnswer();
    }
    if (
      !this.publicView &&
      !this.isNoteInputVisible &&
      !this.isCreateTicketModalVisible &&
      (event.code == 'ArrowLeft' || event.code == 'ArrowUp') &&
      document.querySelector('.amp-note-popover') == null &&
      this.showBackArrow
    ) {
      this.previousAnswer();
    }
  }

  ngOnInit() {
    this.isAccountManager = this.auth.isAccountManager();
    if (!this.publicView) {
      this.getUserData();
    }
    this.getNotes(this.feedback._id);
    if (!this.publicView) {
      this.getFeedbacks();
    }

    this.getTickets();

    this.subscriptions.push(
      this.modalService.onHide.subscribe(() => {
        this.modalClosed = true;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getUserData() {
    this.subscriptions.push(
      this.userService.getInfo().subscribe((data) => {
        this.user = data;
      })
    );
  }

  getTickets() {
    if (this.store.userData) {
      this.ticketService.getTicketsBySurveyAnswer(this.feedback._id).subscribe((data) => {
        this.tickets = data;
      });
    }
  }

  createNewTicket() {
    const initialState = {
      feedbackId: this.feedback._id,
      isFromAnswer: true,
      customerId: !this.feedback.anonymized && !this.feedback.survey.anonymous ? this.feedback._customer : null
    };

    const modal = this.modalService.show(CreateTicketDialogComponent, { initialState, class: 'amp-modal' });

    this.isCreateTicketModalVisible = true;

    modal.content.result.subscribe((result) => {
      this.isCreateTicketModalVisible = false;
      if (result) {
        this.notifyService.success('Ticket criado com sucesso!');
        window.open(window.location.origin + '/ticketing/pipelines/ticket/' + result.ticket._id, '_blank');
      }
    });
  }

  getNames(list) {
    return list.join(', ');
  }

  restoreAnswer(id) {
    this.surveyAnswerService.restoreAnswerFromTrash(id).subscribe(
      () => {
        this.notifyService.success('Resposta restaurada com sucesso!');
      },
      (error) => {
        console.error(error);
      }
    );
  }

  anonymize(surveyAnswer) {
    const title = 'Anonimizar Resposta';
    const message = 'Você tem certeza que gostaria de anonimizar esta resposta?';
    const initialState = {
      title: title,
      message: message,
      strongText: '\nEsta ação é irreversível.',
      buttonName: 'Anonimizar'
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.surveyAnswerService.anonymizeAnswer(surveyAnswer._id).subscribe(
            () => {
              surveyAnswer.anonymized = true;
              delete surveyAnswer._customer;
              delete surveyAnswer.customer;
              this.notifyService.success('Resposta anonimizada com sucesso!');
            },
            (error) => {
              this.notifyService.error('Houve um erro ao anonimizar esta resposta!');
              console.error(error);
            }
          );
        }
      })
    );
  }

  manageDialog() {
    this.feedbacks.forEach((x) => {
      if (x._id == this.feedback._id) {
        this.answerPosition = this.feedbacks.indexOf(x);
      }

      if (this.answerPosition == 0) {
        this.showNextArrow = true;
        this.showBackArrow = false;
        if (this.feedbacks.length <= 1) {
          this.showNextArrow = false;
        }
      }

      if (this.answerPosition == this.feedbacks.length - 1 && this.page == Math.round(this.totalFeedbacks / 10)) {
        this.showNextArrow = false;
      }
    });
  }

  toggleShowBlankAnswers() {
    this.showBlankAnswers = !this.showBlankAnswers;
  }

  attachReply() {
    this.tab = 6;
    if (this.feedback.reply?.length) {
      this.notifyService.warning('Esta resposta já foi respondida!');
    } else {
      this.isReplyInputVisible = true;
    }
  }

  closeReply() {
    this.isReplyVisible = false;
    this.feedback.reply = '';
    this.replyInput = '';
  }

  saveReply() {
    if (this.replyInput) {
      if (!this.publicView) {
        this.subscriptions.push(
          this.surveyAnswerService.addReply(this.feedback._id, this.replyInput).subscribe(
            () => {
              this.notifyService.success('Resposta anexada com sucesso!');
              this.isReplyInputVisible = false;
              window.location.reload();
            },
            () => {
              this.notifyService.error('Não foi possível anexar a resposta.');
            }
          )
        );
      } else {
        this.subscriptions.push(
          this.surveyAnswerService.addReplyPublic(this.feedback._id, this.replyInput, this.replyPublicEmail).subscribe(
            () => {
              this.notifyService.success('Resposta anexada com sucesso!');
              this.isReplyInputVisible = false;
              window.location.reload();
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Não foi possível anexar a resposta.');
            }
          )
        );
      }
    } else {
      this.notifyService.warning('A resposta está vazia!');
    }
  }

  nextAnswer() {
    this.tab = 0;
    this.rightClicked = true;
    if (this.answerPosition == this.feedbacks.length - 1 && this.page < Math.round(this.totalFeedbacks / 10)) {
      this.page = this.page + 1;
      return this.getMoreFeedbacks();
    } else {
      this.feedback = this.feedbacks[this.answerPosition + 1];
      this.answerPosition = this.answerPosition + 1;
    }

    if (this.answerPosition == this.feedbacks.length - 1) {
      this.showNextArrow = false;
    }

    if (this.answerPosition == 0) {
      this.showBackArrow = false;
    } else {
      this.showBackArrow = true;
    }

    this.getNotes(this.feedback._id);

    setTimeout(() => {
      this.rightClicked = false;
    }, 500);
  }

  previousAnswer() {
    this.tab = 0;
    this.leftClicked = true;
    this.feedback = this.feedbacks[this.answerPosition - 1];
    this.answerPosition = this.answerPosition - 1;

    if (this.answerPosition == this.feedbacks.length - 1) {
      this.showNextArrow = false;
    } else {
      this.showNextArrow = true;
    }

    if (this.answerPosition == 0) {
      this.showBackArrow = false;
    } else {
      this.showBackArrow = true;
    }

    this.getNotes(this.feedback._id);
    setTimeout(() => {
      this.leftClicked = false;
    }, 500);
  }

  getFeedbacks() {
    this.subscriptions.push(
      this.surveyAnswerService.getFeedbacks(this.isOldFilter, this.filters, this.limit, this.page || this.page).subscribe((data) => {
        this.feedbacks = data.data;
        this.manageDialog();
      })
    );
  }

  getMoreFeedbacks() {
    this.subscriptions.push(
      this.surveyAnswerService.getFeedbacks(this.isOldFilter, this.filters, this.limit, this.page || this.page).subscribe((data) => {
        data.data.forEach((element) => {
          this.feedbacks.push(element);
        });
        this.feedback = this.feedbacks[this.answerPosition + 1];
        this.answerPosition = this.answerPosition + 1;
        this.getNotes(this.feedback._id);
      })
    );
  }

  call() {
    window.open(`tel:${this.feedback.customer.tel}`, 'blank');
  }

  sendWhatsApp() {
    window.open(`https://api.whatsapp.com/send?phone=${this.feedback.customer.tel.split('+')[1]}`, 'blank');
  }

  sendMail() {
    window.open(`mailto:${this.feedback.customer.email}`, 'blank');
  }

  sendWebhook(feedback) {
    this.surveyAnswerService.scheduleWebhooks(this.store.business._id, feedback._id).subscribe({
      next: (data) => {
        console.log(data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  toggleSolved(value) {
    this.subscriptions.push(
      this.surveyAnswerService.changeResolved(this.feedback._id, value).subscribe(
        () => {
          if (value == true) {
            this.feedback.resolved = true;
            this.notifyService.success('Resposta marcada como resolvida!');
          } else {
            this.feedback.resolved = false;
            this.notifyService.success('Resposta marcada como não resolvida!');
          }
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  getNotes(id) {
    this.subscriptions.push(
      this.surveyAnswerService.getNotes(id).subscribe(
        (result) => {
          this.notes = result;
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  getTypeColor(type) {
    switch (type) {
      case 0:
        return 'type-compliment';
      case 1:
        return 'type-complaint';
      case 2:
        return 'type-question';
      case 3:
        return 'type-suggestion';
      case 4:
        return 'type-other';
      default:
        return '';
    }
  }

  getSeverityColor(type) {
    switch (type) {
      case 0:
        return 'severity-low';
      case 1:
        return 'severity-medium';
      case 2:
        return 'severity-high';
      case 3:
        return 'severity-critical';
      default:
        return '';
    }
  }

  getSentimentColor(type) {
    switch (type) {
      case 0:
        return 'sentiment-negative';
      case 1:
        return 'sentiment-neutral';
      case 2:
        return 'sentiment-positive';
      default:
        return '';
    }
  }

  getAnswerType(type) {
    switch (type) {
      case 0:
        return 'elogio';
      case 1:
        return 'reclamação';
      case 2:
        return 'dúvida';
      case 3:
        return 'sugestão';
      case 4:
        return 'outros';
      default:
        return '';
    }
  }

  getAnswerSentiment(sentiment) {
    switch (sentiment) {
      case 0:
        return 'negativo';
      case 1:
        return 'neutro';
      case 2:
        return 'positivo';
      default:
        return '';
    }
  }

  getAnswerSeverity(severity: number) {
    switch (severity) {
      case 0:
        return 'baixa';
      case 1:
        return 'média';
      case 2:
        return 'alta';
      case 3:
        return 'crítica';
      default:
        return '';
    }
  }

  getTopicColor(sentiment) {
    switch (sentiment) {
      case 0:
        return 'topic-negative';
      case 1:
        return 'topic-neutral';
      case 2:
        return 'topic-positive';
      default:
        return '';
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  openNote() {
    this.isNoteVisible = true;
    setTimeout(() => {
      const element = document.querySelector('.amp-new-note');
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, 100);
  }

  openTicket(ticket) {
    if (!ticket.hide) {
      window.open(window.location.origin + '/ticketing/pipelines/ticket/' + ticket._id);
    }
  }

  saveNote(surveyId, message) {
    if (message) {
      if (this.publicView) {
        this.subscriptions.push(
          this.surveyAnswerService.addNotePublic(surveyId, message, this.replyPublicEmail).subscribe(
            () => {
              this.notifyService.success('Anotação salva com sucesso!');
              this.isNoteVisible = false;
              this.isNoteInputVisible = false;
              this.message = '';
              this.getNotes(surveyId);
            },
            () => {
              this.notifyService.error('Não foi possível salvar a anotação.');
            }
          )
        );
      } else {
        if (this.isEdit) {
          this.subscriptions.push(
            this.surveyAnswerService.putNote(surveyId, this.note._id, message).subscribe(
              () => {
                this.notifyService.success('Anotação editada com sucesso!');
                this.isNoteVisible = false;
                this.isEdit = false;
                this.note.content = message;
                this.note.created_at = Date.now();
                this.note.isEdited = true;
              },
              (error) => {
                console.error(error);
              }
            )
          );
        } else {
          this.subscriptions.push(
            this.surveyAnswerService.addNote(surveyId, message).subscribe(
              () => {
                this.notifyService.success('Anotação salva com sucesso!');
                this.isNoteVisible = false;
                this.isNoteInputVisible = false;
                this.message = '';
                this.getNotes(surveyId);
              },
              () => {
                this.notifyService.error('Não foi possível salvar a anotação.');
              }
            )
          );
        }
      }
    } else {
      this.notifyService.warning('A anotação está vazia!');
    }
  }

  getStatus(status) {
    switch (status) {
      case 0:
        return 'Pendente';
      case 1:
        return 'Em Progresso';
      case 2:
        return 'Fechado';
      default:
        return '';
    }
  }

  createNote() {
    this.noteInput = '';
    this.isNoteInputVisible = true;
  }

  getStep(ticket) {
    return ticket._pipeline.steps.filter((step) => ticket.step == step.unique_id)[0].name;
  }

  editNote(note) {
    this.note = note;
    this.message = note.content;
    this.isNoteVisible = true;
    this.isEdit = true;
  }

  deleteNote(note) {
    const title = 'Excluir Anotação';
    const message = 'Tem certeza que deseja excluir esta anotação?';
    const initialState = {
      title: title,
      message: message + ' ',
      strongText: '',
      messageEnd: '',
      forceConfirmation: false
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.surveyAnswerService.removeNote(this.feedback._id, note._id).subscribe(
              () => {
                this.notifyService.success('Nota excluída com sucesso!');
                this.getNotes(this.feedback._id);
              },
              (error) => {
                console.error(error);
              }
            )
          );
        }
      })
    );
  }

  goToContact(customer) {
    window.open(window.location.origin + '/contatos/' + customer);
  }

  checkQuestionMatrix(question, identifier, value) {
    const index = this.feedback.answers.findIndex((data) => question.internal_name == data.internal_name);
    if (index > -1) {
      if (this.feedback.answers[index].answer[identifier] == value) {
        return true;
      }
      return false;
    }
    return false;
  }

  checkQuestionMultiple(question, value) {
    const index = this.feedback.answers.findIndex((data) => question.internal_name == data.internal_name);
    if (index > -1) {
      if (this.feedback.answers[index].answer.includes(value)) {
        return true;
      }
      return false;
    }
    return false;
  }

  getCustomer(id) {
    this.customerService.getCustomer(id).subscribe((data) => {
      this.customer = data;
      this.contactCustomFields = document.querySelectorAll('.contact-custom-field').length;
    });
  }

  getOtherAnswers(question, feedback) {
    const answer = feedback.answers.find((answer) => question?.internal_name == answer.internal_name);
    if (answer) {
      if (question.type == 'multiple') {
        const compare = answer.answer?.filter((option) => !question.options.includes(option));
        return compare;
      }

      if (question.type == 'unique') {
        const options = [];

        question.options.forEach((option) => {
          options.push(option.trim());
        });

        return !options?.includes(answer.answer) ? [answer.answer] : [];
      }
      return !question.options.includes(answer.answer) ? answer.answer : [];
    }
    return [];
  }

  getAnswerFromQuestion(question, surveyAnswer: SurveyAnswer) {
    if (surveyAnswer) {
      const answer = surveyAnswer.answers.find((answer) => question?.internal_name == answer.internal_name);

      if (!answer) {
        return undefined;
      }

      if (question?.type == 'thumbs') {
        if (answer?.answer == 'up') {
          return 'Liked';
        } else if (answer?.answer == 'down') {
          return 'Disliked';
        } else {
          return undefined;
        }
      } else {
        return answer?.answer;
      }
    } else {
      return undefined;
    }
  }

  getQuestionVariation(question: Question, placeholder = false) {
    let questionText;
    if (question.variations.length > 1) {
      for (const variation of question.variations) {
        if (variation.condition != null && variation.show == false) {
          const cond = this.operators[variation.condition.split(',')[1]];
          if (this.testVariations(cond, variation)) {
            if (placeholder) {
              questionText = variation.placeholder;
            } else {
              questionText = variation.question;
            }
          }
        } else {
          if (placeholder) {
            questionText = question.variations[0].placeholder;
          } else {
            questionText = question.variations[0].question;
          }
        }
      }
      return questionText;
    } else {
      if (placeholder) {
        return question.variations[0].placeholder;
      } else {
        return question.variations[0].question;
      }
    }
  }

  forward(feedback) {
    this.modalClosed = false;
    this.modalService.show(ForwardDialogComponent, {
      initialState: { surveyId: feedback._id },
      class: 'amp-modal'
    });
  }

  softDeleteAnswer() {
    this.subscriptions.push(
      this.surveyAnswerService.softDeleteAnswer(this.feedback._id).subscribe(() => {
        this.notifyService.success('Resposta movida para a lixeira com sucesso!');
        this.result.next({ event: 'delete', id: this.feedback._id });
        if (this.answerPosition == 0) {
          this.nextAnswer();
        } else if (this.answerPosition == this.feedbacks.length - 1) {
          this.previousAnswer();
        } else {
          this.nextAnswer();
        }
      })
    );
  }

  deleteAnswer() {
    const title = 'Excluir Resposta';
    const message = 'Você tem certeza que gostaria de excluir esta resposta?';
    const initialState = {
      title: title,
      message: message,
      strongText: '\nEsta ação é irreversível.',
      messageEnd: '',
      forceConfirmation: false
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.surveyAnswerService.deleteAnswerFromTrash(this.feedback._id).subscribe(() => {
              this.notifyService.success('Resposta excluída com sucesso!');
              this.result.next({ event: 'delete', id: this.feedback._id });
              this.bsModalRef.hide();
            })
          );
        }
      })
    );
  }

  testVariations(cond, variation) {
    const field = variation.condition.split(',')[0];

    const fieldAnswer = this.feedback.answers.find((data) => data.internal_name == field);

    if (fieldAnswer) {
      const value = variation.condition.split(',')[2];
      let value2;
      let condForm = `return a ${cond} b`;

      if (cond == 'between') {
        value2 = variation.condition.split(',')[3];
        condForm = `return a >= b && a <= c`;
      }

      if (fieldAnswer.type == 'multiple') {
        if (cond == '!=') {
          condForm = `return !a.includes(b)`;
        } else {
          condForm = `return a.includes(b)`;
        }
      }

      if (cond == 'any') {
        condForm = `return a.toString().length > 0`;
      }

      const Fcond = new Function('a', 'b', 'c', condForm);

      return Fcond(fieldAnswer.answer, value, value2);
    }

    return false;
  }
}
