import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { NotifyService, StoreService } from 'app/_services';
@Component({
  selector: 'app-sheets-integration-dialog',
  templateUrl: './sheets-integration-dialog.component.html',
  styleUrls: ['./sheets-integration-dialog.component.scss']
})
export class SheetsIntegrationDialogComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef, public store: StoreService, private notifyService: NotifyService) {}

  public columns = [];
  public survey;
  public customFields;

  ngOnInit() {
    this.getColumnName();
  }

  save() {
    this.bsModalRef.hide();
    this.notifyService.success('Colunas mapeadas com sucesso!');
  }

  getColumnName() {
    const nextChar = (c) => (c ? String.fromCharCode(c.charCodeAt(0) + 1) : 'A');
    const nextCol = (s) => s.replace(/([^Z]?)(Z*)$/, (_, a, z) => nextChar(a) + z.replace(/Z/g, 'A'));

    for (let i = 0, s = ''; i < 100; i++) {
      s = nextCol(s);
      this.columns.push(s);
    }
  }

  removeRow(idx) {
    if (this.survey.integrations.gl_spreadsheet.last_saved_index < idx) {
      this.survey.integrations.gl_spreadsheet.columns.splice(idx, 1);
    }
  }

  addRow() {
    this.survey.integrations.gl_spreadsheet.columns.push('');
  }
}
