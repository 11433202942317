import { UserService, ForgotPasswordService } from '../_services/index';
import { MixPanelService } from 'app/_services/mixPanel.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifyService } from '../_services/notify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../_services/menu.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private userService: UserService,
    private mixPanel: MixPanelService,
    public notifyService: NotifyService,
    private translate: TranslateService,
    private forgotPasswordService: ForgotPasswordService
  ) {
    this.menuService.emit(false);
  }

  public sub: any;
  public id: string;
  public fail = false;
  public user: any = {};
  public loading = false;
  public subscriptions = [];

  ngOnInit() {
    this.sub = this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.id = params['id'];

        this.subscriptions.push(
          this.forgotPasswordService.getById(this.id).subscribe({
            next: (data) => {
              let dados;
              dados = data;
              this.user._id = dados._user;
              dados.visited = true;
              this.subscriptions.push(
                this.forgotPasswordService.update(dados).subscribe({
                  next: () => {},
                  error: (error) => {
                    console.error(error);
                  }
                })
              );
            },
            error: (error) => {
              console.error(error);
            }
          })
        );
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  trocarSenha() {
    this.mixPanel.track('Request Forgot Password');
    this.loading = true;
    this.fail = false;
    this.subscriptions.push(
      this.userService.updatePassword(this.user, this.id).subscribe({
        next: (data) => {
          if (data) {
            this.router.navigate(['/']);
            this.subscriptions.push(
              this.translate.get('notify.forgot-password.request_success').subscribe((res) => {
                this.notifyService.success(res);
              })
            );
          } else {
            this.fail = true;
            this.loading = false;
            this.subscriptions.push(
              this.translate.get('notify.general_error').subscribe((res) => {
                this.notifyService.error(res);
              })
            );
          }
        },
        error: (error) => {
          console.error(error);
          this.fail = true;
          this.loading = false;
          this.subscriptions.push(
            this.translate.get('notify.general_error').subscribe((res) => {
              this.notifyService.error(res);
            })
          );
        }
      })
    );
  }
}
