import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { RecurringAISummary, RecurringAISummaryPopulated } from 'app/_models/ai/recurringAISummary';
import { AISummary, AISummaryPopulated } from 'app/_models/ai/aiSummary';

@Injectable({ providedIn: 'root' })
export class AIService {
  constructor(private httpClient: HttpClient, private config: AppConfig) {}

  getReviewAnswerSuggestion(reviewId, sentiment, content, business) {
    return this.httpClient.post(this.config.apiUrl + '/ai/review-answer', {
      reviewId: reviewId,
      sentiment: sentiment,
      content: content,
      business: business
    });
  }

  getCompletion(type: number, input: string, params: any, _empresa: string) {
    return this.httpClient.post<{ completion_text: string; _id: string }>(this.config.apiUrl + '/ai/completion', {
      type: type,
      input: input,
      params: params,
      empresa: _empresa
    });
  }

  createRecurringAISummary(data: any) {
    return this.httpClient.post<RecurringAISummary>(this.config.apiUrl + '/ai/recurring-ai-summary', data);
  }

  getRecurringAISummary(id: string) {
    return this.httpClient.get<RecurringAISummaryPopulated>(this.config.apiUrl + '/ai/recurring-ai-summary/' + id);
  }

  getAllRecurringAISummaries(_empresa: string) {
    return this.httpClient.get<RecurringAISummaryPopulated[]>(this.config.apiUrl + '/ai/recurring-ai-summary?empresa=' + _empresa);
  }

  updateRecurringAISummary(id: string, data: RecurringAISummary) {
    return this.httpClient.put<RecurringAISummary>(this.config.apiUrl + '/ai/recurring-ai-summary/' + id, data);
  }

  deleteRecurringAISummary(id: string) {
    return this.httpClient.delete(this.config.apiUrl + '/ai/recurring-ai-summary/' + id);
  }

  deleteAISummary(id: string) {
    return this.httpClient.delete(this.config.apiUrl + '/ai/ai-summary/' + id);
  }

  createAISummary(data: AISummary) {
    return this.httpClient.post<AISummary>(this.config.apiUrl + '/ai/ai-summary', data);
  }

  renameAISummary(aiSummaryId, name: string) {
    return this.httpClient.put(this.config.apiUrl + '/ai/ai-summary/' + aiSummaryId, {
      name
    });
  }

  getAISummary(id: string) {
    return this.httpClient.get<AISummaryPopulated>(this.config.apiUrl + '/ai/ai-summary/' + id);
  }

  archiveAISummary(id: string, archived: boolean) {
    return this.httpClient.put<any>(this.config.apiUrl + `/ai/ai-summary/${id}/archive`, {
      archived
    });
  }

  getAllAISummaries(_empresa: string, showArchived: boolean = false, dateFilter) {
    return this.httpClient.get<AISummaryPopulated[]>(this.config.apiUrl + '/ai/ai-summary?empresa=' + _empresa, {
      params: {
        showArchived,
        startDate: dateFilter[0].toISOString(),
        endDate: dateFilter[1].toISOString()
      }
    });
  }

  sendAISummaryEmail(id: string, emails: string[]) {
    return this.httpClient.post(this.config.apiUrl + `/ai/ai-summary/${id}/send-emails`, {
      forwardTo: emails
    });
  }

  reprocessAISummary(id: string) {
    return this.httpClient.put(this.config.apiUrl + `/ai/ai-summary/${id}/reprocess`, {});
  }

  estimateAnswersAISummary(_businesses, _surveys, start_date, end_date) {
    return this.httpClient.patch<any>(this.config.apiUrl + '/ai/ai-summary/estimate-answers', {
      businesses: _businesses,
      surveys: _surveys,
      start_date: start_date,
      end_date: end_date
    });
  }
}
