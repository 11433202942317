<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title" *ngIf="isTerms">Termos de Uso</div>
    <div class="amp-dialog-title" *ngIf="isPrivacy">Política de Privacidade</div>
    <div class="amp-dialog-subtitle"><span>Você concorda com os termos abaixo?</span></div>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-terms">
      <object *ngIf="isTerms" height="300px" data="https://cdn.amplifique.me/security/terms-v3.pdf" type="application/pdf">
        <iframe src="https://cdn.amplifique.me/security/terms-v3.pdf"></iframe>
      </object>
      <object *ngIf="isPrivacy" data="https://cdn.amplifique.me/security/privacy.pdf" type="application/pdf">
        <iframe src="https://cdn.amplifique.me/security/privacy.pdf"></iframe>
      </object>
    </div>

    <div class="amp-field">
      <label class="amp-label-text" for="title">Digite <b>CONCORDO</b> para confirmar a ação.</label>
      <input
        type="text"
        class="amp-input-lg confirmation"
        [(ngModel)]="confirmField"
        autocomplete="off"
        placeholder="CONCORDO"
        #confirm="ngModel"
        name="confirm"
        confirm
        required
      />
    </div>

    <button class="amp-btn-success amp-btn-lg" [disabled]="confirmField.toLowerCase() != 'concordo'" (click)="confirmar()">Confirmar</button>
  </div>
</div>
