import { TranslateService } from '@ngx-translate/core';
import { MenuService } from './../_services/menu.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifyService } from './../_services/notify.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DialogComponent } from '../amp-components/dialog/dialog.component';
import { StoreService } from 'app/_services/store.service';
import { TokenDialogComponent } from 'app/_dialogs/token-dialog/token-dialog.component';
import { TokenService } from 'app/_services/token.service';
import { PartnerDialogComponent } from 'app/_dialogs/partner-dialog/partner-dialog.component';
import { Token } from 'app/_models/token';
import Partner from 'app/_models/partner';
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit, OnDestroy {
  constructor(
    private notifyService: NotifyService,
    private translate: TranslateService,
    private store: StoreService,
    private tokenService: TokenService,
    private menuService: MenuService,
    private modalService: BsModalService
  ) {
    this.menuService.emit(true);
    this.menuService.emitSidebar(true);
    this.menuService.emitPageTitle('Integrações');
  }

  public isAccountManager = false;
  public tokens: Token[];
  public partners: Partner[] = [];
  public search = '';
  public subscriptions = [];
  public category;
  public partnersList: Partner[] = [];
  public options = [
    {
      label: 'Todos',
      value: 'all'
    },
    {
      label: 'Uso Geral',
      value: 'general'
    },
    {
      label: 'Marketing e CRM',
      value: 'marketing'
    },
    {
      label: 'Helpdesk e Suporte',
      value: 'support'
    },
    {
      label: 'Hotelaria',
      value: 'hotels'
    },
    {
      label: 'ERPs e Sistemas',
      value: 'erps'
    },
    {
      label: 'WhatsApp',
      value: 'whatsapp'
    },
    {
      label: 'SMS',
      value: 'sms'
    },
    {
      label: 'Outros',
      value: 'others'
    }
  ];

  ngOnInit() {
    // this.partners.push(
    //   new Partner(
    //     null,
    //     'AskSuite',
    //     'asksuite_hotels',
    //     'Integração para mostrar as avaliações online no ChatBot da AskSuite.',
    //     'hotels',
    //     'assets/img/partners/asksuite.png',
    //     '#5885f7',
    //     'Com a Integração da **AskSuite** você poderá: \n* Mostrar uma nova sessão de avaliações em seu Bot de Atendimento. Onde é mostrada sua média e as últimas avaliações recebidas;\n*  Utilizar um Gatilho de Venda (mostrando uma avaliação para o seu cliente durante uma cotação) no Bot de Atendimento.\n\nVeja um exemplo da integração funcionando na prática:\n\n![](https://media.giphy.com/media/hrY0bmUrygHwiJt0lH/source.gif)\n\nVeja como realizar essa integração clicando [aqui!]()',
    //     true
    //   )
    // );

    this.partners.push(
      new Partner(
        null,
        'Simples Hotel',
        'simples-hotel',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        'hotels',
        'assets/img/partners/simpleshotel.png',
        '#5885f7',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'TDF',
        'tdf',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        'hotels',
        'assets/img/partners/tdf.png ',
        '#5885f7',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Syngoo Talk',
        'syngootalk',
        'Integração para enviar pesquisas via WhatsApp.',
        'whatsapp',
        'assets/img/partners/syngootalk.png ',
        '#33b87e',
        '\n ',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Opa! Suite',
        'opasuite',
        'Integração para enviar pesquisas via WhatsApp.',
        'whatsapp',
        'assets/img/partners/opasuite.png ',
        '#33b87e',
        '\n ',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'TakeBlip',
        'takeblip',
        'Integração para enviar pesquisas via WhatsApp.',
        'whatsapp',
        'assets/img/partners/takeblip.png ',
        '#33b87e',
        '\n ',
        false
      )
    );

    // this.partners.push(
    //   new Partner(
    //     null,
    //     'Letalk',
    //     'letalk',
    //     'Integração para enviar pesquisas via WhatsApp.',
    //     'whatsapp',
    //     'assets/img/partners/letalk.png ',
    //     '#33b87e',
    //     '\n ',
    //     false
    //   )
    // );

    this.partners.push(
      new Partner(
        null,
        'Botmaker',
        'botmaker',
        'Integração para enviar pesquisas via WhatsApp.',
        'whatsapp',
        'assets/img/partners/botmaker.png ',
        '#33b87e',
        '\n ',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'AskSuite',
        'asksuite_whatsapp',
        'Integração para enviar pesquisas via WhatsApp.',
        'whatsapp',
        'assets/img/partners/asksuite.png ',
        '#33b87e',
        '\n ',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Zenvia',
        'zenvia',
        'Integração para enviar pesquisas via WhatsApp.',
        'whatsapp',
        'assets/img/partners/zenvia.png ',
        '#33b87e',
        '\n ',
        false
      )
    );

    // this.partners.push(
    //   new Partner(
    //     null,
    //     '21Mobile',
    //     '21mobile',
    //     'Integração para utilizar o canal de envios via SMS.',
    //     'sms',
    //     'assets/img/partners/21mobile.png ',
    //     '#fe7b03',
    //     '\n ',
    //     false
    //   )
    // );

    // this.partners.push(
    //   new Partner(
    //     null,
    //     'Stratus Telecom',
    //     'stratustelecom',
    //     'Integração para utilizar o canal de envios via SMS.',
    //     'sms',
    //     'assets/img/partners/stratustelecom.png ',
    //     '#fe7b03',
    //     '\n ',
    //     false
    //   )
    // );

    // this.partners.push(
    //   new Partner(
    //     null,
    //     'Assertiva',
    //     'assertiva',
    //     'Integração para utilizar o canal de envios via SMS.',
    //     'sms',
    //     'assets/img/partners/assertiva.png ',
    //     '#fe7b03',
    //     '\n ',
    //     false
    //   )
    // );

    // this.partners.push(
    //   new Partner(
    //     null,
    //     'Teleprime',
    //     'teleprime',
    //     'Integração para utilizar o canal de envios via SMS.',
    //     'sms',
    //     'assets/img/partners/teleprime.png ',
    //     '#fe7b03',
    //     '\n ',
    //     false
    //   )
    // );

    this.partners.push(
      new Partner(
        null,
        'ActiveCampaign',
        'active_campaign',
        'Integração para inserir os contatos do Programa de Indicação no CRM do ActiveCampaign.',
        'marketing',
        'assets/img/partners/activecampaign.svg',
        '#949eb6',
        'Integração para inserir os contatos do Programa de Indicação no CRM do ActiveCampaign.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'RDStation Marketing',
        'RDStation_marketing',
        'Envie todos os eventos do Programa de Indicação para o RDStation e também Integre os fluxos do RDStation para enviar pesquisas ou validar indicações baseado nos dados do contato.',
        'marketing',
        'assets/img/partners/rdstation_marketing.svg',
        '#949eb6',
        'Envie todos os eventos do Programa de Indicação (Novo indicador, Novo indicado, Nova Indicação, Nova Milestone) para o RDStation. \n E também Integre os Fluxos do RDStation para enviar pesquisas ou validar indicações baseado nos dados do contato do RDStation.\n ',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Movidesk',
        'movidesk',
        'Integração para enviar pesquisas via e-mail ou SMS baseado em gatilhos.',
        'support',
        'assets/img/partners/movidesk.png',
        '#383838',
        'Integração para enviar pesquisas via e-mail ou SMS baseado em gatilhos.',
        false
      )
    );

    if (this.store.business.referral_enabled) {
      this.partners.push(
        new Partner(
          null,
          'Eduzz',
          'eduzz',
          'Integração do Programa de Indicação com checkout do Eduzz.',
          'marketing',
          'assets/img/partners/eduzz.png',
          '#949eb6',
          'Integração do Programa de Indicação com checkout do Eduzz.',
          false
        )
      );
    }

    this.partners.push(
      new Partner(
        null,
        'PipeDrive',
        'pipedrive',
        'Integração para enviar pesquisas quando o cliente é movido para um determinado estágio do pipeline.',
        'marketing',
        'assets/img/partners/pipedrive.png',
        '#949eb6',
        'Integração para enviar pesquisas quando o cliente é movido para um determinado estágio do pipeline.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Construtor de Vendas',
        'cvcrm',
        'Integração para disparo de pesquisas utilizando gatilhos.',
        'marketing',
        'assets/img/partners/cvcrm.png',
        '#949eb6',
        'Integração para disparo de pesquisas utilizando gatilhos.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'CustomerX',
        'customerx',
        'Integração para envio de pesquisas em determinadas etapas via webhook.',
        'support',
        'assets/img/partners/customerx.png',
        '#383838',
        'Integração para envio de pesquisas em determinadas etapas via webhook.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Tiny ERP',
        'tinyerp',
        'Integração para enviar pesquisa quando o cliente realiza uma compra, e a compra é aprovada.',
        'erps',
        'assets/img/partners/tinyerp.png',
        '#666666',
        'Integração para enviar pesquisa quando o cliente realiza uma compra, e a compra é aprovada.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'TOTVS CMNet',
        'totvs_cmnet',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        'hotels',
        'assets/img/partners/totvs.png',
        '#5885f7',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Desbravador',
        'desbravador',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        'hotels',
        'assets/img/partners/desbravador.png',
        '#5885f7',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Bitz Hotel',
        'bitz',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        'hotels',
        'assets/img/partners/bitz.webp',
        '#5885f7',
        'Integração para enviar pesquisas para os hóspedes no checkout.',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Zendesk',
        'zendesk',
        'Integração para enviar pesquisas via e-mail ou SMS baseado em gatilhos.',
        'support',
        'assets/img/partners/zendesk.png',
        '#383838',
        'Integração para enviar pesquisas via e-mail ou SMS baseado em gatilhos.',
        false
      )
    );

    this.partners.push(
      new Partner(
        null,
        'PipeRun',
        'piperun',
        'Integração para enviar pesquisas via e-mail ou SMS baseado em gatilhos.',
        'marketing',
        'assets/img/partners/piperun.png',
        '#949eb6',
        'Integração para enviar pesquisas via e-mail ou SMS baseado em gatilhos.',
        true
      )
    );

    this.partners.push(
      new Partner(
        null,
        'Gupy.io',
        'gupy',
        'Integração para enviar pesquisas baseado em gatilhos de mudança de fase na Gupy.',
        'others',
        'assets/img/partners/gupy.png',
        '#db4251',
        'Integração para enviar pesquisas baseado em gatilhos de mudança de fase na Gupy.',
        true
      )
    );

    this.partnersList = this.partners;
    this.partnersList.sort((a, b) => (a.name > b.name ? 1 : -1));

    this.partners.unshift(
      new Partner(
        null,
        'Webhook',
        'webhook',
        'Receba notificações de nova resposta de pesquisa, nova indicação, novo participante no programa de indicação e nova recompensa disponível.',
        'general',
        'assets/img/partners/webhook.png',
        '#ffca00',
        '<base target="_blank">Receba notificações de nova resposta de pesquisa, nova indicação, novo participante no programa de indicação e nova recompensa disponível.\n\n A documentação dos nossos Webhooks está disponível [aqui.](https://amplifiqueme.readme.io/reference/nova-resposta-de-pesquisa-de-experi%C3%AAncia-do-cliente)\n\n Para configurar um novo Webhook ou ver o histórico de solicitações de um webhook, clique no botão abaixo para ir ao Painel de Webhooks ',
        true
      )
    );

    this.partners.unshift(
      new Partner(
        null,
        'API',
        'api',
        'Envie pesquisas, valide indicações e gere recompensas.',
        'general',
        'assets/img/partners/api.png',
        '#ffca00',
        '<base target="_blank">\nNossa API pode ser utilizada para enviar pesquisas, validar indicações e gerir recompensas.\n\nA documentação da nossa API está disponível [aqui.](https://amplifiqueme.readme.io/)\n\nCom a nossa API, hoje é possível:\n\n - Enviar solicitações de Pesquisa (NPS, CSAT, CES, ou s/ métrica)\n - Obter um resumo das avaliações online\n - Validar Indicação\n - Obter uma lista das recompensas\n - Entregar uma recompensa\n\nPara obter o Token de acesso a nossa API, clique no botão abaixo para ir ao Painel da API, e criar um novo Token.\n\n',
        true
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  checkWidth() {
    if (window.innerWidth < 1920) {
      return true;
    }

    return false;
  }

  openPartnerDialog(partner: Partner) {
    const initialState = {
      partner
    };

    this.modalService.show(PartnerDialogComponent, {
      initialState,
      class: 'amp-modal-lg col-xl-4 col-lg-6 col-md-6 col-sm-12'
    });
  }

  filterByCategory(event) {
    this.category = event;

    if (this.category == 'all') {
      this.partnersList = this.partners;
    } else {
      this.partnersList = this.partners.filter((partner) => partner.category == this.category);
    }
  }

  createToken() {
    const initialState = {
      isNew: true,
      cb: () => {
        this.loadTokens();
      }
    };
    this.modalService.show(TokenDialogComponent, {
      class: 'amp-modal modal-lg',
      initialState
    });
  }

  editToken(token) {
    const initialState = {
      isNew: false,
      token: token,
      cb: () => {
        this.loadTokens();
      }
    };
    this.modalService.show(TokenDialogComponent, {
      class: 'amp-modal modal-lg',
      initialState
    });
  }

  deleteToken(token) {
    const cb = () => {
      this.subscriptions.push(
        this.tokenService.delete(token._id).subscribe(
          () => {
            this.notifyService.success('Token excluído com sucesso');
            this.loadTokens();
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Não foi possível excluir o token.');
          }
        )
      );
    };
    const initialState = {
      title: 'Excluir Token',
      subtitle: `Tem certeza que deseja excluir o token ${token.name}? Esta ação é irreversível.`,
      cancelButton: this.translate.instant('dialog-delete-user.cancelButton'),
      confirmButton: this.translate.instant('dialog-delete-user.deleteButton'),
      confirmCallback: cb
    };
    this.modalService.show(DialogComponent, {
      initialState,
      class: 'amp-modal'
    });
  }

  loadTokens() {
    this.subscriptions.push(
      this.tokenService.getAll(this.store.business._id).subscribe((data) => {
        this.tokens = data;
      })
    );
  }

  openDocumentation(partner) {
    let url = '';

    switch (partner) {
      case '21mobile':
        url = '';
        break;
      case 'asksuite_whatsapp':
        url = 'https://www.notion.so/amplifiqueme/Ask-Suite-WhatsApp-121a6daff71d805faa1cec253cbb2fbf';
        break;
      case 'asksuite_hotels':
        url = '';
        break;
      case 'assertiva':
        url = '';
        break;
      case 'bitz':
        url = 'https://www.notion.so/amplifiqueme/Bitz-Hotel-121a6daff71d800cb1ebe78929489786';
        break;
      case 'botmaker':
        url = 'https://www.notion.so/amplifiqueme/Botmaker-121a6daff71d8051a04fd795ae501055';
        break;
      case 'letalk':
        url = '';
        break;
      case 'movidesk':
        url = 'https://www.notion.so/amplifiqueme/Movidesk-121a6daff71d80d49662f22b9de01f86';
        break;
      case 'opasuite':
        url = 'https://www.notion.so/amplifiqueme/Opa-Suite-WhatsApp-121a6daff71d807a821ed5f14b914fc3';
        break;
      case 'simples-hotel':
        url = 'https://www.notion.so/amplifiqueme/Simples-Hotel-121a6daff71d80109fbbd59e18cb20cd';
        break;
      case 'stratustelecom':
        url = '';
        break;
      case 'syngootalk':
        url = 'https://www.notion.so/amplifiqueme/Syngoo-Talk-121a6daff71d800c9a6dd498a9735c81';
        break;
      case 'tdf':
        url = 'https://www.notion.so/amplifiqueme/PMS-TDF-T-de-F-rias-121a6daff71d805bac63ec2b9cb89545';
        break;
      case 'takeblip':
        url = 'https://www.notion.so/amplifiqueme/Take-Blip-121a6daff71d80e79dcdfe5b4fcd9cd0';
        break;
      case 'teleprime':
        url = '';
        break;
      case 'zendesk':
        url = 'https://www.notion.so/amplifiqueme/Zendesk-121a6daff71d8015aeabe7470cc602a4';
        break;
      case 'zenvia':
        url = 'https://amplifiqueme.notion.site/Zenvia-121a6daff71d80518de6e545fb0c8ce1';
        break;
    }

    window.open(url, '_blank');
  }
}
