import { TitleCasePipe } from '@angular/common';
import { BusinessService } from './../../_services/business.service';
import { MenuService } from './../../_services/menu.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Dashboard, DashboardReviewCard } from 'app/_models/dashboard';
import { TagSet } from 'app/_models/smartCluster/tagSet';
import { NotifyService } from 'app/_services';
import { DashboardService } from 'app/_services/dashboard.service';
import { SmartTagService } from 'app/_services/smartTags/smartTags.service';
import { StoreService } from 'app/_services/store.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ReviewPlatformService } from 'app/_services/reviewPlatform.service';
import { ReviewPlatform } from 'app/_models/reviewPlatform';
import { ChatService } from 'app/_services/chat.service';
import { Business } from 'app/_models/business';
@Component({
  selector: 'app-add-review-card-dialog',
  templateUrl: './add-review-card-dialog.component.html',
  styleUrls: ['./add-review-card-dialog.component.scss']
})
export class AddReviewCardDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    private dashboardService: DashboardService,
    private notifyService: NotifyService,
    private store: StoreService,
    private smartTagsService: SmartTagService,
    private menuService: MenuService,
    private businessService: BusinessService,
    private reviewPlatformService: ReviewPlatformService,
    private titleCasePipe: TitleCasePipe,
    private chatService: ChatService
  ) {}

  public subscriptions = [];
  public dashboard: Dashboard = new Dashboard();
  public card: DashboardReviewCard = new DashboardReviewCard();
  public isNew = true;
  public reviewPlatforms: ReviewPlatform[] = [];
  public tagSets: TagSet[] = [];
  public items: [];
  public smartTags = [];
  public business: Business;
  public result: Subject<Dashboard> = new Subject();
  public searchField = '';
  public loading = false;
  public selectedAll = false;
  public selectedAllPlatforms = false;
  public selectedAllBusinesses = false;
  public surveyFilter = 'all';
  public showAddNewMetric = true;
  public showSelectPlatformForWordCloud = false;
  public showChooseTagSet = false;
  public showSelectSurvey = false;
  public showSelectTags = false;
  public showNameYourMetric = false;
  public showAddSurveys = false;
  public showAddOption = false;
  public multiSurveyMetric = false;
  public chooseBusiness = false;
  public businesses;
  public showNameYourCard = false;
  public chooseYourPlatforms = false;

  ngOnInit() {
    this.business = this.store.business;
    this.loadPlatforms();
    this.loadBusiness();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadBusiness() {
    this.businesses = this.menuService.getUser()._businesses;
  }

  loadPlatforms() {
    if (this.card.tagSet) {
      this.loading = true;
    } else {
      this.loading = true;
      this.subscriptions.push(
        this.reviewPlatformService.get(undefined, this.store.empresa).subscribe(
          async (data) => {
            this.reviewPlatforms = data;
            this.loading = false;
          },
          (error) => {
            console.error(error);
          }
        )
      );
    }
  }

  toggleNameCard(value) {
    this.showNameYourMetric = true;

    if (value.length == 1) {
      const reviewPlatform = this.reviewPlatforms.find((item) => value[0] == item._id);
      if (reviewPlatform) {
        this.card.name = `${this.titleCasePipe.transform(reviewPlatform.platform)} - ${reviewPlatform.name}`;
      }
    }
  }

  getPlatformName(platform) {
    if (platform == 'booking') return 'Booking';
    if (platform == 'tripadvisor') return 'TripAdvisor';
    if (platform == 'facebook') return 'Facebook';
    if (platform == 'hotelscom') return 'Hoteis.com';
    if (platform == 'google') return 'Google';
    if (platform == 'despegar') return 'Decolar';
    if (platform == 'expedia') return 'Expedia';
    if (platform == 'airbnb') return 'Airbnb';
  }

  loadSmartTagName(tag) {
    return this.smartTags.find((item) => item._id == tag);
  }

  getAvailableTags() {
    return this.smartTags.filter((item) => !this.card.tags.includes(item._id));
  }

  addOption(id) {
    this.card.tags.push(id);
    this.showAddOption = false;
  }

  removeOption(id) {
    const index = this.card.tags.indexOf(id);
    this.card.tags.splice(index, 1);
  }

  loadTagsSets() {
    this.loading = true;
    this.subscriptions.push(
      this.smartTagsService.getTagsSet(this.store.empresa).subscribe((data) => {
        this.tagSets = data.result;
        this.loading = false;
      })
    );
  }

  toggleBusiness(business: Business) {
    let i;
    let bId;

    if (typeof business === 'object') {
      bId = business._id;
    } else {
      bId = business;
    }

    if (bId) {
      if ((i = this.card._businesses.indexOf(bId)) > -1) {
        this.card._businesses.splice(i, 1);
      } else {
        this.card._businesses.push(bId);
      }
    }
  }

  togglePlatform(reviewPlatform: ReviewPlatform) {
    let i;

    //@ts-ignore
    if ((i = this.card._reviewPlatforms.indexOf(reviewPlatform._id)) > -1) {
      this.card._reviewPlatforms.splice(i, 1);
    } else {
      //@ts-ignore
      this.card._reviewPlatforms.push(reviewPlatform._id);
    }
  }

  selectAllBusinesses() {
    if (this.selectedAllBusinesses) {
      this.card._businesses = [];
      this.selectedAllBusinesses = false;
    } else {
      this.businesses.forEach((e) => this.toggleBusiness(e));
      this.selectedAllBusinesses = true;
    }
  }

  selectAllPlatforms() {
    if (this.selectedAllPlatforms) {
      this.card._reviewPlatforms = [];
      this.selectedAllPlatforms = false;
    } else {
      this.reviewPlatforms.forEach((e) => this.togglePlatform(e));
      this.selectedAllPlatforms = true;
    }
  }

  nameNextCard() {
    switch (this.card._reviewPlatforms[0].platform) {
      case 'google':
        this.card.name = 'Google';
        break;
      case 'despegar':
        this.card.name = 'Decolar';
        break;
      case 'tripadvisor':
        this.card.name = 'TripAdvisor';
        break;
      case 'booking':
        this.card.name = 'Booking';
        break;
      case 'expedia':
        this.card.name = 'Expedia';
        break;
      case 'airbnb':
        this.card.name = 'Airbnb';
        break;
      case 'hotelscom':
        this.card.name = 'Hoteis.com';
        break;
    }
  }

  fmSubmit() {
    if (this.isNew) {
      if (!this.dashboard.reviewCards.length) {
        this.dashboard.reviewCards = [this.card];
      } else {
        this.dashboard.reviewCards.push(this.card);
      }
    } else {
      const index = this.dashboard.reviewCards.findIndex((card) => card._id == this.card._id);
      if (index > -1) {
        this.dashboard.reviewCards[index] = this.card;
      } else {
        this.isNew = true;
        this.fmSubmit();
      }
    }

    //@ts-ignore
    this.dashboard.reviewCards = this.dashboard.reviewCards.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        type: item.type,
        subType: item.subType,
        tagSet: item.tagSet,
        tags: item.tags.map((tag) => {
          if (typeof tag === 'string') {
            return tag;
          } else {
            return tag._id;
          }
        }),
        _reviewPlatforms: item._reviewPlatforms.map((platform) => {
          if (platform) {
            if (typeof platform === 'string') {
              return platform;
            } else {
              return platform._id;
            }
          } else {
            return null;
          }
        }),
        _businesses: item._businesses.map((business) => {
          if (business) {
            if (typeof business === 'string') {
              return business;
            } else {
              return business._id;
            }
          } else {
            return null;
          }
        })
      };
    });
    this.subscriptions.push(
      this.dashboardService.update(this.dashboard).subscribe(
        (x) => {
          this.result.next(x);
          if (this.isNew) {
            this.notifyService.success('Dados adicionados com sucesso!');
          } else {
            this.notifyService.success('Dados editados com sucesso!');
            window.location.reload();
          }
          this.bsModalRef.hide();
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Não foi possível adicionar os dados.');
        }
      )
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  checkPlatformIncluded(reviewPlatform) {
    if (this.isNew) {
      return this.card._reviewPlatforms.find((item) => item == reviewPlatform._id) == undefined ? false : true;
    } else {
      return this.card._reviewPlatforms.find((item) => item._id == reviewPlatform._id) == undefined ? false : true;
    }
  }

  checkBusinessIncluded(business) {
    let bId;

    if (typeof business === 'object') {
      bId = business._id;
    } else {
      bId = business;
    }

    return this.card._businesses.find((item) => item == bId) == undefined ? false : true;
  }

  nextStep() {
    this.showNameYourMetric = true;
    this.chooseBusiness = false;
  }

  finalStep() {
    this.chooseBusiness = false;
    this.showNameYourMetric = true;
  }

  openChat() {
    this.chatService.openChat();
  }
}
