import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';
import { BusinessService, EmpresaService, NotifyService, StoreService } from 'app/_services';
@Component({
  selector: 'app-setup-custom-domain-dialog',
  templateUrl: './setup-custom-domain-dialog.component.html',
  styleUrls: ['./setup-custom-domain-dialog.component.scss']
})
export class SetupCustomDomainComponent {
  constructor(
    public notifyService: NotifyService,
    public bsModalRef: BsModalRef,
    public empresaService: EmpresaService,
    public businessService: BusinessService,
    public store: StoreService
  ) {}

  public resultado: Subject<boolean> = new Subject();
  public subdomain;
  public domain;
  public sender;
  public customDKIMSelector;
  public isBusiness = false;

  validateInputs(evt) {
    evt.srcElement.value = evt.srcElement.value.replace('_', '');
    evt.srcElement.value = evt.srcElement.value.replace(' ', '');
    evt.srcElement.value = evt.srcElement.value.replace('@', '');
  }

  cancelar() {
    this.bsModalRef.hide();
  }

  confirmar() {
    if (!this.isBusiness) {
      this.empresaService.addCustomEmailDomain(this.store.empresaData._id, this.subdomain, this.domain, this.sender, this.customDKIMSelector).subscribe(
        () => {
          this.bsModalRef.hide();
          this.notifyService.success('Domínio personalizado adicionado com sucesso!');
          this.store.getEmpresaData();
        },
        (error) => {
          console.error(error);

          if (error.error.message == 'EMPRESA_NOT_FOUND') {
            this.notifyService.error('Houve um erro ao adicionar um domínio personalizado.');
          } else if (error.error.message == 'DOMAIN_ALREADY_ADDED') {
            this.notifyService.error('Domínio já configurado.');
          } else if (error.error.message == 'SENDGRID_FAILED') {
            this.notifyService.error('Erro ao contatar provedor de e-mail.');
          }
        }
      );
    } else {
      this.businessService.addCustomEmailDomain(this.store.business._id, this.subdomain, this.domain, this.sender, this.customDKIMSelector).subscribe(
        () => {
          this.notifyService.success('Domínio personalizado adicionado com sucesso!');
          this.store.getEmpresaData();
          this.businessService.getMyBusinessToEdit(this.store.business._id).subscribe((data) => {
            this.store.setBusiness(data);
            this.bsModalRef.hide();
          });
        },
        (error) => {
          console.error(error);

          if (error.error.message == 'EMPRESA_NOT_FOUND') {
            this.notifyService.error('Houve um erro ao adicionar um domínio personalizado.');
          } else if (error.error.message == 'DOMAIN_ALREADY_ADDED') {
            this.notifyService.error('Domínio já configurado.');
          } else if (error.error.message == 'SENDGRID_FAILED') {
            this.notifyService.error('Erro ao contatar provedor de e-mail.');
          }
        }
      );
    }
  }
}
