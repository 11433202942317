import { Component, OnDestroy } from '@angular/core';
import { Dashboard, DashboardCard, DashboardReviewCard } from 'app/_models/dashboard';
import { NotifyService } from 'app/_services';
import { DashboardService } from 'app/_services/dashboard.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-rename-card-dialog',
  templateUrl: './rename-card-dialog.component.html',
  styleUrls: ['./rename-card-dialog.component.scss']
})
export class RenameCardDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private dashboardService: DashboardService, private notifyService: NotifyService) {}

  public dashboard: Dashboard = new Dashboard();
  public subscriptions = [];
  public result: Subject<any> = new Subject();
  public card: DashboardCard;
  public reviewCard: DashboardReviewCard;

  fmSubmit() {
    if (this.dashboard.type == 'review') {
      this.subscriptions.push(
        this.dashboardService.updateCard(this.dashboard._id, this.reviewCard).subscribe(
          () => {
            this.result.next(this.reviewCard);
            this.notifyService.success('Card renomeado com sucesso!');
            this.bsModalRef.hide();
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Não foi possível renomear o card.');
            this.bsModalRef.hide();
          }
        )
      );
    } else {
      this.subscriptions.push(
        this.dashboardService.updateCard(this.dashboard._id, this.card).subscribe(
          () => {
            this.result.next(this.card);
            this.notifyService.success('Card renomeado com sucesso!');
            this.bsModalRef.hide();
          },
          (error) => {
            console.error(error);
            this.notifyService.error('Não foi possível renomear o card.');
            this.bsModalRef.hide();
          }
        )
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
