<div class="amp-dialog" *ngIf="step == 1">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Importar Contatos
    </div>
    <div class="amp-dialog-subtitle">Escolha de que forma deseja importar os contatos para a plataforma.</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <div class="amp-options">
      <div class="amp-option" (click)="defaultImport()">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Importação Normal</span>
          <span class="amp-option-description">Importar contatos com um arquivo .csv apenas em uma unidade, com a possibilidade de mapear os campos do arquivo para importar, definir uma tag e realizar o envio direto de pesquisa.
          </span>
        </div>
      </div>

      <div class="amp-option" (click)="sicrediImport()">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Importação Multiagências</span>
          <span class="amp-option-description">Importar contatos com um arquivo .xlsx em várias unidades. (Colunas exigidas no XLSX: nome, telefone, ag, carteira, cpf_cnpj, conta, bairro, estado e gestor) é importante que o nome das colunas sigam esse padrão, caso contrário resultará em uma importação incompleta ou com erros.</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="amp-dialog" *ngIf="step == 2">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Importar Contatos
    </div>
    <div class="amp-dialog-subtitle">Escolha qual envio você deseja fazer</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <div class="amp-options">
      <div class="amp-option" (click)="sicrediUploadFile('boas-vindas')">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Boas Vindas</span>
          <span class="amp-option-description">Pesquisa de Boas Vindas utilizando o template: pesquisa_satisfacao_novo</span>
        </div>
      </div>

      <div class="amp-option" (click)="sicrediUploadFile('inativos')">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Inativos</span>
          <span class="amp-option-description">Pesquisa de Inativos utilizando o template: sicredi_informa_novo</span>
        </div>
      </div>

      <div class="amp-option" (click)="sicrediUploadFile('saintes')">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Saintes</span>
          <span class="amp-option-description">Pesquisa de Saintes utilizando o template: COMUNICADO_ENCERRAMENTO_DE_CONTA_3_NOVO</span>
        </div>
      </div>
      <div class="amp-option" (click)="sicrediUploadFile('pesquisa-satisfacao')">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Pesquisa de Satisfação</span>
          <span class="amp-option-description">Pesquisa de Satisfação utilizando o template: pesquisa_satisfacao_novo</span>
        </div>
      </div>
      <div class="amp-option" (click)="sicrediUploadFile('only-import')">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Apenas Importar</span>
          <span class="amp-option-description">Apenas atualizar os dados dos contatos</span>
        </div>
      </div>

      <div class="amp-option" (click)="sicrediUploadFile('pesquisa-satisfacao-senhas')">
        <div class="amp-option-left">
          <i class="bi-upload"></i>
        </div>
        <div class="amp-option-right">
          <span class="amp-option-title">Pesquisa de Satisfação (SENHAS)</span>
          <span class="amp-option-description">Pesquisa de Satisfação utilizando o template: pesquisa_satisfacao_novo e o arquivo .xlsx de Senhas</span>
        </div>
      </div>
    </div>
  </div>
</div>
