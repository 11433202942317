import { Campaign } from './../../_models/referral/campaign';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import moment from 'moment';
@Injectable()
export class CampaignService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  create(data: Campaign) {
    return this.http.post(this.config.apiUrl + '/referral/campaign/', data);
  }

  getAll(business: string) {
    return this.http.get<Campaign[]>(this.config.apiUrl + '/referral/campaign/all/' + business);
  }

  update(data: Campaign) {
    return this.http.put<any>(this.config.apiUrl + '/referral/campaign/' + data._id, data);
  }

  updateCampaignIpBlacklist(campaignId, ip_blacklist, operation = 'update') {
    return this.http.put<any>(this.config.apiUrl + '/referral/campaign/' + campaignId + '/ip-blacklist', { ip_blacklist, operation });
  }

  getActive(business: string) {
    return this.http.get<Campaign[]>(this.config.apiUrl + '/referral/campaign/active/' + business);
  }

  get(campaign: string) {
    return this.http.get<Campaign>(this.config.apiUrl + '/referral/campaign/' + campaign);
  }

  getStats(campaign: string) {
    return this.http.get<any>(this.config.apiUrl + '/referral/campaign/stats/' + campaign);
  }

  reset(campaign: string) {
    return this.http.patch<any>(this.config.apiUrl + '/referral/campaign/reset/' + campaign, {});
  }

  recalculateMilestones(campaign: string) {
    return this.http.patch<any>(this.config.apiUrl + '/referral/campaign/recalculate-milestones/' + campaign, {});
  }

  delete(campaign: string) {
    return this.http.delete<any>(this.config.apiUrl + '/referral/campaign/' + campaign);
  }

  getDetails(campaign: string, limit: number, page: number, sortBy: string, filter: string) {
    // @ts-ignore
    const query = new URLSearchParams({
      limit,
      page,
      sortBy,
      filterMessage: filter
    });

    return this.http.get<any>(this.config.apiUrl + '/referral/campaign/details/' + campaign + '?' + query.toString());
  }

  getRewards(campaign: string, limit: number, page: number, filter: string) {
    // @ts-ignore
    const query = new URLSearchParams({
      limit,
      page,
      filterMessage: filter
    });

    return this.http.get<any>(this.config.apiUrl + '/referral/campaign/rewards/' + campaign + '?' + query.toString());
  }

  getRewardsClassicList(campaign: string, limit: number, page: number, filter: string) {
    // @ts-ignore
    const query = new URLSearchParams({
      limit,
      page,
      filterMessage: filter
    });

    return this.http.get<any>(this.config.apiUrl + '/referral/campaign/rewards-classic/' + campaign + '?' + query.toString());
  }

  getReferrals(campaign: string, limit: number, page: number, filter: string, status?: boolean) {
    let query;
    if (status !== undefined) {
      // @ts-ignore
      query = new URLSearchParams({
        limit,
        page,
        filterMessage: filter
      });
    } else {
      // @ts-ignore
      query = new URLSearchParams({
        limit,
        page,
        status,
        filterMessage: filter
      });
    }
    return this.http.get<any>(this.config.apiUrl + '/referral/campaign/referrals/' + campaign + '?' + query.toString());
  }

  getCampaignStatsOverTime(campaign: string, filterDate) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startAt: start,
      endAt: end
    });

    return this.http.get<any>(this.config.apiUrl + `/referral/campaign/${campaign}/stats-overtime?${query.toString()}`);
  }

  importRewards(fileURL, keys, campaignId, businessId) {
    return this.http.patch<any>(this.config.apiUrl + `/referral/campaign/import-rewards`, {
      keys,
      file: fileURL,
      campaign: campaignId,
      business: businessId
    });
  }

  resendMilestoneRewards(rewardId) {
    return this.http.patch<any>(this.config.apiUrl + `/referral/campaign/resend-milestone-email/${rewardId}`, {});
  }
}
