import mixpanel from 'mixpanel-browser';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from './../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MixPanelService {
  public userId;
  public email;
  public name;
  public business;
  public empresa;
  public initialized = false;

  constructor(public router: Router) {
    try {
      if (environment.production) {
        if (!this.initialized) {
          mixpanel.init('851074d875e9d28b568898a162886028', {
            //@ts-ignore
            track_pageview: true
          });
          this.initialized = true;
        }
        this.router.events
          .pipe(filter((event) => event instanceof NavigationEnd))

          .subscribe(() => {
            this.track('Loaded a Page', {
              title: document.title,
              path: window.location.pathname,
              search: window.location.search
            });
          });
      }
    } catch (exception) {
      console.error(exception);
    }
  }

  init(userId: string, email: string, name: string) {
    try {
      this.userId = userId;
      this.email = email;
      this.name = name;
      if (environment.production) {
        if (!this.initialized) {
          mixpanel.init('851074d875e9d28b568898a162886028', {
            //@ts-ignore
            track_pageview: true
          });
          this.initialized = true;
        }
        const data: any = {};
        data.$last_login = new Date();
        if (userId != null) {
          mixpanel.identify(userId);
        }
        if (email != null) {
          data.$email = email;
        }
        if (name != null) {
          data.$name = name;
        }
        mixpanel.people.set(data);
      }
    } catch (exception) {
      console.error(exception);
    }
  }

  setBusiness(business: string, empresa: string) {
    try {
      this.business = business;
      this.empresa = empresa;
      if (environment.production) {
        mixpanel.people.set({
          business: business,
          empresa: empresa
        });
      }
    } catch (exception) {
      console.error(exception);
    }
  }

  track(id: string, action: any = {}) {
    try {
      if (environment.production) {
        mixpanel.track(id, action);
      }
    } catch (exception) {
      console.error(exception);
    }
  }

  trackDataLayer(event: string, data?: any) {
    try {
      if (data) {
        const data_final = {};
        data_final[event] = data;
        globalThis.dataLayer.push(data_final);
      } else {
        globalThis.dataLayer.push({ event });
      }
    } catch (exception) {
      console.error(exception);
    }
  }
}
