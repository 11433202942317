export default class Partner {
  constructor(_id, name, internal_name, description, category, logo, color, integration_description, self_setting) {
    this._id = _id;
    this.internal_name = internal_name;
    this.name = name;
    this.description = description;
    this.category = category;
    this.logo = logo;
    this.color = color;
    this.integration_description = integration_description;
    this.self_setting = self_setting;
  }

  _id: string;
  name: string;
  internal_name: string;
  description: string;
  category: string;
  logo: string;
  color: string = '#ffca00';
  integration_description: string;
  self_setting: boolean;
}
