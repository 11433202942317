import { CompanyDialogComponent } from '../company-dialog/company-dialog.component';
import { CustomerService } from './../../_services/customer.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MixPanelService } from 'app/_services/mixPanel.service';
import { CompanyService } from 'app/_services/company.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from './../../_services/menu.service';
import { NotifyService, StoreService } from 'app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
@Component({
  selector: 'app-contato-dialog',
  templateUrl: './contato-dialog.component.html',
  styleUrls: ['./contato-dialog.component.scss']
})
export class ContatoDialogComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private store: StoreService,
    public bsModalRef: BsModalRef,
    private menuService: MenuService,
    private mixPanel: MixPanelService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private companyService: CompanyService,
    private customerService: CustomerService
  ) {
    this.menuService.emit(true);
  }

  public isFromTicketing = false;
  public subscriptions = [];
  public customer: any = { name: '', tel: '', email: '' };
  public isNew = true;
  public isProfileEdit = false;
  public businessId: string;
  public userId: string;
  public allowSaveButton = true;
  public selectedCompany = '';
  public companySelector = false;
  public companyField = false;
  public companyName = '';
  public validNumber = false;
  public companies = [];
  public actions = [
    {
      name: 'createCompany',
      label: 'Criar Empresa',
      icon: 'bi-plus-lg'
    }
  ];

  ngOnInit() {
    if (!this.isNew) {
      if (this.customer.tel) {
        this.customer.tel = this.customer.tel.split('+55')[1];
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  onActionClick(name) {
    if (name == 'createCompany') {
      this.openCreateCompanyDialog();
    }
  }

  openCreateCompanyDialog() {
    this.modalService.show(CompanyDialogComponent, { class: 'amp-modal' });
  }

  requestCompaniesAutoComplete = (text): Observable<any> => {
    return this.companyService.getCompanies(this.store.empresa, text).pipe(
      map((companies) =>
        companies.map((company) => {
          return { value: company._id, label: company.name };
        })
      )
    );
  };

  save() {
    if (this.selectedCompany) {
      this.customer._company = this.selectedCompany;
    }

    if (this.customer.name) {
      if (this.customer.name.length < 3) {
        this.notifyService.warning('O nome deve ter pelo menos 3 caracteres!');
        return;
      }
    } else {
      this.notifyService.warning('O campo nome é obrigatório!');
      return;
    }

    if (!this.customer.email && !this.customer.tel) {
      this.notifyService.warning('Preencha pelo menos uma forma de contato!');
    } else {
      this.mixPanel.track('Edit Customer');
      this.customer._business = this.businessId;
      if (this.customer.tel == null && this.customer.email == null) {
        this.subscriptions.push(
          this.translate.get('notify.contato.warning_contact_data').subscribe((res) => {
            this.notifyService.warning(res);
          })
        );
      } else {
        this.executeSave();
      }
    }
  }

  executeSave() {
    if (this.isNew) {
      this.subscriptions.push(
        this.customerService.create(this.customer).subscribe((data) => {
          if (data.err_code) {
            this.notifyService.error('O telefone ou e-mail já estão cadastrados na plataforma!');
            if (!this.isFromTicketing) {
              this.router.navigate(['contatos/' + data.customer._id]);
              this.bsModalRef.hide();
            }
          } else {
            this.bsModalRef.hide();
            this.subscriptions.push(
              this.translate.get('notify.customer.created').subscribe((res) => {
                this.notifyService.success(res);
              })
            );
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.customerService.update(this.customer).subscribe(() => {
          this.bsModalRef.hide();
          this.subscriptions.push(
            this.translate.get('notify.customer.updated').subscribe((res) => {
              this.notifyService.success(res);
            })
          );
        })
      );
    }
  }
}
