import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class AmplmeService {
  constructor(private http: HttpClient) {}

  check(code) {
    return this.http.get<any>('https://ampl.me/check/' + code + '?access_token=OnxJMKh3LOoIdecbhkGxWxJ4CV5ZODtgf3UMAy3JaKsIEfLQb6bt5zIfrABYlxvL');
  }
}
