import { Business } from '../business';
import { Survey } from '../customerFeedback/survey';
import { Empresa } from '../empresa';
import { User } from '../user';
import { RecurringAISummary } from './recurringAISummary';
export class AISummary {
  _businesses: string[];
  _surveys: string[];
  _recurring: RecurringAISummary | string;
  _historyBalance: string;
  _user: User | string;
  _empresa: Empresa | string;
  archived: boolean = false;
  frequency: string; // weekly, biweekly, monthly, onetime
  type = 'onetime'; // onetime, recurring
  content: string;
  status: string; // 'pending', 'running' ,'waiting', 'error', 'finished', 'error_insufficient_credits'
  start_date: Date;
  end_date: Date;
  name: string;
  generated_at: Date;
  answers_count: number;
  emails: string[];
  tokens_usage: {
    input: number;
    output: number;
    total: number;
  };
}
export class AISummaryPopulated extends AISummary {
  businesses?: Business[];
  surveys?: Survey[];
  empresa?: Empresa;
  user?: User;
  recurring?: RecurringAISummary;
  status_message: string; // MAX_AI_PROMPT_SIZE, INSUFFICIENT_AI_CREDITS, others*
}
