import { AmpLoaderService, AmpLoaderEvent, AmpLoaderEventType } from './loader.service';
import { Component, OnInit, Input, ChangeDetectionStrategy, AfterViewInit, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
@Component({
  selector: 'amp-loader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(public service: AmpLoaderService, private _elmRef: ElementRef, private _changeDetectorRef: ChangeDetectorRef) {}

  @Input() show = false;

  public subscriptions = [];

  ngOnInit() {
    this.subscriptions.push(
      this.service.events.subscribe((event: AmpLoaderEvent) => {
        if (event.type === AmpLoaderEventType.VISIBLE) {
          this.show = event.value;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.service.events.subscribe((event: AmpLoaderEvent) => {
        this._elmRef.nativeElement.visible = event.type === AmpLoaderEventType.VISIBLE ? event.value : true;
        this._changeDetectorRef.detectChanges();
      })
    );
  }
}
