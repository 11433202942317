<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      {{ title }}
    </div>
    <div class="amp-dialog-subtitle"><span [innerHtml]="message"></span><span class="amp-dialog-subtitle-strong" [innerHtml]="strongText"></span> <span [innerHtml]="messageEnd"></span></div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-field" *ngIf="forceConfirmation">
      <label class="amp-label-text" for="title">Digite <b>CONFIRMAR</b> para confirmar a ação</label>
      <input type="text" class="amp-input-lg confirmation" [(ngModel)]="confirmField" autocomplete="off" placeholder="CONFIRMAR" #confirm="ngModel" name="confirm" confirm required>
    </div>
    <button class="amp-btn-danger" [disabled]="forceConfirmation && confirmField.toLowerCase() != 'confirmar'" (click)="confirmar()">{{buttonName}}</button>
    <button class="amp-btn-outline-cancel" (click)="cancelar()">Cancelar</button>
  </div>