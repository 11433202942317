import { UploadFileDialogComponent } from '../upload-file-dialog/upload-file-dialog.component';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BusinessService, NotifyService } from 'app/_services';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss']
})
export class GalleryDialogComponent implements OnInit, OnDestroy {
  constructor(
    public store: StoreService,
    public bsModalRef: BsModalRef,
    public notifyService: NotifyService,
    private modalService: BsModalService,
    public businessService: BusinessService
  ) {}

  public type;
  public minWidth;
  public maxWidth;
  public minHeight;
  public maxHeight;
  public aspectRatio;
  public resizeWidth;
  public isCover = false;
  public subscriptions = [];
  public gallery: string[] = [];
  public result: Subject<string> = new Subject();

  ngOnInit() {
    this.subscriptions.push(
      this.businessService.getGallery(this.store.business._id).subscribe(
        (data) => {
          this.gallery = data;
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Houve um erro ao obter a lista de imagens.');
          this.bsModalRef.hide();
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  deleteImage(image) {
    const initialState = {
      title: 'Excluir imagem',
      message: 'Tem certeza que deseja excluir esta imagem?',
      forceConfirmation: false
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.businessService.removeImageFromGallery(this.store.business._id, image).subscribe(
              (data) => {
                this.gallery = data;
                this.notifyService.success('Imagem removida com sucesso!');
              },
              (error) => {
                console.error(error);
                this.notifyService.error('Não foi possível remover a imagem da galeria.');
              }
            )
          );
        }
      })
    );
  }

  onSelectImage(ref: HTMLElement, image: string) {
    if (!this.isImageAllowed(ref)) {
      return;
    }
    this.result.next(image);
    this.bsModalRef.hide();
  }

  isImageAllowed(el: HTMLElement) {
    if (!this.isCover || el == null) {
      return true;
    }

    return (
      //@ts-ignore
      Math.floor((el.children[0].naturalWidth / el.children[0].naturalHeight) * 10) / 10 === Math.floor((600 / 160) * 10) / 10 ||
      //@ts-ignore
      Math.floor((el.children[0].naturalWidth / el.children[0].naturalHeight) * 10) / 10 === Math.floor((16 / 9) * 10) / 10
    );
  }

  uploadImage() {
    this.aspectRatio = null;
    this.minWidth = 150;
    this.maxWidth = 3840;
    this.minHeight = 50;
    this.maxHeight = 2160;
    this.resizeWidth = 1024;
    this.type = this.isCover ? 'cover' : 'any';

    const initialState = {
      fileType: 'images',
      aspectRatio: this.aspectRatio,
      minWidth: this.minWidth,
      maxWidth: this.maxWidth,
      minHeight: this.minHeight,
      maxHeight: this.maxHeight,
      type: this.type
    };

    const BsModalRef = this.modalService.show(UploadFileDialogComponent, {
      class: 'modal-lg amp-modal',
      initialState
    });

    BsModalRef.content.result.subscribe((data) => {
      if (data) {
        this.subscriptions.push(
          this.businessService.addImageToGallery(this.store.business._id, data).subscribe(() => {
            this.gallery.push(data);
            this.onSelectImage(null, data);
          })
        );
        this.notifyService.success('Foto alterada com sucesso!');
      } else {
        this.notifyService.error('Houve um erro ao alterar a foto.');
      }
    });
  }
}
