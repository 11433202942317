import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-input-card-dialog',
  templateUrl: './input-card-dialog.component.html',
  styleUrls: ['./input-card-dialog.component.scss']
})
export class InputCardDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public result: Subject<any> = new Subject();
  public model;
  public placeholder = '';
  public inputLabel = '';
  public title = '';
  public subtitle = '';
  public buttonText = '';
  public textArea = false;

  submit() {
    this.result.next(this.model);
    this.bsModalRef.hide();
  }

  close() {
    this.bsModalRef.hide();
  }
}
