<i (click)="closeModal()" class="bi-x-lg amp-close-button"></i>

<!-- Adicionar Dados -->

<div *ngIf="showAddNewMetric" class="amp-dialog">
  <p class="amp-dialog-title">Adicionar Dados</p>

  <div class="amp-dialog-subtitle">Selecione uma opção abaixo para adicionar ao seu painel.</div>

  <div (click)="showAddNewMetric = false; showAddSurveys = true; card.type = 'score'" class="amp-options">
    <div class="amp-option">
      <div class="amp-option-left">
        <i class="bi-star"></i>
      </div>
      <div class="amp-option-right">
        <span class="amp-option-title">Plataformas</span>
        <span class="amp-option-description">Adicione um card com informações e estatísticas das avaliações de suas propriedades.</span>
      </div>
    </div>
  </div>
  <div (click)="showAddNewMetric = false; showSelectPlatformForWordCloud = true; card.type = 'word-cloud'" class="amp-options">
    <div class="amp-option">
      <div class="amp-option-left">
        <i class="bi-cloud"></i>
      </div>
      <div class="amp-option-right">
        <span class="amp-option-title">Nuvem de Palavras</span>
        <span class="amp-option-description">Adicione um card com uma nuvem de palavras das respostas de texto de
          suas propriedades.</span>
      </div>
    </div>
  </div>
</div>

<!-- Selecionar Método de Comparação -->

<!-- <div *ngIf="!chooseBusiness" class="amp-dialog">
  <p class="amp-dialog-title">Método de Comparação</p>

  <div class="amp-dialog-subtitle">Escolha abaixo se deseja fazer a comparação entre a média das unidades ou entre plataformas do mesmo tipo.</div>

  <div class="amp-body">
    <div class="amp-platforms">
      <div tooltip="Média Geral das Unidades: Escolha esta opção para comparar a média geral das suas unidades ou de concorrentes cadastrados" placement="bottom" (click)="chooseBusiness = true; card.subType = 'average'" class="amp-platform total-average">
        <i class="bi-building"></i>
      </div>
      <div class="amp-platform" (click)="card.subType = 'google'; chooseYourPlatforms = true">
        <div *ngIf="card.subType == 'google'" class="selected">
          <i class="bi-check-lg"></i>
        </div>
        <img src="../../../assets/img/google-icon.svg" />
      </div>
      <div class="amp-platform" (click)="card.subType = 'tripadvisor'; chooseYourPlatforms = true">
        <div *ngIf="card.subType == 'tripadvisor'" class="selected">
          <i class="bi-check-lg"></i>
        </div>
        <img src="../../../assets/img/tripadvisor-icon.svg" />
      </div>
      <div class="amp-platform" (click)="card.subType = 'booking'; chooseYourPlatforms = true">
        <div *ngIf="card.subType == 'booking'" class="selected">
          <i class="bi-check-lg"></i>
        </div>
        <img src="../../../assets/img/booking-icon.svg" />
      </div>
      <div class="amp-platform" (click)="card.subType = 'hotelscom'; chooseYourPlatforms = true">
        <div *ngIf="card.subType == 'hotelscom'" class="selected">
          <i class="bi-check-lg"></i>
        </div>
        <img src="../../../assets/img/hotelscom-icon.svg" />
      </div>
      <div class="amp-platform" (click)="card.subType = 'despegar'; chooseYourPlatforms = true">
        <div *ngIf="card.subType == 'despegar'" class="selected">
          <i class="bi-check-lg"></i>
        </div>
        <img src="../../../assets/img/despegar.svg" />
      </div>
      <div class="amp-platform" (click)="card.subType = 'expedia'; chooseYourPlatforms = true">
        <div *ngIf="card.subType == 'expedia'" class="selected">
          <i class="bi-check-lg"></i>
        </div>
        <img src="../../../assets/img/expedia-icon.svg" />
      </div>
      <div class="amp-platform" (click)="card.subType = 'airbnb'; chooseYourPlatforms = true">
        <div *ngIf="card.subType == 'airbnb'" class="selected">
          <i class="bi-check-lg"></i>
        </div>
        <img src="../../../assets/img/airbnb-icon.svg" />
      </div>
    </div>
  </div>
</div> -->

<!-- Escolhas Suas Unidades -->

<!-- <div *ngIf="chooseBusiness" class="amp-dialog">
  <p class="amp-dialog-title">Escolha Suas Unidades</p>

  <div class="amp-dialog-subtitle">
    Selecione quais das suas unidades deseja incluir nessa comparação. No próximo passo, você poderá escolher as unidades concorrentes.
  </div>

  <div class="amp-surveys">
    <label>Encontrar Unidades</label>
    <input type="search" name="search" class="amp-input" [(ngModel)]="searchField" placeholder="Digite para buscar" autocomplete="off" />

    <div class="amp-select-all">
      <label class="amp-checkbox">
        <input type="checkbox" [checked]="selectedAllBusinesses" (change)="selectAllBusinesses()" />
        <span class="text">Selecionar Todas</span>
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="amp-survey-list">
      <label class="amp-checkbox" *ngFor="let business of businesses | filterPipe: { field: 'nome', value: searchField }">
        <div class="amp-option">
          <input type="checkbox" [checked]="checkBusinessIncluded(business)" (change)="toggleBusiness(business)" />
          <span class="amp-option-text">{{ business.nome }}</span>
          <span class="checkmark"></span>
        </div>
      </label>
    </div>
  </div>

  <div class="amp-buttons-vertical">
    <button [disabled]="!this.card._businesses.length" (click)="nextStep()" class="amp-new-button">Próximo Passo</button>
  </div>
</div> -->

<!-- Escolhas Suas Plataformas -->

<!-- <div *ngIf="chooseYourPlatforms && !chooseBusiness" class="amp-dialog">
  <p class="amp-dialog-title"><i class="bi-bar-chart"></i>Escolha Suas Plataformas</p>

  <div class="amp-dialog-subtitle">
    Selecione quais das suas plataformas deseja incluir nessa comparação. No próximo passo, você poderá escolher as plataformas dos concorrentes.
  </div>

  <div class="amp-surveys">
    <label>Encontrar Plataformas</label>
    <input type="search" name="search" class="amp-input" [(ngModel)]="searchField" placeholder="Digite para buscar" autocomplete="off" />

    <div class="amp-select-all">
      <label class="amp-checkbox">
        <input type="checkbox" [checked]="selectedAllPlatforms" (change)="selectAllPlatforms()" />
        <span class="text">Selecionar Todas</span>
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="amp-survey-list">
      <label class="amp-checkbox" *ngFor="let platform of reviewPlatforms | filterPipe: { field: 'name', value: searchField, field2: 'platform', value2: card.subType, type: 'and' }">
        <div class="amp-option">
          <input type="checkbox" [checked]="checkPlatformIncluded(platform)" (change)="togglePlatform(platform)" />
          <span class="amp-option-text">{{ platform.name }}</span>
          <span class="amp-option-type">{{ getPlatformName(platform.platform) }}</span>
          <span class="checkmark"></span>
        </div>
      </label>
    </div>
  </div>

  <div class="amp-buttons-vertical amp-platform-dialog">
    <button [disabled]="!this.card._reviewPlatforms.length" (click)="searchField = ''; showAddSurveys = false; chooseYourPlatforms = false; showNameYourMetric = false" class="amp-new-button">
      Próximo Passo
    </button>
  </div>
</div> -->

<!-- Selecionar Pesquisas -->

<div *ngIf="showSelectSurvey" class="amp-dialog">
  <p class="amp-dialog-title">Selecionar Plataformas</p>

  <div class="amp-dialog-subtitle">Selecione quais plataformas deseja incluir no gráfico.</div>

  <div class="amp-surveys">
    <label>Encontrar plataformas</label>
    <input type="search" name="search" class="amp-input" [(ngModel)]="searchField" placeholder="Digite para buscar" autocomplete="off" />
    <i class="bi-search"></i>
    <div class="amp-survey-list">
      <label class="amp-checkbox" *ngFor="let platform of reviewPlatforms | filterPipe: { field: 'name', value: searchField }">
        <div class="amp-option">
          <input type="checkbox" [checked]="checkPlatformIncluded(platform)" (change)="togglePlatform(platform)" />
          <span class="amp-option-text">{{ platform.title }}</span>
          <span class="amp-option-type">{{ getPlatformName(platform.platform) }}</span>
          <span class="checkmark"></span>
        </div>
      </label>
    </div>
  </div>

  <button [disabled]="!this.card._reviewsPlatforms.length" (click)="showSelectSurvey = false; showSelectTags = true" class="amp-btn-lg amp-continue">
    Selecionar Plataformas
  </button>
</div>

<!-- WordCloud Select ReviewPlatform -->

<div *ngIf="showSelectPlatformForWordCloud" class="amp-dialog">
  <p class="amp-dialog-title">Selecionar Plataformas</p>

  <div class="amp-dialog-subtitle">Selecione quais plataformas deseja incluir no gráfico.</div>
  <div class="amp-surveys">
    <label>Encontrar plataformas</label>
    <input type="search" name="search" class="amp-input" [(ngModel)]="searchField" placeholder="Digite para buscar" autocomplete="off" />

    <div class="amp-select-all">
      <label class="amp-checkbox">
        <input type="checkbox" [checked]="selectedAll" (change)="selectAllPlatforms()" />
        <span class="text">Selecionar Todas</span>
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="amp-survey-list">
      <label class="amp-checkbox" *ngFor="let platform of reviewPlatforms | filterPipe: { field: 'name', value: searchField }">
        <div class="amp-option">
          <input type="checkbox" [checked]="checkPlatformIncluded(platform)" (change)="togglePlatform(platform)" />
          <span class="amp-option-text">{{ platform.name }}</span>
          <span class="amp-option-type">{{ getPlatformName(platform.platform) }}</span>
          <span class="checkmark"></span>
        </div>
      </label>
    </div>
  </div>

  <button [disabled]="!card._reviewPlatforms?.length" (click)="showSelectPlatformForWordCloud = false; toggleNameCard(card._reviewPlatforms)" class="amp-btn-lg amp-continue">
    Selecionar Plataformas
  </button>
</div>

<!-- Adicionar Pesquisas -->

<div *ngIf="showAddSurveys" class="amp-dialog">
  <p class="amp-dialog-title">Adicionar Plataformas</p>

  <div class="amp-dialog-subtitle">
    Selecione uma ou mais plataformas para adicionar ao seu painel. Também é possível juntá-las para criar uma métrica geral baseada na sua seleção.
  </div>

  <div class="amp-surveys">
    <label>Encontrar plataformas</label>
    <input type="search" name="search" class="amp-input" [(ngModel)]="searchField" placeholder="Digite para buscar" autocomplete="off" />

    <div class="amp-select-all">
      <label class="amp-checkbox">
        <input type="checkbox" [checked]="selectedAll" (change)="selectAllPlatforms()" />
        <span class="text">Selecionar Todas</span>
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="amp-survey-list">
      <label class="amp-checkbox" *ngFor="let platform of reviewPlatforms | filterPipe: { field: 'name', value: searchField }">
        <div class="amp-option">
          <input type="checkbox" [checked]="checkPlatformIncluded(platform)" (change)="togglePlatform(platform)" />
          <span class="amp-option-text">{{ platform.name }}</span>
          <span class="amp-option-type">{{ getPlatformName(platform.platform) }}</span>
          <span class="checkmark"></span>
        </div>
      </label>
    </div>
  </div>

  <div class="amp-buttons-vertical">
    <button [disabled]="!reviewPlatforms.length || !card._reviewPlatforms.length" (click)="showAddSurveys = false; toggleNameCard(card._reviewPlatforms); showNameYourMetric = true; multiSurveyMetric = true" class="amp-new-button">
      {{ isNew ? 'Nova' : 'Editar' }} métrica baseada na seleção
    </button>
  </div>
</div>

<!-- Nomeie Seu Card -->

<div *ngIf="showNameYourMetric" class="amp-dialog">
  <p class="amp-dialog-title">Nomeie Seu Card</p>

  <div class="amp-dialog-subtitle">Escolha um nome que represente o card que você acabou de configurar para que seja seu título no painel.</div>

  <div class="amp-metric-name">
    <label>Nome do seu card</label>
    <input (keyUp.enter)="card.name.length ? fmSubmit() : ''" class="amp-input" placeholder="Ex.: Locais da Zona Sul" [(ngModel)]="card.name" autocomplete="off" />
  </div>

  <div class="amp-buttons-horizontal">
    <button [disabled]="card.name.length == 0" (click)="showSelectTags = false; showNameYourMetric = true; fmSubmit()" class="amp-btn-success">
      {{ isNew ? 'Criar' : 'Editar' }} métrica
    </button>

    <button (click)="closeModal()" class="amp-btn-outline-cancel amp-btn-lg">Cancelar</button>
  </div>
</div>