import { ReputationSurveyErrorDialogComponent } from './reputation-management/reputation-survey-error-dialog/reputation-survey-error-dialog.component';
import { CustomerSurveyErrorDialogComponent } from './customer-feedback/customer-survey-error-dialog/customer-survey-error-dialog.component';
import { RenameQuestionDialogComponent } from './customer-feedback/surveys/rename-question-dialog/rename-question-dialog.component';
import { AmpDeliveryProgressBarComponent } from './amp-components/amp-delivery-progress-bar/amp-delivery-progress-bar.component';
import { MilestonesCustomerDialogComponent } from './_dialogs/milestones-customer-dialog/milestones-customer-dialog.component';
import { UnblockParticipantDialogComponent } from './_dialogs/unblock-participant-dialog/unblock-participant-dialog.component';
import { SheetsIntegrationDialogComponent } from './_dialogs/sheets-integration-dialog/sheets-integration-dialog.component';
import { ValidarRecompensaDialogComponent } from './contatos/validar-recompensa-dialog/validar-recompensa-dialog.component';
import { ManageBlockedIPsDialogComponent } from './_dialogs/manage-blocked-ips-dialog/manage-blocked-ips-dialog.component';
import { ValidarIndicacaoDialogComponent } from './contatos/validar-indicacao-dialog/validar-indicacao-dialog.component';
import { BlockParticipantDialogComponent } from './_dialogs/block-participant-dialog/block-participant-dialog.component';
import { ConfirmReferralDialogComponent } from './_dialogs/confirm-referral-dialog/confirm-referral-dialog.component';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateCompiler } from '@ngx-translate/core';
import { ToggleIPStatusDialogComponent } from './_dialogs/toggle-ip-status-dialog/toggle-ip-status-dialog.component';
import { TagSetManagerDialogComponent } from './smart-tags/tag-set-manager-dialog/tag-set-manager-dialog.component';
import { ChangeBusinessDialogComponent } from './_dialogs/change-business-dialog/change-business-dialog.component';
import { SelectTemplateDialogComponent } from './_dialogs/select-template-dialog/select-template-dialog.component';
import { DisableAccountDialogComponent } from './_dialogs/disable-account-dialog/disable-account-dialog.component';
import { ImportRewardsDialogComponent } from './_dialogs/import-rewards-dialog/import-rewards-dialog.component';
import { ViewAnswerDialogComponent } from './customer-feedback/view-answer-dialog/view-answer-dialog.component';
import { ImageCropperDialogComponent } from './_dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { NetworkSelectorDialogComponent } from './contatos/network-dialog/network-selector-dialog.component';
import { GetCpfCnpjDialogComponent } from './subscription/get-cpf-cnpj-dialog/get-cpf-cnpj-dialog.component';
import { ChangeStatusDialogComponent } from './_dialogs/change-status-dialog/change-status-dialog.component';
import { RenameCardDialogComponent } from './cf-dashboard/rename-card-dialog/rename-card-dialog.component';
import { CampaignDialogComponent } from './gerenciar-referral/campaigns/create/campaign-dialog.component';
import { CompanyListDialogComponent } from './contatos/company-list-dialog/company-list-dialog.component';
import { LinkCreatorDialogComponent } from './_dialogs/link-creator-dialog/link-creator-dialog.component';
import { NewBusinessDialogComponent } from './_dialogs/new-business-dialog/new-business-dialog.component';
import { CloneSurveyDialogComponent } from './_dialogs/clone-survey-dialog/clone-survey-dialog.component';
import { TermsOfUseDialogComponent } from './_dialogs/terms-of-use-dialog/terms-of-use-dialog.component';
import { TagManagerDialogComponent } from './smart-tags/tag-manager-dialog/tag-manager-dialog.component';
import { NewTagSetDialogComponent } from './smart-tags/new-tag-set-dialog/new-tag-set-dialog.component';
import { AlertsAreaComponent, AlertTypePipe } from './amp-components/alerts-area/alerts-area.component';
import { UploadFileDialogComponent } from './_dialogs/upload-file-dialog/upload-file-dialog.component';
import { AddWebhookDialogComponent } from './_dialogs/add-webhook-dialog/add-webhook-dialog.component';
import { ForwardDialogComponent } from './customer-feedback/forward-dialog/forward-dialog.component';
import { SnippetReferralComponent } from './_dialogs/snippet-referral/snippet-referral.component';
import { WebhookDetailsComponent } from './settings/WebhookDetails/webhook-details.component';
import { BlockIPDialogComponent } from './_dialogs/block-ip-dialog/block-ip-dialog.component';
import { TemplateDialogComponent } from './admin/templates/create/template-dialog.component';
import { NewTagDialogComponent } from './smart-tags/new-tag-dialog/new-tag-dialog.component';
import { ContatoDialogComponent } from './contatos/contato-dialog/contato-dialog.component';
import { ConfirmDialogComponent } from './_dialogs/confirm-dialog/confirm-dialog.component';
import { CompanyDialogComponent } from './contatos/company-dialog/company-dialog.component';
import { LoadingDialogComponent } from './_dialogs/loading-dialog/loading-dialog.component';
import { GalleryDialogComponent } from './_dialogs/gallery-dialog/gallery-dialog.component';
import { PartnerDialogComponent } from './_dialogs/partner-dialog/partner-dialog.component';
import { EmpresaDialogComponent } from './admin/empresas/create/empresa-dialog.component';
import { FilterDialogComponent } from './_dialogs/filter-dialog/filter-dialog.component';
import { DeleteDialogComponent } from './_dialogs/delete-dialog/delete-dialog.component';
import { LogDialogComponent } from './settings/TokenLog/log-dialog/log-dialog.component';
import { WebhookPanelComponent } from './partners/webhook-panel/webhook-panel.component';
import { SurveyAnswerService } from './_services/customerFeedback/surveyAnswer.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenDialogComponent } from './_dialogs/token-dialog/token-dialog.component';
import { ErrorDialogComponent } from './_dialogs/error-dialog/error-dialog.component';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { InfoDialogComponent } from './_dialogs/info-dialog/info-dialog.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { CommonModule, registerLocaleData, TitleCasePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AmpComponentsModule } from './amp-components/amp-components.module';
import { ApiPanelComponent } from './partners/api-panel/api-panel.component';
import { SurveyService } from './_services/customerFeedback/survey.service';
import { ClientWebhooksService } from './_services/clientWebhooks.service';
import { AmpLoaderService } from './amp-components/loader/loader.service';
import { SmartTagService } from './_services/smartTags/smartTags.service';
import { EmailTemplateService } from './_services/emailTemplate.service';
import { ExportsComponent } from './settings/Exports/exports.component';
import { ReferralService } from './_services/referral/referral.service';
import { CampaignService } from './_services/referral/campaign.service';
import { TokenInterceptor } from 'app/_interceptors/token.interceptor';
import { NotificationService } from './_services/notification.service';
import { RequestQueueService } from './_services/requestQueue.service';
import { ImportReviewService } from './_services/importReview.service';
import { ChangeLogService } from './_services/adm/change-log.service';
import { AmplifiquemeModule } from '@amplifique.me/ngx-amplifiqueme';
import { CouponService } from './_services/referral/coupon.service';
import { AmpValidators } from './_validators/amp-validators.module';
import { TemplateService } from './_services/adm/template.service';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainDropdown } from './_directives/main-dropdown.module';
import { PartnersComponent } from './partners/partners.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DashboardService } from './_services/dashboard.service';
import { CustomerService } from './_services/customer.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { StripeAmpService } from './_services/stripe.service';
import { CostsService } from './_services/adm/costs.service';
import { CompanyService } from './_services/company.service';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ShowdownModule } from '@amplifiqueme/ngx-showdown';
import { ReviewService } from './_services/review.service';
import { PlanService } from './_services/adm/plan.service';
import { AmplmeService } from './_services/amplme.service';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MenuService } from 'app/_services/menu.service';
import { LoginComponent } from './login/login.component';
import { StoreService } from './_services/store.service';
import { TokenService } from './_services/token.service';
import { AdmService } from './_services/adm/adm.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AppRoutingModule } from './app.routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GlService } from './_services/google.service';
import { environment } from 'environments/environment';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MainPipe } from './_pipes/main-pipe.module';
import { CEPService } from './_services/cep.service';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ColorBlockModule } from 'ngx-color/block';
import { RoleGuard } from 'app/_guards/role.guard';
import { NouisliderModule } from 'ng2-nouislider';
import localePt from '@angular/common/locales/pt';
import { AppComponent } from './app.component';
import { NgNoty } from './amp-noty/src/index';
import { CookieModule } from 'ngx-cookie';
import { MomentModule } from 'ngx-moment';
import { AppConfig } from './app.config';
import 'moment/locale/pt-br';
import {
  AuthenticationService,
  UserService,
  NotifyService,
  FbService,
  BusinessService,
  EmpresaService,
  LinkService,
  SignupService,
  ForgotPasswordService,
  AcessoService,
  ReportService,
  TagService
} from './_services/index';

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem('access_token');
    }
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localePt, 'pt-BR');

import { AddReviewCardDialogComponent } from './reputation-management/add-review-card-dialog/add-review-card-dialog.component';
import { ExportSurveyDialogComponent } from './customer-feedback/surveys/export-survey-dialog/export-survey-dialog.component';
import { RenamePlatformDialogComponent } from './customer-feedback/rename-platform-dialog/rename-platform-dialog.component';
import { SetupCustomDomainComponent } from './_dialogs/setup-custom-domain-dialog/setup-custom-domain-dialog.component';
import { CreateDashboardDialogComponent } from './_dialogs/create-dashboard-dialog/create-dashboard-dialog.component';
import { ImportReferralsDialogComponent } from './_dialogs/import-referrals-dialog/import-referrals-dialog.component';
import { ManageSummariesDialogComponent } from './_dialogs/manage-summaries-dialog/manage-summaries-dialog.component';
import { ViewReviewDialogComponent } from './reputation-management/view-review-dialog/view-review-dialog.component';
import { CreatePipelineDialogComponent } from './ticketing/create-pipeline-dialog/create-pipeline-dialog.component';
import { EmpresaScheduleDeletionComponent } from './admin/empresas/schedule-deletion/schedule-deletion.component';
import { TagEditorListDialogComponent } from './contatos/tag-editor-list-dialog/tag-editor-list-dialog.component';
import { SelectImportDialogComponent } from './_dialogs/select-importer-dialog/select-importer-dialog.component';
import { CreateSummaryDialogComponent } from './_dialogs/create-summary-dialog/create-summary-dialog.component';
import { QueueLogDetailedComponent } from './settings/QueueLog/QueueLogDetailed/queue-log-detailed.component';
import { CreateTicketDialogComponent } from './ticketing/create-ticket-dialog/create-ticket-dialog.component';
import { ImagePreviewDialogComponent } from './_dialogs/image-preview-dialog/image-preview-dialog.component';
import { VideoPlayerDialogComponent } from './_dialogs/video-player-dialog/video-player-dialog.component';
import { PowerMenuDialogComponent } from './_dialogs/power-menu-dialog copy/power-menu-dialog.component';
import { TagEditorDialogComponent } from './contatos/tag-editor-dialog/tag-editor-dialog.component';
import { InputCardDialogComponent } from './_dialogs/input-card-dialog/input-card-dialog.component';
import { ContactEditorComponent } from './amp-components/contact-editor/contact-editor.component';
import { AmpMissingTranslationHandler } from './_services/AmpMissingTranslationHandler.service';
import { PasswordValidatorComponent } from './password-validator/password-validator.component';
import { TutorialDialogComponent } from './_dialogs/tutorial-dialog/tutorial-dialog.component';
import { ErrorAuthDialog } from './_dialogs/error-auth-dialog/error-auth-dialog.component';
import { SelectDialogComponent } from './_dialogs/select-dialog/select-dialog.component';
import { TwoFaDialogComponent } from './_dialogs/two-fa-dialog/two-fa-dialog.component';
import { SurveyGroupService } from './_services/customerFeedback/surveyGroup.service';
import { LoginDialogComponent } from './_dialogs/login-dialog/login-dialog.component';
import { TableDialogComponent } from './_dialogs/table-dialog/table-dialog.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PendingPaymentService } from './_services/adm/pendingPayment.service';
import { QueueLogComponent } from './settings/QueueLog/queue-log.component';
import { ImportsComponent } from './settings/Imports/imports.component';
import { CfFilterService } from './_services/cf-filter.service';
import { StarRatingConfigService } from 'angular-star-rating';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { SignupComponent } from './signup/signup.component';
import { UpdateService } from './_services/update.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UtilService } from './_services/util.service';
import { NgxCurrencyDirective } from 'ngx-currency';
import { FlagGuard } from './_guards/flag.guard';
import { AuthGuard } from './_guards/auth.guard';
import { QRCodeModule } from 'angularx-qrcode';
import { SafePipe } from './_pipes/safe.pipe';
@NgModule({
  declarations: [
    AppComponent,
    PasswordValidatorComponent,
    SignupComponent,
    AlertsAreaComponent,
    CreateAccountComponent,
    ContactEditorComponent,
    ForgotPasswordComponent,
    LoginComponent,
    TwoFaDialogComponent,
    NewBusinessDialogComponent,
    RenameCardDialogComponent,
    InputCardDialogComponent,
    RenamePlatformDialogComponent,
    ForwardDialogComponent,
    CustomerSurveyErrorDialogComponent,
    ReputationSurveyErrorDialogComponent,
    ViewAnswerDialogComponent,
    ViewReviewDialogComponent,
    CampaignDialogComponent,
    ContatoDialogComponent,
    RenameQuestionDialogComponent,
    ExportSurveyDialogComponent,
    InfoDialogComponent,
    TutorialDialogComponent,
    FilterDialogComponent,
    SheetsIntegrationDialogComponent,
    NetworkSelectorDialogComponent,
    ValidarIndicacaoDialogComponent,
    ValidarRecompensaDialogComponent,
    ErrorDialogComponent,
    ErrorAuthDialog,
    DeleteDialogComponent,
    AddWebhookDialogComponent,
    ImageCropperDialogComponent,
    UploadFileDialogComponent,
    ChangeBusinessDialogComponent,
    ChangeStatusDialogComponent,
    BlockIPDialogComponent,
    BlockParticipantDialogComponent,
    UnblockParticipantDialogComponent,
    ToggleIPStatusDialogComponent,
    ManageBlockedIPsDialogComponent,
    VideoPlayerDialogComponent,
    ImagePreviewDialogComponent,
    TemplateDialogComponent,
    TermsOfUseDialogComponent,
    SnippetReferralComponent,
    TokenDialogComponent,
    SelectTemplateDialogComponent,
    ConfirmDialogComponent,
    SelectDialogComponent,
    LoginDialogComponent,
    LogDialogComponent,
    CompanyDialogComponent,
    CompanyListDialogComponent,
    AmpDeliveryProgressBarComponent,
    TagEditorDialogComponent,
    TagEditorListDialogComponent,
    EmpresaDialogComponent,
    WebhookDetailsComponent,
    LoadingDialogComponent,
    GalleryDialogComponent,
    TagManagerDialogComponent,
    TagSetManagerDialogComponent,
    GetCpfCnpjDialogComponent,
    NewTagDialogComponent,
    NewTagSetDialogComponent,
    PartnersComponent,
    ApiPanelComponent,
    WebhookPanelComponent,
    PartnerDialogComponent,
    SetupCustomDomainComponent,
    MilestonesCustomerDialogComponent,
    ConfirmReferralDialogComponent,
    LinkCreatorDialogComponent,
    DisableAccountDialogComponent,
    CloneSurveyDialogComponent,
    CreateSummaryDialogComponent,
    ManageSummariesDialogComponent,
    ImportRewardsDialogComponent,
    ImportReferralsDialogComponent,
    SelectImportDialogComponent,
    TableDialogComponent,
    CreateDashboardDialogComponent,
    CreatePipelineDialogComponent,
    CreateTicketDialogComponent,
    AddReviewCardDialogComponent,
    PowerMenuDialogComponent,
    AlertTypePipe,
    EmpresaScheduleDeletionComponent,
    SafePipe,
    QueueLogComponent,
    QueueLogDetailedComponent,
    ExportsComponent,
    ImportsComponent
  ],
  imports: [
    BrowserModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory
      }
    }),
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AmpComponentsModule.forRoot(),
    ShowdownModule.forRoot({ emoji: true, noHeaderId: true, flavor: 'github' }),
    CookieModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    ScrollingModule,
    CommonModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    ColorBlockModule,
    TimepickerModule.forRoot(),
    NouisliderModule,
    MomentModule,
    DragDropModule,
    ImageCropperModule,
    MainPipe,
    MainDropdown,
    NgxMaskDirective,
    ModalModule.forRoot(),
    AmpValidators,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: AmpMissingTranslationHandler }
    }),
    AmplifiquemeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    QRCodeModule
  ],
  providers: [
    TitleCasePipe,
    AppConfig,
    AuthGuard,
    RoleGuard,
    FlagGuard,
    AuthenticationService,
    SmartTagService,
    RequestQueueService,
    UserService,
    FbService,
    BusinessService,
    EmpresaService,
    LinkService,
    StarRatingConfigService,
    SignupService,
    ForgotPasswordService,
    AcessoService,
    MenuService,
    JwtHelperService,
    NotifyService,
    NgNoty,
    ReportService,
    GlService,
    ReviewService,
    CouponService,
    ReferralService,
    CampaignService,
    CustomerService,
    BsModalService,
    CEPService,
    TagService,
    TokenService,
    StoreService,
    AmpLoaderService,
    CostsService,
    ClientWebhooksService,
    TemplateService,
    ImportReviewService,
    PlanService,
    CompanyService,
    EmailTemplateService,
    AmplmeService,
    AdmService,
    DashboardService,
    StripeAmpService,
    SurveyAnswerService,
    ChangeLogService,
    UtilService,
    SurveyService,
    SurveyGroupService,
    UpdateService,
    PendingPaymentService,
    NgxCurrencyDirective,
    CfFilterService,
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    NotificationService,
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
