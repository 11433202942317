import TkPipeline, { TkPipelineStep } from 'app/_models/ticketing/pipeline';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';

@Injectable({ providedIn: 'root' })
export class TkPipelineService {
  constructor(private httpClient: HttpClient, private config: AppConfig) {}

  getAll(empresaId: string) {
    return this.httpClient.get<TkPipeline[]>(`${this.config.apiUrl}/ticketing/pipeline?empresaId=${empresaId}`);
  }

  getById(id: string) {
    return this.httpClient.get<TkPipeline>(`${this.config.apiUrl}/ticketing/pipeline/${id}`);
  }

  create(pipeline: any) {
    return this.httpClient.post<TkPipeline>(`${this.config.apiUrl}/ticketing/pipeline`, pipeline);
  }

  update(id: string, pipeline: any) {
    return this.httpClient.put<TkPipeline>(`${this.config.apiUrl}/ticketing/pipeline/${id}`, pipeline);
  }

  delete(id: string) {
    return this.httpClient.delete<TkPipeline>(`${this.config.apiUrl}/ticketing/pipeline/${id}`);
  }

  getTicketsGroupedByStep(pipelineId: string, sortDirection = undefined, search = undefined, showArchived = false, filters = []) {
    const urlParams = new URLSearchParams();

    if (sortDirection) urlParams.set('sortDirection', sortDirection);
    if (search) urlParams.set('search', search);
    if (showArchived) urlParams.set('showArchived', showArchived.toString());

    return this.httpClient.post<TkPipelineStep[]>(`${this.config.apiUrl}/ticketing/pipeline/${pipelineId}/tickets?${urlParams.toString()}`, {
      filters
    });
  }

  getMoreTicketsGroupedByStep(
    pipelineId: string,
    stepId: string,
    skip = 0,
    limit = 20,
    sortDirection = undefined,
    search = undefined,
    showArchived = false,
    filters = []
  ) {
    const urlParams = new URLSearchParams();
    if (stepId) urlParams.set('stepId', stepId);
    if (skip) urlParams.set('skip', skip.toString());
    if (limit) urlParams.set('limit', limit.toString());
    if (sortDirection) urlParams.set('sortDirection', sortDirection);
    if (search) urlParams.set('search', search);
    if (showArchived) urlParams.set('showArchived', showArchived.toString());
    return this.httpClient.post<TkPipelineStep[]>(`${this.config.apiUrl}/ticketing/pipeline/${pipelineId}/tickets?${urlParams.toString()}`, {
      filters
    });
  }

  getPipelinesStats(pipelineIds: string[]) {
    return this.httpClient.post<any>(`${this.config.apiUrl}/ticketing/pipeline/get-stats`, { pipelineIds });
  }

  moveInBatch(pipelineId: string, oldStepId: string, newStepId: string) {
    return this.httpClient.put<any>(`${this.config.apiUrl}/ticketing/pipeline/${pipelineId}/move-batch`, { oldStepId, newStepId });
  }
}
