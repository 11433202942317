import { AddWebhookDialogComponent } from 'app/_dialogs/add-webhook-dialog/add-webhook-dialog.component';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { ClientWebhooksService } from 'app/_services/clientWebhooks.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotifyService } from '../../_services/notify.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from '../../_services/menu.service';
import { StoreService } from 'app/_services/store.service';
import Partner from 'app/_models/partner';
import { Token } from 'app/_models/token';
@Component({
  selector: 'app-webhook-panel',
  templateUrl: './webhook-panel.component.html',
  styleUrls: ['./webhook-panel.component.scss']
})
export class WebhookPanelComponent implements OnInit, OnDestroy {
  constructor(
    private store: StoreService,
    private menuService: MenuService,
    public notifyService: NotifyService,
    private modalService: BsModalService,
    private webhookService: ClientWebhooksService
  ) {
    this.menuService.emit(true);
    this.menuService.emitSidebar(true);
    this.menuService.emitPageTitle('Webhook');
  }

  public isAccountManager = false;
  public webhookList = [];
  public subscriptions = [];
  public tokens: Token[];
  public partners: Partner[] = [];

  ngOnInit() {
    this.getWebhooks();

    this.subscriptions.push(
      this.menuService.getChangeEmmiterBusiness().subscribe(() => {
        this.getWebhooks();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getWebhooks() {
    this.subscriptions.push(
      this.webhookService.list(this.store.business._id).subscribe((data) => {
        this.webhookList = data;
      })
    );
  }

  removeWebhook(wh) {
    const initialState = {
      title: 'Remover Webhook',
      message: 'Você tem certeza que deseja remover este webhook',
      messageEnd: '? O histórico de tentativas será perdido.',
      buttonName: 'Remover'
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.webhookService.delete(wh._id).subscribe(() => {
              this.getWebhooks();
              this.notifyService.success('Webhook excluído com sucesso!');
            })
          );
        }
      })
    );
  }

  editWebhook(wh) {
    const initialState = {
      clientWebhook: wh,
      isNew: false
    };
    this.modalService.show(AddWebhookDialogComponent, {
      class: 'amp-modal modal-lg',
      initialState
    });
  }

  addWebhook() {
    this.modalService.show(AddWebhookDialogComponent, {
      class: 'amp-modal modal-lg'
    });

    this.subscriptions.push(
      this.modalService.onHide.subscribe(() => {
        this.getWebhooks();
      })
    );
  }
}
