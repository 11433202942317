import { ConfirmReferralDialogComponent } from 'app/_dialogs/confirm-referral-dialog/confirm-referral-dialog.component';
import { Subject } from 'rxjs';
import { UtilService } from 'app/_services/util.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnDestroy } from '@angular/core';
import { Template } from 'app/_models/adm/template';
import { NotifyService, CampaignService } from 'app/_services';
import { StorageService } from 'app/_services/storage.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-import-rewards-dialog',
  templateUrl: './import-rewards-dialog.component.html',
  styleUrls: ['./import-rewards-dialog.component.scss']
})
export class ImportRewardsDialogComponent implements OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public utilService: UtilService,
    public notifyService: NotifyService,
    public campaignService: CampaignService,
    private storageService: StorageService
  ) {}

  public templates: Template[] = [];
  public type = '';
  public subtype = '';
  public step = 0;
  public subscriptions = [];
  public uploadProgress = 0;
  public file: string;
  public importName = '';
  public keys = { _id: null, redeemed: null };
  public checkingDataXLS;
  public campaign;
  public selectPersistence: any[] = [];
  public disableNextStep = true;
  public validLines;
  public clicked = false;
  public resultado: Subject<boolean> = new Subject();

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  changeKey(key, value) {
    if (this.keys._id == value) {
      this.keys._id = null;
    } else if (this.keys.redeemed == value) {
      this.keys.redeemed = null;
    }
    this.keys[key] = value;
    if (this.keys.redeemed || this.keys._id) {
      this.disableNextStep = false;
    } else {
      this.disableNextStep = true;
    }
  }

  checkXLSFile() {
    this.subscriptions.push(
      this.utilService.checkXLSFile(this.file, this.keys, { _id: 'ObjectId', redeemed: 'text-bool' }).subscribe(
        (data) => {
          this.validLines = data.valids.length;
        },
        (error) => console.error(error)
      )
    );
  }

  checkValidFile() {
    this.subscriptions.push(
      this.utilService.getFileColumns(this.file).subscribe(
        async (data) => {
          if (data.err === undefined) {
            this.checkingDataXLS = data.fields;
            this.keys = {
              _id: null,
              redeemed: null
            };
            if (this.keys._id || this.keys.redeemed) {
              this.disableNextStep = false;
            }
          } else {
            this.notifyService.error('Houve algum erro ao processar o arquivo!');
          }
        },
        () => {
          this.notifyService.error('Não foi possível processar a requisição.');
        }
      )
    );
  }

  import() {
    this.clicked = true;
    this.openModalToValidate();
  }

  changeFile(evt) {
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.checkAndUploadFile(files[0]);
    }
  }

  checkAndUploadFile(file: File) {
    if (!file.name?.includes('.xlsx') || !file.name?.includes('.xls')) {
      this.notifyService.error('O formato do arquivo precisa ser XLSX!');
      return;
    }
    if (file.size > 20 * 1024 * 1024) {
      this.notifyService.error('O arquivo enviado é muito grande!');
      return;
    }

    this.subscriptions.push(
      this.storageService.getSignedUploadUrl('imports').subscribe((urlData: any) => {
        this.subscriptions.push(
          this.storageService.upload(urlData.upload_url, urlData.key, file).subscribe(
            (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.uploadProgress = Math.round((100 * event.loaded) / event.total);
              } else if (event instanceof HttpResponse) {
                this.file = urlData.url;
                this.importName = file.name;
                this.checkValidFile();
                this.step = 1;
              }
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Houve um erro ao enviar o arquivo.');
              this.uploadProgress = 0;
            }
          )
        );
      })
    );
  }

  openModalToValidate() {
    const initialState = {
      buttonName: 'Validar',
      title: 'Validar Recompensa',
      message: 'Selecione uma unidade para validar esta recompensa'
    };

    const modalRef = this.modalService.show(ConfirmReferralDialogComponent, {
      class: 'amp-modal-lg col-xl-4 col-lg-6 col-md-10 col-sm-12',
      ignoreBackdropClick: true,
      initialState
    });

    const sub = modalRef.content.result.subscribe((data) => {
      sub.unsubscribe();
      if (data) {
        this.subscriptions.push(
          this.campaignService.importRewards(this.file, this.keys, this.campaign, data).subscribe(
            () => {
              this.notifyService.success('Importação realizada com sucesso!');
              this.bsModalRef.hide();
              this.resultado.next(true);
            },
            (error) => {
              console.error(error);
              this.notifyService.error('Houve algum erro ao processar o arquivo!');
            }
          )
        );
      }
    });
  }
}
