import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'ampHighlight'
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: any) {
    if (typeof value != 'string') {
      return value;
    }
    if (!args) {
      return value;
    }
    if (typeof args == 'string') {
      args = args.split(' ');
      args = args.filter((it) => it != '');
      args = args.map((it) => {
        return { value: it };
      });
    }
    args.forEach((element) => {
      const re = new RegExp(element.value, 'gi');
      value = value.replace(re, '<span class="amp-highlighted-word">$&</span>');
    });

    return value;
  }
}
