import { TagService } from 'app/_services';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StoreService } from 'app/_services/store.service';
import { TagEditorDialogComponent } from '../tag-editor-dialog/tag-editor-dialog.component';
import { Tag } from 'app/_models/tag';
@Component({
  selector: 'app-tag-editor-list-dialog',
  templateUrl: './tag-editor-list-dialog.component.html',
  styleUrls: ['./tag-editor-list-dialog.component.scss']
})
export class TagEditorListDialogComponent implements OnInit, OnDestroy {
  @Input() editMode = false;
  _tag: Tag;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input()
  public subscriptions = [];
  public loading = false;
  public searchField: any = '';
  public tags: Tag[] = [];

  set tag(value) {
    this._tag = value;
  }

  get tag() {
    return this._tag;
  }

  private subjectSearch: Subject<string> = new Subject();

  @Output() public customerChange: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef, private tagService: TagService, public store: StoreService, public modalService: BsModalService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.subjectSearch
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged())
        .subscribe(() => this.getTags())
    );
    this.getTags();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
    this.subjectSearch?.complete();
    this.close?.complete();
  }

  getTags() {
    this.subscriptions.push(
      this.tagService.getTags(this.store.empresa, this.searchField).subscribe((res) => {
        this.tags = res;
      })
    );
  }

  onTypeSearchField(value) {
    this.subjectSearch.next(value);
  }

  selectTag(tag) {
    const initialState = { isNew: false, tag };
    const modal: BsModalRef = this.modalService.show(TagEditorDialogComponent, { ignoreBackdropClick: true, initialState, class: 'amp-modal' });

    this.subscriptions.push(
      modal.content.result.subscribe(() => {
        this.getTags();
      })
    );
  }

  newTag() {
    const modal: BsModalRef = this.modalService.show(TagEditorDialogComponent, {
      ignoreBackdropClick: true,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      modal.content.result.subscribe(() => {
        this.close.emit({});
        this.getTags();
      })
    );
  }
}
