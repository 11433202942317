<section class="d-flex flex-column">
  <div class="actions">
    <div class="search">
      <input class="amp-input search" [(ngModel)]="search" placeholder="Buscar">
      @if (search.length) {
      <i class="bi-x-lg" (click)="search = ''"></i>
      } @else {
      <i class="bi-search"></i>
      }
    </div>
    <amp-select placeholder="Filtrar por..." [hiddenSelected]="true" (valueChange)="filterByCategory($event)"
      [options]="options" [(value)]="category"></amp-select>
  </div>

  <div class="partners">
    <div class="pb-15 col-lg-2" [ngClass]="{'col-lg-3': checkWidth()}"
      *ngFor="let partner of partnersList | filterPipe: { field: 'name', value: search }">
      <div class="amp-partner amp-card">
        <div class="amp-partner-image-wrapper" [ngStyle]="{ background: partner.color }">
          <img class="amp-partner-image" draggable="false" [src]="partner.logo" />
        </div>
        <div class="amp-partner-items-wrapper">
          <h5 class="amp-partner-title">{{ partner.name }}</h5>
          <p class="amp-partner-description">{{ partner.description }}</p>

          @if (['api', 'webhook', 'tinyerp', 'totvs_cmnet', 'RDStation_marketing', 'active_campaign', 'piperun',
          'cvcrm', 'pipedrive', 'customerx', 'gupy', 'desbravador'].includes(partner.internal_name)) {
          <button class="amp-btn amp-btn-success" (click)="openPartnerDialog(partner)">Ver Integração</button>
          } @else {
          <button class="amp-btn amp-btn-success" (click)="openDocumentation(partner.internal_name)">Ver
            Documentação</button>
          }
        </div>
      </div>
    </div>
  </div>

  @if (!(partnersList | filterPipe: { field: 'name', value: search }).length) {
  <div class="amp-not-found">NENHUM RESULTADO ENCONTRADO</div>
  }
</section>