<div class="amp-dialog">
  <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  <p class="amp-dialog-title">Endereços IP bloqueados</p>
  <div class="amp-dialog-subtitle">
    Bloqueie ou desbloqueie endereços IP dentro desse Programa de Indicação. Ao bloquear um Endereço IP, o sistema ignora
    qualquer pessoa se cadastrando com o referido endereço e não contabiliza a indicação.
  </div>
  <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
    <div class="amp-select-business-wrapper">
      <div class="amp-select-business">
        <div class="amp-select-business-content">
          <label class="amp-checkbox" *ngFor="let ip of campaign.ip_blacklist">
            <div>
              <input type="checkbox" [checked]="items.includes(ip)" (change)="toggleIp(ip)">
              <span class="text">{{ ip }}</span>
              <span class="checkmark"></span>
            </div>
          </label>
        </div>
       
      </div>
    </div>
    <div class="amp-buttons">
      <button class="amp-btn-danger amp-btn-lg" type="submit" *ngIf="!loading" [disabled]="!form.form.valid">Desbloquear IP's selecionado(s)</button>
      <button class="amp-btn-danger amp-btn-lg w-100" *ngIf="loading" [disabled]="true">Processando<span class="loading-ellipsis"lo>...</span></button>
      <button type="button" class="amp-btn-danger amp-btn-lg amp-block" *ngIf="!loading" (click)="blockNewIp()">Bloquear novo endereço IP</button>
    </div>
  </form>
</div>