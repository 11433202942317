<div class="amp-dialog">
  <p class="amp-dialog-title">Renomear Plataforma</p>
  <div class="amp-dialog-subtitle">
    Escolha um novo nome para a plataforma.
  </div>
  <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
    <div class="amp-field">
      <label class="amp-label-text" for="role">Nome da Plataforma</label>
      <input type="text" class="amp-input" placeholder="Plataforma de Exemplo" [(ngModel)]="platform.name"
        #nome="ngModel" name="nome" required />
    </div>
    <div class="amp-buttons">
      <button class="amp-btn-success amp-btn-lg" type="submit" [disabled]="!form.form.valid">Renomear
        Plataforma</button>
      <button type="button" class="amp-btn-outline-cancel amp-btn-lg" (click)="closeModal()">Cancelar</button>
    </div>
  </form>
</div>