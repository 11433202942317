<div class="amp-dialog">
  <i (click)="bsModalRef.hide()" class="bi-x-lg close-button"></i>
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span>Resumos Recorrentes</span>
    </div>
    <div class="amp-dialog-subtitle">
      <span>Gerencie seus resumos recorrentes criados.</span>
    </div>
  </div>

  <div class="amp-dialog-body">
    <div class="summary" *ngFor="let recurrency of recurrencies">
      <div class="top">{{ recurrency.name ?? 'Resumo Sem Título' }}</div>
      <div class="bottom">
        <div>
          <i class="bi-arrow-counterclockwise"></i>
          Criado por {{ recurrency.user.nome }} em {{ recurrency.created_at | date: 'dd/MM/yy HH:mm' }}
        </div>
        <div>
          <i class="bi-people"></i>
          {{ 'ai-summary-frequency-' + recurrency.frequency | translate }}
        </div>
        <div>
          <i class="bi-buildings"></i>
          {{ 'unidade' | translate : { count: recurrency.businesses.length } }}
        </div>
        <div>
          <i class="bi-chat-left-text"></i>
          {{ 'pesquisa' | translate : { count: recurrency.surveys.length - 1 } }}
        </div>
      </div>

      <div dropdown class="options-button" container="modal-container">
        <i dropdownToggle (click)="false" class="bi-three-dots"></i>
        <div class="dropdown-menu dropdown-menu-right row-actions" *dropdownMenu role="menu"
          aria-labelledby="amp-row-actions">
          <div class="options">
            <span class="option" (click)="editRecurrency(recurrency)">Editar</span>
            <span class="option" (click)="deleteRecurrency(recurrency)">Excluir</span>
          </div>
        </div>
      </div>
    </div>

    <amp-loading *ngIf="isLoading"></amp-loading>
    
    <div *ngIf="!isLoading && !recurrencies.length" class="amp-not-found">NENHUM RESULTADO ENCONTRADO</div>
  </div>
</div>