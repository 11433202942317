<div class="amp-wrapper">
  <div class="amp-dialog">
    <div class="amp-dialog-header">
      <div class="amp-dialog-title">
        {{ title }}
      </div>
      <div class="amp-dialog-subtitle">{{ message }}<span class="amp-dialog-subtitle-strong">{{ strongText }}</span>{{
        messageEnd }}</div>
      <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
    </div>

    <div class="amp-dialog-body">
      <img *ngIf="url.length" [src]="url">
    </div>
  </div>
</div>