import { CustomerService } from 'app/_services/customer.service';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotifyService } from 'app/_services';
@Component({
  selector: 'app-customer-survey-error-dialog',
  templateUrl: './customer-survey-error-dialog.component.html',
  styleUrls: ['./customer-survey-error-dialog.component.scss']
})
export class CustomerSurveyErrorDialogComponent {
  constructor(public bsModalRef: BsModalRef, private notifyService: NotifyService, private customerService: CustomerService) {}

  public content: any = {};

  checkBounce(error) {
    if (error?.toLowerCase().includes('bounce')) {
      return true;
    }

    return false;
  }

  removeBounce() {
    this.customerService.removeBounce(this.content._customer).subscribe({
      next: () => {
        this.notifyService.success('Endereço removido com sucesso!');
      },
      error: (error) => {
        console.error(error);
        this.notifyService.error('Houve um erro ao remover o endereço, tente novamente ou entre em contato com o suporte.');
      }
    });
  }

  checkMessageDelivered(error) {
    const message = `\n1) O dispositivo da pessoa está desligado ou fora da área de serviço por um determinado período de tempo 
    (normalmente 24h, mas para algumas operadoras, como a Vivo, o período é de apenas 8 horas).\n
    2) Número válido, mas inativo (algumas operadoras retornam este tipo de erro apenas neste status de segundo nível).`;

    if (error == 'NOT_DELIVERED') {
      return message;
    } else {
      return error || 'Indisponível.';
    }
  }
}
