<div class="amp-dialog" id="amp-autocomplete">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title" *ngIf="isNew">
      <span class="amp-dialog-icon bi-tag"></span>
      Nova Tag 
    </div>
    <div class="amp-dialog-subtitle" *ngIf="isNew">Preencha os dados abaixo para cadastrar uma nova tag</div>

    <div class="amp-dialog-title" *ngIf="!isNew">
      <span class="amp-dialog-icon bi-tag"></span>
      Editar Tag
    </div>
    <div class="amp-dialog-subtitle" *ngIf="!isNew">Edite os dados da tag abaixo</div>

    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

<form (ngSubmit)="save()"  #f="ngForm">
  <div class="amp-dialog-body">
    <div class="amp-field" id="amp-autocomplete">
      <label class="amp-label-text">Nome da Tag</label>
      <input id="amp-autocomplete" type="text" class="amp-input-lg" [(ngModel)]="tag.name" #name="ngModel" name="name" required placeholder=" Ex.: quarto"/>
    </div>
  </div>

  <div class="amp-dialog-actions">

    <button class="amp-btn-gradient1 amp-btn-lg" type="submit" id="amp-autocomplete" *ngIf="isNew && !loading">Criar Tag</button>
    <button class="amp-btn-gradient1 amp-btn-lg" type="submit" *ngIf="!isNew && !loading">Atualizar Tag</button>

    <button class="amp-btn-outline-secondary amp-btn-lg" *ngIf="!loading" type="button" (click)="bsModalRef.hide()">{{'main.cancelButton' | translate}}</button>

    <button class="amp-btn-link-danger" *ngIf="!isNew && !loading" type="button" (click)="delete()">Excluir Tag</button>


    <button class="amp-btn-gradient1 amp-btn-lg w-100" *ngIf="loading" disabled>Processando<span class="loading-ellipsis">...</span></button>

  </div>
</form>
</div>
