import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { Survey } from '../../_models/customerFeedback/survey';
import { SurveyAnswer } from 'app/_models/customerFeedback/surveyAnswer';
import moment from 'moment';
@Injectable()
export class SurveyAnswerService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getById(_id: string) {
    return this.http.get<SurveyAnswer>(this.config.apiUrl + '/cf/surveyAnswer/' + _id);
  }

  getDetailed(_id: string) {
    return this.http.get<SurveyAnswer>(this.config.apiUrl + '/cf/surveyAnswer/detailed/' + _id);
  }

  open(_id: string) {
    return this.http.get<SurveyAnswer>(this.config.apiUrl + '/cf/surveyAnswer/open/' + _id);
  }

  getFeedbacks(isOldFilter, filters, limit, page, questionView?, selectedCompletion?, importDataId?) {
    const query = new URLSearchParams({
      limit: limit,
      page: page
    });

    if (questionView) {
      filters.question = questionView;
    }

    if (selectedCompletion != null) {
      filters.only_finalized = selectedCompletion;
    }
    if (isOldFilter) {
      return this.http.post<any>(this.config.apiUrl + '/cf/surveyAnswer/getFeedbacksOld/?' + query.toString(), filters);
    }

    if (importDataId) {
      query.append('importDataId', importDataId);
    }
    return this.http.post<any>(this.config.apiUrl + '/cf/surveyAnswer/getFeedbacks/?' + query.toString(), filters);
  }

  deliveryMetrics(filters) {
    return this.http.post<any>(this.config.apiUrl + '/cf/surveyAnswer/delivery-metrics/', filters);
  }

  create(surveyAnswer: any, force: boolean, scheduleType = 'default', scheduleTo = undefined): any {
    const params = new URLSearchParams();
    params.set('scheduleType', scheduleType);
    params.set('scheduleTo', scheduleTo);

    if (force) {
      params.set('force', '1');
    }

    return this.http.post<any>(this.config.apiUrl + '/cf/surveyAnswer/?' + params.toString(), surveyAnswer);
  }

  scheduleWebhooks(businessId, surveyAnswerId) {
    return this.http.post<any>(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/webhook/`, { _business: businessId });
  }

  answer(surveyAnswer: SurveyAnswer, isFinalize) {
    return this.http.put<any>(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswer._id}/answer?isFinalize=${isFinalize}`, surveyAnswer);
  }

  exportResponses(survey: Survey) {
    return this.http.get<any>(this.config.apiUrl + `/cf/surveyAnswer/export-single/${survey._id}`);
  }

  exportMultiSurveyResponses(ids, filterDate, onlyAnswered, filters, _empresa, filter_any, business, only_finalized) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      only_answered: onlyAnswered
    });

    return this.http.post<any>(this.config.apiUrl + `/utils/export/enqueue?` + query, {
      data: { ids: ids, _empresa, filters, filter_any, only_finalized: only_finalized },
      type: 'export_multi_survey',
      business: business
    });
  }

  getAutomationRedirection(_id, automation) {
    return this.http.patch<any>(this.config.apiUrl + `/cf/surveyAnswer/${_id}/automation-redirection`, { automation });
  }

  getOrigins(filters) {
    return this.http.post<any>(this.config.apiUrl + `/cf/surveyAnswer/origins`, filters);
  }

  getMetadatas(filters) {
    return this.http.post<any>(this.config.apiUrl + `/cf/surveyAnswer/metadatas`, filters);
  }

  softDeleteAnswer(id) {
    return this.http.delete<any>(this.config.apiUrl + '/cf/surveyAnswer/' + id);
  }

  hardDeleteRequest(id) {
    return this.http.delete<any>(this.config.apiUrl + '/cf/surveyAnswer/request/' + id);
  }

  deleteAnswerFromTrash(id) {
    return this.http.delete<any>(this.config.apiUrl + '/cf/surveyAnswer/delete-from-trash/' + id);
  }

  restoreAnswerFromTrash(id) {
    return this.http.patch<any>(this.config.apiUrl + '/cf/surveyAnswer/' + id + '/restore-from-trash', {});
  }

  anonymizeAnswers(survey) {
    return this.http.patch<any>(this.config.apiUrl + '/cf/surveyAnswer/' + survey + '/anonymize', {});
  }
  anonymizeAnswer(surveyAnswerId) {
    return this.http.put<any>(this.config.apiUrl + '/cf/surveyAnswer/' + surveyAnswerId + '/anonymize', {});
  }

  unsubscribe(customerId) {
    return this.http.patch<any>(this.config.apiUrl + `/customer/${customerId}/unsubscribe`, {});
  }

  sendToEmails(surveyAnswerId: string, emails: []) {
    return this.http.post(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/send-to-emails`, { emails });
  }

  getNotes(surveyAnswerId: string) {
    return this.http.get(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/note`);
  }

  putNote(surveyAnswerId: string, noteId: string, message: string) {
    return this.http.put(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/note/${noteId}`, { message });
  }

  addNote(surveyAnswerId: string, message: string) {
    return this.http.post(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/note`, { message });
  }

  addNotePublic(surveyAnswerId: string, message: string, email: string) {
    return this.http.post(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/note-public`, { message, email });
  }

  addReply(surveyAnswerId: string, reply: string) {
    return this.http.post(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/reply`, { reply });
  }

  addReplyPublic(surveyAnswerId: string, reply: string, reply_by: string) {
    return this.http.post(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/reply-public`, { reply, reply_by });
  }

  removeNote(surveyAnswerId: string, noteId: string) {
    return this.http.delete(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/note/${noteId}`);
  }

  changeResolved(surveyAnswerId: string, resolved: boolean) {
    return this.http.patch(this.config.apiUrl + `/cf/surveyAnswer/${surveyAnswerId}/resolved`, { resolved });
  }

  getAnswersOptionsOfQuestion(surveys: [], internal_name: string, search = '') {
    return this.http.post(this.config.apiUrl + `/cf/surveyAnswer/answers-options-of-question/`, {
      internal_name,
      surveys,
      filter: search
    });
  }

  getQuestionAnswers(
    surveyId,
    filterDate,
    viewMode = 'aggregated',
    filters?,
    _empresa?,
    filter_any = false,
    selectedCompletion = 'complete',
    internal_name = undefined,
    limit = 100,
    skip = 0,
    filters_version = 1
  ) {
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    // @ts-ignore
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode,
      limit,
      skip
    });

    return this.http.post<any>(this.config.apiUrl + `/cf/surveyAnswer/getQuestionAnswers?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      survey: survey,
      only_finalized: selectedCompletion,
      internal_name: internal_name,
      filters_version: filters_version,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getAITopics(empresa, searchFilter?) {
    return this.http.get<any>(this.config.apiUrl + `/cf/surveyAnswer/ai-topics/${empresa}?search=${searchFilter}`);
  }

  getAIPersons(empresa, searchFilter?) {
    return this.http.get<any>(this.config.apiUrl + `/cf/surveyAnswer/ai-persons/${empresa}?search=${searchFilter}`);
  }
}
