import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener } from '@angular/core';
@Component({
  selector: 'app-terms-of-use-dialog',
  templateUrl: './terms-of-use-dialog.component.html',
  styleUrls: ['./terms-of-use-dialog.component.scss']
})
export class TermsOfUseDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public confirmField = '';
  public resultado: Subject<boolean> = new Subject();
  public isTerms;
  public isPrivacy;

  @HostListener('document:keyup', ['$event'])
  public onKeyUp(event) {
    if (event.code == 'Enter' && this.confirmField == 'CONCORDO') {
      this.confirmar();
    }
  }

  cancelar() {
    this.resultado.next(false);
    this.bsModalRef.hide();
  }

  confirmar() {
    this.confirmField = '';
    this.resultado.next(true);
    this.bsModalRef.hide();
  }
}
