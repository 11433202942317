import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlanService } from 'app/_services/adm/plan.service';
import { StoreService } from 'app/_services/store.service';
import { MenuService } from 'app/_services/menu.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EmpresaService } from 'app/_services';
import { Empresa } from 'app/_models/empresa';
import { Plan } from 'app/_models/adm/plan';
import moment from 'moment';
@Component({
  selector: 'app-admin-empresa-create-dialog',
  templateUrl: './empresa-dialog.component.html',
  styleUrls: ['./empresa-dialog.component.scss']
})
export class EmpresaDialogComponent implements OnInit, OnDestroy {
  public isNew = true;
  public plans: Plan[];
  public subscriptions = [];
  public empresa = new Empresa();
  public minDate = moment().subtract(1, 'd').toDate();
  public maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  constructor(
    public bsModalRef: BsModalRef,
    private menuService: MenuService,
    private planService: PlanService,
    private empresaService: EmpresaService,
    public store: StoreService
  ) {
    this.menuService.emit(true);
  }

  public maskCpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public maskCnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  ngOnInit() {
    this.getPlansList();
    if (this.isNew) {
      this.empresa = {
        //@ts-ignore
        suggested_plan: '',
        contato_email: '',
        contato_nome: '',
        empresa_nome: '',
        referral_enabled: true,
        nps_enabled: true,
        reviews_enabled: true,
        email_enabled: true,
        sms_enabled: true
      };
    } else if (this.empresa.isTrial) {
      this.empresa.trial_expiration_date = moment(this.empresa.trial_expiration_date).toDate();
    }
  }

  validateEmail(evt) {
    evt.srcElement.value = evt.srcElement.value.replace(' ', '');
  }

  maskCpfCnpj() {
    let replaceMask;
    return {
      mask: (value) => {
        replaceMask = value.replace(/\D+/g, '');
        if (replaceMask.length <= 11) {
          return this.maskCpf;
        } else {
          return this.maskCnpj;
        }
      },
      guide: true
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getPlansList() {
    this.subscriptions.push(
      this.planService.get().subscribe((data) => {
        this.plans = data.filter((item) => item.period === 'monthly');
      })
    );
  }

  fmSubmit() {
    if (this.isNew) {
      // @ts-ignore
      this.subscriptions.push(this.empresaService.createWithWizard(this.empresa).subscribe(() => {}));
    } else {
      this.subscriptions.push(this.empresaService.update(this.empresa).subscribe(() => {}));
    }
    this.bsModalRef.hide();
  }
}
