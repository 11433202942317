<input type="search" class="amp-input mb-2" (keyup)="onTypeSearchField($event.target)" name="searchCompany" autofill="false" autocomplete="false"  [(ngModel)]="searchField" #name="ngModel" placeholder="Buscar empresa...">

<div class="amp-list" infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]="false" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
  <div class="amp-list-empty" *ngIf="!companies.length">NENHUM RESULTADO ENCONTRADO</div>
  <div class="amp-list-item" *ngFor="let company of companies" (click)="selectCompany(company)">
    <span class="bi-briefcase"></span>
    <div class="amp-company-list-item">
      <div class="amp-company-list-item-name">{{ company.name }}</div>
      <div class="amp-company-list-item-subtitle">{{ company.document }}</div>
      <div class="amp-company-list-item-subtitle">Criado em {{company.created_at | amLocale:'pt-BR' | amDateFormat: 'DD/MM/YY'}} por {{company._user?.nome.split(' ')[0]}}</div>
    </div>
  </div>
  
  <div class="amp-list-empty" *ngIf="endOfList">Não há mais resultados.</div>

  <div *ngIf="loading" class="loader-demo-box">
    <div class="jumping-dots-loader"><span></span><span></span><span></span></div>
  </div>
</div>

<button type="button" (click)="newCompany()" class="amp-btn-outline-primary amp-btn amp-company-selector-btn">Cadastrar Nova Empresa</button>  