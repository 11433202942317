import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interpolation'
})
export class AmpInterpolation implements PipeTransform {
  regexCustomerName = new RegExp(/{{customerName}}/, 'g');
  regexCustomerEmail = new RegExp(/{{customerEmail}}/, 'g');
  regexFirstName = new RegExp(/{{firstName}}/, 'g');
  regexCompanyName = new RegExp(/{{companyName}}/, 'g');
  transform(target: any, ...data: any[]): any {
    if (data) {
      const customFieldsAnswer = data[0] ?? {};
      const customFieldsCustomer = data[1] ?? {};
      const customerFields = data[2];
      if (target) {
        const regex = new RegExp(/{{customFields.([\s\S]*?)}}/, 'g');
        const items = target.match(regex);
        if (items) {
          for (const item of items) {
            let customField = item.replace('{{customFields.', '');
            customField = customField.replace('}}', '');
            if (data) {
              target = target.replace(item, customFieldsAnswer[customField] ?? customFieldsCustomer[customField] ?? '');
            } else {
              target = target.replace(item, '');
            }
          }
        }

        if (customerFields) {
          target = target.replace(this.regexCustomerName, customerFields.name ?? '');
          target = target.replace(this.regexCustomerEmail, customerFields.email ?? '');
          target = target.replace(this.regexFirstName, (customerFields.name?.includes(' ') ? customerFields.name?.split(' ')[0] : customerFields.name) ?? '');
          target = target.replace(this.regexCompanyName, customerFields._company?.name ?? '');
        }
      }
      return target;
    }
    return target;
  }
}
