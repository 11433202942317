<section id="link" class="row">
    <div class="col">
        <div class="amp-nps-header">
            <img [src]="picture">
        </div>
        <div class="amp-card review__card">
            <div class="amp-card-topline" [ngStyle]="{'background-color': bgColor}">

            </div>

            <div class="amp-card-content animate__animated">
                <p class="amp-customer-name">{{userName}},</p>
                <p class="amp-nps-message-big">
                    {{message1}}
                </p>
                <p class="amp-nps-message-small">
                    {{message2}}
                </p>

                <div class="amp-button-referral-wrapper">
                    <div class="amp-btn-lg mx-auto" [ngStyle]="{'background-color': bgColor,'color': fontColor}">
                        Compartilhar</div>
                </div>
            </div>
        </div>
    </div>
</section>
