<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-plus-lg"></span>
      Novo Template de Campanha
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <form (ngSubmit)="form.form.valid && fmSubmit()" #form="ngForm">
      <div class="row">
        <div class="col-xl-6 mx-auto">
          <p class="amp-guide-text"></p>
          <div class="amp-field">
            <label class="amp-label-text" for="title">Título do Template</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="template.title" autocomplete="off" placeholder="" #title="ngModel" name="title" title required>
          </div>
          <div class="amp-field">
            <label class="amp-label-text" for="description">Descrição do Template</label>
            <input type="text" class="amp-input-lg" [(ngModel)]="template.description" autocomplete="off" placeholder="" #description="ngModel" name="description" description required>
          </div>
          <div class="amp-field-to-icons">
            <div class="amp-field">
              <label class="amp-label-text" for="description">Icone do Template <span class="bi-info amp-field-helper-link" (click)="openIcons()"></span></label>
              <input type="text" class="amp-input-lg amp-input-icon" [(ngModel)]="template.icon" autocomplete="off" placeholder="" #icon="ngModel" name="icon" icon required>
            </div>
            <span [class]="'amp-table-cell-icon ' + template.icon" [ngClass]="{'amp-color-referral': template.type=='referral', 'amp-color-nps': template.type=='nps' }"></span>
          </div>
          <div class="amp-field amp-radio-wrapper" *ngIf="!(isClone || !isNew)">
            <label class="amp-label-text">Tipo de Template</label>
            <label class="amp-radio-button">
              <input type="radio" checked="checked" equired name="templateType" value="nps" [(ngModel)]="template.type">
              <span class="text">NPS</span>
              <span class="checkmark"></span>
            </label>
            <label class="amp-radio-button">
              <input type="radio" required name="templateType" value="referral" [(ngModel)]="template.type">
              <span class="text">Programa de Indicação</span>
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="amp-field amp-select-new" *ngIf="template.type=='referral'">
            <label class="amp-label-text" for="subType">Selecione o Tipo de Campanha</label>
            <select class="amp-input-lg" [(ngModel)]="template.subtype" name="templateSubType" required>
              <option value="" disabled>Selecione um tipo de campanha</option>
              <option value="referral">Referral</option>
              <option value="milestone">Milestone</option>
            </select>
            <span class="bi-chevron-down"></span>
          </div>
        </div>
      </div>
      <div class="amp-dialog-actions amp-dialog-actions-lg">
        <button type="submit" [disabled]="!form.form.valid" class="amp-btn-outline-primary amp-btn-lg">Salvar e Continuar<span class="bi-chevron-right"></span></button>
      </div>
    </form>
  </div>
</div>