import { BlockIPDialogComponent } from '../block-ip-dialog/block-ip-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Campaign } from 'app/_models/referral/campaign';
import { Component, OnDestroy } from '@angular/core';
import { CampaignService } from 'app/_services';
@Component({
  selector: 'app-manage-blocked-ips-dialog',
  templateUrl: './manage-blocked-ips-dialog.component.html',
  styleUrls: ['./manage-blocked-ips-dialog.component.scss']
})
export class ManageBlockedIPsDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService, private campaignService: CampaignService) {}

  public subscriptions = [];
  public campaign: Campaign = new Campaign();
  public items = [];
  public selectedAll = false;
  public loading = false;

  toggleIp(ip: string) {
    let i;
    if ((i = this.items.indexOf(ip)) > -1) {
      this.items.splice(i, 1);
    } else {
      this.items.push(ip);
    }
  }

  fmSubmit() {
    this.loading = true;
    this.campaign.ip_blacklist = this.campaign.ip_blacklist.filter((ip) => !this.items.includes(ip));
    this.subscriptions.push(
      this.campaignService.updateCampaignIpBlacklist(this.campaign._id, this.campaign.ip_blacklist).subscribe(() => {
        this.loading = false;
        this.bsModalRef.hide();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  blockNewIp() {
    this.bsModalRef.hide();

    const initialState = {
      campaign: this.campaign
    };

    this.modalService.show(BlockIPDialogComponent, {
      initialState,
      class: 'amp-modal'
    });
  }
}
