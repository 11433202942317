import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService, BusinessService, EmpresaService, StoreService } from 'app/_services';
@Component({
  selector: 'app-clone-survey-dialog',
  templateUrl: './clone-survey-dialog.component.html',
  styleUrls: ['./clone-survey-dialog.component.scss']
})
export class CloneSurveyDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public empresaService: EmpresaService,
    public store: StoreService,
    public authService: AuthenticationService,
    public businessService: BusinessService
  ) {}

  public selected = [];
  public result: Subject<any> = new Subject();
  public businesses;
  public selectedAll = false;
  public subscriptions = [];
  public empresas = [];
  public selectedEmpresa = undefined;

  ngOnInit() {
    this.businesses = this.store.businesses;

    if (this.authService.isAccountManager()) {
      this.subscriptions.push(
        this.empresaService.getManagedEmpresas().subscribe((data) => {
          this.empresas = data.map((e) => ({
            value: e._id,
            label: e.nome
          }));
        })
      );

      this.subscriptions.push(
        this.businessService.getMyBusiness(this.store.empresa).subscribe((data) => {
          this.businesses = data;
        })
      );
    }
  }

  onChangeEmpresa() {
    this.subscriptions.push(
      this.businessService.getMyBusiness(this.selectedEmpresa).subscribe((data) => {
        this.businesses = data;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  toggleBusiness(item) {
    let i;

    if ((i = this.selected.indexOf(item)) > -1) {
      this.selected.splice(i, 1);
    } else {
      this.selected.push(item);
    }
  }

  selectAll() {
    if (this.selectedAll) {
      this.selected = [];
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
      this.businesses.forEach((e) => this.toggleBusiness(e._id));
    }
  }

  cancelar() {
    this.result.next(null);
    this.bsModalRef.hide();
  }

  confirmar() {
    this.result.next({ businesses: this.selected, empresa: this.selectedEmpresa ?? null });
    this.bsModalRef.hide();
  }
}
