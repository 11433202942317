import { SmartTagService } from 'app/_services/smartTags/smartTags.service';
import { SmartTag } from 'app/_models/smartCluster/smartTags';
import { StoreService } from 'app/_services/store.service';
import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotifyService } from 'app/_services';
@Component({
  selector: 'app-new-tag-dialog',
  templateUrl: './new-tag-dialog.component.html',
  styleUrls: ['./new-tag-dialog.component.scss']
})
export class NewTagDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private store: StoreService, private notifyService: NotifyService, private smartTagService: SmartTagService) {}

  public isNew = true;
  public subscriptions = [];
  public smartTag: SmartTag = new SmartTag();

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  save() {
    if (this.isNew) {
      //@ts-ignore
      this.smartTag._empresa = this.store.empresa;
      this.subscriptions.push(
        this.smartTagService.createSmartTag(this.smartTag).subscribe({
          next: () => {
            this.bsModalRef.hide();
            this.notifyService.success('Tag criada com sucesso!');
          },
          error: (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao criar a tag. Tente novamente ou entre em contato com o suporte.');
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.smartTagService.updateSmartTag(this.smartTag).subscribe({
          next: () => {
            this.bsModalRef.hide();
            this.notifyService.success('Tag renomeada com sucesso!');
          },
          error: (error) => {
            console.error(error);
            this.notifyService.error('Houve um erro ao renomear a tag. Tente novamente ou entre em contato com o suporte.');
          }
        })
      );
    }
  }
}
