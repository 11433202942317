import { CompanyDialogComponent } from 'app/contatos/company-dialog/company-dialog.component';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CompanyService } from 'app/_services/company.service';
import { StoreService } from 'app/_services/store.service';
import { Customer } from 'app/_models/customer';
import { Company } from 'app/_models/company';
import { Subject } from 'rxjs';
@Component({
  selector: 'amp-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit, OnDestroy {
  constructor(public companyService: CompanyService, public store: StoreService, public modalService: BsModalService) {}

  @Input() editMode = false;

  public _customer: Customer;

  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input()
  set customer(value) {
    this._customer = value;
  }

  get customer() {
    return this._customer;
  }

  public companies: Company[] = [];
  private subjectSearch: Subject<string> = new Subject();
  public subscriptions = [];
  public searchField: any = '';

  @Output() public customerChange: EventEmitter<any> = new EventEmitter();

  public loading = false;
  public endOfList = false;

  ngOnInit() {
    this.subscriptions.push(
      this.subjectSearch
        .pipe(debounceTime(300))
        .pipe(distinctUntilChanged())
        .subscribe(() => this.getCompanies())
    );
    this.loadInitial();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadInitial() {
    this.getCompanies();
  }

  onScroll() {
    if (!this.loading && !this.endOfList) {
      this.loading = true;
      this.loadNext();
    }
  }

  loadNext() {
    this.subscriptions.push(
      this.companyService.getCompanies(this.store.empresa, this.searchField, this.companies.length).subscribe(
        (data) => {
          if (!data.length) {
            this.endOfList = true;
          }
          this.companies = this.companies.concat(data);
          this.loading = false;
        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      )
    );
  }

  onTypeSearchField(value) {
    this.subjectSearch.next(value);
  }

  newCompany() {
    const modal: BsModalRef = this.modalService.show(CompanyDialogComponent, { class: 'amp-modal' });

    this.subscriptions.push(
      modal.content.result.subscribe((data) => {
        if (this.editMode) {
          this.close.emit({});
          this.getCompanies();
        } else {
          this.selectCompany(data);
        }
      })
    );
  }

  getCompanies() {
    this.subscriptions.push(
      this.companyService.getCompanies(this.store.empresa, this.searchField).subscribe((data) => {
        this.companies = data;
        this.endOfList = false;
      })
    );
  }

  selectCompany(company: Company) {
    if (this.editMode) {
      const initialState = { isNew: false, company };
      const modal: BsModalRef = this.modalService.show(CompanyDialogComponent, { initialState, class: 'amp-modal' });

      this.subscriptions.push(
        modal.content.result.subscribe(() => {
          this.getCompanies();
        })
      );
    } else {
      this._customer._company = company;
      this.customerChange.emit(this.customer);
      this.close.emit({});
    }
  }
}
