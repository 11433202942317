<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      {{title}}
    </div>
    <div class="amp-dialog-subtitle" [innerHTML]="message"></div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <div class="amp-field amp-select-new">
      <label class="amp-label-text" for="business">Selecionar Unidade</label>
      <select class="amp-input-lg" [(ngModel)]="business"  required>
        <option value="" disabled>Selecione uma unidade</option>
        <option *ngFor="let item of businessesList" [value]="item._id">{{item.nome}}</option>
      </select>
      <span class="bi-chevron-down"></span>
    </div>
    <button class="amp-btn-success amp-btn-lg" [disabled]="business==''" (click)="confirmar()">{{buttonName}}</button>
    <button class="amp-btn-outline-cancel amp-btn-lg" (click)="cancelar()">Cancelar</button>
</div>
