import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class StripeAmpService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  createCheckoutSession(products, taxId, companyName, _business) {
    return this.http.post<any>(`${this.config.apiUrl}/stripe/create-checkout-session`, {
      referralProduct: products.referralProduct,
      cfProduct: products.cfProduct,
      reviewsProduct: products.reviewsProduct,
      _business,
      taxId,
      companyName
    });
  }

  getCustomerPortal(businessId) {
    return this.http.get<any>(`${this.config.apiUrl}/stripe/customer/${businessId}`);
  }

  saveCustomerInfo(businessId, companyName, taxId) {
    return this.http.post<any>(`${this.config.apiUrl}/stripe/customer`, {
      _business: businessId,
      companyName: companyName,
      taxId: taxId
    });
  }
}
