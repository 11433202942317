import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusinessService, NotifyService } from 'app/_services';
import { StoreService } from 'app/_services/store.service';
import { Business } from 'app/_models/business';
@Component({
  selector: 'app-confirm-referral-dialog',
  templateUrl: './confirm-referral-dialog.component.html',
  styleUrls: ['./confirm-referral-dialog.component.scss']
})
export class ConfirmReferralDialogComponent implements OnInit, OnDestroy {
  public title = '';
  public message = '';
  public buttonName = '';
  public result: Subject<any> = new Subject();
  public businessesList: Business[];
  public business = '';
  public type;
  public subscriptions = [];

  constructor(public bsModalRef: BsModalRef, private businessService: BusinessService, private notifyService: NotifyService, private store: StoreService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.businessService.getMyBusiness(this.store.empresa).subscribe(
        (data) => {
          this.businessesList = data;
          this.business = this.store.business._id;
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Falha ao obter a lista de unidades.');
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  cancelar() {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  confirmar() {
    this.result.next(this.business);
    this.bsModalRef.hide();
  }
}
