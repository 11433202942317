<div class="amp-dialog">
    <div class="amp-dialog-title">
        <i class="bi-file-earmark-text"></i>
        <p>Dados Necessários</p>
        <span class="amp-dialog-close bi-x-lg" *ngIf="isAccountManager" (click)="bsModalRef.hide()"></span>
    </div>
    
    <p class="amp-dialog-subtitle">Insira os seguintes dados para prosseguir.</p>

    <div class="amp-dialog-content">
        <div class="amp-field amp-company-name">
            <label class="amp-label-text">Nome Completo / Razão Social</label>
            <input [(ngModel)]="company_name" type="text" class="amp-input" required />
        </div>
        <div class="amp-field amp-tax-id">
            <label class="amp-label-text">CPF / CNPJ</label>
            <input mask="CPF_CNPJ" showMaskTyped="true" [(ngModel)]="tax_id" #cpfCnpj type="text" class="amp-input" required />
        </div>
    </div>

    <div class="amp-dialog-actions">
        <button type="submit" [disabled]="!company_name || !tax_id" (click)="save()" class="amp-btn-success">Prosseguir</button>
    </div>
</div>