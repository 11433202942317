import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'nameInitials'
})
export class NameInitials implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, args?: any) {
    let init;

    if (value != undefined && value != null && value != '') {
      init = value.match(/\b\w/g);

      return ((init.shift() || '') + (init.pop() || '')).toUpperCase();
    }
    return null;
  }
}
