import { AddWebhookDialogComponent } from 'app/_dialogs/add-webhook-dialog/add-webhook-dialog.component';
import { ClientWebhooksService } from 'app/_services/clientWebhooks.service';
import { WebhookEndpoint } from 'app/_models/webhook-endpoint';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from 'app/_services/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NotifyService } from 'app/_services';
@Component({
  selector: 'app-webhook-details',
  templateUrl: './webhook-details.component.html',
  styleUrls: ['./webhook-details.component.scss']
})
export class WebhookDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private webhookService: ClientWebhooksService,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private menuService: MenuService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.menuService.emit(true);
    this.menuService.emitPageTitle('Webhook');
  }

  public webhook: WebhookEndpoint;
  public isSecretHidden = true;
  public webhookAttempts = [];
  public subscriptions = [];
  public showDetails = -1;
  public secret = '';
  public webhookId;

  ngOnInit() {
    this.webhookId = this.route.snapshot.params['id'];
    if (this.webhookId) {
      this.loadData();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadData() {
    this.subscriptions.push(
      this.webhookService.get(this.webhookId).subscribe((data) => {
        this.webhook = data.webhook;
        this.webhookAttempts = data.events;
      })
    );
  }

  getSecret() {
    this.subscriptions.push(
      this.webhookService.revealSecret(this.webhookId).subscribe((data) => {
        this.secret = data.secret;
        this.isSecretHidden = false;
      })
    );
  }

  deleteWebhook() {
    this.subscriptions.push(
      this.webhookService.delete(this.webhookId).subscribe(() => {
        this.router.navigate(['settings']);
        this.notifyService.success('Webhook excluído com sucesso!');
      })
    );
  }

  toggleStatusWebhook() {
    const wh = this.webhook;
    wh.active = !wh.active;
    this.subscriptions.push(
      this.webhookService.update(wh).subscribe(() => {
        if (wh.active) {
          this.notifyService.success('Webhook ativado com sucesso!');
        } else {
          this.notifyService.success('Webhook desativado com sucesso!');
        }
      })
    );
  }

  editWebhook() {
    const initialState = {
      clientWebhook: this.webhook,
      isNew: false
    };

    this.modalService.show(AddWebhookDialogComponent, {
      class: 'amp-modal modal-lg',
      initialState
    });
  }

  getEvents() {
    const events = this.webhook?.enabled_events.toString().replace(/,/g, ', ');
    return events;
  }

  async copyToClipboard(secret) {
    await navigator.clipboard.writeText(secret).then(() => {
      this.notifyService.success('Segredo copiado para a área de transferência!');
    });
  }
}
