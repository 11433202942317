<div class="amp-dialog">
    <div class="amp-dialog-title">
        <p>{{ isNew ? 'Nova' : 'Renomear'}} Tag</p>
    </div>

    <p class="amp-dialog-subtitle">Escolha um {{ !isNew ? 'novo' : '' }} nome para sua nova tag. É importante que ele sintetize o
        assunto ou abrangência das palavras-chave que você vai escolher.</p>

    <div class="amp-dialog-content">
        <div class="amp-field">
            <label class="amp-label-text">
                Nome da Tag
            </label>
            <input type="text" [(ngModel)]="smartTag.name" class="amp-input" placeholder="Ex.: atendimento" required />
        </div>
    </div>

    <div class="amp-dialog-actions">
        <button [disabled]="!smartTag.name" type="submit" (click)="save()" class="amp-btn-success">{{ isNew ? 'Criar' : 'Renomear' }} Tag</button>
        <button (click)="bsModalRef.hide()" class="amp-btn-outline-cancel">Cancelar</button>
    </div>
</div>