<div class="amp-dialog">
  <i (click)="bsModalRef.hide()" class="bi-x-lg amp-close-dialog-button"></i>

  <p class="amp-dialog-title mb-4">Erro de Solicitação</p>

  @if (content.sendings.providerSource == 'ManagedTwilio') {
  <div class="d-flex flex-column">
    <p><b>Erro: </b>{{ content.sendings.errorMessage ?? 'Indisponível' }}</p>
    @if (content.sendings.errorData) {
    <p><b>Mensagem: </b>{{ content.sendings.errorData.message }}</p>
    <p><b>Descrição: </b>{{ content.sendings.errorData.description }}</p>
    }
  </div>
  } @else {
  <p><b>Razão: </b>{{ checkMessageDelivered(content.sendings.errorMessage) }}</p>
  }

  @if (checkBounce(content?.sendings?.errorMessage)) {
  <label class="amp-checkbox">
    <input type="checkbox" #checkbox>
    <span class="text">Confirmo que isto foi um engano e desejo remover este endereço da lista de bounce.</span>
    <span class="checkmark"></span>
  </label>

  <div class="d-flex justify-content-center">
    <button [disabled]="!checkbox.checked" class="amp-btn-primary" (click)="removeBounce()">Remover</button>
  </div>
  }
</div>