<div (click)="input.focus()" class="amp-multi-select amp-input" (clickOutside)="onClickOutside()" [ngStyle]="{'border': borders ? 'solid 1px #e1e4e8' : 'none'}">
    <div class="tag" *ngFor="let tag of value; let i = index">
      {{ modelAsString ? tag : tag[displayBy] }}
      <i *ngIf="allowRemove" (click)="removeTag(tag, i)" class="bi-x-lg"></i>
    </div>

  <input #input (focus)="onFocus()" type="text" id="amp-multi-select" [ngStyle]="{width: value?.length ? 'initial' : '100%'}" autocomplete="off" [placeholder]="!value?.length ? primaryPlaceholder : secondaryPlaceholder" class="amp-input" name="select-filter" [(ngModel)]="inputValue" (keyup)="onKeyUp($event)">

  <div class="dropdown" *ngIf="showDropdown">
    <div class="dropdown-inner" *ngIf="optionsFiltered.length > 0" #dialogOptions>
      <ng-container *ngIf="!optionsLoading">
        <div [ngClass]="{'focused': i == focusedOptionIndex}" (mouseover)="focusedOptionIndex = i" class="option cursor-pointer" *ngFor="let option of optionsFiltered; let i = index" (click)="selectOption($event, option)">
          <span class="amp-value" *ngIf="!modelAsString">{{ option[displayBy] }}</span>
          <span class="amp-value" *ngIf="modelAsString">{{ option }}</span>
        </div>
      </ng-container>
    </div>

    <div class="dropdown-inner amp-not-found" *ngIf="!optionsLoading && !optionsFiltered.length">
      NENHUM RESULTADO ENCONTRADO
    </div>

    <div class="loading" *ngIf="optionsLoading">
      <img class="spinner" src="assets/images/spinner.svg">
    </div>
  </div>
</div>