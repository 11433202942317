<div class="amp-dialog">
  <i (click)="bsModalRef.hide()" class="bi-x-lg close-button"></i>

  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span>{{ isNew ? 'Novo' : 'Editar' }} Resumo</span>
    </div>
    <div class="amp-dialog-subtitle">
      <span>Crie um resumo das respostas de texto recebidas utilizando o <i>Amplifique.me Insights</i>.</span>
    </div>
  </div>

  <div class="amp-dialog-body">
    <div class="left">
      <div class="title">Pesquisas Incluídas</div>
      <div class="survey-picker">
        <div class="header">
          <label class="amp-checkbox">
            <div class="amp-option">
              <input type="checkbox" [(ngModel)]="selectAll" (change)="selectAllSurveysFromAllBusinesses()">
              <span class="amp-option-text">Selecionar Tudo</span>
              <span class="checkmark"></span>
            </div>
          </label>
          <i class="bi-info-circle"></i>
        </div>
        <div class="body">
          <div class="search-bar">
            <input type="search" class="amp-input-lg" placeholder="Buscar" [(ngModel)]="searchValue">
            <i *ngIf="!searchValue.length" class="bi-search"></i>
            <i (click)="searchValue = ''" *ngIf="searchValue.length" class="bi-x-lg"></i>
          </div>
          <div class="businesses">
            <div class="business" *ngFor="let business of businesses">
              <label class="amp-checkbox">
                <div class="amp-option">
                  <input type="checkbox" [checked]="businessesSelected.includes(business._id)" (change)="selectAllSurveysFromBusiness(business)">
                  <span class="amp-option-text"><b>{{ business.nome }}</b></span>
                  <span class="checkmark"></span>
                </div>
              </label>
              <div class="surveys" *ngFor="let survey of business.surveys | filterPipe: { field: 'title', value: searchValue}">
                <label class="amp-checkbox">
                  <div class="amp-option">
                    <input type="checkbox" [checked]="surveysSelected.includes(survey._id)" (change)="selectSurveyFromBusiness(survey)">
                    <span class="amp-option-text">{{ survey.title }}</span>
                    <span class="checkmark"></span>
                  </div>
                </label>
              </div>
            </div>
            <div class="amp-not-found">NENHUM RESULTADO ENCONTRADO</div>
            <amp-loading *ngIf="isLoading"></amp-loading>
          </div>
        </div>
      </div>
      <div *ngIf="surveysSelected.length && aiSummary.type == 'onetime' && answerEstimationStatus == 'loaded'" class="estimated">
        <i class="bi-info-circle-fill" tooltip="Este valor indica quantas respostas de texto no máximo serão analisadas pela AI, uma vez que respostas muito curtas e que não cumprem os requisitos são ignoradas."></i>
        {{ answerEstimation }} respostas estimadas em {{ surveysSelected.length }} pesquisas selecionadas.
      </div>
    </div>
    <div class="right">
      <div class="title mb-0">Configurações de Resumo</div>
      <div class="ampl-btn-group">
        <button [disabled]="!isNew" [ngClass]="{ active: aiSummary.type == 'onetime' }" (click)="aiSummary.type = 'onetime'" class="amp-btn">
          Resumo Avulso
        </button>
        <button [ngClass]="{ active: aiSummary.type == 'recurring' }" (click)="aiSummary.type = 'recurring'" class="amp-btn">
          Resumo Recorrente
        </button>
      </div>

      <div class="range-picker" *ngIf="aiSummary.type == 'onetime'">
        <div class="field">
          <label class="amp-label-text">Data de Início</label>
          <input name="startDate" [(ngModel)]="aiSummary.start_date" [maxDate]="today" bsDatepicker [bsConfig]="{ containerClass: 'theme-amp' }" (keydown)="$event.preventDefault()" (ngModelChange)=" aiSummary.end_date = undefined; onChange();" placeholder="Escolha uma data" class="amp-input-lg">
        </div>
        <div class="field">
          <label class="amp-label-text">Data de Término</label>
          <input name="endDate" [(ngModel)]="aiSummary.end_date" [minDate]="aiSummary.start_date" [maxDate]="today" [disabled]="!aiSummary.start_date" (keydown)="$event.preventDefault()" (ngModelChange)="onChange()" bsDatepicker [bsConfig]="{ containerClass: 'theme-amp' }" placeholder="Escolha uma data" class="amp-input-lg">
        </div>
      </div>

      <div class="field" *ngIf="aiSummary.type == 'recurring'">
        <label class="amp-label-text">Intervalo do Resumo</label>
        <amp-select [(value)]="aiSummary.frequency" [options]="frequencies" placeholder="Selecionar Intervalo"></amp-select>
      </div>

      <div class="field">
        <label class="amp-label-text">Nome do Resumo</label>
        <input name="summaryName" [(ngModel)]="aiSummary.name" placeholder="Ex.: Resumo Unidade Matriz" class="amp-input-lg">
      </div>

      <div class="field">
        <label class="amp-label-text">Enviar por E-mail ao Finalizar</label>
        <input id="amp-switch-status-campaign" [(ngModel)]="sendEmail" type="checkbox" class="amp-switch">
        <label for="amp-switch-status-campaign" class="amp-switch-label"></label>
        <label for="amp-switch-status-campaign" class="amp-switch-status-text" *ngIf="sendEmail">Ativada</label>
        <label for="amp-switch-status-campaign" class="amp-switch-status-text" *ngIf="!sendEmail">Desativada</label>
      </div>

      <div class="field" *ngIf="sendEmail">
        <label class="amp-label-text">E-mails</label>
        <amp-multi-select [(value)]="aiSummary.emails" [addOnBlur]="true" primaryPlaceholder="Digite um e-mail e aperte Enter" (onAdd)="validateEmail($event)" [modelAsString]="true"></amp-multi-select>
      </div>

      <div *ngIf="creditsAvailable != 0" class="estimated">Você tem {{ creditsAvailable }} créditos de AI restantes!</div>
      <div (click)="openChat()" *ngIf="creditsAvailable == 0" class="estimated">Você tem {{ creditsAvailable }} créditos de AI, clique aqui para contratar mais!</div>
      <div *ngIf="answerEstimation > 2000" class="estimated warning">O número máximo de respostas para gerar um resumo é de 2000 respostas. Tente remover algumas pesquisas e/ou reduza o intervalo de dias.</div>
      <div *ngIf="answerEstimation > 0 && answerEstimation < 30" class="estimated warning">Não foi possível gerar um resumo porque as respostas não atendem aos critérios mínimos necessários. Tente adicionar mais pesquisas e/ou aumentar o intervalo de dias.</div>
    </div>
  </div>

  <div class="amp-dialog-footer">
    <div class="disclaimer">
      <i *ngIf="aiSummary.type == 'recurring'">Nos resumos recorrentes, o intervalo sempre respeitará o calendário real e o resumo será gerado no dia seguinte ao fechamento desse intervalo.</i>
    </div>
    <div class="buttons">
      <button (click)="bsModalRef.hide()" class="amp-btn-outline-cancel">Cancelar</button>
      <button [disabled]="isDisabled()" class="amp-btn-success" (click)="createSummary()">
        <div>{{ isNew ? 'Criar' : 'Salvar' }} Resumo</div>
      </button>
    </div>
  </div>
</div>