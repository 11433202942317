import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any) {
    let resp;

    if (value) {
      this.translate.get('roles.' + value).subscribe((res) => {
        resp = res;
      });
      return resp;
    } else {
      return '';
    }
  }
}
