<div class="amp-contact-editor animate__animated animate__faster"
  [ngClass]="{'animate__slideInRight': show, 'animate__slideOutRight': show == false && show != null }">

  <div class="amp-header">
    <span>Editar Contato</span>
    <i class="bi-x-lg" (click)="onClose()"></i>
  </div>

  <ng-template [ngIf]="!isLoading">
    <div class="amp-body">
      <div class="amp-contact">
        <div class="amp-initials">
          <span>{{ customer?.name | nameInitials }}</span>
        </div>
        <div class="amp-data">
          <span class="amp-name">{{customer?.name}}</span>
          <span class="amp-created-at">Criado em {{ customer?.created_at | amLocale:'pt-BR' | amDateFormat:
            'DD/MM/YYYY'}}</span>
        </div>
      </div>

      <div class="amp-contact-info">
        <div class="amp-title">
          <span>Informações do Contato</span>
        </div>
        <div class="amp-field">
          <span class="amp-text">Nome:&nbsp;</span>
          <span class="amp-content" (click)="editName = true">
            <span class="amp-text" *ngIf="!editName">{{customer?.name}}</span>
            <i class="bi-pencil" *ngIf="!editName" (click)="editName = true"></i>
          </span>
          <input class="amp-input" *ngIf="editName" autofocus (keyUp.enter)="editName = false" (blur)="editName = false"
            [(ngModel)]="customer.name" #name="ngModel">
        </div>
        <div class="amp-field">
          <span class="amp-text">E-mail:&nbsp;</span>
          <span class="amp-content" (click)="editMail = true">
            <span class="amp-text" *ngIf="!editMail">{{customer?.email}}</span>
            <i class="bi-pencil" *ngIf="!editMail" (click)="editMail = true"></i>
          </span>
          <input class="amp-input" *ngIf="editMail" autofocus (keyUp.enter)="editMail = false"
            (keyup)="removeSpaces($event)" (blur)="editMail = false" [(ngModel)]="customer.email" #mail="ngModel">
        </div>
        <div class="amp-field">
          <span class="amp-text">Telefone:&nbsp;</span>
          <span class="amp-content" (click)="editTel = true">
            <span *ngIf="!editTel && customer.tel"
              [class]="'phone-flag iti__flag iti__' + (customer.tel | phone:true)"></span>
            <span class="amp-text" *ngIf="!editTel">{{customer?.tel}}</span>
            <i class="bi-pencil" *ngIf="!editTel" (click)="editTel = true"></i>
          </span>
          <amp-phone-input *ngIf="editTel" [(phone)]="customer.tel" (finalize)="editTel = false"></amp-phone-input>
        </div>

        <div class="amp-field">
          <span class="amp-text mr-1">Empresa:</span>
          <span class="amp-content" (click)="editCompany = true">
            <span class="amp-text" *ngIf="!editCompany" (click)="editCompany = true; toggleCompanySelector()">{{ getCompanyName(customer._company) }}</span>
            <i class="bi-pencil" *ngIf="!editCompany" (click)="editCompany = true"></i>
          </span>

          <amp-select *ngIf="editCompany" [(value)]="customer._company" [saveBothFields]="true" (onBlur)="editCompany = false" (valueChange)="editCompany = false" placeholder="Selecione" [actions]="actions" (actionsClick)="onActionClick($event)"
          [optionsObservable]="requestCompaniesAutoComplete" [isSearchable]="true" [options]="companies"></amp-select>
        </div>
      </div>

      <div *ngIf="store.custom_fields.length" class="amp-custom-fields">
        <div class="amp-title">
          <span>Campos Customizados</span>
        </div>
        <div class="amp-field" *ngFor="let customField of store.custom_fields; let i = index">
          <span class="amp-text">{{customField.label}}:&nbsp;</span>
          <span class="amp-content" *ngIf="editCustomFieldIndex != i" (click)="editCustomFieldIndex = i">
            <span class="amp-text">{{customer.custom_fields ? customer.custom_fields[customField.internal_name] :
              ''}}</span>
            <i class="bi-pencil"></i>
          </span>
          <div *ngIf="editCustomFieldIndex == i">
            <input type="text" class="amp-input" *ngIf="customField.type == 'text'" autofocus
              (blur)="editCustomFieldIndex = -1" (keyUp.enter)="editCustomFieldIndex = -1;"
              [(ngModel)]="customer.custom_fields[customField.internal_name]">
            <input type="text" class="amp-input" mask="00/00/0000" *ngIf=" customField.type == 'date'" autofocus
              (blur)="editCustomFieldIndex = -1" (keyUp.enter)="editCustomFieldIndex = -1;"
              [(ngModel)]="customer.custom_fields[customField.internal_name]">
            <input type="text" class="amp-input" mask="000.000.000-00" validateCpf *ngIf="customField.type == 'cpf'"
              autofocus (blur)="editCustomFieldIndex = -1" (keyUp.enter)="editCustomFieldIndex = -1;"
              [(ngModel)]="customer.custom_fields[customField.internal_name]">
            <input type="number" class="amp-input" *ngIf="customField.type == 'number'" autofocus
              (blur)="editCustomFieldIndex = -1" (keyUp.enter)="editCustomFieldIndex = -1;"
              [(ngModel)]="customer.custom_fields[customField.internal_name]">
            <input type="text" class="amp-input" mask="00.000.000/0000-00" *ngIf="customField.type == 'cnpj'" autofocus
              (blur)="editCustomFieldIndex = -1" (keyUp.enter)="editCustomFieldIndex = -1;"
              [(ngModel)]="customer.custom_fields[customField.internal_name]">
            <select class="amp-input" *ngIf="customField.type == 'select'" autofocus (blur)="editCustomFieldIndex = -1"
              (keyUp.enter)="editCustomFieldIndex = -1" [(ngModel)]="customer.custom_fields[customField.internal_name]">
              <option *ngFor="let item of customField.options">{{item}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="amp-footer">
      <button class="amp-btn-lg amp-btn-success" (click)="save()">Salvar Alterações</button>
    </div>
  </ng-template>

  <ng-template [ngIf]="isLoading">
    <div class="amp-body">
      <amp-loading></amp-loading>
    </div>
  </ng-template>
</div>