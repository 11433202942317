import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';
@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './tutorial-dialog.component.html',
  styleUrls: ['./tutorial-dialog.component.scss']
})
export class TutorialDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public title = '';
  public message = '';
  public strongText = '';
  public messageEnd = '';
  public buttonText = 'Fechar';
  public buttonType = 'success';
  public url = '';
  public resultado: Subject<boolean> = new Subject();

  cancelar() {
    this.resultado.next(false);
    this.bsModalRef.hide();
  }

  confirmar() {
    this.resultado.next(true);
    this.bsModalRef.hide();
  }
}
