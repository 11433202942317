import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  public title: string;
  public subtitle: string;
  public values: any[];
}
