<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div *ngIf="!isBlocked" class="amp-dialog-title">
      <span class="amp-dialog-icon bi-person-x"></span>
      Bloquear Endereço IP
    </div>
    <div *ngIf="isBlocked" class="amp-dialog-title">
      <span class="amp-dialog-icon bi-person-x"></span>
      Desbloquear Endereço IP
    </div>
    <div *ngIf="!isBlocked" class="amp-dialog-subtitle">
      Ao bloquear um Endereço IP, o sistema ignora qualquer pessoa se cadastrando com o referido endereço e não contabiliza a indicação.
    </div>
    <div *ngIf="isBlocked" class="amp-dialog-subtitle">
      Ao desbloquear um Endereço IP, o sistema volta a aceitar qualquer pessoa se cadastrando com o referido endereço.
    </div>
  </div>

  <div class="amp-buttons">
    <button *ngIf="!isBlocked && !loading" (click)="changeStatus(true)" class="amp-btn-lg amp-btn-danger">Bloquear Endereço IP</button>
    <button class="amp-btn-danger amp-btn-lg w-100" *ngIf="loading" [disabled]="true">Processando<span class="loading-ellipsis"lo>...</span></button>
    <button *ngIf="isBlocked && !loading" (click)="changeStatus(false)" class="amp-btn-lg amp-btn-primary">Desbloquear Endereço IP</button>
    <button *ngIf="!loading" (click)="this.bsModalRef.hide()" class="amp-btn-lg amp-btn-outline-secondary">Cancelar</button>
  </div>
</div>
