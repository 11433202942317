import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UploadFileDialogComponent } from '../upload-file-dialog/upload-file-dialog.component';
import { HttpClient } from '@angular/common/http';
import { NotifyService, StoreService } from 'app/_services';
@Component({
  selector: 'app-select-importer-dialog',
  templateUrl: './select-importer-dialog.component.html',
  styleUrls: ['./select-importer-dialog.component.scss']
})
export class SelectImportDialogComponent {
  constructor(
    private notifyService: NotifyService,
    public store: StoreService,
    public http: HttpClient,
    public bsModalRef: BsModalRef,
    public router: Router,
    public modalService: BsModalService
  ) {}

  public step = 1;

  cancelar() {
    this.bsModalRef.hide();
  }

  sicrediImport() {
    this.step = 2;
  }

  sicrediUploadFile(pattern) {
    const initialState = {
      fileType: 'importSicredi'
    };

    const BsModalRef = this.modalService.show(UploadFileDialogComponent, {
      class: 'modal-lg amp-modal',
      initialState
    });

    BsModalRef.content.result.subscribe((data) => {
      if (data) {
        const body = {
          url: data,
          userId: this.store.userData._id,
          business: this.store.business._id,
          empresaId: this.store.empresaData._id,
          pattern
        };

        this.http
          .post<any>(`https://integration.services.amplifique.me/sicredi/cf/schedule-import`, body, {
            headers: {
              Authorization: `${localStorage.getItem('access_token')}`
            }
          })
          .subscribe(
            () => {
              this.router.navigate(['/imports']);
            },
            (error) => {
              console.error(error);
            }
          );

        this.bsModalRef.hide();
      } else {
        this.notifyService.warning('O arquivo enviado é inválido!');
        this.bsModalRef.hide();
      }
    });
  }

  defaultImport() {
    this.router.navigate(['/importer/contacts']);
    this.bsModalRef.hide();
  }
}
