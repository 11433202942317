import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services';
import { MenuService } from 'app/_services/menu.service';
@Component({
  selector: 'app-image-preview-dialog',
  templateUrl: './image-preview-dialog.component.html',
  styleUrls: ['./image-preview-dialog.component.scss']
})
export class ImagePreviewDialogComponent {
  constructor(public bsModalRef: BsModalRef, public menuService: MenuService, public store: StoreService) {}

  public url;
}
