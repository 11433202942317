<div class="amp-mail-builder-wrapper">
  <div class="row" *ngIf="!showMarkdown">
    <div class="loader"></div>
  </div>
  <div class="amp-sidebar-editor animate__animated animate__faster animate__slideInLeft" *ngIf="showMarkdown">
    <form #form="ngForm" (ngSubmit)="form.form.valid && validateFields()">
      <div class="amp-sidebar-section">
        <p class="amp-now-editing">
          Você está editando o e-mail: <b>{{ mailName }}</b>
        </p>

        <p class="amp-card-title">Dados do e-mail</p>

        <div class="amp-field">
          <label class="amp-label-text" for="title">Nome do remetente</label>
          <input type="text" minlength="1" placeholder="Nome do remetente" class="amp-input" name="fromName" [(ngModel)]="template.from_name" required />
        </div>
        <div class="amp-field">
          <label class="amp-label-text" for="title" >E-mail do "Responder Para" (reply-to) <i  tooltip="É usado para indicar um endereço de e-mail para onde as respostas ao e-mail enviado devem ser encaminhadas. Isso pode ser útil no caso de um contato responder o e-mail." class="bi-info-circle-fill"></i></label>
          <input type="text" (keyup)="removeSpaces($event)" minlength="1" placeholder="E-mail Reply-to" class="amp-input" name="replyTo" [(ngModel)]="template.reply_to" required />
        </div>
        <div class="amp-field">
          <label class="amp-label-text" for="title">Assunto do e-mail</label>
          <input type="text" minlength="1" placeholder="Assunto do e-mail" class="amp-input" name="subject" [(ngModel)]="template.subject" required />
        </div>
        <div class="amp-field mb-4">
          <input
            id="amp-switch-template-logo"
            [(ngModel)]="template.show_logo"
            name="templateLogo"
            #templateLogo="ngModel"
            required
            type="checkbox"
            class="amp-switch"
          />
          <label for="amp-switch-template-logo" class="amp-switch-label"></label>
          <label for="amp-switch-template-logo" class="amp-switch-status-text">Mostrar Logo</label>
        </div>

        <div *ngIf="template.show_logo">
          <label class="amp-label-text">Tamanho da Logo</label>
          <div class="ampl-btn-group">
            <button [ngClass]="{ active: template.logo_size == 'S' }" (click)="template.logo_size = 'S'" class="amp-btn">Pequeno</button>
            <button [ngClass]="{ active: template.logo_size == 'M' }" (click)="template.logo_size = 'M'" class="amp-btn">Médio</button>
            <button [ngClass]="{ active: template.logo_size == 'L' }" (click)="template.logo_size = 'L'" class="amp-btn">Grande</button>
          </div>
        </div>
        <a class="amp-btn-link p-0 mt-4" *ngIf="!template.cover_url" (click)="uploadCover()">Adicionar Imagem de Cabeçalho</a>
        <a class="amp-btn-link-danger p-0 mt-4" *ngIf="template.cover_url" (click)="template.cover_url = null">Remover Imagem de Cabeçalho</a>
      </div>

      <hr class="amp-hr" />
      <div class="amp-sidebar-section">
        <p class="amp-card-title">Corpo do E-mail</p>
        <label class="amp-label-text" for="title">O editor abaixo utiliza Markdown para formatar os textos.</label>
        <div class="amp-field">
          <amp-markdown-editor name="markdown1" required [(markdown)]="template.body" name="body" *ngIf="showMarkdown"> </amp-markdown-editor>
        </div>
      </div>
      <hr class="amp-hr" />
      <div class="amp-sidebar-section">
        <p class="amp-card-title">Estilo do Botão (Call to Action)</p>

        <div
          *ngIf="
            template.type == 'survey' && surveyQuestion
              ? surveyQuestion.type == 'nps' || surveyQuestion.type == 'ces' || surveyQuestion.type == 'csat' || surveyQuestion.type == 'thumbs'
              : false
          "
          class="amp-field mb-4"
        >
          <input
            id="amp-switch-scale"
            (change)="setDefaultColors()"
            [(ngModel)]="template.show_scale"
            name="scaleToggle"
            #scaleToggle="ngModel"
            required
            type="checkbox"
            class="amp-switch"
          />
          <label for="amp-switch-scale" class="amp-switch-label"></label>
          <label for="amp-switch-scale" class="amp-switch-status-text">Mostrar métrica principal</label>
        </div>
        
        <div *ngIf="(template.show_scale && !surveyQuestion.colorful || !template.show_scale)" class="amp-color-selector-input">
          <label class="amp-label-text">Cor Primária</label>
          <div class="amp-color-selector-wrapper">
            <div class="amp-color-selector-name" id="colorPicker" (click)="togglePickerPrimaryColor()">
              {{ template.button_color }}
            </div>
            <div class="amp-color-selector" id="colorPicker" (click)="togglePickerPrimaryColor()" [ngStyle]="{ background: template.button_color }"></div>

            <div class="amp-colorpicker" *ngIf="primaryColorSelector" clickOutside (clickOutside)="togglePickerPrimaryColor()">
              <color-block [color]="template.button_color" [colors]="colors" width="170" (onChangeComplete)="changePrimaryColor($event)"> </color-block>
            </div>
          </div>
        </div>

        <div *ngIf="(template.show_scale && !surveyQuestion.colorful || !template.show_scale)" class="amp-color-selector-input">
          <label class="amp-label-text">Cor do Texto do Botão</label>
          <div class="amp-color-selector-wrapper">
            <div class="amp-color-selector-name" id="colorPicker" (click)="togglePickerButtonTextColor()">
              {{ template.button_text_color }}
            </div>
            <div
              class="amp-color-selector"
              id="colorPicker"
              (click)="togglePickerButtonTextColor()"
              [ngStyle]="{ background: template.button_text_color }"
            ></div>

            <div class="amp-colorpicker" *ngIf="textColorSelector">
              <color-block [color]="template.button_text_color" [colors]="colorsText" width="170" (onChangeComplete)="changeTextColor($event)"> </color-block>
            </div>
          </div>
        </div>

        <br *ngIf="!surveyQuestion?.colorful" />

        <div *ngIf="!template.show_scale" class="amp-field">
          <label class="amp-label-text" for="title">Texto do Botão</label>
          <input
            type="text"
            required
            placeholder="Texto do Botão"
            minlength="1"
            maxlength="30"
            class="amp-input"
            name="buttonText"
            [(ngModel)]="template.button_text"
          />
        </div>

        <div class="amp-field" *ngIf="enableCustomLink">
          <label class="amp-label-text" *ngIf="!showLinkEditor" for="title"
            >Por padrão, o botão leva o usuário até a página do Programa de Indicação, mas você pode customizar este link abaixo</label
          >
          <a class="amp-btn-link p-0" *ngIf="!showLinkEditor" (click)="showLinkEditor = !showLinkEditor">Customizar link do botão</a>
          <label class="amp-label-text" *ngIf="showLinkEditor" for="title">Link customizado do botão</label>
          <input
            type="url"
            *ngIf="showLinkEditor"
            minlength="1"
            pattern="https?://.+"
            name="customButtonLink"
            placeholder="Link do Botão"
            class="amp-input"
            [(ngModel)]="template.button_url"
            required
          />
          <a class="amp-btn-link p-0" *ngIf="showLinkEditor" (click)="showLinkEditor = !showLinkEditor; template.button_url = ''">Voltar a usar link padrão</a>
        </div>
      </div>
      <hr class="amp-hr" />
      <div class="amp-sidebar-section">
        <p class="amp-card-title">Texto do Rodapé</p>
        <div class="amp-field m-0">
          <textarea type="text" [(ngModel)]="template.footer" name="footer" placeholder="Texto do Rodapé" rows="4" class="amp-input"></textarea>
        </div>
      </div>
    </form>
  </div>

  <div class="amp-sidebar-offset"></div>

  <div class="amp-email-preview-wrapper" *ngIf="showMarkdown">
    <div class="amp-email-pre-header">
      <span class="amp-email-pre-header-info"
        >De: <strong>{{ template.from_name == '' ? 'Remetente' : template.from_name }}</strong></span
      >
    </div>
    <div class="amp-email-pre-header">
      <span class="amp-email-pre-header-info"
        >Assunto: <strong>{{ template.subject == '' ? 'Assunto' : template.subject }}</strong></span
      >
    </div>

    <div class="amp-nps-header" *ngIf="template.show_logo && template.type == 'survey'">
      <img *ngIf="template.logo_size == 'S'" class="logo-small" [src]="surveyLogo" />
      <img *ngIf="template.logo_size == 'M'" class="logo-medium" [src]="surveyLogo" />
      <img *ngIf="template.logo_size == 'L'" class="logo-large" [src]="surveyLogo" />
    </div>
    <div class="amp-nps-header" *ngIf="template.show_logo && template.type != 'survey'">
      <img *ngIf="template.logo_size == 'S'" class="logo-small" [src]="storeService.business.picture" />
      <img *ngIf="template.logo_size == 'M'" class="logo-medium" [src]="storeService.business.picture" />
      <img *ngIf="template.logo_size == 'L'" class="logo-large" [src]="storeService.business.picture" />
    </div>

    <div class="amp-card amp-email-preview">
      <img class="amp-email-cover" *ngIf="template.cover_url" [src]="template.cover_url" />
      <showdown class="amp-markdown-email" [value]="template.body"></showdown>
      <br />

      <div class="amp-wrapper-scale" *ngIf="surveyQuestion">
        <div class="amp-scale" [ngClass]="{'am-scale-csat-custom': surveyQuestion.type == 'csat' && surveyQuestion.customScale, 'amp-scale-csat': surveyQuestion.type == 'csat' && !surveyQuestion.customScale, 'amp-scale-ces': surveyQuestion.type == 'ces'}">
      <div *ngIf="template.show_scale && surveyQuestion.type != 'thumbs' && !surveyQuestion.customScale" class="amp-scale-top">
        <div>{{ surveyQuestion.unlikely_text }}</div>
      </div>
      <div *ngIf="template.show_scale && surveyQuestion?.type != 'thumbs' && !surveyQuestion.customScale && template.type == 'survey'" class="amp-email-scale">
        <div
          *ngIf="surveyQuestion.type == 'nps'"
          [ngClass]="{'amp-color-nps-0': surveyQuestion.colorful && surveyQuestion.type == 'nps'}"
          [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }"
        >
          0
        </div>
        <div [ngClass]="{'amp-color-nps-1': surveyQuestion.colorful && surveyQuestion.type == 'nps', 'amp-color-csat-1': surveyQuestion.colorful && surveyQuestion.type == 'csat', 'amp-color-ces-1': surveyQuestion.colorful && surveyQuestion.type == 'ces'}" [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }">1</div>
        <div [ngClass]="{'amp-color-nps-2': surveyQuestion.colorful && surveyQuestion.type == 'nps', 'amp-color-csat-2': surveyQuestion.colorful && surveyQuestion.type == 'csat', 'amp-color-ces-2': surveyQuestion.colorful && surveyQuestion.type == 'ces'}" [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }">2</div>
        <div [ngClass]="{'amp-color-nps-3': surveyQuestion.colorful && surveyQuestion.type == 'nps', 'amp-color-csat-3': surveyQuestion.colorful && surveyQuestion.type == 'csat', 'amp-color-ces-3': surveyQuestion.colorful && surveyQuestion.type == 'ces'}" [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }">3</div>
        <div [ngClass]="{'amp-color-nps-4': surveyQuestion.colorful && surveyQuestion.type == 'nps', 'amp-color-csat-4': surveyQuestion.colorful && surveyQuestion.type == 'csat', 'amp-color-ces-4': surveyQuestion.colorful && surveyQuestion.type == 'ces'}" [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }">4</div>
        <div [ngClass]="{'amp-color-nps-5': surveyQuestion.colorful && surveyQuestion.type == 'nps', 'amp-color-csat-5': surveyQuestion.colorful && surveyQuestion.type == 'csat', 'amp-color-ces-5': surveyQuestion.colorful && surveyQuestion.type == 'ces'}" [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }">5</div>
        <div
          *ngIf="surveyQuestion.type == 'nps' || surveyQuestion.type == 'ces'"
          [ngClass]="{'amp-color-nps-6': surveyQuestion.colorful && surveyQuestion.type == 'nps', 'amp-color-ces-6': surveyQuestion.colorful && surveyQuestion.type == 'ces'}"
          [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }"
        >
          6
        </div>
        <div
          *ngIf="surveyQuestion.type == 'nps' || surveyQuestion.type == 'ces'"
          [ngClass]="{'amp-color-nps-7': surveyQuestion.colorful && surveyQuestion.type == 'nps', 'amp-color-ces-7': surveyQuestion.colorful && surveyQuestion.type == 'ces'}"
          [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }"
        >
          7
        </div>
        <div
          *ngIf="surveyQuestion.type == 'nps'"
          [ngClass]="{'amp-color-nps-8': surveyQuestion.colorful && surveyQuestion.type == 'nps'}"
          [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }"
        >
          8
        </div>
        <div
          *ngIf="surveyQuestion.type == 'nps'"
          [ngClass]="{'amp-color-nps-9': surveyQuestion.colorful && surveyQuestion.type == 'nps'}"
          [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }"
        >
          9
        </div>
        <div
          *ngIf="surveyQuestion.type == 'nps'"
          [ngClass]="{'amp-color-nps-10': surveyQuestion.colorful && surveyQuestion.type == 'nps'}"
          [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }"
        >
          10
        </div>
      </div>

      <div *ngIf="template.show_scale && surveyQuestion?.type == 'csat' && surveyQuestion.customScale && surveyQuestion.scaleTheme == 'emotes'" class="amp-custom-scale">
        <img src="https://cdn.amplifique.me/email_assets/emote-1-filled.svg" />
        <img src="https://cdn.amplifique.me/email_assets/emote-2-filled.svg" />
        <img src="https://cdn.amplifique.me/email_assets/emote-3-filled.svg" />
        <img src="https://cdn.amplifique.me/email_assets/emote-4-filled.svg" />
        <img src="https://cdn.amplifique.me/email_assets/emote-5-filled.svg" />
      </div>

      <div *ngIf="template.show_scale && surveyQuestion?.type == 'csat' && surveyQuestion.customScale && surveyQuestion.scaleTheme == 'hearts'" class="amp-custom-scale">
        <img src="https://cdn.amplifique.me/email_assets/heart-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/heart-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/heart-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/heart-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/heart-filled.png" />
      </div>

      <div *ngIf="template.show_scale && surveyQuestion?.type == 'csat' && surveyQuestion.customScale && surveyQuestion.scaleTheme == 'stars'" class="amp-custom-scale">
        <img src="https://cdn.amplifique.me/email_assets/star-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/star-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/star-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/star-filled.png" />
        <img src="https://cdn.amplifique.me/email_assets/star-filled.png" />
      </div>

      <div *ngIf="template.show_scale && surveyQuestion?.type == 'thumbs'" class="amp-email-scale amp-thumbs">
        <div class="amp-icons">
          <img src="https://cdn.amplifique.me/email_assets/thumbs_up_active.png" />
          <img src="https://cdn.amplifique.me/email_assets/thumbs_down_active.png" />
        </div>
      </div>

      <div *ngIf="template.show_scale && surveyQuestion.type != 'thumbs' && !surveyQuestion.customScale" class="amp-scale-bottom">
        <div>{{ surveyQuestion.likely_text }}</div>
      </div>
    </div>

    </div>
      <br />
      <div *ngIf="!template.show_scale" class="amp-email-btn-wrapper text-center">
        <a
          class="amp-btn-lg amp-btn-cta-email"
          target="_blank"
          rel="noopener noreferrer"
          [href]="template.button_url"
          [ngStyle]="{ 'background-color': template.button_color, 'border-color': template.button_color, color: template.button_text_color }"
          >{{ template.button_text }}</a
        >
      </div>
    </div>
    <showdown class="amp-markdown-email amp-email-footer" [value]="template.footer"></showdown>
  </div>
</div>
