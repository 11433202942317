<div class="amp-dialog">
  <div class="header">
    <div class="title" *ngIf="isNew">
      Novo Fluxo de Fechamento de Loop
    </div>
    <div class="title" *ngIf="!isNew">
      Editar Fluxo de Fechamento de Loop
    </div>
    <div class="subtitle">Configure as informações do novo Fluxo abaixo. Para entender melhor como funciona,
      <a href="https://www.youtube.com/watch?v=fdnTZBhW82M" target="_blank">clique aqui.</a>
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="body" *ngIf="!isLoading">
    <div class="tabs">
      <div class="tab" (click)="tab = 0" [ngClass]="{'tab-active': tab == 0}">Configuração do Fluxo</div>
      <div class="tab" (click)="tab = 1" [ngClass]="{'tab-active': tab == 1}">Fases</div>
    </div>

    <div class="infos" *ngIf="tab == 0">
      <div class="title">Configurações do Fluxo</div>
      <div class="content">
        <div class="left">
          <div class="field">
            <label>Nome do Fluxo</label>
            <input class="amp-input-lg" placeholder="Ex.: Detratores Pesquisa NPS" [(ngModel)]="pipeline.name">
          </div>
          <div class="field">
            <label>Usuários com acesso ao Fluxo</label>
            <amp-multi-select [modelAsString]="false" [(value)]="pipeline._users" [showOptionsOnClick]="true"
              [onlyFromAutoComplete]="true" [addOnEnter]="false" primaryPlaceholder="Adicione um usuário"
              secondaryPlaceholder="Adicione um usuário"
              [optionsObservable]="requestUsersAutoComplete"></amp-multi-select>
          </div>
          <div class="field">
            <label>Responsável Padrão</label>
            <amp-select [options]="pipeline._users" [isSearchable]="true"
              [placeholder]="'Selecione o responsável padrão'" [(value)]="pipeline.default_assigned_to"></amp-select>
          </div>
        </div>
        <div class="right">
          <div class="field">
            <label>Nome do Remetente do E-mail</label>
            <input class="amp-input-lg" placeholder="Ex.: Nome da Empresa" [(ngModel)]="pipeline.email_from_name">
          </div>
          <div class="field">
            <label>Padrão de dias para o vencimento dos Tickets</label>
            <input type="number" class="amp-input-lg" [(ngModel)]="pipeline.default_expiration">
          </div>
        </div>
      </div>
    </div>

    <div class="step-details" *ngIf="tab == 1">
      <div class="step-list">
        <div class="title">Fases</div>
        <div class="sections" cdkDropListGroup>
          <div class="section">
            <div class="title">
              A Fazer
              <i class="bi-plus-lg" (click)="addStep(0)"></i>
            </div>
            <div class="wrapper" [ngClass]="{'empty-dropzone': steps.pending.length == 0}" cdkDropList [id]="'pending'"
              [cdkDropListData]="steps.pending" (cdkDropListDropped)="updateArrays($event)">
              <div class="step pending" cdkDrag *ngFor="let item of steps.pending; let i = index"
                (click)="selectStep(item, i, 'pending', steps.pending)"
                [ngClass]="{'step-pending-selected': currentStepEdit == 'pending' + i}">
                <i class="bi-list"></i>
                <!-- <span *ngIf="currentStepEdit != 'pending' + i" (click)="focusInput('pending', i)">{{item.name}}</span> -->
                <span>{{ item.name }}</span>
                <!-- <input [class]="'amp-input ' + 'pending-' + i" [(ngModel)]="item.name" type="text" *ngIf="currentStepEdit == 'pending' + i" (blur)="currentStepEdit = undefined" (keyUp.enter)="currentStepEdit = undefined"> -->
                <i class="bi-trash3" (click)="deleteStep(item, i)"></i>
              </div>
              <div class="empty-dropzone-message" *ngIf="steps.pending.length == 0">Crie ou arraste uma fase aqui</div>
            </div>
          </div>
          <div class="section">
            <div class="title">
              Fazendo
              <i class="bi-plus-lg" (click)="addStep(1)"></i>
            </div>
            <div class="wrapper" [ngClass]="{'empty-dropzone': steps.in_progress.length == 0}" cdkDropList
              [id]="'in_progress'" [cdkDropListData]="steps.in_progress" (cdkDropListDropped)="updateArrays($event)">
              <div class="step in-progress" cdkDrag *ngFor="let item of steps.in_progress; let i = index"
                (click)="selectStep(item, i, 'in-progress')"
                [ngClass]="{'step-in-progress-selected': currentStepEdit == 'in-progress' + i}">
                <i class="bi-list"></i>
                <!-- <span *ngIf="currentStepEdit != 'in-progress' + i" (click)="focusInput('in-progress', i)">{{item.name}}</span> -->
                <span>{{ item.name }}</span>
                <!-- <input [class]="'amp-input ' + 'in-progress-' + i" [(ngModel)]="item.name" type="text" *ngIf="currentStepEdit == 'in-progress' + i" (blur)="currentStepEdit = undefined" (keyUp.enter)="currentStepEdit = undefined"> -->
                <i *ngIf="currentStepEdit != 'in-progress' + i" class="bi-trash3" (click)="deleteStep(item, i)"></i>
              </div>
              <div class="empty-dropzone-message" *ngIf="steps.in_progress.length == 0">Crie ou arraste uma fase aqui
              </div>
            </div>
          </div>
          <div class="section">
            <div class="title">
              Feito
              <i class="bi-plus-lg" (click)="addStep(2)"></i>
            </div>
            <div class="wrapper" [ngClass]="{'empty-dropzone': steps.closed.length == 0}" cdkDropList [id]="'closed'"
              [cdkDropListData]="steps.closed" (cdkDropListDropped)="updateArrays($event)">
              <div class="step closed" cdkDrag *ngFor="let item of steps.closed; let i = index"
                (click)="selectStep(item, i, 'closed')"
                [ngClass]="{'step-closed-selected': currentStepEdit == 'closed' + i}">
                <i class="bi-list"></i>
                <!-- <span *ngIf="currentStepEdit != 'closed' + i" (click)="focusInput('closed', i)">{{item.name}}</span> -->
                <span>{{ item.name }}</span>
                <!-- <input [class]="'amp-input ' + 'closed-' + i" [(ngModel)]="item.name" type="text" *ngIf="currentStepEdit == 'closed' + i" (blur)="currentStepEdit = undefined" (keyUp.enter)="currentStepEdit = undefined"> -->
                <i *ngIf="currentStepEdit != 'closed' + i" class="bi-trash3" (click)="deleteStep(item, i)"></i>
              </div>
              <div class="empty-dropzone-message" *ngIf="steps.closed.length == 0">Crie ou arraste uma fase aqui</div>
            </div>
          </div>
        </div>
      </div>

      <div class="step-settings-none" *ngIf="!currentStepItem">
        SELECIONE UMA FASE AO LADO PARA EDITAR
      </div>

      <div class="step-settings" *ngIf="currentStepItem" [ngClass]="getStepColor()">
        <div class="title mb-4">
          <span *ngIf="!isEditingName">{{ currentStepItem.name }}</span>
          <input class="amp-input" *ngIf="isEditingName" type="text" [(ngModel)]="currentStepItem.name"
            (blur)="isEditingName = false" (keyUp.enter)="isEditingName = false">
          <i *ngIf="!isEditingName" (click)="isEditingName = true" class="bi-pencil"></i>
          <i *ngIf="isEditingName" (click)="isEditingName = false" class="bi-check-lg"></i>
        </div>

        <div class="title">
          Checklist padrão da fase
        </div>

        <div class="description">
          É possível criar um checklist padrão para cada fase do ticket. Conforme o ticket passa de fase, as informações
          sobre os checklists permanecem acessíveis.
        </div>

        <div class="switch-toggle mb-3">
          <input id="amp-switch-checklist-enable" [(ngModel)]="currentStepItem.checklist_enabled" type="checkbox"
            class="amp-switch" />
          <label for="amp-switch-checklist-enable" class="amp-switch-label"></label>
          <label for="amp-switch-checklist-enable" class="amp-switch-status-text"
            *ngIf="!currentStepItem.checklist_enabled">Desativado</label>
          <label for="amp-switch-checklist-enable" class="amp-switch-status-text"
            *ngIf="currentStepItem.checklist_enabled">Ativado</label>
        </div>

        <div class="checklist" cdkDropList (cdkDropListDropped)="dropOptions($event)"
          *ngIf="currentStepItem.checklist_enabled">
          <div class="checklist-item" cdkDrag *ngFor="let option of currentStepItem.checklist; let i = index">
            <label class="amp-checkbox">
              <div class="amp-option">
                <input type="checkbox" disabled>
                <span *ngIf="isEditingChecklist != i" (click)="toggleChecklistEdition(i)" class="amp-option-text">{{
                  option.name }}</span>
                <input class="amp-input" id="on-edit-input" (keyUp.enter)="toggleChecklistEdition(i)"
                  *ngIf="isEditingChecklist == i" [(ngModel)]="option.name">
                <span class="checkmark"></span>
              </div>
            </label>
            <i (click)="removeOptionFromChecklist(i)" class="bi-x-lg"></i>
          </div>

          <div class="checklist-add">
            <i class="bi-plus-lg"></i>
            <input name="checklist-option" [(ngModel)]="checklistInput" (keyUp.enter)="addOptionToChecklist()"
              placeholder="nova opção">
            <i *ngIf="checklistInput.length" (click)="addOptionToChecklist()" class="bi-check-lg"></i>
          </div>
        </div>

        <div class="title" *ngIf="currentStepItem.checklist_enabled">
          <label class="amp-checkbox">
            <div class="amp-option">
              <input type="checkbox" [(ngModel)]="currentStepItem.checklist_required">
              <span class="amp-option-text title">Exigir checklist completo para passar de fase?</span>
              <span class="checkmark"></span>
            </div>
          </label>
        </div>

        <div class="description" *ngIf="currentStepItem.checklist_enabled">
          Se essa opção for marcada, o ticket só pode passar de fase quando todas as tarefas do checklist estão
          cumpridas.
        </div>
      </div>
    </div>
  </div>

  <div class="footer" *ngIf="!isLoading">
    <button class="amp-btn-outline-cancel" (click)="closeDialog()">Cancelar</button>
    <button [disabled]="!pipeline.name || !pipeline.default_assigned_to || !pipeline.email_from_name || !pipeline.default_expiration" *ngIf="isNew"
      class="amp-btn-success" (click)="save()">Criar Fluxo</button>
    <button *ngIf="!isNew" class="amp-btn-success" (click)="save()">Salvar Fluxo</button>
  </div>
</div>