import { Empresa } from '../empresa';
import { User } from '../user';
import { SmartTag } from './smartTags';
export class TagSet {
  _id: string;
  name: string;
  _empresa: Empresa | string;
  _user: User;
  created_at: Date;
  updated_at: Date;
  _smartTags: SmartTag[];
}
