<div class="amp-dialog">
  <p class="amp-dialog-title">Encaminhar Resposta</p>
  <div class="amp-dialog-subtitle">Digite os e-mails dos destinatários para quem enviará a resposta.</div>

  <amp-multi-select [(value)]="email" (onAdd)="validateEmail($event)" [addOnBlur]="true" [modelAsString]="true"
    identifyBy="_id" displayBy="name" primaryPlaceholder="Digite um e-mail e aperte Enter"></amp-multi-select>

  <div class="amp-buttons">
    <button class="amp-btn-success amp-btn-lg" (click)="forwardAnswer()" [disabled]="!email.length">Encaminhar</button>
    <button type="button" class="amp-btn-outline-cancel amp-btn-lg" (click)="bsModalRef.hide()">Cancelar</button>
  </div>
</div>