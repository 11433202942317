import { Component, Input } from '@angular/core';
@Component({
  selector: 'amp-progress-bar',
  templateUrl: './amp-progress-bar.component.html',
  styleUrls: ['./amp-progress-bar.component.scss']
})
export class AmpProgressBarComponent {
  @Input() value: number;
  @Input() label: string;
  @Input() onlyBar = false;
  @Input() color = '';
  @Input() showPercentage = true;
  @Input() stripes = false;
}
