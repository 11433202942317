import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  enqueueExport(type, data, business) {
    return this.http.post<any>(this.config.apiUrl + '/utils/export/enqueue', { type: type, data: data, business: business });
  }

  getFileColumns(fileURL, fileType = 'xlsx') {
    const params = new URLSearchParams();
    params.set('url', fileURL);
    params.set('fileType', fileType);
    return this.http.get<any>(this.config.apiUrl + `/utils/import-files/get-columns?${params.toString()}`);
  }

  checkFileFields(fileURL, fileType = 'xlsx', keys, delimiter?, types?) {
    const params = new URLSearchParams();
    params.set('url', fileURL);
    params.set('fileType', fileType);
    return this.http.post<any>(this.config.apiUrl + `/utils/import-files/check-fields?${params.toString()}`, { keys, delimiter, types });
  }

  checkXLSFile(fileURL, keys, types) {
    const params = new URLSearchParams();
    params.set('url', fileURL);
    return this.http.post<any>(this.config.apiUrl + `/utils/import-files/check-fields?${params.toString()}`, { keys, types });
  }

  getExportsForAdmin(limit = 20, page = 0) {
    const params = new URLSearchParams();
    //@ts-ignore
    params.set('limit', limit);
    //@ts-ignore
    params.set('page', page);
    return this.http.get<any>(this.config.apiUrl + `/utils/export/business/?${params.toString()}`);
  }

  getExports(_business, limit = 20, page = 0) {
    const params = new URLSearchParams();
    //@ts-ignore
    params.set('limit', limit);
    //@ts-ignore
    params.set('page', page);
    return this.http.get<any>(this.config.apiUrl + '/utils/export/business/' + _business + `?${params.toString()}`);
  }

  enqueueImport(type, data, business) {
    return this.http.post<any>(this.config.apiUrl + '/utils/import/enqueue', { type: type, data: data, business: business });
  }

  getImportsForAdmin(limit = 20, page = 0, search = '') {
    const params = new URLSearchParams();
    //@ts-ignore
    params.set('limit', limit);
    //@ts-ignore
    params.set('page', page);
    params.set('search', search);
    return this.http.get<any>(this.config.apiUrl + `/utils/import/business/?${params.toString()}`);
  }

  getImports(_business, limit = 20, page = 0) {
    const params = new URLSearchParams();
    //@ts-ignore
    params.set('limit', limit);
    //@ts-ignore
    params.set('page', page);
    return this.http.get<any>(this.config.apiUrl + '/utils/import/business/' + _business + `?${params.toString()}`);
  }
}
