import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'amp-reviews-distribution-bar',
  templateUrl: './reviews-distribution-bar.component.html',
  styleUrls: ['./reviews-distribution-bar.component.scss']
})
export class ReviewsDistributionBarComponent implements OnInit {
  _scores = {
    1: { value: 0, percentage: 0 },
    2: { value: 0, percentage: 0 },
    3: { value: 0, percentage: 0 },
    4: { value: 0, percentage: 0 },
    5: { value: 0, percentage: 0 }
  };

  get scores() {
    return this._scores;
  }

  @Input('scores') set scores(value) {
    this._scores = value;
    this.check();
  }

  @Input('total') total = 0;

  public promoterPercentage;
  public neutralPercentage;
  public detractorPercentage;
  public allZero = false;

  ngOnInit() {
    this.check();
  }

  check() {
    this.total = 0;
    try {
      if (this.scores) {
        Object.keys(this.scores).map((key) => (this.total += Number(this.scores[key].value)));

        this.scores[1].percentage = this.scores[1].value / this.total;
        this.scores[2].percentage = this.scores[2].value / this.total;
        this.scores[3].percentage = this.scores[3].value / this.total;
        this.scores[4].percentage = this.scores[4].value / this.total;
        this.scores[5].percentage = this.scores[5].value / this.total;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
