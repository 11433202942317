import { Campaign } from 'app/_models/referral/campaign';
import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CampaignService } from 'app/_services';
@Component({
  selector: 'app-block-ip-dialog',
  templateUrl: './block-ip-dialog.component.html',
  styleUrls: ['./block-ip-dialog.component.scss']
})
export class BlockIPDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private campaignService: CampaignService) {}

  public campaign: Campaign = new Campaign();
  public ipAddress;
  public loading = false;
  public subscriptions = [];

  fmSubmit() {
    this.loading = true;
    this.subscriptions.push(
      this.campaignService.updateCampaignIpBlacklist(this.campaign._id, this.ipAddress, 'insert').subscribe(() => {
        this.loading = false;
        this.bsModalRef.hide();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
