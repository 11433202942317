<ng-template #tooltipScore1>{{_scores[1]?.value}} avaliações<br>{{_scores[1]?.percentage | percent}} do total</ng-template>
<ng-template #tooltipScore2>{{_scores[2]?.value}} avaliações<br>{{_scores[2]?.percentage | percent}} do total</ng-template>
<ng-template #tooltipScore3>{{_scores[3]?.value}} avaliações<br>{{_scores[3]?.percentage | percent}} do total</ng-template>
<ng-template #tooltipScore4>{{_scores[4]?.value}} avaliações<br>{{_scores[4]?.percentage | percent}} do total</ng-template>
<ng-template #tooltipScore5>{{_scores[5]?.value}} avaliações<br>{{_scores[5]?.percentage | percent}} do total</ng-template>

<div class="amp-nps-bar-wrapper" *ngIf="total>0">
  <div class="amp-nps-bar">
    
    <div
      class="amp-nps-bar-indicator indicator-1"
      [tooltip]="tooltipScore1"
      placement="right"
      [ngStyle]="{
        width: (!allZero ? (_scores[1].percentage * 100) : 100/3) + '%'
      }"
    >

      <span class="amp-nps-bar-value">1<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/images/star-rating.icons.svg#star-1"></use></svg></span>
      <!-- <span class="amp-nps-bar-percentage">{{ _scores[1].percentage | percent }}</span> -->
    </div>
    <div
      class="amp-nps-bar-indicator indicator-2"
      [tooltip]="tooltipScore2"
      placement="top"
      [ngStyle]="{
        width: (!allZero ? (_scores[2].percentage * 100) : 100/3) + '%'
      }"
      >

      <span class="amp-nps-bar-value">2<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/images/star-rating.icons.svg#star-2"></use></svg></span>
      <!-- <span class="amp-nps-bar-percentage">{{ _scores[2].percentage | percent }}</span> -->
    </div>
    <div
      class="amp-nps-bar-indicator indicator-3"
      [tooltip]="tooltipScore3"
      placement="top"
      [ngStyle]="{
        width: (!allZero ? (_scores[3].percentage * 100) : 100/3) + '%'
      }"
      >

      <span class="amp-nps-bar-value">3<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/images/star-rating.icons.svg#star-3"></use></svg></span>
      <!-- <span class="amp-nps-bar-percentage">{{ _scores[3].percentage | percent }}</span> -->
    </div>
    <div
      class="amp-nps-bar-indicator indicator-4"
      [tooltip]="tooltipScore4"
      placement="top"
      [ngStyle]="{
        width: (!allZero ? (_scores[4].percentage * 100) : 100/3) + '%'
      }"
      >

      <span class="amp-nps-bar-value">4<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/images/star-rating.icons.svg#star-4"></use></svg></span>
      <!-- <span class="amp-nps-bar-percentage">{{ _scores[4].percentage | percent }}</span> -->
    </div>
    <div
      class="amp-nps-bar-indicator indicator-5"
      [tooltip]="tooltipScore5"
      placement="left"
      [ngStyle]="{
        width: (!allZero ? (_scores[5].percentage * 100) : 100/3) + '%'
      }"
      >

      <span class="amp-nps-bar-value">5<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/images/star-rating.icons.svg#star-5"></use></svg></span>
      <!-- <span class="amp-nps-bar-percentage">{{ _scores[5].percentage | percent }}</span> -->
    </div>
  </div>
</div>