<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      Detalhes da Requisição
    </div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <div class="amp-dialog-body">
    <div class="amp-content-text"></div>
    <div class="amp-field block">
      <label class="amp-label-text">Corpo da Requisição</label>
      <div class="amp-input">
        <pre [innerHTML]="log.body | prettyjson: [true,3]"></pre>
      </div>
    </div>
    <div class="amp-field block" *ngIf="log.error">
      <label class="amp-label-text">Erros da Requisição</label>
      <div class="amp-input">
        <pre>
          <code [innerHTML]="log.error | json">
          </code>
        </pre>
      </div>
    </div>
  </div>
</div>