<i (click)="closeModal()" class="bi-x-lg amp-close-button"></i>

<div class="amp-dialog">
  <p class="amp-dialog-title">Exportar Pesquisa</p>

  <div class="amp-dialog-subtitle">
    Selecione qual modelo de exportação você deseja.
  </div>

  <div class="amp-options">
    <div class="amp-option" (click)="export('finalized_and_not_finalized')" *ngIf="store.business.cf_attributes.cf_incomplete_answers">
      <div class="amp-option-left">
        <i class="bi-archive"></i>
      </div>
      <div class="amp-option-right">
        <span class="amp-option-title">Exportar Tudo</span>
        <span class="amp-option-description">Exportará todas as respostas, completas e incompletas.</span>
      </div>
    </div>

    <div class="amp-option" (click)="export('not_finalized')" *ngIf="store.business.cf_attributes.cf_incomplete_answers">
      <div class="amp-option-left">
        <i class="bi-file-earmark-excel"></i>
      </div>
      <div class="amp-option-right">
        <span class="amp-option-title">Exportar Incompletas</span>
        <span class="amp-option-description">Exportará apenas as respostas incompletas.</span>
      </div>
    </div>

    <div class="amp-option" (click)="export('finalized')">
      <div class="amp-option-left">
        <i class="bi-file-earmark-check"></i>
      </div>
      <div class="amp-option-right">
        <span class="amp-option-title">Exportar Completas</span>
        <span class="amp-option-description">Exportará apenas as respostas completas.</span>
      </div>
    </div>

    <div class="amp-option" (click)="export('request')">
      <div class="amp-option-left">
        <i class="bi-card-checklist"></i>
      </div>
      <div class="amp-option-right">
        <span class="amp-option-title">Exportar Solicitações</span>
        <span class="amp-option-description">Exportará todas as solicitações de respostas.</span>
      </div>
    </div>
  </div>
</div>