import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
@Injectable()
export class ImportReviewService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  trip(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/trip/getReviews/' + _id);
  }

  booking(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/booking/getReviews/' + _id);
  }

  hotelsCom(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/hotelscom/getReviews/' + _id);
  }

  expedia(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/expedia/getReviews/' + _id);
  }

  despegarcom(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/despegar/getReviews/' + _id);
  }

  airbnb(_id: string) {
    return this.http.get<any>(this.config.apiUrl + '/airbnb/getReviews/' + _id);
  }
}
