import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ampCfFilterPipe'
})
export class CfFilterPipe implements PipeTransform {
  private operators = {
    equals: 'igual',
    not_equals: 'diferente',
    contains: 'contém',
    not_contains: 'não contém'
  };
  transform(filter: any, ...args: any[]): any {
    if (filter.values.length > 1) {
      if (filter.specify) {
        return `${filter.filter.label} ${this.operators[filter.condition]} ${filter.values[0]} e +${filter.values.length}`;
      } else {
        return `${filter.filter.label} ${this.operators[filter.condition]} ${filter.values[0]} e +${filter.values.length - 1}`;
      }
    }

    return `${filter.filter.label} ${this.operators[filter.condition]} ${filter.values.toString()} ${filter.specify ? filter.specifyValue : ''}`;
  }
}
