import { BusinessService, EmpresaService, NotifyService } from 'app/_services';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Business } from 'app/_models/business';
import { Empresa } from 'app/_models/empresa';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-new-business-dialog',
  templateUrl: './new-business-dialog.component.html',
  styleUrls: ['./new-business-dialog.component.scss']
})
export class NewBusinessDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    private empresaService: EmpresaService,
    private businessService: BusinessService,
    private notifyService: NotifyService
  ) {}

  public isNew;
  public empresaId = '';
  public empresa: Empresa;
  public subscriptions = [];
  public empresas: any[] = [];
  public isFromAdmInterna = false;
  public business: Business = new Business();
  public result: Subject<boolean> = new Subject();
  public maskCpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public maskCnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  ngOnInit() {
    if (!this.isFromAdmInterna) {
      this.loadEmpresas();
    }
  }

  loadEmpresas() {
    this.subscriptions.push(
      this.empresaService.getManagedEmpresas().subscribe((res) => {
        this.empresas = [];
        this.empresas = res;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  fmSubmit() {
    if (this.isNew) {
      if (this.empresa?.billing_mode == 'organization') {
        this.business.taxId = this.empresa.taxId;
      }

      this.business._empresa = this.empresaId;
      this.business.reviews_params._email_request_message = null;

      this.subscriptions.push(
        this.businessService.create(this.business).subscribe(
          () => {
            this.notifyService.success('Negócio criado com sucesso!');
            this.result.next(false);
            this.bsModalRef.hide();
          },
          (error) => {
            console.error(error);
            this.notifyService.success('Não foi possível criar o negócio.');
          }
        )
      );
      this.result.next(false);
      this.bsModalRef.hide();
    } else {
      delete this.business._id;
      this.subscriptions.push(
        this.businessService.create(this.business).subscribe(
          () => {
            this.notifyService.success('Negócio clonado com sucesso!');
            this.result.next(false);
            this.bsModalRef.hide();
          },
          (error) => {
            console.error(error);
            this.notifyService.success('Não foi possível clonar o negócio.');
          }
        )
      );
    }
  }

  maskCpfCnpj() {
    let replaceMask;
    return {
      mask: (value) => {
        replaceMask = value.replace(/[^0-9]+/g, '');
        if (replaceMask.length <= 11) {
          return this.maskCpf;
        } else {
          return this.maskCnpj;
        }
      },
      guide: true
    };
  }
}
