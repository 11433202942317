import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { AuthenticationService } from 'app/_services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-get-cpf-cnpj-dialog',
  templateUrl: './get-cpf-cnpj-dialog.component.html',
  styleUrls: ['./get-cpf-cnpj-dialog.component.scss']
})
export class GetCpfCnpjDialogComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef, private store: StoreService, private auth: AuthenticationService) {}

  public tax_id = '';
  public company_name = '';
  public isAccountManager = false;
  public result: Subject<object> = new Subject();

  @ViewChild('cpfCnpj', { static: false }) inputCpfCnpj: ElementRef;

  ngOnInit() {
    this.tax_id = this.store.business.taxId;
    this.company_name = this.store.business.companyName;
    this.isAccountManager = this.auth.isAccountManager();
  }

  save() {
    this.tax_id = this.inputCpfCnpj.nativeElement.value;
    this.result.next({ tax_id: this.tax_id, company_name: this.company_name });
    this.bsModalRef.hide();
  }
}
