<div class="amp-wrapper" [ngClass]="{'amp-no-value': !content.length}">
  <div id="amp-wysiwyg-toolbar" [attr.data-placeholder]="placeholder" class="amp-wysiwyg focus" (click)="onClickContent($event)">
    <div #contentEditable class="amp-pseudoinput" contenteditable="true" (keyup)="saveChanges($event)" (paste)="onPaste($event)"></div>
    <div class="amp-wysiwyg-toolbar" *ngIf="showToolbar" clickOutside (clickOutside)="showToolbar = false" [ngStyle]="{left: left + 'px', top: top + 'px'}">
      <img src="../../../assets/img/bold.svg" (click)="applyFormat('bold')">
      <img src="../../../assets/img/italic.svg" (click)="applyFormat('italic')">
      <img src="../../../assets/img/underline.svg" (click)="applyFormat('underline')">
      <div class="amp-arrow"></div>
    </div>
  </div>
</div>

