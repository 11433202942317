import { NewTagSetDialogComponent } from '../new-tag-set-dialog/new-tag-set-dialog.component';
import { DeleteDialogComponent } from 'app/_dialogs/delete-dialog/delete-dialog.component';
import { InfoDialogComponent } from 'app/_dialogs/info-dialog/info-dialog.component';
import { SmartTagService } from 'app/_services/smartTags/smartTags.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SmartTag } from 'app/_models/smartCluster/smartTags';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from 'app/_services/store.service';
import { TagSet } from 'app/_models/smartCluster/tagSet';
import { NotifyService } from 'app/_services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-tag-set-manager-dialog',
  templateUrl: './tag-set-manager-dialog.component.html',
  styleUrls: ['./tag-set-manager-dialog.component.scss']
})
export class TagSetManagerDialogComponent implements OnInit, OnDestroy {
  public tagSets: TagSet[] = [];
  public tagIndexSelected = 0;
  public initialAutoCompleteSmartTags: SmartTag[] = [];
  public smartTags = [];
  public subscriptions = [];
  public closeModalSub;

  constructor(
    private store: StoreService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private bsModalService: BsModalService,
    private smartTagsService: SmartTagService
  ) {}

  ngOnInit() {
    this.loadTagsSets();

    this.closeModalSub = this.subscriptions.push(
      this.bsModalService.onHide.subscribe(() => {
        this.loadTagsSets();
        this.loadSmartTags();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadSmartTags() {
    this.subscriptions.push(
      this.smartTagsService.getSmartTags(this.store.empresa, '').subscribe((data) => {
        this.initialAutoCompleteSmartTags = data.result;
      })
    );
  }

  loadTagsSets() {
    this.subscriptions.push(
      this.smartTagsService.getTagsSet(this.store.empresa).subscribe((data) => {
        this.tagSets = data.result;
        this.tagIndexSelected = 0;
        this.smartTags = this.tagSets[this.tagIndexSelected]._smartTags;
      })
    );
  }

  openCreateNewTagSet() {
    this.modalService.show(NewTagSetDialogComponent, { class: 'amp-modal' });
  }

  selectTag(i) {
    this.tagSets[this.tagIndexSelected]._smartTags = this.smartTags;
    this.tagIndexSelected = i;
    this.smartTags = this.tagSets[this.tagIndexSelected]._smartTags;
  }

  requestTagsAutoComplete = (text): Observable<any> => {
    return this.smartTagsService.getSmartTags(this.store.empresa, text).pipe(map((tags) => tags.result.map((tag) => tag)));
  };

  renameTagSet() {
    const initialState = {
      isNew: false,
      tagSet: this.tagSets[this.tagIndexSelected]
    };

    this.modalService.show(NewTagSetDialogComponent, {
      initialState,
      class: 'amp-modal'
    });
  }

  deleteTagSet() {
    const initialState = {
      title: 'Remover Conjunto de Tags',
      message: 'Tem certeza que deseja remover o conjunto ',
      strongText: this.tagSets[this.tagIndexSelected].name,
      messageEnd: ' e todos as suas tags?'
    };

    const bsModalRef: BsModalRef = this.modalService.show(DeleteDialogComponent, {
      initialState,
      class: 'amp-modal'
    });

    this.subscriptions.push(
      bsModalRef.content.resultado.subscribe((result) => {
        if (result) {
          this.subscriptions.push(
            this.smartTagsService.deleteTagSet(this.tagSets[this.tagIndexSelected]).subscribe(() => {
              this.tagSets.splice(this.tagIndexSelected);
              this.tagIndexSelected = 0;
              this.smartTags = this.tagSets[this.tagIndexSelected]._smartTags;
            })
          );
          this.notifyService.success('Conjunto de Tags deletado com sucesso!');
        }
      })
    );
  }

  async updateTagSets() {
    if (this.tagSets[this.tagIndexSelected]) {
      this.tagSets[this.tagIndexSelected]._smartTags = this.smartTags;

      await Promise.all(
        this.tagSets.map((tagSet) => {
          //@ts-ignore
          tagSet._smartTags = tagSet._smartTags.map((smartTags) => smartTags._id);
          return new Promise((resolve, reject) => {
            this.smartTagsService.updateTagSet(tagSet).subscribe({
              next: (data) => {
                resolve(data);
              },
              error: (error) => {
                reject(error);
              }
            });
          });
        })
      );

      this.bsModalRef.hide();

      const initialState = {
        title: 'Alteração de Conjunto de Tags',
        message: 'Como você realizou alterações nos conjuntos de tags, as respostas das pesquisas serão reanalisadas em ',
        strongText: 'até 30 minutos.',
        messageEnd: ''
      };

      this.modalService.show(InfoDialogComponent, {
        initialState,
        class: 'modal-lg amp-modal'
      });

      this.notifyService.success('Conjuntos de Tags salvos com sucesso!');
    } else {
      this.bsModalRef.hide();
    }
  }
}
