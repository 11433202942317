import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[validatePhone][formControlName],[validatePhone][formControl],[validatePhone][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => PhoneValidator), multi: true }]
})
export class PhoneValidator implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    const v = c.value;

    if (!new RegExp(/^[(]\d{2}[)] \d \d{4}-\d{4}/g).test(v)) {
      return {
        validateEqual: false
      };
    } else {
      return null;
    }
  }
}
