import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, NotifyService, StoreService } from 'app/_services';
import { AIService } from 'app/_services/ai/ai.service';
import { SurveyAnswerService } from 'app/_services/customerFeedback/surveyAnswer.service';
import { MixPanelService } from 'app/_services/mixPanel.service';
import { ReviewService } from 'app/_services/review.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-view-review-dialog',
  templateUrl: './view-review-dialog.component.html',
  styleUrls: ['./view-review-dialog.component.scss']
})
export class ViewReviewDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public surveyAnswerService: SurveyAnswerService,
    public modalService: BsModalService,
    public auth: AuthenticationService,
    public reviewService: ReviewService,
    public notify: NotifyService,
    public AIService: AIService,
    public mixPanelService: MixPanelService,
    public store: StoreService
  ) {}

  public subscriptions = [];
  public review;
  public result = new Subject();
  public isAccountManager;
  public aiFeedbackSent = false;
  public showAnswerInput = false;
  public filterObject: any = {};
  public reply;
  public aiSentiment: any = 'neutral';
  public aiSuggestion: any = {};
  public loadingAISuggestion = false;
  public i = 0;
  public speed = 25;
  public suggestionText = '';

  ngOnInit() {
    this.isAccountManager = this.auth.isAccountManager();
    this.review = { ...this.review };
    if (this.review.ai_suggestion) {
      this.aiSuggestion = { completion: [this.review.ai_suggestion.answer], created_at: this.review.ai_suggestion.created_at };
      this.suggestionText = this.aiSuggestion.completion[0] || '';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  answer() {
    if (this.review.network == 'tripadvisor') {
      window.open(
        `https://tripadvisor.com.br/OwnerResponse-d${this.review.reviewPlatform.location_id.toString()}?review=${this.review.network_id.toString()}`,
        '_blank'
      );
    } else {
      this.reply = '';
      this.showAnswerInput = true;
    }
  }

  typeWriter() {
    if (this.i < this.aiSuggestion.completion[0].length) {
      this.suggestionText += this.aiSuggestion.completion[0].charAt(this.i);
      this.i++;
      setTimeout(() => this.typeWriter(), this.speed);
    }
  }

  async copyToClipboard() {
    await navigator.clipboard.writeText(this.aiSuggestion.completion[0]).then(() => {
      this.notify.success('Resposta copiada para a área de transferência!');
    });
  }

  rateAI(response) {
    this.reviewService.aiFeedback(this.review._id, response).subscribe(
      () => {
        this.notify.success('Obrigado pelo feedback!');
        this.aiFeedbackSent = true;
      },
      (error) => {
        console.error(error);
        this.notify.error('Houve um erro ao enviar o feedback.');
      }
    );
  }

  getAIAnswerSuggestion() {
    if (!this.aiSuggestion?.created_at) {
      this.loadingAISuggestion = true;
      if (this.review.message?.length > 0) {
        this.subscriptions.push(
          this.AIService.getReviewAnswerSuggestion(this.review._id, this.aiSentiment, this.review.message, this.store.business._id).subscribe(
            (response: any) => {
              this.aiSuggestion = response;
              this.loadingAISuggestion = false;
              this.typeWriter();
              this.mixPanelService.track('AI Review Suggestion');
              this.generateAnswer();
            },
            (error) => {
              this.loadingAISuggestion = false;
              console.error(error);
              this.notify.error('Erro ao obter sugestão de resposta com a AI!');
            }
          )
        );
      } else {
        this.loadingAISuggestion = false;
        this.notify.error('Avaliações sem comentários não podem ser respondidas pela AI!');
      }
    } else {
      this.generateAnswer();
      return;
    }
  }

  openAIAnswer() {
    this.showAnswerInput = true;
  }

  generateAnswer() {
    this.reply = this.aiSuggestion.completion[0];
  }

  sendAnswer() {
    this.review.reply = this.reply;
    this.showAnswerInput = false;
    this.subscriptions.push(
      this.reviewService.reply(this.review).subscribe(
        () => {
          this.notify.success('Avaliação respondida com sucesso!');
        },
        (error) => {
          console.error(error);
          if (error.error.message == 'NETWORK_NOT_ALLOW_REPLY') {
            this.notify.warning('Esta plataforma não aceita respostas diretas.');
          } else if (error.error.message == 'REVIEW_NOT_FOUND') {
            this.notify.error('Avaliação não encontrada na plataforma, atualize sua página!');
          } else {
            this.notify.error('Erro ao responder avaliação!');
          }
        }
      )
    );
  }
}
