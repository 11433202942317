import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
@Injectable()
export class CostsService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(dateFrom, dateTo) {
    return this.http.post<any>(this.config.apiUrl + '/adm/costs/', { dateFrom, dateTo });
  }
}
