import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public title = '';
  public message = '';
  public buttonName = '';
  public cancelName = 'Cancelar';
  public messageEmphasis = false;
  public result: Subject<boolean> = new Subject();

  constructor(public bsModalRef: BsModalRef) {}

  cancelar() {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  confirmar() {
    this.result.next(true);
    this.bsModalRef.hide();
  }
}
