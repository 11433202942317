import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import TkTicket from 'app/_models/ticketing/ticket';

@Injectable({ providedIn: 'root' })
export class TkTicketService {
  constructor(private httpClient: HttpClient, private config: AppConfig) {}

  public create(pipelineId: string, ticketData: TkTicket) {
    return this.httpClient.post(`${this.config.apiUrl}/ticketing/ticket/${pipelineId}`, ticketData);
  }

  public update(ticketId, ticketData) {
    return this.httpClient.put(`${this.config.apiUrl}/ticketing/ticket/${ticketId}`, ticketData);
  }

  public getDetails(ticketId, skipActivities = 0, activityType?) {
    const urlParams = new URLSearchParams();
    urlParams.append('skipActivities', skipActivities.toString());
    if (activityType) {
      urlParams.append('activityType', activityType);
    }
    return this.httpClient.get(`${this.config.apiUrl}/ticketing/ticket/${ticketId}/details?${urlParams.toString()}`);
  }

  public createActivity(ticketId, activityData) {
    return this.httpClient.post(`${this.config.apiUrl}/ticketing/ticket/${ticketId}/activity`, activityData);
  }

  public deleteActivity(ticketId, activityId) {
    return this.httpClient.delete(`${this.config.apiUrl}/ticketing/ticket/${ticketId}/activity/${activityId}`);
  }

  public getTicketsBySurveyAnswer(surveyAnswerId) {
    return this.httpClient.get(`${this.config.apiUrl}/ticketing/ticket/surveyAnswer/${surveyAnswerId}`);
  }
  public getTicketsByCustomer(customerId) {
    return this.httpClient.get(`${this.config.apiUrl}/ticketing/ticket/customer/${customerId}`);
  }
}
