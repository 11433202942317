import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { TagSet } from 'app/_models/smartCluster/tagSet';
import { SmartTag } from 'app/_models/smartCluster/smartTags';
@Injectable()
export class SmartTagService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  createTagSet(tagSet: TagSet) {
    return this.http.post<any>(`${this.config.apiUrl}/tag-set`, tagSet);
  }

  updateTagSet(tagSet: TagSet) {
    return this.http.put<any>(`${this.config.apiUrl}/tag-set/${tagSet._id}`, tagSet);
  }

  createSmartTag(smartTag: SmartTag) {
    return this.http.post<any>(`${this.config.apiUrl}/smart-tags`, smartTag);
  }

  updateSmartTag(smartTag: SmartTag) {
    return this.http.put<any>(`${this.config.apiUrl}/smart-tags/${smartTag._id}`, smartTag);
  }

  updateBatchSmartTag(smartTags: SmartTag[]) {
    return this.http.put<any>(`${this.config.apiUrl}/smart-tags/batch`, { smartTags });
  }

  getTagsSet(_empresa) {
    return this.http.get<any>(`${this.config.apiUrl}/tag-set?_empresa=${_empresa}`);
  }

  getSmartTags(_empresa, filter?, _tagSet?) {
    return this.http.get<any>(`${this.config.apiUrl}/smart-tags?_empresa=${_empresa}&filter=${filter}&_tagSet=${_tagSet}`);
  }

  deleteSmartTag(smartTag) {
    return this.http.delete<any>(`${this.config.apiUrl}/smart-tags/${smartTag._id}`);
  }

  deleteTagSet(tagSet) {
    return this.http.delete<any>(`${this.config.apiUrl}/tag-set/${tagSet._id}`);
  }
}
