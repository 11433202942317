import { ConfirmReferralDialogComponent } from 'app/_dialogs/confirm-referral-dialog/confirm-referral-dialog.component';
import { Subject } from 'rxjs';
import { UtilService } from 'app/_services/util.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnDestroy } from '@angular/core';
import { Template } from 'app/_models/adm/template';
import { NotifyService, CampaignService } from 'app/_services';
@Component({
  selector: 'app-import-referrals-dialog',
  templateUrl: './import-referrals-dialog.component.html',
  styleUrls: ['./import-referrals-dialog.component.scss']
})
export class ImportReferralsDialogComponent implements OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public utilService: UtilService,
    public notifyService: NotifyService,
    public campaignService: CampaignService
  ) {}

  public templates: Template[] = [];
  public type = '';
  public subtype = '';
  public step = 0;
  public file: string;
  public importName = '';
  public keys = { _id: null, status: null };
  public checkingDataXLS;
  public campaign;
  public selectPersistence: any[] = [];
  public disableNextStep = true;
  public validLines;
  public clicked = false;
  public resultado: Subject<boolean> = new Subject();
  public subscriptions = [];

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  changeKey(key, value) {
    if (this.keys._id == value) {
      this.keys._id = null;
    } else if (this.keys.status == value) {
      this.keys.status = null;
    }
    this.keys[key] = value;
    if (this.keys.status || this.keys._id) {
      this.disableNextStep = false;
    } else {
      this.disableNextStep = true;
    }
  }

  checkXLSFile() {
    this.subscriptions.push(
      this.utilService.checkXLSFile(this.file, this.keys, { _id: 'ObjectId', redeemed: 'text-bool' }).subscribe(
        (data) => {
          this.validLines = data.length;
        },
        (error) => console.error(error)
      )
    );
  }

  checkValidFile() {
    this.subscriptions.push(
      this.utilService.getFileColumns(this.file).subscribe({
        next: async (data) => {
          if (data.err === undefined) {
            this.checkingDataXLS = data.fields;
            this.keys = {
              _id: null,
              status: null
            };
            if (this.keys._id || this.keys.status) {
              this.disableNextStep = false;
            }
          } else {
            this.notifyService.error('Houve algum erro ao processar o arquivo!');
          }
        },
        error: (error) => {
          console.error(error);
          this.notifyService.error('Não foi possível processar a requisição.');
        }
      })
    );
  }

  import() {
    this.clicked = true;
    this.openModalToValidate();
  }

  openModalToValidate() {
    const initialState = {
      buttonName: 'Validar',
      title: 'Validar Recompensa',
      message: 'Selecione uma unidade para validar esta recompensa'
    };

    const modalRef = this.modalService.show(ConfirmReferralDialogComponent, {
      class: 'amp-modal-lg col-xl-4 col-lg-6 col-md-10 col-sm-12',
      ignoreBackdropClick: true,
      initialState
    });

    const sub = modalRef.content.result.subscribe((data) => {
      sub.unsubscribe();
      if (data) {
        this.subscriptions.push(
          this.campaignService.importRewards(this.file, this.keys, this.campaign, data).subscribe(
            () => {
              this.notifyService.success('Importação realizada com sucesso!');
              this.bsModalRef.hide();
              this.resultado.next(true);
            },
            () => this.notifyService.error('Houve algum erro ao processar o arquivo.')
          )
        );
      }
    });
  }
}
