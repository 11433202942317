import { environment } from '../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class AppConfig {
  public readonly apiUrl = environment.apiURL;
  public readonly novu_key = environment.novu_key;

  constructor(public jwtHelper: JwtHelperService, private router: Router) {}
}
