import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'app/_services';

@Component({
  selector: 'amp-referral-email-preview',
  templateUrl: './referral-email-preview.component.html',
  styleUrls: ['./referral-email-preview.component.scss']
})
export class ReferralEmailPreviewComponent implements OnInit {
  @Input() picture: string;
  @Input() message1: string;
  @Input() message2: string = '';
  @Input() bgColor: string = '#ffca00';
  @Input() fontColor: string = '#ffffff';

  public userName: string;

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    this.userName = this.authService.getNome();
  }
}
