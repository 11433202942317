import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';
import { MenuService } from 'app/_services/menu.service';
import { StoreService } from 'app/_services';
@Component({
  selector: 'app-disable-account-dialog',
  templateUrl: './disable-account-dialog.component.html',
  styleUrls: ['./disable-account-dialog.component.scss']
})
export class DisableAccountDialogComponent {
  constructor(public bsModalRef: BsModalRef, public menuService: MenuService, public store: StoreService) {}

  public title = '';
  public message = '';
  public strongText = '';
  public messageEnd = '';
  public resultado: Subject<boolean> = new Subject();

  confirmar() {
    this.menuService.emitChat(true);
    this.resultado.next(true);
  }
}
