import { Injectable } from '@angular/core';
import { NgNoty, NotyOptions } from '../amp-noty/src/index';
@Injectable()
export class NotifyService {
  constructor(private ngNoty: NgNoty) {}

  private openAnimation = 'animate__animated animate__bounceInUp';
  private closeAnimation = 'animate__animated animate__fadeOutDown';
  private layout = 'bottomCenter';
  private theme = 'amp';
  private timeOut = 5000;
  private progressBar = false;

  show(title: string, text: string, type: string, progressBar?: boolean, timeOut?: number) {
    let icon = '';

    switch (type) {
      case 'success':
        icon = 'check-circle-fill';
        break;
      case 'info':
        icon = 'info-circle-fill';
        break;
      case 'warning':
        icon = 'exclamation-triangle-fill';
        break;
      case 'error':
        icon = 'x-circle-fill';
        break;
      case 'alert':
        icon = 'exclamation-triangle-fill';
        break;
      default:
        icon = '';
        break;
    }

    const content: string = "<span class='noty-icon bi-" + icon + "'></span> <p>" + text + '</p>';
    const noty = this.ngNoty.create(<NotyOptions>{
      type: type,
      layout: this.layout,
      theme: this.theme,
      animation: {
        open: this.openAnimation,
        close: this.closeAnimation
      },
      progressBar: progressBar || this.progressBar,
      timeout: this.timeOut ?? timeOut,
      text: content
    });

    noty.show();
    return noty;
  }

  success(text: string) {
    return this.show('Sucesso', text, 'success');
  }

  warning(text: string) {
    return this.show('Alerta', text, 'warning');
  }

  error(text: string) {
    return this.show('Erro', text, 'error');
  }

  info(text: string) {
    return this.show('Informação', text, 'info');
  }

  loading(text: string): any {
    return this.show('Carregando', text, 'info', true, 0);
  }
}
