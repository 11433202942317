import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { QuestionGroup, Survey } from '../../_models/customerFeedback/survey';
import moment from 'moment';
@Injectable()
export class SurveyService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  get(business: string, empresa?: string, onlyWithQuestions = false, filterTitle = null) {
    // @ts-ignore
    const query = new URLSearchParams({
      empresa,
      onlyWithQuestions
    });

    if (business) {
      query.append('business', business);
    }
    if (filterTitle) {
      query.append('title', filterTitle);
    }

    return this.http.get<Survey[]>(this.config.apiUrl + '/cf/survey?' + query.toString());
  }

  getActive(business: string, empresa?: string) {
    const query = new URLSearchParams({
      empresa
    });

    if (business) {
      query.append('business', business);
    }

    return this.http.get<Survey[]>(this.config.apiUrl + '/cf/survey/active?' + query.toString());
  }

  getSurveysOnDashboard(business: string) {
    return this.http.get<any[]>(this.config.apiUrl + `/cf/survey/on-dashboard/${business}`);
  }

  getActiveByTagSet(business: string, tagSet, empresa?: string, active: boolean = null, onlyWithQuestions = false) {
    // @ts-ignore
    const query = new URLSearchParams({
      empresa,
      tagSet,
      active,
      onlyWithQuestions
    });

    if (business) {
      query.append('business', business);
    }

    return this.http.get<Survey[]>(this.config.apiUrl + '/cf/survey/active/tag-set/?' + query.toString());
  }

  getById(_id: string) {
    return this.http.get<Survey>(this.config.apiUrl + '/cf/survey/' + _id);
  }

  create(survey: Survey) {
    return this.http.post<Survey>(this.config.apiUrl + '/cf/survey', survey);
  }

  update(survey: Survey, force?: boolean) {
    const query = new URLSearchParams();

    if (force) {
      query.set('force', 'true');
    }

    return this.http.put<any>(this.config.apiUrl + `/cf/survey/${survey._id}?${query.toString()}`, survey);
  }

  changeFolder(surveyId: string, surveyFolderId: string) {
    return this.http.put<any>(this.config.apiUrl + `/cf/survey/${surveyId}/change-folder/`, { folder: surveyFolderId });
  }

  updateFilters(surveyId: string, filters: any) {
    return this.http.put<any>(this.config.apiUrl + `/cf/survey/${surveyId}/filters`, filters);
  }

  deleteMulti(surveys: string[]) {
    return this.http.patch<Survey[]>(this.config.apiUrl + `/cf/survey/delete-multi`, { surveys });
  }

  delete(survey: Survey) {
    return this.http.delete<Survey>(this.config.apiUrl + `/cf/survey/${survey._id}`);
  }

  reset(survey: Survey) {
    return this.http.patch<Survey>(this.config.apiUrl + `/cf/survey/${survey._id}/reset`, {});
  }

  getBasicMetrics(surveyId, filterDate, filters?, _empresa?, filter_any = false, selectedCompletion = 'complete', filters_version = 1) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      only_finalized: selectedCompletion,
      filters_version,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getQuestionMetrics(
    surveyId,
    filterDate,
    viewMode = 'aggregated',
    filters?,
    _empresa?,
    filter_any = false,
    selectedCompletion = 'complete',
    filters_version = 1
  ) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/question-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      only_finalized: selectedCompletion,
      filters_version,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getChannelMetrics(
    surveyId,
    filterDate,
    filters?,
    _empresa?,
    filter_any = false,
    filters_version = 1,
    viewType = 'requests',
    selectedCompletion = 'complete'
  ) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/channel-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      filters_version,
      viewType,
      only_finalized: selectedCompletion,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getMetadataMetrics(surveyId, filterDate, filters?, _empresa?, filter_any = false, filters_version = 1, selected_completion = 'complete') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/metadata-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      filters_version,
      only_finalized: selected_completion,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getContactOptinMetrics(surveyId, filterDate, filters?, _empresa?, filter_any = false, filters_version = 1, selectedCompletion = 'complete') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/optin-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      filters_version,
      only_finalized: selectedCompletion,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getResolvedMetrics(surveyId, filterDate, filters?, _empresa?, filter_any = false, filters_version = 1, selectedCompletion = 'complete') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/resolved-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      filters_version,
      only_finalized: selectedCompletion,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getGroupDateChart(surveyId, filterDate, filters?, _empresa?, filter_any = false, filters_version = 1, selectedCompletion = 'complete') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/group-date-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      filters_version,
      only_finalized: selectedCompletion,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getSmartTagsMetrics(
    surveyId,
    filterDate,
    viewMode = 'aggregated',
    filters?,
    _empresa?,
    filter_any = false,
    selectedCompletion = 'complete',
    internal_name = undefined,
    filters_version = 1,
    text_question = undefined
  ) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });

    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/smart-tags-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      only_finalized: selectedCompletion,
      internal_name: internal_name,
      text_question: text_question,
      filters_version,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }
  getBasicMetricsDashboard(ids, filterDate, filters?, _empresa?, filter_any = false, selectedCompletion = 'complete', filters_version = 1) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    return this.http.post<any>(this.config.apiUrl + `/cf/survey/metrics?${query.toString()}`, {
      ids: ids,
      _empresa,
      filters,
      filter_any,
      only_finalized: selectedCompletion,
      filters_version
    });
  }

  getQuestionMetricsDashboard(
    ids,
    filterDate,
    metric,
    viewMode = 'aggregated',
    filters,
    filter_any = false,
    _empresa,
    internal_name = '',
    selectedCompletion = 'complete'
  ) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });
    return this.http.post<any>(this.config.apiUrl + `/cf/survey/question-metrics?${query.toString()}`, {
      ids,
      metric,
      internal_name,
      _empresa,
      filters,
      filter_any,
      only_finalized: selectedCompletion
    });
  }

  getSmartTagsMetricsDashboard(ids, filterDate, metric, viewMode = 'aggregated', smartTags, filters, filter_any, _empresa, selectedCompletion = 'complete') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });
    return this.http.post<any>(this.config.apiUrl + `/cf/survey/smart-tags-metrics?${query.toString()}`, {
      ids,
      metric,
      filters,
      _empresa,
      smartTags,
      filter_any,
      only_finalized: selectedCompletion
    });
  }

  getOvertimeScoreChart(
    ids,
    filterDate,
    metric,
    viewMode = 'aggregated',
    unit = 'day',
    filters,
    filter_any,
    _empresa,
    internal_name = '',
    selectedCompletion = 'complete',
    filters_version = 1
  ) {
    const query = new URLSearchParams({
      viewMode: viewMode,
      unit
    });
    return this.http.post<any>(this.config.apiUrl + `/cf/survey/overtime-score-chart?${query.toString()}`, {
      ids,
      metric,
      internal_name,
      _empresa,
      filters,
      filter_any,
      only_finalized: selectedCompletion,
      filters_version
    });
  }

  getOvertimeChart(ids, filterDate, unit = 'day', filters, filter_any, _empresa, selectedCompletion = 'complete', hasInApp = undefined, filters_version = 1) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      unit
    });

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/overtime-chart?${query.toString()}`, {
      ids,
      _empresa,
      filters,
      filter_any,
      only_finalized: selectedCompletion,
      hasInApp,
      filters_version
    });
  }

  getWordCloud(ids, filterDate, filters, _empresa, filter_any, selectedCompletion = 'complete', internal_name = undefined, filters_version = 1) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams();
    query.set('startDate', start);
    query.set('endDate', end);
    return this.http.post<any>(this.config.apiUrl + `/cf/survey/word-cloud?${query}`, {
      ids,
      _empresa,
      filters,
      filter_any,
      only_finalized: selectedCompletion,
      internal_name,
      filters_version
    });
  }

  getQuestionsListByType(type = 'text', empresa?: string) {
    const query = new URLSearchParams({
      business: '',
      empresa,
      type
    });
    return this.http.get<[{ _id: string; count: number }]>(this.config.apiUrl + '/cf/survey/questions-list-by-type?' + query.toString());
  }

  getQuestionsListBySurveys(ids) {
    return this.http.post(this.config.apiUrl + '/cf/survey/questions-list?', { ids });
  }

  getSmartFollowUpMetrics(surveyId, filterDate, filters?, _empresa?, filter_any = false, filters_version = 1) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end
    });
    const survey = typeof surveyId === 'string' ? surveyId : surveyId[0];

    return this.http.post<any>(this.config.apiUrl + `/cf/survey/${survey}/smart-follow-up-metrics?${query.toString()}`, {
      filters,
      _empresa,
      filter_any,
      filters_version,
      ids: typeof surveyId !== 'string' ? surveyId : undefined
    });
  }

  getQuestionMetricsBreakdown(
    ids,
    filterDate,
    metric,
    viewMode = 'aggregated',
    filters,
    filter_any = false,
    _empresa,
    internal_name = '',
    selectedCompletion = 'complete',
    group_by_custom_fields = [],
    isGroup = false,
    sort = 'desc'
  ) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });
    return this.http.post<any>(this.config.apiUrl + `/cf/survey/question-metrics?${query.toString()}`, {
      ids,
      metric,
      internal_name,
      _empresa,
      filters,
      filter_any,
      only_finalized: selectedCompletion,
      filters_version: 2,
      group_by_custom_field: group_by_custom_fields,
      isBreakdown: true,
      isGroup,
      sort
    });
  }

  updateQuestionsGroups(surveyId, questions_groups: QuestionGroup[]) {
    return this.http.put<any>(this.config.apiUrl + `/cf/survey/${surveyId}/questions-groups`, { questions_groups });
  }

  removeSurveyFromGroups(surveyId) {
    return this.http.put<any>(this.config.apiUrl + `/cf/survey/${surveyId}/remove-survey-from-groups`, {});
  }

  analyzeAI(surveyId, empresaId) {
    return this.http.get<any>(this.config.apiUrl + `/migration/analyze-ai?survey=${surveyId}&empresa=${empresaId}`);
  }

  getAllByBusiness(_empresa: string) {
    // @ts-ignore
    const query = new URLSearchParams({
      empresa: _empresa
    });

    return this.http.get<{ _id: string; nome: 1; surveys: { _id: string; title: 1 }[] }[]>(
      this.config.apiUrl + `/cf/survey/group-by-business/?${query.toString()}`
    );
  }
}
