import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
@Component({
  selector: 'amp-wysiwyg-input',
  templateUrl: 'amp-wysiwyg-input.component.html',
  styleUrls: ['./amp-wysiwyg-input.component.scss']
})
export class AmpWYSIWYGInputComponent implements AfterViewInit {
  @Input()
  set content(value) {
    this._content = value;
    if (this.contentEditable?.nativeElement) {
      if (this.content != this.contentEditable.nativeElement.innerHTML) {
        this.contentEditable.nativeElement.innerHTML = this.content;
      }
    }
  }
  get content() {
    return this._content;
  }

  @Output()
  contentChange = new EventEmitter<string>();

  @ViewChild('contentEditable') contentEditable: ElementRef;

  @Input()
  public placeholder = '';

  public showToolbar = false;
  public left = 0;
  public top = 0;
  public _content: string;

  ngAfterViewInit() {
    this.contentEditable.nativeElement.innerHTML = this.content;
  }

  saveChanges(evt?: KeyboardEvent) {
    if (evt?.code == 'Space' && !this.content.length) {
      this.contentEditable.nativeElement.innerHTML = '';
    }

    this.contentChange.emit(this.contentEditable.nativeElement.innerHTML);
  }

  onClickContent(event) {
    const selection = window.getSelection();
    const selectionPosition = window.getSelection().getRangeAt(0).getBoundingClientRect();
    if (selection.toString().length > 0) {
      this.showToolbar = true;
      if (document.querySelector('.amp-left-menu') != null) {
        this.top = selectionPosition.top - 36 - 72 + document.querySelector('.amp-left-menu').scrollTop;
        this.left = Math.round(selectionPosition.width / 2) + selectionPosition.left - Math.round(100 / 2);
      } else {
        const element = event.srcElement.offsetParent;
        this.top = element.offsetParent.offsetTop - window.scrollY + 90;
        this.left = Math.round(selectionPosition.width / 2) + selectionPosition.left - Math.round(100 / 2) + window.scrollX - element.offsetLeft;
      }
    }
  }

  applyFormat(formatType: string) {
    if (document.queryCommandSupported && document.queryCommandSupported('bold')) {
      document.execCommand(formatType, false);
      this.showToolbar = false;
      window.getSelection().empty();
      this.saveChanges();
    }
  }

  onPaste(e: any) {
    e.preventDefault();
    const text = (e.originalEvent || e).clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }
}
