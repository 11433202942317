import { ReferralService } from './../../_services/referral/referral.service';
import { CustomerService } from './../../_services/customer.service';
import { NotifyService } from 'app/_services';
import { MenuService } from './../../_services/menu.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from 'app/_services/store.service';
import { MixPanelService } from 'app/_services/mixPanel.service';
import moment from 'moment';
@Component({
  selector: 'app-validar-recompensa-dialog',
  templateUrl: './validar-recompensa-dialog.component.html',
  styleUrls: ['./validar-recompensa-dialog.component.scss']
})
export class ValidarRecompensaDialogComponent implements OnInit, OnDestroy {
  constructor(
    public bsModalRef: BsModalRef,
    private menuService: MenuService,
    private mixPanel: MixPanelService,
    private store: StoreService,
    private referralService: ReferralService,
    private customerService: CustomerService,
    private notifyService: NotifyService
  ) {
    this.menuService.emit(true);
  }

  public subscriptions = [];
  public customer: any = {};
  public rewards: any[] = [];
  public selected: string;
  public typeReferrer: boolean;

  ngOnInit() {
    this.subscriptions.push(
      this.customerService.getRewards(this.customer._id).subscribe((res) => {
        this.rewards = res;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  save() {
    if (this.typeReferrer) {
      this.saveReferrer();
      this.mixPanel.track('Redeem a reward on Referral');
    } else {
      this.validateReferral();
      this.mixPanel.track('Confirm a Referral');
    }
  }

  saveReferrer() {
    this.subscriptions.push(
      this.referralService.redeemReferrer(this.selected, this.store.business._id).subscribe(
        () => {
          this.notifyService.success('Recompensa resgatada com sucesso!');
          this.bsModalRef.hide();
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  validateReferral() {
    this.subscriptions.push(
      this.referralService.validateReferral(this.selected, this.store.business._id).subscribe(
        () => {
          this.notifyService.success('Indicação confirmada com sucesso!');
          this.bsModalRef.hide();
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  selectReward(referral: any, type) {
    if (type) {
      if (!referral.reward_confirmed && !this.isExpired(referral.referrer_expiration)) {
        this.selected = referral._id;
        this.typeReferrer = true;
      }
    } else {
      if (!referral.referral_valid && !this.isExpired(referral.referee_expiration)) {
        this.selected = referral._id;
        this.typeReferrer = false;
      }
    }
  }

  isExpired(dateField: Date) {
    return moment().isAfter(dateField);
  }
}
