import { Campaign } from './../../../_models/referral/campaign';
import { Business } from './../../../_models/business';
import { CampaignService } from './../../../_services/referral/campaign.service';
import { NotifyService, BusinessService } from 'app/_services';
import { MenuService } from 'app/_services/menu.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'app/_services/store.service';
import { SnippetReferralComponent } from 'app/_dialogs/snippet-referral/snippet-referral.component';
import { TemplateService } from 'app/_services/adm/template.service';
import { Template } from 'app/_models/adm/template';
import { MixPanelService } from 'app/_services/mixPanel.service';
import moment from 'moment';
@Component({
  selector: 'app-usuarios-dialog',
  templateUrl: './campaign-dialog.component.html',
  styleUrls: ['./campaign-dialog.component.scss']
})
export class CampaignDialogComponent implements OnInit, OnDestroy {
  constructor(
    public store: StoreService,
    public bsModalRef: BsModalRef,
    private menuService: MenuService,
    private mixPanel: MixPanelService,
    private templateService: TemplateService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private businessService: BusinessService,
    private campaignService: CampaignService,
    private notifyService: NotifyService
  ) {
    this.menuService.emit(true);
  }

  public businessId = '';
  public minDate: Date = moment().toDate();
  public campaign: Campaign = new Campaign();
  public isNew = true;
  public isTemplate = false;
  public fromTemplate = false;
  public template: Template = null;
  public subscriptions = [];
  public text = '';
  public colorsText = ['#383838', '#ffffff', '#0a1f44', '#445571', '#98a1b1'];
  public colors = ['#5885f7', '#4bc490', '#e85d6b', '#98a1b1', '#ffca00', '#0a1f44', '#445571', '#ba68c8', '#dbe5ff', '#ffe373'];
  public primaryColorSelector = false;
  public textColorSelector = false;
  public referralSmsShareFocus = true;
  public allowSaveButton = true;
  public businessesList: Business[];
  public selectAllBusinesses = false;
  public loadingSave = false;
  public step = 0;

  ngOnInit() {
    this.subscriptions.push(
      this.businessService.getMyBusiness(this.store.empresa).subscribe((data) => {
        const t = data;
        this.businessesList = t;
      })
    );
    if ((this.campaign._id && this.isNew) || this.fromTemplate) {
      this.campaign._id = undefined;
      this.campaign.created_at = undefined;
      //@ts-ignore
      this.campaign._business = this.store.business._id;
      this.campaign._businesses = [];
      this.campaign.channels = [];
    }
    if (this.isTemplate && !this.isNew) {
      this.mixPanel.track('Clone Referral Campaign from Template');
      //@ts-ignore
      this.campaign = this.template.json;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  toggleChannel(channel: string) {
    let i;
    if ((i = this.campaign.channels.indexOf(channel)) > -1) {
      this.campaign.channels.splice(i, 1);
    } else {
      this.campaign.channels.push(channel);
    }
  }

  fmCampaignInformationSubmit() {
    this.step = 1;
  }

  fmCampaignRewardsSubmit() {
    this.step = 2;
  }

  fmCampaignWidgetSubmit() {
    this.step = 3;
  }

  fmCampaignEmailSubmit() {
    this.step = 4;
  }

  fmCampaignSmsShareSubmit() {
    this.loadingSave = true;
    //@ts-ignore
    this.campaign._business = this.store.business._id;
    if (!this.verifyFieldsToSubmit() || this.isTemplate) {
      if (this.isTemplate) {
        this.campaign.widget_stats = { click: 0, view: 0, open: 0 };
        this.campaign._businesses = [];
        this.campaign._business = undefined;
        this.template.json = this.campaign;
        this.subscriptions.push(
          this.templateService.update(this.template).subscribe(() => {
            this.notifyService.success('Template Salvo com sucesso!');
            this.loadingSave = false;
            this.bsModalRef.hide();
          })
        );
      } else {
        if (this.isNew) {
          this.subscriptions.push(
            this.campaignService.create(this.campaign).subscribe(
              (data) => {
                this.bsModalRef.hide();
                this.subscriptions.push(
                  this.translate.get('notify.campaign.save_success').subscribe((res) => {
                    this.notifyService.success(res);

                    this.loadingSave = false;
                  })
                );
                //@ts-ignore
                this.campaign = data;

                const initialState = {
                  campaign: this.campaign
                };
                if (this.campaign.channels.includes('widget')) {
                  this.modalService.show(SnippetReferralComponent, {
                    initialState,
                    class: 'modal-lg amp-modal'
                  });
                }
              },
              (error) => {
                console.error(error);
                this.subscriptions.push(
                  this.translate.get('notify.campaign.save_error').subscribe((res) => {
                    this.notifyService.error(`${res} Motivo: ${error.error.message}`);
                    this.loadingSave = false;
                  })
                );
                (error) => {
                  console.error(error);
                };
              }
            )
          );
          this.mixPanel.track('Create New Referral Campaign');
        } else {
          this.subscriptions.push(
            this.campaignService.update(this.campaign).subscribe(
              (data) => {
                this.bsModalRef.hide();
                this.subscriptions.push(
                  this.translate.get('notify.campaign.save_success').subscribe((res) => {
                    this.notifyService.success(res);
                    //@ts-ignore
                    this.campaign = data;

                    this.loadingSave = false;
                    const initialState = {
                      campaign: this.campaign
                    };
                    if (this.campaign.channels.includes('widget')) {
                      this.modalService.show(SnippetReferralComponent, {
                        initialState,
                        class: 'modal-lg amp-modal'
                      });
                    }
                  })
                );
              },
              (error) => {
                console.error(error);
                this.subscriptions.push(
                  this.translate.get('notify.campaign.update_error').subscribe((res) => {
                    this.notifyService.error(res);

                    this.loadingSave = false;
                  })
                );
              }
            )
          );

          this.mixPanel.track('Update Referral Campaign');
        }
      }
    } else {
      this.notifyService.error('Alguns campos não foram preenchidos!');

      this.loadingSave = false;
    }
  }

  changePrimaryColor(evt) {
    this.campaign.primary_color = evt.color.hex;
  }

  changeTextColor(evt) {
    this.campaign.text_color = evt.color.hex;
  }

  togglePickerPrimaryColor() {
    if (this.textColorSelector) {
      this.textColorSelector = false;
    }
    this.primaryColorSelector = !this.primaryColorSelector;
  }

  togglePickerTextColor() {
    if (this.primaryColorSelector) {
      this.primaryColorSelector = false;
    }
    this.textColorSelector = !this.textColorSelector;
  }

  toggleBusiness(_id: string) {
    this.selectAllBusinesses = false;
    let i;
    //@ts-ignore
    if ((i = this.campaign._businesses.indexOf(_id)) > -1) {
      this.campaign._businesses.splice(i, 1);
    } else {
      //@ts-ignore
      this.campaign._businesses.push(_id);
    }

    if (this.campaign._businesses.length == this.businessesList.length) {
      this.selectAllBusinesses = true;
    }
  }

  selectAllBusiness() {
    if (!this.selectAllBusinesses) {
      //@ts-ignore
      this.campaign._businesses = this.businessesList.map((b) => b._id);
      this.selectAllBusinesses = true;
    } else {
      this.campaign._businesses = [];
      this.selectAllBusinesses = false;
    }
  }

  verifyFieldsToSubmit() {
    if (
      this.campaign.title.length == 0 ||
      this.campaign.description.length == 0 ||
      this.campaign.channels.length == 0 ||
      this.campaign._businesses.length == 0 ||
      this.campaign.share_message.length == 0 ||
      this.campaign.reward_referee.instructions.length == 0 ||
      this.campaign.reward_referee.name.length == 0 ||
      this.campaign.reward_referrer.instructions.length == 0 ||
      this.campaign.reward_referrer.name.length == 0 ||
      this.campaign.verification_mode.length == 0
    ) {
      return true;
    }

    if (
      this.campaign.text_color.length == 0 ||
      this.campaign.primary_color.length == 0 ||
      this.campaign.content_about.length == 0 ||
      this.campaign.content_refer.length == 0 ||
      this.campaign.content_coupons.length == 0
    ) {
      return true;
    }

    if (this.campaign.channels.includes('widget')) {
      if (this.campaign.widget_position != 'left' && this.campaign.widget_position != 'right') {
        return true;
      }
      if (this.campaign.widget_url.length == 0) {
        return true;
      }
    }

    if (this.campaign.channels.includes('e-mail')) {
      if (this.campaign.email_message_1.length == 0 || this.campaign.email_message_2.length == 0) {
        return true;
      }
    }

    if (this.campaign.channels.includes('sms')) {
      if (this.campaign.sms_message.length == 0) {
        return true;
      }
    }

    return false;
  }
}
