import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'amp-smart-tags-bar',
  templateUrl: './smart-tags-bar.component.html',
  styleUrls: ['./smart-tags-bar.component.scss']
})
export class SmartTagsBarComponent implements OnInit {
  _promoter = 1 / 3;
  _neutral = 1 / 3;
  _detractor = 1 / 3;
  _score = 1;
  _metric = '';
  _max = 0;

  get promoter() {
    return this._promoter;
  }
  @Input('promoter') set promoter(value) {
    this._promoter = value;
    this.check();
  }
  get neutral() {
    return this._neutral;
  }
  @Input('neutral') set neutral(value) {
    this._neutral = value;
    this.check();
  }
  get detractor() {
    return this._detractor;
  }
  @Input('detractor') set detractor(value) {
    this._detractor = value;
    this.check();
  }
  get score() {
    return this._score;
  }
  @Input('score') set score(value) {
    this._score = value;
    this.check();
  }
  get metric() {
    return this._metric;
  }
  @Input('metric') set metric(value) {
    this._metric = value;
    this.check();
  }
  get max() {
    return this._max;
  }
  @Input('max') set max(value) {
    this._max = value;
    this.check();
  }

  @Input('tag') tag: any = '';
  public promoterPercentage;
  public neutralPercentage;
  public detractorPercentage;
  public barPercentage;

  public allZero = false;

  @Output() actionsClick = new EventEmitter();

  ngOnInit() {
    this.check();
  }

  check() {
    if (this.promoter == 0 && this.neutral == 0 && this.detractor == 0) {
      this.allZero = true;
    }
    this.promoterPercentage = this.promoter / (this.promoter + this.detractor + this.neutral);
    this.neutralPercentage = this.neutral / (this.promoter + this.detractor + this.neutral);
    this.detractorPercentage = this.detractor / (this.promoter + this.detractor + this.neutral);
    this.convertRange();
  }

  convertRange() {
    if (this.metric == 'nps') {
      this.barPercentage = ((this.score + 100) * 90) / 200;
    } else if (this.metric == 'ces') {
      this.barPercentage = ((this.score - 1) * 90) / (7 - 1);
    } else if (this.metric == 'csat' || this.metric == 'thumbs') {
      this.barPercentage = (this.score * 90) / 100;
    } else if (this.metric == 'topics') {
      this.barPercentage = (this.score / this.max) * 100;
    } else {
      this.barPercentage = (this.score / this.max) * 100;
    }
  }

  redirectToAnswers(value) {
    this.actionsClick.emit(value);
  }
}
