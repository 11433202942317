import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
@Injectable()
export class AdmService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getRequestLogByEmpresa(_empresa, limit = 10, skip = 0, statusCodeFilter, methodFilter, urlFilter) {
    const query = new URLSearchParams();
    query.set('limit', limit.toString());
    query.set('skip', skip.toString());
    if (statusCodeFilter?.length > 0) {
      query.set('statusCode', statusCodeFilter);
    }
    if (methodFilter?.length > 0) {
      query.set('method', methodFilter);
    }
    if (urlFilter?.length > 0) {
      query.set('url', urlFilter);
    }
    return this.http.get<[]>(this.config.apiUrl + `/adm/system-log/${_empresa}?${query}`);
  }

  getInvoices(taxId) {
    return this.http.get<[]>(this.config.apiUrl + `/adm/invoices/?taxId=${taxId}`);
  }

  getCustomerData(taxId) {
    return this.http.get<[]>(this.config.apiUrl + `/adm/customer/?taxId=${taxId}`);
  }

  updateCustomerData(id, data) {
    return this.http.put<any>(this.config.apiUrl + `/adm/customer/${id}`, data);
  }

  getInvoicePdf(invoiceId, taxId) {
    return this.http.get<[]>(this.config.apiUrl + `/adm/invoices/${invoiceId}/pdf?taxId=${taxId}`);
  }

  getInvoiceBoleto(invoiceId, taxId) {
    return this.http.get<[]>(this.config.apiUrl + `/adm/invoices/${invoiceId}/boleto?taxId=${taxId}`);
  }

  getAllBatchOps() {
    return this.http.get<[]>(this.config.apiUrl + `/adm/batch-ops`);
  }

  getEmpresaSurveys(empresaId) {
    return this.http.get<[]>(this.config.apiUrl + `/adm/acmng/empresa/${empresaId}/surveys`);
  }

  listTemporaryAccess() {
    return this.http.get<[]>(this.config.apiUrl + `/adm/acmng/temporaryAccess`);
  }

  createTemporaryAccess(data) {
    return this.http.post<any>(this.config.apiUrl + `/adm/acmng/temporaryAccess`, data);
  }

  deleteTemporaryAccess(id) {
    return this.http.delete<any>(this.config.apiUrl + `/adm/acmng/temporaryAccess/${id}`);
  }

  listUsers() {
    return this.http.get<any[]>(this.config.apiUrl + `/adm/acmng/users`);
  }
}
