import { HttpClient } from '@angular/common/http';
import { Business } from 'app/_models/business';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
@Injectable()
export class BusinessService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll() {
    return this.http.get<Business[]>(this.config.apiUrl + '/business');
  }

  getMyBusiness(_empresa: string) {
    return this.http.get<any>(this.config.apiUrl + '/business/empresa/' + _empresa);
  }

  getMyBusinessToEdit(id: string) {
    return this.http.get<Business>(this.config.apiUrl + '/business/edit/' + id);
  }

  getById(_id: string) {
    return this.http.get<Business>(this.config.apiUrl + '/business/' + _id);
  }

  getPublicData(_id: string) {
    return this.http.get<Business>(this.config.apiUrl + '/business/public/' + _id);
  }

  create(business: Business) {
    return this.http.post(this.config.apiUrl + '/business/', business);
  }

  update(business: Business | any) {
    return this.http.put<Business>(this.config.apiUrl + '/business/' + business._id, business);
  }

  delete(_id: string) {
    return this.http.delete<Business>(this.config.apiUrl + '/business/' + _id);
  }

  getGallery(_id: string) {
    return this.http.get<string[]>(this.config.apiUrl + '/business/' + _id + '/gallery');
  }

  addImageToGallery(_id: string, imageUrl: string) {
    return this.http.post<any>(this.config.apiUrl + '/business/' + _id + '/gallery', { imageUrl });
  }

  removeImageFromGallery(_id: string, imageUrl: string) {
    return this.http.patch<any>(this.config.apiUrl + '/business/' + _id + '/gallery', { imageUrl });
  }

  reloadSmsBalance(_business, smsBalance, minimumBalance?) {
    return this.http.put<any>(this.config.apiUrl + `/business/${_business}/sms-reload`, { smsBalance, minimumBalance });
  }

  reloadSmsBalanceClient(_business, smsBalance, minimumBalance?) {
    return this.http.put<any>(this.config.apiUrl + `/business/${_business}/sms-reload-client`, { smsBalance, minimumBalance });
  }

  getBusinessInsights(businessId) {
    return this.http.get<any>(this.config.apiUrl + `/business/insights/${businessId}`);
  }

  getActiveCampaignList(businessId, acName, acToken) {
    const query = new URLSearchParams({
      acName,
      acToken
    });

    return this.http.get<any>(this.config.apiUrl + `/business/${businessId}/active_campaign_list?` + query.toString());
  }

  checkAccountUsage(businessId) {
    return this.http.get<any>(this.config.apiUrl + `/business/${businessId}/account-usage`);
  }

  checkAccountsUsage(sortBy = 'referral') {
    const query = new URLSearchParams({
      sortBy
    });

    return this.http.get<any>(this.config.apiUrl + `/business/account-usage?${query.toString()}`);
  }

  getBusinessBalance(businessId, full_history = false) {
    return this.http.get<any>(this.config.apiUrl + `/business/${businessId}/historyBalance?full_history=${full_history}`);
  }

  getBusinessBalancesAdm(period = null, type) {
    return this.http.get<any>(this.config.apiUrl + `/business/historyBalance?type=${type}&period=${period}`);
  }

  getBusinessBalancesByEmpresa(period = null, type, empresaId?) {
    return this.http.get<any>(this.config.apiUrl + `/business/historyBalance?type=${type}&period=${period}&empresaId=${empresaId}`);
  }

  updateCurrentBusinessBalance(businessId, newBalance, type = 'cf_responses') {
    return this.http.put<any>(this.config.apiUrl + `/business/${businessId}/historyBalance/current?type=${type}`, { limit: newBalance });
  }

  setIntegrationCMNet(businessId, totvsParams) {
    return this.http.put<any>(this.config.apiUrl + `/business/${businessId}/integration/cmnet`, totvsParams);
  }

  putManyBusinessBalances(businessesToUpdate) {
    return this.http.put<any>(this.config.apiUrl + `/business/multi/historyBalance`, { businesses: businessesToUpdate });
  }

  putManyBusiness(businessesIds, fields) {
    return this.http.put<any>(this.config.apiUrl + `/business/many`, { businesses: businessesIds, fields });
  }

  addCustomEmailDomain(businessId, subdomain, domain, sender, custom_dkim_selector = undefined) {
    return this.http.post<any>(this.config.apiUrl + `/business/${businessId}/custom-email-domain`, { subdomain, domain, sender, custom_dkim_selector });
  }

  validateCustomEmailDomain(businessId) {
    return this.http.get<any>(this.config.apiUrl + `/business/${businessId}/custom-email-domain/validate`);
  }

  getCustomEmailDomain(businessId) {
    return this.http.get<any>(this.config.apiUrl + `/business/${businessId}/custom-email-domain`);
  }

  deleteCustomEmailDomain(businessId) {
    return this.http.delete<any>(this.config.apiUrl + `/business/${businessId}/custom-email-domain`);
  }
}
