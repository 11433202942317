import { Component, OnDestroy } from '@angular/core';
import { Dashboard, DashboardCard, DashboardReviewCard } from 'app/_models/dashboard';
import { NotifyService } from 'app/_services';
import { ReviewPlatformService } from 'app/_services/reviewPlatform.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-rename-platform-dialog',
  templateUrl: './rename-platform-dialog.component.html',
  styleUrls: ['./rename-platform-dialog.component.scss']
})
export class RenamePlatformDialogComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef, private notifyService: NotifyService, public reviewPlatformService: ReviewPlatformService) {}

  public dashboard: Dashboard = new Dashboard();
  public subscriptions = [];
  public result: Subject<any> = new Subject();
  public card: DashboardCard;
  public platform;
  public reviewCard: DashboardReviewCard;

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  fmSubmit() {
    this.subscriptions.push(
      this.reviewPlatformService.update(this.platform).subscribe(
        () => {
          this.notifyService.success('Plataforma renomeada com sucesso!');
          this.bsModalRef.hide();
        },
        (error) => {
          console.error(error);
          this.notifyService.error('Houve um erro ao renomear a plataforma, tente novamente.');
        }
      )
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
