<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      {{ title }}
    </div>
    <div class="amp-dialog-subtitle">{{ message }}</div>
    <span class="amp-dialog-close bi-x-lg" (click)="cancelar()"></span>
  </div>
  
  <div class="amp-dialog-body">
    <amp-select *ngIf="options" [placeholder]="placeholder" [isSearchable]="isSearchable" [options]="options" [(value)]="model"></amp-select>
    <amp-select *ngIf="!options" [placeholder]="placeholder" [isSearchable]="isSearchable" [optionsObservable]="optionsObservable" [(value)]="model"></amp-select>
  </div>

  <div class="footer">
    <button class="amp-btn-outline-cancel" (click)="cancelar()">{{ cancelName }}</button>
    <button [disabled]="!model" class="amp-btn-success" (click)="confirmar()">{{ buttonName }}</button>
  </div>