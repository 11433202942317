<div class="amp-wrapper">

  <!-- Left Arrow -->
  <div *ngIf="showBackArrow && !publicView" [ngClass]="{ animate__animated: leftClicked, animate__bounceIn: leftClicked }" tooltip="Resposta Anterior (Seta Esquerda no Teclado)" class="amp-left-arrow" (click)="previousAnswer()">
    <i class="bi-chevron-left"></i>
  </div>

  <!-- Top Bar -->
  <div placement="right" container="body" [adaptivePosition]="true" boundariesElement="viewport" triggers="mouseenter:mouseleave" [popover]="ampliscorePopover" [popoverContext]="{ data: feedback.score }" class="amp-top-bar" [ngClass]="{
      'bg-green': feedback.score >= 76 && feedback.score <= 100,
      'bg-yellow': feedback.score >= 0 && feedback.score <= 50,
      'bg-red': feedback.score < 0,
      'bg-blue': feedback.score == null || (feedback.score >= 51 && feedback.score <= 75)
    }"></div>

  <!-- Close Button -->
  <i *ngIf="!this.publicView" (click)="closeModal()" class="bi-x-lg close-button"></i>

  <!-- Content -->
  <div class="amp-dialog">
    <div class="header">
      <div class="details">
        <div class="amp-card-score" *ngIf="getAnswerFromQuestion(feedback.survey?.questions[0], feedback) != undefined">
          <span *ngIf="feedback.survey?.questions[0].type == 'nps'" class="metric-score" [ngClass]="{
                good: getAnswerFromQuestion(feedback.survey.questions[0], feedback) >= 9,
                neutral:
                  getAnswerFromQuestion(feedback.survey.questions[0], feedback) == 7 || getAnswerFromQuestion(feedback.survey.questions[0], feedback) == 8
              }">
            <div class="score">
              {{ getAnswerFromQuestion(feedback.survey.questions[0], feedback) }}
            </div>
            <div class="metric">
              NPS
            </div>
          </span>
          <span *ngIf="feedback.survey?.questions[0].type == 'likert'" class="likert metric-score">
            <div class="score">
              {{ getAnswerFromQuestion(feedback.survey.questions[0], feedback) }}
            </div>
            <div class="metric">
              LIKERT
            </div>
          </span>
          <span *ngIf="feedback.survey?.questions[0].type == 'ces'" class="metric-score" [ngClass]="{
                good: getAnswerFromQuestion(feedback.survey.questions[0], feedback) >= 6,
                neutral:
                  getAnswerFromQuestion(feedback.survey.questions[0], feedback) == 5 || getAnswerFromQuestion(feedback.survey.questions[0], feedback) == 4
              }">
            <div class="score">
              {{ getAnswerFromQuestion(feedback.survey.questions[0], feedback) }}
            </div>
            <div class="metric">
              CES
            </div>
          </span>
          <span *ngIf="feedback.survey?.questions[0].type == 'csat'" class="metric-score" [ngClass]="{
                good: getAnswerFromQuestion(feedback.survey.questions[0], feedback) >= 4,
                neutral: getAnswerFromQuestion(feedback.survey.questions[0], feedback) == 3
              }">
            <div class="score">
              {{ getAnswerFromQuestion(feedback.survey.questions[0], feedback) }}
            </div>
            <div class="metric">
              CSAT
            </div>
          </span>

          <img *ngIf="feedback.survey?.questions[0].type == 'thumbs' && getAnswerFromQuestion(feedback.survey.questions[0], feedback) == 'Liked'" src="https://cdn.amplifique.me/email_assets/thumbs_up_active.png" />
          <img *ngIf="feedback.survey?.questions[0].type == 'thumbs' && getAnswerFromQuestion(feedback.survey.questions[0], feedback) == 'Disliked'" src="https://cdn.amplifique.me/email_assets/thumbs_down_active.png" />

          <i *ngIf="
                feedback.survey?.questions[0].type != 'nps' &&
                feedback.survey?.questions[0].type != 'ces' &&
                feedback.survey?.questions[0].type != 'likert' &&
                feedback.survey?.questions[0].type != 'csat' &&
                feedback.survey?.questions[0].type != 'thumbs'
              " class="bi-chat"></i>
        </div>

        <div class="amp-card-score" *ngIf="getAnswerFromQuestion(feedback.survey?.questions[0], feedback) == undefined">
          <i class="bi-chat"></i>
        </div>

        <div class="amp-card-infos">
          <div class="amp-card-user">
            <span *ngIf="(feedback.survey?.anonymous) && !feedback.anonymized">Anônimo</span>
            <span *ngIf="!(feedback.survey?.anonymous) && !feedback.anonymized && feedback.customer && !feedback.customer?.name && !feedback.customer?.email">Contato sem nome</span>
            <span *ngIf="feedback.anonymized">Anonimizado</span>
            <span *ngIf="!feedback?.customer && !feedback.survey?.anonymous && !feedback.anonymized">Contato
              Excluído</span>
            <span>{{
              (feedback.customer && !feedback.survey?.anonymous && !feedback.anonymized)
              ? feedback.customer?.name
              ? (feedback.customer?.name | lgpd)
              : (feedback.customer?.email | lgpd)
              : ''
              }}</span>
              <div class="icons">
                <i class="bi-x-circle-fill" tooltip="Esta pessoa não autorizou o contato."
                *ngIf="!feedback.survey?.anonymous && feedback.channel != 4 && feedback.survey.show_optin_contact_button && !feedback.contact_optin"></i>
                <i class="bi-exclamation-triangle-fill" tooltip="Esta resposta está incompleta."
                *ngIf="!feedback.finalized"></i>
                <i class="bi-patch-check-fill" tooltip="Esta pessoa autorizou o contato."
                *ngIf="!feedback.survey?.anonymous && feedback.channel != 4 && feedback.survey.show_optin_contact_button && feedback.contact_optin"></i>
                <i class="bi-ticket" tooltip="Esta resposta possui um Ticket criado." *ngIf="feedback.has_ticket"></i>
              </div>
          </div>
          <div *ngIf="!feedback.survey?.anonymous" class="amp-card-contact">
            <span class="phone-cell"><span *ngIf="feedback.customer?.tel?.length > 5" [class]="'phone-flag iti__flag iti__' + (feedback.customer?.tel | phone: true)"></span>{{
              feedback.customer ? (feedback.customer?.tel | phone | lgpd) : '' }}</span>
            <span *ngIf="feedback.customer?.tel?.length > 5 && feedback.customer?.email">&nbsp;|&nbsp;</span>
            <span>{{ feedback.customer ? (feedback.customer?.email | lgpd) : '' }}</span>
          </div>
        </div>
        <div class="amp-card-details">
          <div class="amp-card-campaign">
            <span>{{ feedback.survey ? feedback.survey?.title : '' }}</span>
            <span *ngIf="feedback.channel !== undefined && feedback.survey && feedback.survey?.title"> | </span>
            <span class="feedback-origin" *ngIf="feedback.channel !== undefined">{{
              'amp-channel-' + feedback.channel | translate
              }}</span>
          </div>
          <span class="amp-origin" *ngIf="feedback.origin">{{ feedback.origin }}</span>
          <div class="amp-card-date">
            <span>{{ feedback.answered_at | amDateFormat: 'DD/MM/YYYY HH:mm' }}</span>
          </div>
        </div>
      </div>
      <div class="insights">
        <div *ngIf="feedback.ai?.summary" class="amp-card-addons">
          <div class="type" [ngClass]="getTypeColor(feedback.ai.type)">
            <i *ngIf="feedback.ai.type == 0" class="bi-heart"></i>
            <i *ngIf="feedback.ai.type == 1" class="bi-emoji-angry"></i>
            <i *ngIf="feedback.ai.type == 2" class="bi-question-circle"></i>
            <i *ngIf="feedback.ai.type == 3" class="bi-lightbulb"></i>
            <i *ngIf="feedback.ai.type == 4" class="bi-three-dots"></i>
            <span>{{ getAnswerType(feedback.ai.type) }}</span>
          </div>
          <div class="severity" [ngClass]="getSeverityColor(feedback.ai.severity)">
            <i *ngIf="feedback.ai.severity == 0" class="bi-check2-all"></i>
            <i *ngIf="feedback.ai.severity == 1" class="bi-eye"></i>
            <i *ngIf="feedback.ai.severity == 2" class="bi-search"></i>
            <i *ngIf="feedback.ai.severity == 3" class="bi-exclamation-circle-fill"></i>
            <span>severidade {{ getAnswerSeverity(feedback.ai.severity) }}</span>
          </div>
          <div class="sentiment" [ngClass]="getSentimentColor(feedback.ai.sentiment)">
            <i *ngIf="feedback.ai.sentiment == 0" class="bi-emoji-angry"></i>
            <i *ngIf="feedback.ai.sentiment == 1" class="bi-emoji-neutral"></i>
            <i *ngIf="feedback.ai.sentiment == 2" class="bi-emoji-smile"></i>
            <span>{{ getAnswerSentiment(feedback.ai.sentiment) }}</span>
          </div>
        </div>
      </div>
      <div class="tabs">
        <div class="tab" [ngClass]="{active: tab == 0}" (click)="tab = 0">Respostas</div>
        <div class="tab" [ngClass]="{active: tab == 1}" (click)="tab = 1">Dados</div>
        <div class="tab" [ngClass]="{active: tab == 2}" (click)="tab = 2; getCustomer(feedback.customer?._id || feedback._customer)" *ngIf="!feedback.anonymized && feedback.customer && !feedback.survey.anonymous">Contato</div>
        <div class="tab" [ngClass]="{active: tab == 3}" (click)="tab = 3; getTickets()" *ngIf="store.empresaData?.general_attributes?.allow_ticketing">Fechamento de Loop</div>
        <div class="tab" [ngClass]="{active: tab == 4}" (click)="tab = 4" *ngIf="store.business?.cf_attributes?.cf_ai">
          <i class="bi-magic"></i>Insights com AI
        </div>
        <div class="tab" [ngClass]="{active: tab == 5}" (click)="tab = 5">Anotações</div>
        <div class="tab" [ngClass]="{active: tab == 6}" (click)="tab = 6" *ngIf="feedback.survey?.protocol_mode_params?.enabled">Protocolo</div>
      </div>
    </div>
    <div class="body">
      <div class="answers" *ngIf="tab == 0">
        <ng-container *ngFor="let question of feedback.survey?.questions; let i = index">
          <div class="card" *ngIf="!showBlankAnswers ? getAnswerFromQuestion(question, feedback) !== undefined && getAnswerFromQuestion(question, feedback) !== '' : true">
            <div class="title">
              {{ i + 1 }}. <span [innerHTML]="getQuestionVariation(question, false) | interpolation:feedback.custom_fields:feedback.customer?.custom_fields:feedback.customer"></span> ({{
              'survey-type-' + question.type | translate }})
            </div>
            <div class="nps" *ngIf="question.type == 'nps'">
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) === 0}">0</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 1}">1</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 2}">2</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 3}">3</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 4}">4</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 5}">5</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 6}">6</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 7}">7</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 8}">8</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 9}">9</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 10}">10</div>
            </div>
            <div class="csat" *ngIf="question.type == 'csat'">
              <div class="default" *ngIf="!question.customScale">
                <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 1}">1</div>
                <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 2}">2</div>
                <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 3}">3</div>
                <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 4}">4</div>
                <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 5}">5</div>
              </div>
              <div class="emotes" *ngIf="question.customScale && question.scaleTheme == 'emotes'">
                <i class="bi-emoji-angry" *ngIf="getAnswerFromQuestion(question, feedback) != 1"></i>
                <i class="bi-emoji-angry-fill" *ngIf="getAnswerFromQuestion(question, feedback) == 1"></i>
                <i class="bi-emoji-frown" *ngIf="getAnswerFromQuestion(question, feedback) != 2"></i>
                <i class="bi-emoji-frown-fill" *ngIf="getAnswerFromQuestion(question, feedback) == 2"></i>
                <i class="bi-emoji-neutral" *ngIf="getAnswerFromQuestion(question, feedback) != 3"></i>
                <i class="bi-emoji-neutral-fill" *ngIf="getAnswerFromQuestion(question, feedback) == 3"></i>
                <i class="bi-emoji-smile" *ngIf="getAnswerFromQuestion(question, feedback)  != 4"></i>
                <i class="bi-emoji-smile-fill" *ngIf="getAnswerFromQuestion(question, feedback) == 4"></i>
                <i class="bi-emoji-laughing" *ngIf="getAnswerFromQuestion(question, feedback) != 5"></i>
                <i class="bi-emoji-laughing-fill" *ngIf="getAnswerFromQuestion(question, feedback) == 5"></i>
              </div>
              <div class="hearts" *ngIf="question.customScale && question.scaleTheme == 'hearts'">
                <i class="bi-heart-fill" *ngFor="let i of [].constructor(getAnswerFromQuestion(question, feedback))"></i>
                <i class="bi-heart" *ngFor="let i of [].constructor((5 - getAnswerFromQuestion(question, feedback)))"></i>
              </div>
              <div class="stars" *ngIf="question.customScale && question.scaleTheme == 'stars'">
                <i class="bi-star-fill" *ngFor="let i of [].constructor(getAnswerFromQuestion(question, feedback))"></i>
                <i class="bi-star" *ngFor="let i of [].constructor((5 - getAnswerFromQuestion(question, feedback)))"></i>
              </div>
            </div>
            <div class="ces" *ngIf="question.type == 'ces'">
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 1}">1</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 2}">2</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 3}">3</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 4}">4</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 5}">5</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 6}">6</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 7}">7</div>
            </div>
            <div class="likert" *ngIf="question.type == 'likert'">
              <div class="square" *ngIf="question.typeScale == '0-5' || question.typeScale == '0-10'" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 0}">0</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 1}">1</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 2}">2</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 3}">3</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 4}">4</div>
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 5}">5</div>
              <div class="square" *ngIf="question.typeScale == '1-10' || question.typeScale == '0-10'" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 6}">6</div>
              <div class="square" *ngIf="question.typeScale == '1-10' || question.typeScale == '0-10'" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 7}">7</div>
              <div class="square" *ngIf="question.typeScale == '1-10' || question.typeScale == '0-10'" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 8}">8</div>
              <div class="square" *ngIf="question.typeScale == '1-10' || question.typeScale == '0-10'" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 9}">9</div>
              <div class="square" *ngIf="question.typeScale == '1-10' || question.typeScale == '0-10'" [ngClass]="{selected: getAnswerFromQuestion(question, feedback) == 10}">10</div>
            </div>
            <div class="unique" *ngIf="question.type == 'unique'">
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback).trim() == option.trim()}" *ngFor="let option of question.options">{{ option }}</div>

              <div class="square selected" *ngFor="let item of getOtherAnswers(question, feedback)">
                {{ item }}
              </div>
            </div>
            <div class="multiple" *ngIf="question.type == 'multiple'">
              <div class="square" [ngClass]="{selected: checkQuestionMultiple(question, option)}" *ngFor="let option of question.options">{{ option }}</div>

              <div class="square selected" *ngFor="let item of getOtherAnswers(question, feedback)">
                {{ item }}
              </div>
            </div>
            <div class="select" *ngIf="question.type == 'select'">
              <div class="square" [ngClass]="{selected: getAnswerFromQuestion(question, feedback).trim() == option.trim()}" *ngFor="let option of question.options">{{ option }}</div>
            </div>
            <div class="text" *ngIf="question.type == 'text'">
              {{ getAnswerFromQuestion(question, feedback).length > 0 && getAnswerFromQuestion(question, feedback) != '
              '?
              getAnswerFromQuestion(question, feedback) : '\<resposta em branco\>' }}
            </div>
            <div class="thumbs" *ngIf="question.type == 'thumbs'">
              <div class="up" *ngIf="getAnswerFromQuestion(question, feedback) == 'Liked'">
                <img src="https://cdn.amplifique.me/email_assets/thumbs_up_active.png">
                Like
              </div>
              <div class="down" *ngIf="getAnswerFromQuestion(question, feedback) == 'Disliked'">
                <img src="https://cdn.amplifique.me/email_assets/thumbs_down_active.png">
                Dislike
              </div>
            </div>
            <div class="matrix" *ngIf="question.type == 'matrix'">
              <div class="table">
                <div class="header">
                  <div class="blank"></div>
                  <div class="columns">
                    <div class="title" *ngFor="let column of question.columns">{{ column }}</div>
                  </div>
                </div>
                <div class="rows">
                  <div class="row" *ngFor="let row of question.rows; let idxRow = index">
                    <div class="question">{{ row.question }}</div>
                    <div class="options">
                      <label class="amp-checkbox" *ngFor="let itemColumn of question.columns; let idx = index">
                        <input type="radio" disabled [checked]="checkQuestionMatrix(question, row.identifier, itemColumn)" name="answerMatrix-{{ question.internal_name }}-{{ idxRow }}">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="data" *ngIf="tab == 1">
        <div class="left">
          <div class="answer">
            <div class="title">Resposta</div>
            <div class="line">
              <div>ID</div>
              <div>{{ feedback._id }}</div>
            </div>
            <div class="line">
              <div>Aberto em</div>
              <div>{{ feedback.opened_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm' }}</div>
            </div>
            <div class="line">
              <div>Respondido em</div>
              <div>{{ feedback.answered_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm' }}</div>
            </div>
            <div class="line">
              <div>Finalizado em</div>
              <div>{{ feedback.finalized_at ? (feedback.finalized_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm') : '-' }}</div>
            </div>
            <div class="line">
              <div>Tentativa Respondida</div>
              <div>{{ feedback.attemptAnswered ?? '-' }}</div>
            </div>
            <div class="line">
              <div>Tempo para Responder</div>
              <div>{{ (feedback.time_to_answer | transformDuration) ?? '-' }}</div>
            </div>
            <div class="line">
              <div>Opt-in</div>
              <div>Sim</div>
            </div>
          </div>
          <div class="request">
            <div class="title">Dados da Solicitação</div>
            <div class="line">
              <div>Solicitado em</div>
              <div>{{ feedback.created_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm' }}</div>
            </div>
            <div class="line">
              <div>Usuário</div>
              <div>{{ feedback.user?.nome ?? '-' }}</div>
            </div>
            <div class="line">
              <div>Canal</div>
              <div>{{ 'amp-channel-' + feedback.channel | translate }}</div>
            </div>
            <div class="line">
              <div>Origem</div>
              <div>{{ feedback.origin?.length ? feedback?.origin : '-' }}</div>
            </div>
            <div class="line">
              <div>Tentativas</div>
              <div>{{ feedback.attempts }}</div>
            </div>
          </div>
          <div class="metadata">
            <div class="title">Metadados da Resposta</div>
            <div class="line">
              <div>Navegador</div>
              <div>{{ feedback.metadata?.browser ?? '-' }}</div>
            </div>
            <div class="line">
              <div>Plataforma</div>
              <div>{{ feedback.metadata?.platform ?? '-' }}</div>
            </div>
            <div class="line">
              <div>Sistema Operacional</div>
              <div>{{ feedback.metadata?.os ?? '-' }}</div>
            </div>
            <div class="line">
              <div>IP de Origem</div>
              <div>{{ feedback.originIp ?? '-' }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">Campos Customizados da Resposta</div>
          <ng-container *ngIf="feedback?.custom_fields">
            <div class="line" *ngFor="let custom_field of Object.keys(feedback?.custom_fields)">
              <div>{{ custom_field }}</div>
              <div>{{ feedback.custom_fields[custom_field] | lgpd }}</div>
            </div>
          </ng-container>
          <i *ngIf="!feedback?.custom_fields" class="not-found">Nenhum dado para exibir.</i>
        </div>
      </div>
      <div class="contact" *ngIf="tab == 2">
        <div class="left">
          <div class="info" [ngClass]="{'remove-border': !customer?.customer?.tags?.length}">
            <div class="title">Dados do Contato
              <i container="body" boundariesElement="viewport" tooltip="Clique para abrir o contato em uma nova aba" (click)="goToContact(feedback._customer)" class="bi-arrow-up-right"></i>
            </div>
            <div class="line">
              <div>Nome</div>
              <div>{{ customer?.customer?.name | lgpd }}</div>
            </div>
            <div class="line">
              <div>E-mail</div>
              <div>{{ (customer?.customer?.email ?? '-') | lgpd }}</div>
            </div>
            <div class="line">
              <div>Telefone</div>
              <div>{{ customer?.customer?.tel?.length ? (customer?.customer?.tel | phone | lgpd) : '-' }}</div>
            </div>
            <div class="line">
              <div>Empresa</div>
              <div>{{ (customer?.customer?._company?.name | lgpd) ?? '-' }}</div>
            </div>
            <div class="line">
              <div>Data de Criação</div>
              <div>{{ customer?.customer?.created_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY HH:mm' }}</div>
            </div>
          </div>
          <div class="tags" *ngIf="customer?.customer?.tags?.length">
            <div class="title">Tags do Contato</div>
            <div class="list">
              <div class="tag" *ngFor="let tag of customer?.customer?.tags">
                {{ tag._tag?.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="custom-fields">
            <div class="title">Campos Customizados do Contato
              <i container="body" boundariesElement="viewport" tooltip="Clique para abrir o contato em uma nova aba" (click)="goToContact(feedback._customer)" class="bi-arrow-up-right"></i>
            </div>
            <ng-container *ngIf="customer?.customer?.custom_fields">
              <div class="line" *ngFor="let custom_field of Object.keys(customer?.customer?.custom_fields)">
                <div>{{ custom_field }}</div>
                <div>{{ customer?.customer?.custom_fields[custom_field] | lgpd }}</div>
              </div>
            </ng-container>
            <i *ngIf="!customer?.customer?.custom_fields" class="not-found">Nenhum dado para exibir.</i>
          </div>
        </div>
      </div>
      <div class="loop" *ngIf="tab == 3">
        <div class="ticket" *ngFor="let ticket of tickets" (click)="openTicket(ticket)">
          <div *ngIf="!ticket.hide" class="content">
            <div [ngClass]="{'unread': ticket.has_unread_activities}" class="border"></div>
            <div class="title">{{ ticket.subject }}</div>
            <div class="info">
              <div container="body" boundariesElement="viewport" tooltip="Data de Vencimento"><i class="bi-calendar-event"></i>{{ ticket.due_at | amLocale: 'pt-BR' | amDateFormat: 'DD/MM/YYYY' }}
              </div>
              <div container="body" boundariesElement="viewport" tooltip="Última Alteração"><i class="bi-arrow-counterclockwise"></i>{{ ticket.last_activity_at | amLocale: 'pt-BR' | amDateFormat:
                'DD/MM/YYYY HH:mm' }}</div>
              <div container="body" boundariesElement="viewport" tooltip="Status"><i class="bi-check-circle"></i>{{
                getStatus(ticket.status) }}</div>
              <div container="body" boundariesElement="viewport" tooltip="Etapa"><i class="bi-funnel"></i>{{
                getStep(ticket) }}</div>
              <div container="body" boundariesElement="viewport" tooltip="Fluxo"><i class="bi-ticket-perforated"></i>{{
                ticket._pipeline.name }}</div>
            </div>
            <img *ngIf="ticket._assigned_to?.picture" container="body" boundariesElement="viewport" [tooltip]="ticket._assigned_to.nome" [src]="ticket._assigned_to?.picture">
          </div>
          <div *ngIf="ticket.hide" class="not-allowed">
            <span><i class="bi-lock"></i>Ticket Bloqueado</span>
          </div>
        </div>
        <div *ngIf="!tickets.length" class="no-ticket">
          <span>Esta resposta não está atribuída a nenhum Ticket.</span>
          <button class="amp-btn-primary" (click)="createNewTicket()">Criar Ticket</button>
        </div>
      </div>
      <div class="ai" *ngIf="tab == 4">
        <div class="line-1">
          <div class="title">Resumo dos Textos<i class="bi-magic"></i></div>
          <i *ngIf="!feedback.ai?.summary?.length" class="not-found">Nenhum dado para exibir.</i>
          <i class="text" *ngIf="feedback.ai?.summary?.length">
            {{ feedback.ai.summary }}
          </i>
        </div>
        <div class="line-2">
          <div class="type">
            <div class="title">Tipo do Feedback</div>
            <i *ngIf="!feedback.ai" class="not-found">Nenhum dado para exibir.</i>
            <div class="list" *ngIf="feedback.ai">
              <div class="item" [ngClass]="{'type-compliment': feedback.ai.type == 0}"><i class="bi-heart"></i>elogio</div>
              <div class="item" [ngClass]="{'type-complaint': feedback.ai.type == 1}"><i class="bi-emoji-frown"></i>reclamação</div>
              <div class="item" [ngClass]="{'type-question': feedback.ai.type == 2}"><i class="bi-question-circle"></i>dúvida</div>
              <div class="item" [ngClass]="{'type-suggestion': feedback.ai.type == 3}"><i class="bi-lightbulb"></i>sugestão</div>
              <div class="item" [ngClass]="{'type-other': feedback.ai.type == 4}"><i class="bi-three-dots"></i>outros</div>
            </div>
          </div>
          <div class="severity">
            <div class="title">Severidade</div>
            <i *ngIf="!feedback.ai" class="not-found">Nenhum dado para exibir.</i>
            <div class="list" *ngIf="feedback.ai">
              <div class="item" [ngClass]="{'severity-low': feedback.ai.severity == 0}"><i class="bi-check2-all"></i>severidade baixa</div>
              <div class="item" [ngClass]="{'severity-medium': feedback.ai.severity == 1}"><i class="bi-eye"></i>severidade média</div>
              <div class="item" [ngClass]="{'severity-high': feedback.ai.severity == 2}"><i class="bi-search"></i>severidade alta</div>
              <div class="item" [ngClass]="{'severity-critical': feedback.ai.severity == 3}"><i class="bi-exclamation-circle-fill"></i>severidade crítica</div>
            </div>
          </div>
        </div>
        <div class="line-3">
          <div class="topics">
            <div class="title">Tópicos com AI</div>
            <i *ngIf="!feedback.ai?.topics_list.length" class="not-found">Nenhum dado para exibir.</i>
            <div class="list" *ngIf="feedback.ai?.topics_list.length">
              <div class="topic" [ngClass]="getTopicColor(topic.sentiment)" *ngFor="let topic of feedback.ai?.topics_list">
                <i *ngIf="topic.sentiment == 0" class="bi-emoji-frown"></i>
                <i *ngIf="topic.sentiment == 1" class="bi-emoji-neutral"></i>
                <i *ngIf="topic.sentiment == 2" class="bi-emoji-smile"></i>
                {{ topic.topic }}
              </div>
            </div>
          </div>
          <div class="tags">
            <div class="title">Tags Inteligentes</div>
            <i *ngIf="!feedback.smartTags.length" class="not-found">Nenhum dado para exibir.</i>
            <div class="list" *ngIf="feedback.smartTags.length">
              <div class="tag" *ngFor="let tag of feedback.smartTags">{{ tag.name }}</div>
            </div>
          </div>
        </div>
        <div class="line-4">
          <div class="sentiment">
            <div class="title">Sentimento Geral da Resposta</div>
            <i *ngIf="!feedback.ai" class="not-found">Nenhum dado para exibir.</i>
            <div *ngIf="feedback.ai" class="sentiments">
              <div [ngClass]="{'sentiment-negative': feedback.ai?.sentiment == 0}"><i class="bi-emoji-angry"></i>negativo</div>
              <div [ngClass]="{'sentiment-neutral': feedback.ai?.sentiment == 1}"><i class="bi-emoji-neutral"></i>neutro
              </div>
              <div [ngClass]="{'sentiment-positive': feedback.ai?.sentiment == 2}"><i class="bi-emoji-smile"></i>positivo</div>
            </div>
          </div>
          <div class="people">
            <div class="title">Pessoas</div>
            <i *ngIf="!feedback.ai?.has_persons" class="not-found">Nenhum dado para exibir.</i>
            <div *ngIf="feedback.ai?.has_persons" class="names">
              {{ getNames(feedback.ai?.persons_list) }}
            </div>
          </div>
        </div>
        <i class="log">Analisado por Amplifique.me Insights em {{ feedback.ai?.analyzed_at | amLocale: 'pt-BR' |
          amDateFormat: 'DD/MM/YYYY HH:mm' }}</i>
      </div>
      <div class="notes" *ngIf="tab == 5">
        <div *ngIf="!notes.length && !isNoteInputVisible" class="not-found">NENHUMA ANOTAÇÃO ENCONTRADA</div>
        <button *ngIf="!isNoteInputVisible" (click)="createNote()" class="amp-btn-primary create-note"><i class="bi-plus-lg"></i>Fazer Anotação</button>
        <div class="note-input" *ngIf="isNoteInputVisible">
          <textarea placeholder="Escreva aqui sua anotação..." [(ngModel)]="noteInput" class="amp-input-lg"></textarea>
          <div class="buttons">
            <button (click)="isNoteInputVisible = false" class="amp-btn-outline-cancel">Cancelar</button>
            <button (click)="saveNote(feedback._id, noteInput)" class="amp-btn-primary">Salvar</button>
          </div>
        </div>
        <div class="note" *ngFor="let note of notes">
          <div>
            <div class="amp-title">
              <span class="amp-name">{{ note._user ? note._user.nome : note.email }}</span>
              <div class="amp-info">
                <span class="amp-created-at">&nbsp;• <span *ngIf="!note.isEdited">adicionado em</span><span *ngIf="note.isEdited">editado em</span> {{ note.created_at | amDateFormat: 'DD/MM/YYYY HH:mm' }}
                </span>
              </div>
            </div>
            <p class="amp-note">{{ note.content }}</p>
          </div>
          <div class="delete-note" (click)="deleteNote(note)">
            <i class="bi-trash3"></i>
          </div>
        </div>
      </div>
      <div class="protocol" *ngIf="tab == 6">
        <div class="number">NÚMERO DO PROTOCOLO: {{ (feedback.id || feedback._id) | protocol }}</div>
        <div *ngIf="!feedback.reply?.length && !isReplyInputVisible" class="not-found">NENHUMA RESPOSTA ENCONTRADA</div>
        <button *ngIf="!feedback.reply?.length && !isReplyInputVisible" (click)="isReplyInputVisible = true" class="amp-btn-primary create-reply"><i class="bi-plus-lg"></i>Registrar Resposta</button>
        <div class="reply-input" *ngIf="isReplyInputVisible">
          <textarea placeholder="Escreva aqui sua anotação..." [(ngModel)]="replyInput" class="amp-input-lg"></textarea>
          <div class="buttons">
            <button (click)="isReplyInputVisible = false" class="amp-btn-outline-cancel">Cancelar</button>
            <button (click)="saveReply()" class="amp-btn-primary">Salvar</button>
          </div>
        </div>
        <div class="reply" *ngIf="feedback.reply?.length">
          <div class="amp-title">
            <span class="amp-name">{{ feedback.reply_by }}</span>
            <div class="amp-info">
              <span class="amp-created-at">&nbsp;• <span>adicionado em</span>
                {{ feedback.reply_at | amDateFormat: 'DD/MM/YYYY HH:mm' }}
              </span>
            </div>
          </div>
          <p class="amp-note">{{ feedback.reply }}</p>
        </div>
      </div>
    </div>
    <div class="actions">
      <a *ngIf="store.empresaData?.general_attributes?.allow_ticketing && store.userData?.ticketing_role?.length > 0" container="body" boundariesElement="viewport" placement="bottom" tooltip="Criar Ticket" (click)="createNewTicket()">
        <i class="bi-ticket"></i>
      </a>
      <a *ngIf="(!feedback.survey?.anonymous && !feedback.anonymized) && (feedback.customer?.tel || feedback.customer?.email || feedback.survey?.protocol_mode_params?.enabled)" id="optionDropdown" class="optionDropdown amp-dropdown" dropdownToggle (click)="(false)" aria-controls="option-dropdown" tooltip="Responder" dropdown placement="bottom">
        <i class="bi-reply"></i>
        <div class="dropdown-menu dropdown-menu-right sortOptions" id="option-dropdown" *dropdownMenu role="menu" aria-labelledby="optionDropdown">
          <div class="options">
            <a *ngIf="feedback.customer?.tel" (click)="call()" class="option">Ligar</a>
            <a *ngIf="feedback.customer?.tel" (click)="sendWhatsApp()" class="option">WhatsApp</a>
            <a *ngIf="feedback.customer?.email" (click)="sendMail()" class="option">E-mail</a>
            <a *ngIf="feedback.survey.protocol_mode_params.enabled" (click)="attachReply()" class="option">Registrar
              Resposta</a>
          </div>
        </div>
      </a>
      <a container="body" placement="bottom" boundariesElement="viewport" *ngIf="feedback.resolved == false && (user?.role == 'acmng' || user?.role == 'adm' || user?.role == 'own' || user?.role == 'ana')" tooltip="Marcar Como Resolvido" (click)="toggleSolved(true)">
        <i class="bi-check2"></i>
      </a>
      <a container="body" placement="bottom" boundariesElement="viewport" *ngIf="feedback.resolved == true && (user?.role == 'acmng' || user?.role == 'adm' || user?.role == 'own' || user?.role == 'ana')" tooltip="Marcar Como Não Resolvido" (click)="toggleSolved(false)">
        <i class="bi-x-lg"></i>
      </a>
      <a placement="bottom" *ngIf="!this.publicView && !feedback.survey?.anonymous" container="body" [routerLink]="'/contatos/' + feedback._customer" target="_blank" boundariesElement="viewport" tooltip="Ir para o Contato">
        <i class="bi-person"></i>
      </a>
      <a placement="bottom" container="body" boundariesElement="viewport" *ngIf="!this.publicView" tooltip="Encaminhar" (click)="forward(feedback)">
        <i class="bi-send"></i>
      </a>
      <a placement="bottom" *ngIf="!feedback.anonymized && (user?.role == 'acmng' || user?.role == 'adm' || user?.role == 'own')" (click)="anonymize(feedback)" container="body" boundariesElement="viewport" tooltip="Anonimizar Resposta">
        <i class="bi-eye-slash"></i>
      </a>
      <a placement="bottom" (click)="toggleShowBlankAnswers()" container="body" boundariesElement="viewport" [ngClass]="{'button-active': showBlankAnswers}" [tooltip]="!showBlankAnswers ? 'Mostrar Perguntas Em Branco' : 'Esconder Perguntas Em Branco'">
        <i class="bi-chat-quote"></i>
      </a>
      <a placement="bottom" *ngIf="feedback.deleted && !this.publicView && (user?.role == 'acmng' || user?.role == 'adm' || user?.role == 'own')" tooltip="Restaurar Resposta" (click)="restoreAnswer(feedback._id)">
        <i class="bi-arrow-clockwise"></i>
      </a>
      <a placement="bottom" (click)="!feedback.survey?.protocol_mode_params?.enabled ? softDeleteAnswer() : ''" *ngIf="!feedback.deleted && !this.publicView && (user?.role == 'acmng' || user?.role == 'adm' || user?.role == 'own')" [ngClass]="{ disabled: feedback.survey?.protocol_mode_params?.enabled }" container="body" boundariesElement="viewport" [tooltip]="!feedback.survey?.protocol_mode_params?.enabled ? 'Mover para Lixeira' : 'Não é possível mover para a lixeira uma resposta cuja pesquisa possui o Modo Protocolo ativado!'">
        <i class="bi-trash3"></i>
      </a>
      <a placement="bottom" *ngIf="feedback.deleted && !this.publicView && (user?.role == 'acmng' || user?.role == 'adm' || user?.role == 'own')" tooltip="Excluir Permanentemente" (click)="deleteAnswer()">
        <i class="bi-trash3"></i>
      </a>

      @if (user?.role == 'acmng') {
      <a placement="bottom" (click)="sendWebhook(feedback)" tooltip="Disparar Webhook">
        <i class="bi-braces-asterisk"></i>
      </a>
      }

      <div class="amp-resolved" *ngIf="feedback.resolved">
        <i class="bi-check-lg"></i>
        Resolvida com sucesso!
      </div>
    </div>
  </div>

  <!-- Right Arrow -->
  <div *ngIf="showNextArrow && !publicView" [ngClass]="{ animate__animated: rightClicked, animate__bounceIn: rightClicked }" tooltip="Próxima Resposta (Seta Direita no Teclado)" class="amp-right-arrow" (click)="nextAnswer()">
    <i class="bi-chevron-right"></i>
  </div>
</div>

<!-- Tooltip Content -->
<ng-template #ampliscorePopover let-data="data">
  <div class="ampliscore-popover">
    <h5>AmpliScore: {{ data }}</h5>
    <br>
    <span>O AmpliScore é uma métrica que varia de -100 a 100 que sintetiza todas as perguntas respondidas dentro de uma resposta em apenas uma nota.</span>
    <br>
    <br>
    <i>* Perguntas de texto, única escolha, múltipla escolha e de seleção não são contabilizadas.</i>
  </div>
</ng-template>