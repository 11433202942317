<section>
  <div class="row">
    <div class="col-lg-12">
      <div class="amp-card">
        <div class="amp-table">
          <div class="amp-table-head">
            <div class="amp-table-column amp-webhook-description-header">Descrição</div>
            <div class="amp-table-column amp-webhook-url-header">URL</div>
            <div class="amp-table-column">Status</div>
            <div class="amp-table-column">Ações</div>
          </div>
          <div class="amp-table-row amp-webhook-row" *ngFor="let wh of webhookList">
            <div class="amp-table-cell amp-webhook-description">{{ wh.description }}</div>
            <div class="amp-table-cell amp-webhook-url-content">{{ wh.endpoint_url }}</div>
            <div class="amp-table-cell amp-webhook-status">
              <span [ngClass]="{'disabled': !wh.active}">{{ wh.active ? 'Ativo' : 'Inativo' }}</span>
            </div>
            <div class="amp-table-cell amp-webhook-actions">
              <i routerLink="/partners/webhook/{{wh._id}}" class="bi-eye"></i>
              <i (click)="editWebhook(wh)" class="bi-pencil"></i>
              <i (click)="removeWebhook(wh)" class="bi-x-lg"></i>
            </div>
          </div>
        </div>
        <div class="amp-save amp-save-integrations">
          <button class="amp-btn-primary" (click)="addWebhook()">Adicionar Webhook</button>
        </div>
      </div>
    </div>
  </div>
</section>