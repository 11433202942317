import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StoreService } from 'app/_services';
@Injectable()
export class FlagGuard {
  constructor(private router: Router, private store: StoreService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<Observable<boolean> | boolean> {
    const expectedFlag: string = route.data.expectedFlag;

    const value = await this.check(expectedFlag);
    if (value) {
      return value;
    }
    this.router.navigate(['/cf-dashboard']);
    return false;
  }

  async check(expectedFlag: string) {
    let value = false;
    let checkCount = 0;

    while ((!this.store.business || !this.store.empresaData) && checkCount < 5) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      checkCount++;
    }

    switch (expectedFlag) {
      case 'reputation-management':
        value = this.store.business.reviews_enabled;
        if (!value) {
          // @ts-ignore
          window.Intercom('showNews', 46373);
        }
        break;
      case 'customer-feedback':
        value = this.store.business.nps_enabled;
        if (!value) {
          // @ts-ignore
          window.Intercom('showNews', 46375);
        }
        break;
      case 'insights':
        value = this.store.business.cf_attributes.cf_ai;
        if (!value) {
          // @ts-ignore
          window.Intercom('showNews', 46376);
        }
        break;
      case 'ticketing':
        value = this.store.empresaData.general_attributes.allow_ticketing;
        if (!value) {
          // @ts-ignore
          window.Intercom('showNews', 46377);
        }
        break;
      default:
        value = false;
        break;
    }
    return value;
  }
}
