import { Empresa } from '../empresa';
import { User } from '../user';
import TkTicket from './ticket';

export default class TkPipeline {
  _id: string;
  name: string;
  _empresa: Empresa | string;
  _users: any[] = [];
  default_assigned_to: User | string = '';
  steps: TkPipelineStep[];
  _created_by: User | string;
  last_ticket_at?: Date;
  created_at: Date;
  updated_at: Date;
  email_from_name: string;
  default_expiration?: number;
}

export interface TkPipelineStep {
  _id: string;
  name: string;
  status: TkPipelineStatus;
  unique_id: string;
  checklist_enabled: boolean;
  checklist_rqeuired: boolean;
  checklist: TkPipelineStepChecklist[];
  tickets?: TkTicket[];
  count?: number;
}

export interface TkPipelineStepChecklist {
  _id?: string;
  name: string;
  unique_id: string;
}

export enum TkPipelineStatus {
  pending = 0,
  in_progress = 1,
  closed = 2
}
