import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from 'app/_services/menu.service';
import { PartnerRequest } from 'app/_models/partnerRequest';
import { ActivatedRoute } from '@angular/router';
import { AmpLoaderService } from 'app/amp-components/loader/loader.service';
import { TokenService } from 'app/_services/token.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LogDialogComponent } from './log-dialog/log-dialog.component';
import { Token } from 'app/_models/token';
@Component({
  selector: 'app-token-log',
  templateUrl: './token-log.component.html',
  styleUrls: ['./token-log.component.scss']
})
export class TokenLogComponent implements OnInit, OnDestroy {
  constructor(
    private menuService: MenuService,
    private route: ActivatedRoute,
    private loadService: AmpLoaderService,
    private tokenService: TokenService,
    private modalService: BsModalService
  ) {
    this.menuService.emit(true);
    this.menuService.emitPageTitle('Log de Integração');
  }

  public logs: PartnerRequest[] = [];
  public metrics: any[] = [];
  public token: Token = new Token();
  public subscriptions = [];
  public page: any;
  public pagination: any = { totalItems: 0 };
  public paramId: string;

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(async (params) => {
        this.paramId = params['id'];
        this.getPageData();
      })
    );
  }

  async getPageData() {
    if (this.paramId != null) {
      this.getLog(0);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  getLog(page) {
    this.loadService.show();
    this.subscriptions.push(
      this.tokenService.getLog(this.paramId, page).subscribe((data) => {
        if (data.metrics?.length > 0) {
          this.metrics = data.metrics;
          this.pagination.totalItems = data.totalResults;
        }
        this.logs = data.data;
        this.token = data.token;
        this.loadService.hide();
      })
    );
  }

  view(log: PartnerRequest) {
    const initialState = {
      log
    };
    this.modalService.show(LogDialogComponent, { class: 'modal-xs', initialState });
  }
}
