<div class="amp-validator">
  <div class="amp-header">
    <span>Requerimentos de Senha</span>
  </div>

  <div class="amp-body">
    <span>Sua senha deve conter, no mínimo:</span>
    <div class="amp-list">
      <ul>
        <li [ngClass]="{'valid': password ? patternNumber.test(password): false}">Um número</li>
        <li [ngClass]="{'valid': password?.length >= 8}">Oito caracteres</li>
        <li [ngClass]="{'valid': password ? patternUpperCase.test(password): false}">Uma letra maiúscula</li>
        <li [ngClass]="{'valid': password ? patternLowerCase.test(password): false}">Uma letra minúscula</li>
        <li [ngClass]="{'valid': password ? patternSpecial.test(password): false}">Um caracter especial</li>
      </ul>
    </div>
  </div>
</div>
