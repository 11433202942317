import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, StoreService } from 'app/_services';
import { Injectable } from '@angular/core';
@Injectable()
export class AuthGuard {
  constructor(public auth: AuthenticationService, private router: Router, private store: StoreService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.routeConfig.path.includes('subscription')) {
      this.store.checkTrial();
    }

    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    return true;
  }
}
