<amp-loader></amp-loader>

<alerts-area></alerts-area>

<amp-delivery-progress-bar *ngIf="isSidebar"></amp-delivery-progress-bar>

<amp-contact-editor #editContactSidebar [show]="showContactEditor" (close)="showContactEditor = false"></amp-contact-editor>

<div clickOutside (clickOutside)="closeDrawer()" class="sidebar collapsed" *ngIf="isSidebar" [ngClass]="{
    'top-space': store.alerts?.length && checkAlert(),
    'environment-banner-space': homolog,
    'active-mobile': !isSidebarMobile,
    'alert-space': (store.business?.pendingPayment && store.togglePendingPayment) || (togglePromo && store.empresaData?.isTrial)
  }">

  <div class="amp-topbar">
    <img src="/assets/img/icon_amp.svg" />
  </div>

  <div [ngClass]="{ 'alert-space': togglePromo }" class="sidebar__items">
    <div *ngFor="let item of menuItems">
      <li [routerLinkActive]="['active']" *ngIf="item.expectedRole?.indexOf(user.role) > -1 || item.expectedRoleTicketing ? (item.expectedRoleTicketing?.indexOf(user.ticketing_role) > -1) : false" [ngClass]="{ disabled: item.disabled }">
        <div *ngIf="item.title == 'menu.referralProgram' ? !item.disabled : true">
          <a *ngIf="!item.subItems" (click)="uncollapse()" [adaptivePosition]="false" containerClass="tooltip-menu" container="body" [tooltip]="item.title | translate" placement="right" (click)="redirect(item)">
            <span [class]="'icon ' + item.icon"></span>
            <span class="amp-item-title">{{ item.title }}</span>
          </a>
          <span class="amp-sidebar-title" (click)="uncollapse()" [routerLink]="item.url">{{ item.title | translate }}</span>
        </div>
        <div *ngIf="item.disabled && item.title == 'menu.integrations'">
          <a *ngIf="!item.subItems" (click)="uncollapse()" [adaptivePosition]="false" containerClass="tooltip-menu" container="body" [tooltip]="notAvailable" placement="right">

            <ng-template #notAvailable>
              <span>{{item.title | translate}}</span>
              <br>
              <span>O seu plano não inclui o recurso Integrações</span>
            </ng-template>

            <span [class]="'icon ' + item.icon"></span>
            <span class="amp-item-title">{{ item.title }}</span>
          </a>
          <span class="amp-sidebar-title" (click)="uncollapse()" [routerLink]="item.url">{{ item.title | translate }}</span>
        </div>
      </li>
    </div>

    <li class="amp-secondary-bar" *ngIf="['own', 'adm', 'ana', 'acmng'].includes(user.role)" [routerLinkActive]="['active']" clickOutside (clickOutside)="showSecondaryBar = false" (click)="openSecondaryBar()">
      <div>
        <a (click)="uncollapse()" [adaptivePosition]="false" containerClass="tooltip-menu" container="body" tooltip="Mais" placement="right">
          <span class="icon bi-three-dots"></span>
          <span class="amp-item-title">Mais</span>
        </a>
        <span class="amp-sidebar-title" (click)="uncollapse()">Mais</span>
      </div>
    </li>

  </div>
</div>

<div class="fixed" *ngIf="isSidebar">
  <div (click)="openNotion()" class="amp-help-button" [adaptivePosition]="false" container="body" containerClass="tooltip-menu" tooltip="Central de Ajuda" placement="right">
    <i class="bi-question-circle-fill"></i>
  </div>

  <div class="amp-chat-button" id="intercom-chat-launcher" *ngIf="TawkToAvailable" [ngClass]="{ active: showChat }" [adaptivePosition]="false" container="body" containerClass="tooltip-menu" tooltip="Chat" placement="right">
    <i class="bi-chat-fill"></i>
  </div>
</div>

<nav class="environment-banner" *ngIf="homolog">
  <span>AMBIENTE DE HOMOLOGAÇÃO</span>
</nav>

<nav aria-labbelledby="notification-topbar" aria-label="Banner de Pagamento Pendente" class="notification-topbar" *ngIf="store.business?.pendingPayment && store.togglePendingPayment">
  <i *ngIf="isAcMng" (click)="store.togglePendingPayment = false" class="bi-x-lg"></i>
  <span>Seu pagamento está pendente,&nbsp;</span>

  @if (store.business.stripeCustomer) {
  <a (click)="getCustomerPortal()">clique aqui para verificar.</a>
  } @else {
  <a (click)="goToFinances()">clique aqui para ver as faturas.</a>
  }
</nav>

<nav class="amp-navbar collapsed" aria-label="Barra de Navegação" *ngIf="isNavBar" [ngClass]="{
    'top-space': store.alerts?.length && checkAlert(),
    'environment-banner-space': homolog,
    'active': !isSidebarMobile,
    'active': !isSidebar,
    'alert-space': (store.business?.pendingPayment && store.togglePendingPayment) || (togglePromo && store.empresaData?.isTrial)
  }">
  <div class="amp-title-wrapper">
    <i id="colorPicker" (click)="openDrawer()" class="bi-list"></i>
    <h2 class="amp-main-title" [innerHTML]="pageTitle"></h2>
    <button *ngIf="checkPage('insights')" class="amp-btn-primary" (click)="openTutorial('insights')"><i class="bi-play-circle"></i>Assistir Tutorial</button>
    <button *ngIf="checkPage('ticketing')" class="amp-btn-primary" (click)="openTutorial('ticketing')"><i class="bi-play-circle"></i>Assistir Tutorial</button>
    <button *ngIf="checkPage('importer/answers')" class="amp-btn-primary" (click)="openTutorial('answer-importer')"><i class="bi-book"></i>Ler Tutorial</button>
  </div>

  <div class="amp-nav-right">
    <div class="amp-navbar-trial" *ngIf="store.empresaData?.isTrial">
      <div *ngIf="!store.isExpired" class="amp-counter">
        <span>Teste</span>
        <span>termina em {{ store.empresaData.trial_expiration_date | amTimeAgo: true }}</span>
      </div>
      <div *ngIf="store.isExpired" class="amp-counter">
        <span>Teste Expirado</span>
      </div>
      <button (click)="openSubscribe()" class="amp-btn-primary amp-button-trial">Assinar agora</button>
    </div>

    <div class="amp-navbar-profile">
      <i *ngIf="setAlertIconVisibility()" placement="bottom" tooltip="Clique para visualizar os alertas!" (click)="store.checkAccountUsage(true)" class="bi-exclamation-triangle-fill"></i>

      <div class="amp-user-data">
        <span class="amp-username">{{ user.nome }}</span>
        <span (click)="showDropdown()" id="amp-autocomplete" class="amp-business">
          <span *ngIf="user._businesses.length > 1" class="bi-chevron-down"></span>
          UNIDADE: {{ empresa }}
          <div>
            <div clickOutside (clickOutside)="hideDropdown()" *ngIf="showDropDown" class="amp-field-dropdown">
              <div class="amp-field">
                <input type="search" id="amp-businesses-search" name="searchBusiness" class="amp-input" [(ngModel)]="searchBusinessField" placeholder="Pesquisar..." autocomplete="off" />
              </div>
              <div class="amp-autocomplete-content">
                <div *ngFor="let business of user._businesses | ampAdmCompany: searchBusinessField">
                  <div *ngIf="business._id != store.business._id" class="amp-autocomplete-item">
                    <span class="amp-info-name" (click)="changeBusiness(business._id)">{{ business.nome }} <span class="bi-chevron-right"></span></span>
                  </div>
                </div>
                <div class="not-found" *ngIf="searchBusinessField?.length && checkHasResults()">NENHUM RESULTADO ENCONTRADO</div>
              </div>
            </div>
          </div>
        </span>
      </div>

      <div *ngIf="beta" class="amp-beta" tooltip="Você está na versão BETA da nossa plataforma. Tenha em mente que essa versão está em fase de testes e pode conter erros, limitações ou inconsistências. Seu feedback é fundamental para aprimorarmos a plataforma. Fique à vontade para enviar suas sugestões e relatar problemas encontrados. Agradecemos sua participação!">beta</div>

      <span *ngIf="user.role != 'dashonly'" class="bi-bell-fill" id="notification-bell">
        <span id="unseen-badge"></span>
      </span>

      <div dropdown class="dropdown amp-profile-dropdown">
        <a id="userDropdown" href dropdownToggle (click)="(false)" aria-controls="user-dropdown">
          <img [src]="user.picture ? user.picture : '/assets/img/user.png'" class="amp-avatar" />
          <span *ngIf="store.empresaData?.general_attributes?.allow_beta" class="badge"></span>
          <i class="bi-chevron-down"></i>
        </a>

        <div class="dropdown-menu dropdown-menu-right" id="user-dropdown" *dropdownMenu role="menu">
          <div class="options">
            <a (click)="editProfile()">Meu Perfil</a>
            <a *ngIf="user.role != 'dashonly'" routerLink="/settings">Configurações</a>
            <a *ngIf="isAcMng || canTemporaryAccess" (click)="openModalSwitchEmpresa()">Trocar Conta</a>
            <a *ngIf="isAcMng" routerLink="/admin">Adm. Interna</a>
            <a class="open-intercom-widget" (click)="openIntercomNews()">Novidades</a>
            <a *ngIf="store.empresaData?.general_attributes?.allow_beta" (click)="goToBeta()">Acessar Beta</a>
            <a target="_blank" [href]="amplmeAuthenticatedLink">Encurtador de Link</a>
            <a (click)="openChat()">Ajuda</a>
            <a (click)="logout()">Sair</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="store.empresaData ? store.empresaData.isTrial : false" class="amp-counter amp-counter-small">
    <span>Seu teste termina em {{ store.empresaData?.trial_expiration_date | amTimeAgo: true }}.</span>
    <a (click)="openSubscribe()">&nbsp;Clique aqui para assinar agora!</a>
  </div>
</nav>

<div class="amp-splash-screen" *ngIf="(!userDataLoaded || !empresaDataLoaded) && authenticationService.isAuthenticated()">
  <div class="amp-splash-loader" *ngIf="!mainLoadingError">
    <img class="phrase" src="/assets/img/icon_amp_white.svg" />
    <div class="loader"></div>
  </div>

  <div class="amp-splash-error" *ngIf="mainLoadingError">
    <i class="bi-x-lg"></i>
    <div class="loader"></div>
  </div>

  <p *ngIf="!mainLoadingError" class="phrase">{{ phrase }}</p>
  <p class="text-center" *ngIf="mainLoadingError">Houve um erro.<br>Por favor, atualize a página ou <a (click)="logout()">entre novamente.</a></p>
</div>

<main [ngStyle]="{display: ((userDataLoaded && empresaDataLoaded) || !authenticationService.isAuthenticated()) ? 'block': 'none' }" class="content collapsed" [ngClass]="{
    'top-space-alt': (store.alerts?.length && checkAlert()) || homolog,
    'active': !isNavBar,
    'active-2': !isSidebar,
    'alert-content-space': (store.business?.pendingPayment && store.togglePendingPayment) || (togglePromo && store.empresaData?.isTrial)
  }">
  <router-outlet *ngIf="store.empresa || !authenticationService.isAuthenticated()"></router-outlet>
</main>

<amp-secondary-bar id="secondary-bar" [show]="showSecondaryBar" (close)="showSecondaryBar = false"></amp-secondary-bar>