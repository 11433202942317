import { ReviewPlatform } from 'app/_models/reviewPlatform';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
import moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class ReviewPlatformService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  create(reviewPlatform: ReviewPlatform) {
    return this.http.post<any>(this.config.apiUrl + '/reviewPlatform', reviewPlatform);
  }

  update(reviewPlatform: ReviewPlatform) {
    return this.http.put<any>(this.config.apiUrl + '/reviewPlatform/' + reviewPlatform._id, reviewPlatform);
  }

  forceImport(reviewPlatform: ReviewPlatform) {
    return this.http.patch<any>(this.config.apiUrl + '/reviewPlatform/forceImport/' + reviewPlatform._id, {});
  }

  delete(reviewPlatformId: string) {
    return this.http.delete<any>(this.config.apiUrl + '/reviewPlatform/' + reviewPlatformId);
  }

  get(business = null, empresa?: string, onlyRequestable = false) {
    if (business || empresa) {
      // @ts-ignore
      const query = new URLSearchParams({
        empresa,
        onlyRequestable
      });

      if (business) {
        query.append('business', business);
      }

      return this.http.get<any>(this.config.apiUrl + '/reviewPlatform/?' + query.toString());
    } else {
      return this.http.get<any>(this.config.apiUrl + '/reviewPlatform/');
    }
  }

  getReviews(filters, limit, page) {
    const query = new URLSearchParams({
      limit: limit,
      page: page
    });

    return this.http.post<any>(this.config.apiUrl + '/reviewPlatform/getReviews?' + query.toString(), filters);
  }

  deletePlatformAndReviews(_id: string) {
    return this.http.delete<any>(this.config.apiUrl + '/reviewPlatform/' + _id);
  }

  getReviewPlatformCardScoreDashboard(ids, filterDate, viewMode = 'aggregated') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });

    return this.http.post<any>(this.config.apiUrl + `/reviewPlatform/score-card-metrics?${query.toString()}`, { ids });
  }

  getReviewPlatformCardDistributionDashboard(ids, filterDate, viewMode = 'aggregated') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });

    return this.http.post<any>(this.config.apiUrl + `/reviewPlatform/score-distribution-metrics?${query.toString()}`, { ids });
  }

  getReviewPlatformEvolutiveChartData(ids, filterDate, granularity = 'month', viewMode = 'restrict') {
    return this.http.post<any>(this.config.apiUrl + `/reviewPlatform/evolutive-chart-data`, {
      platforms: ids,
      startDate: filterDate[0],
      endDate: filterDate[1],
      granularity,
      viewMode
    });
  }

  getReviewPlatformSmartTagsChartData(ids, filterDate) {
    return this.http.post<any>(this.config.apiUrl + `/reviewPlatform/smart-tags-chart-data`, {
      platforms: ids,
      startDate: filterDate[0],
      endDate: filterDate[1]
    });
  }

  exportReviewsByDashboardCard(ids, filterDate, viewMode = 'aggregated') {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    const query = new URLSearchParams({
      startDate: start,
      endDate: end,
      viewMode: viewMode
    });

    return this.http.post<any>(this.config.apiUrl + `/reviewPlatform/export-reviews-by-dashboard?${query.toString()}`, { ids });
  }

  getReviewsWordCloud(ids, _empresa, filterDate) {
    const timestamp = 'T00:00:00.000';
    const start = moment(filterDate[0]).format('YYYY-MM-DD') + timestamp;
    const end = moment(filterDate[1]).format('YYYY-MM-DD') + timestamp;
    return this.http.post<any>(this.config.apiUrl + `/reviewPlatform/wordcloud-chart-data/`, {
      platforms: ids,
      _empresa: _empresa,
      startDate: start,
      endDate: end
    });
  }
}
