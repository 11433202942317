<div class="amp-milestone-widget-wrapper" [ngClass]="{'dark-mode': campaign.dark_mode}">
  <div class="amp-milestone-bar" *ngIf="campaign.has_reward">
    <div class="amp-tab-links" [ngClass]="{'full': !campaign.referee_can_refer}" [ngStyle]="{'background-color': showRecompensas ? (campaign.primary_color+'1a') : 'inherit','color': showRecompensas ? (campaign.primary_color) : 'inherit', 'border-bottom': showRecompensas ? ('4px solid ' + campaign.primary_color) : '4px solid #E0E7F4' }" (click)="toggleRecompensas()">
      <span>
        Recompensas
      </span>
    </div>
    <div *ngIf="campaign.referee_can_refer" class="amp-tab-links" [ngStyle]="{'background-color': showIndicacoes ? (campaign.primary_color+'1a') : 'inherit','color': showIndicacoes ? (campaign.primary_color) : 'inherit',  'border-bottom': showIndicacoes ?  ('4px solid ' + campaign.primary_color) : '4px solid #E0E7F4'}" (click)="toggleIndicacoes()">
      <span>
        Indicações
      </span>
    </div>
  </div>

  <div *ngIf="showRecompensas && campaign.type == 'milestone'" class="amp-milestones amp-tab-recompensas" @list>
    <div class="amp-milestone" @items *ngFor="let milestone of campaign.milestones;let i = index">
      <div class="amp-milestone-vr-first" *ngIf="i==0" [ngStyle]="{'background-color': campaign.primary_color}"></div>
      <div class="amp-milestone-badge-wrapper">
        <div class="amp-milestone-badge" *ngIf="i==0" [ngStyle]="{'background-color': campaign.primary_color,'color': campaign.text_color, 'font-size': 32 - ((milestone.required_referrals.toString().length - 1) * 3) +'px'}">
          {{milestone.required_referrals}}
        </div>
        <div *ngIf="i==1" class="amp-milestone-badge active last-reward" [ngStyle]="{'background-color': campaign.primary_color,'color': campaign.text_color, 'box-shadow':'0px 0px 16px '+campaign.primary_color, 'font-size': 32-((milestone.required_referrals.toString().length - 1) * 3) + 'px'}">
          {{milestone.required_referrals}}
        </div>

        <div class="amp-milestone-badge" *ngIf="i>1" [ngStyle]="{'background-color': campaign.primary_color+'1a', 'color': campaign.primary_color, 'font-size': 32 - ((milestone.required_referrals.toString().length - 1) * 3) +'px'}">
          {{milestone.required_referrals}}
        </div>
      </div>
      <div class="amp-milestone-content" [ngClass]="{'disabled': i > 1}">
        <div class="amp-milestone-goal" [ngStyle]="{'color': campaign.primary_color}" *ngIf="i == 1 || i == 0">conquistada em 01/01/2020</div>
        <div class="amp-milestone-title">{{milestone.name}}</div>
        <div class="amp-milestone-reward-status"></div>
      </div>
      <div class="amp-milestone-vr" *ngIf="i < 1" [ngStyle]="{'background-color': campaign.primary_color}"></div>
      <div class="amp-milestone-vr" *ngIf="i >= 1"></div>
    </div>
  </div>


  <div *ngIf="showRecompensas && !campaign.has_reward && campaign.type != 'milestone'" class="amp-tab-recompensas amp-tab-recompensas-referral">
    <showdown class="amp-markdown" [value]="campaign.content_coupons"></showdown>
    <div @items class="amp-reward" [ngStyle]="{'background-color': campaign.primary_color+'1a', 'border-color': campaign.primary_color+'1a'}">
      <span [ngStyle]="{'color': campaign.primary_color}">
        Você tem 0 indicações confirmadas!
      </span>
    </div>
  </div>

  <div *ngIf="showIndicacoes" class="amp-tab-indicacoes" @list>
    <div @items [ngStyle]="{'background-color': campaign.primary_color+'1a', 'border-color': campaign.primary_color}">
      <span [ngStyle]="{'color': campaign.primary_color}">
        Você tem {{ indicacoesConfirmadas }} indicações confirmadas!
      </span>
    </div>
    <div>
      <table>
        <tr @items *ngFor="let indicacoes of listaIndicacoes">
          <td>
            {{ indicacoes.nome }}
          </td>
          <td [ngClass]="indicacoes.status == 1 ? 'confirmado' : 'pendente'">
            {{ indicacoes.status == 1 ? 'CONFIRMADO' : 'PENDENTE' }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>