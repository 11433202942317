import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-change-business-dialog',
  templateUrl: './change-business-dialog.component.html',
  styleUrls: ['./change-business-dialog.component.scss']
})
export class ChangeBusinessDialogComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef) {}

  public changeEmpresa: any;
  public empresas: any = [];
  public searchField;
  public typeFilter = 'all';
  public list;
  public options = [
    {
      label: 'Mostrar Todos',
      value: 'all'
    },
    {
      label: 'Apenas em Teste',
      value: 'only_trial'
    },
    {
      label: 'Apenas Teste Expirado',
      value: 'free_trial_expired'
    },
    {
      label: 'Apenas Teste Não Expirado',
      value: 'free_trial_not_expired'
    },
    {
      label: 'Apenas Ativos',
      value: 'only_active'
    },
    {
      label: 'Apenas Cancelados',
      value: 'canceled'
    }
  ];

  ngOnInit() {
    const searchInput = document.getElementById('amp-search-input');
    let x = this.list;

    setTimeout(() => {
      searchInput.focus();
    }, 500);

    setInterval(() => {
      this.list = document.querySelectorAll('.amp-list .amp-option');
      x = document.querySelectorAll('.amp-list .amp-option');
    }, 500);

    searchInput.addEventListener('keypress', function (event) {
      if (event.key === 'Enter' && x.length == 1) {
        event.preventDefault();
        x[0].click();
      }
    });
  }

  filterType() {
    if (this.typeFilter == 'all') {
      return this.empresas;
    } else if (this.typeFilter == 'only_trial') {
      return this.empresas.filter((item) => item.isTrial == true);
    } else if (this.typeFilter == 'only_active') {
      return this.empresas.filter((item) => item.active && (!item.isTrial || item.isTrial == undefined));
    } else if (this.typeFilter == 'canceled') {
      return this.empresas.filter((item) => !item.active && (!item.isTrial || item.isTrial == undefined));
    } else if (this.typeFilter == 'free_trial_expired') {
      return this.empresas.filter((item) => item.isTrial == true && new Date(item.trial_expiration_date) < new Date());
    } else if (this.typeFilter == 'free_trial_not_expired') {
      return this.empresas.filter((item) => item.isTrial == true && new Date(item.trial_expiration_date) >= new Date());
    }
  }
}
