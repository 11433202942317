<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">
      <span class="amp-dialog-icon bi-person-x"></span>
      Bloquear Participante
    </div>
    <div class="amp-dialog-subtitle">
      Ao bloquear um participante, ele não poderá mais se autenticar no Programa de Indicação, e suas indicações não serão mais contabilizadas.
      <br>
      <br>
      O bloqueio é feito com base no dado de contato principal cadastrado (e-mail ou telefone). Esta ação é reversível.
    </div>
  </div>

  <div class="amp-buttons">
    <button *ngIf="!loading" (click)="blockParticipant()" class="amp-btn-lg amp-btn-danger">Bloquear Participante</button>
    <button class="amp-btn-danger amp-btn-lg w-100" *ngIf="loading" [disabled]="true">Processando<span class="loading-ellipsis"lo>...</span></button>
    <button *ngIf="!loading" (click)="this.bsModalRef.hide()" class="amp-btn-lg amp-btn-outline-secondary">Cancelar</button>
  </div>
</div>
