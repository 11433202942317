import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'protocol'
})
export class ProtocolPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      let result = '';
      for (let i = 0; i < value.length; i++) {
        if (i > 0 && i % 4 === 0) {
          result += '-';
        }
        result += value.charAt(i);
      }
      return result;
    }
    return '';
  }
}
