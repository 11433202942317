<div class="amp-dialog">
  <div class="amp-dialog-header">

    @if (isNew) {
    <div class="amp-dialog-title">{{ 'contato-dialog.title' | translate }}</div>
    <div class="amp-dialog-subtitle">{{ 'contato-dialog.subtitle' | translate }}</div>
    } @else {
    <div class="amp-dialog-title">{{ 'contato-dialog.title-old' | translate }}</div>
    <div class="amp-dialog-subtitle">{{ 'contato-dialog.subtitle-old' | translate }}</div>
    }

    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>

  <form (ngSubmit)="save()" #f="ngForm">
    <div class="amp-field">
      <label class="amp-label-text">{{'contato-dialog.nameField' | translate}}</label>
      <input type="search" class="amp-input-lg" [(ngModel)]="customer.name" #name="ngModel" name="name" required
        [placeholder]="'contato-dialog.nameFieldPlaceholder' | translate" />
    </div>

    <div class="amp-field">
      <label class="amp-label-text">{{'contato-dialog.emailField' | translate}}</label>
      <input type="email" class="amp-input-lg" [(ngModel)]="customer.email" #email="ngModel" name="email"
        [placeholder]="'contato-dialog.emailFieldPlaceholder' | translate" />
    </div>

    <div class="amp-field">
      <label class="amp-label-text">{{ 'contato-dialog.phoneField' | translate }}</label>
      <amp-phone-input [autofocus]="false" [placeholder]="'contato-dialog.phoneFieldPlaceholder' | translate"
        [(phone)]="customer.tel" [(valid)]="validNumber"></amp-phone-input>
    </div>

    <div class="amp-field amp-field-autocomplete" id="amp-autocomplete">
      <label class="amp-label-text" id="amp-autocomplete">Nome da Empresa (opcional)</label>
      <amp-select placeholder="Clique e digite para pesquisar" [actions]="actions" (actionsClick)="onActionClick($event)"
        [optionsObservable]="requestCompaniesAutoComplete" [isSearchable]="true" [options]="companies"
        [(value)]="selectedCompany"></amp-select>
    </div>

    <div class="amp-dialog-actions">
      <button class="amp-btn-success" type="submit" *ngIf="isNew">{{ 'contato-dialog.createButton' |
        translate }}</button>
      <button class="amp-btn-success" type="submit" *ngIf="!isNew">{{ 'contato-dialog.updateButton' |
        translate }}</button>
      <button class="amp-btn-outline-secondary" type="button" (click)="bsModalRef.hide()">{{ 'main.cancelButton' |
        translate }}</button>
    </div>
  </form>
</div>