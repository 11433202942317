import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  constructor(public bsModalRef: BsModalRef) {}

  @Input() title: string;
  @Input() subtitle: string;
  @Input() cancelButton: string;
  @Input() confirmButton: string;
  @Input() public confirmCallback: any;

  confirm() {
    this.bsModalRef.hide();
    this.confirmCallback();
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
