import { SurveyAnswerService } from 'app/_services/customerFeedback/surveyAnswer.service';
import { EmpresaService, StoreService, TagService, UserService } from 'app/_services';
import { SurveyService } from 'app/_services/customerFeedback/survey.service';
import { TkPipelineService } from 'app/_services/ticketing/pipeline.service';
import { SmartTagService } from 'app/_services/smartTags/smartTags.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CustomerService } from 'app/_services/customer.service';
import { CompanyService } from 'app/_services/company.service';
import { Survey } from 'app/_models/customerFeedback/survey';
import uniqueId from 'lodash-es/uniqueId';
import { Subject } from 'rxjs';
@Component({
  selector: 'amp-cf-filter',
  templateUrl: 'cf-filter.component.html',
  styleUrls: ['cf-filter.component.scss']
})
export class CfFilterComponent implements OnInit, OnDestroy {
  constructor(
    public store: StoreService,
    private tagService: TagService,
    private userService: UserService,
    private surveyService: SurveyService,
    private empresaService: EmpresaService,
    private companyService: CompanyService,
    private smartTagService: SmartTagService,
    private customerService: CustomerService,
    private pipelineService: TkPipelineService,
    private surveyAnswerService: SurveyAnswerService
  ) {}

  public subscriptions = [];
  public step = 0;
  public filterSearch = '';
  public innerSearchFilter = '';
  public editFilterIndex = -1;
  public selectedMetric = 'nps';
  public isMainMetric = false;
  public isSelectAll = false;
  public pipelineId;
  public isEditingFilter = false;

  public customFieldsIcons = {
    date: 'bi-calendar',
    cpf: 'bi-person-vcard',
    cnpj: 'bi-buildings',
    cpf_cnpj: 'bi-buildings',
    text: 'bi-type',
    number: 'bi-hash',
    select: 'bi-list-nested',
    multiple: 'bi-ui-checks'
  };

  public questionIcons = {
    nps: 'bi-speedometer',
    csat: 'bi-emoji-heart-eyes',
    ces: 'bi-bandaid',
    unique: 'bi-check-circle',
    multiple: 'bi-ui-checks',
    text: 'bi-chat-left-text',
    select: 'bi-list-nested',
    thumbs: 'bi-hand-thumbs-up',
    matrix: 'bi-grid-3x2-gap-fill',
    likert: 'bi-123'
  };

  public filterOptions = [
    {
      type: 'customer',
      subType: 'none',
      name: 'name',
      label: 'Nome',
      icon: 'bi-type'
    },
    {
      type: 'customer',
      subType: 'none',
      name: 'email',
      label: 'E-mail',
      icon: 'bi-envelope-at'
    },
    {
      type: 'customer',
      subType: 'none',
      name: 'tel',
      label: 'Telefone',
      icon: 'bi-telephone'
    },
    {
      type: 'customer',
      subType: 'none',
      name: 'tag',
      label: 'Tag',
      icon: 'bi-tag'
    },
    {
      type: 'company',
      subType: 'none',
      name: 'name',
      label: 'Nome',
      icon: 'bi-buildings'
    },
    {
      type: 'company',
      subType: 'none',
      name: 'document',
      label: 'CNPJ',
      icon: 'bi-buildings'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'score',
      label: 'Nota',
      icon: 'bi-star'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'text',
      label: 'Texto',
      icon: 'bi-type'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'aiTopic',
      label: 'Tópico (AI)',
      icon: 'bi-list-ul'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'aiSentiment',
      label: 'Sentimento Geral (AI)',
      icon: 'bi-emoji-smile'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'aiTopicSentiment',
      label: 'Sentimento do Tópico (AI)',
      icon: 'bi-emoji-smile'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'aiSeverity',
      label: 'Severidade (AI)',
      icon: 'bi-exclamation-triangle'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'aiType',
      label: 'Tipo do Feedback (AI)',
      icon: 'bi-chat-right-text'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'aiPerson',
      label: 'Pessoas Mencionadas (AI)',
      icon: 'bi-person-add'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'deliveryStatus',
      label: 'Status do Envio',
      icon: 'bi-check2-square'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'smartTags',
      label: 'Tags Inteligentes',
      icon: 'bi-tags'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'business',
      label: 'Unidade',
      icon: 'bi-building'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'origin',
      label: 'Origem',
      icon: 'bi-box-arrow-up-right'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'survey',
      label: 'Pesquisa',
      icon: 'bi-chat-left-text'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'channel',
      label: 'Canal',
      icon: 'bi-megaphone'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'user',
      label: 'Solicitante',
      icon: 'bi-person'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'contact_optin',
      label: 'Autorização do Contato',
      icon: 'bi-patch-check'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'resolved',
      label: 'Resolvidas',
      icon: 'bi-check2'
    },
    {
      type: 'cf_response',
      subType: 'none',
      name: 'has_ticket',
      label: 'Ticket Criado',
      icon: 'bi-ticket'
    },
    {
      type: 'metadata',
      subType: 'none',
      name: 'browser',
      label: 'Navegador',
      icon: 'bi-globe2'
    },
    {
      type: 'metadata',
      subType: 'none',
      name: 'os',
      label: 'Sistema Operacional',
      icon: 'bi-laptop'
    },
    {
      type: 'metadata',
      subType: 'none',
      name: 'platform',
      label: 'Plataforma',
      icon: 'bi-phone'
    },
    {
      type: 'ticketing',
      subType: 'none',
      name: 'ticketing-assignee',
      label: 'Responsável',
      icon: 'bi-person'
    },
    {
      type: 'ticketing',
      subType: 'none',
      name: 'ticketing-tag',
      label: 'Tag',
      icon: 'bi-tag'
    },
    {
      type: 'ticketing',
      subType: 'none',
      name: 'priority',
      label: 'Prioridade',
      icon: 'bi-exclamation-diamond'
    }
  ];

  public scoreOptions = {
    nps: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ces: [1, 2, 3, 4, 5, 6, 7],
    csat: [1, 2, 3, 4, 5],
    likert: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    thumbs: ['Positivo', 'Negativo']
  };

  public metricsOptions = [
    {
      label: 'NPS',
      value: 'nps'
    },
    {
      label: 'CES',
      value: 'ces'
    },
    {
      label: 'CSAT',
      value: 'csat'
    },
    {
      label: 'Likert',
      value: 'likert'
    },
    {
      label: 'Like/Dislike',
      value: 'thumbs'
    }
  ];

  public deliveryStatusOptions = ['Enviado', 'Pendente', 'Erro'];

  public channelsOptions = ['E-mail', 'SMS', 'WhatsApp Web', 'Link/QRCode', 'WhatsApp API', 'InApp'];

  public optinOptions = ['Autorizado', 'Não Autorizado'];

  public sentimentOptions = ['Negativo', 'Neutro', 'Positivo'];

  public severityOptions = ['Baixa', 'Média', 'Alta', 'Crítica'];

  public priorityOptions = ['Baixa', 'Média', 'Alta'];

  public typeOptions = ['Elogio', 'Reclamação', 'Dúvida', 'Sugestão', 'Outros'];

  public resolvedOptions = ['Resolvido', 'Não Resolvido'];

  public hasTicketOptions = ['Possui', 'Não Possui'];

  public loadingSuggestions = false;
  public suggestions = [];
  public newFilter: any = {
    condition: 'equals',
    specify: false,
    specifyValue: '',
    values: []
  };

  public disableSearchAndSpecify = false;

  public subjectInnerSearchFilter = new Subject();

  @Input() selectedSurvey: Survey;
  @Input() filters = [];
  @Input() dateSubject = new Subject();
  @Input() filterBusinessMode;
  @Input() excludedFilters = [];

  ngOnInit() {
    this.pipelineId = window.location.href.split('pipelines/')[1];

    this.loadOptions();

    this.subjectInnerSearchFilter
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.loadFilterData(false);
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item, index, array) => {
      item.unsubscribe();
      array.splice(index, 1);
    });
  }

  loadOptions() {
    if (this.store.custom_fields == undefined) {
      return setTimeout(() => this.loadOptions(), 500);
    }

    this.filterOptions = this.filterOptions.filter((item: any) => item.subType === 'none');

    const customer_custom_fields = this.store.custom_fields.map((item: any) => {
      return {
        type: 'customer',
        subType: 'custom_fields',
        name: item.internal_name,
        label: item.internal_name,
        icon: this.customFieldsIcons[item.type]
      };
    });

    const cf_response_custom_fields = !this.excludedFilters.includes('cf_response_custom_fields')
      ? this.store.custom_fields.map((item: any) => {
          return {
            type: 'cf_response',
            subType: 'custom_fields',
            name: item.internal_name,
            label: item.internal_name,
            icon: this.customFieldsIcons[item.type]
          };
        })
      : [];

    const cf_response_question = this.selectedSurvey
      ? this.selectedSurvey.questions
          .filter((x) => ['multiple', 'unique', 'select'].includes(x.type))
          .map((item: any) => {
            return {
              type: 'cf_response',
              subType: 'question',
              name: item.internal_name,
              label: item.internal_name,
              icon: this.questionIcons[item.type]
            };
          })
      : [];

    this.filterOptions.push(...customer_custom_fields, ...cf_response_custom_fields, ...cf_response_question);

    this.filterOptions = this.filterOptions.filter((item) => !this.excludedFilters.includes(item.name));
  }

  onChangeCondition() {
    this.newFilter.values = [];
  }

  checkDisabled(item) {
    if (
      (item.name == 'text' && this.filters.some((filter) => filter.filter.name == 'text')) ||
      (item.name == 'aiTopicSentiment' && !this.filters.find((filter) => filter.filter.name == 'aiTopic'))
    ) {
      return true;
    }
  }

  selectOption(item, evt) {
    if (this.checkDisabled(item)) {
      return;
    }

    this.step = 1;
    evt.stopPropagation();
    this.newFilter.filter = item;
    this.loadFilterData(true);
    return false;
  }

  loadFilterData(clean = false) {
    if (clean) {
      this.suggestions = [];
    }

    if (this.newFilter.filter.subType === 'custom_fields') {
      this.getCustomFields(this.newFilter.filter.type == 'cf_response' ? 'answers' : 'customers');
    } else if (this.newFilter.filter.type == 'customer' && this.newFilter.filter.name != 'tag') {
      this.getCustomers();
    } else if (this.newFilter.filter.type == 'company') {
      this.getCompanies();
    } else if (this.newFilter.filter.type == 'cf_response' && this.newFilter.filter.name == 'origin') {
      this.getOrigins();
    } else if (this.newFilter.filter.type == 'cf_response' && this.newFilter.filter.name == 'user') {
      this.getUsers();
    } else if (this.newFilter.filter.type == 'metadata') {
      this.getMetadatas();
    } else if (this.newFilter.filter.name == 'channel') {
      this.getChannels();
    } else if (this.newFilter.filter.name == 'contact_optin') {
      this.suggestions = this.optinOptions;
    } else if (this.newFilter.filter.name == 'aiSentiment') {
      this.suggestions = this.sentimentOptions;
    } else if (this.newFilter.filter.name == 'aiTopicSentiment') {
      this.suggestions = this.sentimentOptions;
    } else if (this.newFilter.filter.name == 'aiSeverity') {
      this.suggestions = this.severityOptions;
    } else if (this.newFilter.filter.name == 'aiType') {
      this.suggestions = this.typeOptions;
    } else if (this.newFilter.filter.name == 'resolved') {
      this.suggestions = this.resolvedOptions;
    } else if (this.newFilter.filter.name == 'has_ticket') {
      this.suggestions = this.hasTicketOptions;
    } else if (this.newFilter.filter.name == 'priority') {
      this.suggestions = this.priorityOptions;
    } else if (this.newFilter.filter.name == 'aiPerson') {
      this.getPersons();
    } else if (this.newFilter.filter.name == 'aiTopic') {
      this.getTopics();
    } else if (this.newFilter.filter.name == 'score') {
      this.suggestions = this.scoreOptions[this.selectedMetric];
    } else if (this.newFilter.filter.name == 'text') {
      this.newFilter.condition = 'contains';
    } else if (this.newFilter.filter.name == 'deliveryStatus') {
      this.suggestions = this.deliveryStatusOptions;
    } else if (this.newFilter.filter.name == 'tag') {
      this.getTags();
    } else if (this.newFilter.filter.name == 'ticketing-tag') {
      this.getTicketingTags();
    } else if (this.newFilter.filter.name == 'ticketing-assignee') {
      this.getTicketingAssignee();
    } else if (this.newFilter.filter.name == 'smartTags') {
      this.getSmartTags();
    } else if (this.newFilter.filter.name == 'survey') {
      this.getSurveys();
    } else if (this.newFilter.filter.subType == 'question') {
      this.getQuestionOptions();
    } else if (this.newFilter.filter.name == 'business') {
      this.getBusinessOptions();
    }

    this.suggestions = [...new Set(this.suggestions)];
  }

  getQuestionOptions() {
    this.loadingSuggestions = true;
    const data = this.selectedSurvey.questions.find((x) => x.internal_name == this.newFilter.filter.name);
    this.suggestions = data.options;
    this.suggestions = [...new Set(this.suggestions)];
    this.loadingSuggestions = false;
  }

  getBusinessOptions() {
    this.loadingSuggestions = true;

    this.suggestions = this.store.userData._businesses
      .filter((item) => item.nome.toLowerCase().includes(this.innerSearchFilter.toLowerCase()))
      .map((item) => item.nome);
    this.suggestions = [...new Set(this.suggestions)];
    this.loadingSuggestions = false;
  }

  emitKeyUpSearchFilter(event) {
    this.subjectInnerSearchFilter.next(event.target.value);
  }

  getChannels() {
    this.suggestions = this.channelsOptions.filter((item) => item.toLowerCase().includes(this.innerSearchFilter.toLowerCase()));
    this.suggestions = [...new Set(this.suggestions)];
  }

  getCustomFields(restrict_to) {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.empresaService
        .getCustomFieldValues(this.store.empresa, this.newFilter.filter.name, this.store.business._id, this.innerSearchFilter, restrict_to)
        .subscribe((data: any) => {
          this.suggestions = data.map((item) => item.custom_fields).filter((item) => item != '');
          this.suggestions = [...new Set(this.suggestions)];
          this.loadingSuggestions = false;
        })
    );
  }

  getSmartTags() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.smartTagService.getSmartTags(this.store.empresa, this.innerSearchFilter, this.selectedSurvey?._tagSet?._id ?? undefined).subscribe((data) => {
        this.suggestions = data.result.map((item) => item.name).filter((item) => item != '' && item != null);
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  getTicketingAssignee() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.pipelineService.getById(this.pipelineId).subscribe({
        next: (data) => {
          this.suggestions = data._users
            .filter((item) => item.nome.toLowerCase().includes(this.innerSearchFilter.toLowerCase()))
            .map((item) => item.nome)
            .filter((item) => item != '' && item != null);
          this.suggestions = [...new Set(this.suggestions)];
          this.loadingSuggestions = false;
        },
        error: (error) => {
          console.error(error);
          this.loadingSuggestions = false;
        }
      })
    );
  }

  getCustomers() {
    this.loadingSuggestions = true;
    const filters: any = {};
    filters.filterMessage = this.innerSearchFilter;
    if (this.newFilter.filter.name === 'name') {
      filters.name = true;
    }
    if (this.newFilter.filter.name === 'email') {
      filters.email = true;
    } else if (this.newFilter.filter.name === 'tel') {
      filters.tel = true;
    }
    filters.filterMessageField = this.newFilter.filter.name;

    this.subscriptions.push(
      this.customerService.getCustomersAdvanced(this.store.business._id, 50, 1, filters, JSON.stringify({ created_at: -1 }), 'ok').subscribe((data) => {
        this.suggestions = this.remove_duplicates_es6(data.data.map((item) => item[this.newFilter.filter.name]));
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  getCompanies() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.companyService.getCompanies(this.store.empresa, this.innerSearchFilter, 0, 50).subscribe((data) => {
        this.suggestions = data.map((item) => item[this.newFilter.filter.name]).filter((item) => item != '' && item != null);
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  getOrigins() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.surveyAnswerService
        .getOrigins({
          _empresa: this.store.empresa,
          _businesses: [this.store.business._id],
          filterText: this.innerSearchFilter
        })
        .subscribe((data) => {
          this.suggestions = data.map((item) => item.origin).filter((item) => item != '' && item != null);
          this.suggestions = [...new Set(this.suggestions)];
          this.loadingSuggestions = false;
        })
    );
  }

  getUsers() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.userService.getByEmpresa(this.store.empresa).subscribe((data) => {
        this.suggestions = data.map((item) => item.nome).filter((item) => item != '' && item != null);
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  getMetadatas() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.surveyAnswerService
        .getMetadatas({
          _empresa: this.store.empresa,
          _businesses: [this.store.business._id],
          filterText: this.innerSearchFilter,
          field: this.newFilter.filter.name
        })
        .subscribe((data) => {
          this.suggestions = data.map((item) => item.metadata).filter((item) => item != '' && item != null);
          this.suggestions = [...new Set(this.suggestions)];
          this.loadingSuggestions = false;
        })
    );
  }

  getPersons() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.surveyAnswerService.getAIPersons(this.store.empresa, this.innerSearchFilter).subscribe(
        (data) => {
          this.suggestions = data.map((item) => item._id).filter((item) => item != '' && item != null);
          this.suggestions = [...new Set(this.suggestions)];
          this.loadingSuggestions = false;
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  getTopics() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.surveyAnswerService.getAITopics(this.store.empresa, this.innerSearchFilter).subscribe((data) => {
        this.suggestions = data.filter((item) => item != '' && item != null);
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  getTags() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.tagService.getTags(this.store.empresa, this.innerSearchFilter).subscribe((data) => {
        this.suggestions = data.map((item) => item.name).filter((item) => item != '' && item != null);
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  getTicketingTags() {
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.tagService.getTags(this.store.empresa, this.innerSearchFilter, 'ticketing').subscribe((data) => {
        this.suggestions = data.map((item) => item.name).filter((item) => item != '' && item != null);
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  getSurveys() {
    let businesses = null;
    if (this.filterBusinessMode == 'current') {
      businesses = [this.store.business._id];
    }
    this.loadingSuggestions = true;
    this.subscriptions.push(
      this.surveyService.get(businesses, this.store.empresa, true, this.innerSearchFilter).subscribe((data) => {
        this.suggestions = data.map((item) => item.title).filter((item) => item != '' && item != null);
        this.suggestions = [...new Set(this.suggestions)];
        this.loadingSuggestions = false;
      })
    );
  }

  onSelectValue(item) {
    if (this.newFilter.condition == 'contains' || this.newFilter.condition == 'not_contains') {
      if (this.newFilter.values[0] == item) {
        this.newFilter.values = [];
      } else {
        this.newFilter.values = [item];
      }
    } else {
      const index = this.newFilter.values.indexOf(item);
      if (index > -1) {
        this.newFilter.values.splice(index, 1);
      } else {
        this.newFilter.values.push(item);
      }
    }
  }

  editFilter(filter, i, dropdown, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isEditingFilter = true;
    this.editFilterIndex = i;
    this.step = 1;
    this.newFilter = filter;
    this.loadFilterData(true);
    dropdown.show();
  }

  selectAll(isGlobal = false) {
    const includeAll = this.suggestions.filter((item) => this.newFilter.values.indexOf(item) != -1).length == this.suggestions.length;
    this.suggestions.forEach((item) => {
      if (this.newFilter.values.indexOf(item) == -1) {
        this.newFilter.values.push(item);
      } else {
        if (isGlobal && !this.isSelectAll) {
          this.newFilter.values.splice(this.newFilter.values.indexOf(item), 1);
        } else if (includeAll) {
          this.newFilter.values.splice(this.newFilter.values.indexOf(item), 1);
        }
      }
    });
  }

  applyFilter() {
    if (this.newFilter.filter.name == 'score') {
      this.newFilter.specifyValue = this.selectedMetric;
      this.newFilter.isMainMetric = this.isMainMetric;
    }

    if (this.newFilter.filter.name == 'text') {
      this.newFilter.specify = true;
    }

    if (this.newFilter.specify) {
      this.newFilter.specifyValue = this.innerSearchFilter;
    }
    if (this.editFilterIndex != -1) {
      this.filters[this.editFilterIndex] = this.newFilter;
    } else {
      this.filters.push(this.newFilter);
    }

    this.isEditingFilter = false;

    this.dateSubject.next(uniqueId());
  }

  removeFilter(index) {
    this.filters.splice(index, 1);
    this.dateSubject.next(uniqueId());
  }

  backStep(evt) {
    this.newFilter.filter = null;
    this.innerSearchFilter = '';
    this.filterSearch = '';
    this.step = 0;
    evt.stopPropagation();
  }

  getCondition(condition) {
    if (condition == 'equals') {
      return 'igual';
    } else if (condition == 'not_equals') {
      return 'diferente';
    } else if (condition == 'contains') {
      return 'contém';
    } else {
      return 'não contém';
    }
  }

  resetNewFilter() {
    this.step = 0;
    this.newFilter = {
      condition: 'equals',
      specify: false,
      specifyValue: '',
      values: []
    };
    this.innerSearchFilter = '';
    this.filterSearch = '';
    this.isEditingFilter = false;
  }

  trackByFilter(index, data) {
    //@ts-ignore
    return window.md5(JSON.stringify(data));
  }

  remove_duplicates_es6(arr) {
    const s = new Set(arr);
    const it = s.values();
    return Array.from(it);
  }
}
