<div class="amp-dialog">
  <div class="amp-dialog-header">
    <div class="amp-dialog-title">Importar Recompensas</div>
    <span class="amp-dialog-close bi-x-lg" (click)="bsModalRef.hide()"></span>
  </div>
  <div class="amp-dialog-body">
    <div *ngIf="step == 0" class="amp-steps">
      <div class="amp-dropzone">
        <input type="file" #fileDiv (change)="checkAndUploadFile($event.target.files[0])">
        <img class="amp-import-box-img" src="assets/img/group-596.svg" />
        <h3>Arraste o arquivo aqui</h3>
        <h3>ou</h3>
        <label>Procure o arquivo</label>
      </div>
    </div>
    <div *ngIf="step == 1" class="amp-steps">
      <h1 class="amp-step-title col-lg-4">Mapeie as colunas do arquivo para as propriedades da Recompensa</h1>
      <h2 class="amp-step-subtitle col-lg-6">
        Para cada coluna do arquivo que está sendo importado, você deve selecionar qual será o campo interno da plataforma Amplifique.me que receberá o dado da
        coluna. Abaixo também é exibido um exemplo dos dados contidos em cada coluna do seu arquivo de importação para auxiliar no mapeamento de campos.
      </h2>
      <div class="amp-match-box col-lg-8 mx-auto">
        <div class="amp-table">
          <div class="amp-table-head">
            <div class="amp-table-column">Coluna do Arquivo</div>
            <div class="amp-table-column">Exemplos de dados do Arquivo</div>
            <div class="amp-table-column">Campo a ser mapeado</div>
          </div>
          <div class="amp-table-row" *ngFor="let ckXLS of checkingDataXLS; let i = index">
            <div class="amp-table-cell">{{ ckXLS.key }}</div>
            <div class="amp-table-cell">
              <span *ngFor="let ex of ckXLS.examples">{{ ex ?? '-' }}</span>
            </div>
            <div class="amp-table-cell">
              <div class="amp-field">
                <select class="amp-input" (change)="changeKey(select.value, ckXLS.key)" #select [(ngModel)]="selectPersistence[i]" required>
                  <option value="" selected>Selecione uma opção</option>
                  <option value="_id" [disabled]="keys._id">ID</option>
                  <option value="redeemed" [disabled]="keys.redeemed">Status de Entrega</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="step == 2">
      <span>{{ validLines }} linhas válidas</span>
    </div>
    <div class="amp-navigation">
      <div class="amp-navigation-buttons-right">
        <button class="amp-btn-lg" (click)="step = step + 1; checkXLSFile()" *ngIf="step == 1" [disabled]="disableNextStep">
          Avançar &nbsp;<span class="bi-chevron-right"></span>
        </button>
        <button class="amp-btn-lg amp-btn-success" (click)="import()" *ngIf="step == 2" [disabled]="validLines == 0 || clicked">
          <img *ngIf="clicked" class="animate-img" src="../../assets/img/014-rotate-blue.svg" />Finalizar &nbsp;<span class="bi-check-lg"></span>
        </button>
      </div>
    </div>
  </div>
</div>
