import { Business } from './business';
import { Empresa } from './empresa';
export class EmailTemplate {
  _id: string;
  _business: Business;
  _empresa: Empresa | string;
  created_at: Date;
  type = '';
  sendgrid_template = '';
  from_name = '';
  reply_to = '';
  subject = '';
  body = '';
  button_text = 'Texto do botão';
  button_url = 'https://';
  button_color = '#ffca00';
  button_text_color = '#000000';
  footer = 'Atenciosamente';
  show_logo = true;
  logo_size = 'S';
  cover_url = 'https://images.unsplash.com/photo-1592880475728-31c2b5b617ef?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max';
  show_scale = false;
}
